var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "layout",
    { attrs: { "no-sidebar": true } },
    [
      _c("div", { staticClass: "page-header" }, [
        _c("h1", [_c("small", [_vm._v("Refund Requests")])]),
        _vm._v(" "),
        _c("div", { staticClass: "text-right" }, [
          _c("div", [
            _vm.refundRequest.status == "approved"
              ? _c(
                  "button",
                  {
                    directives: [
                      {
                        name: "hasPermits",
                        rawName: "v-hasPermits:prevent",
                        value: ["channel_user"],
                        expression: "['channel_user']",
                        arg: "prevent"
                      },
                      {
                        name: "hasPermits",
                        rawName: "v-hasPermits:allow",
                        value: ["super_admin"],
                        expression: "['super_admin']",
                        arg: "allow"
                      }
                    ],
                    staticClass: "btn btn-danger",
                    class: { disabled: _vm.processing },
                    attrs: { disabled: _vm.processing },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.updateStatus("reverted")
                      }
                    }
                  },
                  [_vm._v("\n          Revert\n        ")]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.refundRequest.next_id || _vm.refundRequest.prev_id
              ? _c("span", [
                  _vm._v("\n        |\n        "),
                  _vm.refundRequest.prev_id
                    ? _c(
                        "a",
                        {
                          attrs: { href: "#" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.moveToRequest(
                                _vm.refundRequest.prev_id
                              )
                            }
                          }
                        },
                        [_vm._v("Prev")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.refundRequest.next_id && _vm.refundRequest.prev_id
                    ? _c("span", [_vm._v("-")])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.refundRequest.next_id
                    ? _c(
                        "a",
                        {
                          attrs: { href: "#" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.moveToRequest(
                                _vm.refundRequest.next_id
                              )
                            }
                          }
                        },
                        [_vm._v("Next")]
                      )
                    : _vm._e()
                ])
              : _vm._e()
          ])
        ])
      ]),
      _vm._v(" "),
      _vm.loading
        ? _c("div", {}, [
            _c("i", { staticClass: "fa fa-spinner fa-spin fa-3x fa-fw" })
          ])
        : _c("div", {}, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-lg-6 margin-bottom-3x" }, [
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "label",
                    {
                      staticClass: "flaot-left margin-top-1x",
                      attrs: { for: "refundAmount" }
                    },
                    [
                      _vm._v(
                        "Refund Amount (Max $" +
                          _vm._s(
                            _vm.refundRequest.available_for_partial_refund.toFixed(
                              2
                            )
                          ) +
                          ")"
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    staticClass:
                      "form-control float-left refund-request-amount",
                    attrs: { type: "text", id: "refundAmount", readonly: "" },
                    domProps: { value: _vm.refundAmount }
                  })
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-lg-6 margin-bottom-3x" }, [
                _c("div", { staticClass: "text-right" }, [
                  _c("div", [
                    _c("label", { attrs: { for: "" } }, [
                      _vm._v(
                        "Status : " +
                          _vm._s(_vm.displayStatus(_vm.refundRequest.status))
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "hasPermits",
                            rawName: "v-hasPermits:allow",
                            value: ["channel_user"],
                            expression: "['channel_user']",
                            arg: "allow"
                          },
                          {
                            name: "hasPermits",
                            rawName: "v-hasPermits:prevent",
                            value: ["super_admin"],
                            expression: "['super_admin']",
                            arg: "prevent"
                          }
                        ]
                      },
                      [
                        _vm.refundRequest.status == "pending"
                          ? _c("div", [
                              _vm._v(
                                "\n                Deadline: " +
                                  _vm._s(
                                    _vm
                                      .moment(_vm.refundRequest.deadline)
                                      .format("MMM D, YYYY")
                                  ) +
                                  "\n              "
                              )
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.refundRequest.status == "approved"
                          ? _c("div", [
                              _vm._v(
                                "\n                Go ahead with refund process with your customer \n              "
                              )
                            ])
                          : _vm._e()
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _vm.adminHasAccess() &&
                  _vm.refundRequest.status != "approved" &&
                  _vm.refundRequest.status != "reverted"
                    ? _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "hasPermits",
                              rawName: "v-hasPermits:allow",
                              value: {
                                id: _vm.refundRequest.location_id,
                                permits: ["refundRequest_update"]
                              },
                              expression:
                                "{id: refundRequest.location_id, permits: ['refundRequest_update']}",
                              arg: "allow"
                            }
                          ]
                        },
                        [
                          _vm.refundRequest.status != "escalated" &&
                          _vm.refundRequest.status != "approved"
                            ? _c("span", [
                                _vm._v(
                                  "\n              You have until  " +
                                    _vm._s(
                                      _vm
                                        .moment(_vm.refundRequest.deadline)
                                        .format("MMM D, YYYY")
                                    ) +
                                    " to "
                                ),
                                _c("br")
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.refundRequest.available_for_partial_refund.toFixed(
                            2
                          ) <= 0 ||
                          _vm.refundAmount >
                            _vm.refundRequest.available_for_partial_refund ||
                            _vm.refundAmount <= 0
                            ? _c(
                                "button",
                                {
                                  staticClass: "btn btn-success",
                                  class: { disabled: _vm.processing },
                                  attrs: { disabled: _vm.processing },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.openApproveModal.apply(
                                        null,
                                        arguments
                                      )
                                    }
                                  }
                                },
                                [_vm._v("\n              Accept\n            ")]
                              )
                            : _c(
                                "button",
                                {
                                  staticClass: "btn btn-success",
                                  class: { disabled: _vm.processing },
                                  attrs: { disabled: _vm.processing },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.updateStatus("approved")
                                    }
                                  }
                                },
                                [_vm._v("\n              Accept\n            ")]
                              ),
                          _vm._v(" "),
                          _vm.refundRequest.status != "escalated" &&
                          _vm.refundRequest.status != "approved"
                            ? _c("span", [
                                _vm._v("\n              Or\n            ")
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-danger",
                              class: {
                                disabled:
                                  _vm.refundRequest.status == "rejected" ||
                                  _vm.processing ||
                                  !_vm.canReject()
                              },
                              attrs: {
                                disabled:
                                  _vm.refundRequest.status == "rejected" ||
                                  _vm.processing ||
                                  !_vm.canReject()
                              },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.openRejectModal.apply(
                                    null,
                                    arguments
                                  )
                                }
                              }
                            },
                            [_vm._v("\n              Reject\n            ")]
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.refundRequest.status == "rejected"
                    ? _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "hasPermits",
                              rawName: "v-hasPermits:allow",
                              value: ["channel_user"],
                              expression: "['channel_user']",
                              arg: "allow"
                            },
                            {
                              name: "hasPermits",
                              rawName: "v-hasPermits:prevent",
                              value: ["super_admin"],
                              expression: "['super_admin']",
                              arg: "prevent"
                            }
                          ]
                        },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-info",
                              class: { disabled: _vm.processing },
                              attrs: { disabled: _vm.processing },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.updateStatus("escalated")
                                }
                              }
                            },
                            [_vm._v("\n              Escalate\n            ")]
                          )
                        ]
                      )
                    : _vm._e()
                ])
              ])
            ]),
            _vm._v(" "),
            _c(
              "table",
              {
                staticClass: "table table-bordered",
                attrs: { id: "refund-request-information" }
              },
              [
                _c("tbody", [
                  _vm.refundRequest.no_show_reservation
                    ? _c("tr", [
                        _c(
                          "th",
                          { staticClass: "text-danger font-weight-bold" },
                          [_vm._v("(No Show)")]
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("tr", [
                    _c("th", [_vm._v("Reservation Number:")]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(_vm._s(_vm.refundRequest.reservation_number))
                    ])
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("th", [_vm._v("Location Name:")]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(_vm.refundRequest.location))])
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("th", [_vm._v("Date of Refund Request:")]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(_vm.refundRequest.date))])
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("th", [_vm._v("From Date:")]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(_vm.refundRequest.from_date))])
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("th", [_vm._v("To Date:")]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(_vm.refundRequest.to_date))])
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("th", [_vm._v("Due at location:")]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        _vm._s(_vm.refundRequest.due_at_location ? "Yes" : "No")
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("th", [_vm._v("Case Type:")]),
                    _vm._v(" "),
                    _c("td", [
                      _c("div", { staticClass: "refund-request-type" }, [
                        _vm.refundRequest.type ==
                        "cancellation_request_prior_to_check_in_time"
                          ? _c("div", [
                              _vm._v(
                                "Customer sent a cancellation request prior to check-in time"
                              )
                            ])
                          : _vm.refundRequest.type == "significant_issue"
                          ? _c("div", [
                              _vm._v(
                                "Customer could not park because of a significant issue at location"
                              )
                            ])
                          : _vm.refundRequest.type == "unacceptable_service"
                          ? _c("div", [_vm._v("Unacceptable service")])
                          : _c("div", [_vm._v("Other")])
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("th", [_vm._v("Case Description:")]),
                    _vm._v(" "),
                    _c("td", [
                      _c("div", {
                        staticClass: "refund-request-notes",
                        domProps: { innerHTML: _vm._s(_vm.refundRequest.notes) }
                      })
                    ])
                  ]),
                  _vm._v(" "),
                  _vm.attachments.length > 0
                    ? _c("tr", [
                        _c("th", [_vm._v("Attachments:")]),
                        _vm._v(" "),
                        _c("td", [
                          _c(
                            "ul",
                            _vm._l(_vm.attachments, function(file) {
                              return _c("li", { key: file.id }, [
                                _c("a", { attrs: { href: file.path } }, [
                                  _vm._v(_vm._s(file.filename))
                                ])
                              ])
                            }),
                            0
                          )
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("tr", [
                    _c("th", [_vm._v("Comments:")]),
                    _vm._v(" "),
                    _c(
                      "td",
                      [
                        _vm._l(_vm.refundRequest.comments, function(comment) {
                          return _c(
                            "div",
                            { key: comment.id, staticClass: "margin-top-1x" },
                            [
                              _c("label", [_vm._v(_vm._s(comment.user.name))]),
                              _vm._v(" "),
                              _c("span", [_vm._v(_vm._s(comment.created_at))]),
                              _vm._v(" "),
                              _c("div", {
                                staticClass: "refund-request-comment",
                                domProps: { innerHTML: _vm._s(comment.comment) }
                              })
                            ]
                          )
                        }),
                        _vm._v(" "),
                        _c("hr"),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "hasPermits",
                                rawName: "v-hasPermits:allow",
                                value: {
                                  id: _vm.refundRequest.location_id,
                                  permits: [
                                    "refundRequest_update",
                                    "channel_user"
                                  ]
                                },
                                expression:
                                  "{id: refundRequest.location_id, permits: ['refundRequest_update','channel_user']}",
                                arg: "allow"
                              }
                            ]
                          },
                          [
                            !_vm.typing_comment
                              ? _c("input", {
                                  ref: "newComment",
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "text",
                                    placeholder: "Add a comment..."
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.writeComment()
                                    }
                                  }
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _c("vue-html5-editor", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.typing_comment,
                                  expression: "typing_comment"
                                }
                              ],
                              ref: "commentEditor",
                              attrs: { height: 100, content: _vm.new_comment },
                              on: {
                                change: _vm.changeComment,
                                "update:content": function($event) {
                                  _vm.new_comment = $event
                                }
                              }
                            }),
                            _vm._v(" "),
                            _vm.errors.comment
                              ? _c("div", { staticClass: "text-danger" }, [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.errors.comment[0]) +
                                      "\n              "
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.typing_comment
                              ? _c("div", [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-primary",
                                      class: { disabled: _vm.processing },
                                      attrs: { disabled: _vm.processing },
                                      on: {
                                        click: function($event) {
                                          $event.preventDefault()
                                          return _vm.newComment()
                                        }
                                      }
                                    },
                                    [
                                      _vm.processing
                                        ? _c("span", [
                                            _c("i", {
                                              staticClass:
                                                "fa fa-spinner fa-spin"
                                            })
                                          ])
                                        : _vm._e(),
                                      _vm._v(
                                        "\n                  Save\n                "
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn",
                                      on: { click: _vm.cancelComment }
                                    },
                                    [_vm._v("Cancel")]
                                  )
                                ])
                              : _vm._e()
                          ],
                          1
                        )
                      ],
                      2
                    )
                  ])
                ])
              ]
            )
          ]),
      _vm._v(" "),
      _c("reject-modal", { ref: "rejectModal" }),
      _vm._v(" "),
      _c("approve-modal", { ref: "approveModal" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-2df60b89", { render: render, staticRenderFns: staticRenderFns })
  }
}