import moment from 'moment'
import layout from 'layouts/default'
import datepicker from 'components/datepicker'
import sSelect from 'components/sSelect'
import datatable from 'components/datatable'

const components = {
  layout,
  datepicker,
  sSelect,
  datatable
}

const computed = {
  url () {
    let params = [];
    let data = _.cloneDeep(this.form);
    if (data.from_date && data.to_date) {
        params.push('from_date=' + moment(data.from_date, ['MM/DD/YYYY']).format('YYYY-MM-DD'),
            'to_date=' + moment(data.to_date, ['MM/DD/YYYY']).format('YYYY-MM-DD'))
    }

    if (data.auditable_type.value) {
        params.push('auditable_type=' + data.auditable_type.value)
    }
    if (data.search_id) {
        params.push('search_id=' + data.search_id)
    }
    if (data.user.value) {
        params.push('user_id=' + data.user.value)
    }
    params = params.join('&');
    return 'audits?' + params;
  },

  audits () {
    return _.cloneDeep(this.$store.state.audits.filtered_audits);
  },

  locations () {
    if (!_.isEmpty(this.$store.state.locations.all)) {
        let locations = this.$store.state.locations.all.map((item) => {
            return _.merge({text: item.name, value: item.id}, item)
          });
          locations.unshift({value: null, text: '-- Choose --'});
          return locations;
    } 
    return [];
  },

  channels () {
    if (!_.isEmpty(this.$store.state.channels.all)) {
        let channels = this.$store.state.channels.all.map((item) => {
            return _.merge({text: item.name, value: item.id}, item)
          });
          channels.unshift({value: null, text: '-- Choose --'});
          return channels;
    } 
    return [];
  },

  users () {
    if (!_.isEmpty(this.$store.state.users.all)) {
        let users = this.$store.state.users.all.map((item) => {
            return _.merge({text: item.email, value: item.id}, item)
          });
          users.unshift({value: null, text: '-- Choose --'});
          return users;
    } 
    return [];
  },

  auditsModal () {
    if (!_.isEmpty(this.$store.state.audits.modals)) {
        let types = this.$store.state.audits.modals.map((item) => {
            return _.merge({text: item.auditable_table, value: item.auditable_type}, item)
          });
          types.unshift({value: null, text: '-- Choose --'});
          return types;
    } 
    return [];
  },

  canRun () {
    return this.form.from_date && this.form.to_date;
  }
}

const methods = {

    showErrors (errors) {
        this.errors = errors;
    },

    applyFilters () {
        this.apply_filter = true;
    },

    resetFilters () {
        this.apply_filter = false;
        this.errors = [];
    },

    typeChanged (n) {
        this.form.auditable_type = n;
        this.form.search_id = null;
        if (n.value == "App\\Models\\Location") {
            this.$store.dispatch('locations.getAll', {_q: "", _all: true})
        }
        if (n.value == "App\\Models\\Channel") {
            this.$store.dispatch('channels.getAll', {_q: "", _all: true})
        }
        this.resetFilters();
    },

    setSearchId (n) {
        this.search_obj = n;
        this.form.search_id = n.value;
        this.resetFilters();
    },

    userChanged (n) {
        this.form.user = n;
        this.resetFilters();
    }
}


export default {
  name: 'audits',
  components,
  computed,
  methods,
  data () {
    return {
      apply_filter: false,
      form: {
        auditable_type: {value: null, text: '-- Choose --'},
        search_id: null,
        from_date: null,
        to_date: null,
        user: {value: null, text: '-- Choose --'}
       },
       search_obj: {value: null, text: '-- Choose --'},
      errors: {},
      columns: [
        {name: "Id", object_name: "auditable_id", sortable: true},
        {name: "Auditable type", object_name: "auditable_type"},
        {name: "Event", object_name: "event"},
        {name: "Old values", object_name: "old_values"},
        {name: "New values", object_name: "new_values"},
        {name: "User", object_name: (obj) => {
            return obj.user ? obj.user.email : '_';
        }},
        {name: "IP address", object_name: "ip_address"},
        {name: "Url", object_name: "url"},
        {name: "Updated at", object_name: "created_at", sortable: true, sort_key: 'created_at'}
      ]
    }
  },
  created () {
    this.$store.dispatch('audits.getModals', {_q: "", _all: true});
    this.$store.dispatch('users.getAll', {_q: "", _all: true});
  }
}
