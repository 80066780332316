import toastr from 'toastr'
import layout from 'layouts/default'

const components = {
  layout
}

const computed = {
  roles () {
    return this.$store.state.roles.all;
  },
  countries () {
    let countries = [{name: "Select Country", id: null}]
      .concat(this.$store.state.address.countries)

    return countries
  },
  states () {
    let states = [{name: "Select State", id: null}];
    if (this.form.country_id) {

      let indx = _.findIndex(this.countries, item => item.id == this.form.country_id);
      if (indx > -1) {
        states = states.concat(this.countries[indx].states);
      }
    }

    return states;
  }
}

const methods = {
  submit () {
    this.errors = {}

    try {
      if (this.form.roles[0] == '') {
        delete this.form.roles;
      }
    } catch(e) {}

    this.processing = true;
    this.$store.dispatch('users.newUser', this.form)
    .then(() => {
      this.processing = false;
      this.form.roles = [''];
      let errors = this.$store.state.users.errors;
      if (errors) {
        if (errors.errors) {
          this.errors = _.cloneDeep(errors.errors);
        }        
        toastr.error(errors.message);
      } else {
        this.$router.replace({name: 'users.all'});
      }
    });
  }
}

export default {
  name: 'new-user',
  components,
  computed,
  methods,
  data () {
    return {
      form: {
        name: null,
        email: null,
        roles: [''],
        address1: null,
        address2: null,
        zip_code: null,
        country_id: null,
        state_id: null,
        type: 'ims'
      },
      loading: true,
      processing: false,
      errors: {}
    }
  },
  created () {
    this.$store.dispatch('roles.getAll', {_all: true}).then(() => {
      this.loading = false;
    });
    this.$store.dispatch('address.getCountries');
  }
}
