import toastr from 'toastr'
// import { funcModal } from 'components/modals'
import { dateFormat } from 'filters'
import viewReservation from 'components/modals/reservationDetails'

const components = {
  /*funcModal,*/ viewReservation
}

const filters = {
  dateFormat
}

const methods = {
  // validateReservation () {
  //   let value = this.$refs.validationId.value;
  //   if (!value.length) return;
  //
  //   this.validationProcessing = true;
  //   this.$store.dispatch('dashboards.validateReservation', value)
  //     .then(() => {
  //       this.validationProcessing = false;
  //       console.log(this.$store.state.dashboards.reservation);
  //       if (this.$store.state.dashboards.errors) {
  //         toastr.error('Reservation not found');
  //       } else {
  //         this.reservation = this.$store.state.dashboards.reservation;
  //         this.$refs.reservationDetails.open();
  //       }
  //     });
  // }
  showReservationDetails (item_id) {
    if (!item_id.length) return;
    this.validationProcessing = true;
    this.$store.dispatch('dashboards.validateReservation', {
      id:item_id,
      location_id:this.$route.params.id,
    }).then(() => {
      this.validationProcessing = false;
      let errors = _.cloneDeep(this.$store.state.dashboards.errors);
      if (errors) {
        toastr.error(errors.message);
      } else {
        this.reservation = this.$store.state.dashboards.reservation;
        if (this.reservation.cancelled) {
          toastr.warning("The reservation has been cancelled");
        }
        this.$refs.view.open();
      }
    });

    return false;
  }
}


export default {
  name: 'validate-reservation',
  methods,
  components,
  filters,
  data () {
    return {
      reservation: null,
      validationProcessing: false
    }
  }
}
