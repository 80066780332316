import { http } from 'services'
// initial state
const state = () => ({
  errors: null,
  template: null
})

// getters
const getters = {
}

// actions
const actions = {
  ['bulkUpload.downloadTemplate'] ({ commit, state }, {location_id, params}) {
    commit('CLEAR_ERRORS')

    return (http.get('locations/' + location_id + '/templates', {
      params,
      responseType: 'arraybuffer'
    })).then ((res) => {
      commit('BULK_DOWNLOAD_TEMPLATE', res);
    })
    .catch ((res) => {
      commit('BULK_ERROR', res);
    })
  },

  ['bulkUpload.uploadCSV'] ({ commit, state }, {location_id, file, channels, change_price_reason}) {
    commit('CLEAR_ERRORS')
    let config = {
      headers: {
        'Content-Type': ''
      },
      "processData": false,
      "contentType": false,
      "mimeType": "multipart/form-data",
    }
    let formData = new FormData();
    formData.append("file", file);
    formData.append("change_price_reason", change_price_reason);
    for (let i in channels) {
      formData.append("channels[]", channels[i]);
    }

    return (http.post('locations/' + location_id + '/templates', formData, config)).then ((res) => {
      commit('BULK_UPLOAD', res);
    })
    .catch ((res) => {
      commit('BULK_ERROR', res);
    })
  }
}

// mutations
const mutations = {
  BULK_DOWNLOAD_TEMPLATE (state, res) {
    state.template = res.data;
    const blob = new Blob([res.data], { type: `application/csv; charset=UTF-8` });
    blob.name = 'Bulk_upload_template.csv';
    const reader = new FileReader();
    reader.onload = e => {
      const anchor = document.createElement('a');
      anchor.style.display = 'none';
      anchor.href = e.target.result;
      anchor.download = blob.name;
      anchor.click();
    };
    reader.readAsDataURL(blob);
  },

  BULK_UPLOAD (state, res) {
    //DO NOTHING
  },

  //ERRORS
  BULK_ERROR (state, err) {
    try {
      state.errors = err.response.data;
    } catch (e) {
      state.errors = err;
    }
  },

  CLEAR_ERRORS (state) {
    state.errors = null
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
