var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("form", [
    _c("div", { staticClass: "form-input margin-bottom-3x" }, [
      _c("label", [_vm._v("Bundle Name")]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.formData.name,
            expression: "formData.name"
          }
        ],
        staticClass: "form-control",
        attrs: { type: "text" },
        domProps: { value: _vm.formData.name },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.$set(_vm.formData, "name", $event.target.value)
          }
        }
      }),
      _vm._v(" "),
      _vm.errors.name
        ? _c("div", { staticClass: "text-danger" }, [
            _vm._v("\n      " + _vm._s(_vm.errors.name[0]) + "\n    ")
          ])
        : _vm._e()
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "hasPermits",
            rawName: "v-hasPermits:allow",
            value: ["super_admin"],
            expression: "['super_admin']",
            arg: "allow"
          }
        ],
        staticClass: "form-input margin-bottom-3x"
      },
      [
        _c("label", [_vm._v("Price Limit")]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.formData.price_limit,
              expression: "formData.price_limit"
            }
          ],
          staticClass: "form-control",
          attrs: { type: "text" },
          domProps: { value: _vm.formData.price_limit },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.formData, "price_limit", $event.target.value)
            }
          }
        }),
        _vm._v(" "),
        _vm.errors.price_limit
          ? _c("div", { staticClass: "text-danger" }, [
              _vm._v("\n      " + _vm._s(_vm.errors.price_limit[0]) + "\n    ")
            ])
          : _vm._e()
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "form-input margin-bottom-3x" }, [
      _c("label", [_vm._v("Select Room Type")]),
      _vm._v(" "),
      _c(
        "select",
        {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.formData.location_room_type_id,
              expression: "formData.location_room_type_id"
            }
          ],
          staticClass: "form-control",
          on: {
            change: function($event) {
              var $$selectedVal = Array.prototype.filter
                .call($event.target.options, function(o) {
                  return o.selected
                })
                .map(function(o) {
                  var val = "_value" in o ? o._value : o.value
                  return val
                })
              _vm.$set(
                _vm.formData,
                "location_room_type_id",
                $event.target.multiple ? $$selectedVal : $$selectedVal[0]
              )
            }
          }
        },
        [
          _c("option", { domProps: { value: null } }, [
            _vm._v("-- Select Type --")
          ]),
          _vm._v(" "),
          _vm._l(_vm.roomTypes, function(type) {
            return _c("option", { domProps: { value: type.id } }, [
              _vm._v("\n        " + _vm._s(type.name) + "\n      ")
            ])
          })
        ],
        2
      ),
      _vm._v(" "),
      _vm.errors.location_room_type_id
        ? _c("div", { staticClass: "text-danger" }, [
            _vm._v(
              "\n      " +
                _vm._s(_vm.errors.location_room_type_id[0]) +
                "\n    "
            )
          ])
        : _vm._e()
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "form-input margin-bottom-3x" }, [
      _c("label", [_vm._v("Select Parking Type")]),
      _vm._v(" "),
      _c(
        "select",
        {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.formData.location_parking_type_id,
              expression: "formData.location_parking_type_id"
            }
          ],
          staticClass: "form-control",
          on: {
            change: function($event) {
              var $$selectedVal = Array.prototype.filter
                .call($event.target.options, function(o) {
                  return o.selected
                })
                .map(function(o) {
                  var val = "_value" in o ? o._value : o.value
                  return val
                })
              _vm.$set(
                _vm.formData,
                "location_parking_type_id",
                $event.target.multiple ? $$selectedVal : $$selectedVal[0]
              )
            }
          }
        },
        [
          _c("option", { domProps: { value: null } }, [
            _vm._v("-- Select Type --")
          ]),
          _vm._v(" "),
          _vm._l(_vm.parkingTypes, function(type) {
            return _c("option", { domProps: { value: type.id } }, [
              _vm._v("\n        " + _vm._s(type.name) + "\n      ")
            ])
          })
        ],
        2
      ),
      _vm._v(" "),
      _vm.errors.location_parking_type_id
        ? _c("div", { staticClass: "text-danger" }, [
            _vm._v(
              "\n      " +
                _vm._s(_vm.errors.location_parking_type_id[0]) +
                "\n    "
            )
          ])
        : _vm._e()
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "form-input margin-bottom-3x" }, [
      _c("label", [_vm._v("Number of Nights to Sleep")]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.formData.number_of_room_days,
            expression: "formData.number_of_room_days"
          }
        ],
        staticClass: "form-control",
        attrs: { type: "text" },
        domProps: { value: _vm.formData.number_of_room_days },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.$set(_vm.formData, "number_of_room_days", $event.target.value)
          }
        }
      }),
      _vm._v(" "),
      _vm.errors.number_of_room_days
        ? _c("div", { staticClass: "text-danger" }, [
            _vm._v(
              "\n      " + _vm._s(_vm.errors.number_of_room_days[0]) + "\n    "
            )
          ])
        : _vm._e()
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "form-input margin-bottom-3x" }, [
      _c("label", [_vm._v("Number of Parking Days")]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.formData.number_of_parking_days,
            expression: "formData.number_of_parking_days"
          }
        ],
        staticClass: "form-control",
        attrs: { type: "text" },
        domProps: { value: _vm.formData.number_of_parking_days },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.$set(
              _vm.formData,
              "number_of_parking_days",
              $event.target.value
            )
          }
        }
      }),
      _vm._v(" "),
      _vm.errors.number_of_parking_days
        ? _c("div", { staticClass: "text-danger" }, [
            _vm._v(
              "\n      " +
                _vm._s(_vm.errors.number_of_parking_days[0]) +
                "\n    "
            )
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-fa6168ae", { render: render, staticRenderFns: staticRenderFns })
  }
}