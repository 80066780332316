var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "layout",
    { attrs: { "no-box": true, title: _vm.location ? _vm.location.name : "" } },
    [
      _c("tabs"),
      _vm._v(" "),
      _c("div", { staticClass: "box" }, [
        _c("div", { staticClass: "box-body" }, [
          _vm.loading
            ? _c("div", { staticClass: "text-center" }, [
                _c("i", { staticClass: "fa fa-spinner fa-spin fa-3x fa-fw" })
              ])
            : _c("div", { staticClass: "margin-top-3x" }, [
                _c(
                  "div",
                  { staticClass: "text-right" },
                  [
                    _c("new-employee", {
                      directives: [
                        {
                          name: "hasPermits",
                          rawName: "v-hasPermits:allow",
                          value: {
                            id: _vm.locId,
                            permits: ["locationEmployee_create"]
                          },
                          expression:
                            "{id: locId, permits: ['locationEmployee_create']}",
                          arg: "allow"
                        }
                      ]
                    }),
                    _vm._v(" "),
                    _c("edit-employee", {
                      directives: [
                        {
                          name: "hasPermits",
                          rawName: "v-hasPermits:allow",
                          value: {
                            id: _vm.locId,
                            permits: ["locationEmployee_create"]
                          },
                          expression:
                            "{id: locId, permits: ['locationEmployee_create']}",
                          arg: "allow"
                        }
                      ],
                      ref: "editModal",
                      staticClass: "text-left f14",
                      attrs: {
                        canOpen: !_vm._.isEmpty(_vm.selectedUser),
                        userData: _vm.selectedUser
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.location.users && _vm.location.users.length
                  ? _c(
                      "div",
                      { staticClass: "table-responsive" },
                      [
                        _c(
                          "table",
                          { staticClass: "table table-striped margin-top-3x" },
                          [
                            _c("thead", [
                              _c("tr", [
                                _c("th", [_vm._v(" Employee Name ")]),
                                _vm._v(" "),
                                _c("th", [_vm._v(" Email ")]),
                                _vm._v(" "),
                                _c("th", [_vm._v(" Role ")]),
                                _vm._v(" "),
                                _c("th", [_vm._v(" Details ")]),
                                _vm._v(" "),
                                _c(
                                  "th",
                                  {
                                    directives: [
                                      {
                                        name: "hasPermits",
                                        rawName: "v-hasPermits:allow",
                                        value: {
                                          id: _vm.locId,
                                          permits: ["locationEmployee_delete"]
                                        },
                                        expression:
                                          "{id: locId, permits: ['locationEmployee_delete']}",
                                        arg: "allow"
                                      }
                                    ]
                                  },
                                  [_vm._v(" Remove ")]
                                )
                              ])
                            ]),
                            _vm._v(" "),
                            _c(
                              "tbody",
                              _vm._l(_vm.location.users, function(emp) {
                                return emp.user
                                  ? _c("tr", { key: emp.id }, [
                                      _c("td", [
                                        _vm._v(
                                          " " + _vm._s(emp.user.name) + " "
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c("td", [
                                        _vm._v(
                                          " " + _vm._s(emp.user.email) + " "
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c("td", [
                                        emp.roles.length
                                          ? _c(
                                              "div",
                                              { staticClass: "text-primary" },
                                              [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(
                                                      emp.roles[0].display_name
                                                    ) +
                                                    "\n                  "
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ]),
                                      _vm._v(" "),
                                      _c("td", [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "text-info",
                                            attrs: { href: "#" },
                                            on: {
                                              click: function($event) {
                                                $event.preventDefault()
                                                return _vm.openEditModal(emp)
                                              }
                                            }
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "fa fa-ellipsis-h",
                                              attrs: { "aria-hidden": "true" }
                                            })
                                          ]
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        {
                                          directives: [
                                            {
                                              name: "hasPermits",
                                              rawName: "v-hasPermits:allow",
                                              value: {
                                                id: _vm.locId,
                                                permits: [
                                                  "locationEmployee_delete"
                                                ]
                                              },
                                              expression:
                                                "{id: locId, permits: ['locationEmployee_delete']}",
                                              arg: "allow"
                                            }
                                          ]
                                        },
                                        [
                                          _vm.delete_loadings[emp.id]
                                            ? _c("span", [
                                                _c("i", {
                                                  staticClass:
                                                    "fa fa-spin fa-spinner"
                                                })
                                              ])
                                            : _c(
                                                "a",
                                                {
                                                  staticClass: "text-danger",
                                                  attrs: { href: "#" },
                                                  on: {
                                                    click: function($event) {
                                                      $event.preventDefault()
                                                      return _vm.removeEmployee(
                                                        emp
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass: "fa fa-times",
                                                    attrs: {
                                                      "aria-hidden": "true"
                                                    }
                                                  })
                                                ]
                                              )
                                        ]
                                      )
                                    ])
                                  : _vm._e()
                              }),
                              0
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c("confirm", {
                          ref: "removeConfirm",
                          attrs: { type: "danger" }
                        })
                      ],
                      1
                    )
                  : _c("div", { staticClass: "text-warning" }, [
                      _vm._v(
                        "\n          There are no employees assigned to this location\n        "
                      )
                    ])
              ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-1306076c", { render: render, staticRenderFns: staticRenderFns })
  }
}