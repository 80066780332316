import toastr from 'toastr'

const methods = {
  submit () {
    this.errors = {};
    this.processing = true;
    this.$store.dispatch('locations.newFee', {
      id: this.$route.params.id,
      data:this.form
    }).then(() => {
      this.processing = false;
      let errors =  this.$store.state.locations.errors;
      if (errors) {
        if (errors.errors) {
          this.errors = _.cloneDeep(errors.errors);  
        }
        toastr.error(errors.message);
      } else {
        toastr.success('data has been saved successfully');
        this.formActive = false;
        this.form = {
          name: null,
          amount: null,
          description: null,
          type: "fixed",
          associated_to: "room",
          calculation: "one_time",
        }
      }
    });
  },

  handleFeesType (event) {
    let type = event.target.value;
    if (type == 'one_time') {
      this.form.type = 'fixed';
    }
  },
}

export default {
  name: 'new-fee',
  methods,
  data() {
    return {
      formActive: false,
      processing: false,
      errors: {},
      form: {
        name: null,
        amount: null,
        description: null,
        type: "fixed",
        associated_to: "room",
        calculation: "one_time",
      }
    }
  }
}
