var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { style: { "pointer-events": _vm.updateGeneral ? "auto" : "none" } },
    [
      _vm.user && _vm.user.type == "ims"
        ? _c("div", { staticClass: "form-group" }, [
            _vm._m(0),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.trim",
                  value: _vm.form.hourly_checkin_limit,
                  expression: "form.hourly_checkin_limit",
                  modifiers: { trim: true }
                }
              ],
              staticClass: "form-control",
              attrs: {
                type: "text",
                placeholder: "Hourly check-in limit of parking"
              },
              domProps: { value: _vm.form.hourly_checkin_limit },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(
                    _vm.form,
                    "hourly_checkin_limit",
                    $event.target.value.trim()
                  )
                },
                blur: function($event) {
                  return _vm.$forceUpdate()
                }
              }
            }),
            _vm._v(" "),
            _vm.errors.hourly_checkin_limit
              ? _c("div", { staticClass: "text-danger" }, [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.errors.hourly_checkin_limit[0]) +
                      "\n    "
                  )
                ])
              : _vm._e()
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "form-group" }, [
        _vm._m(1),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model.trim",
              value: _vm.form.minimum_booking_days,
              expression: "form.minimum_booking_days",
              modifiers: { trim: true }
            }
          ],
          staticClass: "form-control",
          attrs: {
            type: "text",
            placeholder: "Minimum booking days of parking"
          },
          domProps: { value: _vm.form.minimum_booking_days },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(
                _vm.form,
                "minimum_booking_days",
                $event.target.value.trim()
              )
            },
            blur: function($event) {
              return _vm.$forceUpdate()
            }
          }
        }),
        _vm._v(" "),
        _vm.errors.minimum_booking_days
          ? _c("div", { staticClass: "text-danger" }, [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.errors.minimum_booking_days[0]) +
                  "\n    "
              )
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group overflow-auto" }, [
        _c("label", { attrs: { for: "" } }, [
          _vm._v("Number of Hours Prior To Booking ")
        ]),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("div", { staticClass: "col-sm-6" }, [
          _c("label", { attrs: { for: "" } }, [_vm._v("Parking ")]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model.trim",
                value: _vm.form.hours_before_reservation,
                expression: "form.hours_before_reservation",
                modifiers: { trim: true }
              }
            ],
            staticClass: "form-control",
            attrs: {
              type: "text",
              placeholder: "Number of Hours Prior To  Parking Booking"
            },
            domProps: { value: _vm.form.hours_before_reservation },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(
                  _vm.form,
                  "hours_before_reservation",
                  $event.target.value.trim()
                )
              },
              blur: function($event) {
                return _vm.$forceUpdate()
              }
            }
          }),
          _vm._v(" "),
          _vm.errors.hours_before_reservation
            ? _c("div", { staticClass: "text-danger" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.errors.hours_before_reservation[0]) +
                    "\n      "
                )
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-sm-6" }, [
          _c("label", { attrs: { for: "" } }, [_vm._v("Rooms ")]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model.trim",
                value: _vm.form.hours_before_room_reservation,
                expression: "form.hours_before_room_reservation",
                modifiers: { trim: true }
              }
            ],
            staticClass: "form-control",
            attrs: {
              type: "text",
              placeholder: "Number of Hours Prior To  Parking Booking"
            },
            domProps: { value: _vm.form.hours_before_room_reservation },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(
                  _vm.form,
                  "hours_before_room_reservation",
                  $event.target.value.trim()
                )
              },
              blur: function($event) {
                return _vm.$forceUpdate()
              }
            }
          }),
          _vm._v(" "),
          _vm.errors.hours_before_room_reservation
            ? _c("div", { staticClass: "text-danger" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.errors.hours_before_room_reservation[0]) +
                    "\n      "
                )
              ])
            : _vm._e()
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group" }, [
        _vm._m(2),
        _vm._v(" "),
        _c("div", { staticClass: "input-group radio" }, [
          _c("label", [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.daily_or_hourly,
                  expression: "form.daily_or_hourly"
                }
              ],
              attrs: { type: "radio", value: "daily" },
              domProps: { checked: _vm._q(_vm.form.daily_or_hourly, "daily") },
              on: {
                change: function($event) {
                  return _vm.$set(_vm.form, "daily_or_hourly", "daily")
                }
              }
            }),
            _vm._v("\n        Every Calendar Day\n      ")
          ]),
          _vm._v("\n       \n      "),
          _c("label", [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.daily_or_hourly,
                  expression: "form.daily_or_hourly"
                }
              ],
              attrs: { type: "radio", value: "hourly" },
              domProps: { checked: _vm._q(_vm.form.daily_or_hourly, "hourly") },
              on: {
                change: function($event) {
                  return _vm.$set(_vm.form, "daily_or_hourly", "hourly")
                }
              }
            }),
            _vm._v("\n        Every 24 hours\n      ")
          ])
        ]),
        _vm._v(" "),
        _vm.errors.daily_or_hourly
          ? _c("div", { staticClass: "text-danger" }, [
              _vm._v(
                "\n      " + _vm._s(_vm.errors.daily_or_hourly[0]) + "\n    "
              )
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group" }, [
        _c("label", [_vm._v("Currency")]),
        _vm._v(" "),
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.form.currency_id,
                expression: "form.currency_id"
              }
            ],
            staticClass: "form-control",
            attrs: { placeholder: "Currency" },
            on: {
              change: function($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function(o) {
                    return o.selected
                  })
                  .map(function(o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.$set(
                  _vm.form,
                  "currency_id",
                  $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                )
              }
            }
          },
          [
            _c("option", { domProps: { value: null } }, [
              _vm._v("Select Currency")
            ]),
            _vm._v(" "),
            _vm._l(_vm.currencies, function(curr) {
              return _c("option", { domProps: { value: curr.id } }, [
                _vm._v(_vm._s(curr.name + " - " + curr.code))
              ])
            })
          ],
          2
        ),
        _vm._v(" "),
        _vm.errors.currency_id
          ? _c("div", { staticClass: "text-danger" }, [
              _vm._v("\n      " + _vm._s(_vm.errors.currency_id[0]) + "\n    ")
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group" }, [
        _c("label", [_vm._v("Tax Type")]),
        _vm._v(" "),
        _c("div", { staticClass: "radio" }, [
          _c("label", { staticClass: "margin-right-2x" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.tax_type,
                  expression: "form.tax_type"
                }
              ],
              attrs: { type: "radio", value: "gross" },
              domProps: { checked: _vm._q(_vm.form.tax_type, "gross") },
              on: {
                change: function($event) {
                  return _vm.$set(_vm.form, "tax_type", "gross")
                }
              }
            }),
            _vm._v(
              "\n        Gross remitted by Location (Tax on subtotal)\n      "
            )
          ]),
          _vm._v(" "),
          _c("label", { staticClass: "margin-right-2x" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.tax_type,
                  expression: "form.tax_type"
                }
              ],
              attrs: { type: "radio", value: "gross_channel" },
              domProps: { checked: _vm._q(_vm.form.tax_type, "gross_channel") },
              on: {
                change: function($event) {
                  return _vm.$set(_vm.form, "tax_type", "gross_channel")
                }
              }
            }),
            _vm._v(
              "\n        Gross remitted by Channel (Tax on subtotal)\n      "
            )
          ]),
          _vm._v(" "),
          _c("label", [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.tax_type,
                  expression: "form.tax_type"
                }
              ],
              attrs: { type: "radio", value: "net" },
              domProps: { checked: _vm._q(_vm.form.tax_type, "net") },
              on: {
                change: function($event) {
                  return _vm.$set(_vm.form, "tax_type", "net")
                }
              }
            }),
            _vm._v("\n        Net (Tax on vendor portion only)\n      ")
          ])
        ]),
        _vm._v(" "),
        _vm.errors.tax_type
          ? _c("div", { staticClass: "text-danger" }, [
              _vm._v("\n      " + _vm._s(_vm.errors.tax_type[0]) + "\n    ")
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group" }, [
        _c("label", [_vm._v("Tax Value (%)")]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model.trim",
              value: _vm.form.tax_value,
              expression: "form.tax_value",
              modifiers: { trim: true }
            }
          ],
          staticClass: "form-control",
          attrs: {
            type: "text",
            min: "0",
            placeholder: "The percentage of tax (from 1 to 100)"
          },
          domProps: { value: _vm.form.tax_value },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.form, "tax_value", $event.target.value.trim())
            },
            blur: function($event) {
              return _vm.$forceUpdate()
            }
          }
        }),
        _vm._v(" "),
        _vm.errors.tax_value
          ? _c("div", { staticClass: "text-danger" }, [
              _vm._v("\n      " + _vm._s(_vm.errors.tax_value[0]) + "\n    ")
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "hasPermits",
              rawName: "v-hasPermits:allow",
              value: {
                id: _vm.locId,
                permits: ["location_booking_days_limit_update"]
              },
              expression:
                "{id: locId, permits: ['location_booking_days_limit_update']}",
              arg: "allow"
            }
          ],
          staticClass: "form-group"
        },
        [
          _c("label", [_vm._v("Booking Days Limit")]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model.trim",
                value: _vm.form.booking_days_limit,
                expression: "form.booking_days_limit",
                modifiers: { trim: true }
              }
            ],
            staticClass: "form-control",
            attrs: { type: "number", min: "1" },
            domProps: { value: _vm.form.booking_days_limit },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(
                  _vm.form,
                  "booking_days_limit",
                  $event.target.value.trim()
                )
              },
              blur: function($event) {
                return _vm.$forceUpdate()
              }
            }
          }),
          _vm._v(" "),
          _vm.errors.booking_days_limit
            ? _c("div", { staticClass: "text-danger" }, [
                _vm._v("\n      " + _vm._s(_vm.errors.tax_value[0]) + "\n    ")
              ])
            : _vm._e()
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "form-group checkbox" }, [
        _c("label", [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.form.parking_due_at_location,
                expression: "form.parking_due_at_location"
              }
            ],
            attrs: { type: "checkbox" },
            domProps: {
              checked: Array.isArray(_vm.form.parking_due_at_location)
                ? _vm._i(_vm.form.parking_due_at_location, null) > -1
                : _vm.form.parking_due_at_location
            },
            on: {
              change: function($event) {
                var $$a = _vm.form.parking_due_at_location,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 &&
                      _vm.$set(
                        _vm.form,
                        "parking_due_at_location",
                        $$a.concat([$$v])
                      )
                  } else {
                    $$i > -1 &&
                      _vm.$set(
                        _vm.form,
                        "parking_due_at_location",
                        $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                      )
                  }
                } else {
                  _vm.$set(_vm.form, "parking_due_at_location", $$c)
                }
              }
            }
          }),
          _vm._v("\n      Parking Due At Check-in\n    ")
        ]),
        _vm._v(" "),
        _vm.errors.parking_due_at_location
          ? _c("div", { staticClass: "text-danger" }, [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.errors.parking_due_at_location[0]) +
                  "\n    "
              )
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group checkbox" }, [
        _c("label", [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.form.room_due_at_location,
                expression: "form.room_due_at_location"
              }
            ],
            attrs: { type: "checkbox" },
            domProps: {
              checked: Array.isArray(_vm.form.room_due_at_location)
                ? _vm._i(_vm.form.room_due_at_location, null) > -1
                : _vm.form.room_due_at_location
            },
            on: {
              change: function($event) {
                var $$a = _vm.form.room_due_at_location,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 &&
                      _vm.$set(
                        _vm.form,
                        "room_due_at_location",
                        $$a.concat([$$v])
                      )
                  } else {
                    $$i > -1 &&
                      _vm.$set(
                        _vm.form,
                        "room_due_at_location",
                        $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                      )
                  }
                } else {
                  _vm.$set(_vm.form, "room_due_at_location", $$c)
                }
              }
            }
          }),
          _vm._v("\n      Room Due At Check-in\n    ")
        ]),
        _vm._v(" "),
        _vm.errors.room_due_at_location
          ? _c("div", { staticClass: "text-danger" }, [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.errors.room_due_at_location[0]) +
                  "\n    "
              )
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group checkbox" }, [
        _c("label", [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.form.printed_receipt,
                expression: "form.printed_receipt"
              }
            ],
            attrs: { type: "checkbox" },
            domProps: {
              checked: Array.isArray(_vm.form.printed_receipt)
                ? _vm._i(_vm.form.printed_receipt, null) > -1
                : _vm.form.printed_receipt
            },
            on: {
              change: function($event) {
                var $$a = _vm.form.printed_receipt,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 &&
                      _vm.$set(_vm.form, "printed_receipt", $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      _vm.$set(
                        _vm.form,
                        "printed_receipt",
                        $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                      )
                  }
                } else {
                  _vm.$set(_vm.form, "printed_receipt", $$c)
                }
              }
            }
          }),
          _vm._v("\n      Requires Printed Receipt\n    ")
        ]),
        _vm._v(" "),
        _vm.errors.printed_receipt
          ? _c("div", { staticClass: "text-danger" }, [
              _vm._v(
                "\n      " + _vm._s(_vm.errors.printed_receipt[0]) + "\n    "
              )
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "hasPermits",
              rawName: "v-hasPermits:allow",
              value: ["super_admin"],
              expression: "['super_admin']",
              arg: "allow"
            }
          ],
          staticClass: "form-group checkbox"
        },
        [
          _c("label", [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.credit_card_required,
                  expression: "form.credit_card_required"
                }
              ],
              attrs: { type: "checkbox" },
              domProps: {
                checked: Array.isArray(_vm.form.credit_card_required)
                  ? _vm._i(_vm.form.credit_card_required, null) > -1
                  : _vm.form.credit_card_required
              },
              on: {
                change: function($event) {
                  var $$a = _vm.form.credit_card_required,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 &&
                        _vm.$set(
                          _vm.form,
                          "credit_card_required",
                          $$a.concat([$$v])
                        )
                    } else {
                      $$i > -1 &&
                        _vm.$set(
                          _vm.form,
                          "credit_card_required",
                          $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                        )
                    }
                  } else {
                    _vm.$set(_vm.form, "credit_card_required", $$c)
                  }
                }
              }
            }),
            _vm._v("\n      Requires card details for room reservations\n    ")
          ]),
          _vm._v(" "),
          !_vm.form.room_due_at_location && _vm.form.credit_card_required
            ? _c("div", { staticClass: "text-danger" }, [
                _c("label", [_vm._v("Room due at location must be enabled")])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.errors.credit_card_required
            ? _c("div", { staticClass: "text-danger" }, [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.errors.credit_card_required[0]) +
                    "\n    "
                )
              ])
            : _vm._e()
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "hasPermits",
              rawName: "v-hasPermits:allow",
              value: ["super_admin"],
              expression: "['super_admin']",
              arg: "allow"
            }
          ],
          staticClass: "form-group checkbox"
        },
        [
          _c("label", [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.allow_refund_request,
                  expression: "form.allow_refund_request"
                }
              ],
              attrs: { type: "checkbox" },
              domProps: {
                checked: Array.isArray(_vm.form.allow_refund_request)
                  ? _vm._i(_vm.form.allow_refund_request, null) > -1
                  : _vm.form.allow_refund_request
              },
              on: {
                change: function($event) {
                  var $$a = _vm.form.allow_refund_request,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 &&
                        _vm.$set(
                          _vm.form,
                          "allow_refund_request",
                          $$a.concat([$$v])
                        )
                    } else {
                      $$i > -1 &&
                        _vm.$set(
                          _vm.form,
                          "allow_refund_request",
                          $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                        )
                    }
                  } else {
                    _vm.$set(_vm.form, "allow_refund_request", $$c)
                  }
                }
              }
            }),
            _vm._v("\n      Allow partial refund request\n    ")
          ]),
          _vm._v(" "),
          _vm.errors.allow_refund_request
            ? _c("div", { staticClass: "text-danger" }, [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.errors.allow_refund_request[0]) +
                    "\n    "
                )
              ])
            : _vm._e()
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "hasPermits",
              rawName: "v-hasPermits:allow",
              value: ["super_admin"],
              expression: "['super_admin']",
              arg: "allow"
            }
          ],
          staticClass: "form-group checkbox"
        },
        [
          _c("label", [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.first_day_rate,
                  expression: "form.first_day_rate"
                }
              ],
              attrs: { type: "checkbox" },
              domProps: {
                checked: Array.isArray(_vm.form.first_day_rate)
                  ? _vm._i(_vm.form.first_day_rate, null) > -1
                  : _vm.form.first_day_rate
              },
              on: {
                change: function($event) {
                  var $$a = _vm.form.first_day_rate,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 &&
                        _vm.$set(_vm.form, "first_day_rate", $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        _vm.$set(
                          _vm.form,
                          "first_day_rate",
                          $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                        )
                    }
                  } else {
                    _vm.$set(_vm.form, "first_day_rate", $$c)
                  }
                }
              }
            }),
            _vm._v("\n      Apply 1st day of parking\n    ")
          ]),
          _vm._v(" "),
          _vm.errors.first_day_rate
            ? _c("div", { staticClass: "text-danger" }, [
                _vm._v(
                  "\n      " + _vm._s(_vm.errors.first_day_rate[0]) + "\n    "
                )
              ])
            : _vm._e()
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "hasPermits",
              rawName: "v-hasPermits:allow",
              value: ["super_admin"],
              expression: "['super_admin']",
              arg: "allow"
            }
          ],
          staticClass: "form-group checkbox"
        },
        [
          _c("label", [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.validate_phone_number,
                  expression: "form.validate_phone_number"
                }
              ],
              attrs: { type: "checkbox" },
              domProps: {
                checked: Array.isArray(_vm.form.validate_phone_number)
                  ? _vm._i(_vm.form.validate_phone_number, null) > -1
                  : _vm.form.validate_phone_number
              },
              on: {
                change: function($event) {
                  var $$a = _vm.form.validate_phone_number,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 &&
                        _vm.$set(
                          _vm.form,
                          "validate_phone_number",
                          $$a.concat([$$v])
                        )
                    } else {
                      $$i > -1 &&
                        _vm.$set(
                          _vm.form,
                          "validate_phone_number",
                          $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                        )
                    }
                  } else {
                    _vm.$set(_vm.form, "validate_phone_number", $$c)
                  }
                }
              }
            }),
            _vm._v("\n      Validate phone number\n    ")
          ]),
          _vm._v(" "),
          _vm.errors.validate_phone_number
            ? _c("div", { staticClass: "text-danger" }, [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.errors.validate_phone_number[0]) +
                    "\n    "
                )
              ])
            : _vm._e()
        ]
      ),
      _vm._v(" "),
      _vm.user && _vm.user.type == "ims"
        ? _c("div", { staticClass: "form-group checkbox" }, [
            _c("label", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.block_extensions_beyond_limit,
                    expression: "form.block_extensions_beyond_limit"
                  }
                ],
                attrs: { type: "checkbox" },
                domProps: {
                  checked: Array.isArray(_vm.form.block_extensions_beyond_limit)
                    ? _vm._i(_vm.form.block_extensions_beyond_limit, null) > -1
                    : _vm.form.block_extensions_beyond_limit
                },
                on: {
                  change: function($event) {
                    var $$a = _vm.form.block_extensions_beyond_limit,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.form,
                            "block_extensions_beyond_limit",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.form,
                            "block_extensions_beyond_limit",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.form, "block_extensions_beyond_limit", $$c)
                    }
                  }
                }
              }),
              _vm._v("\n      Block extensions beyond limit\n    ")
            ]),
            _vm._v(" "),
            _vm.errors.block_extensions_beyond_limit
              ? _c("div", { staticClass: "text-danger" }, [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.errors.block_extensions_beyond_limit[0]) +
                      "\n    "
                  )
                ])
              : _vm._e()
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "hasPermits",
              rawName: "v-hasPermits:allow",
              value: ["super_admin"],
              expression: "['super_admin']",
              arg: "allow"
            }
          ],
          staticClass: "form-group checkbox"
        },
        [
          _c("label", [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.one_car_per_booking,
                  expression: "form.one_car_per_booking"
                }
              ],
              attrs: { type: "checkbox" },
              domProps: {
                checked: Array.isArray(_vm.form.one_car_per_booking)
                  ? _vm._i(_vm.form.one_car_per_booking, null) > -1
                  : _vm.form.one_car_per_booking
              },
              on: {
                change: function($event) {
                  var $$a = _vm.form.one_car_per_booking,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 &&
                        _vm.$set(
                          _vm.form,
                          "one_car_per_booking",
                          $$a.concat([$$v])
                        )
                    } else {
                      $$i > -1 &&
                        _vm.$set(
                          _vm.form,
                          "one_car_per_booking",
                          $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                        )
                    }
                  } else {
                    _vm.$set(_vm.form, "one_car_per_booking", $$c)
                  }
                }
              }
            }),
            _vm._v("\n      One car per booking\n    ")
          ]),
          _vm._v(" "),
          _vm.errors.one_car_per_booking
            ? _c("div", { staticClass: "text-danger" }, [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.errors.one_car_per_booking[0]) +
                    "\n    "
                )
              ])
            : _vm._e()
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "hasPermits",
              rawName: "v-hasPermits:allow",
              value: ["super_admin"],
              expression: "['super_admin']",
              arg: "allow"
            }
          ],
          staticClass: "form-group checkbox"
        },
        [
          _c("label", [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.block_extending_reservations,
                  expression: "form.block_extending_reservations"
                }
              ],
              attrs: { type: "checkbox" },
              domProps: {
                checked: Array.isArray(_vm.form.block_extending_reservations)
                  ? _vm._i(_vm.form.block_extending_reservations, null) > -1
                  : _vm.form.block_extending_reservations
              },
              on: {
                change: function($event) {
                  var $$a = _vm.form.block_extending_reservations,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 &&
                        _vm.$set(
                          _vm.form,
                          "block_extending_reservations",
                          $$a.concat([$$v])
                        )
                    } else {
                      $$i > -1 &&
                        _vm.$set(
                          _vm.form,
                          "block_extending_reservations",
                          $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                        )
                    }
                  } else {
                    _vm.$set(_vm.form, "block_extending_reservations", $$c)
                  }
                }
              }
            }),
            _vm._v("\n      Block extending reservations\n    ")
          ]),
          _vm._v(" "),
          _vm.errors.block_extending_reservations
            ? _c("div", { staticClass: "text-danger" }, [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.errors.block_extending_reservations[0]) +
                    "\n    "
                )
              ])
            : _vm._e()
        ]
      ),
      _vm._v(" "),
      _vm.user && _vm.user.type == "ims"
        ? _c("div", { staticClass: "form-group checkbox" }, [
            _c("label", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.block_min_booking_days,
                    expression: "form.block_min_booking_days"
                  }
                ],
                attrs: { type: "checkbox" },
                domProps: {
                  checked: Array.isArray(_vm.form.block_min_booking_days)
                    ? _vm._i(_vm.form.block_min_booking_days, null) > -1
                    : _vm.form.block_min_booking_days
                },
                on: {
                  change: function($event) {
                    var $$a = _vm.form.block_min_booking_days,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.form,
                            "block_min_booking_days",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.form,
                            "block_min_booking_days",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.form, "block_min_booking_days", $$c)
                    }
                  }
                }
              }),
              _vm._v("\n       Block based on the minimum booking days\n    ")
            ]),
            _vm._v(" "),
            _vm.errors.block_min_booking_days
              ? _c("div", { staticClass: "text-danger" }, [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.errors.block_min_booking_days[0]) +
                      "\n    "
                  )
                ])
              : _vm._e()
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [
      _vm._v("Hourly check-in limit "),
      _c("small", [_vm._v("(for parking)")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "locationPhone" } }, [
      _vm._v("Minimum Booking Days "),
      _c("small", [_vm._v("(for parking)")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [
      _vm._v("Price Basis "),
      _c("small", [_vm._v("(for parking)")])
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-2b6e5f83", { render: render, staticRenderFns: staticRenderFns })
  }
}