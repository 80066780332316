import toastr from 'toastr'
import moment from 'moment'
import layout from 'layouts/default'
import { hasPermits } from 'directives'

const directives = {
    hasPermits
}

const components = {
    layout
}

const methods = {
    submit () {
        this.processing = true;
        this.errors = {};
        this.$store.dispatch('guaranteeCard.update', {id: this.$route.params.id, data: this.form}).then(() => {
            if (this.$store.state.guaranteeCard.errors) {
                this.errors = _.cloneDeep(this.$store.state.guaranteeCard.errors.errors);
                toastr.error(this.$store.state.guaranteeCard.errors.message);
            } else {
                toastr.success('Card Has been updated');
            }

            this.processing = false;
        });
    }
}

export default {
    name: 'guarantee-card',
    components,
    methods,
    directives,
    data () {
        return {
            loading: true,
            processing : false,
            errors: {},
            moment,
            form: {
                name_on_card: null,
                card_number: null,
                cvc: null,
                expiration_month: null,
                expiration_year: null,
                zipcode: null
            },
        }
    },

    created () {
        this.$store.dispatch('guaranteeCard.get', 1).then(() => {
            this.form = Object.assign(this.form, this.$store.state.guaranteeCard.active);
            this.loading = false;
        });
    }
}