import toastr from 'toastr'
import fileUpload from 'components/fileUpload'
import { hasPermits } from 'directives'

const directives = {
  hasPermits
}

const computed = {
  base_amenities () {
    return this.$store.state.amenities.parking_amenities
  },
  photos () {
    return this.location.parking_photos ? this.location.parking_photos.map((item) => ({
        filename: item.filename,
        id: item.id,
        featured: item.featured,
      })) : [];
  },
  imageErrors () {
    let errors = [];
    for (let i in this.images) {
      if (this.errors && this.errors['images.' + i]) {
        errors.push(this.errors['images.' + i]);
      }
    }

    return errors;
  }
}

const components = {
  fileUpload
}

const methods = {
  addAmenity () {
    let value = this.$refs.new_amenity.value;
    if (value.trim().length) {
      this.custom_amenities.push(value);
      this.$refs.new_amenity.value = null;
    }
  },

  imagesToUpload (files) {
    if (files.length) {
      this.images = files;
    } else {
      this.images = [];
    }
  },

  uploadImages () {
    this.errors = {};
    this.files_processing = true;
    this.$store.dispatch('locations.uploadImages', {
      id: this.$route.params.id,
      type: 'parking',
      files: this.images
    }).then(() => {
      this.files_processing = false;

      if (this.$store.state.locations.errors) {
        this.errors = _.cloneDeep(this.$store.state.locations.errors.errors);
        toastr.error(this.$store.state.locations.errors.message);
      } else {
        toastr.success('Data has been saved');
        this.$refs.file_upload.removeFiles();
      }
    });
  },

  removePhoto (image) {
    this.need_to_save = true;
    this.$store.dispatch('locations.removeImage', {image, type: 'parking'});
  },

  setFeatured (image) {
    this.errors = {}
    this.processing = true;
    this.photos.forEach((photo) => {
      photo.featured = 1;
      if (photo.id != image.id) {
        photo.featured = 0;
      }
    })
    this.$store.dispatch('locations.setFeaturedImage', {location_id:this.location.id, id:image.id}).then(() => {
      this.processing = false;
      if (this.$store.state.locations.errors) {
        this.errors.images = [];
        this.errors.images.push(this.$store.state.locations.errors.message)
        toastr.error(this.errors.images[0]);
      } else {
        toastr.success('Data has been saved');
      }
    });
  },

  removeAmenity (indx) {
    this.custom_amenities.splice(indx, 1);
  },

  submit () {
    this.errors = {}
    this.processing = true;
    let data = _.cloneDeep(this.location);
    delete data.channels;


    data.parking_custom_amenities = this.custom_amenities;
    data.parking_amenities = this.amenities.map(x => ({id: x}));
    data.room_amenities = this.location.amenities.filter(amenity => amenity.type == 'room').map(x => ({id: x.id}));
    data.facility_amenities = this.location.amenities.filter(amenity => amenity.type == 'facility').map(x => ({id: x.id}));

    this.$store.dispatch('locations.edit', {id: this.$route.params.id, data}).then(() => {
      this.processing = false;
      if (this.$store.state.locations.errors) {
        this.errors = _.cloneDeep(this.$store.state.locations.errors.errors);
        toastr.error(this.$store.state.locations.errors.message);
      } else {
        toastr.success('Data has been saved');
        this.need_to_save = false;
      }
    })
  }
}

const watch = {
  location (n, o) {
    this.custom_amenities = n.parking_custom_amenities;
    this.amenities = (n.amenities.filter(amenity => amenity.type == 'parking')).map(x => x.id);
  }
}

export default {
  name: "parking-photos-and-amenities",
  props: ['location'],
  methods,
  components,
  computed,
  directives,
  data () {
    return {
      locId: this.$route.params.id,
      errors: {},
      processing: false,
      files_processing: false,
      need_to_save: false,
      images: [],
      custom_amenities: this.location.parking_custom_amenities || [],
      amenities: this.location.amenities ?
        (this.location.amenities.filter(amenity => amenity.type == 'parking')).map(x => x.id) : [],
    }
  },
  mounted () {
    $('[data-toggle="tooltip"]').tooltip();
  },
  created () {
    this.$store.dispatch('amenities.getParkingAmenities').then(() => {

    });
  }
}
