var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "col-xs-12" }, [
    _vm.processing
      ? _c("div", { staticClass: "text-center" }, [
          _c("i", { staticClass: "fa fa-spinner fa-spin fa-3x fa-fw" })
        ])
      : _c("div", { staticClass: "table-responsive" }, [
          _c("table", { staticClass: "table table-striped" }, [
            _c("thead", [
              _c(
                "tr",
                [
                  _c("th", [_vm._v("date")]),
                  _vm._v(" "),
                  _vm._l(_vm.columns, function(column, z) {
                    return [
                      column.length > 0
                        ? _c(
                            "th",
                            {
                              key: z,
                              staticClass: "header colr",
                              attrs: { colspan: "3" }
                            },
                            [_vm._v(_vm._s(column))]
                          )
                        : _vm._e()
                    ]
                  })
                ],
                2
              )
            ]),
            _vm._v(" "),
            _c(
              "tbody",
              _vm._l(_vm.rows, function(row, i) {
                return _c(
                  "tr",
                  { key: i },
                  _vm._l(row, function(item, j) {
                    return _c(
                      "td",
                      {
                        key: j,
                        staticClass: "col",
                        class: { colr: j % 3 == 0 }
                      },
                      [_c("div", [_vm._v(_vm._s(item))])]
                    )
                  }),
                  0
                )
              }),
              0
            )
          ])
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-0af6b01c", { render: render, staticRenderFns: staticRenderFns })
  }
}