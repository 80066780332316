var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {}, [
    _c("div", { staticClass: "col-sm-8 text-right" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-default",
          on: {
            click: function($event) {
              $event.preventDefault()
              _vm.formActive = !_vm.formActive
            }
          }
        },
        [
          !_vm.formActive
            ? _c("i", {
                staticClass: "fa fa-caret-down",
                attrs: { "aria-hidden": "true" }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.formActive
            ? _c("i", {
                staticClass: "fa fa-caret-up",
                attrs: { "aria-hidden": "true" }
              })
            : _vm._e(),
          _vm._v("\n      Add a Location\n    ")
        ]
      )
    ]),
    _vm._v(" "),
    _vm.formActive
      ? _c("div", { staticClass: "col-sm-12 margin-top-3x text-left" }, [
          _c("div", { staticClass: "panel panel-default" }, [
            _c("div", { staticClass: "panel-body" }, [
              _c("div", { staticClass: "box" }, [
                _c(
                  "div",
                  { staticClass: "box-header with-border" },
                  [
                    _c("h5", [
                      _vm._v(
                        "Please select the channel that you want to copy rates and locations from"
                      )
                    ]),
                    _vm._v(" "),
                    _c("s-select", {
                      attrs: {
                        selected: _vm.selectedChannel,
                        options: _vm.channelsList,
                        multiple: false
                      },
                      on: { selectedChanged: _vm.onChangeSelectedChannel }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.select
                  ? _c(
                      "div",
                      { staticClass: "box-body" },
                      [
                        _c("div", [_vm._v("Select Locations")]),
                        _vm._v(" "),
                        _c("div", [
                          _c(
                            "a",
                            {
                              attrs: { href: "#" },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.selectAll("locations")
                                }
                              }
                            },
                            [_vm._v("All")]
                          ),
                          _vm._v("  \n              "),
                          _c(
                            "a",
                            {
                              attrs: { href: "#" },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.selectNone("locations")
                                }
                              }
                            },
                            [_vm._v("None")]
                          )
                        ]),
                        _vm._v(" "),
                        _vm._l(_vm.locations, function(location) {
                          return _c(
                            "div",
                            { key: location.id, staticClass: "checkbox" },
                            [
                              _c("label", [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.selectedLocations,
                                      expression: "selectedLocations"
                                    }
                                  ],
                                  attrs: { type: "checkbox" },
                                  domProps: {
                                    value: location.id,
                                    checked: Array.isArray(
                                      _vm.selectedLocations
                                    )
                                      ? _vm._i(
                                          _vm.selectedLocations,
                                          location.id
                                        ) > -1
                                      : _vm.selectedLocations
                                  },
                                  on: {
                                    change: function($event) {
                                      var $$a = _vm.selectedLocations,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = location.id,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            (_vm.selectedLocations = $$a.concat(
                                              [$$v]
                                            ))
                                        } else {
                                          $$i > -1 &&
                                            (_vm.selectedLocations = $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1)))
                                        }
                                      } else {
                                        _vm.selectedLocations = $$c
                                      }
                                    }
                                  }
                                }),
                                _vm._v(
                                  "\n                " +
                                    _vm._s(location.name) +
                                    "\n              "
                                )
                              ])
                            ]
                          )
                        })
                      ],
                      2
                    )
                  : _vm._e()
              ]),
              _vm._v(" "),
              _vm.selectedLocations && _vm.selectedLocations.length
                ? _c("div", { staticClass: "text-right" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-success",
                        class: { disabled: _vm.processing },
                        attrs: { disabled: _vm.processing },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.submit()
                          }
                        }
                      },
                      [
                        _vm.processing
                          ? _c("span", [
                              _c("i", { staticClass: "fa fa-spinner fa-spin" })
                            ])
                          : _vm._e(),
                        _vm._v("\n            SAVE\n          ")
                      ]
                    )
                  ])
                : _vm._e()
            ])
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-2fcd680e", { render: render, staticRenderFns: staticRenderFns })
  }
}