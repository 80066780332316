import toastr from 'toastr'
import layout from "layouts/default"
import { user } from 'services'


const components = {
  layout
}

const computed = {
  form () {
    return user.getUser();
  }
}

const methods = {
  submit () {
    this.processing = true;
    this.$store.dispatch('user.updatePersonalData', this.form).then(() => {
      this.processing = false;
      let errors = this.$store.state.user.errors;
      if (errors) {
        if (errors.errors) {
          this.errors = _.cloneDeep(errors.errors);
        }        
        toastr.error(errors.message);
      } else {
        toastr.success('Data has been saved');
      }
    })
  }
}

export default {
  name: 'personal-settings',
  components,
  computed,
  methods,
  data () {
    return {
      errors: {},
      processing: false
    }
  }
}
