import layout from 'layouts/default'
import tabs from '../tabs'
import { dateFormat, currencySymbol } from 'filters'
import pagination from 'components/pagination'
import { reservationDetails } from 'components/modals'
import { hasPermits } from 'directives'

const directives = {
  hasPermits
}

const components = {
  layout,
  tabs,
  pagination,
  reservationDetails
}

const filters = {
  dateFormat,
  currencySymbol
}

const computed = {
  location () {
    return _.cloneDeep(this.$store.state.locations.active);
  },
  reservations () {
    return _.cloneDeep(this.$store.state.locations.active.reservations);
  },
  currencyCode() {
    return this.location.currency ? currencySymbol(this.location.currency.code) : "Na";
  }
}

const methods = {
  loadPage (page) {
    this.$route.query.page = page;
    this.loading = true;
    Promise.all([
      this.$store.dispatch('locations.get', this.$route.params.id),
      this.$store.dispatch('locations.getReservations', {
        id: this.$route.params.id,
        params: {
          page,
          _sort: '-id',
          search_by: this.filters.search_by,
          'reservation_number-lk': this.filters.search ? (this.filters.search_by == 'reservation_number' ? "*" + this.filters.search.trim() + "*" : this.filters.search.trim()): null,
          cancelled: this.filters.cancelled,
          type: this.filters.type
        }
      })
    ]).then(() => {
      this.loading = false;
    });
  },
  loadReservation (item) {
    this.$set(this.loadings, item.id, true);
    this.$store.dispatch('locations.getReservation', {
      id: item.id,
      lId: this.$route.params.id
    }).then(() => {
      this.$set(this.loadings, item.id, false);
      this.activeReservation = this.$store.state.locations.active_reservation;
      this.$refs.reservationDetailsModal.open();
    })
  }
}

const watch = {
  activeTab (n) {
    if (n.title == "Reservations") {
      this.loadPage(1);
    }
  }
}

export default {
  name: 'reservations-tab',
  props: ['activeTab'],
  directives,
  components,
  computed,
  methods,
  filters,
  watch,
  data () {
    return {
      loading: false,
      activeReservation: null,
      loadings: {},
      filters: {
        search: null,
        search_by: 'reservation_number',
        cancelled: null,
        type: null
      }
    }
  },
  created () {
    this.loadPage(1);
  }
}
