import {all as allRoles, new as newRole, edit as editRole} from 'pages/roles'

export default [
  //Roles
  { path: '/roles', name: 'roles.all', component: allRoles
    , meta:{permits:['role_list']}},
  { path: '/roles/new', name: 'roles.new', component: newRole
    , meta:{permits:['role_create']}},
  { path: '/roles/edit/:id', name: 'roles.edit', component: editRole
    , meta:{permits:['role_list']}},
]
