var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {}, [
    _c("div", { staticClass: "text-right" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-default",
          on: {
            click: function($event) {
              $event.preventDefault()
              _vm.formActive = !_vm.formActive
            }
          }
        },
        [
          !_vm.formActive
            ? _c("i", {
                staticClass: "fa fa-caret-down",
                attrs: { "aria-hidden": "true" }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.formActive
            ? _c("i", {
                staticClass: "fa fa-caret-up",
                attrs: { "aria-hidden": "true" }
              })
            : _vm._e(),
          _vm._v("\n      New Fee\n    ")
        ]
      )
    ]),
    _vm._v(" "),
    _vm.formActive
      ? _c("div", { staticClass: "margin-top-3x" }, [
          _c("div", { staticClass: "form-group" }, [
            _c("label", [_vm._v("Fee Name")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.name,
                  expression: "form.name"
                }
              ],
              staticClass: "form-control",
              attrs: { type: "text" },
              domProps: { value: _vm.form.name },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.form, "name", $event.target.value)
                }
              }
            }),
            _vm._v(" "),
            _vm.errors.name
              ? _c("div", { staticClass: "text-danger" }, [
                  _vm._v("\n        " + _vm._s(_vm.errors.name[0]) + "\n      ")
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form-group" }, [
            _c("label", [_vm._v("Amount")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.amount,
                  expression: "form.amount"
                }
              ],
              staticClass: "form-control",
              attrs: { type: "text" },
              domProps: { value: _vm.form.amount },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.form, "amount", $event.target.value)
                }
              }
            }),
            _vm._v(" "),
            _vm.errors.amount
              ? _c("div", { staticClass: "text-danger" }, [
                  _vm._v(
                    "\n        " + _vm._s(_vm.errors.amount[0]) + "\n      "
                  )
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form-group" }, [
            _c("label", [_vm._v("Description")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.description,
                  expression: "form.description"
                }
              ],
              staticClass: "form-control",
              attrs: { type: "text" },
              domProps: { value: _vm.form.description },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.form, "description", $event.target.value)
                }
              }
            }),
            _vm._v(" "),
            _vm.errors.description
              ? _c("div", { staticClass: "text-danger" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.errors.description[0]) +
                      "\n      "
                  )
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form-group" }, [
            _c("label", [_vm._v("For")]),
            _c("br"),
            _vm._v(" "),
            _c("div", { staticClass: "radio" }, [
              _c("label", { staticClass: "margin-right-3x" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.associated_to,
                      expression: "form.associated_to"
                    }
                  ],
                  attrs: { type: "radio", value: "room" },
                  domProps: { checked: _vm._q(_vm.form.associated_to, "room") },
                  on: {
                    change: function($event) {
                      return _vm.$set(_vm.form, "associated_to", "room")
                    }
                  }
                }),
                _vm._v("\n          Rooms\n        ")
              ]),
              _vm._v(" "),
              _c("label", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.associated_to,
                      expression: "form.associated_to"
                    }
                  ],
                  attrs: { type: "radio", value: "parking" },
                  domProps: {
                    checked: _vm._q(_vm.form.associated_to, "parking")
                  },
                  on: {
                    change: function($event) {
                      return _vm.$set(_vm.form, "associated_to", "parking")
                    }
                  }
                }),
                _vm._v("\n          Parkings\n        ")
              ])
            ]),
            _vm._v(" "),
            _vm.errors.type
              ? _c("div", { staticClass: "text-danger" }, [
                  _vm._v("\n        " + _vm._s(_vm.errors.type[0]) + "\n      ")
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form-group" }, [
            _c("label", [_vm._v("Fee Calculation")]),
            _c("br"),
            _vm._v(" "),
            _c("div", { staticClass: "radio" }, [
              _c("label", { staticClass: "margin-right-3x" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.calculation,
                      expression: "form.calculation"
                    }
                  ],
                  attrs: { type: "radio", value: "one_time" },
                  domProps: {
                    checked: _vm._q(_vm.form.calculation, "one_time")
                  },
                  on: {
                    change: [
                      function($event) {
                        return _vm.$set(_vm.form, "calculation", "one_time")
                      },
                      _vm.handleFeesType
                    ]
                  }
                }),
                _vm._v("\n          One Time "),
                _c("strong", [_vm._v("($)")])
              ]),
              _vm._v(" "),
              _c("label", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.calculation,
                      expression: "form.calculation"
                    }
                  ],
                  attrs: { type: "radio", value: "daily" },
                  domProps: { checked: _vm._q(_vm.form.calculation, "daily") },
                  on: {
                    change: [
                      function($event) {
                        return _vm.$set(_vm.form, "calculation", "daily")
                      },
                      _vm.handleFeesType
                    ]
                  }
                }),
                _vm._v("\n          Daily "),
                _c("strong", [_vm._v("($, %)")])
              ])
            ]),
            _vm._v(" "),
            _vm.errors.calculation
              ? _c("div", { staticClass: "text-danger" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.errors.calculation[0]) +
                      "\n      "
                  )
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _vm.form.calculation == "daily"
            ? _c("div", { staticClass: "form-group" }, [
                _c("label", [_vm._v("Fee Type")]),
                _c("br"),
                _vm._v(" "),
                _c("div", { staticClass: "radio" }, [
                  _c("label", { staticClass: "margin-right-3x" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.type,
                          expression: "form.type"
                        }
                      ],
                      attrs: { type: "radio", value: "fixed" },
                      domProps: { checked: _vm._q(_vm.form.type, "fixed") },
                      on: {
                        change: function($event) {
                          return _vm.$set(_vm.form, "type", "fixed")
                        }
                      }
                    }),
                    _vm._v("\n          Fixed ($)\n        ")
                  ]),
                  _vm._v(" "),
                  _c("label", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.type,
                          expression: "form.type"
                        }
                      ],
                      attrs: { type: "radio", value: "percentage" },
                      domProps: {
                        checked: _vm._q(_vm.form.type, "percentage")
                      },
                      on: {
                        change: function($event) {
                          return _vm.$set(_vm.form, "type", "percentage")
                        }
                      }
                    }),
                    _vm._v("\n          Percentage (%)\n        ")
                  ])
                ]),
                _vm._v(" "),
                _vm.errors.type
                  ? _c("div", { staticClass: "text-danger" }, [
                      _vm._v(
                        "\n        " + _vm._s(_vm.errors.type[0]) + "\n      "
                      )
                    ])
                  : _vm._e()
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "form-group" }, [
            _c("label", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.include_in_tax,
                    expression: "form.include_in_tax"
                  }
                ],
                attrs: { type: "checkbox" },
                domProps: {
                  checked: Array.isArray(_vm.form.include_in_tax)
                    ? _vm._i(_vm.form.include_in_tax, null) > -1
                    : _vm.form.include_in_tax
                },
                on: {
                  change: function($event) {
                    var $$a = _vm.form.include_in_tax,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.form,
                            "include_in_tax",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.form,
                            "include_in_tax",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.form, "include_in_tax", $$c)
                    }
                  }
                }
              }),
              _vm._v("\n        Include in tax\n      ")
            ])
          ]),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          _c("div", { staticClass: "text-right" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-success",
                class: { disabled: _vm.processing },
                attrs: { disabled: _vm.processing },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.submit()
                  }
                }
              },
              [
                _vm.processing
                  ? _c("span", [
                      _c("i", { staticClass: "fa fa-spinner fa-spin" })
                    ])
                  : _vm._e(),
                _vm._v("\n        SAVE FEE\n      ")
              ]
            )
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-37a19f05", { render: render, staticRenderFns: staticRenderFns })
  }
}