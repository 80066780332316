import { http } from 'services'
// initial state
const state = () => ({
  locations: [],
  channelRequests: [],
  utilization_data: [],
  errors: null
})

// getters
const getters = {
}

// actions
const actions = {
  ['reports.getUtilizationReport'] ({commit}, {data}) {
    commit('CLEAR_ERRORS')

    return (http.get('reports/utilization?', {
      params: data
    })).then((res) => {
      commit('GET_UTILIZATION_REPORT_DATA', res);
    })
    .catch ((res) => {
      commit('REPORTS_ERROR', res);
    });
  },

  ['reports.getLocations'] ({ commit, state }, channelId) {
    commit('CLEAR_ERRORS')

    return (http.get('channels/' + channelId + '/reports/locations')).then ((res) => {
      commit('GET_ALL_LOCATIONS', res);
    })
    .catch ((res) => {
      commit('REPORTS_ERROR', res);
    })
  },

  ['reports.getChannelRequests'] ({ commit, state }, url) {
    commit('CLEAR_ERRORS')

    return (http.get(`${url}`)).then ((res) => {
      commit('GET_CHANNEL_REQUESTS', res);
    })
    .catch ((res) => {
      commit('REPORTS_ERROR', res);
    })
  },

  ['reports.getDBReport'] ({ commit, state }, url) {
    commit('CLEAR_ERRORS')

    return (http.get(`${url}`)).then ((res) => {
      commit('GET_BD_REPORT', res);
    })
    .catch ((res) => {
      commit('REPORTS_ERROR', res);
    })
  },

  ['reports.resetReports'] ({ commit }) {
    commit('CLEAR_ERRORS')
    commit('CLEAR_REPORTS')
  }
}

// mutations
const mutations = {
  GET_ALL_LOCATIONS (state, res) {
    if (res)
      state.locations = res.data;
  },

  GET_CHANNEL_REQUESTS (state, res) {
    if (res)
      state.channelRequests = res.data;
  },

  GET_BD_REPORT (state, res) {
    if (res)
      state.BDReport = res.data;
  },

  GET_UTILIZATION_REPORT_DATA (state, res) {
    state.utilization_data = res.data;
  },

  CLEAR_REPORTS (state) {
    state.utilization_data = [];
    state.channelRequests = [];
  },

  //ERRORS
  REPORTS_ERROR (state, err) {
    try {
      state.errors = err.response.data;
    } catch (e) {
      state.errors = err;
    }
  },

  CLEAR_ERRORS (state) {
    state.errors = null
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
