import layout from 'layouts/default'
import { dateFormat } from 'filters'
import moment from 'moment'
import { confirm } from 'components/modals'
import periodModal from '../periodModal'
import { hasPermits } from 'directives'



const directives = {
  hasPermits
}

const components = {
  layout,
  periodModal,
  confirm,
}

if (!isServer) {
  let VueApexCharts = require('vue-apexcharts');
  components.apexchart = VueApexCharts;
}

const filters = {
  dateFormat
}

const computed = {
  location () {
    return _.cloneDeep(this.$store.state.locations.active);
  },

  parkingType () {
    return _.cloneDeep(this.$store.state.locations.active.parkingType);
  },
  periods() {
    return _.cloneDeep(this.$store.state.leaseTypes.periods);
  }
}

const methods = {

  setChartOption() {
    let today = new Date();
    // chartOptions
    this.chartOptions = {
      chart: {
        type: 'area',
        height: 350,
        animations: {
          enabled: true
        },
        zoom: {
          enabled: true
        },
      },
      dataLabels: {
        enabled: true
      },
      stroke: {
        curve: 'straight'
      },
      fill: {
        opacity: 0.8,
        type: 'gradient',
        pattern: {
          style: ['verticalLines', 'horizontalLines'],
          width: 5,
          height: 6
        },
      },
      markers: {
        size: 5,
        hover: {
          size: 9
        }
      },
      title: {
        text: 'Parking lease periods',
      },
      tooltip: {
        // intersect: true,
        shared: false,
        followCursor: true,
      },
      annotations: {
        xaxis: [],
      },
      theme: {
        palette: 'palette'
      },
      xaxis: {
        type: 'datetime',
        min: new Date(today.getFullYear()-1,0,1).getTime(),
        max: new Date(today.getFullYear()+1,11,31).getTime(),
        // labels: {
        //   format: 'dd/MM',
        // }
      },
      yaxis: {
        min: 0,
        max: 100,
        title: {
          text: 'Number of spots'
        }
      }
    };
  },

  refreshPeriods() {
    
    let chartSeries = [];
    let annotationsXaxis = [];
    for (let i = 0; i < this.periods.length; i++) {

      let seriesData = [
        {
          x: new Date(moment(this.periods[i].from, ['YYYY-MM-DD']).format('YYYY-MM-DD')).getTime(),
          y: this.periods[i].number_of_spots
        },
        {
          x: this.periods[i].to ? new Date(moment(this.periods[i].to, ['YYYY-MM-DD']).format('YYYY-MM-DD') + " 23:59:59").getTime() : new Date(moment().add(20, 'years').format('YYYY-MM-DD')).getTime(),
          y: this.periods[i].number_of_spots
        },
      ];


      let ser = {
        name: `Period ${(i+1)}(${this.periods[i].amount}$)`,
        data: seriesData,
      };
      chartSeries.push(ser);

      let annotationstart = {
        x: new Date(moment(this.periods[i].from, ['YYYY-MM-DD']).format('YYYY-MM-DD')).getTime(),
        borderColor: '#00E396',
        label: {
            borderColor: '#00E396',
            offsetX: 0,
            style: {
              color: '#fff',
              background: '#00E396'
            },
            text: moment(this.periods[i].from, ['YYYY-MM-DD']).format('YYYY-MM-DD')
        }
      };

      let annotationsEnd = {
        x: new Date(moment(this.periods[i].to, ['YYYY-MM-DD']).format('YYYY-MM-DD')).getTime(),
        borderColor: '#FEB019',
        label: {
          borderColor: '#FEB019',
          style: {
            color: '#fff',
            background: '#FEB019'
          },
          text: moment(this.periods[i].to, ['YYYY-MM-DD']).format('YYYY-MM-DD')
        }
      };
      annotationsXaxis.push(annotationstart);
      annotationsXaxis.push(annotationsEnd);
    }
      
    this.chartOptions.annotations.xaxis = annotationsXaxis;
    this.series = chartSeries;
    
    this.chartOptions = { ...this.chartOptions };
  },
  
  openNewPeriodModal () {
    this.$nextTick(() => {
      this.$refs.periodModal.open();
    })
  },
  
  editPeriod (period) {
    this.$nextTick(() => {
      this.$refs.periodModal.open(period);
    })
  },
  
  openNewPeriodOperationModal (period) {
    this.$nextTick(() => {
      this.$refs.periodOperationModal.open(period);
    })
  },

  removePeriod (period) {
    this.$refs.deleteConfirm.confirm('Are you sure?', () => {
      this.$store.dispatch('leaseTypes.removePeriod', {location_id: this.$route.params.id,period_id: period.id}).then(() => {
        let errors = this.$store.state.leaseTypes.errors;
        if (errors) {
          toastr.error(errors.message);
        } else {
          this.refreshPeriods();
        }
      })
    });
  },
}


export default {
  name: 'parking-lease',
  components,
  computed,
  methods,
  filters,
  directives,
  data () {
    return {
      loading: true,
      location_id: this.$route.params.id,
      type_id: this.$route.params.type_id,
      series: [],
      chartOptions: {},
    }
  },
  mounted () {

    this.setChartOption();
    this.loading = true;
    Promise.all([
      this.$store.dispatch('locations.get', this.$route.params.id),
      this.$store.dispatch('leaseTypes.getLeasePerids',{location_id: this.location_id, params:{type_id: this.type_id, type: 'parking'}}),
      this.$store.dispatch('locations.getLocationParkingType', {id : this.$route.params.type_id}),
    ]).then(() => {
      this.loading = false;
      
      this.chartOptions.yaxis.max = this.location.number_of_parkings;
      this.refreshPeriods();
    });

  }
}
