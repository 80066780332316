var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "s-editable" }, [
    _vm.active
      ? _c("div", {}, [
          _c(
            "div",
            { staticClass: "input-group" },
            [
              _c("datepicker", {
                ref: "input",
                staticClass: "form-control",
                class: { disabled: _vm.loading },
                attrs: {
                  disabled: _vm.loading,
                  "min-date": _vm.minDate,
                  "max-date": _vm.maxDate
                },
                model: {
                  value: _vm.editedValue,
                  callback: function($$v) {
                    _vm.editedValue = $$v
                  },
                  expression: "editedValue"
                }
              }),
              _vm._v(" "),
              _c("span", { staticClass: "input-group-btn" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-success",
                    class: { disabled: _vm.loading },
                    attrs: { disabled: _vm.loading, type: "button" },
                    on: {
                      click: function($event) {
                        return _vm.save(_vm.$refs.input.$el.value)
                      }
                    }
                  },
                  [
                    _vm.loading
                      ? _c("span", [
                          _c("i", { staticClass: "fa fa-spinner fa-spin" }),
                          _vm._v("\n            Saving...\n          ")
                        ])
                      : _c("span", [_vm._v("Save")])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-danger",
                    class: { disabled: _vm.loading },
                    attrs: { disabled: _vm.loading, type: "button" },
                    on: {
                      click: function($event) {
                        return _vm.cancel()
                      }
                    }
                  },
                  [_vm._v("Cancel")]
                )
              ])
            ],
            1
          )
        ])
      : _c(
          "div",
          {
            ref: "editableText",
            staticClass: "editable-container stooltip",
            on: {
              dblclick: function($event) {
                return _vm.enterActiveMode()
              }
            }
          },
          [
            _c("span", { staticClass: "tooltiptext" }, [
              _vm._v("Double click to edit")
            ]),
            _vm._v("\n    " + _vm._s(_vm.editedValue || "-") + "\n    "),
            _c(
              "span",
              {
                staticClass: "editable-pincel",
                class: { "always-show": _vm.showPen }
              },
              [
                _c("i", {
                  staticClass: "fa fa-pencil",
                  attrs: { "aria-hidden": "true" }
                })
              ]
            )
          ]
        )
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-4c25ba2a", { render: render, staticRenderFns: staticRenderFns })
  }
}