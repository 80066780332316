import { hasPermits } from 'directives'

const directives = {
  hasPermits
}

const computed = {
  roomTypes () {
    let types = this.$store.state.locations.active.roomTypes.map((item) => {
      return _.merge({text: item.name, value: item.id}, item)
    });

    return types;
  },

  parkingTypes () {
    return _.cloneDeep(this.$store.state.locations.active.parkingTypes);
  },
}

const methods = {

}

export default {
  name: 'room-type-form',
  props: ['formData', 'errors'],
  computed,
  methods,
  directives
}
