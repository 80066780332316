var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "layout",
    { attrs: { "no-sidebar": true } },
    [
      _c("div", { staticClass: "page-header" }, [
        _c("h1", [_c("small", [_vm._v("Roles")])])
      ]),
      _vm._v(" "),
      !_vm.loading
        ? _c("div", {}, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-lg-3 margin-bottom-3x" }, [
                _c("div", { staticClass: "input-group" }, [
                  _c("input", {
                    ref: "search",
                    staticClass: "form-control",
                    attrs: { type: "text", placeholder: "Search for..." },
                    domProps: { value: _vm.searchKey },
                    on: {
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          $event.keyCode !== 13
                        ) {
                          return null
                        }
                        return _vm.search(_vm.$refs.search.value)
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "input-group-btn" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-default",
                        attrs: { type: "button" },
                        on: {
                          click: function($event) {
                            return _vm.search(_vm.$refs.search.value)
                          }
                        }
                      },
                      [_vm._v("Go!")]
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "input-group radio" }, [
                  _c("label", { staticClass: "margin-right-2x" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.type,
                          expression: "type"
                        }
                      ],
                      attrs: { type: "radio", value: "all" },
                      domProps: { checked: _vm._q(_vm.type, "all") },
                      on: {
                        change: [
                          function($event) {
                            _vm.type = "all"
                          },
                          function($event) {
                            return _vm.loadPage(1)
                          }
                        ]
                      }
                    }),
                    _vm._v("\n            All\n          ")
                  ]),
                  _vm._v(" "),
                  _c("label", { staticClass: "margin-right-2x" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.type,
                          expression: "type"
                        }
                      ],
                      attrs: { type: "radio", value: "admin" },
                      domProps: { checked: _vm._q(_vm.type, "admin") },
                      on: {
                        change: [
                          function($event) {
                            _vm.type = "admin"
                          },
                          function($event) {
                            return _vm.loadPage(1)
                          }
                        ]
                      }
                    }),
                    _vm._v("\n            IMS Roles\n          ")
                  ]),
                  _vm._v(" "),
                  _c("label", {}, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.type,
                          expression: "type"
                        }
                      ],
                      attrs: { type: "radio", value: "location" },
                      domProps: { checked: _vm._q(_vm.type, "location") },
                      on: {
                        change: [
                          function($event) {
                            _vm.type = "location"
                          },
                          function($event) {
                            return _vm.loadPage(1)
                          }
                        ]
                      }
                    }),
                    _vm._v("\n            Location Roles\n          ")
                  ])
                ])
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "hasPermits",
                      rawName: "v-hasPermits:allow",
                      value: { permits: ["role_create"] },
                      expression: "{permits: ['role_create']}",
                      arg: "allow"
                    }
                  ],
                  staticClass: "col-lg-9 text-right margin-bottom-3x"
                },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "btn btn-default",
                      attrs: {
                        to: {
                          name: "roles.new"
                        }
                      }
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-plus",
                        attrs: { "aria-hidden": "true" }
                      }),
                      _vm._v("\n          New Role\n        ")
                    ]
                  )
                ],
                1
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.loading
        ? _c("div", { staticClass: "text-center" }, [
            _c("i", { staticClass: "fa fa-spinner fa-spin fa-3x fa-fw" })
          ])
        : _c(
            "div",
            [
              _c("div", { staticClass: "table-responsive" }, [
                _c("table", { staticClass: "table table-striped" }, [
                  _c("thead", [
                    _c("tr", [
                      _c("th", [_vm._v("Role Name")]),
                      _vm._v(" "),
                      _c("th", [_vm._v("Role Type")]),
                      _vm._v(" "),
                      _c(
                        "th",
                        {
                          directives: [
                            {
                              name: "hasPermits",
                              rawName: "v-hasPermits:allow",
                              value: { permits: ["role_list"] },
                              expression: "{permits: ['role_list']}",
                              arg: "allow"
                            }
                          ]
                        },
                        [_vm._v("Edit")]
                      ),
                      _vm._v(" "),
                      _c(
                        "th",
                        {
                          directives: [
                            {
                              name: "hasPermits",
                              rawName: "v-hasPermits:allow",
                              value: { permits: ["role_delete"] },
                              expression: "{permits: ['role_delete']}",
                              arg: "allow"
                            }
                          ],
                          staticClass: "text-right"
                        },
                        [_vm._v("Remove")]
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "tbody",
                    _vm._l(_vm.roles.data, function(item, i) {
                      return _c("tr", [
                        _c("td", [
                          _vm._v(
                            "\n              " + _vm._s(item.display_name)
                          ),
                          _c("br"),
                          _vm._v(" "),
                          _c("small", [_vm._v(_vm._s(item.description))])
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                item.type == "admin"
                                  ? "IMS Role"
                                  : "Location Role"
                              ) +
                              "\n            "
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "td",
                          {
                            directives: [
                              {
                                name: "hasPermits",
                                rawName: "v-hasPermits:allow",
                                value: { permits: ["role_list"] },
                                expression: "{permits: ['role_list']}",
                                arg: "allow"
                              }
                            ]
                          },
                          [
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to: {
                                    name: "roles.edit",
                                    params: { id: item.id }
                                  }
                                }
                              },
                              [
                                _c("i", {
                                  staticClass: "fa fa-pencil",
                                  attrs: { "aria-hidden": "true" }
                                })
                              ]
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          {
                            directives: [
                              {
                                name: "hasPermits",
                                rawName: "v-hasPermits:allow",
                                value: { permits: ["role_delete"] },
                                expression: "{permits: ['role_delete']}",
                                arg: "allow"
                              }
                            ],
                            staticClass: "text-right"
                          },
                          [
                            _vm.roles_loadings[item.id]
                              ? _c("span", [
                                  _c("i", {
                                    staticClass: "fa fa-spinner fa-spin fa-fw"
                                  })
                                ])
                              : _c(
                                  "a",
                                  {
                                    staticClass: "text-danger",
                                    attrs: { href: "#" },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        return _vm.removeRole(item)
                                      }
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "fa fa-times",
                                      attrs: { "aria-hidden": "true" }
                                    })
                                  ]
                                )
                          ]
                        )
                      ])
                    }),
                    0
                  )
                ])
              ]),
              _vm._v(" "),
              _c("pagination", {
                attrs: { "pages-number": _vm.roles.last_page },
                on: {
                  pageChanged: function(page) {
                    return _vm.loadPage(page)
                  }
                }
              })
            ],
            1
          ),
      _vm._v(" "),
      _c("confirm", { ref: "deleteConfirm", attrs: { type: "danger" } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-1fd7ddfb", { render: render, staticRenderFns: staticRenderFns })
  }
}