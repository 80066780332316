import toastr from 'toastr'
import { funcModal } from 'components/modals'

const components = {
    funcModal
}

const filters = {
}

const computed = {
}

const methods = {
    open (reservation, activeHistory) {
        this.reservation = reservation;
        this.activeHistory = activeHistory;
        this.$refs.modal.open();
    },

    onClose () {
        this.resetValues();
        this.$emit('close');
    },
    submit () {
        if (this.refund_type == '') {
            toastr.error('Please Select Refund Type');
            return;
        }
        if (this.refund_type == 'partial_refund' && this.amount == '') {
            toastr.error('Please Enter Refund Amount');
            return;
        }

        this.processing = true;
        this.errors = {};

        this.$store.dispatch('dashboards.cancelReservation', {
            channel_id: this.reservation.channel_id,
            reservation_id: this.reservation.id,
            refund_type: this.refund_type,
            amount: this.amount,
          }).then(() => {
            this.processing=false;
            let errors = _.cloneDeep(this.$store.state.dashboards.errors);
            if (errors) {
              this.errors = errors.errors;
              toastr.error(errors.message);
            } else {
              let messsage = `Reservation has been cancelled successfully.`
              toastr.success(messsage);
              this.resetValues();
              this.$refs.modal.close();
            }
          })
    },

    resetValues () {
        this.amount = '';
        this.refund_type = '';
        this.errors = {};
    }
}

export default {
    name: 'cancel-reservation-modal',
    methods,
    computed,
    components,
    filters,
    data () {
        return {
            errors: {},
            reservation: null,
            activeHistory: null,
            processing: false,
            refund_type: '',
            amount: '',
        }
    }
}