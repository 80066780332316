import {all as allUsers, new as newUser, edit as editUser} from 'pages/users'

export default [
  //Users
  { path: '/users', name: 'users.all', component: allUsers
    , meta:{permits:['user_list']}},
  { path: '/users/new', name: 'users.new', component: newUser
    , meta:{permits:['user_create']}},
  { path: '/users/edit/:id', name: 'users.edit', component: editUser
    , meta:{permits:['user_list']}},
]
