var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    [
      _c("label", [_vm._v("Type Name")]),
      _vm._v(" "),
      _c("div", { staticClass: "input-group" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.formData.name,
              expression: "formData.name"
            }
          ],
          ref: "",
          staticClass: "form-control",
          attrs: {
            type: "text",
            placeholder: "New Type Name",
            "aria-describedby": "basic-addon1"
          },
          domProps: { value: _vm.formData.name },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.formData, "name", $event.target.value)
            }
          }
        })
      ]),
      _vm._v(" "),
      _vm.errors.name
        ? _c("div", { staticClass: "text-danger" }, [
            _vm._v("\n    " + _vm._s(_vm.errors.name[0]) + "\n  ")
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("label", [_vm._v("Standard Occupancy")]),
      _vm._v(" "),
      _c("div", { staticClass: "input-group" }, [
        _vm._m(1),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.formData.standard_occupancy,
              expression: "formData.standard_occupancy"
            }
          ],
          ref: "",
          staticClass: "form-control",
          attrs: {
            type: "number",
            placeholder: "How many persons can normally occupy the room",
            "aria-describedby": "basic-addon1"
          },
          domProps: { value: _vm.formData.standard_occupancy },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.formData, "standard_occupancy", $event.target.value)
            }
          }
        })
      ]),
      _vm._v(" "),
      _vm.errors.standard_occupancy
        ? _c("div", { staticClass: "text-danger" }, [
            _vm._v("\n    " + _vm._s(_vm.errors.standard_occupancy[0]) + "\n  ")
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("label", [_vm._v("Maximum Occupancy")]),
      _vm._v(" "),
      _c("div", { staticClass: "input-group" }, [
        _vm._m(2),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.formData.max_occupancy,
              expression: "formData.max_occupancy"
            }
          ],
          ref: "",
          staticClass: "form-control",
          attrs: {
            type: "number",
            placeholder:
              "The maximum number of persons that can occupy the room",
            "aria-describedby": "basic-addon1"
          },
          domProps: { value: _vm.formData.max_occupancy },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.formData, "max_occupancy", $event.target.value)
            }
          }
        })
      ]),
      _vm._v(" "),
      _vm.errors.max_occupancy
        ? _c("div", { staticClass: "text-danger" }, [
            _vm._v("\n    " + _vm._s(_vm.errors.max_occupancy[0]) + "\n  ")
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c(
        "label",
        {
          directives: [
            {
              name: "hasPermits",
              rawName: "v-hasPermits:allow",
              value: ["super_admin"],
              expression: "['super_admin']",
              arg: "allow"
            }
          ]
        },
        [_vm._v("Price Limit")]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "hasPermits",
              rawName: "v-hasPermits:allow",
              value: ["super_admin"],
              expression: "['super_admin']",
              arg: "allow"
            }
          ],
          staticClass: "input-group"
        },
        [
          _vm._m(3),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.formData.price_limit,
                expression: "formData.price_limit"
              }
            ],
            ref: "",
            staticClass: "form-control",
            attrs: {
              type: "number",
              placeholder: "Price Limit",
              "aria-describedby": "basic-addon1"
            },
            domProps: { value: _vm.formData.price_limit },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.formData, "price_limit", $event.target.value)
              }
            }
          })
        ]
      ),
      _vm._v(" "),
      _vm.errors.price_limit
        ? _c("div", { staticClass: "text-danger" }, [
            _vm._v("\n    " + _vm._s(_vm.errors.price_limit[0]) + "\n  ")
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("label", [_vm._v("Price of Each Extra Occupant (if applicable)")]),
      _vm._v(" "),
      _c("div", { staticClass: "input-group" }, [
        _vm._m(4),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.formData.extra_occupant_price,
              expression: "formData.extra_occupant_price"
            }
          ],
          staticClass: "form-control",
          class: {
            readonly:
              _vm.formData.max_occupancy <= _vm.formData.standard_occupancy
          },
          attrs: {
            type: "number",
            readonly:
              _vm.formData.max_occupancy <= _vm.formData.standard_occupancy
          },
          domProps: { value: _vm.formData.extra_occupant_price },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(
                _vm.formData,
                "extra_occupant_price",
                $event.target.value
              )
            }
          }
        })
      ]),
      _vm._v(" "),
      _vm.errors.extra_occupant_price
        ? _c("div", { staticClass: "text-danger" }, [
            _vm._v(
              "\n    " + _vm._s(_vm.errors.extra_occupant_price[0]) + "\n  "
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("label", [_vm._v("Beds In The Room")]),
      _vm._v(" "),
      _vm._l(_vm.formData.beds, function(bedType, i) {
        return _c("div", { key: bedType.id, staticClass: "row" }, [
          _c("div", { staticClass: "col-sm-7" }, [
            _c("h5", [_vm._v("Bed Type")]),
            _vm._v(" "),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: bedType.id,
                    expression: "bedType.id"
                  }
                ],
                staticClass: "form-control",
                on: {
                  change: function($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function(o) {
                        return o.selected
                      })
                      .map(function(o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.$set(
                      bedType,
                      "id",
                      $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                    )
                  }
                }
              },
              [
                _c("option", { domProps: { value: null } }, [
                  _vm._v("select bed type")
                ]),
                _vm._v(" "),
                _vm._l(_vm.bedTypes, function(type) {
                  return _c(
                    "option",
                    { key: type.id, domProps: { value: type.id } },
                    [_vm._v(_vm._s(type.name))]
                  )
                })
              ],
              2
            ),
            _vm._v(" "),
            _vm.errors["beds." + i + ".number_of_beds"]
              ? _c("div", { staticClass: "text-danger" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.errors["beds." + i + ".number_of_beds"][0]) +
                      "\n      "
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.errors["beds." + i + ".id"]
              ? _c("div", { staticClass: "text-danger" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.errors["beds." + i + ".id"][0]) +
                      "\n      "
                  )
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-5" }, [
            _c("div", {}, [
              _c("h5", { staticClass: "pull-left" }, [
                _vm._v("Number of Beds")
              ]),
              _vm._v(" "),
              i > 0
                ? _c(
                    "a",
                    {
                      staticClass: "text-danger pull-right margin-top-2x",
                      attrs: { href: "#" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.removeBedType(i)
                        }
                      }
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-times",
                        attrs: { "aria-hidden": "true" }
                      })
                    ]
                  )
                : _vm._e()
            ]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: bedType.number_of_beds,
                  expression: "bedType.number_of_beds"
                }
              ],
              ref: "",
              refInFor: true,
              staticClass: "form-control",
              attrs: {
                type: "number",
                min: "1",
                placeholder: "Number Of Beds",
                "aria-describedby": "basic-addon1"
              },
              domProps: { value: bedType.number_of_beds },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(bedType, "number_of_beds", $event.target.value)
                }
              }
            })
          ])
        ])
      }),
      _vm._v(" "),
      _vm.errors.beds
        ? _c("div", { staticClass: "text-danger" }, [
            _vm._v("\n    " + _vm._s(_vm.errors.beds[0]) + "\n  ")
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "text-right margin-top-2x" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-default",
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.addBedType.apply(null, arguments)
              }
            }
          },
          [
            _c("i", {
              staticClass: "fa fa-plus",
              attrs: { "aria-hidden": "true" }
            }),
            _vm._v("\n      Add Bed Type\n    ")
          ]
        )
      ]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("label", [_vm._v("Number Of Available Rooms")]),
      _vm._v(" "),
      _c("div", { staticClass: "input-group" }, [
        _vm._m(5),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.formData.number_of_rooms,
              expression: "formData.number_of_rooms"
            }
          ],
          ref: "",
          staticClass: "form-control",
          attrs: {
            type: "text",
            placeholder: "Number of rooms",
            "aria-describedby": "basic-addon1"
          },
          domProps: { value: _vm.formData.number_of_rooms },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.formData, "number_of_rooms", $event.target.value)
            }
          }
        })
      ]),
      _vm._v(" "),
      _vm.errors.number_of_rooms
        ? _c("div", { staticClass: "text-danger" }, [
            _vm._v("\n    " + _vm._s(_vm.errors.number_of_rooms[0]) + "\n  ")
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("div", { staticClass: "form-group" }, [
        _c("label", [_vm._v("Description")]),
        _vm._v(" "),
        _c("textarea", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.formData.description,
              expression: "formData.description"
            }
          ],
          ref: "",
          staticClass: "form-control",
          attrs: {
            type: "text",
            placeholder: "Room description...",
            "aria-describedby": "basic-addon1"
          },
          domProps: { value: _vm.formData.description },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.formData, "description", $event.target.value)
            }
          }
        }),
        _vm._v(" "),
        _vm.errors.description
          ? _c("div", { staticClass: "text-danger" }, [
              _vm._v("\n      " + _vm._s(_vm.errors.description[0]) + "\n    ")
            ])
          : _vm._e()
      ])
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      { staticClass: "input-group-addon", attrs: { id: "basic-addon1" } },
      [
        _c("i", {
          staticClass: "fa fa-text-width",
          attrs: { "aria-hidden": "true" }
        })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      { staticClass: "input-group-addon", attrs: { id: "basic-addon1" } },
      [
        _c("i", {
          staticClass: "fa fa-user-circle",
          attrs: { "aria-hidden": "true" }
        })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      { staticClass: "input-group-addon", attrs: { id: "basic-addon1" } },
      [
        _c("i", {
          staticClass: "fa fa-user-circle",
          attrs: { "aria-hidden": "true" }
        })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      { staticClass: "input-group-addon", attrs: { id: "basic-addon1" } },
      [_c("i", { staticClass: "fa fa-usd", attrs: { "aria-hidden": "true" } })]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      { staticClass: "input-group-addon", attrs: { id: "basic-addon1" } },
      [_c("i", { staticClass: "fa fa-usd", attrs: { "aria-hidden": "true" } })]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      { staticClass: "input-group-addon", attrs: { id: "basic-addon1" } },
      [
        _c("i", {
          staticClass: "fa fa-hashtag",
          attrs: { "aria-hidden": "true" }
        })
      ]
    )
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-b614fefc", { render: render, staticRenderFns: staticRenderFns })
  }
}