// initial state
const state = () => ({
  active: 'home'
})

// getters
const getters = {
  // reversedMessage: state => state.message.reverse()
}

// actions
const actions = {
  ['nav.changeRoute'] ({ commit }, route) {
    commit('UPDATE_NAV', route );
  }
}

// mutations
const mutations = {
  UPDATE_NAV (state, route) {
    console.log('dispatched', state, route);
    state.active = route;
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
