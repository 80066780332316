import { user } from 'services'

export function beforeRouteUpdateMixin () {
  return {
    beforeRouteUpdate (to, from, next) {
      const { asyncData } = this.$options
      console.log('beforeRouteUpdate', this.$options);

      if (asyncData) {
        asyncData({
          app: this.$root,
          router: this.$router,
          store: this.$store,
          route: to
        }).then(() => {
          next()
        }).catch((e) => {
          next(e)
        })
      } else {
        next()
      }
    }
  }
}

export function beforeRouteEnterMixin () {
  return {
    beforeCreate () {
      let route = null;
      if (this.$route && this.$route.name != 'login') {
        route =  this.$route.fullPath;
      }

      try {
        if (!user.getToken() && !this.$route.meta.public) {
          console.log('test user.getToken()', user.getToken());
          console.log('route fullpath 0000', route);
          this.$router.replace({
            name: 'login',
            query: {
              goto: route
            }
          });
        }
      } catch (e) {

      }

    }
  }
}

export function lodash () {
  return {
    computed: {
      _ () {
        return _;
      }
    }
  };
}
