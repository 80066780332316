import { http } from 'services'
import layout from 'layouts/default'
import datatable from 'components/datatable'
import { hasPermits } from 'directives'


const directives = {
  hasPermits
}

const components = {
  layout,
  datatable
}

const methods = {
  search (searchKey) {
    this.filters._q = searchKey;
    this.$nextTick(() => {
      this.$refs.table.loadPage(1);
    })
  },

  download() {
    http.get('refund-requests', {
      params: {
        download: true,
        status: this.filters.status
      },
      responseType: 'arraybuffer'
    }).then((res) => {
      const blob = new Blob([res.data], { type: `application/csv; charset=UTF-8` });
      blob.name = 'refund_requests.csv';
      const reader = new FileReader();
      reader.onload = e => {
        const anchor = document.createElement('a');
        anchor.style.display = 'none';
        anchor.href = e.target.result;
        anchor.download = blob.name;
        anchor.click();
      };
      reader.readAsDataURL(blob);
    });
  },

  setCols () {
    this.columns = [
      {name: "ID", object_name: 'id'},
      {name: "Reservation #", object_name: 'reservation_number'},
      {name: "Date", object_name: 'date', sortable: true, sort_key: "created_at"},
      {name: "Channel", object_name: 'channel'},
      {name: "Location", object_name: 'location'},
      {name: "Refund Amount", object_name: 'amount'},
      {name: "Deadline", object_name: 'deadline'},
      {name: "Status",object_name:(obj, parent) => {
        let i, frags = obj.status.split('_');
        for (i=0; i<frags.length; i++) {
          frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
        }
        return frags.join(' ');
      }},
      {
        name: "View",
        object_name:(obj, parent) => {
          return `<a class="" href="/refund-requests/${obj.id}" ><i class="fa fa-eye" aria-hidden="true"></i></a>`;
        },
        classes: ['clickable'],
        click: (data) => {
          let query_params = {};
          if (this.$refs.table.sort_by) {
            query_params['_sort'] = (this.$refs.table.sort_dir == 'desc' ? '-' : '') + this.$refs.table.sort_by;
          }

          if (this.$refs.table.filters) {
            _.each(this.$refs.table.filters, (value, key) => {
              if (value)
                query_params[key] = value;
            });
          }

          this.$router.push({
            name: 'refundRequests.edit',
            params: {
               id: data.id
            },
            query: query_params
          });
        }
      },
    ]
  }
}

const computed = {
  url () {
    var url = 'refund-requests';
    return url;
  },
}

export default {
  name: 'all-refund-requests',
  components,
  methods,
  computed,
  directives,
  data () {
    return {
      download_processing: false,
      filters: {
        status: 'all',
        _q: '',
      },
      columns: [],
      searchKey: null
    }
  },
  created () {
  },
  
  mounted () {
    this.setCols();
}

}
