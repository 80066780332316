import toastr from 'toastr'
import layout from 'layouts/default'

const components = {
  layout
}

const methods = {
  submit () {
    this.errors = {}
    this.processing = true;
    this.$store.dispatch('vendors.newVendor', this.form)
    .then(() => {
      this.processing = false;
      let errors = this.$store.state.vendors.errors;
      if (errors) {
        if (errors.errors) {
          this.errors = _.cloneDeep(errors.errors);
        }        
        toastr.error(errors.message);
      } else {
        this.$router.replace({name: 'vendors.all'});
      }
    });
  }
}

export default {
  name: 'new-vendor',
  components,
  methods,
  data () {
    return {
      form: {
        name: null,
      },
      loading: false,
      processing: false,
      errors: {}
    }
  },
}
