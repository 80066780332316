var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "func-modal",
    {
      ref: "modal",
      attrs: { title: "Copy Fees" },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function(props) {
            return [
              _c(
                "button",
                {
                  directives: [
                    {
                      name: "hasPermits",
                      rawName: "v-hasPermits:allow",
                      value: ["channelFee_update"],
                      expression: "['channelFee_update']",
                      arg: "allow"
                    }
                  ],
                  staticClass: "btn btn-primary",
                  class: { disabled: _vm.processing || _vm.channalsLoading },
                  attrs: { disabled: _vm.processing || _vm.channalsLoading },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.submit(props)
                    }
                  }
                },
                [
                  _vm.processing
                    ? _c("span", [
                        _c("i", { staticClass: "fa fa-spin fa-spinner" }),
                        _vm._v("\n        Saving...\n      ")
                      ])
                    : _c("span", [_vm._v("\n        Save\n      ")])
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-default",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return props.close()
                    }
                  }
                },
                [_vm._v("Close")]
              )
            ]
          }
        }
      ])
    },
    [
      _c("template", { slot: "body" }, [
        _vm.channalsLoading
          ? _c("div", { staticClass: "text-center" }, [
              _c("i", { staticClass: "fa fa-spinner fa-spin fa-3x fa-fw" })
            ])
          : _c(
              "div",
              [
                _c("div", { staticClass: "form-group margin-top-3x" }, [
                  _c("label", [_vm._v("Locations")]),
                  _vm._v(" "),
                  _c("h5", [
                    _vm._v(
                      "Please select the channel that you want to copy fees from"
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("s-select", {
                  attrs: {
                    selected: _vm.selectedChannel,
                    options: _vm.channelsList,
                    multiple: false
                  },
                  on: { selectedChanged: _vm.onChangeSelectedChannel }
                })
              ],
              1
            )
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-496d787b", { render: render, staticRenderFns: staticRenderFns })
  }
}