import toastr from 'toastr'
import { confirm } from 'components/modals'
import { hasPermits } from 'directives'
// import './details.scss'

const directives = {
  hasPermits
}

const components = {
  confirm
}

const computed = {
  cancellationPolicies () {
    return _.cloneDeep(this.$store.state.locations.active.cancellationPolicies);
  }
}

const methods = {
  removePolicy (id) {
    this.$refs.confirmDelete.confirm('Are you sure?', () => {
      this.$set(this.loadings, id, true);

      this.$store.dispatch('locations.removePolicy', {
        locId: this.$route.params.id,
        id
      }).then(() => {
        this.$set(this.loadings, id, false);
        let errors;
        if (errors = this.$store.state.locations.errors) {
          console.log('errors', errors);
          _.forEach(errors.errors, (error) => {
            toastr.error(error[0]);
          })
          toastr.error(errors.message);
        } else {
          toastr.success('data has been removed successfully');
        }
      });
    })
  },

  addMoreFields () {
    this.policies_to_submit.push({
      number_of_days: null,
      percentage: null,
      type: 'room'
    });
  },

  removeFields (i, e) {
    e.preventDefault();
    this.policies_to_submit.splice(i, 1);
  },

  submit () {
    this.processing = true;
    this.errors = {};
    this.$store.dispatch('locations.addPolicies', {
      id: this.$route.params.id,
      data: {data: this.policies_to_submit}
    })
    .then(() => {
      this.processing = false;
      // console.log('active discount', this.$store.state.locations);
      let errors;
      if (errors = this.$store.state.locations.errors) {
        this.errors = _.cloneDeep(errors.errors);

        toastr.error(errors.message);
      } else {
        toastr.success('Data has been saved');
        this.policies_to_submit = [
          {
            number_of_days: null,
            percentage: null,
            type: 'room',
          }
        ]
      }
    });
  }
}

export default {
  name: "cancellation-policy",
  computed,
  methods,
  components,
  directives,
  data () {
    return {
      locId: this.$route.params.id,
      errors: {},
      processing: false,
      loadings: {},
      formActive: false,
      policies_to_submit: [
        {
          number_of_days: null,
          percentage: null,
          type: 'room',
        }
      ]
    }
  }
}
