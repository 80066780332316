var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {}, [
    _c("div", { staticClass: "text-right" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-default",
          on: {
            click: function($event) {
              $event.preventDefault()
              _vm.formActive = !_vm.formActive
            }
          }
        },
        [
          !_vm.formActive
            ? _c("i", {
                staticClass: "fa fa-caret-down",
                attrs: { "aria-hidden": "true" }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.formActive
            ? _c("i", {
                staticClass: "fa fa-caret-up",
                attrs: { "aria-hidden": "true" }
              })
            : _vm._e(),
          _vm._v("\n      Add a Channel\n    ")
        ]
      )
    ]),
    _vm._v(" "),
    _vm.formActive
      ? _c("div", { staticClass: "margin-top-3x text-left" }, [
          _c("div", { staticClass: "panel panel-default" }, [
            _c("div", { staticClass: "panel-body" }, [
              _c("div", { staticClass: "box" }, [
                _c("div", { staticClass: "box-header with-border" }, [
                  _c("h5", [_vm._v("Type a channel name")]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.search,
                        expression: "search"
                      }
                    ],
                    staticClass: "form-control margin-bottom-2x",
                    attrs: { type: "text", placeholder: "Search channel.." },
                    domProps: { value: _vm.search },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.search = $event.target.value
                      }
                    }
                  })
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "box-body" },
                  [
                    _c("div", [
                      _c(
                        "a",
                        {
                          attrs: { href: "#" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.selectAll("filteredList")
                            }
                          }
                        },
                        [_vm._v("All")]
                      ),
                      _vm._v("  \n              "),
                      _c(
                        "a",
                        {
                          attrs: { href: "#" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.selectNone("filteredList")
                            }
                          }
                        },
                        [_vm._v("None")]
                      )
                    ]),
                    _vm._v(" "),
                    _vm._l(_vm.filteredList, function(channel) {
                      return _c("div", { staticClass: "checkbox" }, [
                        _c("label", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.selected_channels,
                                expression: "selected_channels"
                              }
                            ],
                            attrs: { type: "checkbox" },
                            domProps: {
                              value: channel.id,
                              checked: Array.isArray(_vm.selected_channels)
                                ? _vm._i(_vm.selected_channels, channel.id) > -1
                                : _vm.selected_channels
                            },
                            on: {
                              change: function($event) {
                                var $$a = _vm.selected_channels,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = channel.id,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      (_vm.selected_channels = $$a.concat([
                                        $$v
                                      ]))
                                  } else {
                                    $$i > -1 &&
                                      (_vm.selected_channels = $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1)))
                                  }
                                } else {
                                  _vm.selected_channels = $$c
                                }
                              }
                            }
                          }),
                          _vm._v(
                            "\n                " +
                              _vm._s(channel.name) +
                              "\n              "
                          )
                        ])
                      ])
                    })
                  ],
                  2
                )
              ]),
              _vm._v(" "),
              _vm.selected_channels && _vm.selected_channels.length
                ? _c("div", [
                    _c("div", [
                      _c("label", [
                        _vm._v("Copy Commissions and Rates From (optional):")
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "table-responsive" }, [
                        _c("table", { staticClass: "table table-bordered" }, [
                          _c(
                            "tbody",
                            _vm._l(_vm.channels, function(channel) {
                              return _c("tr", { key: channel.id }, [
                                _c("td", [
                                  _c("div", { staticClass: "checkbox" }, [
                                    _c(
                                      "label",
                                      {
                                        attrs: { for: "" },
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                            return _vm.onCopyRatesSelected(
                                              channel
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("input", {
                                          attrs: { type: "checkbox" },
                                          domProps: {
                                            checked:
                                              _vm.copy_rates_from.id ==
                                              channel.id,
                                            value: channel.id
                                          }
                                        }),
                                        _vm._v(
                                          "\n                          " +
                                            _vm._s(channel.channel.name) +
                                            " - " +
                                            _vm._s(channel.channel.domain) +
                                            "\n                        "
                                        )
                                      ]
                                    )
                                  ])
                                ])
                              ])
                            }),
                            0
                          )
                        ])
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "text-right margin-top-3x" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-success",
                          class: { disabled: _vm.processing },
                          attrs: { disabled: _vm.processing },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.submit()
                            }
                          }
                        },
                        [
                          _vm.processing
                            ? _c("span", [
                                _c("i", {
                                  staticClass: "fa fa-spinner fa-spin"
                                })
                              ])
                            : _vm._e(),
                          _vm._v("\n              SAVE\n            ")
                        ]
                      )
                    ])
                  ])
                : _vm._e()
            ])
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-cd0ebcf4", { render: render, staticRenderFns: staticRenderFns })
  }
}