var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "layout",
    { attrs: { "no-box": true, title: _vm.location ? _vm.location.name : "" } },
    [
      _vm.loading
        ? _c("div", { staticClass: "text-center" }, [
            _c("i", { staticClass: "fa fa-spinner fa-spin fa-3x fa-fw" })
          ])
        : _c("div", { staticClass: "row margin-top-5x" }, [
            _c("div", { staticClass: "col-sm-6" }, [
              _c("div", { staticClass: "box" }, [
                _c("div", { staticClass: "box-header with-border" }, [
                  _c("h4", [_vm._v("General")])
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "box-body" },
                  [
                    _c("general-tab", {
                      attrs: {
                        form: _vm.form,
                        currencies: _vm.currencies,
                        errors: _vm.errors
                      },
                      on: {
                        "update:form": function($event) {
                          _vm.form = $event
                        }
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "hasPermits",
                        rawName: "v-hasPermits:allow",
                        value: {
                          id: _vm.location.id,
                          permits: ["location_update"]
                        },
                        expression:
                          "{id: location.id, permits: ['location_update']}",
                        arg: "allow"
                      }
                    ],
                    staticClass: "box-footer text-right"
                  },
                  [
                    _c(
                      "button",
                      {
                        directives: [
                          {
                            name: "hasPermits",
                            rawName: "v-hasPermits:allow",
                            value: {
                              id: _vm.location.id,
                              permits: ["location_booking_conditions_update"]
                            },
                            expression:
                              "{id: location.id, permits: ['location_booking_conditions_update']}",
                            arg: "allow"
                          }
                        ],
                        staticClass: "btn btn-success",
                        class: { disabled: _vm.submit_loading },
                        attrs: { disabled: _vm.submit_loading || _vm.disabled },
                        on: { click: _vm.submitEditLocation }
                      },
                      [
                        _vm.submit_loading
                          ? _c("span", [
                              _c("i", {
                                staticClass: "fa fa-spinner fa-spin fa-fw"
                              }),
                              _vm._v("\n              SAVING...\n            ")
                            ])
                          : _c("span", [
                              _vm._v("\n              SAVE\n            ")
                            ])
                      ]
                    )
                  ]
                )
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "hasPermits",
                      rawName: "v-hasPermits:allow",
                      value: {
                        id: _vm.location.id,
                        permits: ["location_channel_list", "channel_user"]
                      },
                      expression:
                        "{id: location.id, permits: ['location_channel_list', 'channel_user']}",
                      arg: "allow"
                    }
                  ],
                  staticClass: "box"
                },
                [
                  _c("div", { staticClass: "box-header with-border" }, [
                    _c("h4", [_vm._v("Commissions")])
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "box-body" },
                    [
                      _c("commissions", {
                        attrs: { form: _vm.form, errors: _vm.errors },
                        on: {
                          "update:form": function($event) {
                            _vm.form = $event
                          },
                          throwErrors: _vm.displayErrors
                        }
                      })
                    ],
                    1
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "hasPermits",
                      rawName: "v-hasPermits:allow",
                      value: ["super_admin"],
                      expression: "['super_admin']",
                      arg: "allow"
                    }
                  ],
                  staticClass: "box"
                },
                [
                  _c("div", { staticClass: "box-header with-border" }, [
                    _c("h4", [_vm._v("Coupons")])
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "box-body" },
                    [
                      _c("coupons", {
                        attrs: { form: _vm.form, errors: _vm.errors },
                        on: {
                          "update:form": function($event) {
                            _vm.form = $event
                          },
                          throwErrors: _vm.displayErrors
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "box-footer text-right" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-success",
                        class: { disabled: _vm.submit_loading },
                        attrs: { disabled: _vm.submit_loading },
                        on: { click: _vm.submitEditLocation }
                      },
                      [
                        _vm.submit_loading
                          ? _c("span", [
                              _c("i", {
                                staticClass: "fa fa-spinner fa-spin fa-fw"
                              }),
                              _vm._v("\n              SAVING...\n            ")
                            ])
                          : _c("span", [
                              _vm._v("\n              SAVE\n            ")
                            ])
                      ]
                    )
                  ])
                ]
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-sm-6" }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "hasPermits",
                      rawName: "v-hasPermits:allow",
                      value: {
                        id: _vm.location.id,
                        permits: ["locationFee_list"]
                      },
                      expression:
                        "{id: location.id, permits: ['locationFee_list']}",
                      arg: "allow"
                    }
                  ],
                  staticClass: "box"
                },
                [
                  _c("div", { staticClass: "box-header with-border" }, [
                    _c("h4", [_vm._v("Fees")])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "box-body" }, [_c("fees")], 1)
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "hasPermits",
                      rawName: "v-hasPermits:allow",
                      value: {
                        id: _vm.location.id,
                        permits: ["cancellationPolicy_list"]
                      },
                      expression:
                        "{id: location.id, permits: ['cancellationPolicy_list']}",
                      arg: "allow"
                    }
                  ],
                  staticClass: "box"
                },
                [
                  _c("div", { staticClass: "box-header with-border" }, [
                    _c("h4", [_vm._v("Cancellation Policy")])
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "box-body" },
                    [_c("cancellation-policy")],
                    1
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "hasPermits",
                      rawName: "v-hasPermits:allow",
                      value: {
                        id: _vm.location.id,
                        permits: ["extraFields_list"]
                      },
                      expression:
                        "{id: location.id, permits: ['extraFields_list']}",
                      arg: "allow"
                    }
                  ],
                  staticClass: "box"
                },
                [
                  _c("div", { staticClass: "box-header with-border" }, [
                    _c("h4", [_vm._v("Extra Required Fields")])
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "box-body" },
                    [_c("extra-fields")],
                    1
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "hasPermits",
                      rawName: "v-hasPermits:allow",
                      value: {
                        id: _vm.location.id,
                        permits: ["carTypesStructure_list"]
                      },
                      expression:
                        "{id: location.id, permits: ['carTypesStructure_list']}",
                      arg: "allow"
                    }
                  ],
                  staticClass: "box"
                },
                [
                  _c("div", { staticClass: "box-header with-border" }, [
                    _c("h4", [_vm._v("Reservation Types")])
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "box-body" },
                    [_c("parking-types-car-structure")],
                    1
                  )
                ]
              )
            ])
          ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-5ad213a2", { render: render, staticRenderFns: staticRenderFns })
  }
}