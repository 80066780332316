var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-modal",
    {
      attrs: {
        label: "New Parking Type",
        title: "Add New Parkings Type",
        "btn-class": "btn-primary",
        "modal-class": "text-left"
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function(props) {
            return [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.submit(props)
                    }
                  }
                },
                [_vm._v("Save")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-default",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return props.close()
                    }
                  }
                },
                [_vm._v("Close")]
              )
            ]
          }
        }
      ])
    },
    [
      _c("template", { slot: "body" }, [
        _c(
          "form",
          {
            on: {
              submit: function($event) {
                $event.preventDefault()
                return _vm.onFormSubmit.apply(null, arguments)
              }
            }
          },
          [
            _c("label", [_vm._v("Select Parking Type")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "input-group" },
              [
                _c(
                  "span",
                  {
                    staticClass: "input-group-addon",
                    attrs: { id: "basic-addon1" }
                  },
                  [
                    _c("i", {
                      staticClass: "fa fa-hashtag",
                      attrs: { "aria-hidden": "true" }
                    })
                  ]
                ),
                _vm._v(" "),
                _c("s-select", {
                  attrs: { selected: _vm.form.type, options: _vm.abstract },
                  on: {
                    selectedChanged: function(n) {
                      return (_vm.form.type = n)
                    }
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _vm.errors.type
              ? _c("div", { staticClass: "text-danger" }, [
                  _vm._v("\n      " + _vm._s(_vm.errors.type[0]) + "\n    ")
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c("label", [_vm._v("Type Name")]),
            _vm._v(" "),
            _c("div", { staticClass: "input-group" }, [
              _c(
                "span",
                {
                  staticClass: "input-group-addon",
                  attrs: { id: "basic-addon1" }
                },
                [
                  _c("i", {
                    staticClass: "fa fa-bed",
                    attrs: { "aria-hidden": "true" }
                  })
                ]
              ),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.number",
                    value: _vm.form.name,
                    expression: "form.name",
                    modifiers: { number: true }
                  }
                ],
                ref: "",
                staticClass: "form-control",
                attrs: {
                  type: "text",
                  placeholder: "Type Name",
                  "aria-describedby": "basic-addon1"
                },
                domProps: { value: _vm.form.name },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.form, "name", _vm._n($event.target.value))
                  },
                  blur: function($event) {
                    return _vm.$forceUpdate()
                  }
                }
              })
            ]),
            _vm._v(" "),
            _vm.errors.name
              ? _c("div", { staticClass: "text-danger" }, [
                  _vm._v("\n      " + _vm._s(_vm.errors.name[0]) + "\n    ")
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c(
              "label",
              {
                directives: [
                  {
                    name: "hasPermits",
                    rawName: "v-hasPermits:allow",
                    value: ["super_admin"],
                    expression: "['super_admin']",
                    arg: "allow"
                  }
                ]
              },
              [_vm._v("Price Limit")]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "hasPermits",
                    rawName: "v-hasPermits:allow",
                    value: ["super_admin"],
                    expression: "['super_admin']",
                    arg: "allow"
                  }
                ],
                staticClass: "input-group"
              },
              [
                _c(
                  "span",
                  {
                    staticClass: "input-group-addon",
                    attrs: { id: "basic-addon1" }
                  },
                  [
                    _c("i", {
                      staticClass: "fa fa-usd",
                      attrs: { "aria-hidden": "true" }
                    })
                  ]
                ),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.number",
                      value: _vm.form.price_limit,
                      expression: "form.price_limit",
                      modifiers: { number: true }
                    }
                  ],
                  ref: "",
                  staticClass: "form-control",
                  attrs: {
                    type: "text",
                    placeholder: "Price limit",
                    "aria-describedby": "basic-addon1"
                  },
                  domProps: { value: _vm.form.price_limit },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.form,
                        "price_limit",
                        _vm._n($event.target.value)
                      )
                    },
                    blur: function($event) {
                      return _vm.$forceUpdate()
                    }
                  }
                })
              ]
            ),
            _vm._v(" "),
            _vm.errors.price_limit
              ? _c("div", { staticClass: "text-danger" }, [
                  _vm._v(
                    "\n      " + _vm._s(_vm.errors.price_limit[0]) + "\n    "
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c("label", [_vm._v("Number Of Available Parkings")]),
            _vm._v(" "),
            _c("div", { staticClass: "input-group" }, [
              _c(
                "span",
                {
                  staticClass: "input-group-addon",
                  attrs: { id: "basic-addon1" }
                },
                [
                  _c("i", {
                    staticClass: "fa fa-bed",
                    attrs: { "aria-hidden": "true" }
                  })
                ]
              ),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.number",
                    value: _vm.form.number_of_parkings,
                    expression: "form.number_of_parkings",
                    modifiers: { number: true }
                  }
                ],
                ref: "",
                staticClass: "form-control",
                attrs: {
                  type: "text",
                  placeholder: "Number of spots",
                  "aria-describedby": "basic-addon1"
                },
                domProps: { value: _vm.form.number_of_parkings },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.form,
                      "number_of_parkings",
                      _vm._n($event.target.value)
                    )
                  },
                  blur: function($event) {
                    return _vm.$forceUpdate()
                  }
                }
              })
            ]),
            _vm._v(" "),
            _vm.errors.number_of_parkings
              ? _c("div", { staticClass: "text-danger" }, [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.errors.number_of_parkings[0]) +
                      "\n    "
                  )
                ])
              : _vm._e()
          ]
        )
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-4cfbf4ec", { render: render, staticRenderFns: staticRenderFns })
  }
}