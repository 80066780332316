import toastr from "toastr";
import sSelect from "components/sSelect";

const components = {
  sSelect,
}

const computed = {
  channelLocations() {
    return _.cloneDeep(this.$store.state.channels.active.locations.data);
  },

  allChannels () {
    return _.cloneDeep(this.$store.state.channels.all);
  },

}

const methods = {
  submit() {
    this.processing = true;

    this.$store.dispatch("channels.updateLocations", {
        id: this.$route.params.id,
        data: {
          locations : this.selectedLocations,
          copy_rates_from: this.copy_rates_from.value,
          with_commission: true,
        },
      })
      .then(() => {
        let errors;
        if ((errors = this.$store.state.channels.errors)) {
          this.processing = false;
          console.log("errors", errors);
          _.forEach(errors.errors, (error) => {
            toastr.error(error[0]);
          });
        } else {
          toastr.success("locations added successfully");
          this.processing = false;
          this.selectedChannel = [];
          this.locations = [];
          this.formActive = false;
          this.copy_rates_from = {};
        }
      });
  },

  selectAll() {
    this.selectedLocations = [];
    for (let i in this.locations) {
      this.selectedLocations.push(this.locations[i].id);
    }
  },

  selectNone() {
    this.selectedLocations = [];
  },

  onChangeSelectedChannel(channel) {
    this.select = true;
    this.selectedLocations = [];
    this.locations = [];
    this.selectedChannel = channel;
    this.locations = this.allChannels.find(channel => {
      if(channel.id == this.selectedChannel.value){
        return true;
      }
    }).locations.map(item => {
      return {
        id: item.location.id,
        name: item.location.name,
      }
    });
    if (this.copy_rates_from.value == this.$route.params.id) {
      this.copy_rates_from = {};
      return;
    }
    this.copy_rates_from = this.selectedChannel;
  },
}

export default {
  name: "add-location",
  computed,
  methods,
  components,
  data() {
    return {
      formActive: false,
      processing: false,
      selectedLocations: [],
      copy_rates_from: {},
      errors: {},
      select: false,
      selectedChannel: {},
      channalsLoading: false,
      locations: [],
      channelsList: [],
    }
  },
  created () {
    this.$store.dispatch('channels.getAll', {_q: "", _all: true, _with_locations: true}).then (() => {
      this.channelsList = this.allChannels.filter(current => {
        return current.id != this.$route.params.id;
      }).map(item => {
        return {
          value: item.id,
          text: item.name,
        };
      });
    });
  }
}
