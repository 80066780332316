/*
* @DESC: this component makes an element editable when double click on it.
* @param value: the value that you need to change
* @callback: v-on:valueSaved="doSomething({value, close(), startLoading(), startLoading()})"
*/

const methods = {
  cancel () {
    this.editedValue = this.cachedVal;
    this.active = false;
  },
  close () {
    this.active = false;
  },
  startLoading () {
    this.loading = true;
  },
  stopLoading () {
    this.loading = false;
  },
  save (value) {
    this.$emit('valueSaved', {
      value,
      close: this.close,
      startLoading: this.startLoading,
      stopLoading: this.stopLoading
    });

    this.editedValue = value;
    if (this.autoCloseOnSave) {
      this.active = false;
    }
  },
  enterActiveMode () {
    this.active = true;
    console.log('double clicked');
  }
}

const computed = {
  editedValue: {
      get() {
        return _.cloneDeep(this.value);
      },
      set (value) {
        this.cachedVal = value
      }
  }
}

const watch = {
  active (n) {
    if (n) {
      setTimeout(() => {
        $(this.$refs.input).focus();
      }, 100)
      this.cachedVal = _.cloneDeep(this.value);
    }
    console.log(this.cachedVal);
  }
}

export default {
  name: 'editable-text',
  props: ['value', 'autoCloseOnSave', 'showPen', 'shownValue'],
  methods,
  computed,
  watch,
  data () {
    return {
      active: false,
      cachedVal: null,
      loading: false
    }
  },
  mounted () {

  }
}
