import toastr from 'toastr'

const methods = {
  googleAutoComplete () {
    this.$store.dispatch('googleMaps.loadGoogleMapsAPI').then(() => {
      //this.$store.commit('INIT_PLACES_AUTOCOMPLETE', this.$refs.place);
      var autocomplete = new google.maps.places.Autocomplete(this.$refs.place, {
        //types: ['(cities)']
      });

      autocomplete.addListener('place_changed', (function(place) {
        var place = autocomplete.getPlace();
        if (!place.geometry) {
          // User entered the name of a Place that was not suggested and
          // pressed the Enter key, or the Place Details request failed.
          toastr.error("No details available for input: '" + place.name + "'");
          return;
        }
        console.log('place', place);
        console.log('place.geometry.viewport', place.geometry.viewport);
        console.log('place.geometry.location.lat()', place.geometry.location.lat());
        console.log('place.geometry.location.lng()', place.geometry.location.lng());
        this.$emit('input', place);

        //TODO: use this on map when search
        // If the place has a geometry, then present it on a map.
        if (place.geometry.viewport) {
          // map.fitBounds(place.geometry.viewport);
        } else {
          // map.setCenter(place.geometry.location);
          // map.setZoom(17);  // Why 17? Because it looks good.
        }
        // marker.setPosition(place.geometry.location);
        // marker.setVisible(true);
      }).bind(this));
    });
  }
}

export default {
  name: 'places-autocomplete',
  methods,
  mounted () {
    this.googleAutoComplete();
  }
}
