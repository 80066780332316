import { http } from 'services'
import layout from 'layouts/default'
import pagination from 'components/pagination'
import datepicker from 'components/datepicker'
import { funcModal, exportReport } from 'components/modals'
import { blackouts, overallCommissionPickup,commissionPickup, locationCommission,
  commissionBooking,  commissionLastChange, checkinCheckout,
  refundedFinishedReservations, extendedFinishedReservations, utilizationReport, channelReqests, BDReport } from './partials'
import { hasPermits } from 'directives'
import { user, permits } from 'services'
import toastr from 'toastr'

import moment from 'moment'
const components = {
  layout,
  pagination,
  blackouts,
  overallCommissionPickup,
  locationCommission,
  commissionBooking,
  commissionPickup,
  commissionLastChange,
  refundedFinishedReservations,
  extendedFinishedReservations,
  channelReqests,
  BDReport,
  checkinCheckout,
  utilizationReport,
  datepicker,
  exportReport,
  funcModal,
}

const directives = {
  hasPermits
}

const methods = {
  exportReport(ext) {
    this.$refs.export.open(this.url, ext);
    return;
  },
  resetReports () {
    this.run_report = false;
    this.errors = [];
  },
  selectChannel (channel, event) {
      
    let index = _.findIndex(this.channel_ids,(_channel) => {
      return _channel == channel.id;
    });

    if (index === -1) {
      this.channel_ids.push(channel.id)
    } else {
      this.channel_ids.splice(index, 1)
    }
 
    this.resetReports()
  },
  selectLocation (location, event) {
    
    let index = _.findIndex(this.location_ids,(_location) => {
      return _location == location.id;
    });
    
    if (index === -1) {
      this.location_ids.push(location.id)

      if (permits.hasPermit('location_user')) {
        this.$store.dispatch('locations.getChannels', location.id).then(() => {
          let channels = this.$store.state.locations.active.channels.map(ch => Object.assign(ch.channel, {location_id: location.id}));
          this.channels_list = _.unionBy(this.channels_list, channels, 'id');
        });
      }
    } else {
      this.location_ids.splice(index, 1);

      if (permits.hasPermit('location_user')) {
        this.channels_list = this.channels_list.filter(ch => ch.location_id != location.id);
      }
    }
    
    this.resetReports()
  },

  showErrors (errors) {
    this.errors = errors;
  },

  canAccessReport (permission) {
    let u = user.getUser();

    if (permits.hasPermit('location_user')) {
      for (let i in u.locations) {
        let found = _.findIndex(u.locations[i].roles[0].permissions, (item) => permission == item.name) > -1
        if (found) return true
      }
    }

    if (permits.hasPermit(permission)) {
      return true
    }

    return false;
  },

  runReport () {
    this.run_report = true
  },

  openFeesBreakDown ({location_id, reservation_id}) {
    this.fees_loading = true;
    this.fees_breakdown = [];
    this.$refs.feesBreakDown.open();
    this.$store.dispatch('locations.getReservationFees', {location_id, reservation_id}).then(() => {
      this.fees_breakdown = this.$store.state.locations.active.fees_breakdown;
      this.fees_loading = false;
    });
  },

  nextMonth (e) {
    e.preventDefault();
    this.from = moment(this.from, [this.dateFormat]).add(1, 'month').startOf('month').format(this.dateFormat);
    this.to = moment(this.from, [this.dateFormat]).endOf('month').format(this.dateFormat);
    this.resetReports();

  },

  prevMonth (e) {
    e.preventDefault();
    let prev = moment(this.from, [this.dateFormat]).subtract(1, 'month')
    this.from = prev.startOf('month').format(this.dateFormat);
    this.to = prev.endOf('month').format(this.dateFormat);
    this.resetReports();

  },

  editDate (date) {
    this.from = moment(date.from).format('YYYY-MM-DD');
    this.to = moment(date.to).format('YYYY-MM-DD');
    this.resetReports();
  }
}

const computed = {
  isChannelUser () {
    return !permits.hasPermit('super_admin') && permits.hasPermit('channel_user');
  },

  channels () {
    let u = user.getUser();
    if (permits.hasPermit('channel_user')) {
      return this.$store.state.channels.all;
    }

    if (permits.hasPermit('location_user')) {
      return this.channels_list
    }

    return []
  },

  showReport() {
    let reportsList = ['commission_pickup', 'blackouts', 'commission_booking', 'commission_last_change', 'checkin_checkout',
     'utilization_report', 'channel_requests','refunded_finished_reservations', 'extended_finished_reservations',
      'overall_commission_pickup', 'location_commission', 'bd_report'];
      return reportsList.includes(this.report);
  },
  url () {
    var url = '',
    params = '',
    pairs = [];

    if (this.report == 'bd_report') {
      let from_date = null;
      let to_date = null;
      if (this.from) {
        from_date = moment(this.from, [this.dateFormat]).startOf('month');
        pairs.push('from_date=' + from_date.format('YYYY-MM-DD'))
      }
      if (this.to) {
        to_date = moment(from_date, [this.dateFormat]).endOf('month');
        pairs.push('to_date=' + to_date.format('YYYY-MM-DD'))
      }
      params = pairs.join('&');
      url = 'reports/bd-report?' + params;
      return url;
    }

    let from_date = moment(this.from_date, ['MM/DD/YYYY']).format('YYYY-MM-DD');
    let to_date = moment(this.to_date, ['MM/DD/YYYY']).format('YYYY-MM-DD');
    
    if (from_date) {
      pairs.push('from_date=' + from_date)
    }
    if (to_date) {
      pairs.push('to_date=' + to_date)
    }

    if (this.report == 'channel_requests') {
      pairs.push('report_type=' + this.channel_requests_report_type);
    }
    
    if (this.report == 'commission_booking' || this.report == 'commission_last_change') {
      pairs.push('timezone=' + this.timezone);
    }
    
    for (var key in this.location_ids) {
      pairs.push(encodeURIComponent('locations[]') + '=' + encodeURIComponent(this.location_ids[key]));
    }
    

    if (!this.location_ids.length && !this.isChannelUser && this.report != 'location_commission' && this.locations.length) {
      let all_locations = this.locations.map(l => encodeURIComponent('locations[]') + '=' + encodeURIComponent(l.id));
      console.log('all_locations', all_locations);
      pairs = pairs.concat(all_locations);
    }

    for (var key in this.channel_ids) {
      if (this.channel_ids.hasOwnProperty(key)) {
        pairs.push(encodeURIComponent('channels[]') + '=' + encodeURIComponent(this.channel_ids[key]));
      }
    }

    if (this.report == 'overall_commission_pickup' && this.old_report) {
      pairs.push('old_report=true');
    }

    if (this.report == 'location_commission') {
      pairs.push('for_location=true');
    }

    params = pairs.join('&');//overall_commission_pickup

    if (this.report == 'blackouts') {
      url = 'reports/blackouts?' + params;
    } else if (this.report == 'commission_pickup') {
      url = 'reports/commission?by_pickup=true&' + params ;
    } else if (this.report == 'overall_commission_pickup' || this.report == 'location_commission') {
      url = 'reports/overall-commission?by_pickup=true&' + params ;
    }else if (this.report == 'commission_booking') {
      url = 'reports/commission?by_pickup=false&affiliate=true&' + params;
    }else if (this.report == 'commission_last_change') {
      url = 'reports/commission?by_pickup=false&last_change=true&' + params;
    }else if (this.report == 'refunded_finished_reservations') {
      url = 'reports/refunded-finished-reservations?' + params;
    } else if (this.report == 'extended_finished_reservations') {
      url = 'reports/extended-finished-reservations?' + params;
    } else if (this.report == 'checkin_checkout') {
      url = 'reports/checkin-checkout?' + params;
    }  else if (this.report == 'channel_requests') {
      url = 'reports/channel-request-logs?' + params;
    }

    return url;
  },
  locations () {
    if (this.isChannelUser) {
      let _locations = _.cloneDeep(this.$store.state.channels.active.locations);
      let locations = [];
      for (let i = 0; i < _locations.length; i++) {
        locations.push(_locations[i].location)    
      }
      return locations;
    }
    return this.$store.state.locations.all;
  },
  can_run () {
    switch (this.report) {
      case 'blackouts':
        if (!this.from_date || !this.to_date ) return false
        break;
      case 'overall_commission_pickup':  
      case 'location_commission':  
      case 'commission_pickup':
      case 'commission_booking':
      case 'commission_last_change':
      case 'refunded_finished_reservations':
      case 'extended_finished_reservations':
      case 'channel_requests':
        if (!this.from_date || !this.to_date || !this.channel_requests_report_type) return false
        break;
      case 'checkin_checkout':
        if (!this.from_date || !this.to_date ) return false
        break;
      case 'bd_report':
        if (!this.from) return false
        break;
      default:
        return false
    }

    return true;
  },
}

const watch = {
  report (n, o) {
    if (n == o) return;

    if (n == 'utilization_report') {

      if (permits.hasPermit('location_user')) {
        this.channels_list = [];
      }
    }

    this.resetReports();
  },
  // location_ids (n, o) {
  //   if (n == o) return;//
  //   this.resetReports();
  // },
  from_date (n, o) {
    if (n == o) return;
    this.resetReports();
  },
  to_date (n, o) {
    if (n == o) return;
    this.resetReports();
  },

  timezone (n, o) {
    if (n == o) return;
    this.resetReports();
  },

  channel_requests_report_type(n, o) {
    if (n == o) return;
    this.resetReports();
  },

  ['from'] (n, o) {
    let from_date_unformated = moment(this.from, [this.dateFormat])
    let to_date_unformated = moment(from_date_unformated);
    let from = _.cloneDeep(from_date_unformated.startOf('month').format(this.dateFormat));
    let to = _.cloneDeep(to_date_unformated.endOf('month').format(this.dateFormat));
    this.from = from;
    this.to = to;
    this.resetReports();
  },
}

export default {
  name: 'ReportMain',
  components,
  methods,
  computed,
  directives,
  watch,
  data () {
    let dateFormat = 'MMMM/YYYY';
    let from_date_unformated = moment().subtract(1,'months').startOf('month');
    let from = from_date_unformated.format(dateFormat);
    let to_date_unformated = moment().subtract(1,'months').endOf('month');
    let to = to_date_unformated.format(dateFormat);
    return {
      loading: true,
      searchKey: null,
      loadings: {},
      filter: 'active',
      report: '',
      channel_ids: [],
      channels_list: [],
      from_date: null,
      to_date: null,
      old_report: false,
      location_ids: [],
      run_report: false,
      errors:[],
      fees_loading: false,
      channel_requests_report_type: 'technical',
      timezone: 'EST',
      fees_breakdown: [],
      from,
      to,
      date_picker_settings: {
        changeMonth: true,
        changeYear: true,
        showButtonPanel: true,
        dateFormat: 'MM/yy',
      },
      dateFormat,
    }
  },
  created () {
    if (!permits.hasPermit('location_user') || permits.hasPermit('super_admin')) {
      this.$store.dispatch('channels.getAll', {_q: "", _all: true}).then(() => {
        // if (this.channels.length == 1) {
        //   this.selectChannel(this.channels[0]);
        // }

        if (this.channels.length == 1) {
          if (this.isChannelUser) {
            this.$store.dispatch('channels.getLocations', {
              id: this.channels[0].id,
              params: {_all: true}
            });
          }
          
        }
      });
    }

    this.$store.dispatch('locations.getAll', {_q: "", _all: true}).then(() => {
      if (this.locations && this.locations.length == 1) {
        this.selectLocation(this.locations[0]);
      }
    });

  },
  mounted () {

  }
}
