class User {

  setUser (user) {
    if (user) {
      user.permissions = [];
      if (user.roles && !user.locations) {
        user.permissions = user.roles[0] ?
        user.roles[0].permissions.map((perm) => {
          return perm.name
        }) : [];
      }

      try {
        if (user.locations.length) {
          user.permissions.push('location_user');
        }
      } catch (e) {

      }

      try {
        if (user.channels.length) {
          user.permissions.push('channel_user');
          user.permissions.push('location_management');
          user.permissions.push('location_rate_management');
          user.permissions.push('location_booking_conditions');
          user.permissions.push('locationFee_list');
          user.permissions.push('locationPhoto_list');
          user.permissions.push('amenity_list');
          user.permissions.push('extraFields_list');
          user.permissions.push('locationRate_adjustment_list');
          user.permissions.push('cancellationPolicy_list');
          user.permissions.push('longTermDiscount_list');
          user.permissions.push('blackoutDate_list');
          user.permissions.push('channelParkingRate_list');
          user.permissions.push('channelRoomRate_list');
          user.permissions.push('locationBundleRate_list');
          user.permissions.push('locationBundle_list');
          user.permissions.push('locationParkingType_list');
          user.permissions.push('locationRoomType_list');
          user.permissions.push('report_commission');
        }
      } catch (e) {

      }
    }
    sessionStorage.setItem("__user__", JSON.stringify(user));
  }

  getUser () {
    let user = sessionStorage.getItem("__user__");

    if(user == "null") return null;
    return JSON.parse(user);
  }

  setToken (token) {
    sessionStorage.setItem("__token__", token);
  }

  getToken () {
    let token = sessionStorage.getItem("__token__");

    if(token == "null") return null;
    return token;
  }
}

export default isServer ? {
  setUser () {

  },
  getUser () {
    return null
  },
  setToken () {

  },
  getToken () {
    return null
  },
} : new User()
