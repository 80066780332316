/*
* @INFO: Autocomplete component
* @PROP label: a dot notation string to specify the label of the objects (the text to be shown to the user).
* @PROP inputClass: the css classes to be assigned to the input field.
* @PROP url: the url to be requested for remote datasets. If the url needs a parameter, append it to the url like _q=.
* @PROP source: the local source of items. If you have a local list, use this (if this is set, the url param  will be ignored)
* @CALLBACK select: an event that is triggerred when an item is selected. params ({item, input})
*/

import _ from 'lodash'
import { http } from 'services'

const methods = {
  startAutocomplete (e) {
    if (this.source) {
      this.items = _.cloneDeep(this.source).filter((item) =>
        item.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1
      );
      this.toggleList();
    } else {
      this.loading = true;
      http.get(this.url + e.target.value).then((res) => {
        if (typeof res.data == Array) {
          this.items = res.data;
        } else {
          this.items = res.data.data;
        }
        this.loading = false;
        this.toggleList();
      });
    }

  },
  toggleList () {
    if(!this.$refs.input.value.length) {
      this.active = false;
    } else {
      this.active = true;
    }
  },
  select (item) {
    this.$emit('select', {item, input: this.$refs.input})
    this.active = false;
  },
  formatedItem (item) {
    if(this.label)
      return this.label.split('.').reduce(
        (o,i) => o[i], item
      )

    return item
  }
}

const computed = {

}

export default {
  name: 'autocomplete',
  props: ['label', 'inputClass', 'url', 'source'],
  computed,
  methods,
  data () {
    return {
      active: false,
      items: [],
      loading: false
    }
  }
}
