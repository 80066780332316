import toastr from 'toastr'
import moment from 'moment'
import { funcModal } from 'components/modals'
import datepicker from 'components/datepicker'
import { permits } from 'services'
import { hasPermits } from 'directives'
import fileUpload from 'components/fileUpload'

const components = {
  funcModal,
  datepicker,
  fileUpload
}

const directives = {
  hasPermits
}

const methods = {
  openDownloadModal () {
    this.downloadForm.from_date = null;
    this.downloadForm.to_date = null;

    this.$refs.downloadModal.open();
  },

  openUploadModal () {
    this.errors = {};
    if (!this.isLocationUser && !this.uploadForm.change_price_reason) {
      this.errors.change_price_reason = true;
      return
    }

    if (!this.uploadForm.file) {
      this.errors.no_file_selected = true;
      return
    }

    this.selectAll();
    this.$refs.uploadModal.open();
  },

  openopenBulkUpload () {
    this.formActive = true;
    this.$nextTick(() => {
      this.$refs.openBulkUpload.open();
    });
  },

  getTemplateAndDownload (modal) {
    this.download_loading = true;
    this.errors = {};
    let params = _.clone(this.downloadForm);
    params.from_date = moment(params.from_date, ['MM/DD/YYYY']).format('YYYY-MM-DD');
    params.to_date = moment(params.to_date, ['MM/DD/YYYY']).format('YYYY-MM-DD');
    this.$store.dispatch('bulkUpload.downloadTemplate', {
      location_id: this.$route.params.id,
      params
    }).then(() => {
      this.download_loading = false;
      let errors;
      if (errors = this.$store.state.bulkUpload.errors) {
        if (errors.errors) {
          this.errors = errors.errors;
        } else {
          toastr.error(errors.message);
        }
      } else {
        modal.close();
      }
    });
  },

  fileSelectedToUpload (files) {
    console.log("files", files);
    if (files.length) {
      this.uploadForm.file = files[0];
    } else {
      this.uploadForm.file = null;
    }

    this.errors = {};
  },

  uploadCSV (modal) {
    this.upload_loading = true;
    this.errors = {};
    this.uploadForm.change_price_reason = this.isLocationUser ? 'Random (e.g. location request without reason)' : this.uploadForm.change_price_reason;
    this.$store.dispatch('bulkUpload.uploadCSV',
      _.extend(this.uploadForm, {
        location_id: this.$route.params.id
      })
    ).
    then(() => {
      this.upload_loading = false;
      let errors;
      if (errors = this.$store.state.bulkUpload.errors) {
        if (errors.errors) {
          this.errors = errors.errors;
        } else {
          toastr.error(errors.message);
        }
      } else {
        toastr.success("Data has been saved");
        this.$emit('uploadCompleted');
        this.$refs.openBulkUpload.close();
        this.formActive = false;
        modal.close();
      }
    });
  },

  selectAll () {
    this.location.channels.forEach((item) => {
      this.uploadForm.channels.push(item.id);
    });
  },

  selectNone () {
    this.uploadForm.channels = [];
  }
}

const computed = {
  location () {
    return _.cloneDeep(this.$store.state.locations.active)
  },

  isLocationUser () {
    return !permits.hasPermit('super_admin') && permits.hasPermit('location_user');
  },
}

export default {
  name: "bulk-upload",
  components,
  methods,
  computed,
  directives,
  data () {
    return {
      formActive: false,
      download_loading: false,
      upload_loading: false,
      errors: {},
      downloadForm: {
        from_date: null,
        to_date: null,
        type: null, //parking, room, bundle
      },
      uploadForm: {
        channels: [],
        change_price_reason: null,
        file: null
      }
    }
  }
}
