
class Helper {

  validateChanges (keys, current_state, old_state, db_state) {
    let keys_to_sync = [];
    let changed_keys = [];

    keys.forEach((key) => {
      if (old_state[key] != current_state[key]) {
        changed_keys.push(key);
      }
    });

    keys.forEach((key) => {      
      if (typeof db_state[key] === 'object') {
        if (JSON.stringify(db_state[key]) != JSON.stringify(old_state[key])) {
          keys_to_sync.push(key);
        }
      } else {
        if (db_state[key] != old_state[key]) {
          keys_to_sync.push(key);
        }
      }
    });

    let conflicted_keys = keys_to_sync.filter(key => changed_keys.includes(key));
    
    if (conflicted_keys.length) {
      return false;
    }

    keys_to_sync.forEach((key) => {
      current_state[key] = db_state[key];
    });
    
    return true;
  }
}

export default new Helper();
