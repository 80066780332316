/*
* @INFO: this component is a rebuild of "select" element
* @PROP selected: is the selected option -> {text: 'the text to show', value: 'the value of the option'}
* @PROP options: an array of options -> [{text: '...', value: '...'}]
* @CALLBACK selectedChanged: fires when the selected option has changed
*/
const computed = {
  value () {
    let fallback = {
      value: "",
      text: "-- Select From List --"
    };
    if (this.multiple) {
      return fallback
    } else {
      try {
        return typeof this.selected.value == 'undefined' ? fallback : this.selected;
      } catch (e) {
        return typeof this.selected == 'undefined' ? fallback : this.selected;
      }
    }
  },
  filterredOptions () {
    if (this.filter_value)
      return this.options.filter((item) => item.text.toLowerCase().indexOf(this.filter_value.toLowerCase()) > -1);

    return this.options;
  }
}

const methods = {
  selectOption (option) {
    if (this.disabled) return;
  
    if (this.multiple) {
      if (_.findIndex(this.selectedOpts, o => o.value == option.value) > -1) {
        this.selectedOpts = _.filter(this.selectedOpts, o => o.value != option.value)
      } else {
        this.selectedOpts.push(option);
      }
      console.log('this.selectedOpts', this.selectedOpts);

      this.$emit('selectedChanged', this.selectedOpts);
    } else {
      this.$emit('selectedChanged', option);
    }

    this.filter_value = null;
  }
}

const watch = {
  selected (n) {
    if (this.multiple) {
      console.log('nnn', n);
      // this.$set(this, 'selectedOpts', n);
      this.selectedOpts = n;
    }
  }
}

export default {
  name: 's-select',
  props: {
    'selected': [Array, Object],
    'options': {
      required: true,
      type: Array
    },
    'multiple': {
      type: Boolean
    },
    'disabled': {
      type: Boolean,
      value: false
    }
  },
  computed,
  methods,
  watch,
  data () {
    return {
      filter_value: null,
      selectedOpts: [] // for multiple selection
      // active: this.selected
    }
  },
  mounted () {
    if (this.multiple) {
      this.selectedOpts = _.cloneDeep(this.selected);
      // console.log('this.selected', this.selected);

      $(this.$refs.menu).on('click', (e) => {
        if (!this.disabled) {
          $(this.$refs.menu).parent().is(".open") && e.stopPropagation();
        }
      })
    }
  }
}
