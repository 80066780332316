var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c("div", { staticClass: "text-center margin-top-6x" }, [
        _c("div", { staticClass: "box" }, [
          _c("div", { staticClass: "box-body" }, [
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "f20 uppercase margin-bottom-3x" }, [
              _vm._v(
                "\n          Validate reservations using Reservation Id\n        "
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-9" }, [
                _c("input", {
                  ref: "validationId",
                  staticClass: "form-control",
                  attrs: { type: "text", placeholder: "Reservation ID" }
                })
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-3" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary btn-block uppercase",
                    class: { disabled: _vm.validationProcessing },
                    attrs: { disabled: _vm.validationProcessing },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.showReservationDetails(
                          _vm.$refs.validationId.value
                        )
                      }
                    }
                  },
                  [
                    _vm.validationProcessing
                      ? _c("span", [_vm._v("Validating...")])
                      : _c("span", [_vm._v("Validate")])
                  ]
                )
              ])
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("view-reservation", {
        ref: "view",
        attrs: { reservation: _vm.reservation }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "f30 dark-turquoise" }, [
      _c("i", {
        staticClass: "fa fa-check-square-o",
        attrs: { "aria-hidden": "true" }
      })
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-1e26157c", { render: render, staticRenderFns: staticRenderFns })
  }
}