import toastr from 'toastr'
import { permits } from 'services'
import { baseModal } from 'components/modals'

const components = {
  baseModal
}

const computed = {
  countries () {
    let countries = [{name: "Select Country", id: null}]
      .concat(this.$store.state.address.countries)

    return countries
  },
  states () {
    let states = [{name: "Select State", id: null}];
    if (this.form.country_id) {

      let indx = _.findIndex(this.countries, item => item.id == this.form.country_id);
      if (indx > -1) {
        states = states.concat(this.countries[indx].states);
      }
    }

    return states;
  }
}

const methods = {
  checkEmail () {
    if (!this.form.email) return;
    this.checkingEmail = true;
    this.$store.dispatch("users.getUserByEmail", {
      type: 'channel',
      params: {
        email: this.form.email,
      }
    })
    .then(() => {
      this.checkingEmail = false;
      if (this.$store.state.users.errors) {
        toastr.error(this.$store.state.users.errors.message);
      } else {
        this.selectedUser = this.$store.state.users.active;
        this.form = this.selectedUser;
        toastr.success('User found, assign permissions to them and then press submit');
      }
    });
  },

  submit (modal) {
    this.errors = {}
    this.processing = true;
    this.$store.dispatch('channels.upsertEmployee', {id: this.$route.params.id, data: this.form})
    .then(() => {
      this.processing = false;
      let errors = this.$store.state.channels.errors;
      if (errors) {
        if (errors.errors) {
          this.errors = _.cloneDeep(errors.errors);
        }
        toastr.error(errors.message);
      } else {
        modal.close();
        this.unSelectUser();
      }
    });
  },

  unSelectUser () {
    this.selectedUser = null;
    this.form = {
      name: null,
      email: null,
      address1: null,
      address2: null,
      zip_code: null,
      country_id: null,
      state_id: null,
      type: 'channel',
    }
  }
}

export default {
  name: 'new-employee-modal',
  computed,
  components,
  methods,
  data () {
    return {
      form: {
        name: null,
        email: null,
        address1: null,
        address2: null,
        zip_code: null,
        country_id: null,
        state_id: null,
        type: 'channel',
      },
      selectedUser: null,
      loading: true,
      processing: false,
      checkingEmail: false,
      errors: {}
    }
  },
  created () {
    this.$store.dispatch('address.getCountries');
  }
}
