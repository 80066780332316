import toastr from 'toastr'
import { permits } from 'services'

const filters = {
}

const computed = {
  channels () {
    return _.cloneDeep(this.$store.state.locations.active.channels)
  },

  allChannels () {
    return _.cloneDeep(this.$store.state.channels.all);
  },

  filteredList() {
    return this.allChannels.filter(object1 => {
      return !this.channels.some(object2 => {
        return object1.id === object2.channel_id;
      });
    }).filter(channel => {
      return channel.name.toLowerCase().includes(this.search.toLowerCase())
    })
  },
}

const methods = {
  //this submits the new rates
  submit () {
    this.processing = true;

    this.$store.dispatch('locations.updateChannels', {
      id: this.$route.params.id,
      data: {
        channels: this.selected_channels,
        copy_rates_from: this.copy_rates_from.channel_id,
        with_commission: true
      }
    }).then(() => {
      let errors;
      if (errors = this.$store.state.locations.errors) {
        this.processing = false;
        console.log('errors', errors);
        _.forEach(errors.errors, (error) => {
          toastr.error(error[0]);
        })
      } else {
        this.$store.dispatch('locations.getChannels', this.$route.params.id)
        .then(() => {
          toastr.success('channel added successfully');
          this.formActive = false;
          this.search = '';
          this.processing = false;
          this.selected_channels = [];
        })

        this.copy_rates_from = {}
      }
    });
  },

  onCopyRatesSelected (channel) {
    if (this.copy_rates_from.id == channel.id) {
      this.copy_rates_from = {}
      return;
    }

    this.copy_rates_from = channel
  },

  selectAll() {
    this.selected_channels = [];
    for (let i in this.filteredList) {
      this.selected_channels.push(this.filteredList[i].id);
    }
  },

  selectNone() {
    this.selected_channels = [];
  },
}

export default {
  name: 'add-channel',
  computed,
  methods,
  filters,
  data () {
    return {
      formActive: false,
      loading: false,
      processing: false,
      search: '',
      copy_rates_from: {},
      errors: {},
      selected_channels: [],
    }
  },
  created () {
    if (!permits.hasPermit('location_user') || permits.hasPermit('super_admin')) {
      this.$store.dispatch('channels.getAll', {_q: "", _all: true}).then (() => {
      });
    }
  }
}
