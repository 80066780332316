import toastr from 'toastr'
import { confirm } from 'components/modals'
import { hasPermits } from 'directives'

const directives = {
  hasPermits
}

const components = {
  confirm
}

const computed = {
  extraFields () {
    return _.cloneDeep(this.$store.state.locations.active.extraFields);
  }
}

const methods = {
  removeExtraField (id) {
    this.$refs.confirmDelete.confirm('Are you sure?', () => {
      this.$set(this.loadings, id, true);

      this.$store.dispatch('locations.removeExtraField', {
        locId: this.$route.params.id,
        id
      }).then(() => {
        this.$set(this.loadings, id, false);
        let errors;
        if (errors = this.$store.state.locations.errors) {
          console.log('errors', errors);
          _.forEach(errors.errors, (error) => {
            toastr.error(error[0]);
          })
        } else {
          toastr.success('data has been removed successfully');

        }
      });
    })
  },

  addMoreFields () {
    this.fields_to_submit.push({
      label: null,
      type: 'both',
      input_type: 'text',
      per_car: false
    });
  },

  removeFields (i, e) {
    e.preventDefault();
    this.fields_to_submit.splice(i, 1);
  },

  submit () {
    this.processing = true;
    this.errors = {};
    this.$store.dispatch('locations.addExtraFields', {
      id: this.$route.params.id,
      data:this.fields_to_submit
    })
    .then(() => {
      this.processing = false;
      // console.log('active discount', this.$store.state.locations);
      let errors;
      if (errors = this.$store.state.locations.errors) {
        if (errors.errors) {
          this.errors = _.cloneDeep(errors.errors);
        }

        toastr.error(errors.message);
      } else {
        toastr.success('Data has been saved');
        this.fields_to_submit = [
          {
            label: null,
            type: 'both',
            input_type: 'text',
            per_car: false
          }
        ]
      }
    });
  }
}

export default {
  name: "extra-fields",
  computed,
  methods,
  components,
  directives,
  data () {
    return {
      locId: this.$route.params.id,
      errors: {},
      processing: false,
      loadings: {},
      formActive: false,
      fields_to_submit: [
        {
          label: null,
          type: 'both',
          input_type: 'text',
          per_car: false
        }
      ]
    }
  }
}
