import toastr from 'toastr'
import { hasPermits } from 'directives'
import sSelect from 'components/sSelect'
import { funcModal } from 'components/modals'

const computed = {
  channelsList () {
    let channels = _.cloneDeep(this.$store.state.channels.all);
    
    if(channels.data) channels = channels.data;

    return _.map(channels, item => {
      return {
        value: item.id,
        text: item.name
      }
    });
  }
}

const components = {
  funcModal,
  sSelect
}

const methods = {
  open () {
    this.$refs.modal.open();
  },

  submit (modal) {
    this.errors = {};
    this.processing = true;

    if (!this.selectedChannel){
      this.processing = false;
      toastr.error("Please select a channel to copy from!");
      return;
    }

    if (this.selectedChannel.value == this.$route.params.id){
      this.processing = false;
      toastr.error("Can't copy to same channel");
      return;
    }

    this.$store.dispatch('channels.copyFees', {
      chIdSource: this.selectedChannel.value,
      chIdDestination: this.$route.params.id
    }).then(() => {
      this.processing = false;
      let errors = this.$store.state.channels.errors;
      if (errors) {
        if (errors.errors) {
          this.errors = _.cloneDeep(errors.errors);
        }
        toastr.error(errors.message);
      } else {
        toastr.success('data has been saved successfully');
        modal.close();
      }
    });
  },
  onChangeSelectedChannel (channel) {
    this.selectedChannel = channel;
  },
  loadChannels () {
    this.channalsLoading = true;
    this.$store.dispatch('channels.getAll', {_q: "", _all: true}).then(() => {
      this.channalsLoading = false;
    });
  },
}

const watch = {
}

const directives = {
  hasPermits
}

export default {
  name: "copy-fee",
  props:['channels'],
  components,
  methods,
  computed,
  watch,
  directives,
  data () {
    return {
      processing: false,
      errors: {},
      selectedChannel: [],
      channalsLoading: false,
    }
  },
  created () {
    this.loadChannels()
  }
}
