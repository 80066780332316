import layout from 'layouts/default'
import { hasPermits } from 'directives'
import timepicker from 'components/timepicker'
import _ from 'lodash'
import { permits } from 'services'

const directives = {
  hasPermits
}

const components = {
  layout,
  timepicker,
}

const computed = {
  
}

const methods = {

  hasShuttleSchedulePermits() {
    return permits.hasLocationPermits(this.locId,['location_shuttle_schedule_update']);
  },
  
  addDaySchedule(index = -1) {
    let days = _.cloneDeep(this.days_of_week);
    //filter selected days from previous schedules.
    for (let day in days) {
      days[day]=!this.isDayInOtherSchedules(index,day)
    }
    let item = {
      times:[]
      };
      item = _.extend(item, days);
      const length = this.schedules.length;
      let addedIndex;
      if (index == -1 || index >= length) {
          this.schedules.push(item);
          addedIndex = length;
      } else {
          this.schedules.splice(index, 0, item);
          addedIndex = index;
      }
    
      this.addSchedule(addedIndex)   
  },

  addSchedule(schedule_index,index = -1) {

    this.editShuttleSchedule = true;
    
    let item = {
      from: '',
      to: '',
      frequency: null,
      comment: '',
    }
    
    let length = this.schedules.length; 
    if (index == -1 || index >= length) {
      this.schedules[schedule_index].times.push(item);
    } else {
      this.schedules[schedule_index].times.splice(index, 0, item);
    }
    this.onchangeTime(Math.max(index-1, 0), true);
  },
  removeDayRange(index) {
    this.schedules.splice(index, 1);
    this.schedule_counter--;
    this.onchange();
  },
  removeSchedule(schedule_index,index) {
    this.schedules[schedule_index].times.splice(index, 1);
    this.onchange();
  },

  onchangeTime (index, isTo = true) {
    this.onchange();
  },

  setLastTime (schedule_index) {
    let len = this.schedules[schedule_index].length
    if (len > 1 && this.schedules[schedule_index].times[0].from != this.schedules[schedule_index].times[len-1].to) {
      this.schedules[schedule_index].times[len-1].to = this.schedules[schedule_index].times[0].from;
    }
  },

  onchange() {
    // this.setLastTime();
    this.$emit('input', this.schedules);
  },

  startEdit() {
    this.editShuttleSchedule = true;
  },
  
  isDayInOtherSchedules(index,dayName) {
    return this.schedules
    .some((schedule, i) => index !== i && schedule[dayName]);
  },
}

export default {
  name: "shuttle-schedules",
  props: ['value', 'errors'],
  computed,
  components,
  methods,
  directives,
  data () {
    return {
      locId: this.$route.params.id,
      schedules: _.cloneDeep(this.value),
      loading: false,
      processing: false,
      editShuttleSchedule: false,
      days_of_week: {
        "monday":true, "tuesday":true, "wednesday":true, "thursday":true, "friday":true, "saturday":true,"sunday":true
    },
    }
  },
  created () {
  },

  mounted() {
  }
}
