import toastr from 'toastr'

const components = {
}

const methods = {
  submit () {
    this.errors = {};
    this.processing = true;
    console.log("this.form.password_confirmation", this.form.password_confirmation);
    if (!this.form.password_confirmation ||
    (this.form.password != this.form.password_confirmation)) {
      this.errors.password_confirmation = ['password and password confirmation must be matched'];
      this.processing = false;
      return;
    }

    this.$store.dispatch('user.resetPassword', {
      password: this.form.password,
      token: this.$route.query.token
    }).then(() => {
      this.processing = false;
      let errors;
      if (errors = this.$store.state.user.errors) {
        this.errors = errors.errors;
      } else {
        toastr.success("Your password has been changed");
        this.$router.push({name: "login"});
      }
    });
  }
}

export default {
  name: "reset-password",
  methods,
  components,
  data () {
    return {
      errors: {},
      form: {
        password: null,
        password_confirmation: null
      },
      processing: false
    }
  },
  created () {
    console.log("this.$route.query.token", this.$route.query.token);
    if (!this.$route.query.token) {
      this.$router.replace({name: "login"});
    }
  }
}
