var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "func-modal",
    {
      ref: "modal",
      attrs: { title: "Employee Details" },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function(props) {
            return [
              _c(
                "button",
                {
                  staticClass: "btn btn-default",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return props.close()
                    }
                  }
                },
                [_vm._v("Close")]
              )
            ]
          }
        }
      ])
    },
    [
      _c("template", { slot: "body" }, [
        _c("div", { staticClass: "margin-bottom-3x" }, [
          _c("table", { staticClass: "table table-bordered" }, [
            _c("tbody", [
              _c("tr", [
                _c("th", [_vm._v(" Name ")]),
                _vm._v(" "),
                _c("td", [_vm._v(" " + _vm._s(_vm.user.name) + " ")])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("th", [_vm._v(" Email ")]),
                _vm._v(" "),
                _c("td", [_vm._v(" " + _vm._s(_vm.user.email) + " ")])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("th", [_vm._v(" Phone ")]),
                _vm._v(" "),
                _c("td", [_vm._v(" " + _vm._s(_vm.user.phone) + " ")])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("th", [_vm._v(" Country ")]),
                _vm._v(" "),
                _c("td", [_vm._v(" " + _vm._s(this.country.name) + " ")])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("th", [_vm._v(" State ")]),
                _vm._v(" "),
                _c("td", [_vm._v(" " + _vm._s(this.state.name) + " ")])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("th", [_vm._v(" City ")]),
                _vm._v(" "),
                _c("td", [_vm._v(" " + _vm._s(_vm.user.city) + " ")])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("th", [_vm._v(" Zip Code ")]),
                _vm._v(" "),
                _c("td", [_vm._v(" " + _vm._s(_vm.user.zip_code) + " ")])
              ])
            ])
          ])
        ])
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-cac066f4", { render: render, staticRenderFns: staticRenderFns })
  }
}