import layout from 'layouts/default'
import toastr from 'toastr'
import { stripTags } from 'filters'
import datatable from 'components/datatable'
import { hasPermits } from 'directives'
import { user, permits } from 'services'
import moment from 'moment'
import rejectModal from './rejectModal'
import approveModal from './approveModal'

const directives = {
  hasPermits
}

const components = {
  layout,
  rejectModal,
  datatable,
  approveModal,
}

const methods = {
  moveToRequest(id) {
    this.$router.push({
      name: 'refundRequests.edit',
      params: {
         id: id
      },
      query: this.$route.query
    });
  },

  getRequest(id) {
    this.$store.dispatch('refundRequests.get', {id: id, query: this.$route.query}).then(() => {
      this.loading = false;
    });
  },

  writeComment () {
    this.typing_comment = true;
    setTimeout(() => {
      this.$refs.commentEditor.focus();
    }, 100)

  },

  cancelComment () {
    this.new_comment = "";
    this.typing_comment = false;
  },

  changeComment(newValue) {
    this.new_comment = newValue;
  },
  
  newComment () {

    if (stripTags(this.new_comment) == "" ) {
      return;
    }

    this.errors = {};
    this.processing = true;
    this.$store.dispatch('refundRequests.newComment', {
      id: this.$route.params.id,
      data: {
        comment: this.new_comment
      }
    }).then(() => {
      this.processing = false;
      let errors =  this.$store.state.refundRequests.errors;
      if (errors) {
        if (errors.errors) {
          this.errors = _.cloneDeep(errors.errors);  
        }
        toastr.error(errors.message);
      } else {
        toastr.success('Comment has been add successfully');
        this.new_comment = "";
        this.typing_comment = false;
      }
    });
  },

  updateStatus (status) {
    
    this.errors = {};
    this.processing = true;
    this.$store.dispatch('refundRequests.update', {
      id: this.$route.params.id,
      data: {
        status: status
      }
    }).then(() => {
      this.processing = false;
      let errors =  this.$store.state.refundRequests.errors;
      if (errors) {
        if (errors.errors) {
          this.errors = _.cloneDeep(errors.errors);  
        }
        toastr.error(errors.message);
      } else {
        toastr.success('Refund request has been updated successfully');
      }
    });
  },

  displayStatus (status) {
    
    let i, frags = status.split('_');
    for (i=0; i<frags.length; i++) {
      frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags.join(' ');
  },

  canReject () {
    let u = user.getUser();
    if ((permits.hasPermit('location_user') || permits.hasPermit('super_admin')) && this.refundRequest.status == 'pending') {
      return true;
    } else if (permits.hasPermit('super_admin') && this.refundRequest.status == 'escalated') {
      return true;
    }

    return false;
  },

  openRejectModal () {
    this.$refs.rejectModal.open();  
  },

  openApproveModal() {
    this.$refs.approveModal.open(); 
  },

  onCloseRejectModal () {
    this.$refs.rejectModal.close();  
  },

  adminHasAccess () {
    let u = user.getUser();
    if (permits.hasPermit('location_user') || permits.hasPermit('super_admin')) {
      return true;
    }
    
    return false
  }
}

const computed = {
  refundRequest () {
    return _.cloneDeep(this.$store.state.refundRequests.active);
  },

  refundAmount () {
    if (!this.refundRequest) return "";
    return "$" + this.refundRequest.amount;
  },

  attachments () {
    return _.cloneDeep(this.$store.state.refundRequests.active.attachments);
  }
}

const watch = {
  $route (to, from){
    this.getRequest(to.params.id);
  }
}

export default {
  name: 'edit-refund-requests',
  components,
  methods,
  computed,
  watch,
  directives,
  data () {
    return {
      moment,
      loading: true,
      processing: false,
      typing_comment: false,
      new_comment: '',
      comments: [],
      errors: {},
    }
  },
  created () {
    this.getRequest(this.$route.params.id);
  },
  
  mounted () {
  }

}
