import moment from 'moment-timezone'
import _ from 'lodash'
import toastr from 'toastr'
import layout from 'layouts/default'
import { hasPermits } from 'directives'
import { dateFormat, stripTags, currencySymbol } from 'filters'
import { permits, user } from 'services'
import guaranteeCard from 'components/modals/reservationDetails/guaranteeCard'
import partialRefundModal from 'components/modals/reservationDetails/partialRefundModal'
import revertPartialRefundModal from 'components/modals/reservationDetails/revertPartialRefundModal'
import partialRefundRequestModal from 'components/modals/reservationDetails/partialRefundRequestModal'
import cancelReservationModal from 'components/modals/reservationDetails/cancelReservationModal'
import updateReservationModal from 'components/modals/reservationDetails/updateReservationModal'
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import confirm from 'components/modals/confirm'

const directives = {
  hasPermits
}

const filters = {
  dateFormat,
  stripTags,
  currencySymbol,
}

const components = {
  layout,
  guaranteeCard,
  partialRefundModal,
  revertPartialRefundModal,
  partialRefundRequestModal,
  VueCtkDateTimePicker,
  confirm,
  cancelReservationModal,
  updateReservationModal,
}

const computed = {
  reservation () {
    return _.cloneDeep(this.$store.state.reservations.active)
  },

  activeHistory() {
    return _.find(this.reservation.history, h => h.active);
  },

  costsActiveHistory() {
    return this.costs ? _.find(this.costs.old_reservation.history, h => h.active) : null;
  },

  partialRefundRequest () {
    return !_.isEmpty(this.reservation) ? this.reservation.partial_refund_request : null;
  },

  extraFieldsName() {
    return this.activeHistory.extra_fields.map(item => item.name);
  },

  historyDetails () {
    let current = _.clone(this.activeHistory);
    current.room_type = null;
    current.parking_type = null;
    current.parking_dates = [];
    current.room_dates = [];

    for (let i in current.dates) {
      if (current.dates[i].type_type == 'room') {
        current.room_dates.push({
          from_date: current.dates[i].from_date,
          to_date: current.dates[i].to_date,
        });

        current.room_type = current.dates[i].type.name;

        current.number_of_guests = current.dates[i].number_of_guests;
        current.number_of_rooms = current.dates[i].number_of_units;
        current.guest_names = current.dates[i].guest_names;
      }

      if (current.dates[i].type_type == 'parking') {
        current.parking_dates.push({
          from_date: current.dates[i].from_date,
          to_date: current.dates[i].to_date,
        });
        current.parking_type = current.dates[i].type.name;
        current.number_of_spots = current.dates[i].number_of_units;
      }
    }

    return current;
  },

  isLocationUser () {
    return !permits.hasPermit('super_admin') && permits.hasPermit('location_user');
  },

  isAdmin () {
    return this.user && this.user.type == 'ims';
  },

  newAmountToPay () {
    if (this.costs) {
      if (this.costs.reservation.grand_total < this.costs.reservation.due_at_location) {
        return ((this.costs.reservation.grand_total - this.costs.reservation.location_commission) - (this.costsActiveHistory.grand_total - this.costsActiveHistory.location_total)).toFixed(2) 
      } else {
        console.log('this.costsActiveHistory', this.costsActiveHistory);
        console.log('1', (this.costs.reservation.grand_total - this.costs.reservation.due_at_location));
        console.log('2',   this.costsActiveHistory.grand_total , this.costsActiveHistory.due_at_location_total);
        return ((this.costs.reservation.grand_total - this.costs.reservation.due_at_location) - (this.costsActiveHistory.grand_total - this.costsActiveHistory.due_at_location_total)).toFixed(2) 
      }
    }

    return null;
  },

  timezone_name () {
  
    if (this.user.type != 'ims' && !permits.hasPermit('super_admin')) {
      return '';
    }
    return this.activeHistory.location.timezone.name;
  },

  credit_card_max_view_date () {
    let max_date = null;

    for (let i in this.historyDetails.room_dates) {
      let date = moment(this.historyDetails.room_dates[i].to_date, ['YYYY-MM-DD HH:mm:ss']);
      if (max_date == null || date.isAfter(max_date)) {
        max_date = date;
      }
    }
    
    return max_date.add('week', 1)
  },

  isReservationFinished () {

    let finishDate = null;

    for (let i in this.historyDetails.parking_dates) {
      let date = moment(this.historyDetails.parking_dates[i].to_date, ['YYYY-MM-DD HH:mm:ss']);
      if (finishDate == null || date.isAfter(finishDate)) {
        finishDate = date;
      }
    }

    for (let i in this.historyDetails.room_dates) {
      let date = moment(this.historyDetails.room_dates[i].to_date, ['YYYY-MM-DD HH:mm:ss']);
      if (finishDate == null || date.isAfter(finishDate)) {
        finishDate = date;
      }
    }
    
    let today = moment(moment(), ['YYYY-MM-DD HH:mm:ss']);
    return today.isAfter(finishDate)
  },

  isSuperAdmin () {
    return permits.hasPermit('super_admin');
  },

  parkingItem() {
    let item = _.filter(this.form.items, i => i.type == 'parking');
    return item[0];
  },
}

const methods = {
  changeNumber() {
    _.forEach(this.activeHistory.extra_fields, (field) => {
      if (field.per_car) {
        this.form[field.name].length = parseInt(this.parkingItem.number_of_spots);
      }
    });
  },

  confirmUpdate () {
    let msg = "";
    if (this.newAmountToPay > 0) {
      msg = "Customer will be charged " + this.currencyCode(this.activeHistory.location) + this.newAmountToPay;
    } else if (this.newAmountToPay < 0) {
      msg = "Customer will be refund " + this.currencyCode(this.activeHistory.location) + -1 * this.newAmountToPay;
    }

    if (msg != "") {
      this.$refs.confirmDialog.confirm(msg, () => {
        this.onSubmitFom();
      })
    } else {
      this.onSubmitFom();
    }
  
  },

  onSubmitFom () {
    this.errors = {}
    this.costs = null;
    this.costs_loading = true;
    this.submit_loading = true;
    this.recalculationRoomsItems();

    this.$store.dispatch('reservations.update', {id: this.$route.params.id, data: this.form}).then(() => {
      this.submit_loading = false;
      this.costs_loading = false;
      let errors = _.cloneDeep(this.$store.state.reservations.errors);

      if (errors) {
        toastr.error(errors.message);
        this.errors = errors.errors;
      } else {
        toastr.success("Data has been saved");
        this.getReservation()
      }
    })
  },  

  onSubmitCost() {
    this.errors = {}
    this.costs = null;
    this.costs_loading = true;
    this.recalculationRoomsItems();

    this.$store.dispatch('reservations.costs', {id: this.$route.params.id, data: this.form}).then(() => {
      this.costs_loading = false;
      let errors = _.cloneDeep(this.$store.state.reservations.errors);

      if (errors) {
        toastr.error(errors.message);
        this.errors = errors.errors;
      } else {
        this.costs = _.cloneDeep(this.$store.state.reservations.costs);
      }
    })
  },

  recalculationRoomsItems() {
    this.form.items = _.map(this.form.items, (item) => {
      if (item.type == 'room') {
        item.guest_names = _.slice(item.guest_names, 0, Number(item.number_of_rooms));
        item.guests_in_rooms = _.slice(item.guests_in_rooms, 0, Number(item.number_of_rooms));
      }

      return item;
    });
  },

  openCardModal () {
    this.$refs.cardModal.open(this.reservation.id, this.activeHistory.location.id);
  },

  openPartialRefundModal () {
    if (this.activeHistory.dates.some(e=>e.check_in)) {
      this.$refs.confirmDialog.confirm('Are you sure? This reservation has been validated by the location.', () => {
        this.$refs.partialRefundModal.open(this.activeHistory);
      });
    } else {
      this.$refs.partialRefundModal.open(this.activeHistory);
    }
  },

  openRevertPartialRefundModal () {
    this.$refs.revertPartialRefundModal.open(this.reservation, this.activeHistory);
  },

  openCancelReservationModal () {
    this.$refs.cancelReservationModal.open(this.reservation, this.activeHistory);
  },

  openUpdateReservationModal () {
    this.$refs.updateReservationModal.open(this.reservation, this.activeHistory, this.form);
  },

  openPartialRefundRequestModal () {

    if (!this.isReservationFinished) {
      this.$refs.confirmDialog.confirm("Once a refund is accepted, the reservation freezes and does not accept any more changes or refunds. It is recommended to submit a refund approval after the reservation has ended", () => {
        this.$refs.partialRefundRequestModal.open(this.activeHistory.available_for_partial_refund || 0, this.reservation.id);
      }, null, "Continue");
    } else {
      this.$refs.partialRefundRequestModal.open(this.activeHistory.available_for_partial_refund || 0, this.reservation.id);
    }

  },

  onCloseRefundModal () {
    this.getReservation()
  },

  cancelReservation () {
  
    if (this.isReservationStarted(false) || this.reservation.history_id_when_begin) {
      if (!this.isAdmin) {
        toastr.error("The reservation is started, just super admin can cancel it.");
        return;
      }
      this.openCancelReservationModal();
      return;
    }

    let confirmMsg = "Are you sure?";
    if (this.activeHistory.dates.some(e=>e.check_in)) {
      confirmMsg="Are you sure? This reservation has been validated by the location.";
    }

    this.$refs.confirmDialog.confirm(confirmMsg, () => {

      this.$store.dispatch('dashboards.cancelReservation', {
        channel_id: this.reservation.channel_id,
        reservation_id: this.reservation.id
      }).then(() => {
        let errors = _.cloneDeep(this.$store.state.dashboards.errors);
        if (errors) {
          toastr.error(errors.message);
        } else {
          let messsage = `Reservation has been cancelled successfully.`
          toastr.success(messsage);
          this.getReservation();
        }
      })
    })
  },

  updateReservation () {
    if ((this.isReservationStarted(false) || this.reservation.history_id_when_begin) && this.isFromDateChanged()) {
      if (!permits.hasPermit('super_admin')) {
        toastr.error("The reservation is started, just super admin can update from date for it.");
        return;
      }
      if (this.costsActiveHistory && this.costsActiveHistory.grand_total > this.costs.reservation.grand_total) {
        this.openUpdateReservationModal();
        this.costs = null;
        return;
      } else {
        this.onSubmitFom()
      }
    }
  },

  revertCancelReservation () {
    this.$refs.confirmDialog.confirm("Are you sure to revert cancel for this reservation?", () => {
      this.$store.dispatch('locations.revertCancel', {
        lId: this.activeHistory.location_id, id: this.reservation.id,
      }).then(() => {
        let errors = _.cloneDeep(this.$store.state.locations.errors);
        if (errors) {
          toastr.error(errors.message);
        } else {
          let messsage = `Reservation has been revert cancel successfully.`
          toastr.success(messsage);
          this.getReservation();
        }
      })
    })
  },

  getReservation() {
    this.loading = true;
    this.$store.dispatch('reservations.get', this.$route.params.id).then(() => {
      this.form = {
        reservation_number: this.reservation.reservation_number,
        location_id: this.activeHistory.location_id,
        email: this.activeHistory.email,
        phone: this.activeHistory.phone,
        reserved_for: this.activeHistory.reserved_for,
        state: this.activeHistory.state,
        items: [],
        coupon_discount: this.activeHistory.coupon_discount > 0 ? this.activeHistory.coupon_discount.toFixed(2): null,
      }
      _.forEach(this.activeHistory.extra_fields, (field) => {
        this.form[field.name] = field.value;
        if (field.per_car && Array.isArray(this.form[field.name])) {
          this.allowChangeSpotNumbers = true;
        }
      });

      _.forEach(this.activeHistory.location.extra_fields, (field) => {
        if (!this.extraFieldsName.includes(field.name)) {
          if (field.per_car && ((this.historyDetails.room_type && this.historyDetails.parking_type) || this.historyDetails.parking_type)) {
            this.form[field.name] = [''];
          } else {
            this.form[field.name] = null;
          }
        };
      });
      
      _.forEach(this.activeHistory.dates, (item) => {
        if (item.type_type == 'bundle') {
          this.form.bundle_id = item.type_id;
          return;
        }

        this.form.items.push({
          id: item.id,
          from_date: item.from_date,
          to_date: item.to_date,
          type: item.type_type,
          number_of_spots: item.type_type == 'parking' ? item.number_of_units : null,
          number_of_rooms: item.type_type == 'room' ? item.number_of_units : null,
          type_id: item.type_id,
          guest_names: item.type_type == 'room' ? item.guest_names : null,
          guests_in_rooms: item.type_type == 'room' ? item.number_of_guests : null,
        });
        this.old_from_dates.push({
          id: item.id,
          from_date: item.from_date,
        })
      });
      this.loading = false;
      
      for (let i in this.form.items) {
        let from_date = moment(this.form.items[i].from_date, ['YYYY-MM-DD HH:mm:ss']);
        let to_date = moment(this.form.items[i].to_date, ['YYYY-MM-DD HH:mm:ss']);
        if (to_date.isSame(from_date, 'day')) {
          this.tempForm = _.cloneDeep(this.form.items);
          setTimeout(() => {
            this.form.items = _.cloneDeep(this.tempForm);
          }, 100);
          break;
        }
      }
    });
  },

  isReservationStarted (doSubtract24h=true) {

    let startDate = null;

    for (let i in this.historyDetails.parking_dates) {
      let date = moment(this.historyDetails.parking_dates[i].from_date, ['YYYY-MM-DD HH:mm:ss']);
      if (startDate == null || date.isBefore(startDate)) {
        startDate = date;
      }
    }

    for (let i in this.historyDetails.room_dates) {
      let date = moment(this.historyDetails.room_dates[i].from_date, ['YYYY-MM-DD HH:mm:ss']);
      if (startDate == null || date.isBefore(startDate)) {
        startDate = date;
      }
    }
    
    startDate = startDate.tz(this.activeHistory.location.timezone.code, true);
    let today = moment(moment(), ['YYYY-MM-DD HH:mm:ss']).tz(this.activeHistory.location.timezone.code);
    if (doSubtract24h) {
      today = today.subtract('hours',24)
    }
    return today.isAfter(startDate)
  },

  isFromDateChanged () {
    for (let i in this.form.items) {
      for (let j in this.old_from_dates) {
        if (this.form.items[i].id == this.old_from_dates[j].id && this.form.items[i].from_date != this.old_from_dates[j].from_date) {
          return true;
        }
      }
    }
    return false;  
  },
  
  currencyCode(location) {
    return location.currency ? currencySymbol(location.currency.code) : "Na";
  },
}

export default {
  name: 'edit-reservation',
  components,
  computed,
  methods,
  directives,
  filters,
  data () {
    return {
      loading: true,
      costs_loading: false,
      submit_loading: false,
      errors: {},
      costs: null,
      old_from_dates: [],
      number_of_spots: null,
      form: {
        reservation_number: null,
        extra_fields: [],
        items: []
      },
      allowChangeSpotNumbers: false,
      user: null
    }
  },
  created () {
    this.getReservation()
  },
  mounted () {
    this.user = user.getUser();
  }
}
