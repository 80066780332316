import toastr from 'toastr'
import layout from 'layouts/default'
import tabs from '../tabs'
import { confirm } from 'components/modals'
import { hasPermits } from 'directives'
import newChannel from "./newChannel"


const directives = {
  hasPermits
}

const components = {
  layout,
  tabs,
  confirm,
  newChannel
}

const computed = {
  location () {
    return _.cloneDeep(this.$store.state.locations.active);
  },
}

const methods = {
  removeChannel (channel) {
    this.$refs.confirmDelete.confirm('Are you sure that you want to remove this channel?', () => {
      this.$store.dispatch('locations.removeChannelFromLocation', channel.id)
      .then(() => {
        let channels = this.location.channels.map((item) => {
          return item.channel.id
        });
        this.$store.dispatch('locations.updateChannels', {
          id: this.$route.params.id,
          data: {
            channels,
          }
        }).then(() => {
          let errors;
          if (errors = this.$store.state.locations.errors) {
            console.log('errors', errors);
            _.forEach(errors.errors, (error) => {
              toastr.error(error[0]);
            })
            this.$store.dispatch('locations.getChannels', this.$route.params.id);
          } else {
            toastr.success('channel removed successfully');
          }
        });
      });
    });
  }
}


export default {
  name: 'chennels-tab',
  components,
  computed,
  methods,
  directives,
  data () {
    return {
      loading: false,
      locId: this.$route.params.id
    }
  },
  created () {
    this.loading = true;

    Promise.all([
      this.$store.dispatch('locations.get', this.$route.params.id),
      this.$store.dispatch('locations.getChannels', this.$route.params.id)
    ]).then(() => {
      this.loading = false;
    });
  }
}
