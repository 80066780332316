import reports from 'pages/reports'

export default [
  //Reports
  { path: '/reports', name: 'reports.all', component: reports
    , meta:{permits:[
      'report_locations',
      'report_blackouts',
      'report_commission',
      'report_checkin_checkout',
      'report_arrivals',
      'report_departures',
      'report_utilization',
    ]}},
]
