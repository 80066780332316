import toastr from 'toastr'
import { funcModal } from 'components/modals'
import { confirm } from 'components/modals'
import { hasPermits } from 'directives'
import { user} from 'services'

const directives = {
  hasPermits
}

const components = {
  funcModal,
  confirm
}

const computed = {
  need_to_save () {
    return this.$store.state.channels.active.need_to_save;
  },

  errors () {
    if (this.$store.state.channels.errors) {
      return this.$store.state.channels.errors.errors;
    }

    return {}
  },

  error () {
    return this.$store.state.channels.errors;
  },

  isAdmin () {
    return this.user && this.user.type == 'ims';
  },
}

const methods = {

  showPasswordModal (key_type) {
    this.modalShouldShow = false;
    this.$nextTick(() => {
      this.modalShouldShow = true;
      this.$store.dispatch('channels.clearErrors');
      this.$nextTick(() => {
        this.key_type = key_type;
        this.$refs.passwordModal.open();
      });
    });
  },

  requestKey (password) {
    this.loading = true;

    this.$store.dispatch('channels.getProductionKey', {id: this.$route.params.id, password}).then(() => {
      this.loading = false;
      if (this.error) {
        toastr.error(this.error.message);
      } else {
        this.$refs.passwordModal.close();
      }
    });
  },

  regenerateKey (type) {
    this.$refs.confirmRegenerateKey.confirm(`Are you sure that you want to re-generate the ${type} Key?`, () => {
      this.$store.dispatch('channels.regenerateKey', {id: this.$route.params.id, type})
      .then(() => {
        let errors = _.cloneDeep(this.$store.state.channels.errors);
        if (errors) {
          toastr.error(`Generating new ${type} Key failed`);
        } else {
          toastr.success(`Generated new ${type} Key successfully`);
        }
      });
    });
  },

  loadMoreIps() {
    if (this.has_more) {
      this.ips = this.form.ips;
      this.has_more = false;
    }
  },

  onPressEnterRequestKey (event, password) {
    console.log('event', event);
    if (event.keyCode == 13) {
      event.preventDefault();
      if (password.length) {
        this.requestKey(password);
      }
    }
  },

  loadDocumentation() {
    this.file = null;
    this.$store.dispatch('channels.getApiDocumentation', {id: this.$route.params.id}).then(() => {
      if (!this.error) {
        this.file = _.cloneDeep(this.$store.state.channels.api_doc);
      }
      if (this.file) {
        const file = new Blob([this.file], { type: "text/html" }); // the blob
        var fileURL = window.URL.createObjectURL(file);
        let tab = window.open();
        tab.location.href = fileURL;
      } else {
          if (this.error) {
            toastr.error(this.error.message);
          }
        }
    });
  }
}

const watch = {
  formData (n, o) {
    if (n == o) return;

    this.form = n;
  }
}

export default {
  name: 'general-tab',
  props: ['formData'],
  components,
  computed,
  methods,
  directives,
  watch,
  data () {
    return {
      // errors: {},
      form: this.formData,
      ips: [],
      user: null,
      has_more: false,
      key_type: null,
      files_processing: false,
      modalShouldShow: false,
      loading: false,
      file: null
    }
  }

  , created() {
    if (this.form.ips && this.form.ips.length > 10) {
      this.has_more = true;
      for (let i = 0; i < 10; i++) {
        this.ips.push(this.form.ips[i]);
      }
    } else{
      this.ips = this.form.ips;
    }
  },

  mounted () {
    this.user = user.getUser();
  }
}
