var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "layout",
    { attrs: { "no-box": true, title: _vm.location ? _vm.location.name : "" } },
    [
      _c("tabs"),
      _vm._v(" "),
      _vm.loading
        ? _c("div", { staticClass: "text-center" }, [
            _c("i", { staticClass: "fa fa-spinner fa-spin fa-3x fa-fw" })
          ])
        : _c("div", { staticClass: "box" }, [
            _c(
              "div",
              { staticClass: "box-header with-border margin-bottom-8x" },
              [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-sm-3 col-xs-12 margin-bottom-2x" },
                    [
                      _c("label", [_vm._v("To Date")]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "input-group" },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "input-group-addon",
                              attrs: { id: "basic-addon1" }
                            },
                            [
                              _c("i", {
                                staticClass: "fa fa-calendar",
                                attrs: { "aria-hidden": "true" }
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c("datepicker", {
                            staticClass: "form-control",
                            attrs: {
                              "min-date": "none",
                              placeholder: "MM/DD/YYYY"
                            },
                            model: {
                              value: _vm.form.to_date,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "to_date", $$v)
                              },
                              expression: "form.to_date"
                            }
                          })
                        ],
                        1
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-sm-6 col-xs-12 padding-top-5x" },
                    [
                      _c("span", [
                        _c(
                          "a",
                          {
                            attrs: { href: "#" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.prevMonth.apply(null, arguments)
                              }
                            }
                          },
                          [
                            _c("i", {
                              staticClass: "fa fa-chevron-left",
                              attrs: { "aria-hidden": "true" }
                            })
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "span",
                          { staticClass: "margin-right-3x margin-left-3x" },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(this.form.from_date) +
                                " - " +
                                _vm._s(this.form.to_date) +
                                "\n              "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            attrs: { href: "#" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.nextMonth.apply(null, arguments)
                              }
                            }
                          },
                          [
                            _c("i", {
                              staticClass: "fa fa-chevron-right",
                              attrs: { "aria-hidden": "true" }
                            })
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-default margin-left-3x",
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.goBackToToday.apply(null, arguments)
                              }
                            }
                          },
                          [_vm._v("\n                Today\n              ")]
                        )
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-sm-3 col-xs-12 pull-right margin-bottom-2x"
                    },
                    [
                      _c("label", [_vm._v("Filter By")]),
                      _vm._v(" "),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.filter,
                              expression: "form.filter"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: { disabled: _vm.chartLoading },
                          on: {
                            change: function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.form,
                                "filter",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            }
                          }
                        },
                        [
                          _c(
                            "option",
                            {
                              attrs: { selected: "" },
                              domProps: { value: null }
                            },
                            [_vm._v("-- select --")]
                          ),
                          _vm._v(" "),
                          _c("option", { attrs: { value: "room" } }, [
                            _vm._v("Room")
                          ]),
                          _vm._v(" "),
                          _c("option", { attrs: { value: "parking" } }, [
                            _vm._v("Parking")
                          ]),
                          _vm._v(" "),
                          _c("option", { attrs: { value: "bundle" } }, [
                            _vm._v("Bundle")
                          ])
                        ]
                      )
                    ]
                  )
                ])
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "box-body" }, [
              _vm.chartLoading
                ? _c("div", { staticClass: "text-center" }, [
                    _c("i", {
                      staticClass: "fa fa-spinner fa-spin fa-3x fa-fw"
                    })
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _vm.form.filter !== "room"
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "col-md-10 col-md-offset-1 col-sm-10 col-sm-offset-1 col-xs-12 margin-bottom-8x"
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "chart-box chart-box--shadow" },
                          [
                            _c(
                              "div",
                              { staticClass: "chart-box chart-box--title" },
                              [_c("h3", [_vm._v("Demand LINE")])]
                            ),
                            _vm._v(" "),
                            _vm.showComponents
                              ? _c("GChart", {
                                  attrs: {
                                    type: "LineChart",
                                    settings: {
                                      packages: ["line", "corechart"]
                                    },
                                    createChart: function(el, google) {
                                      return new google.charts.Line(el)
                                    },
                                    data: _vm.demandLineDataFinalResult,
                                    options: _vm.chartsOptions.demandLine
                                  }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "col-md-10 col-md-offset-1 col-sm-10 col-sm-offset-1 col-xs-12 margin-bottom-8x"
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "chart-box chart-box--shadow" },
                      [
                        _c(
                          "div",
                          { staticClass: "chart-box chart-box--title" },
                          [_c("h3", [_vm._v("Revenue Chart")])]
                        ),
                        _vm._v(" "),
                        _vm.showComponents
                          ? _c("GChart", {
                              attrs: {
                                type: "LineChart",
                                settings: { packages: ["line", "corechart"] },
                                createChart: function(el, google) {
                                  return new google.charts.Line(el)
                                },
                                data: _vm.revenueDataFinalResult,
                                options: _vm.chartsOptions.revenue
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  ]
                ),
                _vm._v(" "),
                _vm.form.filter !== "room"
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "col-md-10 col-md-offset-1 col-sm-10 col-sm-offset-1 col-xs-12 margin-bottom-8x"
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "chart-box chart-box--shadow" },
                          [
                            _c(
                              "div",
                              { staticClass: "chart-box chart-box--title" },
                              [_c("h3", [_vm._v("Conversion Rate")])]
                            ),
                            _vm._v(" "),
                            _vm.showComponents
                              ? _c("GChart", {
                                  attrs: {
                                    type: "LineChart",
                                    settings: {
                                      packages: ["line", "corechart"]
                                    },
                                    createChart: function(el, google) {
                                      return new google.charts.Line(el)
                                    },
                                    data: _vm.conversionRateFinalResult,
                                    options: _vm.chartsOptions.conversionRate
                                  }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      ]
                    )
                  : _vm._e()
              ])
            ])
          ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-3d846676", { render: render, staticRenderFns: staticRenderFns })
  }
}