import moment from 'moment'
import { permits } from 'services'

const computed = {
  isChannelUser () {
    return !permits.hasPermit('super_admin') && permits.hasPermit('channel_user');
  },
  
  channel () {
    return _.cloneDeep(this.$store.state.locations.activeChannel.channel);
  },
  calendarRates () {
    for (let i in this.types) {
      this.types[i].rates = _.sortBy(this.types[i].rates, ['id']).reverse();
    }
    return this.types;
  },
}

const methods = {
  priceInThisDate (type, date, priceType = 'daily', base_rate_only = false) {
    let day_name = date.format('dddd').toLowerCase();
    for (let i in this.calendarRates) {
      if (type.id == this.calendarRates[i].id) {
        let rates = this.calendarRates[i].rates;
        for (let j in rates) {
          if (base_rate_only && rates[j].automation) {
            continue;
          }
          let start_date = moment(rates[j].from_date, ['YYYY-MM-DD']);
          let to_date = rates[j].to_date ? rates[j].to_date : '2099-12-31';
          let end_date = moment(to_date, ['YYYY-MM-DD']);
          if (date.isBetween(start_date, end_date, null, "[]") && rates[j][day_name]) {
            if (priceType == 'daily') {
              return rates[j].price;
            } else{
              return rates[j].hourly_price || 0;
            }
            
          }
        }
        break;
      }
    }

    return 0;
  },

  availabilityAtDate (type, date, addDays) {
    for (let i in this.calendarRates) {
      if (type.id == this.calendarRates[i].id) {
        let date_key = moment(date, ['MM/DD/YYYY']).add(addDays, 'days').format('DD-MM-YYYY');
        return this.calendarRates[i].dates_data[date_key].available || 0;
      }
    }

    return 0;
  },

  hideCalenderTestingData () {
    this.showAvailability = false;
    this.showBasePrice = false;

    sessionStorage.setItem('__calendar_test_mode1___', null);
    sessionStorage.setItem('__calendar_test_mode2___', null);
  },

  showCalenderTestingData (mode) {
    if (mode == 1) {
      sessionStorage.setItem('__calendar_test_mode1___', 'true');
      this.showAvailability = true;
    }
    if (mode == 2) {
      sessionStorage.setItem('__calendar_test_mode2___', 'true');
      this.showBasePrice = true;
    }
  },

  getCalenderTestData () {
    let _test1 = sessionStorage.getItem('__calendar_test_mode1___');
    
    if (_test1 == null || _test1 == 'null' || _test1 == 'false') {
      this.showAvailability = false;
    } else {
      this.showAvailability = true;
    }

    let _test2 = sessionStorage.getItem('__calendar_test_mode2___');
    if (_test2 == null || _test2 == 'null' || _test2 == 'false') {
      this.showBasePrice = false;
    } else {
      this.showBasePrice = true;
    }
  },

  isLease (type, date, addDays) {
    for (let i in this.calendarRates) {
      if (type.id == this.calendarRates[i].id) {
        let date_key = moment(date, ['MM/DD/YYYY']).add(addDays, 'days').format('DD-MM-YYYY');
        return this.calendarRates[i].dates_data[date_key].is_lease;
      }
    }

    return false;
  }
}

export default {
  name: "line-calendar",
  props: ['numberOfDays', 'rateTypes', 'fromDate', 'type'],
  computed,
  methods,
  data () {
    return {
      moment,
      showAvailability: false,
      showBasePrice: false,
      priceBasis: "hourly",
      types: []
    }
  },
  created () {
    let priceBasis =  _.cloneDeep(this.$store.state.locations.active.daily_or_hourly) || 'hourly';
    this.types = _.cloneDeep(this.$store.state.locations.calendarRates);
    for (let i = 0; i < this.rateTypes.length; i++) {
      this.rateTypes[i]['rates'] = [];
      for (let j = 0; j < this.numberOfDays + 1; j++) {
        let datej = moment(this.fromDate, ['MM/DD/YYYY']).add(j, 'days');
        let price = this.priceInThisDate(this.rateTypes[i], datej, 'daily');
        let base_price = this.priceInThisDate(this.rateTypes[i], datej, 'daily', true);
        let availability = parseInt(this.availabilityAtDate(this.rateTypes[i], this.fromDate, j));
        let tooltip = `${availability} available unit/s`;
        tooltip += `\n <br> Base price: ${base_price}`;
        if (priceBasis == 'hourly') {
          let hourlyPrice = this.priceInThisDate(this.rateTypes[i], datej, 'hourly');
          tooltip += `\n <br> Hourly price: ${hourlyPrice}`;
        }
        this.rateTypes[i]['rates'].push({
          date: datej,
          availability: availability,
          tooltip: tooltip,
          price: price,
          base_price: base_price,
        });
      }
    }

    this.getCalenderTestData();
  },
  mounted () {
    // this.types = _.cloneDeep(this.$store.state.locations.calendarRates);
    $(this.$refs.cells).tooltip({
      html: true
    });

    window.lineCalendar = this;
  }
}
