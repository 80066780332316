import toastr from 'toastr'
import sSelect from 'components/sSelect'

const components = {
  sSelect
}

const computed = {
  locations () {
    return _.map(_.cloneDeep(this.channelLocations), item => {
      return {
        value: item.id,
        text: item.location.name
      }
    });
  }
}

const methods = {
  submit () {
    this.errors = {};
    this.processing = true;

    let data = _.cloneDeep(this.form);

    if (!data.all_locations) {
      data.locations = this.selectedLocations.map(location => location.value);
    }

    this.$store.dispatch('channels.newFee', {
      id: this.$route.params.id,
      data
    }).then(() => {
      this.processing = false;
      let errors = this.$store.state.channels.errors;
      if (errors) {
        if (errors.errors) {
          this.errors = _.cloneDeep(errors.errors);
        }
        toastr.error(errors.message);

      } else {
        toastr.success('data has been saved successfully');
        this.formActive = false;
        this.selectedLocations = [];
        this.form = {
          name: null,
          amount: null,
          description: null,
          type: "fixed",
          associated_to: "room",
          calculation: "one_time",
          all_locations: 1,
        }
      }
    });
  },

  onChangeSelectedLocations (locations) {
    console.log('locations', locations);
    this.selectedLocations = locations;
  },

  removeSelected (selectedLocation) {
    console.log('selectedLocations', selectedLocation);
    this.selectedLocations = _.filter(this.selectedLocations, location => location.value != selectedLocation.value);
  },

  handleFeesType (event) {
    let type = event.target.value;
    if (type == 'one_time') {
      this.form.type = 'fixed';
    }
  },
}

export default {
  name: 'new-fee',
  props: ['channelLocations'],
  methods,
  components,
  computed,
  data() {
    return {
      formActive: false,
      processing: false,
      errors: {},
      form: {
        name: null,
        amount: null,
        description: null,
        type: "fixed",
        associated_to: "room",
        calculation: "one_time",
        all_locations: 1,
      },
      selectedLocations: [],
    }
  },
  created () {

  }
}
