export function noSSR () {
  return {
    name: 'no-ssr',
    props: ['placeholder'],
    data () {
      return {
        canRender: false
      }
    },
    mounted () {
      this.canRender = true
    },
    render (h) {
      if (this.canRender) {
        if (
          process.env.NODE_ENV === 'dev' &&
          this.$slots.default &&
          this.$slots.default.length > 1
        ) {
          throw new Error('<no-ssr> You cannot use multiple child components')
        }
        return this.$slots.default && this.$slots.default[0]
      }

      return h(
        'div',
        {
          class: ['no-ssr-placeholder']
        },
        this.$slots.placeholder || this.placeholder
      )
    }
  }
}
