import toastr from 'toastr'
import layout from 'layouts/default'

const components = {
  layout
}

const computed = {

}

const methods = {
  submit () {
    this.processing = true;
    this.$store.dispatch('roles.newRole', this.form)
    .then(() => {
      this.processing = false;
      let errors = this.$store.state.roles.errors;
      if (errors) {
        if (errors.errors) {
          this.errors = _.cloneDeep(errors.errors);
        }
        toastr.error(errors.message);
      } else {
        this.$router.replace({name: 'roles.all'});
      }
    });
  },

  filterPermissions (type) {
    console.log('this.permissions', this.permissions);
    if (type == 'admin') {
      this.permissions = this.all_permissions;
      return
    }

    this.permissions = {};
    for (let i in this.all_permissions) {
      let items = this.all_permissions[i].filter(item => item.type == type)
      if (items.length) {
        this.permissions[i] = items
      }
    }

  }
}

export default {
  name: 'new-role',
  components,
  computed,
  methods,
  data () {
    return {
      form: {
        name: null,
        description: null,
        permissions: [],
        type: 'admin'
      },
      loading: true,
      processing: false,
      errors: {},
      all_permissions: {},
      permissions: {}
    }
  },
  created () {
    this.$store.dispatch('roles.getPermissions').then(() => {
      this.loading = false;
      this.all_permissions = _.cloneDeep(this.$store.state.roles.permissions);
      this.permissions = this.all_permissions;
    });
  }
}
