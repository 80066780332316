
const methods = {
  openUpload () {
    this.$refs.file.click();
  },



  selectFile () {
    if (this.multiple) {
      let items = _.map(this.$refs.file.files, (item) => item.name);
      this.$refs.name.innerHTML = items.join(', ');
    } else {
      this.$refs.name.innerHTML = this.$refs.file.files[0].name;
    }

    this.files = this.$refs.file.files;

    this.$emit('filesChanged', this.$refs.file.files);
  },
  removeFiles () {
    this.$refs.file.value = null;
    this.$refs.name.innerHTML = null;
    this.files = [];
    this.$emit('filesChanged', this.$refs.file.files);
  }
}

export default {
  name: "file-upload",
  props: ['multiple', 'accept'],
  methods,
  data () {
    return {
      files: []
    }
  }
}
