import {all as allRefundRequests} from 'pages/refundRequests'
import {edit as editRefundRequests} from 'pages/refundRequests'

export default [
  //partalRefundRequests
  { path: '/refund-requests', name: 'refundRequests.all', component: allRefundRequests
  , meta:{permits:['location_user', 'channel_user']}},
  { path: '/refund-requests/:id', name: 'refundRequests.edit', component: editRefundRequests
  , meta:{permits:['location_user', 'channel_user']}},
]
