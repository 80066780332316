import toastr from 'toastr'
import moment from 'moment'
import layout from 'layouts/default'
import datepicker from 'components/datepicker'
import { hasPermits } from 'directives'
import { permits } from 'services'
import { confirm } from 'components/modals'

const directives = {
  hasPermits
}

const components = {
  layout,
  datepicker,
  confirm,
}

const computed = {
  location () {
    return _.cloneDeep(this.$store.state.locations.active)
  },

  hasSelectChannelsPermit () {
    return permits.hasLocationPermit(this.$route.params.id, 'location_channel_select');
  }
}

const methods = {
  submit () {
    if (this.hasAutomation) {
      let text = `This change will be subject to automated dynamic pricing`;
      this.$refs.submitConfirm.confirm(text, () => {
        this.submitAdjustment();
      });
    } else {
      this.submitAdjustment();
    }
  },

  submitAdjustment () {
    this.errors = {};
    this.processing = true;
    let data = _.clone(_.extend(this.form, this.adjustmentForm));
    data.change_price_reason = this.isLocationUser() ? 'Random (e.g. location request without reason)' : data.change_price_reason;

    if (this.type == 'adjustment_bundles') {
      data.parking_types = [];
      data.room_types = [];
    } else {
      data = _.extend(data, this.selected_days);
      data.bundle_types = [];
    }

    this.parseDates(data);
    this.$store.dispatch('rateManagement.submitRateAdjustment', {
      location_id: this.$route.params.id,
      data
    })
    .then(() => {
      this.processing = false;
      // console.log('active discount', this.$store.state.rateManagement);
      let errors;
      if (errors = this.$store.state.rateManagement.errors) {
        if (errors.errors) {
          this.errors = _.cloneDeep(errors.errors);
        }

        toastr.error(errors.message);
      } else {
        toastr.success('Data has been saved');
      }
    });
  },

  submitLongTerm () {
    this.errors = {};
    this.processing = true;
    let data = _.clone(_.extend(this.form, this.longTermForm));
    this.parseDates(data);
    this.$store.dispatch('rateManagement.submitLongTermDiscount', data)
    .then(() => {
      this.processing = false;
      // console.log('active discount', this.$store.state.rateManagement);
      let errors;
      if (errors = this.$store.state.rateManagement.errors) {
        if (errors.errors) {
          this.errors = _.cloneDeep(errors.errors);
        }

        toastr.error(errors.message);
      } else {
        toastr.success('Data has been saved');
        this.longTermForm.discounts = [{
          number_of_days: null,
          discount: null
        }]
      }
    });
  },

  parseDates (data) {
    if (data.from_date) {
      data.from_date = moment(data.from_date, ['MM/DD/YYYY']).format('YYYY-MM-DD 00:00:00');
    }
    if (data.to_date) {
      data.to_date = moment(data.to_date, ['MM/DD/YYYY']).format('YYYY-MM-DD 00:00:00');
    }
  },

  addMoreLongTermFields () {
    this.longTermForm.discounts.push({
      number_of_days: null,
      discount: null
    });
  },

  removeLongTermFields (i, e) {
    e.preventDefault();
    this.longTermForm.discounts.splice(i, 1);
  },

  selectAll (type) {

    if (type == 'channels') {
      //Note: type of channels is OBJECT
      for (let i in this.location.channels) {
        this.form.channels.push(this.location.channels[i].id);
      }
    } else if (type == 'parkingTypes') {
      this.parkingTypes.forEach((item) => {
        this.form.parking_types.push(item.id);
      });
    } else if (type == 'roomTypes') {
      this.roomTypes.forEach((item) => {
        this.form.room_types.push(item.id);
      });
    } else if (type == 'bundleTypes') {
      this.bundleTypes.forEach((item) => {
        this.form.bundle_types.push(item.id);
      });
    }
  },

  selectNone (type) {

    if (type == 'channels') {
        this.form.channels = [];
    } else if (type == 'parkingTypes') {
        this.form.parking_types = [];
    } else if (type == 'roomTypes') {
        this.form.room_types = [];
    } else if (type == 'bundleTypes') {
        this.form.bundle_types = [];
    }
  },

  canUncheckDay (day) {
    let count = 0;
    for (let i in this.selected_days) {
      if (!this.selected_days[i] && day != i) {
        count++;
      }
    }

    return count < 6;
  },

  isLocationUser () {
    return !permits.hasPermit('super_admin') && permits.hasPermit('location_user');
  },

  parkingTypeChanged() {
    let _hasAutomation = false;

    for (let i = 0; i < this.parkingTypes.length; i++) {
      let ptype = this.parkingTypes[i];
      if (ptype.has_automation && this.form.parking_types.includes(ptype.id)) {
        _hasAutomation = true;
        console.log('_hasAutomation', ptype);
        break;
      }
    }
    this.hasAutomation = _hasAutomation;
    console.log('this.hasAutomation', this.hasAutomation);
  },
}

export default {
  name: 'manual-adjustment',
  components,
  computed,
  methods,
  directives,
  props: ['parkingTypes', 'roomTypes', 'bundleTypes'],
  data () {
    return {
      loading: true,
      processing: false,
      typesContainsDynamicAdj: false,
      hasAutomation: false,
      errors: {},
      locId: this.$route.params.id,
      type: "adjustment",//adjustment, long_term, adjustment_bundles
      daysOfWeek: [
        "sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"
      ],
      selected_days: {
        saturday: true,
        sunday: true,
        monday: true,
        tuesday: true,
        wednesday: true,
        thursday: true,
        friday: true
      },
      form: {
        from_date: null,
        to_date: null,
        room_types: [],
        parking_types: [],
        bundle_types: [],
        channels: []
      },
      adjustmentForm: {
        type: 'increase', // increase, decrease
        unit: 'percentage',// percentage, fixed
        change_price_reason: null,
        amount: null
      },
      longTermForm: {
        discounts: [
          {
            number_of_days: null,
            discount: null
          }
        ]
      }
    }
  },
  created () {

    for (let i = 0; i < this.parkingTypes.length; i++) {
      if (this.parkingTypes[i].has_automation) {
        this.typesContainsDynamicAdj = true;
        break;
      }
    }

    if (permits.hasLocationPermit(this.locId, 'locationRate_adjustment_create')) {
      this.type = 'adjustment'
    } else if (permits.hasLocationPermit(this.locId, 'longTermDiscount_create')) {
      this.type = 'long_term'
    }

    Promise.all([
    ]).then(() => {
      this.loading = false;
      this.selectAll('channels');
    });
  }
}
