var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "func-modal",
    {
      ref: "modal",
      attrs: { title: "Employee Details" },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function(props) {
            return [
              _c(
                "button",
                {
                  directives: [
                    {
                      name: "hasPermits",
                      rawName: "v-hasPermits:allow",
                      value: {
                        id: _vm.locId,
                        permits: ["locationEmployee_update"]
                      },
                      expression:
                        "{id: locId, permits: ['locationEmployee_update']}",
                      arg: "allow"
                    }
                  ],
                  staticClass: "btn btn-primary",
                  class: { disabled: _vm.processing },
                  attrs: { disabled: _vm.processing },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.submit(props)
                    }
                  }
                },
                [
                  _vm.processing
                    ? _c("span", [
                        _c("i", { staticClass: "fa fa-spin fa-spinner" }),
                        _vm._v("\n      Saving...\n    ")
                      ])
                    : _c("span", [_vm._v("\n      Save\n    ")])
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-default",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return props.close()
                    }
                  }
                },
                [_vm._v("Close")]
              )
            ]
          }
        }
      ])
    },
    [
      _vm.user
        ? _c("template", { slot: "body" }, [
            _c("div", { staticClass: "margin-bottom-3x" }, [
              _c("table", { staticClass: "table table-bordered" }, [
                _c("tbody", [
                  _c("tr", [
                    _c("th", [_vm._v(" Name ")]),
                    _vm._v(" "),
                    _c("td", [_vm._v(" " + _vm._s(_vm.user.name) + " ")])
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("th", [_vm._v(" Email ")]),
                    _vm._v(" "),
                    _c("td", [_vm._v(" " + _vm._s(_vm.user.email) + " ")])
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("th", [_vm._v(" Phone ")]),
                    _vm._v(" "),
                    _c("td", [_vm._v(" " + _vm._s(_vm.user.phone) + " ")])
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("th", [_vm._v(" Country ")]),
                    _vm._v(" "),
                    _c("td", [_vm._v(" " + _vm._s(this.country.name) + " ")])
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("th", [_vm._v(" State ")]),
                    _vm._v(" "),
                    _c("td", [_vm._v(" " + _vm._s(this.state.name) + " ")])
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("th", [_vm._v(" City ")]),
                    _vm._v(" "),
                    _c("td", [_vm._v(" " + _vm._s(_vm.user.city) + " ")])
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("th", [_vm._v(" Zip Code ")]),
                    _vm._v(" "),
                    _c("td", [_vm._v(" " + _vm._s(_vm.user.zip_code) + " ")])
                  ])
                ])
              ])
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "hasPermits",
                    rawName: "v-hasPermits:allow",
                    value: {
                      id: _vm.locId,
                      permits: ["locationEmployee_update"]
                    },
                    expression:
                      "{id: locId, permits: ['locationEmployee_update']}",
                    arg: "allow"
                  }
                ]
              },
              [
                _c("label", [_vm._v("\n        Role:\n      ")]),
                _vm._v(" "),
                _vm.errors.role
                  ? _c("div", { staticClass: "text-danger" }, [
                      _vm._v(
                        "\n        " + _vm._s(_vm.errors.role[0]) + "\n      "
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "ul",
                  { staticClass: "row no-padding-all checkbox" },
                  _vm._l(_vm.roles, function(r) {
                    return _c(
                      "li",
                      { staticClass: "col-sm-4 no-bullet margin-bottom-2x" },
                      [
                        _c("label", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.role,
                                expression: "role"
                              }
                            ],
                            attrs: { type: "radio" },
                            domProps: {
                              value: r.id,
                              checked: _vm._q(_vm.role, r.id)
                            },
                            on: {
                              change: function($event) {
                                _vm.role = r.id
                              }
                            }
                          }),
                          _vm._v("\n            " + _vm._s(r.display_name)),
                          _c("br"),
                          _vm._v(" "),
                          _c("small", [_vm._v(_vm._s(r.description))])
                        ])
                      ]
                    )
                  }),
                  0
                )
              ]
            )
          ])
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-d29697dc", { render: render, staticRenderFns: staticRenderFns })
  }
}