import moment from 'moment'
import VueCal from 'vue-cal'
import { currencySymbol } from 'filters'

const components = {
  VueCal,
}

const computed = {
  channel () {
    return _.cloneDeep(this.$store.state.locations.activeChannel.channel);
  },
  location () {
    return _.cloneDeep(this.$store.state.locations.active);
  },
  calendarRates () {
    let types = _.cloneDeep(this.$store.state.locations.calendarRates);
    for (let i in types) {
      types[i].rates = _.sortBy(types[i].rates, ['id']).reverse();
    }
    return types;
  },

  events () {
    let events = [];
    let types = _.cloneDeep(this.$store.state.locations.calendarRates);
    let selectedType = null;
    if (this.type == 'all') {
      selectedType = types['all'];
    } else {
      selectedType = types[0];
    }
    
    if (!selectedType) {
      return [];
    }

    let dates_data = selectedType.dates_data;
    const keys = Object.keys(dates_data);
    for (let i in keys) {
      let key = keys[i];
      if (i == 0) {
        this.selectedDate = key;
      }
      let arriving_event = {
        start: key,
        end: key,
        title: `${dates_data[key].arriving}`,
        content: '',
        class: 'entries'
      };
      events.push(arriving_event);

      let leaving_event = {
        start: key,
        end: key,
        title: `${dates_data[key].leaving}`,
        content: '',
        class: 'exits'
      };
      events.push(leaving_event);

      if (this.type != 'all') {
        let available = parseInt(`${dates_data[key].available}`);
        let available_event = {
          start: key,
          end: key,
          title: selectedType.is_large ? 0 : `${available}`,
          content: '',
          class: 'available'
        };
        events.push(available_event);

        let price = this.priceInThisDate (selectedType, moment(key, ['YYYY-MM-DD']));
        let rate_event = {
          start: key,
          end: key,
          title: `${currencySymbol(this.location.currency.code)}${price}`,
          content: '',
          class: 'price'
        };
        events.push(rate_event);
      }
    }
    return events;
  }
}

const filters = {
  currencySymbol,
}

const methods = {
  priceInThisDate (type, date) {
    let day_name = date.format('dddd').toLowerCase();
    for (let i in this.calendarRates) {
      if (type.id == this.calendarRates[i].id) {
        let rates = this.calendarRates[i].rates;
        for (let j in rates) {
          let start_date = moment(rates[j].from_date, ['YYYY-MM-DD']);
          let to_date = rates[j].to_date ? rates[j].to_date : '2099-12-31';
          let end_date = moment(to_date, ['YYYY-MM-DD']);
          if (date.isBetween(start_date, end_date, null, "[]") && rates[j][day_name]) {
            return rates[j].price;
          }
        }
        break;
      }
    }

    return 0;
  },

  viewChange (name, event) {
    let viewType = event.view;
    let startDate = event.startDate;
    if (viewType == 'month') {
      let newStartDate = moment(startDate);
      this.$emit('changeCalenderStartDate', newStartDate);
    }
  },

  gotToday () {
    this.$refs.vuecal.switchView('month');
  },

  availabilityAtDate (type, date, addDays) {
    for (let i in this.calendarRates) {
      if (type.id == this.calendarRates[i].id) {
        return this.calendarRates[i].available_dates[
          moment(date, ['MM/DD/YYYY']).add(addDays, 'days').format('DD-MM-YYYY')
        ] || 0;
      }
    }

    return 0;
  }
}

export default {
  name: "calendar-view",
  props: ['numberOfDays', 'rateTypes', 'fromDate', 'type'],
  components,
  filters,
  computed,
  methods,
  data () {
    return {
      selectedDate: this.fromDate,
      moment
    }
  },
  mounted () {
    $(this.$refs.cells).tooltip()
  }
}
