var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "nav nav-pills nav-justified margin-bottom-4x" },
    [
      _c(
        "li",
        {
          class: {
            active: _vm.$route.name == "locations.administration.channels"
          },
          attrs: { role: "presentation" }
        },
        [
          _c(
            "router-link",
            {
              attrs: {
                to: {
                  name: "locations.administration.channels",
                  params: {
                    id: _vm.$route.params.id
                  }
                }
              }
            },
            [_vm._v("\n      Channels\n    ")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "li",
        {
          directives: [
            {
              name: "hasPermits",
              rawName: "v-hasPermits:allow",
              value: { id: _vm.locId, permits: ["reservations_list"] },
              expression: "{id: locId, permits: ['reservations_list']}",
              arg: "allow"
            }
          ],
          class: {
            active: _vm.$route.name == "locations.administration.reservations"
          },
          attrs: { role: "presentation" }
        },
        [
          _c(
            "router-link",
            {
              attrs: {
                to: {
                  name: "locations.administration.reservations",
                  params: {
                    id: _vm.$route.params.id
                  }
                }
              }
            },
            [_vm._v("\n      Reservations\n    ")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "li",
        {
          directives: [
            {
              name: "hasPermits",
              rawName: "v-hasPermits:allow",
              value: { id: _vm.locId, permits: ["locationEmployee_list"] },
              expression: "{id: locId, permits: ['locationEmployee_list']}",
              arg: "allow"
            }
          ],
          class: {
            active: _vm.$route.name == "locations.administration.employees"
          },
          attrs: { role: "presentation" }
        },
        [
          _c(
            "router-link",
            {
              attrs: {
                to: {
                  name: "locations.administration.employees",
                  params: {
                    id: _vm.$route.params.id
                  }
                }
              }
            },
            [_vm._v("\n      Users\n    ")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "li",
        {
          directives: [
            {
              name: "hasPermits",
              rawName: "v-hasPermits:allow",
              value: { id: _vm.locId, permits: ["locationEmails_list"] },
              expression: "{id: locId, permits: ['locationEmails_list']}",
              arg: "allow"
            }
          ],
          class: {
            active: _vm.$route.name == "locations.administration.emails"
          },
          attrs: { role: "presentation" }
        },
        [
          _c(
            "router-link",
            {
              attrs: {
                to: {
                  name: "locations.administration.emails",
                  params: {
                    id: _vm.$route.params.id
                  }
                }
              }
            },
            [_vm._v("\n      Location Emails\n    ")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-d1b4eae6", { render: render, staticRenderFns: staticRenderFns })
  }
}