var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-modal",
    {
      attrs: {
        title: "New Channels Room Rate",
        label: "New Room Rate",
        "btn-class": "btn btn-primary",
        "modal-class": "text-left"
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function(props) {
            return [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.submit(props)
                    }
                  }
                },
                [_vm._v("Save")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-default",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return props.close()
                    }
                  }
                },
                [_vm._v("Close")]
              )
            ]
          }
        }
      ])
    },
    [
      _c("template", { slot: "body" }, [
        _c("form", [
          _c("label", [_vm._v("Select Room Type")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "input-group" },
            [
              _c(
                "span",
                {
                  staticClass: "input-group-addon",
                  attrs: { id: "basic-addon1" }
                },
                [
                  _c("i", {
                    staticClass: "fa fa-car",
                    attrs: { "aria-hidden": "true" }
                  })
                ]
              ),
              _vm._v(" "),
              _c("s-select", {
                attrs: { selected: _vm.form.type, options: _vm.types },
                on: { selectedChanged: _vm.typeChanged }
              })
            ],
            1
          ),
          _vm._v(" "),
          _vm.errors.location_room_type_id
            ? _c("div", { staticClass: "text-danger" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.errors.location_room_type_id[0]) +
                    "\n      "
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("label", [_vm._v("Standard Price")]),
          _vm._v(" "),
          _c("div", { staticClass: "input-group" }, [
            _c(
              "span",
              {
                staticClass: "input-group-addon",
                attrs: { id: "basic-addon1" }
              },
              [
                _c("i", {
                  staticClass: "fa fa-usd",
                  attrs: { "aria-hidden": "true" }
                })
              ]
            ),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.price,
                  expression: "form.price"
                }
              ],
              staticClass: "form-control",
              attrs: { type: "text" },
              domProps: { value: _vm.form.price },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.form, "price", $event.target.value)
                }
              }
            })
          ]),
          _vm._v(" "),
          _vm.errors.price
            ? _c("div", { staticClass: "text-danger" }, [
                _vm._v("\n        " + _vm._s(_vm.errors.price[0]) + "\n      ")
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("label", [_vm._v("From Date")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "input-group" },
            [
              _c(
                "span",
                {
                  staticClass: "input-group-addon",
                  attrs: { id: "basic-addon1" }
                },
                [
                  _c("i", {
                    staticClass: "fa fa-calendar",
                    attrs: { "aria-hidden": "true" }
                  })
                ]
              ),
              _vm._v(" "),
              _c("datepicker", {
                staticClass: "form-control",
                model: {
                  value: _vm.form.from_date,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "from_date", $$v)
                  },
                  expression: "form.from_date"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _vm.errors.from_date
            ? _c("div", { staticClass: "text-danger" }, [
                _vm._v(
                  "\n        " + _vm._s(_vm.errors.from_date[0]) + "\n      "
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("label", [_vm._v("To Date")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "input-group" },
            [
              _c(
                "span",
                {
                  staticClass: "input-group-addon",
                  attrs: { id: "basic-addon1" }
                },
                [
                  _c("i", {
                    staticClass: "fa fa-calendar",
                    attrs: { "aria-hidden": "true" }
                  })
                ]
              ),
              _vm._v(" "),
              _c("datepicker", {
                staticClass: "form-control",
                attrs: { "min-date": _vm.form.from_date },
                model: {
                  value: _vm.form.to_date,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "to_date", $$v)
                  },
                  expression: "form.to_date"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _vm.errors.to_date
            ? _c("div", { staticClass: "text-danger" }, [
                _vm._v(
                  "\n        " + _vm._s(_vm.errors.to_date[0]) + "\n      "
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "hasPermits",
                  rawName: "v-hasPermits:allow",
                  value: ["super_admin"],
                  expression: "['super_admin']",
                  arg: "allow"
                }
              ],
              staticClass: "form-input"
            },
            [
              _c("label", [_vm._v("Reason to change the price")]),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.change_price_reason,
                      expression: "form.change_price_reason"
                    }
                  ],
                  staticClass: "form-control",
                  on: {
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.form,
                        "change_price_reason",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    }
                  }
                },
                [
                  _c("option", { domProps: { value: null } }, [
                    _vm._v("-- Select Reason --")
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "Upcoming holiday" } }, [
                    _vm._v(" Upcoming holiday ")
                  ]),
                  _vm._v(" "),
                  _c(
                    "option",
                    {
                      attrs: {
                        value: "Expected change in demand (increase/decrease)"
                      }
                    },
                    [_vm._v(" Expected change in demand (increase/decrease) ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "option",
                    {
                      attrs: {
                        value:
                          "Unexpected change in supply (excess/shortage of spaces)"
                      }
                    },
                    [
                      _vm._v(
                        " Unexpected change in supply (excess/shortage of spaces) "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "option",
                    {
                      attrs: {
                        value: "Random (e.g. location request without reason)"
                      }
                    },
                    [_vm._v(" Random (e.g. location request without reason) ")]
                  )
                ]
              ),
              _vm._v(" "),
              _vm.errors.change_price_reason
                ? _c("div", { staticClass: "text-danger" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.errors.change_price_reason[0]) +
                        "\n        "
                    )
                  ])
                : _vm._e()
            ]
          ),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          _c("label", [_vm._v("Channels")]),
          _vm._v(" "),
          _vm.hasSelectChannelsPermit
            ? _c("div", { staticClass: "margin-top-2x" }, [
                _c(
                  "a",
                  {
                    attrs: { href: "#" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.selectAll()
                      }
                    }
                  },
                  [_vm._v("Select All")]
                ),
                _vm._v("  \n        "),
                _c(
                  "a",
                  {
                    attrs: { href: "#" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.selectNone()
                      }
                    }
                  },
                  [_vm._v("None")]
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.channels.length
            ? _c("div", [
                _c("table", { staticClass: "table table-striped" }, [
                  _c("thead", [
                    _c("tr", [
                      _c("th", [_vm._v(" Select ")]),
                      _vm._v(" "),
                      _c("th", [_vm._v(" Channel Name ")]),
                      _vm._v(" "),
                      _c("th", [_vm._v(" Channel Domain ")])
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "tbody",
                    _vm._l(_vm.channels, function(item) {
                      return _c("tr", [
                        _c("td", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.selectedChannels,
                                expression: "selectedChannels"
                              }
                            ],
                            attrs: {
                              disabled: !_vm.hasSelectChannelsPermit,
                              type: "checkbox"
                            },
                            domProps: {
                              value: item,
                              checked: Array.isArray(_vm.selectedChannels)
                                ? _vm._i(_vm.selectedChannels, item) > -1
                                : _vm.selectedChannels
                            },
                            on: {
                              change: function($event) {
                                var $$a = _vm.selectedChannels,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = item,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      (_vm.selectedChannels = $$a.concat([$$v]))
                                  } else {
                                    $$i > -1 &&
                                      (_vm.selectedChannels = $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1)))
                                  }
                                } else {
                                  _vm.selectedChannels = $$c
                                }
                              }
                            }
                          })
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(_vm._s(item.channel ? item.channel.name : ""))
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            _vm._s(item.channel ? item.channel.domain : "")
                          )
                        ])
                      ])
                    }),
                    0
                  )
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.errors.channels
            ? _c("div", { staticClass: "text-danger" }, [
                _vm._v("\n    " + _vm._s(_vm.errors.channels[0]) + "\n  ")
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("br")
        ])
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-3e0002c4", { render: render, staticRenderFns: staticRenderFns })
  }
}