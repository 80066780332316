var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-sm-8 col-sm-offset-2 margin-top-3x" }, [
      _c(
        "div",
        { staticClass: "text-right" },
        [
          _c("new-employee", {
            directives: [
              {
                name: "hasPermits",
                rawName: "v-hasPermits:allow",
                value: ["channel_create_user"],
                expression: "['channel_create_user']",
                arg: "allow"
              }
            ]
          }),
          _vm._v(" "),
          _c("view-employee", {
            ref: "editModal",
            staticClass: "text-left f14",
            attrs: {
              canOpen: !_vm._.isEmpty(_vm.selectedUser),
              userData: _vm.selectedUser
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.formData.users.length
        ? _c(
            "div",
            { staticClass: "table-responsive" },
            [
              _c(
                "table",
                { staticClass: "table table-striped margin-top-3x" },
                [
                  _c("thead", [
                    _c("tr", [
                      _c("th", [_vm._v(" Employee Name ")]),
                      _vm._v(" "),
                      _c("th", [_vm._v(" Email ")]),
                      _vm._v(" "),
                      _c("th", [_vm._v(" Details ")]),
                      _vm._v(" "),
                      _c(
                        "th",
                        {
                          directives: [
                            {
                              name: "hasPermits",
                              rawName: "v-hasPermits:allow",
                              value: ["super_admin"],
                              expression: "['super_admin']",
                              arg: "allow"
                            }
                          ]
                        },
                        [_vm._v(" Send Email ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "th",
                        {
                          directives: [
                            {
                              name: "hasPermits",
                              rawName: "v-hasPermits:allow",
                              value: ["channel_delete_user"],
                              expression: "['channel_delete_user']",
                              arg: "allow"
                            }
                          ]
                        },
                        [_vm._v(" Remove ")]
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "tbody",
                    _vm._l(_vm.formData.users, function(emp) {
                      return _c("tr", [
                        _c("td", [_vm._v(" " + _vm._s(emp.user.name) + " ")]),
                        _vm._v(" "),
                        _c("td", [_vm._v(" " + _vm._s(emp.user.email) + " ")]),
                        _vm._v(" "),
                        _c("td", [
                          _c(
                            "a",
                            {
                              staticClass: "text-info",
                              attrs: { href: "#" },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.openEditModal(emp)
                                }
                              }
                            },
                            [
                              _c("i", {
                                staticClass: "fa fa-ellipsis-h",
                                attrs: { "aria-hidden": "true" }
                              })
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "td",
                          {
                            directives: [
                              {
                                name: "hasPermits",
                                rawName: "v-hasPermits:allow",
                                value: ["super_admin"],
                                expression: "['super_admin']",
                                arg: "allow"
                              }
                            ]
                          },
                          [
                            _c("div", { staticClass: "checkbox" }, [
                              _c("label", [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: emp.send_email,
                                      expression: "emp.send_email"
                                    }
                                  ],
                                  attrs: { type: "checkbox" },
                                  domProps: {
                                    checked: Array.isArray(emp.send_email)
                                      ? _vm._i(emp.send_email, null) > -1
                                      : emp.send_email
                                  },
                                  on: {
                                    change: function($event) {
                                      var $$a = emp.send_email,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              emp,
                                              "send_email",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              emp,
                                              "send_email",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(emp, "send_email", $$c)
                                      }
                                    }
                                  }
                                })
                              ])
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          {
                            directives: [
                              {
                                name: "hasPermits",
                                rawName: "v-hasPermits:allow",
                                value: ["channel_delete_user"],
                                expression: "['channel_delete_user']",
                                arg: "allow"
                              }
                            ]
                          },
                          [
                            _vm.delete_loadings[emp.id]
                              ? _c("span", [
                                  _c("i", {
                                    staticClass: "fa fa-spin fa-spinner"
                                  })
                                ])
                              : _c(
                                  "a",
                                  {
                                    staticClass: "text-danger",
                                    attrs: { href: "#" },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        return _vm.removeEmployee(emp)
                                      }
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "fa fa-times",
                                      attrs: { "aria-hidden": "true" }
                                    })
                                  ]
                                )
                          ]
                        )
                      ])
                    }),
                    0
                  )
                ]
              ),
              _vm._v(" "),
              _c("confirm", { ref: "removeConfirm", attrs: { type: "danger" } })
            ],
            1
          )
        : _c("div", { staticClass: "text-warning" }, [
            _vm._v(
              "\n      There are no employees assigned to this location\n    "
            )
          ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-6b9479bd", { render: render, staticRenderFns: staticRenderFns })
  }
}