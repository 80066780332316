import toastr from 'toastr'
import bus from 'bus'
import moment from 'moment'
import layout from 'layouts/default'
import datepicker from 'components/datepicker'
import tabs from '../tabs'
import { GChart } from 'vue-google-charts'

const components = {
  layout,
  datepicker,
  tabs,
  GChart
}

const computed = {
    location () {
      return _.cloneDeep(this.$store.state.locations.active)
    },
   
    demandLineData() {
      return _.cloneDeep(this.$store.state.analytics.salesPerformance.demand_line)
    },

    revenueData() {
      return _.cloneDeep(this.$store.state.analytics.salesPerformance.revenue)
    },

    conversionRate() {
      return _.cloneDeep(this.$store.state.analytics.salesPerformance.conversion_rate)
    },

    demandLineDataFinalResult() {
      let filter = this.form.filter;
      if (!this.demandLineData || this.chartLoading || filter == 'room') {
        return;
      }
      this.chartsOptions.demandLine.series = [];
      try {
        var data = new google.visualization.DataTable();
      } catch (e) {
        return;
      }
      data.addColumn('string', 'Day');
      if (filter == null || filter == 'parking') {
        this.chartsOptions.demandLine.series.push({axis: 'sales', color: this.colors.light_blue});
        this.chartsOptions.demandLine.series.push({axis: 'searches', color:  this.colors.dark_blue});
        data.addColumn('number', "Sales Parking");
        data.addColumn('number', "Search Parking");
      }

      if (filter == null || filter == 'bundle') {
        this.chartsOptions.demandLine.series.push({axis: 'sales', color: '#ffdc00'});
        this.chartsOptions.demandLine.series.push({axis: 'searches', color: '#d0b300'});

        data.addColumn('number', "Sales Bundle");
        data.addColumn('number', "Search Bundle");
      }
      
      let result = []

      _.each(this.demandLineData, (row, index) => {
        let item = [];
        item.push(moment(index, ['YYYY-MM-DD']).format('MM/DD'));

        if (filter == null || filter == 'parking') {
          item.push(row.sales.parking,row.searches.parking);
        }

        if (filter == null || filter == 'bundle') {
          item.push(row.sales.bundle,row.searches.bundle);
        }
        result.push(item);
      });

      data.addRows(result);

      return data;
    },

    revenueDataFinalResult() {
      if (!this.revenueData || this.chartLoading) {
        return;
      }
      let filter = this.form.filter;
      try {
        var data = new google.visualization.DataTable();
      } catch (e) {
        return;
      }
      data.addColumn('string', 'Day');
      this.chartsOptions.revenue.series = [];
      if (filter == null || filter == 'room') {
        this.chartsOptions.revenue.series.push({axis: 'min_price', color: this.colors.light_red});
        this.chartsOptions.revenue.series.push({axis: 'sales', color: this.colors.dark_red });
        data.addColumn('number', "Room Min Price");
        data.addColumn('number', "Rooms Sales");
      }

      if (filter == null || filter == 'parking') {
        this.chartsOptions.revenue.series.push({axis: 'min_price', color: this.colors.light_blue})
        this.chartsOptions.revenue.series.push({axis: 'sales', color: this.colors.dark_blue})
        data.addColumn('number', "Parking Min Price");
        data.addColumn('number', "Parking Sales");
      }

      if (filter == null || filter == 'bundle') {
        this.chartsOptions.revenue.series.push({axis: 'min_price', color: this.colors.light_yellow})
        this.chartsOptions.revenue.series.push({axis: 'sales', color: this.colors.dark_yellow});
        data.addColumn('number', "Bundle Min Price");
        data.addColumn('number', "Bundles Sales");  
      }
      
      let result = []

      _.each(this.revenueData, (row, index) => {

        let item = [];
        item.push(moment(index, ['YYYY-MM-DD']).format('MM/DD'));

        if (filter == null || filter == 'room') {
          item.push(row.min_price.room,row.sales.room);
        }

        if (filter == null || filter == 'parking') {
          item.push(row.min_price.parking,row.sales.parking);
        }

        if (filter == null || filter == 'bundle') {
          item.push(row.min_price.bundle,row.sales.bundle);
        }

        result.push(item);
        
      });

      data.addRows(result);

      return data;
    },

    conversionRateFinalResult() {
      let filter = this.form.filter;
      if (!this.conversionRate || this.chartLoading || filter == 'room') {
        return;
      }

      try {
        var data = new google.visualization.DataTable();
      } catch (e) {
        return;
      }
      data.addColumn('string', 'Day');


      if (filter == null || filter == 'parking') {
        data.addColumn('number', "Parking (%)");
      }
      if (filter == null || filter == 'bundle') {
        data.addColumn('number', "Bundle (%)");
      }
      
      
      let result = []
      this.chartsOptions.conversionRate.colors = [];
      _.each(this.conversionRate, (row, index) => {

        let item = [];
        item.push(moment(index, ['YYYY-MM-DD']).format('MM/DD'));
        if (filter == 'parking') {
          this.chartsOptions.conversionRate.colors.push(this.colors.light_blue);
        }
        if (filter == 'bundle') {
          
          this.chartsOptions.conversionRate.colors.push(this.colors.light_yellow);
        }
        if (filter == null) {
          this.chartsOptions.conversionRate.colors.push(this.colors.light_blue);
          this.chartsOptions.conversionRate.colors.push(this.colors.light_yellow);
        }
        if (filter == null || filter == 'parking') {
          item.push(row.parking);
        }

        if (filter == null || filter == 'bundle') {
          item.push(row.bundle);
        }

        result.push(item);
      });

      data.addRows(result);

      return data;
    },
}

const watch = {
  ['form.to_date'] (n, o) {
    
    let to_date_unformated = moment(this.form.to_date, [this.dateFormat]);
    let from_date_unformated = moment(to_date_unformated).subtract(this.number_of_days, 'days');
    this.form.from_date = from_date_unformated.format(this.dateFormat);
    this.form.to_date = to_date_unformated.format(this.dateFormat);

    if (this.timeout) {
      clearTimeout(this.timeout)
    }

    this.timeout = setTimeout(() => {
      this.getChartsData();
    }, 200);
  },

  ['form.filter'] () {
    if (this.timeout) {
      clearTimeout(this.timeout)
    }

    this.timeout = setTimeout(() => {
      this.getChartsData();
    }, 200);
  }
}

const methods = {
  
  parseDates (data) {
    if (data.from_date) {
      data.from_date = moment(data.from_date, [this.dateFormat]).format('YYYY-MM-DD');
    }
    if (data.to_date) {
      data.to_date = moment(data.to_date, [this.dateFormat]).format('YYYY-MM-DD');
    }
    if (!data.filter) {
      delete data.filter;
    }
    return data;
  },
  setTab(tab) {
    this.selectedTab = tab;
  },
  getChartsData () {

    if (!this.form.from_date)
      return;
    this.chartLoading = true;
    let data = _.clone(this.form);
    data = this.parseDates(data);
    let filter = this.form.filter;

    let promises = [];

    if (filter === 'room') {
      promises.push(
        this.$store.dispatch('analytics.salesPerformance.revenue', {
          id: this.$route.params.id,
          params: _.cloneDeep(data)
        })
      );
    }

    if (filter !== 'room') {
      promises.push(
        this.$store.dispatch('analytics.salesPerformance.demandLine', {
          id: this.$route.params.id,
          params: _.cloneDeep(data)
        }),

        this.$store.dispatch('analytics.salesPerformance.conversionRate', {
          id: this.$route.params.id,
          params: _.cloneDeep(data)
        }),

        this.$store.dispatch('analytics.salesPerformance.revenue', {
          id: this.$route.params.id,
          params: _.cloneDeep(data)
        })
      )
    }

    Promise.all(promises).then(() => {
      this.$nextTick(() =>{
        this.chartLoading = false;
      })
    });

  },

  goBackToToday () {
    this.form.to_date = moment().format(this.dateFormat);
  },

  nextMonth (e) {
    e.preventDefault();
    // from_date becomes to_date
    let next = moment(this.form.to_date, [this.dateFormat]);
    let from_date = next.format(this.dateFormat);
    let to_date = next.add(this.number_of_days, 'days').format(this.dateFormat);

    this.form.from_date = from_date;
    this.form.to_date = to_date;
  },

  prevMonth (e) {
    e.preventDefault();
    // from_date becomes to_date
    let prev = moment(this.form.from_date, [this.dateFormat]);
    let to_date = prev.format(this.dateFormat);
    this.form.to_date = to_date;
  }
}

export default {
  name: 'sales-performance',
  components,
  computed,
  watch,
  methods,
  data () {
    let colors = {
      light_red: '#ff5050',
      dark_red: '#9b0b26',
      light_blue: '#2D7DD2',
      dark_blue: '#044389',
      light_yellow: '#ffdc00',
      dark_yellow: '#c8c600',
    }
    let dateFormat = 'MM/DD/YYYY';
    let number_of_days = 30;
    let to_date_unformated = moment();
    let to_date = to_date_unformated.format(dateFormat);
    let from_date_unformated = moment(to_date_unformated).subtract(number_of_days, 'days');
    let from_date = from_date_unformated.format(dateFormat);
    return {
      loading: true,
      chartLoading: false,
      showComponents: true,
      errors: {},
      timeout: null,
      number_of_days,
      dateFormat,
      colors,
      form: {
        from_date,
        to_date,
        filter: 'parking',
      },
      chartsOptions:{
        demandLine: {
          chart: {
            // title: 'Demand LINE'
          },
          series: [],
          height:200,
          axes: {
            y: {
              searches: {label: 'Searches'},
              sales: {label: 'Sales'}
            }
          }
        },
        revenue: {
          chart: {
            // title: 'Revenue Chart'
          },
          series: [],
          height:200,
          axes: {
            y: {
              min_price: {label: 'Min Price'},
              sales: {label: 'Sales'}
            }
          },

        },
        conversionRate: {
          chart: {
            // title: 'Conversion Rate'
          },
          hAxis: {
            title: 'Date',
          },
          height:200,
          
        }
      }
    }
  },
  mounted () {
    Promise.all([
      this.$store.dispatch('locations.get', this.$route.params.id),
    ]).then(() => {
      this.loading = false;
      this.getChartsData();
    });

    bus.$on('layout.sideBarToggled', () => {
      if (google) {
        this.showComponents = false;
        // timeout `301 ms` is due to the sidebar transition time
        setTimeout(() => {
          this.showComponents = true
        },301)
      }
    });
  }
}
