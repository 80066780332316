var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-modal",
    {
      attrs: {
        label: "New Room Type",
        title: "Add New Rooms Type",
        "btn-class": "btn-primary",
        "modal-class": "text-left"
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function(props) {
            return [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.submit(props)
                    }
                  }
                },
                [_vm._v("Save")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-default",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return props.close()
                    }
                  }
                },
                [_vm._v("Close")]
              )
            ]
          }
        }
      ])
    },
    [
      _c(
        "template",
        { slot: "body" },
        [
          _c("type-form", {
            attrs: { formData: _vm.form, errors: _vm.errors },
            on: {
              "update:formData": function($event) {
                _vm.form = $event
              },
              "update:form-data": function($event) {
                _vm.form = $event
              }
            }
          })
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-93a6932a", { render: render, staticRenderFns: staticRenderFns })
  }
}