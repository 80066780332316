import toastr from 'toastr'
import layout from 'layouts/default'
import {tabs, tab} from 'components/tabs'
import switchBtn from 'components/switchBtn'
import { general, locations, reservations, employees, fees, webhook } from './partials'
import { hasPermits } from 'directives'
import { user} from 'services'


const directives = {
  hasPermits
}

const components = {
  layout,
  tabs,
  tab,
  general,
  locations,
  fees,
  webhook,
  reservations,
  employees,
  switchBtn
}

const computed = {
  isAdmin () {
    return this.user && this.user.type == 'ims';
  },

  formData () {
    return _.cloneDeep(this.$store.state.channels.active);
  }
}

const methods = {
  onSubmitForm () {
    this.errors = {}
    this.submit_loading = true;
    this.$store.dispatch('channels.edit', {id: this.$route.params.id, data: _.omit(this.formData, 'key', 'locations')}).then(() => {
      this.submit_loading = false;
      if (this.$store.state.channels.errors) {
        if (this.$store.state.channels.errors.errors) {
            this.errors = _.cloneDeep(this.$store.state.channels.errors.errors);
        }
        toastr.error(this.$store.state.channels.errors.message);
      } else {
        toastr.success("Data has been saved");
      }
    })
  },
  getChannel () {
    this.loading = true;
    this.$store.dispatch('channels.get', this.$route.params.id).then(() => {
      this.loading = false;
    });
  }
}

const watch = {
  testing_mode (n) {
    console.log('test xxxxxx ', n);
    sessionStorage.setItem('__testing_mode_items__', n);
  }
}

export default {
  name: 'edit-channel',
  components,
  computed,
  methods,
  directives,
  watch,
  data () {
    return {
      loading: false,
      user: null,
      submit_loading: false,
      activeTab: {
        title: 'Locations'
      },
      testing_mode: false,
      errors: {}
    }
  },
  created () {
    this.getChannel();
  },
  mounted () {
    this.user = user.getUser();
    let testing_mode_from_storage = sessionStorage.getItem('__testing_mode_items__');

    if (testing_mode_from_storage == null || testing_mode_from_storage == 'null' || testing_mode_from_storage == 'false') {
      testing_mode_from_storage = false;
    } else {
      testing_mode_from_storage = true;
    }

    this.testing_mode = testing_mode_from_storage;
  },
  destroyed () {
    console.log('destroyed');
    
    this.$store.dispatch('channels.clearProductionKey');
  }
}
