var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {}, [
    _c("div", { staticClass: "text-right" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-default",
          on: {
            click: function($event) {
              $event.preventDefault()
              _vm.formActive = !_vm.formActive
            }
          }
        },
        [
          !_vm.formActive
            ? _c("i", {
                staticClass: "fa fa-caret-down",
                attrs: { "aria-hidden": "true" }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.formActive
            ? _c("i", {
                staticClass: "fa fa-caret-up",
                attrs: { "aria-hidden": "true" }
              })
            : _vm._e(),
          _vm._v("\n      Add an email\n    ")
        ]
      )
    ]),
    _vm._v(" "),
    _vm.formActive
      ? _c("div", { staticClass: "margin-top-3x text-left" }, [
          _c("div", { staticClass: "panel panel-default" }, [
            _c("div", { staticClass: "panel-body" }, [
              _c("form", [
                _c("div", { staticClass: "form-group" }, [
                  _c("label", [_vm._v("Email address")]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.email,
                        expression: "form.email"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: { type: "email", placeholder: "Email" },
                    domProps: { value: _vm.form.email },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.form, "email", $event.target.value)
                      }
                    }
                  }),
                  _vm._v(" "),
                  _vm.errors.email
                    ? _c("div", { staticClass: "text-danger" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.errors.email[0]) +
                            "\n            "
                        )
                      ])
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c("label", [_vm._v("Enable notifications for")]),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c("label", { staticClass: "checkbox-inline" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.parking,
                          expression: "form.parking"
                        }
                      ],
                      attrs: { type: "checkbox" },
                      domProps: {
                        checked: Array.isArray(_vm.form.parking)
                          ? _vm._i(_vm.form.parking, null) > -1
                          : _vm.form.parking
                      },
                      on: {
                        change: function($event) {
                          var $$a = _vm.form.parking,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(_vm.form, "parking", $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.form,
                                  "parking",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.form, "parking", $$c)
                          }
                        }
                      }
                    }),
                    _vm._v(" Parking\n            ")
                  ]),
                  _vm._v(" "),
                  _c("label", { staticClass: "checkbox-inline" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.room,
                          expression: "form.room"
                        }
                      ],
                      attrs: { type: "checkbox" },
                      domProps: {
                        checked: Array.isArray(_vm.form.room)
                          ? _vm._i(_vm.form.room, null) > -1
                          : _vm.form.room
                      },
                      on: {
                        change: function($event) {
                          var $$a = _vm.form.room,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(_vm.form, "room", $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.form,
                                  "room",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.form, "room", $$c)
                          }
                        }
                      }
                    }),
                    _vm._v(" Room\n            ")
                  ]),
                  _vm._v(" "),
                  _c("label", { staticClass: "checkbox-inline" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.daily_list,
                          expression: "form.daily_list"
                        }
                      ],
                      attrs: { type: "checkbox" },
                      domProps: {
                        checked: Array.isArray(_vm.form.daily_list)
                          ? _vm._i(_vm.form.daily_list, null) > -1
                          : _vm.form.daily_list
                      },
                      on: {
                        change: function($event) {
                          var $$a = _vm.form.daily_list,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.form,
                                  "daily_list",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.form,
                                  "daily_list",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.form, "daily_list", $$c)
                          }
                        }
                      }
                    }),
                    _vm._v(" Daily Check-in/out List\n            ")
                  ]),
                  _vm._v(" "),
                  _c(
                    "label",
                    {
                      directives: [
                        {
                          name: "hasPermits",
                          rawName: "v-hasPermits:prevent",
                          value: ["location_user"],
                          expression: "['location_user']",
                          arg: "prevent"
                        }
                      ],
                      staticClass: "checkbox-inline"
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.decision_maker,
                            expression: "form.decision_maker"
                          }
                        ],
                        attrs: { type: "checkbox" },
                        domProps: {
                          checked: Array.isArray(_vm.form.decision_maker)
                            ? _vm._i(_vm.form.decision_maker, null) > -1
                            : _vm.form.decision_maker
                        },
                        on: {
                          change: function($event) {
                            var $$a = _vm.form.decision_maker,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(
                                    _vm.form,
                                    "decision_maker",
                                    $$a.concat([$$v])
                                  )
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    _vm.form,
                                    "decision_maker",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(_vm.form, "decision_maker", $$c)
                            }
                          }
                        }
                      }),
                      _vm._v(" Decision Maker\n            ")
                    ]
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "text-right" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-success",
                    class: { disabled: _vm.processing },
                    attrs: { disabled: _vm.processing },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.submit()
                      }
                    }
                  },
                  [
                    _vm.processing
                      ? _c("span", [
                          _c("i", { staticClass: "fa fa-spinner fa-spin" })
                        ])
                      : _vm._e(),
                    _vm._v("\n            SAVE\n          ")
                  ]
                )
              ])
            ])
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-6373270e", { render: render, staticRenderFns: staticRenderFns })
  }
}