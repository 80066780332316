import user from './user'
import http from './http'

class Permits {

  hasPermit(permit) {
    let u = user.getUser();
    if (!u) return false;   
     
    if (u.permissions.indexOf('super_admin') > -1)
      return true;

    console.log();
    
    return u.permissions.indexOf(permit) > -1;
  }

  //check if the user has at least one permission from the list
  hasPermits(arr = []) {
    for (let i in arr) {
      if (this.hasPermit(arr[i])) {
        return true;
      }
    }

    return false;
  }

  hasLocationPermit (locationId, permit) {
    let u = user.getUser();
    if (!u) return false;
    
    if (u.permissions.indexOf('super_admin') > -1)
      return true;

    if (u.permissions.indexOf('location_user') == -1) {
      return this.hasPermit(permit);
    }

    let i = _.findIndex(u.locations, (item) => item.location_id == locationId);

    if (i < 0) return false

    return _.findIndex(u.locations[i].roles[0].permissions, (item) => item.name == permit) > -1
  }

  hasLocationPermits (locationId, arr = []) {
    for (let i in arr) {
      if (this.hasLocationPermit(locationId, arr[i])) {
        return true;
      }
    }

    return false;
  }

  handleHttpQ (q = []) {
    let urls = [];
    for (let i in q) {
      if (this.hasPermits(q[i].permits) ||
        this.hasLocationPermits(q[i].id, q[i].permits)) {
        urls.push(http.get(q[i].url));
      }

    }

    return urls;
  }
}

export default new Permits();
