import _ from 'lodash'
import { baseModal } from 'components/modals'
import sSelect from 'components/sSelect'
import typeForm from './form'

const components = {
  sSelect,
  baseModal,
  typeForm
}

const methods = {
  submit (modal) {
    let errors = false;
    this.errors = {};

    //validating totals
    let added_rooms = 0;
    for (let i in this.roomTypes) {
      added_rooms += parseInt(this.roomTypes[i].number_of_rooms);
    }

    console.log("added_rooms", added_rooms);
    console.log("added_rooms + this.form.roomsNumber", added_rooms + parseInt(this.form.number_of_rooms));

    if (added_rooms + parseInt(this.form.number_of_rooms) > this.totalRoomsNumber) {
      this.errors.number_of_rooms = ["Number of total rooms types must be less than or equal to " + this.totalRoomsNumber + ", " + parseInt(this.totalRoomsNumber - added_rooms) + ' left.'];
      errors = true;
    }

    if (errors) return;
    let data = _.cloneDeep(this.form)
    // console.log('this.form', data);
    // return;
    data.location_id = this.$route.params.id;

    this.$store.dispatch('locations.newLocationRoomType', data).then(() => {
      if (this.$store.state.locations.errors) {
        this.errors = _.cloneDeep(this.$store.state.locations.errors.errors);
      } else {
        this.form = {
          number_of_rooms: null,
          max_occupancy: null,
          standard_occupancy: null,
          extra_occupant_price: null,
          name: null,
          price_limit: null,
          beds: [
            {
              id: null,
              number_of_beds: 1
            }
          ],
          description: null,
        };
        modal.close();
      }
    });

  },
}

const computed = {
  roomTypes () {
    return _.cloneDeep(this.$store.state.locations.active.roomTypes);
  },
  totalRoomsNumber () {
    return this.$store.state.locations.active.number_of_rooms;
  }
}

export default {
  name: 'new-rooms-type',
  methods,
  components,
  computed,
  data () {
    return {
      form: {
        number_of_rooms: null,
        max_occupancy: null,
        standard_occupancy: null,
        extra_occupant_price: null,
        name: null,
        price_limit: null,
        beds: [
          {
            id: null,
            number_of_beds: 1
          }
        ],
        description: null,
      },
      errors: {}
    }
  }
}
