var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "nav nav-pills nav-justified margin-bottom-4x" },
    [
      _c(
        "li",
        {
          class: { active: _vm.$route.name == "locations.analytics.dashboard" },
          attrs: { role: "presentation" }
        },
        [
          _c(
            "router-link",
            {
              attrs: {
                to: {
                  name: "locations.analytics.dashboard",
                  params: {
                    id: _vm.$route.params.id
                  }
                }
              }
            },
            [_vm._v("\n      Dashboard\n    ")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "li",
        {
          directives: [
            {
              name: "hasPermits",
              rawName: "v-hasPermits:allow",
              value: { id: _vm.locId, permits: ["analytics_occupancy"] },
              expression: "{id: locId, permits: ['analytics_occupancy']}",
              arg: "allow"
            }
          ],
          class: { active: _vm.$route.name == "locations.analytics.occupancy" },
          attrs: { role: "presentation" }
        },
        [
          _c(
            "router-link",
            {
              attrs: {
                to: {
                  name: "locations.analytics.occupancy",
                  params: {
                    id: _vm.$route.params.id
                  }
                }
              }
            },
            [_vm._v("\n      Occupancy\n    ")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-5ecf493f", { render: render, staticRenderFns: staticRenderFns })
  }
}