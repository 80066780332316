import toastr from 'toastr'
import { hasPermits } from 'directives'
import sSelect from 'components/sSelect'
import { funcModal } from 'components/modals'

const computed = {
  locations () {
    return _.map(_.cloneDeep(this.channelLocations), item => {
      return {
        value: item.id,
        text: item.location.name
      }
    });
  }
}

const components = {
  funcModal,
  sSelect
}

const methods = {
  open () {
    this.$refs.modal.open();
  },

  submit (modal) {
    this.errors = {};
    this.processing = true;

    let data = _.cloneDeep(this.form);

    if (!data.all_locations) {
      data.locations = this.selectedLocations.map(location => location.value);
    }

    this.$store.dispatch('channels.updateFee', {
      chId: this.$route.params.id,
      id: data.id,
      data
    }).then(() => {
      this.processing = false;
      let errors = this.$store.state.channels.errors;
      if (errors) {
        if (errors.errors) {
          this.errors = _.cloneDeep(errors.errors);
        }
        toastr.error(errors.message);
      } else {
        toastr.success('data has been saved successfully');
        modal.close();
      }
    });
  },

  onChangeSelectedLocations (locations) {
    console.log('locations', locations);
    this.selectedLocations = locations;
  },

  removeSelected (selectedLocation) {
    console.log('selectedLocations', selectedLocation);
    this.selectedLocations = _.filter(this.selectedLocations, location => location.value != selectedLocation.value);

  }
}

const watch = {
  fee (n) {
    this.form = n;
    this.selectedLocations = _.map(this.form.channel_locations, item => (
      {
        value: item.id,
        text: item.location.name
      }
    ));
  }
}

const directives = {
  hasPermits
}

export default {
  name: "edit-fee",
  props: ['fee', 'channelLocations'],
  components,
  methods,
  computed,
  watch,
  directives,
  data () {
    return {
      processing: false,
      errors: {},
      form: this.fee,
      selectedLocations: [],
    }
  }
}
