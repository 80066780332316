var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {}, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "text-center margin-bottom-3x" }, [
      _c("span", [
        _vm._v(
          "\n      Total Check-outs: " +
            _vm._s(_vm.total_checkouts_count) +
            "\n    "
        )
      ]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("span", [
        _vm._v(
          "\n      Room Check-outs: " +
            _vm._s(_vm.room_checkouts_count) +
            "\n    "
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "input-group" }, [
      _c("input", {
        ref: "search",
        staticClass: "form-control",
        attrs: {
          type: "text",
          placeholder: "Search for reservations or customers..."
        },
        on: {
          keyup: function($event) {
            return _vm.onSearchKeyup(_vm.$refs.search.value, $event)
          }
        }
      }),
      _vm._v(" "),
      _c("span", { staticClass: "input-group-btn" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-default",
            attrs: { type: "button" },
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.search(_vm.$refs.search.value)
              }
            }
          },
          [
            _c("i", {
              staticClass: "fa fa-search",
              attrs: { "aria-hidden": "true" }
            })
          ]
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "margin-top-2x" }, [
      _c("label", { staticClass: "radio-inline" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.type,
              expression: "type"
            }
          ],
          attrs: { type: "radio", value: "all" },
          domProps: { checked: _vm._q(_vm.type, "all") },
          on: {
            change: [
              function($event) {
                _vm.type = "all"
              },
              _vm.getList
            ]
          }
        }),
        _vm._v(" All\n    ")
      ]),
      _vm._v(" "),
      _c("label", { staticClass: "radio-inline" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.type,
              expression: "type"
            }
          ],
          attrs: { type: "radio", value: "parkings" },
          domProps: { checked: _vm._q(_vm.type, "parkings") },
          on: {
            change: [
              function($event) {
                _vm.type = "parkings"
              },
              _vm.getList
            ]
          }
        }),
        _vm._v(" Parking\n    ")
      ]),
      _vm._v(" "),
      _c("label", { staticClass: "radio-inline" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.type,
              expression: "type"
            }
          ],
          attrs: { type: "radio", value: "rooms" },
          domProps: { checked: _vm._q(_vm.type, "rooms") },
          on: {
            change: [
              function($event) {
                _vm.type = "rooms"
              },
              _vm.getList
            ]
          }
        }),
        _vm._v(" Room\n    ")
      ]),
      _vm._v(" "),
      _c("label", { staticClass: "radio-inline" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.type,
              expression: "type"
            }
          ],
          attrs: { type: "radio", value: "both" },
          domProps: { checked: _vm._q(_vm.type, "both") },
          on: {
            change: [
              function($event) {
                _vm.type = "both"
              },
              _vm.getList
            ]
          }
        }),
        _vm._v(" Parking & Room\n    ")
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "margin-top-2x" }, [
      _c("label", { staticClass: "radio-inline" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.checked_out,
              expression: "checked_out"
            }
          ],
          attrs: { type: "radio", value: "both" },
          domProps: { checked: _vm._q(_vm.checked_out, "both") },
          on: {
            change: [
              function($event) {
                _vm.checked_out = "both"
              },
              _vm.getList
            ]
          }
        }),
        _vm._v(" All\n    ")
      ]),
      _vm._v(" "),
      _c("label", { staticClass: "radio-inline" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.checked_out,
              expression: "checked_out"
            }
          ],
          attrs: { type: "radio" },
          domProps: { value: false, checked: _vm._q(_vm.checked_out, false) },
          on: {
            change: [
              function($event) {
                _vm.checked_out = false
              },
              _vm.getList
            ]
          }
        }),
        _vm._v(" Not Checked out\n    ")
      ])
    ]),
    _vm._v(" "),
    _vm.loading
      ? _c("div", { staticClass: "margin-top-3x" }, [
          _c("i", { staticClass: "fa fa-spinner fa-spin fa-lg fa-fw" })
        ])
      : _c(
          "div",
          [
            _vm.list.length && _vm.noShowReservation.length < _vm.list.length
              ? _c(
                  "div",
                  {
                    staticClass: "table-responsive no-border padding-bottom-2x"
                  },
                  [
                    _c(
                      "table",
                      {
                        directives: [
                          {
                            name: "hasPermits",
                            rawName: "v-hasPermits:allow",
                            value: {
                              id: _vm.location.id,
                              permits: ["reservations_list"]
                            },
                            expression:
                              "{id: location.id, permits: ['reservations_list']}",
                            arg: "allow"
                          }
                        ],
                        staticClass: "table table-hover margin-top-3x"
                      },
                      [
                        _c("thead", [
                          _c("tr", [
                            _c(
                              "th",
                              {
                                directives: [
                                  {
                                    name: "hasPermits",
                                    rawName: "v-hasPermits:allow",
                                    value: {
                                      id: _vm.location.id,
                                      permits: ["reservations_checkin_checkout"]
                                    },
                                    expression:
                                      "{id: location.id, permits: ['reservations_checkin_checkout']}",
                                    arg: "allow"
                                  }
                                ]
                              },
                              [
                                _c("i", {
                                  staticClass: "fa fa-check-square-o",
                                  attrs: { "aria-hidden": "true" }
                                })
                              ]
                            ),
                            _vm._v(" "),
                            _c("th", [_vm._v("Customer Name")]),
                            _vm._v(" "),
                            _c("th", [_vm._v("Res. #")]),
                            _vm._v(" "),
                            _c("th", [_vm._v("Type")]),
                            _vm._v(" "),
                            _c("th", [_vm._v("Pick up")]),
                            _vm._v(" "),
                            _c(
                              "th",
                              {
                                directives: [
                                  {
                                    name: "hasPermits",
                                    rawName: "v-hasPermits:allow",
                                    value: {
                                      id: _vm.$route.params.id,
                                      permits: [
                                        "location_management",
                                        "super_admin"
                                      ]
                                    },
                                    expression:
                                      "{id: $route.params.id, permits: ['location_management', 'super_admin']}",
                                    arg: "allow"
                                  }
                                ]
                              },
                              [_vm._v("No Show")]
                            ),
                            _vm._v(" "),
                            _c("th", [_vm._v("View")]),
                            _vm._v(" "),
                            _c(
                              "th",
                              {
                                directives: [
                                  {
                                    name: "hasPermits",
                                    rawName: "v-hasPermits:allow",
                                    value: {
                                      id: _vm.location.id,
                                      permits: [
                                        "update_reservation_dates",
                                        "update_reservation"
                                      ]
                                    },
                                    expression:
                                      "{id: location.id, permits: ['update_reservation_dates', 'update_reservation']}",
                                    arg: "allow"
                                  }
                                ]
                              },
                              [_vm._v("Edit")]
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c(
                          "tbody",
                          _vm._l(_vm.list, function(item) {
                            return !item.history.reservation.no_show
                              ? _c("tr", [
                                  _c(
                                    "td",
                                    {
                                      directives: [
                                        {
                                          name: "hasPermits",
                                          rawName: "v-hasPermits:allow",
                                          value: {
                                            id: _vm.location.id,
                                            permits: [
                                              "reservations_checkin_checkout"
                                            ]
                                          },
                                          expression:
                                            "{id: location.id, permits: ['reservations_checkin_checkout']}",
                                          arg: "allow"
                                        }
                                      ]
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          attrs: { href: "#" },
                                          on: {
                                            click: function($event) {
                                              $event.preventDefault()
                                              return _vm.toggleCheck(item)
                                            }
                                          }
                                        },
                                        [
                                          _vm.loadings[item.id]
                                            ? _c("i", {
                                                staticClass:
                                                  "fa fa-spinner fa-spin",
                                                attrs: { "aria-hidden": "true" }
                                              })
                                            : !item.check_out
                                            ? _c("i", {
                                                staticClass: "fa fa-square-o",
                                                attrs: { "aria-hidden": "true" }
                                              })
                                            : _c("i", {
                                                staticClass:
                                                  "fa fa-check-square-o",
                                                attrs: { "aria-hidden": "true" }
                                              })
                                        ]
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(_vm._s(item.history.guest_name))
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        item.history.reservation
                                          .reservation_number
                                      )
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(item.type_type) +
                                        "\n          "
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(
                                          _vm._f("dateFormat")(
                                            item.to_date,
                                            "MM/DD/YYYY hh:mm A"
                                          )
                                        ) +
                                        "\n          "
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    {
                                      directives: [
                                        {
                                          name: "hasPermits",
                                          rawName: "v-hasPermits:allow",
                                          value: {
                                            id: _vm.$route.params.id,
                                            permits: [
                                              "location_management",
                                              "super_admin"
                                            ]
                                          },
                                          expression:
                                            "{id: $route.params.id, permits: ['location_management', 'super_admin']}",
                                          arg: "allow"
                                        }
                                      ]
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          attrs: { href: "#" },
                                          on: {
                                            click: function($event) {
                                              $event.preventDefault()
                                              return _vm.toggleShow(item)
                                            }
                                          }
                                        },
                                        [
                                          !item.history.reservation.no_show
                                            ? _c("i", {
                                                staticClass: "fa fa-square-o",
                                                attrs: { "aria-hidden": "true" }
                                              })
                                            : _c("i", {
                                                staticClass:
                                                  "fa fa-check-square-o",
                                                attrs: { "aria-hidden": "true" }
                                              })
                                        ]
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("td", [
                                    _c(
                                      "a",
                                      {
                                        attrs: { href: "#" },
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                            return _vm.showReservationDetails(
                                              item.history.reservation.id,
                                              item
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _vm.loadings[item.id]
                                          ? _c("i", {
                                              staticClass:
                                                "fa fa-spinner fa-spin",
                                              attrs: { "aria-hidden": "true" }
                                            })
                                          : _c("i", {
                                              staticClass: "fa fa-eye",
                                              attrs: { "aria-hidden": "true" }
                                            })
                                      ]
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    {
                                      directives: [
                                        {
                                          name: "hasPermits",
                                          rawName: "v-hasPermits:allow",
                                          value: {
                                            id: _vm.location.id,
                                            permits: [
                                              "update_reservation_dates",
                                              "update_reservation"
                                            ]
                                          },
                                          expression:
                                            "{id: location.id, permits: ['update_reservation_dates', 'update_reservation']}",
                                          arg: "allow"
                                        }
                                      ]
                                    },
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          attrs: {
                                            to: {
                                              name: "reservations.edit",
                                              params: {
                                                id: item.history.reservation.id
                                              }
                                            }
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fa fa-pencil",
                                            attrs: { "aria-hidden": "true" }
                                          })
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ])
                              : _vm._e()
                          }),
                          0
                        )
                      ]
                    )
                  ]
                )
              : _c("div", { staticClass: "margin-top-3x text-info" }, [
                  _vm._v("\n      Nothing to show\n    ")
                ]),
            _vm._v(" "),
            _c("confirm", { ref: "confirm", attrs: { type: "warning" } }),
            _vm._v(" "),
            _c("view-reservation", {
              ref: "view",
              attrs: { reservation: _vm.reservation },
              on: { "reload-list": _vm.getList }
            }),
            _vm._v(" "),
            _c("extend-reservation-modal", {
              directives: [
                {
                  name: "hasPermits",
                  rawName: "v-hasPermits:allow",
                  value: {
                    id: _vm.location.id,
                    permits: ["reservations_checkin_checkout"]
                  },
                  expression:
                    "{id: location.id, permits: ['reservations_checkin_checkout']}",
                  arg: "allow"
                }
              ],
              ref: "extendReservationModal",
              attrs: { processing: _vm.processing },
              on: {
                "update-processing": _vm.updateProcessing,
                "update-reservation": _vm.updateReservation,
                close: _vm.onCloseModal
              }
            })
          ],
          1
        )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "text-center f20 uppercase margin-bottom-3x" },
      [
        _c("i", {
          staticClass: "fa fa-sign-out text-info",
          attrs: { "aria-hidden": "true" }
        }),
        _vm._v(" Check Out List\n  ")
      ]
    )
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-41efad72", { render: render, staticRenderFns: staticRenderFns })
  }
}