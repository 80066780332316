import Vue from 'vue'
import _ from 'lodash'
import { http } from 'services'

// initial state
const state = () => ({
  carTypes: [],
  locationParkingCarTypes: [],
  carBodyTypes: [],
  errors: null
})

// getters
const getters = {

}

// actions
const actions = {
  ['carTypes.getCarTypes'] ({ commit, state }) {
    commit('CLEAR_ERRORS');
    return (http.get(`car-types`)).then((res) => {
      commit('GET_CAR_TYPES', res);
    }).catch((res) => {
      commit('CAR_TYPES_ERRORS', res);
    });
  },

  ['carTypes.getLocationParkingCarTypes'] ({ commit, state }, {location_id}) {
    commit('CLEAR_ERRORS');
    return (http.get(`location-parking-car-types/${location_id}`)).then((res) => {
      commit('GET_LOCATION_PARKING_CAR_TYPES', res);
    }).catch((res) => {
      commit('CAR_TYPES_ERRORS', res);
    });
  },

  ['carTypes.storeUserCarBodyType'] ({ commit, state }, {data}) {
    commit('CLEAR_ERRORS')
    return (http.post(`store-user-car-body-type`, data)).then((res) => {
      
    }).catch((res) => {
      commit('CAR_TYPES_ERRORS', res);
    });
  },

  ['carTypes.getAllCarBodyTypes'] ({ commit, state }) {
    commit('CLEAR_ERRORS')

    if (state.carBodyTypes.length) {
      return commit('GET_ALL_CAR_BODY_TYPES');
    }

    return (http.get('car-body-types')).then ((res) => {
      commit('GET_ALL_CAR_BODY_TYPES', res);
    })
    .catch ((res) => {
      commit('CAR_TYPES_ERRORS', res);
    })
  },
}

// mutations
const mutations = {
  GET_CAR_TYPES (state, res) {
    state.carTypes = res.data;
  },

  GET_LOCATION_PARKING_CAR_TYPES (state, res) {
    let response = [];
    res.data.forEach((parkingType) => {
      let type = {};
      type.parkingType = parkingType.parking_type;
      if(parkingType.dimensions) {
        type.dimensions = {};
        type.dimensions.allTrims = parkingType.dimensions.all_trims;
        type.dimensions.allTypes = parkingType.dimensions.all_types;
        type.dimensions.trims = [];
        type.dimensions.types = [];

        let trims = parkingType.dimensions.trims.split(',');
        trims.forEach((trim) => {
          if(trim.includes('-')) {
            let [start, end] = trim.split('-').map(num => parseInt(num.trim()));
            type.dimensions.trims.push([start, end]);
          }else {
            let num = parseInt(trim);
            if (!isNaN(num)) {
              type.dimensions.trims.push([num, num]);
            }
          }
        });
        let carTypes = parkingType.dimensions.types.split(',');
        carTypes.forEach((carType) => {
          if(carType.includes('-')) {
            let [start, end] = carType.split('-').map(num => parseInt(num.trim()));
            type.dimensions.types.push([start, end]);
          }else {
            let num = parseInt(carType);
            if (!isNaN(num)) {
              type.dimensions.types.push([num, num]);
            }
          }
        })
      } else if (parkingType.bodyTypes){
        type.bodyTypes = {};
        type.bodyTypes.allTrims = parkingType.bodyTypes.all_trims;
        type.bodyTypes.allTypes = parkingType.bodyTypes.all_types;
        type.bodyTypes.trims = [];
        type.bodyTypes.types = [];

        let trims = parkingType.bodyTypes.trims.split(',');
        trims.forEach((trim) => {
          if(trim.includes('-')) {
            let [start, end] = trim.split('-').map(num => parseInt(num.trim()));
            type.bodyTypes.trims.push([start, end]);
          }else {
            let num = parseInt(trim);
            if (!isNaN(num)) {
              type.bodyTypes.trims.push([num, num]);
            }
          }
        });
        let carTypes = parkingType.bodyTypes.types.split(',');
        carTypes.forEach((carType) => {
          if(carType.includes('-')) {
            let [start, end] = carType.split('-').map(num => parseInt(num.trim()));
            type.bodyTypes.types.push([start, end]);
          }else {
            let num = parseInt(carType);
            if (!isNaN(num)) {
              type.bodyTypes.types.push([num, num]);
            }
          }
        });
      }
      response.push(type);
    })
    state.locationParkingCarTypes = response;
  },

  GET_ALL_CAR_BODY_TYPES (state, res) {
    if(res)
      state.carBodyTypes = res.data
  },

  CAR_TYPES_ERRORS (state, err) {
    try {
      state.errors = err.response.data;
    } catch (e) {
      state.errors = err;
    }
  },

  CLEAR_ERRORS (state) {
    state.errors = null
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
