import toastr from 'toastr'
import layout from 'layouts/default'
import { hasPermits } from 'directives'

const components = {
  layout,
}

const directives = {
  hasPermits
}

const computed = {
  // formData () {
  //   return _.cloneDeep(this.$store.state.channels.active);
  // }
}

const data = {
  loading: false,
  submit_loading: false,
  errors: {},
  formData: {
    name: null,
    domain: null,
    sandbox: "on"
  }
}

const methods = {
  onSubmitFom () {
    this.errors = {}
    this.submit_loading = true;
    this.$store.dispatch('channels.add', _.cloneDeep(this.formData)).then(() => {
      this.submit_loading = false;
      if (this.$store.state.channels.errors) {
        if (this.$store.state.channels.errors.errors) {
          this.errors = _.cloneDeep(this.$store.state.channels.errors.errors);  
        }
        toastr.error(this.$store.state.channels.errors.message);
      } else {
        toastr.success('A new channel has been created');
        this.resetForm();
        this.$router.push({name: 'channels.all'});
      }
    })
  },
  resetForm () {
    this.formData = {
      name: null,
      domain: null,
      prefix: '',
      sandbox: "on"
    };
  }
}

export default {
  name: 'new-channel',
  components,
  computed,
  methods,
  directives,
  data: () => data,
  created () {
    this.resetForm()
  }
}
