var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-md-3 col-sm-6" }, [
      _c("div", { staticClass: "box" }, [
        _c("div", { staticClass: "box-header with-border" }, [_vm._v("Types")]),
        _vm._v(" "),
        _c("div", { staticClass: "box-body" }, [
          _c("div", [
            _vm.form.type == "parking"
              ? _c("div", [
                  _vm.parentParkingTypes.length > 0
                    ? _c(
                        "div",
                        [
                          _vm._l(_vm.parentParkingTypes, function(type) {
                            return [
                              _c(
                                "div",
                                { key: type.id, staticClass: "radio" },
                                [
                                  _c("label", [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.form.type_id,
                                          expression: "form.type_id"
                                        }
                                      ],
                                      attrs: { type: "radio" },
                                      domProps: {
                                        value: type.id,
                                        checked: _vm._q(
                                          _vm.form.type_id,
                                          type.id
                                        )
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.$set(
                                            _vm.form,
                                            "type_id",
                                            type.id
                                          )
                                        }
                                      }
                                    }),
                                    _vm._v(
                                      "\n                      " +
                                        _vm._s(
                                          type.name +
                                            " " +
                                            (type.parent_id
                                              ? ""
                                              : "(" +
                                                type.number_of_parkings +
                                                " spots)")
                                        ) +
                                        "\n                      \n                    "
                                    )
                                  ])
                                ]
                              ),
                              _vm._v(" "),
                              _vm._l(type.childs, function(childType) {
                                return _c(
                                  "div",
                                  { key: childType.id, staticClass: "radio" },
                                  [
                                    _c("label", [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.form.type_id,
                                            expression: "form.type_id"
                                          }
                                        ],
                                        attrs: { type: "radio" },
                                        domProps: {
                                          value: childType.id,
                                          checked: _vm._q(
                                            _vm.form.type_id,
                                            childType.id
                                          )
                                        },
                                        on: {
                                          change: function($event) {
                                            return _vm.$set(
                                              _vm.form,
                                              "type_id",
                                              childType.id
                                            )
                                          }
                                        }
                                      }),
                                      _vm._v(
                                        "\n                          " +
                                          _vm._s("" + childType.name) +
                                          "\n                    "
                                      )
                                    ])
                                  ]
                                )
                              })
                            ]
                          })
                        ],
                        2
                      )
                    : _c("div", { staticClass: "text-danger" }, [
                        _vm._v("This location dont have any parking types")
                      ]),
                  _vm._v(" "),
                  _vm.errors.type_id
                    ? _c("div", { staticClass: "text-danger" }, [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.errors.type_id[0]) +
                            "\n              "
                        )
                      ])
                    : _vm._e()
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.form.type == "room"
              ? _c("div", [
                  _vm.roomTypes.length > 0
                    ? _c(
                        "div",
                        _vm._l(_vm.roomTypes, function(type) {
                          return _c(
                            "div",
                            { key: type.id, staticClass: "radio" },
                            [
                              _c("label", [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.form.type_id,
                                      expression: "form.type_id"
                                    }
                                  ],
                                  attrs: { type: "radio" },
                                  domProps: {
                                    value: type.id,
                                    checked: _vm._q(_vm.form.type_id, type.id)
                                  },
                                  on: {
                                    change: function($event) {
                                      return _vm.$set(
                                        _vm.form,
                                        "type_id",
                                        type.id
                                      )
                                    }
                                  }
                                }),
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(
                                      type.name +
                                        " (" +
                                        type.number_of_rooms +
                                        " rooms)"
                                    ) +
                                    "\n                  "
                                )
                              ])
                            ]
                          )
                        }),
                        0
                      )
                    : _c("div", { staticClass: "text-danger" }, [
                        _vm._v("This location dont have any room types")
                      ]),
                  _vm._v(" "),
                  _vm.errors.type_id
                    ? _c("div", { staticClass: "text-danger" }, [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.errors.type_id[0]) +
                            "\n              "
                        )
                      ])
                    : _vm._e()
                ])
              : _vm._e()
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "box" }, [
        _c("div", { staticClass: "box-header with-border" }, [
          _vm._v("Dates and info")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "box-body" }, [
          _c("div", { staticClass: "form-group" }, [
            _c("label", [_vm._v("From Date")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "input-group" },
              [
                _vm._m(0),
                _vm._v(" "),
                _c("datepicker", {
                  staticClass: "form-control",
                  attrs: { "min-date": "none" },
                  model: {
                    value: _vm.from_date,
                    callback: function($$v) {
                      _vm.from_date = $$v
                    },
                    expression: "from_date"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _vm.errors.from_date
              ? _c("div", { staticClass: "text-danger" }, [
                  _vm._v(
                    "\n              " +
                      _vm._s(_vm.errors.from_date[0]) +
                      "\n            "
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("label", [_vm._v("To Date")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "input-group" },
              [
                _vm._m(1),
                _vm._v(" "),
                _c("datepicker", {
                  staticClass: "form-control",
                  attrs: { "min-date": _vm.from_date },
                  model: {
                    value: _vm.to_date,
                    callback: function($$v) {
                      _vm.to_date = $$v
                    },
                    expression: "to_date"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _vm.errors.to_date
              ? _c("div", { staticClass: "text-danger" }, [
                  _vm._v(
                    "\n              " +
                      _vm._s(_vm.errors.to_date[0]) +
                      "\n            "
                  )
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form-group" }, [
            _c("label", [_vm._v("Number of period days")]),
            _vm._v(" "),
            _c("div", { staticClass: "input-group" }, [
              _vm._m(2),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.number_of_period_days,
                    expression: "form.number_of_period_days"
                  }
                ],
                staticClass: "form-control",
                attrs: { type: "text" },
                domProps: { value: _vm.form.number_of_period_days },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.form,
                      "number_of_period_days",
                      $event.target.value
                    )
                  }
                }
              })
            ]),
            _vm._v(" "),
            _vm.errors.number_of_period_days
              ? _c("div", { staticClass: "text-danger" }, [
                  _vm._v(
                    "\n              " +
                      _vm._s(_vm.errors.number_of_period_days[0]) +
                      "\n            "
                  )
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form-group" }, [
            _c("div", { staticClass: "checkbox" }, [
              _c("label", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.exclude_blackout,
                      expression: "form.exclude_blackout"
                    }
                  ],
                  attrs: { type: "checkbox" },
                  domProps: {
                    checked: Array.isArray(_vm.form.exclude_blackout)
                      ? _vm._i(_vm.form.exclude_blackout, null) > -1
                      : _vm.form.exclude_blackout
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.form.exclude_blackout,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.form,
                              "exclude_blackout",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.form,
                              "exclude_blackout",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.form, "exclude_blackout", $$c)
                      }
                    }
                  }
                }),
                _vm._v("\n                  Exclude blackout\n              ")
              ])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form-group" }, [
            _c("div", { staticClass: "checkbox" }, [
              _c("label", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.enable,
                      expression: "form.enable"
                    }
                  ],
                  attrs: { type: "checkbox" },
                  domProps: {
                    checked: Array.isArray(_vm.form.enable)
                      ? _vm._i(_vm.form.enable, null) > -1
                      : _vm.form.enable
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.form.enable,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(_vm.form, "enable", $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.form,
                              "enable",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.form, "enable", $$c)
                      }
                    }
                  }
                }),
                _vm._v("\n                  enable\n              ")
              ])
            ])
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-sm-6" }, [
      _c("div", { staticClass: "box" }, [
        _c("div", { staticClass: "box-header with-border" }, [
          _vm._v("Adjustments")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "box-body" }, [
          _c("table", { staticClass: "table table-hover" }, [
            _vm._m(3),
            _vm._v(" "),
            _c(
              "tbody",
              _vm._l(_vm.form.adjustments, function(adj, i) {
                return _c("tr", { key: i }, [
                  _c("td", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: adj.from,
                          expression: "adj.from"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: { type: "text" },
                      domProps: { value: adj.from },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(adj, "from", $event.target.value)
                        }
                      }
                    }),
                    _vm._v(" "),
                    _vm.errors["adjustments." + i + ".from"]
                      ? _c("div", { staticClass: "text-danger" }, [
                          _vm._v(
                            "\n                    " +
                              _vm._s(
                                _vm.errors["adjustments." + i + ".from"][0]
                              ) +
                              "\n                  "
                          )
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: adj.to,
                          expression: "adj.to"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: { type: "text" },
                      domProps: { value: adj.to },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(adj, "to", $event.target.value)
                        }
                      }
                    }),
                    _vm._v(" "),
                    _vm.errors["adjustments." + i + ".to"]
                      ? _c("div", { staticClass: "text-danger" }, [
                          _vm._v(
                            "\n                    " +
                              _vm._s(
                                _vm.errors["adjustments." + i + ".to"][0]
                              ) +
                              "\n                  "
                          )
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: adj.amount,
                          expression: "adj.amount"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: { type: "text" },
                      domProps: { value: adj.amount },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(adj, "amount", $event.target.value)
                        }
                      }
                    }),
                    _vm._v(" "),
                    _vm.errors["adjustments." + i + ".amount"]
                      ? _c("div", { staticClass: "text-danger" }, [
                          _vm._v(
                            "\n                    " +
                              _vm._s(
                                _vm.errors["adjustments." + i + ".amount"][0]
                              ) +
                              "\n                  "
                          )
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _vm.form.adjustments.length > 1
                      ? _c(
                          "a",
                          {
                            staticClass: "text-danger",
                            attrs: { href: "#" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.removeAdjustment(i)
                              }
                            }
                          },
                          [_c("i", { staticClass: "fa fa-trash fa-lg" })]
                        )
                      : _vm._e()
                  ])
                ])
              }),
              0
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                attrs: { href: "#" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.addAdjustment()
                  }
                }
              },
              [_c("i", { staticClass: "fa fa-plus fa-lg" })]
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "box" }, [
        _c("div", { staticClass: "box-header with-border" }, [
          _vm._v("Excluded periods")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "box-body" }, [
          _c("div", {}, [
            _vm.form.excluded_periods.length > 0
              ? _c("table", { staticClass: "table table-hover" }, [
                  _vm._m(4),
                  _vm._v(" "),
                  _c(
                    "tbody",
                    _vm._l(_vm.form.excluded_periods, function(period, i) {
                      return _c("tr", { key: i }, [
                        _c(
                          "td",
                          [
                            _c("datepicker", {
                              staticClass: "form-control",
                              attrs: { "min-date": "none" },
                              model: {
                                value: period.from,
                                callback: function($$v) {
                                  _vm.$set(period, "from", $$v)
                                },
                                expression: "period.from"
                              }
                            }),
                            _vm._v(" "),
                            _vm.errors["excluded_periods." + i + ".from"]
                              ? _c("div", { staticClass: "text-danger" }, [
                                  _vm._v(
                                    "\n                      " +
                                      _vm._s(
                                        _vm.errors[
                                          "excluded_periods." + i + ".from"
                                        ][0]
                                      ) +
                                      "\n                    "
                                  )
                                ])
                              : _vm._e()
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          [
                            _c("datepicker", {
                              staticClass: "form-control",
                              attrs: { "min-date": period.from },
                              model: {
                                value: period.to,
                                callback: function($$v) {
                                  _vm.$set(period, "to", $$v)
                                },
                                expression: "period.to"
                              }
                            }),
                            _vm._v(" "),
                            _vm.errors["excluded_periods." + i + ".to"]
                              ? _c("div", { staticClass: "text-danger" }, [
                                  _vm._v(
                                    "\n                      " +
                                      _vm._s(
                                        _vm.errors[
                                          "excluded_periods." + i + ".to"
                                        ][0]
                                      ) +
                                      "\n                    "
                                  )
                                ])
                              : _vm._e()
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("td", [
                          _c(
                            "a",
                            {
                              staticClass: "text-danger",
                              attrs: { href: "#" },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.removeExcludedPeriod(i)
                                }
                              }
                            },
                            [_c("i", { staticClass: "fa fa-trash fa-lg" })]
                          )
                        ])
                      ])
                    }),
                    0
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "a",
              {
                attrs: { href: "#" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.addExcludedPeriod()
                  }
                }
              },
              [_c("i", { staticClass: "fa fa-plus fa-lg" })]
            )
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-3 col-sm-6" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-success margin-top-2x",
          class: { disabled: _vm.processing },
          attrs: { disabled: _vm.processing },
          on: { click: _vm.submit }
        },
        [
          _vm.processing
            ? _c("span", [
                _c("i", { staticClass: "fa fa-spinner fa-spin fa-fw" })
              ])
            : _vm._e(),
          _vm._v("\n        Save\n      ")
        ]
      ),
      _vm._v(" "),
      _vm.edit
        ? _c(
            "button",
            {
              staticClass: "btn btn-danger margin-top-2x",
              on: { click: _vm.cancelEdit }
            },
            [_vm._v("\n        Cancel \n      ")]
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      { staticClass: "input-group-addon", attrs: { id: "basic-addon1" } },
      [
        _c("i", {
          staticClass: "fa fa-calendar",
          attrs: { "aria-hidden": "true" }
        })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      { staticClass: "input-group-addon", attrs: { id: "basic-addon1" } },
      [
        _c("i", {
          staticClass: "fa fa-calendar",
          attrs: { "aria-hidden": "true" }
        })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      { staticClass: "input-group-addon", attrs: { id: "basic-addon1" } },
      [
        _c("i", {
          staticClass: "fa fa-calendar",
          attrs: { "aria-hidden": "true" }
        })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("from")]),
        _vm._v(" "),
        _c("th", [_vm._v("to")]),
        _vm._v(" "),
        _c("th", [_vm._v("+amount")]),
        _vm._v(" "),
        _c("th", [_vm._v("actions")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("from")]),
        _vm._v(" "),
        _c("th", [_vm._v("to")]),
        _vm._v(" "),
        _c("th")
      ])
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-e13b82c0", { render: render, staticRenderFns: staticRenderFns })
  }
}