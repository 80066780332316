var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("layout", { attrs: { "no-sidebar": true } }, [
    _c("div", { staticClass: "page-header" }, [
      _c("h1", [
        _c("small", [_vm._v("Users: " + _vm._s(_vm.form ? _vm.form.name : ""))])
      ])
    ]),
    _vm._v(" "),
    _vm.loading
      ? _c("div", { staticClass: "text-center" }, [
          _c("i", { staticClass: "fa fa-spinner fa-spin fa-3x fa-fw" })
        ])
      : _c("div", { staticClass: "col-sm-8 col-sm-offset-2" }, [
          _c("div", { staticClass: "text-right" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-success",
                class: { disabled: _vm.processing },
                attrs: { disabled: _vm.processing },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.submit.apply(null, arguments)
                  }
                }
              },
              [
                _vm.processing
                  ? _c("span", [
                      _c("i", { staticClass: "fa fa-spinner fa-spin fa-fw" }),
                      _vm._v("\n        SAVING...\n      ")
                    ])
                  : _c("span", [_vm._v("SAVE")])
              ]
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "margin-bottom-3x" }, [
            _c("label", [_vm._v("Name")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.name,
                  expression: "form.name"
                }
              ],
              staticClass: "form-control",
              attrs: { type: "text" },
              domProps: { value: _vm.form.name },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.form, "name", $event.target.value)
                }
              }
            }),
            _vm._v(" "),
            _vm.errors.name
              ? _c("div", { staticClass: "text-danger" }, [
                  _vm._v("\n      " + _vm._s(_vm.errors.name[0]) + "\n    ")
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "margin-bottom-3x" }, [
            _c("label", [_vm._v("Email")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.email,
                  expression: "form.email"
                }
              ],
              staticClass: "form-control",
              attrs: { type: "text" },
              domProps: { value: _vm.form.email },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.form, "email", $event.target.value)
                }
              }
            }),
            _vm._v(" "),
            _vm.errors.email
              ? _c("div", { staticClass: "text-danger" }, [
                  _vm._v("\n      " + _vm._s(_vm.errors.email[0]) + "\n    ")
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          _c("div", { staticClass: "margin-bottom-3x" }, [
            _c("label", [_vm._v("User Type")]),
            _vm._v(" "),
            _c("div", { staticClass: "radio" }, [
              _c("label", { staticClass: "margin-right-3x" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.type,
                      expression: "form.type"
                    }
                  ],
                  attrs: { type: "radio", value: "ims" },
                  domProps: { checked: _vm._q(_vm.form.type, "ims") },
                  on: {
                    change: function($event) {
                      return _vm.$set(_vm.form, "type", "ims")
                    }
                  }
                }),
                _vm._v("\n        IMS user\n      ")
              ]),
              _vm._v(" "),
              _c("label", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.type,
                      expression: "form.type"
                    }
                  ],
                  attrs: { type: "radio", value: "location" },
                  domProps: { checked: _vm._q(_vm.form.type, "location") },
                  on: {
                    change: function($event) {
                      return _vm.$set(_vm.form, "type", "location")
                    }
                  }
                }),
                _vm._v("\n        Location user\n      ")
              ]),
              _vm._v(" "),
              _c("label", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.type,
                      expression: "form.type"
                    }
                  ],
                  attrs: { type: "radio", value: "channel" },
                  domProps: { checked: _vm._q(_vm.form.type, "channel") },
                  on: {
                    change: function($event) {
                      return _vm.$set(_vm.form, "type", "channel")
                    }
                  }
                }),
                _vm._v("\n        Channel user\n      ")
              ])
            ]),
            _vm._v(" "),
            _vm.errors.type
              ? _c("div", { staticClass: "text-danger" }, [
                  _vm._v("\n      " + _vm._s(_vm.errors.type[0]) + "\n    ")
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _vm.form.type == "ims"
            ? _c("div", { staticClass: "margin-bottom-3x" }, [
                _c("label", [_vm._v("Role")]),
                _vm._v(" "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.roles[0],
                        expression: "form.roles[0]"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: { name: "" },
                    on: {
                      change: function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.form.roles,
                          0,
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      }
                    }
                  },
                  [
                    _c("option", { attrs: { value: "" } }, [
                      _vm._v("Select A Role")
                    ]),
                    _vm._v(" "),
                    _vm._l(_vm.roles, function(role) {
                      return _c("option", { domProps: { value: role.id } }, [
                        _vm._v(
                          "\n      " + _vm._s(role.display_name) + "\n    "
                        )
                      ])
                    })
                  ],
                  2
                ),
                _vm._v(" "),
                _vm.errors.roles
                  ? _c("div", { staticClass: "text-danger" }, [
                      _vm._v("\n    " + _vm._s(_vm.errors.roles[0]) + "\n  ")
                    ])
                  : _vm._e()
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "margin-bottom-3x" }, [
            _c("label", [_vm._v("Phone")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.phone,
                  expression: "form.phone"
                }
              ],
              staticClass: "form-control",
              attrs: { type: "text" },
              domProps: { value: _vm.form.phone },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.form, "phone", $event.target.value)
                }
              }
            }),
            _vm._v(" "),
            _vm.errors.phone
              ? _c("div", { staticClass: "text-danger" }, [
                  _vm._v("\n    " + _vm._s(_vm.errors.phone[0]) + "\n  ")
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form-group" }, [
            _c("label", [_vm._v("Country")]),
            _vm._v(" "),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.country_id,
                    expression: "form.country_id"
                  }
                ],
                staticClass: "form-control",
                attrs: { placeholder: "Country" },
                on: {
                  change: function($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function(o) {
                        return o.selected
                      })
                      .map(function(o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.$set(
                      _vm.form,
                      "country_id",
                      $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                    )
                  }
                }
              },
              _vm._l(_vm.countries, function(country) {
                return _c("option", { domProps: { value: country.id } }, [
                  _vm._v(_vm._s(country.name))
                ])
              }),
              0
            ),
            _vm._v(" "),
            _vm.errors.country_id
              ? _c("div", { staticClass: "text-danger" }, [
                  _vm._v("\n    " + _vm._s(_vm.errors.country_id[0]) + "\n  ")
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form-group" }, [
            _c("label", [_vm._v("State")]),
            _vm._v(" "),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.state_id,
                    expression: "form.state_id"
                  }
                ],
                staticClass: "form-control",
                attrs: { placeholder: "Country" },
                on: {
                  change: function($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function(o) {
                        return o.selected
                      })
                      .map(function(o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.$set(
                      _vm.form,
                      "state_id",
                      $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                    )
                  }
                }
              },
              _vm._l(_vm.states, function(state) {
                return _c("option", { domProps: { value: state.id } }, [
                  _vm._v(_vm._s(state.name))
                ])
              }),
              0
            ),
            _vm._v(" "),
            _vm.errors.state_id
              ? _c("div", { staticClass: "text-danger" }, [
                  _vm._v("\n    " + _vm._s(_vm.errors.state_id[0]) + "\n  ")
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form-group" }, [
            _c("label", [_vm._v("City")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.trim",
                  value: _vm.form.city,
                  expression: "form.city",
                  modifiers: { trim: true }
                }
              ],
              staticClass: "form-control",
              attrs: { type: "text", placeholder: "City name" },
              domProps: { value: _vm.form.city },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.form, "city", $event.target.value.trim())
                },
                blur: function($event) {
                  return _vm.$forceUpdate()
                }
              }
            }),
            _vm._v(" "),
            _vm.errors.city
              ? _c("div", { staticClass: "text-danger" }, [
                  _vm._v("\n    " + _vm._s(_vm.errors.city[0]) + "\n  ")
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "margin-bottom-3x" }, [
            _c("label", [_vm._v("Address 1")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.address1,
                  expression: "form.address1"
                }
              ],
              staticClass: "form-control",
              attrs: { type: "text" },
              domProps: { value: _vm.form.address1 },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.form, "address1", $event.target.value)
                }
              }
            }),
            _vm._v(" "),
            _vm.errors.address1
              ? _c("div", { staticClass: "text-danger" }, [
                  _vm._v("\n    " + _vm._s(_vm.errors.address1[0]) + "\n  ")
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "margin-bottom-3x" }, [
            _c("label", [_vm._v("Address 2")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.address2,
                  expression: "form.address2"
                }
              ],
              staticClass: "form-control",
              attrs: { type: "text" },
              domProps: { value: _vm.form.address2 },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.form, "address2", $event.target.value)
                }
              }
            }),
            _vm._v(" "),
            _vm.errors.address2
              ? _c("div", { staticClass: "text-danger" }, [
                  _vm._v("\n    " + _vm._s(_vm.errors.address2[0]) + "\n  ")
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "margin-bottom-3x" }, [
            _c("label", [_vm._v("Zip Code")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.zip_code,
                  expression: "form.zip_code"
                }
              ],
              staticClass: "form-control",
              attrs: { type: "text" },
              domProps: { value: _vm.form.zip_code },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.form, "zip_code", $event.target.value)
                }
              }
            }),
            _vm._v(" "),
            _vm.errors.zip_code
              ? _c("div", { staticClass: "text-danger" }, [
                  _vm._v("\n    " + _vm._s(_vm.errors.zip_code[0]) + "\n  ")
                ])
              : _vm._e()
          ])
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-03fb7ac5", { render: render, staticRenderFns: staticRenderFns })
  }
}