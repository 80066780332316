var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "layout",
    { attrs: { "no-sidebar": true } },
    [
      _c("div", { staticClass: "page-header" }, [
        _c("h1", [_c("small", [_vm._v("Locations")])])
      ]),
      _vm._v(" "),
      !_vm.loading
        ? _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-4 margin-bottom-3x" }, [
              _c("div", { staticClass: "input-group" }, [
                _c("input", {
                  ref: "search",
                  staticClass: "form-control",
                  attrs: { type: "text", placeholder: "Search for..." },
                  domProps: { value: _vm.searchKey },
                  on: {
                    keyup: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        $event.keyCode !== 13
                      ) {
                        return null
                      }
                      return _vm.search(_vm.$refs.search.value)
                    }
                  }
                }),
                _vm._v(" "),
                _c("span", { staticClass: "input-group-btn" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-default",
                      attrs: { type: "button" },
                      on: {
                        click: function($event) {
                          return _vm.search(_vm.$refs.search.value)
                        }
                      }
                    },
                    [_vm._v("Go!")]
                  )
                ])
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "hasPermits",
                      rawName: "v-hasPermits:prevent",
                      value: ["location_user"],
                      expression: "['location_user']",
                      arg: "prevent"
                    }
                  ],
                  staticClass: "d-flex input-group radio"
                },
                [
                  _c("div", { staticClass: "d-inline margin-right-2x" }, [
                    _vm._v("Status")
                  ]),
                  _vm._v(" "),
                  _c("label", { staticClass: "d-inline margin-right-2x" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.filter,
                          expression: "filter"
                        }
                      ],
                      attrs: { type: "radio", value: "active" },
                      domProps: { checked: _vm._q(_vm.filter, "active") },
                      on: {
                        change: [
                          function($event) {
                            _vm.filter = "active"
                          },
                          function($event) {
                            return _vm.filterChanged()
                          }
                        ]
                      }
                    }),
                    _vm._v("\n          Active\n        ")
                  ]),
                  _vm._v(" "),
                  _c("label", { staticClass: "margin-right-2x" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.filter,
                          expression: "filter"
                        }
                      ],
                      attrs: { type: "radio", value: "deleted" },
                      domProps: { checked: _vm._q(_vm.filter, "deleted") },
                      on: {
                        change: [
                          function($event) {
                            _vm.filter = "deleted"
                          },
                          function($event) {
                            return _vm.filterChanged()
                          }
                        ]
                      }
                    }),
                    _vm._v("\n          Deleted\n        ")
                  ]),
                  _vm._v(" "),
                  _c("label", { staticClass: "margin-right-2x" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.filter,
                          expression: "filter"
                        }
                      ],
                      attrs: { type: "radio", value: "all" },
                      domProps: { checked: _vm._q(_vm.filter, "all") },
                      on: {
                        change: [
                          function($event) {
                            _vm.filter = "all"
                          },
                          function($event) {
                            return _vm.filterChanged()
                          }
                        ]
                      }
                    }),
                    _vm._v("\n          All\n        ")
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "hasPermits",
                      rawName: "v-hasPermits:allow",
                      value: ["super_admin"],
                      expression: "['super_admin']",
                      arg: "allow"
                    }
                  ],
                  staticClass: "margin-bottom-3x"
                },
                [
                  _c("div", { staticClass: "d-inline margin-right-2x" }, [
                    _vm._v("POI Type")
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "hasPermits",
                          rawName: "v-hasPermits:prevent",
                          value: ["location_user", "channel_user"],
                          expression: "['location_user', 'channel_user']",
                          arg: "prevent"
                        }
                      ],
                      staticClass: "input-group radio d-inline"
                    },
                    [
                      _c("label", { staticClass: "margin-right-2x" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.poi_type_filter,
                              expression: "poi_type_filter"
                            }
                          ],
                          attrs: { type: "radio", value: "airport" },
                          domProps: {
                            checked: _vm._q(_vm.poi_type_filter, "airport")
                          },
                          on: {
                            change: [
                              function($event) {
                                _vm.poi_type_filter = "airport"
                              },
                              function($event) {
                                return _vm.filterChanged()
                              }
                            ]
                          }
                        }),
                        _vm._v("\n          Airport\n        ")
                      ]),
                      _vm._v(" "),
                      _c("label", { staticClass: "margin-right-2x" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.poi_type_filter,
                              expression: "poi_type_filter"
                            }
                          ],
                          attrs: { type: "radio", value: "cruiseport" },
                          domProps: {
                            checked: _vm._q(_vm.poi_type_filter, "cruiseport")
                          },
                          on: {
                            change: [
                              function($event) {
                                _vm.poi_type_filter = "cruiseport"
                              },
                              function($event) {
                                return _vm.filterChanged()
                              }
                            ]
                          }
                        }),
                        _vm._v("\n          Cruiseport\n        ")
                      ]),
                      _vm._v(" "),
                      _c("label", { staticClass: "margin-right-2x" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.poi_type_filter,
                              expression: "poi_type_filter"
                            }
                          ],
                          attrs: { type: "radio", value: "city" },
                          domProps: {
                            checked: _vm._q(_vm.poi_type_filter, "city")
                          },
                          on: {
                            change: [
                              function($event) {
                                _vm.poi_type_filter = "city"
                              },
                              function($event) {
                                return _vm.filterChanged()
                              }
                            ]
                          }
                        }),
                        _vm._v("\n          City\n        ")
                      ]),
                      _vm._v(" "),
                      _c("label", { staticClass: "margin-right-2x" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.poi_type_filter,
                              expression: "poi_type_filter"
                            }
                          ],
                          attrs: { type: "radio", value: "all" },
                          domProps: {
                            checked: _vm._q(_vm.poi_type_filter, "all")
                          },
                          on: {
                            change: [
                              function($event) {
                                _vm.poi_type_filter = "all"
                              },
                              function($event) {
                                return _vm.filterChanged()
                              }
                            ]
                          }
                        }),
                        _vm._v("\n          All\n        ")
                      ])
                    ]
                  )
                ]
              )
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "hasPermits",
                    rawName: "v-hasPermits:allow",
                    value: ["location_create"],
                    expression: "['location_create']",
                    arg: "allow"
                  }
                ],
                staticClass: "col-md-8 margin-bottom-3x"
              },
              [
                _c(
                  "div",
                  { staticClass: "text-right" },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "btn btn-default",
                        attrs: { to: { name: "locations.new" } }
                      },
                      [
                        _c("i", {
                          staticClass: "fa fa-plus",
                          attrs: { "aria-hidden": "true" }
                        }),
                        _vm._v("\n          New Location\n        ")
                      ]
                    )
                  ],
                  1
                )
              ]
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.loading
        ? _c("div", { staticClass: "text-center" }, [
            _c("i", { staticClass: "fa fa-spinner fa-spin fa-3x fa-fw" })
          ])
        : _c(
            "div",
            [
              _c("div", { staticClass: "table-responsive" }, [
                _c("table", { staticClass: "table table-striped" }, [
                  _c("thead", [
                    _c("tr", [
                      _c("th", [_vm._v("Location Name")]),
                      _vm._v(" "),
                      _c("th", [_vm._v("address")]),
                      _vm._v(" "),
                      _c("th", [_vm._v("Phone")]),
                      _vm._v(" "),
                      _c(
                        "th",
                        {
                          directives: [
                            {
                              name: "hasPermits",
                              rawName: "v-hasPermits:allow",
                              value: ["super_admin"],
                              expression: "['super_admin']",
                              arg: "allow"
                            }
                          ]
                        },
                        [_vm._v("POI Type")]
                      ),
                      _vm._v(" "),
                      _c(
                        "th",
                        {
                          directives: [
                            {
                              name: "hasPermits",
                              rawName: "v-hasPermits:allow",
                              value: ["location_delete"],
                              expression: "['location_delete']",
                              arg: "allow"
                            }
                          ]
                        },
                        [_vm._v("Remove/Recover")]
                      ),
                      _vm._v(" "),
                      _c("th", [_vm._v("Publish/Unpublish")])
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "tbody",
                    _vm._l(_vm.locations.data, function(item, i) {
                      return _c("tr", [
                        _c(
                          "td",
                          { attrs: { width: "30%" } },
                          [
                            _c(
                              "router-link",
                              {
                                directives: [
                                  {
                                    name: "hasPermits",
                                    rawName: "v-hasPermits:allow",
                                    value: ["location_user", "location_list"],
                                    expression:
                                      "['location_user', 'location_list']",
                                    arg: "allow"
                                  }
                                ],
                                attrs: {
                                  to: {
                                    name: "locations.guestManagement",
                                    params: { id: item.id }
                                  }
                                }
                              },
                              [
                                _c("strong", { staticClass: "f16" }, [
                                  _vm._v(_vm._s(item.name))
                                ])
                              ]
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(item.address))]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(item.phone))]),
                        _vm._v(" "),
                        _c(
                          "td",
                          {
                            directives: [
                              {
                                name: "hasPermits",
                                rawName: "v-hasPermits:allow",
                                value: ["super_admin"],
                                expression: "['super_admin']",
                                arg: "allow"
                              }
                            ],
                            staticClass: "text-capitalize"
                          },
                          [
                            _vm._v(
                              _vm._s(
                                item.profile && item.profile.poi_type
                                  ? item.profile.poi_type
                                  : "_"
                              )
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          {
                            directives: [
                              {
                                name: "hasPermits",
                                rawName: "v-hasPermits:allow",
                                value: ["location_delete"],
                                expression: "['location_delete']",
                                arg: "allow"
                              }
                            ]
                          },
                          [
                            _vm.loadings[item.id]
                              ? _c("div", [
                                  _c("i", {
                                    staticClass: "fa fa-spinner fa-spin"
                                  })
                                ])
                              : _c("div", {}, [
                                  !item.deleted_at
                                    ? _c(
                                        "a",
                                        {
                                          staticClass: "text-danger",
                                          on: {
                                            click: function($event) {
                                              $event.preventDefault()
                                              return _vm.removeLocation(item)
                                            }
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fa fa-trash",
                                            attrs: { "aria-hidden": "true" }
                                          })
                                        ]
                                      )
                                    : _c(
                                        "a",
                                        {
                                          staticClass: "text-success",
                                          on: {
                                            click: function($event) {
                                              return _vm.undoRemove(item)
                                            }
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fa fa-undo",
                                            attrs: { "aria-hidden": "true" }
                                          })
                                        ]
                                      )
                                ])
                          ]
                        ),
                        _vm._v(" "),
                        _c("td", [
                          item.published_at
                            ? _c("i", {
                                staticClass: "fa fa-check text-success",
                                attrs: { "aria-hidden": "true" }
                              })
                            : _c("i", {
                                staticClass: "fa fa-times text-warning",
                                attrs: { "aria-hidden": "true" }
                              })
                        ])
                      ])
                    }),
                    0
                  )
                ])
              ]),
              _vm._v(" "),
              _c("pagination", {
                attrs: { "pages-number": _vm.locations.last_page },
                on: {
                  pageChanged: function(page) {
                    return _vm.loadPage(page)
                  }
                }
              })
            ],
            1
          ),
      _vm._v(" "),
      _c("confirm", { ref: "confirmDelete", attrs: { type: "danger" } }),
      _vm._v(" "),
      _c("confirm", { ref: "confirmUndoDelete", attrs: { type: "success" } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-6bc8e8c8", { render: render, staticRenderFns: staticRenderFns })
  }
}