import toastr from 'toastr'
import sSelect from 'components/sSelect'

const components = {
  sSelect
}

const computed = {
  oldFees () {
    return _.cloneDeep(this.$store.state.channels.active.fees);
  },
  
  locations () {
    return _.map(_.cloneDeep(this.channelLocations), item => {
      return {
        value: item.id,
        text: item.location.name
      }
    });
  }
}

const watch = {
  fee (n) {
    this.form = {
      id: n.id,
      name: n.name,
      usd_flat: n.dynamic_fees.usd_flat,
      cad_flat: n.dynamic_fees.cad_flat,
      associated_to: n.associated_to,
      percent_values: n.dynamic_fees.percent_values,
      all_locations: n.all_locations,
    };

    this.selectedLocations = _.map(n.channel_locations, item => (
      {
        value: item.id,
        text: item.location.name
      }
    ));
  }
}

const methods = {
  submit () {
    this.errors = {};
    this.processing = true;

    let data = _.cloneDeep(this.form);

    if (data.all_locations != 1) {
      data.locations = this.selectedLocations.map(location => location.value);
    }

    this.$store.dispatch('channels.updateDynamicFee', {
      chId: this.$route.params.id,
      id: data.id,
      data
    }).then(() => {
      this.processing = false;
      let errors = this.$store.state.channels.errors;
      if (errors) {
        if (errors.errors) {
          this.errors = _.cloneDeep(errors.errors);
        }
        toastr.error(errors.message);
      } else {
        toastr.success('data has been saved successfully');
        this.$emit('onSave', data.id, false);
      }
    });
  },

  addFeePercentValue(index = -1) {
    let item = {
      from: null,
      to: null,
      value: '',
    }
    
    let length = this.form.percent_values.length; 
    if (index == -1 || index >= length) {
      this.form.percent_values.push(item);
    } else {
      this.form.percent_values.splice(index, 0, item);
    } 
  },

  removePercentValue(index) {
    this.form.percent_values.splice(index, 1);
  },

  onChangeSelectedLocations (locations) {
    this.selectedLocations = locations;
    this.onChangeLocations();
  },

  removeSelected (selectedLocation) {
    this.selectedLocations = _.filter(this.selectedLocations, location => location.value != selectedLocation.value);
  },

  onChangeLocations () {

    this.showOldFeeWarning = false;
    let locationType = this.form.all_locations;
    if (locationType == 1 && this.oldFees.length) { // all locations
      this.showOldFeeWarning = true;
    } else if (locationType == 0) { // some locations 
      for (let i = 0; i < this.oldFees.length; i++) {
        let feeLocationType = this.oldFees[i].all_locations;
        if (feeLocationType == 1 || (locationType == 2 && !this.selectedLocations.length)) {
          this.showOldFeeWarning = true;
          break;
        } else {
          for (let j = 0; j < this.selectedLocations.length; j++) {
            let found = _.find(_.cloneDeep(this.oldFees[i].channel_locations), location => location.id == this.selectedLocations[j].value);
            if (found ) {
              this.showOldFeeWarning = true; 
              break;
            } 
          }
          if (this.showOldFeeWarning) {
            break;
          }
        }
      }
    } else if (locationType == 2) {
      for (let i = 0; i < this.oldFees.length; i++) {
        for (let j = 0; j < this.oldFees[i].channel_locations.length; j++) {
          let loc = _.find(_.cloneDeep( this.selectedLocations), slocation => slocation.value == this.oldFees[i].channel_locations[j].id);
          if (!loc) {
            this.showOldFeeWarning = true;
            break;
          }
        }

        if (this.showOldFeeWarning) {
          break;
        }
      }
    }
  },
}

export default {
  name: 'edit-dynamic-fee',
  props: ['fee', 'channelLocations'],
  methods,
  watch,
  components,
  computed,
  data() {
    return {
      processing: false,
      errors: {},
      showOldFeeWarning: false,
      form: {
        id: this.fee.id,
        name: this.fee.name,
        usd_flat: this.fee.dynamic_fees.usd_flat,
        cad_flat: this.fee.dynamic_fees.cad_flat,
        associated_to: this.fee.associated_to,
        percent_values: this.fee.dynamic_fees.percent_values,
        all_locations: this.fee.all_locations,
      },
      selectedLocations: _.map(this.fee.channel_locations, item => (
        {
          value: item.id,
          text: item.location.name
        }
      )),
    }
  },
  created () {
    this.onChangeLocations();
  }
}
