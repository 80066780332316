import layout from 'layouts/default'
import { dateFormat } from 'filters'
import { confirm } from 'components/modals'
import parkingRateModal from '../parkingRateModal'
import roomRateModal from '../roomRateModal'
import bundleRateModal from '../bundleRateModal'
import bulkUpload from '../bulkUpload'
import rateViews from '../rateViews'
import tabs from '../tabs'
import { hasPermits } from 'directives'


const directives = {
  hasPermits
}

const components = {
  layout,
  parkingRateModal,
  roomRateModal,
  bundleRateModal,
  confirm,
  bulkUpload,
  rateViews,
  tabs
}

const filters = {
  dateFormat
}

const computed = {
  location () {
    return _.cloneDeep(this.$store.state.locations.active);
  },
  channel() {
    return _.cloneDeep(this.$store.state.locations.activeChannel.channel);
  }
}

const methods = {
  refreshRates () {
    this.$refs.rateReviews.getRates();
  }
}


export default {
  name: 'rates-tab',
  components,
  computed,
  methods,
  filters,
  directives,
  data () {
    return {
      loading: false,
      locId: this.$route.params.id
    }
  },
  created () {
    this.loading = true;
    Promise.all([
      this.$store.dispatch('locations.get', this.$route.params.id),
      this.$store.dispatch('locations.getChannels', this.$route.params.id),
      this.$store.dispatch('locations.parkingTypes', {id: this.$route.params.id}),
      this.$store.dispatch('locations.roomTypes', {id: this.$route.params.id}),
      this.$store.dispatch('locations.bundleTypes', {id: this.$route.params.id}),
    ]).then(() => {
      this.loading = false;
    });
  }
}
