import layout from 'layouts/default'
import pagination from 'components/pagination'
import { confirm } from 'components/modals'
import { hasPermits } from 'directives'


const directives = {
  hasPermits
}

const components = {
  layout,
  pagination,
  confirm
}

const methods = {
  loadPage (page) {
    this.loading = true;
    this.$store.dispatch('poi.getAll', {page, _q: this.searchKey}).then (() => {
      this.loading = false;
    });
  },
  
  search (searchKey) {
    this.searchKey = searchKey;
    this.loadPage(1);
  },

  removePOI (item) {
    this.$refs.deleteConfirm.confirm('Are you sure?', () => {
      this.poi_loadings[item.id] = true

      this.$store.dispatch('poi.removePOI', item.id).then(() => {
        this.poi_loadings[item.id] = false

        if (this.$store.state.pointOfInterest.errors) {
          this.errors = _.cloneDeep(this.$store.state.pointOfInterest.errors.errors);
          if (this.errors.hasOwnProperty('message')) {
            toastr.error(this.errors.message);
          }
        }
      })
    });
  }
}

const computed = {
  poi () {
    let poi = _.cloneDeep(this.$store.state.pointOfInterest.all);
    _.each(poi.data, (poi) => {
      this.poi_loadings[poi.id] = false;
    });
    this.poi_loadings = _.clone(this.poi_loadings);
    return poi;
  }
}

export default {
  name: 'all-poi',
  components,
  methods,
  computed,
  directives,
  data () {
    return {
      loading: true,
      searchKey: null,
      poi_loadings: {}
    }
  },
  created () {
    this.loadPage (this.$route.query.page || 1);
  }
}
