import layout from 'layouts/default'
import facility from './facility'
import parking from './parking'
import room from './room'
import tabs from '../tabs'

const components = {
  layout,
  facility,
  parking,
  room,
  tabs
}

const computed = {
  location () {
    return this.$store.state.locations.active;
  }
}

export default {
  name: "amenities-and-photos",
  components,
  computed,
  data () {
    return {
      loading: false
    }
  },
  created () {
    this.loading = true;
    this.$store.dispatch('locations.get', this.$route.params.id).then(() => {
      this.loading = false;
    });
  }
}
