import datatable from 'components/datatable'
import env from 'constants/env'
import { user} from 'services'
import moment from 'moment'

const components = {
  datatable,
}

const directives = {

}

const computed = {

}

const methods = {
  // runReport() {
  //   // Validation
  //   this.do_search = false
  //   this.do_search = true
  // }
  setCols () {
    this.columns = [
      {name: "Res. #", object_name: "reservation_number", sortable: true, sort_key: "reservation_number"},
      {name: "Location", object_name: "location_name"},
      {name: "Modified", object_name: "modified"},
      {name: "Cancelled", object_name: "cancelled"},
      {name: "Channel", object_name: "reservation.channel.name"},
      {name: "Customer Name", object_name: "guest_name"},
      {name: "Email", object_name: "email", sortable: true, sort_key: "email", visible: this.user.type != 'location'},
      {name: "From", object_name: (obj, parent) => {
        return moment(obj.from_date, ['YYYY-MM-DD HH:mm:ss']).format('MM/DD/YYYY');
      }, sortable: true, sort_key: "from_date"},
      {name: "To", object_name: (obj, parent) => {
        return moment(obj.to_date, ['YYYY-MM-DD HH:mm:ss']).format('MM/DD/YYYY');
      }, sortable: true, sort_key: "to_date"},
      {name: "Currency", object_name: "currency"},
      {name: "Subtotal", object_name: "subtotal"},
      {name: "Total Discount", object_name: "total_discount"},
      {name: "Partial Refund", object_name: "partial_refund"},
      {name: "Grand Total", object_name: "grand_total"},
      {name: "Location Commission", object_name: "commissions_total"},
      {name: "Location Taxes", object_name: "total_tax"},
      {name: "Location Fees", object_name: "location_fees_total", click: (data) => this.$emit('locationFeesClicked', {
        location_id: data.location_id, 
        reservation_id: data.reservation_id
      }),
      classes: ['pointer']},
      {name: "Due at Lot", object_name: "due_at_location"},
      {name: "Location Total Amount", object_name: "location_total"},
      {name: "Owed Amount", object_name: "owed_amount", visible: this.user.type == 'ims'},
      {name: "# Of Parking", object_name: "number_of_parkings"},
      {name: "# Of Rooms", object_name: "number_of_rooms"},
      {name: "Creation Date", object_name: (obj, parent) => {
        return moment(obj.creation_date, ['YYYY-MM-DD HH:mm:ss']).format('MM/DD/YYYY');
      }, sortable: true, sort_key: "creation_date"},
    ]
  },

  displayErrors (errors) {
    this.$emit('extendedFinishedReservationsErrors',errors);
  }
}

export default {
  name: 'reports-extended-finished_reservations',
  props: ['url', 'feesBreakDown'],
  computed,
  components,
  directives,
  methods,
  data () {
    return {
      // loading: true,
      processing: false,
      errors: {},
      user: null,
      columns: []
    }
  },
  created () {

  },

  mounted () {
    this.user = user.getUser();
    this.setCols();
  }
}
