var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "s-editable" }, [
    _vm.active
      ? _c("div", {}, [
          _c("div", { staticClass: "input-group" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.editedValue,
                  expression: "editedValue"
                }
              ],
              ref: "input",
              staticClass: "form-control",
              class: { disabled: _vm.loading },
              attrs: { type: "text", disabled: _vm.loading, placeholder: "" },
              domProps: { value: _vm.editedValue },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.editedValue = $event.target.value
                }
              }
            }),
            _vm._v(" "),
            _c("span", { staticClass: "input-group-btn" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-success",
                  class: { disabled: _vm.loading },
                  attrs: { disabled: _vm.loading, type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.save(_vm.$refs.input.value)
                    }
                  }
                },
                [
                  _vm.loading
                    ? _c("span", [
                        _c("i", { staticClass: "fa fa-spinner fa-spin" }),
                        _vm._v("\n            Saving...\n          ")
                      ])
                    : _c("span", [_vm._v("Save")])
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-danger",
                  class: { disabled: _vm.loading },
                  attrs: { disabled: _vm.loading, type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.cancel()
                    }
                  }
                },
                [_vm._v("Cancel")]
              )
            ])
          ])
        ])
      : _c(
          "div",
          {
            ref: "editableText",
            staticClass: "editable-container stooltip",
            on: {
              dblclick: function($event) {
                return _vm.enterActiveMode()
              }
            }
          },
          [
            _c("span", { staticClass: "tooltiptext" }, [
              _vm._v("Double click to edit")
            ]),
            _vm._v(
              "\n    " +
                _vm._s(_vm.shownValue || _vm.editedValue || "-") +
                "\n    "
            ),
            _c(
              "span",
              {
                staticClass: "editable-pincel",
                class: { "always-show": _vm.showPen }
              },
              [
                _c("i", {
                  staticClass: "fa fa-pencil",
                  attrs: { "aria-hidden": "true" }
                })
              ]
            )
          ]
        )
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-1fbca29b", { render: render, staticRenderFns: staticRenderFns })
  }
}