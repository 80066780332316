import toastr from 'toastr'
import { hasPermits } from 'directives'

const directives = {
  hasPermits
}

const components = {
}

const filters = {
}

const computed = {
}

const watch = {
  formActive () {
    this.errors = {};
  }
}

const methods = {
  //this submits the new rates
  submit () {

    this.processing = true;
    let data = _.cloneDeep(this.form);
    this.errors = {};
    data.room = Number(data.room);
    data.parking = Number(data.parking);
    data.daily_list = Number(data.daily_list);
    data.decision_maker = Number(data.decision_maker);

    this.$store.dispatch('locationEmail.new', data).then(() => {
      let errors = _.cloneDeep(this.$store.state.locationEmail.errors);
      if (errors) {
        this.errors = errors.errors;
        toastr.error(errors.message);
      } else {
        toastr.success('channel added successfully');
        this.formActive = false;
        this.clearForm()
      }
      this.processing = false;
    });
  },

  clearForm () {
    this.form.email = '';
    this.form.parking = false;
    this.form.room = false;
    this.form.daily_list = false;
    this.form.decision_maker = false;
  },

  
}

export default {
  name: 'add-location-email',
  computed,
  methods,
  components,
  filters,
  watch,
  directives,
  data () {
    return {
      formActive: false,
      loading: false,
      processing: false,
      form: {
        email:'',
        daily_list: false,
        decision_maker: false,
        parking: false,
        room: false,
        location_id: this.$route.params.id
      },
      errors: {},
    }
  }
}
