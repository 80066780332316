import toastr from 'toastr'
import { funcModal } from 'components/modals'
import { hasPermits } from 'directives'

const directives = {
  hasPermits
}

const components = {
  funcModal
}

const computed = {
  need_to_save () {
    return this.$store.state.channels.active.need_to_save;
  },

  errors () {
    if (this.$store.state.channels.errors) {
      return this.$store.state.channels.errors.errors;
    }

    return {}
  },

  error () {
    return this.$store.state.channels.errors;
  }
}

const methods = {

  showWebhookLogs () {
    this.showLogs = true;
  },

  generatekey () {
    const result = Math.random().toString(36).substring(2,12);
    this.form.webhook_secret_key = result;
  }

}

const watch = {
  formData (n, o) {
    if (n == o) return;

    this.form = n;
  }
}

export default {
  name: 'general-tab',
  props: ['formData'],
  components,
  computed,
  methods,
  directives,
  watch,
  data () {
    return {
      // errors: {},
      saving: false,
      form: this.formData,
      webhooks: [],
      showLogs: false,
      loading: false
    }
  }

  , created() {
    this.webhooks = this.formData.webhooks;
  }
}
