import toastr from 'toastr'
import moment from 'moment'
import { funcModal } from 'components/modals'
import datepicker from 'components/datepicker'

const components = {
    funcModal,
    datepicker,
}

const methods = {
    open (period = null) {
        if (period) {
            this.edit = true;
            this.title = "Edit Lease period";
            this.form.period_id = period.id;
            this.form.number_of_spots = period.number_of_spots;
            this.form.amount = period.amount;
            this.from_date = period.from ? moment(period.from, ['YYYY-MM-DD']).format('MM/DD/YYYY') : null;
            this.to_date = period.to ? moment(period.to, ['YYYY-MM-DD']).format('MM/DD/YYYY') : null;
        } else {
            this.edit = false;
            this.from_date = null;
            this.to_date = null;

            this.form.number_of_spots = null;
            this.form.amount = null;
        }
        
        this.$nextTick(() => {
            this.$refs.modal.open();
        });
    },

    submit (modal) {

        let data = _.cloneDeep(this.form);

        data.from_date = moment(this.from_date, ['MM/DD/YYYY']).format('YYYY-MM-DD');
        data.to_date = this.to_date ? moment(this.to_date, ['MM/DD/YYYY']).format('YYYY-MM-DD') : null;
        this.processing = true;
        let action = 'leaseTypes.newPeriod';
        if (this.edit) {
            action = 'leaseTypes.updatePeriod';
        }
        this.$store.dispatch(action, {
            location_id: this.$route.params.id,
            params: data
        }).then(() => {
            if (this.$store.state.leaseTypes.errors) {
                this.errors = _.cloneDeep(this.$store.state.leaseTypes.errors.errors);
                toastr.error(this.$store.state.leaseTypes.errors.message);
            } else {
                toastr.success('Data has been saved');
                this.$emit('refreshPeriods');
                this.from_date = null;
                this.to_date = null;
                this.form.number_of_spots = null;
                this.form.amount = null;
                modal.close();
            }
            this.processing = false;
        });
    }
}

export default {
    components,
    methods,
    props: ['type_id', 'type'],
    data () {
        return {
            edit: false,
            title: "New Lease period",
            from_date: null,
            to_date: null,
            form: {
                period_id: null,
                type_id: this.type_id,
                type: this.type,
                from_date: null,
                to_date: null,
                amount: null,
                number_of_spots: null,
            },
            errors: {},
            processing: false,
        }
    }
}