// import { http } from 'services'
import axios from 'axios'
// initial state
const state = () => ({
  title: null
})

// getters
const getters = {
}

// actions
const actions = {
  ['title.setTitle'] ({ commit, state }, title) {
    commit('META_SET_TITLE', title)
  }
}

// mutations
const mutations = {
  META_SET_TITLE (state, title) {
    state.title = title
    if (!isServer) {
      document.title = title;
    }
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
