import toastr from 'toastr'
import layout from 'layouts/default'
import tabs from '../tabs'
import { confirm } from 'components/modals'
import { hasPermits } from 'directives'
import newLocationEmail from "./newLocationEmail"
import schedulesModal from "./schedules/index"


const directives = {
  hasPermits
}

const components = {
  layout,
  tabs,
  confirm,
  newLocationEmail,
  schedulesModal
}

const computed = {
  location () {
    return _.cloneDeep(this.$store.state.locations.active);
  },
  data () {
    let users_emails =_.map(_.cloneDeep(this.$store.state.locations.active).users, item => {
      if (item.user) return item.user.email;
    });
    let data = _.map(_.cloneDeep(this.$store.state.locationEmail.all), item => {
      item.is_location_user = users_emails.includes(item.email)
      return item
    });
    return data;
  },
}

const methods = {
  removeEmail (user) {
    this.$refs.confirmDelete.confirm('Are you sure that you want to remove this email?', () => {
      this.$set(this.loadings, user.id, true)
      this.$store.dispatch('locationEmail.remove', {location_id: this.location_id, id: user.id})
      .then(() => {
        this.$set(this.loadings, user.id, false)
        
        let errors = _.cloneDeep(this.$store.state.locationEmail.errors);
        if (errors) {
          toastr.error(errors.message);
        } else {
          toastr.success('Email has been deleted successfully');
        }
      });
    });
  },

  updateLocationEmail(user, type, e) {
    let value = e.target.checked;
    user[type] = Number(value);
    
    this.$store.dispatch('locationEmail.edit', {
      id: user.id, 
      data: user,
    }).then(() => {
      let errors = _.cloneDeep(this.$store.state.locationEmail.errors);
      if (errors) {
        this.errors = errors.errors;
        toastr.error(errors.message);
        if (type == 'decision_maker' && user[type] == 1) user[type] = e.target.checked = 0;
      } else {
        toastr.success('Location email updated successfully');
      }      
    })
  },

  openSchedules(user) {
    this.$refs.schedulesModal.open(this.$route.params.id, user.id);
  }
}


export default {
  name: 'location-emails-tab',
  components,
  computed,
  methods,
  directives,
  data () {
    return {
      loading: false,
      location_id: this.$route.params.id,
      loadings: {},
    }
  },
  created () {
    this.loading = true;
    let params = {location_id: this.$route.params.id}

    Promise.all([
      this.$store.dispatch('locations.get', this.$route.params.id),
      this.$store.dispatch('locationEmail.getAll', params)
    ]).then(() => {
      this.loading = false;
    });
  }
}
