import {
  edit as editReservation
} from 'pages/reservation'
import reservations from 'pages/reservations'

export default [
  //Reservations
  { path: '/reservations', name: 'reservations.all', component: reservations
    , meta:{permits:['super_admin']}},
  { path: '/reservation/:id', name: 'reservations.edit', component: editReservation
    , meta:{permits:['location_user', 'update_reservation_dates', 'update_reservation']}},
]
