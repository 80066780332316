import toastr from 'toastr'
import { funcModal } from 'components/modals'
import fileUpload from 'components/fileUpload'

const components = {
  funcModal,
  fileUpload
}

const methods = {

  uploadFile () {
    this.files_processing = true;
    this.errors = {};
    if (!this.uploadForm.file) {
      this.errors.no_file_selected = true;
      this.files_processing = false;
      return;
    }
    this.$store.dispatch('apiDocumentation.uploadFile', {
        file: this.uploadForm.file,
        type: this.uploadForm.type
    }).then(() => {
        let errors;
        if (errors = this.$store.state.apiDocumentationUpload.errors) {
          if (errors.message) {
            toastr.error(errors.message);
          }
        } else {
            toastr.success("File uploaded successfully");
            this.formActive = false;
            this.$refs.file_upload.close();
        }
        this.files_processing = false;
    });
  },

  openAPIDocumentationUpload () {
    this.formActive = true;
    this.$nextTick(() => {
      this.$refs.file_upload.open();
    });
  },

  fileSelectedToUpload (files) {
    if (files.length) {
      this.uploadForm.file = files[0];
    } else {
      this.uploadForm.file = null;
    }

    this.errors = {};
  },
}

const computed = {
}

export default {
  name: "api-documentation-upload",
  components,
  methods,
  computed,
  data () {
    return {
      formActive: false,
      files_processing: false,
      errors: {},
      uploadForm: {
        file: null,
        type: 'parking_only'
      }
    }
  }
}
