import Vue from 'vue'
import _ from 'lodash'
import { http } from 'services'

// initial state
const state = () => ({
  rooms: [],
  parkings: [],
  bedTypes: [],
  errors: null
})

// getters
const getters = {

}

// actions
const actions = {
  //abstract/main types
  // ['types.getRooms'] ({ commit, state }) {
  //   commit('CLEAR_ERRORS')
  //   return (http.get('types', {
  //     params: {
  //       type: 'room',
  //       _all: true
  //     }
  //   })).then((res) => {
  //     commit('GET_ROOM_TYPES', res);
  //   }).catch((res) => {
  //     commit('TYPES_ERRORS', res);
  //   });
  // },
  ['types.getParkings'] ({ commit, state }, id) {
    commit('CLEAR_ERRORS')
    return (http.get('types', {
      params: {
        type: 'parking',
        _all: true,
        location_id: id
      }
    })).then((res) => {
      commit('GET_PARKING_TYPES', res);
    }).catch((res) => {
      commit('TYPES_ERRORS', res);
    });
  },
  ['types.getBedTypes'] ({ commit, state }, id) {
    commit('CLEAR_ERRORS')
    return (http.get('bed-types', {
      params: {
        _all: true,
        location_id: id
      }
    })).then((res) => {
      commit('GET_BED_TYPES', res);
    }).catch((res) => {
      commit('TYPES_ERRORS', res);
    });
  },
}

// mutations
const mutations = {
  // GET_ROOM_TYPES (state, res) {
  //   state.rooms = res.data
  // },
  GET_PARKING_TYPES (state, res) {
    state.parkings = res.data
  },
  GET_BED_TYPES (state, res) {
    state.bedTypes = res.data
  },
  //ERRORS
  TYPES_ERRORS (state, err) {
    try {
      state.errors = err.response.data;
    } catch (e) {
      state.errors = err;
    }
  },
  CLEAR_ERRORS (state) {
    state.errors = null
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
