import datatable from 'components/datatable'
import env from 'constants/env'
import moment from 'moment'

const components = {
  datatable,
}

const directives = {

}

const computed = {

}

const methods = {
  // runReport() {
  //   // Validation
  //   this.do_search = false
  //   this.do_search = true
  // }
  displayErrors (errors) {
    this.$emit('checkinReportErrors',errors);
  }
}

export default {
  name: 'reports-checkin_checkout',
  props: ['url'],
  computed,
  components,
  directives,
  methods,
  data () {
    return {
      // loading: true,
      processing: false,
      errors: {},
      // do_search: false,
      columns: [
        {name: "Reservation #", object_name: "reservation_number", sortable: true, sort_key: 'reservation_number'},
        {name: "Customer Name", object_name: "reserved_for", sortable: true, sort_key: 'reserved_for'},
        {name: "From", object_name: (obj, parent) => {
          return moment(obj.from_date, ['YYYY-MM-DD HH:mm:ss']).format('MM/DD/YYYY');
        }, sortable: true, sort_key: "from_date"},
        {name: "To", object_name: (obj, parent) => {
          return moment(obj.to_date, ['YYYY-MM-DD HH:mm:ss']).format('MM/DD/YYYY');
        }, sortable: true, sort_key: "to_date"},
        {name: "Dates of Sleep", object_name: (obj, parent) => {
          let dates = [];
          if (obj.dates_of_sleep.length) {
            _.forEach(obj.dates_of_sleep, (date) => {
              let formated_date = moment(date, ['YYYY-MM-DD HH:mm:ss']).format('MM/DD/YYYY');
              dates.push(formated_date);
            })
            return dates.join(' | ');
          }
          return '-';
        }, containsHtml: true},
        {name: "Check-in", object_name: (obj, parent) => {
          if (obj.checkin_datetime != '-') {
            return moment(obj.checkin_datetime, ['YYYY-MM-DD HH:mm:ss']).format('MM/DD/YYYY HH:mm');  
          }
          return '-';
        }, sortable: true, sort_key: "checkin_datetime"},
        {name: "Check-out", object_name: (obj, parent) => {
          if (obj.checkout_datetime != '-') {
            return moment(obj.checkout_datetime, ['YYYY-MM-DD HH:mm:ss']).format('MM/DD/YYYY HH:mm');  
          }
          return '-';
        }, sortable: true, sort_key: "checkout_datetime"},
        {name: "Location", object_name: "location_name", sortable: true, sort_key: "location_name"},
        {name: "Channel", object_name: "channel_name", sortable: true, sort_key: "channel_name"},
        {name: "# Of Parking", object_name: "number_of_parkings"},
        {name: "Parking Type", object_name: "parking_type"},
        {name: "# Of Rooms", object_name: "number_of_rooms"},
        {name: "Room Type", object_name: "room_type"},
        {name: "Creation Date", object_name: (obj, parent) => {
          return moment(obj.creation_date, ['YYYY-MM-DD HH:mm:ss']).format('MM/DD/YYYY');
        }, sortable: true, sort_key: "creation_date"},
      ]
    }
  },
  created () {
  }
}
