var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.location
    ? _c(
        "func-modal",
        {
          ref: "modal",
          attrs: { title: "Update Check-in and Check-out times" },
          scopedSlots: _vm._u(
            [
              {
                key: "footer",
                fn: function(props) {
                  return [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary",
                        class: { disabled: _vm.processing },
                        attrs: { disabled: _vm.processing },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.submit(props)
                          }
                        }
                      },
                      [
                        _vm.processing
                          ? _c("i", { staticClass: "fa fa-spinner fa-spin" })
                          : _vm._e(),
                        _vm._v("\n            Save\n        ")
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-default",
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return props.close()
                          }
                        }
                      },
                      [_vm._v("Close")]
                    )
                  ]
                }
              }
            ],
            null,
            false,
            2328347292
          )
        },
        [
          _c("template", { slot: "body" }, [
            _c("div", { staticClass: "form-group" }, [
              _c("label", { attrs: { for: "checkinTime" } }, [
                _vm._v("Check-in Time")
              ]),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.location.checkin_time,
                      expression: "location.checkin_time"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { id: "checkinTime" },
                  on: {
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.location,
                        "checkin_time",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    }
                  }
                },
                [
                  _c("option", { attrs: { value: "" } }, [
                    _vm._v("-- Choose time --")
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.times, function(time) {
                    return _c("option", { domProps: { value: time.value } }, [
                      _vm._v(
                        "\n            " + _vm._s(time.label) + "\n            "
                      )
                    ])
                  })
                ],
                2
              ),
              _vm._v(" "),
              _vm.errors.checkin_time
                ? _c("div", { staticClass: "text-danger" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.errors.checkin_time[0]) +
                        "\n        "
                    )
                  ])
                : _vm._e()
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c("label", { attrs: { for: "checkoutTime" } }, [
                _vm._v("Check-out Time")
              ]),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.location.checkout_time,
                      expression: "location.checkout_time"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { id: "checkoutTime" },
                  on: {
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.location,
                        "checkout_time",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    }
                  }
                },
                [
                  _c("option", { attrs: { value: "" } }, [
                    _vm._v("-- Choose time --")
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.times, function(time) {
                    return _c("option", { domProps: { value: time.value } }, [
                      _vm._v(
                        "\n            " + _vm._s(time.label) + "\n            "
                      )
                    ])
                  })
                ],
                2
              ),
              _vm._v(" "),
              _vm.errors.checkout_time
                ? _c("div", { staticClass: "text-danger" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.errors.checkout_time[0]) +
                        "\n        "
                    )
                  ])
                : _vm._e()
            ])
          ])
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-634cfe38", { render: render, staticRenderFns: staticRenderFns })
  }
}