var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {}, [
    _c("div", { staticClass: "text-right" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-default",
          on: {
            click: function($event) {
              $event.preventDefault()
              _vm.formActive = !_vm.formActive
            }
          }
        },
        [
          !_vm.formActive
            ? _c("i", {
                staticClass: "fa fa-caret-down",
                attrs: { "aria-hidden": "true" }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.formActive
            ? _c("i", {
                staticClass: "fa fa-caret-up",
                attrs: { "aria-hidden": "true" }
              })
            : _vm._e(),
          _vm._v("\n      New Dynamic Fee\n    ")
        ]
      )
    ]),
    _vm._v(" "),
    _vm.formActive
      ? _c("div", { staticClass: "margin-top-3x" }, [
          _c("div", { staticClass: "panel panel-default" }, [
            _c("div", { staticClass: "panel-body" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-md-3" }, [
                  _c("label", [_vm._v("Name")]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.name,
                        expression: "form.name"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: { type: "text" },
                    domProps: { value: _vm.form.name },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.form, "name", $event.target.value)
                      }
                    }
                  }),
                  _vm._v(" "),
                  _vm.errors.name
                    ? _c("div", { staticClass: "text-danger" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.errors.name[0]) +
                            "\n            "
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("label", [_vm._v("USD Flat")]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.usd_flat,
                        expression: "form.usd_flat"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: { type: "text" },
                    domProps: { value: _vm.form.usd_flat },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.form, "usd_flat", $event.target.value)
                      }
                    }
                  }),
                  _vm._v(" "),
                  _vm.errors.usd_flat
                    ? _c("div", { staticClass: "text-danger" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.errors.usd_flat[0]) +
                            "\n            "
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("label", [_vm._v("CAD Flat")]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.cad_flat,
                        expression: "form.cad_flat"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: { type: "text" },
                    domProps: { value: _vm.form.cad_flat },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.form, "cad_flat", $event.target.value)
                      }
                    }
                  }),
                  _vm._v(" "),
                  _vm.errors.cad_flat
                    ? _c("div", { staticClass: "text-danger" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.errors.cad_flat[0]) +
                            "\n            "
                        )
                      ])
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-md-5 no-padding-all" },
                  [
                    _c("label", [_vm._v("Fee Percent Values")]),
                    _vm._v(" "),
                    _vm.form.percent_values.length == 0
                      ? _c(
                          "a",
                          {
                            attrs: { href: "#" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.addFeePercentValue()
                              }
                            }
                          },
                          [_c("i", { staticClass: "fa fa-plus fa-lg" })]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm._l(_vm.form.percent_values, function(percent_value, i) {
                      return _c(
                        "div",
                        { key: i, staticClass: "col-md-12 no-padding-all" },
                        [
                          _c("div", { staticClass: "col-sm-3" }, [
                            _vm._m(0, true),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.form.percent_values[i].from,
                                  expression: "form.percent_values[i].from"
                                }
                              ],
                              staticClass: "form-control",
                              attrs: { type: "text" },
                              domProps: {
                                value: _vm.form.percent_values[i].from
                              },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.form.percent_values[i],
                                    "from",
                                    $event.target.value
                                  )
                                }
                              }
                            }),
                            _vm._v(" "),
                            _vm.errors["percent_values." + i + ".from"]
                              ? _c("div", { staticClass: "text-danger" }, [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(
                                        _vm.errors[
                                          "percent_values." + i + ".from"
                                        ][0]
                                      ) +
                                      "\n                "
                                  )
                                ])
                              : _vm._e()
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-sm-3" }, [
                            _c("label", [_vm._v("To")]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.form.percent_values[i].to,
                                  expression: "form.percent_values[i].to"
                                }
                              ],
                              staticClass: "form-control",
                              attrs: { type: "text" },
                              domProps: {
                                value: _vm.form.percent_values[i].to
                              },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.form.percent_values[i],
                                    "to",
                                    $event.target.value
                                  )
                                }
                              }
                            }),
                            _vm._v(" "),
                            _vm.errors["percent_values." + i + ".to"]
                              ? _c("div", { staticClass: "text-danger" }, [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(
                                        _vm.errors[
                                          "percent_values." + i + ".to"
                                        ][0]
                                      ) +
                                      "\n                "
                                  )
                                ])
                              : _vm._e()
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col-sm-3 no-padding-right" },
                            [
                              _c("label", [_vm._v("Value")]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.form.percent_values[i].value,
                                    expression: "form.percent_values[i].value"
                                  }
                                ],
                                staticClass: "form-control",
                                attrs: { type: "text" },
                                domProps: {
                                  value: _vm.form.percent_values[i].value
                                },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.form.percent_values[i],
                                      "value",
                                      $event.target.value
                                    )
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _vm.errors["percent_values." + i + ".value"]
                                ? _c("div", { staticClass: "text-danger" }, [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          _vm.errors[
                                            "percent_values." + i + ".value"
                                          ][0]
                                        ) +
                                        "\n                "
                                    )
                                  ])
                                : _vm._e()
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-sm-3" }, [
                            _c("label", [_vm._v("   ")]),
                            _vm._v(" "),
                            _c("div", [
                              _c(
                                "a",
                                {
                                  staticClass: "text-danger",
                                  attrs: { href: "#" },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.removePercentValue(i)
                                    }
                                  }
                                },
                                [_c("i", { staticClass: "fa fa-trash fa-lg" })]
                              ),
                              _vm._v(" "),
                              _c(
                                "a",
                                {
                                  attrs: { href: "#" },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.addFeePercentValue(i + 1)
                                    }
                                  }
                                },
                                [_c("i", { staticClass: "fa fa-plus fa-lg" })]
                              )
                            ])
                          ])
                        ]
                      )
                    }),
                    _vm._v(" "),
                    _vm.errors.percent_values
                      ? _c("div", { staticClass: "text-danger" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.errors.percent_values[0]) +
                              "\n            "
                          )
                        ])
                      : _vm._e()
                  ],
                  2
                ),
                _vm._v(" "),
                _c("div", { staticClass: "col-md-4 no-padding-all" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", [_vm._v("Locations")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "radio" }, [
                      _c("label", { staticClass: "margin-right-3x" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.all_locations,
                              expression: "form.all_locations"
                            }
                          ],
                          attrs: { type: "radio" },
                          domProps: {
                            value: 1,
                            checked: _vm._q(_vm.form.all_locations, 1)
                          },
                          on: {
                            change: [
                              function($event) {
                                return _vm.$set(_vm.form, "all_locations", 1)
                              },
                              _vm.onChangeLocations
                            ]
                          }
                        }),
                        _vm._v(
                          "\n                  All Locations\n                "
                        )
                      ]),
                      _vm._v(" "),
                      _c("label", { staticClass: "margin-right-3x" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.all_locations,
                              expression: "form.all_locations"
                            }
                          ],
                          attrs: { type: "radio" },
                          domProps: {
                            value: 2,
                            checked: _vm._q(_vm.form.all_locations, 2)
                          },
                          on: {
                            change: [
                              function($event) {
                                return _vm.$set(_vm.form, "all_locations", 2)
                              },
                              _vm.onChangeLocations
                            ]
                          }
                        }),
                        _vm._v(
                          "\n                  All Locations Except \n                  "
                        ),
                        _c("span", { staticClass: "text-success f10" }, [
                          _vm._v("(this fee will applied to new locations)")
                        ])
                      ]),
                      _vm._v(" "),
                      _c("label", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.all_locations,
                              expression: "form.all_locations"
                            }
                          ],
                          attrs: { type: "radio" },
                          domProps: {
                            value: 0,
                            checked: _vm._q(_vm.form.all_locations, 0)
                          },
                          on: {
                            change: [
                              function($event) {
                                return _vm.$set(_vm.form, "all_locations", 0)
                              },
                              _vm.onChangeLocations
                            ]
                          }
                        }),
                        _vm._v(
                          "\n                  Selected Locations\n                  "
                        ),
                        _c("span", { staticClass: "text-danger f10" }, [
                          _vm._v("(this fee will not applied to new locations)")
                        ])
                      ]),
                      _vm._v(" "),
                      _vm.errors.all_locations
                        ? _c("div", { staticClass: "text-danger" }, [
                            _vm._v(
                              "\n                  " +
                                _vm._s(_vm.errors.all_locations[0]) +
                                "\n                "
                            )
                          ])
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _vm.showOldFeeWarning
                      ? _c("div", { staticClass: "text-warning" }, [
                          _vm._v(
                            "\n                Some locations have more than one channel fee\n              "
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.form.all_locations != 1
                      ? _c(
                          "div",
                          {},
                          [
                            _c("s-select", {
                              attrs: {
                                selected: _vm.selectedLocations,
                                options: _vm.locations,
                                multiple: true
                              },
                              on: {
                                selectedChanged: _vm.onChangeSelectedLocations
                              }
                            }),
                            _vm._v(" "),
                            _vm._l(_vm.selectedLocations, function(
                              location,
                              l
                            ) {
                              return _c(
                                "div",
                                {
                                  key: l,
                                  staticClass: "margin-top-1x margin-bottom-1x"
                                },
                                [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "text-danger",
                                      attrs: { href: "#" },
                                      on: {
                                        click: function($event) {
                                          $event.preventDefault()
                                          return _vm.removeSelected(location)
                                        }
                                      }
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "fa fa-times",
                                        attrs: { "aria-hidden": "true" }
                                      })
                                    ]
                                  ),
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(location.text) +
                                      "\n                "
                                  )
                                ]
                              )
                            }),
                            _vm._v(" "),
                            _vm.errors.locations
                              ? _c("div", { staticClass: "text-danger" }, [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(_vm.errors.locations[0]) +
                                      "\n                "
                                  )
                                ])
                              : _vm._e()
                          ],
                          2
                        )
                      : _vm._e()
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "text-right" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-success",
                    class: { disabled: _vm.processing },
                    attrs: { disabled: _vm.processing },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.submit()
                      }
                    }
                  },
                  [
                    _vm.processing
                      ? _c("span", [
                          _c("i", { staticClass: "fa fa-spinner fa-spin" })
                        ])
                      : _vm._e(),
                    _vm._v("\n            SAVE FEE\n          ")
                  ]
                )
              ])
            ])
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [
      _vm._v("\n                  From\n                  "),
      _c(
        "i",
        {
          staticClass: "fa fa-info stooltip",
          attrs: { "data-toggle": "tooltip" }
        },
        [
          _vm._v("*\n                    "),
          _c("span", { staticClass: "tooltiptext" }, [_vm._v("inclusive")])
        ]
      )
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-0c571671", { render: render, staticRenderFns: staticRenderFns })
  }
}