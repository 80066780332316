import moment from 'moment';

class DateTime {
  getHalfHours () {
    let times = [];
    for (var i = 1; i <= 24; i++) {
      
      let label_hour = i > 12 ? i - 12 : i;
      
      let label1 = label_hour < 10 ? '0'+ label_hour + ':00' + (i % 24 >= 12 ? ' pm' : ' am') : label_hour + ':00' + (i % 24 >= 12 ? ' pm' : ' am');
      let value1 = i < 10 ? '0'+ i + ':00' : i % 24 + ':00';
      let label2 = label_hour < 10 ? '0'+ label_hour + ':30' + (i % 24 >= 12 ? ' pm' : ' am') : label_hour + ':30' + (i % 24 >= 12 ? ' pm' : ' am');
      let value2 = i < 10 ? '0'+ i + ':30' : i % 24 + ':30';

      times.push({
        label: label1,
        value: value1,
      });

      times.push({
        label: label2,
        value: value2,
      });
      
    }

    return times
  }
}

export default new DateTime()
