var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("layout", { attrs: { "no-sidebar": true } }, [
    _vm.loading
      ? _c("div", { staticClass: "text-center" }, [
          _c("i", { staticClass: "fa fa-spinner fa-spin fa-3x fa-fw" })
        ])
      : _c("div", { staticClass: "col-sm-8 col-sm-offset-2 margin-top-3x" }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "hasPermits",
                  rawName: "v-hasPermits:allow",
                  value: { permits: ["user_update"] },
                  expression: "{permits: ['user_update']}",
                  arg: "allow"
                }
              ],
              staticClass: "text-right"
            },
            [
              _c(
                "button",
                {
                  staticClass: "btn btn-success",
                  class: { disabled: _vm.processing },
                  attrs: { disabled: _vm.processing },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.submit.apply(null, arguments)
                    }
                  }
                },
                [
                  _vm.processing
                    ? _c("span", [
                        _c("i", { staticClass: "fa fa-spinner fa-spin fa-fw" }),
                        _vm._v("\n          SAVING...\n        ")
                      ])
                    : _c("span", [_vm._v("SAVE")])
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-default",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.$router.back()
                    }
                  }
                },
                [_vm._v("Back")]
              )
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "margin-bottom-3x" }, [
            _c("label", [_vm._v("Parking Type")]),
            _vm._v(" "),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.type_id,
                    expression: "form.type_id"
                  }
                ],
                staticClass: "form-control",
                on: {
                  change: function($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function(o) {
                        return o.selected
                      })
                      .map(function(o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.$set(
                      _vm.form,
                      "type_id",
                      $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                    )
                  }
                }
              },
              _vm._l(_vm.types, function(type) {
                return _c(
                  "option",
                  { key: type.id, domProps: { value: type.id } },
                  [_vm._v(_vm._s(type.name))]
                )
              }),
              0
            ),
            _vm._v(" "),
            _vm.errors.type_id
              ? _c("div", { staticClass: "text-danger" }, [
                  _vm._v(
                    "\n        " + _vm._s(_vm.errors.type_id[0]) + "\n      "
                  )
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "margin-bottom-3x" }, [
            _c("label", [_vm._v("Type Name")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.number",
                  value: _vm.form.name,
                  expression: "form.name",
                  modifiers: { number: true }
                }
              ],
              ref: "",
              staticClass: "form-control",
              attrs: { type: "text" },
              domProps: { value: _vm.form.name },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.form, "name", _vm._n($event.target.value))
                },
                blur: function($event) {
                  return _vm.$forceUpdate()
                }
              }
            }),
            _vm._v(" "),
            _vm.errors.name
              ? _c("div", { staticClass: "text-danger" }, [
                  _vm._v("\n        " + _vm._s(_vm.errors.name[0]) + "\n      ")
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "margin-bottom-3x" }, [
            _vm.form.parent_id
              ? _c("label", [_vm._v("Maximum Spots")])
              : _c("label", [_vm._v("Number Of Available Parkings")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.number",
                  value: _vm.form.number_of_parkings,
                  expression: "form.number_of_parkings",
                  modifiers: { number: true }
                }
              ],
              ref: "",
              staticClass: "form-control",
              attrs: { type: "number", min: "0" },
              domProps: { value: _vm.form.number_of_parkings },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(
                    _vm.form,
                    "number_of_parkings",
                    _vm._n($event.target.value)
                  )
                },
                blur: function($event) {
                  return _vm.$forceUpdate()
                }
              }
            }),
            _vm._v(" "),
            _vm.errors.number_of_parkings
              ? _c("div", { staticClass: "text-danger" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.errors.number_of_parkings[0]) +
                      "\n      "
                  )
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "hasPermits",
                  rawName: "v-hasPermits:allow",
                  value: ["super_admin"],
                  expression: "['super_admin']",
                  arg: "allow"
                }
              ],
              staticClass: "margin-bottom-3x"
            },
            [
              _c("label", [_vm._v("Price Limit")]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.number",
                    value: _vm.form.price_limit,
                    expression: "form.price_limit",
                    modifiers: { number: true }
                  }
                ],
                ref: "",
                staticClass: "form-control",
                attrs: { type: "number", min: "0" },
                domProps: { value: _vm.form.price_limit },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.form,
                      "price_limit",
                      _vm._n($event.target.value)
                    )
                  },
                  blur: function($event) {
                    return _vm.$forceUpdate()
                  }
                }
              }),
              _vm._v(" "),
              _vm.errors.price_limit
                ? _c("div", { staticClass: "text-danger" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.errors.price_limit[0]) +
                        "\n      "
                    )
                  ])
                : _vm._e()
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "margin-bottom-3x" }, [
            _c("label", [_vm._v("Suffix")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.number",
                  value: _vm.form.suffix,
                  expression: "form.suffix",
                  modifiers: { number: true }
                }
              ],
              ref: "",
              staticClass: "form-control",
              attrs: { type: "text" },
              domProps: { value: _vm.form.suffix },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.form, "suffix", _vm._n($event.target.value))
                },
                blur: function($event) {
                  return _vm.$forceUpdate()
                }
              }
            }),
            _vm._v(" "),
            _vm.errors.suffix
              ? _c("div", { staticClass: "text-danger" }, [
                  _vm._v(
                    "\n        " + _vm._s(_vm.errors.suffix[0]) + "\n      "
                  )
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          !_vm.location_parking_type.parent_id &&
          !_vm.location_parking_type.childTypes.length
            ? _c("div", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.link_with_parent,
                      expression: "link_with_parent"
                    }
                  ],
                  attrs: {
                    type: "checkbox",
                    id: "link",
                    name: "link",
                    value: "link_with_parent"
                  },
                  domProps: {
                    checked: Array.isArray(_vm.link_with_parent)
                      ? _vm._i(_vm.link_with_parent, "link_with_parent") > -1
                      : _vm.link_with_parent
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.link_with_parent,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = "link_with_parent",
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.link_with_parent = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.link_with_parent = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.link_with_parent = $$c
                      }
                    }
                  }
                }),
                _vm._v(" "),
                _c("label", { attrs: { for: "link" } }, [
                  _vm._v("Link with another parking type")
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.link_with_parent
            ? _c("div", [
                _c("div", { staticClass: "margin-bottom-3x" }, [
                  _c("label", [_vm._v("Parent Parking Type")]),
                  _vm._v(" "),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.parent_id,
                          expression: "form.parent_id"
                        }
                      ],
                      staticClass: "form-control",
                      class: { disabled: _vm.location_parking_type.parent_id },
                      attrs: { disabled: _vm.location_parking_type.parent_id },
                      on: {
                        change: function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.form,
                            "parent_id",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        }
                      }
                    },
                    [
                      _c("option", { domProps: { value: null } }, [
                        _vm._v("select parking type")
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.parking_types, function(type) {
                        return _c(
                          "option",
                          { key: type.id, domProps: { value: type.id } },
                          [_vm._v(_vm._s(type.name))]
                        )
                      })
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _vm.errors.parent_id
                    ? _c("div", { staticClass: "text-danger" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.errors.parent_id[0]) +
                            "\n        "
                        )
                      ])
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "margin-bottom-3x" }, [
                  _c("label", [_vm._v("percentage")]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.number",
                        value: _vm.form.percentage,
                        expression: "form.percentage",
                        modifiers: { number: true }
                      }
                    ],
                    ref: "",
                    staticClass: "form-control",
                    attrs: { type: "number", min: "0" },
                    domProps: { value: _vm.form.percentage },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.form,
                          "percentage",
                          _vm._n($event.target.value)
                        )
                      },
                      blur: function($event) {
                        return _vm.$forceUpdate()
                      }
                    }
                  }),
                  _vm._v(" "),
                  _vm.errors.percentage
                    ? _c("div", { staticClass: "text-danger" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.errors.percentage[0]) +
                            "\n        "
                        )
                      ])
                    : _vm._e()
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "form-group" }, [
            _c("div", { staticClass: "checkbox" }, [
              _c("label", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.sold_out_if_not_lease,
                      expression: "form.sold_out_if_not_lease"
                    }
                  ],
                  class: { disabled: !_vm.form.parent_id },
                  attrs: { type: "checkbox", disabled: !_vm.form.parent_id },
                  domProps: {
                    checked: Array.isArray(_vm.form.sold_out_if_not_lease)
                      ? _vm._i(_vm.form.sold_out_if_not_lease, null) > -1
                      : _vm.form.sold_out_if_not_lease
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.form.sold_out_if_not_lease,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.form,
                              "sold_out_if_not_lease",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.form,
                              "sold_out_if_not_lease",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.form, "sold_out_if_not_lease", $$c)
                      }
                    }
                  }
                }),
                _vm._v(
                  "\n            If outside of lease period, return lease rate as sold out\n        "
                )
              ])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form-group" }, [
            _c("div", { staticClass: "checkbox" }, [
              _c("label", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.related_to_lease,
                      expression: "form.related_to_lease"
                    }
                  ],
                  class: {
                    disabled: !_vm.form.parent_id || _vm.form.percentage == 1
                  },
                  attrs: {
                    type: "checkbox",
                    disabled: !_vm.form.parent_id || _vm.form.percentage == 1
                  },
                  domProps: {
                    checked: Array.isArray(_vm.form.related_to_lease)
                      ? _vm._i(_vm.form.related_to_lease, null) > -1
                      : _vm.form.related_to_lease
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.form.related_to_lease,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.form,
                              "related_to_lease",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.form,
                              "related_to_lease",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.form, "related_to_lease", $$c)
                      }
                    }
                  }
                }),
                _vm._v("\n            Related to lease\n        ")
              ])
            ])
          ])
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-7267e9b2", { render: render, staticRenderFns: staticRenderFns })
  }
}