import { dateFormat, currencySymbol } from 'filters'
import { confirm, extendReservationModal, reservationDetails as viewReservation } from 'components/modals'
import { hasPermits } from 'directives'
import toastr from 'toastr'
import moment from 'moment'


const directives = {
  hasPermits
}

const filters = {
  dateFormat
}

const components = {
  confirm, viewReservation, extendReservationModal
}

const methods = {
  showReservationDetails (reservation_id, item) {
    this.$store.dispatch('dashboards.getReservation', {
      'item': item
    });
    this.$store.dispatch('locations.getReservation', {
      lId: this.location.id,
      id: reservation_id
    }).then(() => {
      this.loadings[item.id] = false;
      let errors = _.cloneDeep(this.$store.state.locations.errors);
      if (errors) {
        toastr.error(errors.message);
      } else {
        this.reservation = this.$store.state.locations.active_reservation;
        this.$refs.view.open('check-out', item);
      }
    });

    return false;
  },
  getList () {
    this.loading = true;
    let type = this.type;
    let date = this.date;
    if (this.type == 'all') {
      type = null;
    }
    this.$store.dispatch('dashboards.getCheckOutList', {
      location_id: this.location.id,
      params: {
        checked_out: this.checked_out,
        type,
        date,
        _q: this.search_key
      }
    }).then(() => {
      this.loading = false;
      this.all_list = _.cloneDeep(this.list);
      this.total_checkouts_count = this.list.length;
      if (this.type == 'all') {
        this.room_checkouts_count = _.filter(this.list, (item) => {
          return item.type_type == 'room';
        }).length;
      }
      
    });
  },

  toggleCheck (item) {
    let to_date = item.to_date;
    let to = moment(to_date).add(3, 'hours').tz(this.location.timezone.code, true);
    let now = moment(moment(), ['YYYY-MM-DD HH:mm:ss']).tz(this.location.timezone.code);

    if (!item.check_out && now.isAfter(to) && this.location.extend_reservation_when_check_out) {
      this.loadings[item.id] = true;
      this.loadings = _.cloneDeep(this.loadings);

      this.prepareData(item);

      this.$store.dispatch('reservations.costs', {id: item.history.reservation.id, data: this.form}).then(() => {

        let errors = _.cloneDeep(this.$store.state.reservations.errors);
  
        if (errors) {
          toastr.error(errors.message);
          this.errors = errors.errors;
          this.loadings[item.id] = false;

        } else {
          this.costs = _.cloneDeep(this.$store.state.reservations.costs);
          if (this.costs) {
            if (this.costsActiveHistory.due_at_location_total > 0) {
              this.amount_to_pay = (this.costs.reservation.due_at_location - this.costsActiveHistory.due_at_location_total).toFixed(2);
            } else {
              this.amount_to_pay = (this.costs.reservation.grand_total - this.costsActiveHistory.grand_total).toFixed(2);
            }
          }
      
          var duration = moment.duration(now.diff(moment(to_date).tz(this.location.timezone.code, true)));
          let hours = duration._data.days > 0 ? duration._data.days * 24 + duration._data.hours : duration._data.hours;
          let diff = hours + ':' + (duration._data.minutes < 10 ? '0' + duration._data.minutes : duration._data.minutes);
          if (this.amount_to_pay > 0) {
            let currency_code = this.location.currency ? currencySymbol(this.location.currency.code) : "Na";
            this.$refs.extendReservationModal.open(item, this.amount_to_pay + '' + currency_code, diff);
          } else {
            this.checkOut(item);
          }

        }
      });



    } else {
      this.checkOut(item);
    }
  },

  checkOut (item) {
    if (item.check_out) {
      this.$refs.confirm.confirm('Are you sure?', () => {
        this.checkReservation(item.id, this.location.id);
      });
    } else {
      this.checkReservation(item.id, this.location.id);
    }
  },

  updateReservation(type, item) {
    if (type == 'charge' && !this.location.block_extending_reservations) {
      this.errors = {}
      this.costs = null;

      this.loading = false;
      this.prepareData(item);

    this.$store.dispatch('reservations.update', {id: item.history.reservation.id, data: this.form}).then(() => {
      let errors = _.cloneDeep(this.$store.state.reservations.errors);

      if (errors) {
        toastr.error(errors.message);
        this.errors = errors.errors;
      } else {
        let reservation = _.cloneDeep(this.$store.state.reservations.active);
        let active_history = _.find(reservation.history, h => h.active);
        _.forEach(active_history.dates, (date) => {
          if (date.type_type != 'bundle' && date.type_type == item.type_type && date.from_date == item.from_date) {
            this.checkOut(date);
          }
        });
        toastr.success("Data has been saved");
        this.getList();
      }
    })} else if ((type == 'without_charge' || this.location.block_extending_reservations) && type != 'dont_checkout') {
      this.checkOut(item);
    } else {
      this.onCloseModal();
    }
    this.processing = false;
    this.loadings[item.id] = false;
  },

  onCloseModal() {
    this.loadings = {};
    this.$emit('close');
  },

  checkReservation (id, location_id) {
    this.$store.dispatch('dashboards.checkOutReservation', {
      id, location_id
    }).then(() => {
      let errors = _.cloneDeep(this.$store.state.dashboards.errors);
      if (errors) {
        if (errors.message.includes('This reservation is already cancelled')) {
          toastr.error(errors.message);
          setTimeout(() => {
            this.$router.go();
          }, "2000")
        }
      }
      this.processing = false;
      this.onCloseModal();
    });
  },

  updateProcessing(processing) {
    this.processing = processing;
  },

  toggleShow (item) {
    this.$store.dispatch('dashboards.showReservation', {
      'id': item.id, 'from': 'location', "type": "checkout"
    });
  },

  search (value) {
    if (value.length) {
      this.search_key = value;
    } else {
      this.search_key = null;
    }
    this.getList();
  },
  onSearchKeyup(value, e) {
    if (!value.length || e.keyCode == 13) {
      this.search(value);
    }
  },

  prepareData(item) {

    let now = moment(moment(), ['YYYY-MM-DD HH:mm:ss']).tz(this.location.timezone.code);

    this.form = {
      reservation_number: item.history.reservation.reservation_number,
      location_id: this.location.id,
      email: item.history.email,
      phone: item.history.phone,
      reserved_for: item.history.reserved_for,
      state: item.history.state,
      items: [],
      coupon_discount: item.history.coupon_discount > 0 ? item.history.coupon_discount.toFixed(2): null,
      extend_when_checkout: true
    }
    _.forEach(item.history.extra_fields, (field) => {
      this.form[field.name] = field.value;
    });
      
    _.forEach(item.history.dates, (date) => {
      if (date.type_type == 'bundle') {
        this.form.bundle_id = date.type_id;
        return;
      }

      this.form.items.push({
        id: date.id,
        from_date: date.from_date,
        to_date: date.id == item.id? now.format('YYYY-MM-DD HH:mm:ss') : date.to_date,
        type: date.type_type,
        number_of_spots: date.type_type == 'parking' ? date.number_of_units : null,
        number_of_rooms: date.type_type == 'room' ? date.number_of_units : null,
        type_id: date.type_id,
        guest_names: date.type_type == 'room' ? date.guest_names : null,
        guests_in_rooms: date.type_type == 'room' ? date.number_of_guests : null,
      });
    });

    this.form.items = _.map(this.form.items, (date) => {
      if (date.type == 'room') {
        date.guest_names = _.slice(date.guest_names, 0, Number(date.number_of_rooms));
        date.guests_in_rooms = _.slice(date.guests_in_rooms, 0, Number(date.number_of_rooms));
      }

      return date;
    });
  },

  getLicensePlate (item) {
    let license_plate = '';
    let text_license_plate ='';
    for (let i in item.history.extra_fields) {
      let extra_field = item.history.extra_fields[i];
      if (extra_field.input_type == 'license_plate' && extra_field.value.length > 0) {
        if (license_plate.length > 0) {
          license_plate += ", ";
        }
        license_plate += Array.isArray(extra_field.value) ? extra_field.value.join(', ') : extra_field.value;
      } else if (extra_field.input_type == 'text' && extra_field.name.includes('license')) {
        if (text_license_plate.length > 0) {
          text_license_plate += ", ";
        }
        text_license_plate += Array.isArray(extra_field.value) ? extra_field.value.join(', ') : extra_field.value;
      }
    }
    return license_plate.length > 0 ? license_plate : text_license_plate;
  }
}

const computed = {
  list () {
    return _.cloneDeep(this.$store.state.dashboards.checkout_list) || []
  },
  noShowReservation () {
    return _.filter(this.list, (item) => {
      return item.history.reservation.no_show == true;
    })
  },

  costsActiveHistory() {
    return this.costs ? _.find(this.costs.old_reservation.history, h => h.active) : null;
  },
}

const watch = {
  location (n) {
    this.getList();
  },
  date () {
    this.getList();
  }
}

export default {
  name: 'checkout-list',
  props: ['location', 'date'],
  methods,
  watch,
  computed,
  filters,
  components,
  directives,
  data () {
    return {
      loading: false,
      loadings: {},
      processing: false,
      type: 'all',
      checked_out: 'both',
      search_key: null,
      reservation: null,
      room_checkouts_count: 0,
      total_checkouts_count: 0,
      all_list: null,
      amount_to_pay: 0,
      form: {
        reservation_number: null,
        extra_fields: [],
        items: []
      },
      costs: null,
      has_license_plate: false,
    }
  },
  created () {
    if (this.location) {
      this.getList();
      if (this.location.extra_fields.some(extra_field => extra_field.input_type == 'license_plate' || 
        (extra_field.input_type == 'text' && extra_field.name.includes('license')))) {
        this.has_license_plate = true;
      } else {
        this.has_license_plate = false;
      }
    }
  }
}
