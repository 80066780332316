var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "calendar-view" },
    [
      _vm._m(0),
      _vm._v(" "),
      _c("VueCal", {
        ref: "vuecal",
        attrs: {
          "events-on-month-view": "short",
          "active-view": "month",
          todayButton: true,
          "disable-views": ["years", "week", "day"],
          hideViewSelector: true,
          clickToNavigate: true,
          dblclickToNavigate: false,
          "selected-date": _vm.selectedDate,
          events: _vm.events
        },
        on: {
          "view-change": function($event) {
            return _vm.viewChange("view-change", $event)
          }
        },
        scopedSlots: _vm._u([
          {
            key: "today-button",
            fn: function() {
              return [
                [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-default margin-left-3x",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.gotToday.apply(null, arguments)
                        }
                      }
                    },
                    [_vm._v("\n            Today\n          ")]
                  )
                ]
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "legend pull-right margin-bottom-2x margin-right-5x" },
        [
          _c("div", [
            _c("div", { staticClass: "square entries" }),
            _c("span", { staticClass: "f12" }, [_vm._v("Entries")])
          ]),
          _vm._v(" "),
          _c("div", [
            _c("div", { staticClass: "square exits" }),
            _c("span", { staticClass: "f12" }, [_vm._v("Exits")])
          ]),
          _vm._v(" "),
          _c("div", [
            _c("div", { staticClass: "square available" }),
            _c("span", { staticClass: "f12" }, [_vm._v("Available")])
          ]),
          _vm._v(" "),
          _c("div", [
            _c("div", { staticClass: "square rate" }),
            _c("span", { staticClass: "f12" }, [_vm._v("Rate")])
          ])
        ]
      )
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-2c64ea6e", { render: render, staticRenderFns: staticRenderFns })
  }
}