var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "layout",
    { attrs: { "no-box": true, title: _vm.location ? _vm.location.name : "" } },
    [
      _c("tabs"),
      _vm._v(" "),
      _vm.loading
        ? _c("div", { staticClass: "text-center" }, [
            _c("i", { staticClass: "fa fa-spinner fa-spin fa-3x fa-fw" })
          ])
        : _c("div", { staticClass: "box" }, [
            _c(
              "div",
              { staticClass: "box-header with-border margin-bottom-8x" },
              [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-sm-4 col-xs-12 margin-bottom-2x" },
                    [
                      _c("label", [_vm._v("From Date")]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "input-group" },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "input-group-addon",
                              attrs: { id: "basic-addon1" }
                            },
                            [
                              _c("i", {
                                staticClass: "fa fa-calendar",
                                attrs: { "aria-hidden": "true" }
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c("datepicker", {
                            staticClass: "form-control",
                            attrs: {
                              "min-date": "none",
                              placeholder: "MM/DD/YYYY"
                            },
                            on: { change: _vm.setChartData },
                            model: {
                              value: _vm.form.from_date,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "from_date", $$v)
                              },
                              expression: "form.from_date"
                            }
                          })
                        ],
                        1
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-sm-8 col-xs-12 padding-top-5x" },
                    [
                      _c("span", [
                        _c(
                          "a",
                          {
                            attrs: { href: "#" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.prevMonth.apply(null, arguments)
                              }
                            }
                          },
                          [
                            _c("i", {
                              staticClass: "fa fa-chevron-left",
                              attrs: { "aria-hidden": "true" }
                            })
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "span",
                          { staticClass: "margin-right-3x margin-left-3x" },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(this.form.from_date) +
                                " - " +
                                _vm._s(this.form.to_date) +
                                "\n            "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            attrs: { href: "#" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.nextMonth.apply(null, arguments)
                              }
                            }
                          },
                          [
                            _c("i", {
                              staticClass: "fa fa-chevron-right",
                              attrs: { "aria-hidden": "true" }
                            })
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-default margin-left-3x",
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.goBackToToday.apply(null, arguments)
                              }
                            }
                          },
                          [_vm._v("\n              Today\n            ")]
                        )
                      ])
                    ]
                  )
                ])
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "box-body" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-sm-12 col-xs-12" }, [
                  _c(
                    "div",
                    { staticClass: "chart-box chart-box--shadow" },
                    [
                      _c("div", { staticClass: "chart-box chart-box--title" }, [
                        _c("h3", [_vm._v("Occupancy Averages")])
                      ]),
                      _vm._v(" "),
                      _c("transition", { attrs: { name: "expand" } }, [
                        _c("table", { staticClass: "table table-hover" }, [
                          _c("thead", [
                            _c("tr", [
                              _c("th", { attrs: { scope: "col" } }, [
                                _vm._v(
                                  "\n                    Unit Type\n                  "
                                )
                              ]),
                              _vm._v(" "),
                              _c("th", { attrs: { scope: "col" } }, [
                                _vm._v(
                                  "\n                    Maximum Available Units\n                  "
                                )
                              ]),
                              _vm._v(" "),
                              _c("th", { attrs: { scope: "col" } }, [
                                _vm._v(
                                  "\n                    30-day Avg Occupancy\n                  "
                                )
                              ])
                            ])
                          ]),
                          _vm._v(" "),
                          _vm.showTable
                            ? _c(
                                "tbody",
                                [
                                  _c("tr", [
                                    _c("td", [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "btn btn-link",
                                          attrs: {
                                            "data-toggle": "tooltip",
                                            "data-placement": "top",
                                            title: "Room types listed"
                                          },
                                          on: {
                                            click: function($event) {
                                              $event.preventDefault()
                                              _vm.types.room.show = !_vm.types
                                                .room.show
                                            }
                                          }
                                        },
                                        [
                                          _c("strong", [
                                            !_vm.types.room.show
                                              ? _c("i", {
                                                  staticClass:
                                                    "f11 glyphicon glyphicon-plus"
                                                })
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _vm.types.room.show
                                              ? _c("i", {
                                                  staticClass:
                                                    "f11 glyphicon glyphicon-minus"
                                                })
                                              : _vm._e(),
                                            _vm._v(
                                              "\n                      Rooms\n                    "
                                            )
                                          ])
                                        ]
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("td", [
                                      _c(
                                        "span",
                                        {
                                          attrs: {
                                            "data-toggle": "tooltip",
                                            "data-placement": "top",
                                            title:
                                              "Maximum available units per type"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                        " +
                                              _vm._s(_vm.types.room.total) +
                                              "\n                    "
                                          )
                                        ]
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("td", [
                                      _vm._v(
                                        "%" +
                                          _vm._s(
                                            Number(
                                              _vm.types.room.average
                                            ).toFixed(2)
                                          )
                                      )
                                    ])
                                  ]),
                                  _vm._v(" "),
                                  _vm._l(_vm.types.room.data, function(
                                    type,
                                    i
                                  ) {
                                    return _vm.types.room.show
                                      ? _c("tr", [
                                          _c(
                                            "td",
                                            { staticClass: "cell-indentation" },
                                            [_vm._v(_vm._s(type.name))]
                                          ),
                                          _vm._v(" "),
                                          _c("td", [
                                            _vm._v(_vm._s(type.number_of_units))
                                          ]),
                                          _vm._v(" "),
                                          _c("td", [
                                            _vm._v(
                                              "%" +
                                                _vm._s(
                                                  Number(type.avg).toFixed(2)
                                                )
                                            )
                                          ])
                                        ])
                                      : _vm._e()
                                  }),
                                  _vm._v(" "),
                                  _c("tr", [
                                    _c("td", [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "btn btn-link",
                                          on: {
                                            click: function($event) {
                                              $event.preventDefault()
                                              _vm.types.parking.show = !_vm
                                                .types.parking.show
                                            }
                                          }
                                        },
                                        [
                                          _c("strong", [
                                            !_vm.types.parking.show
                                              ? _c("i", {
                                                  staticClass:
                                                    "f11 glyphicon glyphicon-plus"
                                                })
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _vm.types.parking.show
                                              ? _c("i", {
                                                  staticClass:
                                                    "f11 glyphicon glyphicon-minus"
                                                })
                                              : _vm._e(),
                                            _vm._v(
                                              "\n                      Parking\n                    "
                                            )
                                          ])
                                        ]
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("td", [
                                      _c(
                                        "span",
                                        {
                                          attrs: {
                                            "data-toggle": "tooltip",
                                            "data-placement": "top",
                                            title:
                                              "Maximum available units per type"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(_vm.types.parking.total) +
                                              "\n                    "
                                          )
                                        ]
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("td", [
                                      _vm._v(
                                        "%" +
                                          _vm._s(
                                            Number(
                                              _vm.types.parking.average
                                            ).toFixed(2)
                                          )
                                      )
                                    ])
                                  ]),
                                  _vm._v(" "),
                                  _vm._l(_vm.types.parking.data, function(
                                    type,
                                    i
                                  ) {
                                    return _vm.types.parking.show
                                      ? _c("tr", [
                                          _c(
                                            "td",
                                            { staticClass: "cell-indentation" },
                                            [_vm._v(_vm._s(type.name))]
                                          ),
                                          _vm._v(" "),
                                          _c("td", [
                                            _vm._v(_vm._s(type.number_of_units))
                                          ]),
                                          _vm._v(" "),
                                          _c("td", [
                                            _vm._v(
                                              "%" +
                                                _vm._s(
                                                  Number(type.avg).toFixed(2)
                                                )
                                            )
                                          ])
                                        ])
                                      : _vm._e()
                                  })
                                ],
                                2
                              )
                            : _c("tbody", [
                                _c("tr", [
                                  _c(
                                    "td",
                                    {
                                      staticClass: "text-danger text-center",
                                      attrs: { colspan: "3" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                    No data available.Please add room and parking types.\n                  "
                                      )
                                    ]
                                  )
                                ])
                              ])
                        ])
                      ])
                    ],
                    1
                  )
                ])
              ]),
              _vm._v(" "),
              _c("hr"),
              _vm._v(" "),
              _vm.chartLoading
                ? _c("div", { staticClass: "text-center" }, [
                    _c("i", {
                      staticClass: "fa fa-spinner fa-spin fa-3x fa-fw"
                    })
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-sm-3" }, [
                  _c("label", [_vm._v("Choose Units:")]),
                  _vm._v(" "),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.type,
                          expression: "form.type"
                        }
                      ],
                      staticClass: "form-control",
                      on: {
                        change: [
                          function($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function(o) {
                                return o.selected
                              })
                              .map(function(o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.form,
                              "type",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                          _vm.setChartData
                        ]
                      }
                    },
                    [
                      _c(
                        "option",
                        { attrs: { disabled: "" }, domProps: { value: null } },
                        [_vm._v("-- Choose --")]
                      ),
                      _vm._v(" "),
                      _c(
                        "optgroup",
                        { attrs: { label: "_________________" } },
                        [
                          _c("option", { attrs: { value: "parking" } }, [
                            _vm._v(
                              "\n                 Parking \n              "
                            )
                          ]),
                          _vm._v(" "),
                          _vm._l(_vm.types.parking.data, function(parkingType) {
                            return _c(
                              "option",
                              { domProps: { value: parkingType.id } },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(parkingType.name) +
                                    "\n              "
                                )
                              ]
                            )
                          })
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "optgroup",
                        { attrs: { label: "_________________" } },
                        [
                          _c("option", { attrs: { value: "room" } }, [
                            _vm._v(
                              "\n                   Rooms \n                "
                            )
                          ]),
                          _vm._v(" "),
                          _vm._l(_vm.types.room.data, function(roomType) {
                            return _c(
                              "option",
                              { domProps: { value: roomType.id } },
                              [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(roomType.name) +
                                    "\n                "
                                )
                              ]
                            )
                          })
                        ],
                        2
                      )
                    ]
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-9 margin-bottom-8x" }, [
                  _c(
                    "div",
                    { staticClass: "chart-box chart-box--shadow" },
                    [
                      _c("div", { staticClass: "chart-box chart-box--title" }, [
                        _c("h3", [_vm._v("Capacity Utilization")])
                      ]),
                      _vm._v(" "),
                      _vm.showChart
                        ? _c("GChart", {
                            attrs: {
                              settings: { packages: ["line", "corechart"] },
                              createChart: function(el, google) {
                                return new google.charts.Line(el)
                              },
                              data: _vm.chartData,
                              options: _vm.chartOptions
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ])
              ])
            ])
          ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-439bcec2", { render: render, staticRenderFns: staticRenderFns })
  }
}