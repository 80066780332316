
import env from 'constants/env'

var CaptchaInitiated  = false;

class Captcha {

    init () {
        if (CaptchaInitiated) {
            return;
        }
        CaptchaInitiated = true;

        this.__auth2 = null;
        if (!isServer) {            
            let element = (function(d, s, id) {
                var js, fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) {return;}
                js = d.createElement(s); js.id = id;
                js.src = "https://www.google.com/recaptcha/enterprise.js?render=" + env.default.googleRecaptcha;
                fjs.parentNode.insertBefore(js, fjs);
                js.onload = () => {
                    window.dispatchEvent(new Event('google_recaptcha_loaded'));
                }
                return js
            }(document, 'script', 'google-recaptcah'));
        }
    }

    execute (action) {
        return grecaptcha.enterprise.execute(env.default.googleRecaptcha, {action});
    }

    ready(cb) {
        return grecaptcha.enterprise.ready(cb);
    }
}

export default new Captcha