import _ from 'lodash'
import toastr from 'toastr'
import { baseModal } from 'components/modals'
import sSelect from 'components/sSelect'
import { hasPermits } from 'directives'

const directives = {
  hasPermits
}

const components = {
  sSelect,
  baseModal
}

const methods = {
  submit (modal) {
    let errors = false;
    this.errors = {};

    //validating totals
    let added_parkings = 0;
    for (let i in this.parkingTypes) {
      added_parkings += parseInt(this.parkingTypes[i].number_of_parkings);
    }

    console.log("added_parkings", added_parkings);
    console.log("added_parkings + this.form.number_of_parkings", added_parkings + parseInt(this.form.number_of_parkings));
    if (added_parkings + parseInt(this.form.number_of_parkings) > this.totalParkingsNumber) {
      this.errors.number_of_parkings = ["Number of total parkings types must be less than or equal to " + this.totalParkingsNumber +", " + parseInt(this.totalParkingsNumber - added_parkings) + ' left.'];

      errors = true;
    }

    if (errors) return;

    let data = _.cloneDeep(this.form)
    data.location_id = this.$route.params.id;
    data.type_id = data.type.value;
    data = _.omit(data, 'type');

    this.$store.dispatch('locations.newLocationParkingType', data).then(() => {
      if (this.$store.state.locations.errors) {
        this.errors = _.cloneDeep(this.$store.state.locations.errors.errors);
        toastr.error(this.$store.state.locations.errors.message);
      } else {
        this.form = {
          type: this.abstract[0],
          name: null,
          number_of_parkings: null,
          price_limit: null
        }

        modal.close();
      }
    });
  }
}

const computed = {
  abstract () {
    let options = _.map(this.$store.state.types.parkings, (item) => {
      return {value: item.id, text: item.name}
    });

    if (options.length) {
      this.form.type = options[0]
    }

    return options
  },
  parkingTypes () {
    return _.cloneDeep(this.$store.state.locations.active.parkingTypes);
  },
  totalParkingsNumber () {
    return this.$store.state.locations.active.number_of_parkings;
  }
}

export default {
  data () {
    return {
      form: {
        type: {},
        name: null,
        number_of_parkings: null,
        price_limit: null
      },
      errors: {}
    }
  },
  methods,
  components,
  computed,
  directives,
}
