import { dateFormat } from 'filters'
import { confirm, reservationDetails as viewReservation } from 'components/modals'
import { hasPermits } from 'directives'
import toastr from 'toastr'

const directives = {
  hasPermits
}

const filters = {
  dateFormat
}

const components = {
  confirm, viewReservation
}

const methods = {
  showReservationDetails (reservation_id, item) {
    this.$set(this.loadings, item.id, true);
    console.log('this.loadings', this.loadings[item.id]);
    this.active_checkin_item = item;
    console.log(item);
    this.$store.dispatch('dashboards.getReservation', {
      'item': item
    });
    this.$store.dispatch('locations.getReservation', {
      lId: this.location.id,
      id: reservation_id
    }).then(() => {
      this.loadings[item.id] = false;
      let errors = _.cloneDeep(this.$store.state.locations.errors);
      if (errors ) {
        toastr.error(errors.message);
      } else {
        this.reservation = this.$store.state.locations.active_reservation;
        this.$refs.view.open('check-in', item);
      }
    });

    return false;
  },
  getList () {
    this.loading = true;
    let type = this.type;
    let date = this.date;
    if (this.type == 'all') {
      type = null;
    }
    this.$store.dispatch('dashboards.getCheckinList', {
      location_id: this.location.id,
      params: {
        checked_in: this.checked_in,
        type,
        date,
        _q: this.search_key
      }
    }).then(() => {
      this.loading = false;
      this.all_list = _.cloneDeep(this.list);
      this.total_checkins_count = this.list.length;
      if (this.type == 'all') {
        this.room_checkins_count = _.filter(this.list, (item) => {
          return item.type_type == 'room';
        }).length;
      }
    });
  },

  toggleCheck (item) {
    if (item.check_in) {
      this.$refs.confirm.confirm('Are you sure?', () => {
        this.checkReservation(item.id, this.location.id);
      });
    } else {
      this.checkReservation(item.id, this.location.id);
    }
  },

  checkReservation (id, location_id) {
    this.$store.dispatch('dashboards.checkInReservation', {
      id, location_id
    }).then(() => {
      let errors = _.cloneDeep(this.$store.state.dashboards.errors);
      if (errors) {
        if (errors.message.includes('This reservation is already cancelled')) {
          toastr.error(errors.message);
          setTimeout(() => {
            this.$router.go();
          }, "2000")
        }
      }
    });
  },

  toggleShow (item) {
    this.$store.dispatch('dashboards.showReservation', {
      'id': item.id, 'from': 'location'
    });
  },

  search (value) {
    if (value.length) {
      this.search_key = value;
    } else {
      this.search_key = null;
    }
    this.getList();
  },
  onSearchKeyup(value, e) {
    console.log('xxxx', e.keyCode);
    if (!value.length || e.keyCode == 13) {
      this.search(value);
    }
  }
}

const computed = {
  list () {
    return _.cloneDeep(this.$store.state.dashboards.checkin_list) || []
  },
}

const watch = {
  location (n) {
    this.getList();
  },
  date () {
    this.getList();
  }
}

export default {
  name: 'checkin-list',
  props: ['location', 'date'],
  methods,
  watch,
  computed,
  filters,
  components,
  directives,
  data () {
    return {
      loading: false,
      loadings: {},
      type: 'all',
      checked_in: 'both',
      search_key: null,
      reservation: null,
      room_checkins_count: 0,
      total_checkins_count: 0,
      all_list: null,
    }
  },
  created () {
    if (this.location)
      this.getList();
  }
}
