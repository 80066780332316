import datatable from 'components/datatable'
import moment from 'moment'

const components = {
  datatable,
}

const methods = {
  displayErrors (errors) {
    this.$emit('DBReportsErrors',errors);
  }
}

export default {
  name: 'reports-BDReport',
  props: ['url'],
  components,
  methods,
  data () {
    return {
      columns: [
        {name: "Location", object_name: "location_name", sortable: true, sort_key: 'location_name'},
        {name: "BD Person", object_name: "bd_person", sortable: true, sort_key: 'bd_person'},
        {name: "Currency", object_name: "currency_code", sortable: true, sort_key: "currency_code"},
        {name: "Rate Type", object_name: "rate_type", sortable: true, sort_key: "rate_type"},
        {name: "Rate Arrangement", object_name: "rate_arrangement", sortable: true, sort_key: "rate_arrangement"},
        {name: "Owed Amount", object_name: "owed_amount", sortable: true, sort_key: "owed_amount"},
        {name: "Subtotal", object_name: "subtotal", sortable: true, sort_key: "subtotal"},
        {name: "Lease", object_name: "lease", sortable: true, sort_key: "subtotal"},
        {name: "RL Fees", object_name: "ims_fees"},
        {name: "RL amount", object_name: "reslab_amount"},
        {name: "Date Started", object_name: (obj, parent) => {
          return obj.bd_start_date ? moment(obj.bd_start_date).format('MM/DD/YYYY') : '';
        }, sortable: true, sort_key: "bd_start_date"},
        {name: "Months since start date", object_name: "months", sortable: true, sort_key: "subtotal"},
      ]
    }
  },
}
