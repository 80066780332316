import pagination from 'components/pagination'
import { hasPermits } from 'directives'
import newLocation from "./newLocation"

const directives = {
  hasPermits
}
const components = {
  pagination,
  newLocation,
}

const computed = {
  locations () {
    return _.cloneDeep(this.$store.state.channels.active.locations);
  }
}

const methods = {
  loadPage (page) {
    this.$route.query.page = page;
    this.loading = true;
    this.$store.dispatch('channels.getLocations', {
      id: this.channelId,
      params: {page, location_name: this.searchKey ? this.searchKey : null}
    }).then(() => {
      this.loading = false;
    });
  }
}

const watch = {
  activeTab (n) {
    if (n.title == "Locations") {
      this.loadPage(1);
    }
  }
}

export default {
  name: 'locations-tab',
  props: ['channelId','activeTab'],
  components,
  computed,
  directives,
  methods,
  watch,
  data () {
    return {
      searchKey: null,
      loading: false
    }
  },
  created () {
    this.loadPage(1);
  }
}
