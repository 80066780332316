import { http } from 'services'
// initial state
const state = () => ({
  all: [],
  errors: null
})

// getters
const getters = {
}

// actions
const actions = {
  ['currencies.getAll'] ({ commit, state }) {
    commit('CLEAR_ERRORS')

    if (state.all.length) {
      return commit('GET_ALL_CURRENCIES');
    }

    return (http.get('currencies?_all=true')).then ((res) => {
      commit('GET_ALL_CURRENCIES', res);
    })
    .catch ((res) => {
      commit('CURRENCIES_ERROR', res);
    })
  },

}

// mutations
const mutations = {

  GET_ALL_CURRENCIES (state, res) {
    if(res)
      state.all = res.data
  },

  //ERRORS
  CURRENCIES_ERROR (state, err) {
    try {
      state.errors = err.response.data;
    } catch (e) {
      state.errors = err;
    }
  },

  CLEAR_ERRORS (state) {
    state.errors = null
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
