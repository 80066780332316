var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c("form", { staticClass: "form-reset-password" }, [
      _c("h2", { staticClass: "form-reset-password-heading" }, [
        _vm._v("Reset Your Password")
      ]),
      _vm._v(" "),
      _c("div", {}, [
        _c("label", { staticClass: "sr-only" }, [_vm._v("New Password")]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.form.password,
              expression: "form.password"
            }
          ],
          staticClass: "form-control",
          attrs: { type: "password", placeholder: "Password", required: "" },
          domProps: { value: _vm.form.password },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.form, "password", $event.target.value)
            }
          }
        })
      ]),
      _vm._v(" "),
      _c("div", {}, [
        _c("label", { staticClass: "sr-only" }, [_vm._v("Confirm Password")]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.form.password_confirmation,
              expression: "form.password_confirmation"
            }
          ],
          staticClass: "form-control",
          attrs: {
            type: "password",
            placeholder: "Confirm Password",
            required: ""
          },
          domProps: { value: _vm.form.password_confirmation },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.form, "password_confirmation", $event.target.value)
            }
          }
        })
      ]),
      _vm._v(" "),
      _vm.errors.token
        ? _c("div", { staticClass: "text-danger" }, [
            _vm._v("\n      " + _vm._s(_vm.errors.token[0]) + "\n    ")
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.errors.password
        ? _c("div", { staticClass: "text-danger" }, [
            _vm._v("\n      " + _vm._s(_vm.errors.password[0]) + "\n    ")
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.errors.password_confirmation
        ? _c("div", { staticClass: "text-danger" }, [
            _vm._v(
              "\n      " +
                _vm._s(_vm.errors.password_confirmation[0]) +
                "\n    "
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn btn-lg btn-primary btn-block",
          class: { disabled: _vm.processing },
          attrs: { disabled: _vm.processing },
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.submit()
            }
          }
        },
        [
          _vm.processing
            ? _c("span", [
                _c("i", { staticClass: "fa fa-spinner fa-spin fa-fw" }),
                _vm._v("\n        Processing...\n      ")
              ])
            : _c("span", [_vm._v("\n        Submit\n      ")])
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-b5f33b8e", { render: render, staticRenderFns: staticRenderFns })
  }
}