import toastr from 'toastr'
import layout from 'layouts/default'
import pagination from 'components/pagination'
import confirm from 'components/modals/confirm'
import { hasPermits } from 'directives'

const components = {
  layout,
  pagination,
  confirm
}

const directives = {
  hasPermits
}

const methods = {
  loadPage (page) {
    this.$route.query.page = page;
    let data = {page, _q: this.searchKey};

    this.loading = true;

    if (this.filter == 'deleted') {
      data._only_deleted = 'true';
    } else if (this.filter == 'all') {
      data._with_deleted = 'true';
    }

    if (this.poi_type_filter != 'all') {
      data.poi_type = this.poi_type_filter;
    }

    return (this.$store.dispatch('locations.getAll', data)).then (() => {
      this.loading = false;
    });
  },
  search (searchKey) {
    this.searchKey = searchKey;
    this.loadPage(1);
  },

  removeLocation (item) {
    this.$refs.confirmDelete.confirm(
    'are you sure that you want to delete this location?',
    () => {
      this.loadings[item.id] = true;
      this.loadings = _.cloneDeep(this.loadings);

      this.$store.dispatch('locations.remove', item.id).then(() => {
        this.loadings[item.id] = false;
        console.log('this.$store.state.locations.errors', this.$store.state.locations.errors);
        let errors;
        if (errors = this.$store.state.locations.errors) {
          if (errors.message) {
            toastr.error(errors.message);
          } else {
            toastr.error(errors.error);
          }
        }
      });
    });
  },

  undoRemove (item) {
    this.$refs.confirmUndoDelete.confirm(
    'Are you sure that you want to undelete this location?',
    () => {
      this.loadings[item.id] = true;
      this.loadings = _.cloneDeep(this.loadings);

      this.$store.dispatch('locations.undoRemove', item.id).then(() => {
        this.loadings[item.id] = false;
        console.log('this.$store.state.locations.errors', this.$store.state.locations.errors);
        let errors;
        if (errors = this.$store.state.locations.errors) {
          if (errors.message) {
            toastr.error(errors.message);
          } else {
            toastr.error(errors.error);
          }
        }
      });
    });
  },

  filterChanged () {
    console.log(this.filter);
    this.loadPage(1);
  }
}

const computed = {
  locations () {
    return _.cloneDeep(this.$store.state.locations.all_locations);
  }
}

export default {
  name: 'Main',
  components,
  methods,
  computed,
  directives,
  data () {
    return {
      loading: true,
      searchKey: null,
      loadings: {},
      poi_type_filter: 'all',
      filter: 'active'
    }
  },
  created () {
    // this.$store.dispatch('channels.getAll');
    this.loadPage (this.$route.query.page || 1).then(() => {
      if (this.$store.state.locations.all.total == 1) {
        try {
          this.$router.replace({
            name: 'locations.guestManagement',
            params: {
              id: this.$store.state.locations.all.data[0].id
            }
          });
        } catch (e) {
          console.log('no locations found');
        }
      }
    });
  },
  mounted () {

  }
}
