var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row margin-top-4x" }, [
    _vm.locationsLoading || _vm.feesLoading || _vm.dynamicFeesLoading
      ? _c("div", { staticClass: "text-center" }, [
          _c("i", { staticClass: "fa fa-spinner fa-spin fa-3x fa-fw" })
        ])
      : _c(
          "div",
          [
            _c(
              "div",
              { staticClass: "col-sm-8 col-sm-offset-2 margin-top-3x" },
              [
                _c("div", {}, [
                  _c("div", { staticClass: "text-right" }, [
                    _c(
                      "button",
                      {
                        directives: [
                          {
                            name: "hasPermits",
                            rawName: "v-hasPermits:allow",
                            value: ["super_admin", "channelFee_create"],
                            expression: "['super_admin', 'channelFee_create']",
                            arg: "allow"
                          }
                        ],
                        staticClass: "btn btn-default",
                        class: { disabled: _vm.copyingFees },
                        attrs: { disabled: _vm.copyingFees, type: "button" },
                        on: {
                          click: function($event) {
                            return _vm.openCopyFeeModal()
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n            Copy Fees from another channel          \n          "
                        )
                      ]
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _c("new-fee", {
                  directives: [
                    {
                      name: "hasPermits",
                      rawName: "v-hasPermits:allow",
                      value: ["channelFee_create"],
                      expression: "['channelFee_create']",
                      arg: "allow"
                    }
                  ],
                  attrs: { channelLocations: _vm.locations }
                }),
                _vm._v(" "),
                _vm.fees.length
                  ? _c("div", [
                      _c("div", { staticClass: "table-responsive" }, [
                        _c("table", { staticClass: "table table-striped" }, [
                          _c("thead", [
                            _c("tr", [
                              _c("th", { attrs: { width: "15%" } }, [
                                _vm._v(" Name ")
                              ]),
                              _vm._v(" "),
                              _c("th", [_vm._v(" For ")]),
                              _vm._v(" "),
                              _c("th", [_vm._v(" Amount ")]),
                              _vm._v(" "),
                              _c("th", [_vm._v(" Desc. ")]),
                              _vm._v(" "),
                              _c("th", [_vm._v(" Type ")]),
                              _vm._v(" "),
                              _c("th", [_vm._v(" calculation ")]),
                              _vm._v(" "),
                              _c("th", [_vm._v(" Locations ")]),
                              _vm._v(" "),
                              _c("th", {
                                directives: [
                                  {
                                    name: "hasPermits",
                                    rawName: "v-hasPermits:allow",
                                    value: ["channelFee_delete"],
                                    expression: "['channelFee_delete']",
                                    arg: "allow"
                                  }
                                ]
                              })
                            ])
                          ]),
                          _vm._v(" "),
                          _c(
                            "tbody",
                            _vm._l(_vm.fees, function(item) {
                              return _c("tr", { key: item.id }, [
                                _c(
                                  "td",
                                  [
                                    _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "hasPermits",
                                            rawName: "v-hasPermits:prevent",
                                            value: [
                                              "channelFee_update",
                                              "super_admin"
                                            ],
                                            expression:
                                              "['channelFee_update', 'super_admin']",
                                            arg: "prevent"
                                          }
                                        ]
                                      },
                                      [_vm._v(_vm._s(item.name))]
                                    ),
                                    _vm._v(" "),
                                    _c("editable-text", {
                                      directives: [
                                        {
                                          name: "hasPermits",
                                          rawName: "v-hasPermits:allow",
                                          value: ["channelFee_update"],
                                          expression: "['channelFee_update']",
                                          arg: "allow"
                                        }
                                      ],
                                      attrs: { value: item.name },
                                      on: {
                                        valueSaved: function(data) {
                                          return _vm.onEditFee(
                                            data,
                                            item,
                                            "name"
                                          )
                                        }
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  [
                                    _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "hasPermits",
                                            rawName: "v-hasPermits:prevent",
                                            value: [
                                              "channelFee_update",
                                              "super_admin"
                                            ],
                                            expression:
                                              "['channelFee_update', 'super_admin']",
                                            arg: "prevent"
                                          }
                                        ]
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            item.associated_to == "room"
                                              ? "Rooms"
                                              : "Parking"
                                          )
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("editable-radio", {
                                      directives: [
                                        {
                                          name: "hasPermits",
                                          rawName: "v-hasPermits:allow",
                                          value: ["channelFee_update"],
                                          expression: "['channelFee_update']",
                                          arg: "allow"
                                        }
                                      ],
                                      attrs: {
                                        value: item.associated_to,
                                        options: {
                                          room: "Rooms",
                                          parking: "Parking"
                                        }
                                      },
                                      on: {
                                        valueSaved: function(data) {
                                          return _vm.onEditFee(
                                            data,
                                            item,
                                            "associated_to"
                                          )
                                        }
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  [
                                    _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "hasPermits",
                                            rawName: "v-hasPermits:prevent",
                                            value: [
                                              "channelFee_update",
                                              "super_admin"
                                            ],
                                            expression:
                                              "['channelFee_update', 'super_admin']",
                                            arg: "prevent"
                                          }
                                        ]
                                      },
                                      [_vm._v(_vm._s(item.amount))]
                                    ),
                                    _vm._v(" "),
                                    _c("editable-text", {
                                      directives: [
                                        {
                                          name: "hasPermits",
                                          rawName: "v-hasPermits:allow",
                                          value: ["channelFee_update"],
                                          expression: "['channelFee_update']",
                                          arg: "allow"
                                        }
                                      ],
                                      attrs: { value: item.amount },
                                      on: {
                                        valueSaved: function(data) {
                                          return _vm.onEditFee(
                                            data,
                                            item,
                                            "amount"
                                          )
                                        }
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  [
                                    _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "hasPermits",
                                            rawName: "v-hasPermits:prevent",
                                            value: [
                                              "channelFee_update",
                                              "super_admin"
                                            ],
                                            expression:
                                              "['channelFee_update', 'super_admin']",
                                            arg: "prevent"
                                          }
                                        ]
                                      },
                                      [_vm._v(_vm._s(item.description))]
                                    ),
                                    _vm._v(" "),
                                    _c("editable-text", {
                                      directives: [
                                        {
                                          name: "hasPermits",
                                          rawName: "v-hasPermits:allow",
                                          value: ["channelFee_update"],
                                          expression: "['channelFee_update']",
                                          arg: "allow"
                                        }
                                      ],
                                      attrs: { value: item.description },
                                      on: {
                                        valueSaved: function(data) {
                                          return _vm.onEditFee(
                                            data,
                                            item,
                                            "description"
                                          )
                                        }
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                item.calculation == "daily"
                                  ? _c(
                                      "td",
                                      [
                                        _c(
                                          "span",
                                          {
                                            directives: [
                                              {
                                                name: "hasPermits",
                                                rawName: "v-hasPermits:prevent",
                                                value: [
                                                  "channelFee_update",
                                                  "super_admin"
                                                ],
                                                expression:
                                                  "['channelFee_update', 'super_admin']",
                                                arg: "prevent"
                                              }
                                            ]
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                item.type == "fixed" ? "$" : "%"
                                              )
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("editable-radio", {
                                          directives: [
                                            {
                                              name: "hasPermits",
                                              rawName: "v-hasPermits:allow",
                                              value: ["channelFee_update"],
                                              expression:
                                                "['channelFee_update']",
                                              arg: "allow"
                                            }
                                          ],
                                          attrs: {
                                            value: item.type,
                                            options: {
                                              fixed: "$",
                                              percentage: "%"
                                            }
                                          },
                                          on: {
                                            valueSaved: function(data) {
                                              return _vm.onEditFee(
                                                data,
                                                item,
                                                "type"
                                              )
                                            }
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  : _c("td", [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            item.type == "fixed" ? "$" : "%"
                                          ) +
                                          "\n                "
                                      )
                                    ]),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  [
                                    _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "hasPermits",
                                            rawName: "v-hasPermits:prevent",
                                            value: [
                                              "channelFee_update",
                                              "super_admin"
                                            ],
                                            expression:
                                              "['channelFee_update', 'super_admin']",
                                            arg: "prevent"
                                          }
                                        ]
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            item.type == "daily"
                                              ? "Daily"
                                              : "One Time"
                                          )
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("editable-radio", {
                                      directives: [
                                        {
                                          name: "hasPermits",
                                          rawName: "v-hasPermits:allow",
                                          value: ["channelFee_update"],
                                          expression: "['channelFee_update']",
                                          arg: "allow"
                                        }
                                      ],
                                      attrs: {
                                        value: item.calculation,
                                        options: {
                                          daily: "Daily",
                                          one_time: "One Time"
                                        }
                                      },
                                      on: {
                                        valueSaved: function(data) {
                                          return _vm.onEditFee(
                                            data,
                                            item,
                                            "calculation"
                                          )
                                        }
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(
                                        item.all_locations
                                          ? "All Locations"
                                          : "Some Locations"
                                      ) +
                                      "\n                  "
                                  ),
                                  _c(
                                    "a",
                                    {
                                      directives: [
                                        {
                                          name: "hasPermits",
                                          rawName: "v-hasPermits:allow",
                                          value: ["channelFee_update"],
                                          expression: "['channelFee_update']",
                                          arg: "allow"
                                        }
                                      ],
                                      attrs: { href: "#" },
                                      on: {
                                        click: function($event) {
                                          $event.preventDefault()
                                          return _vm.openEditFeeModal(item)
                                        }
                                      }
                                    },
                                    [_c("i", { staticClass: "fa fa-pencil" })]
                                  )
                                ]),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  {
                                    directives: [
                                      {
                                        name: "hasPermits",
                                        rawName: "v-hasPermits:allow",
                                        value: ["channelFee_delete"],
                                        expression: "['channelFee_delete']",
                                        arg: "allow"
                                      }
                                    ]
                                  },
                                  [
                                    _vm.loadings[item.id]
                                      ? _c("div", [
                                          _c("i", {
                                            staticClass: "fa fa-spinner fa-spin"
                                          })
                                        ])
                                      : _c(
                                          "a",
                                          {
                                            staticClass: "text-danger",
                                            attrs: { href: "#" },
                                            on: {
                                              click: function($event) {
                                                $event.preventDefault()
                                                return _vm.removeFee(item.id)
                                              }
                                            }
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "fa fa-trash"
                                            })
                                          ]
                                        )
                                  ]
                                )
                              ])
                            }),
                            0
                          )
                        ])
                      ])
                    ])
                  : _c("div", { staticClass: "text-warning" }, [
                      _vm._v("\n        There are no fees yet\n      ")
                    ])
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-sm-8 col-sm-offset-2 margin-top-3x" },
              [
                _c("h4", [_vm._v("Dynamic Fees")]),
                _vm._v(" "),
                _c(
                  "label",
                  {
                    directives: [
                      {
                        name: "hasPermits",
                        rawName: "v-hasPermits:allow",
                        value: ["channelFee_update"],
                        expression: "['channelFee_update']",
                        arg: "allow"
                      }
                    ]
                  },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.use_dynamic_fees,
                          expression: "form.use_dynamic_fees"
                        }
                      ],
                      attrs: { type: "checkbox" },
                      domProps: {
                        checked: Array.isArray(_vm.form.use_dynamic_fees)
                          ? _vm._i(_vm.form.use_dynamic_fees, null) > -1
                          : _vm.form.use_dynamic_fees
                      },
                      on: {
                        change: function($event) {
                          var $$a = _vm.form.use_dynamic_fees,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.form,
                                  "use_dynamic_fees",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.form,
                                  "use_dynamic_fees",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.form, "use_dynamic_fees", $$c)
                          }
                        }
                      }
                    }),
                    _vm._v("\n          Using Dynamic Fees\n      ")
                  ]
                ),
                _vm._v(" "),
                _c("new-dynamic-fee", {
                  directives: [
                    {
                      name: "hasPermits",
                      rawName: "v-hasPermits:allow",
                      value: ["channelFee_create"],
                      expression: "['channelFee_create']",
                      arg: "allow"
                    }
                  ],
                  attrs: { channelLocations: _vm.locations }
                }),
                _vm._v(" "),
                _vm.dynamicFees.length
                  ? _c("div", [
                      _c("div", { staticClass: "table-responsive" }, [
                        _c("table", { staticClass: "table table-striped" }, [
                          _c("thead", [
                            _c("tr", [
                              _c("th", { attrs: { width: "15%" } }, [
                                _vm._v(" Name ")
                              ]),
                              _vm._v(" "),
                              _c("th", [_vm._v(" USD flat ")]),
                              _vm._v(" "),
                              _c("th", [_vm._v(" CAD flat ")]),
                              _vm._v(" "),
                              _c("th", [_vm._v(" Percent values ")]),
                              _vm._v(" "),
                              _c("th", [_vm._v(" Locations ")]),
                              _vm._v(" "),
                              _c("th", {
                                directives: [
                                  {
                                    name: "hasPermits",
                                    rawName: "v-hasPermits:allow",
                                    value: ["channelFee_update"],
                                    expression: "['channelFee_update']",
                                    arg: "allow"
                                  }
                                ]
                              }),
                              _vm._v(" "),
                              _c("th", {
                                directives: [
                                  {
                                    name: "hasPermits",
                                    rawName: "v-hasPermits:allow",
                                    value: ["channelFee_delete"],
                                    expression: "['channelFee_delete']",
                                    arg: "allow"
                                  }
                                ]
                              })
                            ])
                          ]),
                          _vm._v(" "),
                          _c(
                            "tbody",
                            [
                              _vm._l(_vm.dynamicFees, function(item, i) {
                                return [
                                  _c("tr", [
                                    _c("td", [
                                      _c("span", [_vm._v(_vm._s(item.name))])
                                    ]),
                                    _vm._v(" "),
                                    _c("td", [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(item.dynamic_fees.usd_flat)
                                        )
                                      ])
                                    ]),
                                    _vm._v(" "),
                                    _c("td", [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(item.dynamic_fees.cad_flat)
                                        )
                                      ])
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      _vm._l(
                                        item.dynamic_fees.percent_values,
                                        function(percent_value) {
                                          return _c("span", [
                                            _vm._v(
                                              "\n                      from " +
                                                _vm._s(percent_value.from) +
                                                " to " +
                                                _vm._s(percent_value.to) +
                                                " = " +
                                                _vm._s(percent_value.value) +
                                                "\n                      "
                                            ),
                                            _c("br")
                                          ])
                                        }
                                      ),
                                      0
                                    ),
                                    _vm._v(" "),
                                    _c("td", [
                                      item.all_locations == 1
                                        ? _c("span", [_vm._v("All Locations")])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      item.all_locations == 2
                                        ? _c("span", [
                                            _vm._v("All Locations Except")
                                          ])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      item.all_locations == 0
                                        ? _c("span", [_vm._v("Some Locations")])
                                        : _vm._e()
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      {
                                        directives: [
                                          {
                                            name: "hasPermits",
                                            rawName: "v-hasPermits:allow",
                                            value: ["channelFee_update"],
                                            expression: "['channelFee_update']",
                                            arg: "allow"
                                          }
                                        ]
                                      },
                                      [
                                        !_vm.dynamicFeesEditStatus[item.id]
                                          ? _c(
                                              "a",
                                              {
                                                attrs: { href: "#" },
                                                on: {
                                                  click: function($event) {
                                                    $event.preventDefault()
                                                    return _vm.editDynamicFeeStatus(
                                                      item.id
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c("i", {
                                                  staticClass: "fa fa-pencil"
                                                })
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.dynamicFeesEditStatus[item.id]
                                          ? _c(
                                              "a",
                                              {
                                                attrs: { href: "#" },
                                                on: {
                                                  click: function($event) {
                                                    $event.preventDefault()
                                                    return _vm.editDynamicFeeStatus(
                                                      item.id,
                                                      false
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c(
                                                  "i",
                                                  {
                                                    staticClass: "fa fa-times"
                                                  },
                                                  [_vm._v(" cancel")]
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      {
                                        directives: [
                                          {
                                            name: "hasPermits",
                                            rawName: "v-hasPermits:allow",
                                            value: ["channelFee_delete"],
                                            expression: "['channelFee_delete']",
                                            arg: "allow"
                                          }
                                        ]
                                      },
                                      [
                                        _vm.loadings[item.id]
                                          ? _c("div", [
                                              _c("i", {
                                                staticClass:
                                                  "fa fa-spinner fa-spin"
                                              })
                                            ])
                                          : _c(
                                              "a",
                                              {
                                                staticClass: "text-danger",
                                                attrs: { href: "#" },
                                                on: {
                                                  click: function($event) {
                                                    $event.preventDefault()
                                                    return _vm.removeFee(
                                                      item.id,
                                                      true
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c("i", {
                                                  staticClass: "fa fa-trash"
                                                })
                                              ]
                                            )
                                      ]
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _vm.dynamicFeesEditStatus[item.id]
                                    ? _c("tr", [
                                        _c(
                                          "td",
                                          { attrs: { colspan: "8" } },
                                          [
                                            _c("edit-dynamic-fee", {
                                              directives: [
                                                {
                                                  name: "hasPermits",
                                                  rawName: "v-hasPermits:allow",
                                                  value: ["channelFee_update"],
                                                  expression:
                                                    "['channelFee_update']",
                                                  arg: "allow"
                                                }
                                              ],
                                              attrs: {
                                                fee: item,
                                                channelLocations: _vm.locations
                                              },
                                              on: {
                                                onSave: _vm.editDynamicFeeStatus
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ])
                                    : _vm._e()
                                ]
                              })
                            ],
                            2
                          )
                        ])
                      ])
                    ])
                  : _vm._e()
              ],
              1
            ),
            _vm._v(" "),
            _c("confirm", { ref: "confirmDelete", attrs: { type: "danger" } }),
            _vm._v(" "),
            _c("edit-fee", {
              ref: "editFeeModal",
              attrs: { fee: _vm.selectedFee, channelLocations: _vm.locations }
            }),
            _vm._v(" "),
            _c("copy-fee", {
              ref: "copyFeeModal",
              attrs: { channelLocations: _vm.locations }
            })
          ],
          1
        )
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-3c719039", { render: render, staticRenderFns: staticRenderFns })
  }
}