var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "layout",
    { attrs: { "no-box": true, title: _vm.location ? _vm.location.name : "" } },
    [
      _c("div", { staticClass: "box" }, [
        _c("div", { staticClass: "box-body" }, [
          _vm.loading
            ? _c("div", { staticClass: "text-center" }, [
                _c("i", { staticClass: "fa fa-spinner fa-spin fa-3x fa-fw" })
              ])
            : _c(
                "div",
                { staticClass: "row" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-sm-12 text-right margin-top-4x margin-bottom-4x"
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-success",
                          class: { disabled: _vm.processing },
                          attrs: { disabled: _vm.processing },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.submit()
                            }
                          }
                        },
                        [
                          _vm.processing
                            ? _c("span", [
                                _c("i", {
                                  staticClass: "fa fa-spinner fa-spin fa-fw"
                                }),
                                _vm._v(
                                  "\n              SAVING...\n            "
                                )
                              ])
                            : _c("span", [_vm._v("SAVE")])
                        ]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-5" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { attrs: { for: "mainContact" } }, [
                        _vm._v("Main Contact(s)")
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _vm.form.main_contact,
                            expression: "form.main_contact",
                            modifiers: { trim: true }
                          }
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          id: "mainContact",
                          placeholder: "Main Contact(s)"
                        },
                        domProps: { value: _vm.form.main_contact },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.form,
                              "main_contact",
                              $event.target.value.trim()
                            )
                          },
                          blur: function($event) {
                            return _vm.$forceUpdate()
                          }
                        }
                      }),
                      _vm._v(" "),
                      _vm.errors.main_contact
                        ? _c("div", { staticClass: "text-danger" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.errors.main_contact[0]) +
                                "\n            "
                            )
                          ])
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", [_vm._v("Payment Type")]),
                      _vm._v(" "),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.payment_type,
                              expression: "form.payment_type"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: { placeholder: "Payment Type" },
                          on: {
                            change: function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.form,
                                "payment_type",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            }
                          }
                        },
                        [
                          _c("option", { attrs: { value: "ach" } }, [
                            _vm._v("ACH")
                          ]),
                          _vm._v(" "),
                          _c("option", { attrs: { value: "check" } }, [
                            _vm._v("CHECK")
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _vm.errors.payment_type
                        ? _c("div", { staticClass: "text-danger" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.errors.payment_type[0]) +
                                "\n            "
                            )
                          ])
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { attrs: { for: "accountInformation" } }, [
                        _vm._v("Account Information")
                      ]),
                      _vm._v(" "),
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _vm.form.account_information,
                            expression: "form.account_information",
                            modifiers: { trim: true }
                          }
                        ],
                        staticClass: "form-control",
                        attrs: {
                          id: "accountInformation",
                          placeholder: "Account Information"
                        },
                        domProps: { value: _vm.form.account_information },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.form,
                              "account_information",
                              $event.target.value.trim()
                            )
                          },
                          blur: function($event) {
                            return _vm.$forceUpdate()
                          }
                        }
                      }),
                      _vm._v(" "),
                      _vm.errors.account_information
                        ? _c("div", { staticClass: "text-danger" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.errors.account_information[0]) +
                                "\n            "
                            )
                          ])
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "hasPermits",
                            rawName: "v-hasPermits:allow",
                            value: ["super_admin"],
                            expression: "['super_admin']",
                            arg: "allow"
                          }
                        ],
                        staticClass: "margin-top-8x"
                      },
                      [
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "form-group col-sm-4" }, [
                            _c("label", { attrs: { for: "vendor" } }, [
                              _vm._v("Vendor")
                            ])
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "form-group col-sm-7" },
                            [
                              _c("s-select", {
                                attrs: {
                                  selected: _vm.vendor,
                                  options: _vm.vendors,
                                  multiple: false
                                },
                                on: { selectedChanged: _vm.setVendor }
                              }),
                              _vm._v(" "),
                              _vm.errors.vendors
                                ? _c("div", { staticClass: "text-danger" }, [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(_vm.errors.vendors[0]) +
                                        "\n                "
                                    )
                                  ])
                                : _vm._e()
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "form-group col-sm-1 margin-top-1x"
                            },
                            [
                              _c(
                                "a",
                                {
                                  staticClass: "text-danger",
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.removeVendor()
                                    }
                                  }
                                },
                                [
                                  _c("i", {
                                    staticClass: "fa fa-trash",
                                    attrs: { "aria-hidden": "true" }
                                  })
                                ]
                              )
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "form-group col-sm-4" }, [
                            _c("label", { attrs: { for: "poi" } }, [
                              _vm._v("Point of Interest")
                            ])
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "form-group col-sm-7" },
                            [
                              _c("s-select", {
                                attrs: {
                                  selected: _vm.poi,
                                  options: _vm.points_of_interest,
                                  multiple: false
                                },
                                on: { selectedChanged: _vm.setPointOfInterest }
                              }),
                              _vm._v(" "),
                              _vm.errors.point_of_interest
                                ? _c("div", { staticClass: "text-danger" }, [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          _vm.errors.point_of_interest[0]
                                        ) +
                                        "\n                "
                                    )
                                  ])
                                : _vm._e()
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "form-group col-sm-1 margin-top-1x"
                            },
                            [
                              _c(
                                "a",
                                {
                                  staticClass: "text-danger",
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.removePoi()
                                    }
                                  }
                                },
                                [
                                  _c("i", {
                                    staticClass: "fa fa-trash",
                                    attrs: { "aria-hidden": "true" }
                                  })
                                ]
                              )
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "form-group col-sm-4" }, [
                            _c("label", { attrs: { for: "vendor" } }, [
                              _vm._v("POI type")
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "form-group col-sm-7" }, [
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.form.poi_type,
                                    expression: "form.poi_type"
                                  }
                                ],
                                staticClass: "form-control text-capitalize",
                                attrs: { placeholder: "POI Type" },
                                on: {
                                  change: function($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call($event.target.options, function(o) {
                                        return o.selected
                                      })
                                      .map(function(o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      _vm.form,
                                      "poi_type",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  }
                                }
                              },
                              [
                                _c("option", { domProps: { value: null } }, [
                                  _vm._v(" -- Select from list -- ")
                                ]),
                                _vm._v(" "),
                                _vm._l(_vm.POIOptions, function(option) {
                                  return _c(
                                    "option",
                                    {
                                      key: option,
                                      domProps: { value: option }
                                    },
                                    [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(option) +
                                          "\n                  "
                                      )
                                    ]
                                  )
                                })
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _vm.errors.poi_type
                              ? _c("div", { staticClass: "text-danger" }, [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(_vm.errors.poi_type[0]) +
                                      "\n                "
                                  )
                                ])
                              : _vm._e()
                          ])
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "hasPermits",
                            rawName: "v-hasPermits:allow",
                            value: { permits: ["location_profile"] },
                            expression: "{permits: ['location_profile']}",
                            arg: "allow"
                          }
                        ],
                        staticClass: "row"
                      },
                      [
                        _c("div", { staticClass: "form-group col-sm-4" }, [
                          _c("label", { attrs: { for: "vendor" } }, [
                            _vm._v("Shuttle Quality")
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-group col-sm-7" }, [
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.form.shuttle_quality,
                                  expression: "form.shuttle_quality"
                                }
                              ],
                              staticClass: "form-control text-capitalize",
                              attrs: { placeholder: "Shuttle Quality" },
                              on: {
                                change: function($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function(o) {
                                      return o.selected
                                    })
                                    .map(function(o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    _vm.form,
                                    "shuttle_quality",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                }
                              }
                            },
                            [
                              _c("option", { domProps: { value: null } }, [
                                _vm._v(" -- Select from list -- ")
                              ]),
                              _vm._v(" "),
                              _vm._l(_vm.shuttleQualityOptions, function(
                                quality
                              ) {
                                return _c(
                                  "option",
                                  {
                                    key: quality,
                                    domProps: { value: quality }
                                  },
                                  [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(quality) +
                                        "\n                  "
                                    )
                                  ]
                                )
                              })
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _vm.errors.shuttle_quality
                            ? _c("div", { staticClass: "text-danger" }, [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(_vm.errors.shuttle_quality[0]) +
                                    "\n                "
                                )
                              ])
                            : _vm._e()
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "hasPermits",
                            rawName: "v-hasPermits:allow",
                            value: { permits: ["location_profile"] },
                            expression: "{permits: ['location_profile']}",
                            arg: "allow"
                          }
                        ],
                        staticClass: "row"
                      },
                      [
                        _c("div", { staticClass: "form-group col-sm-4" }, [
                          _c("label", { attrs: { for: "vendor" } }, [
                            _vm._v("Special Conditions Quality")
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-group col-sm-7" }, [
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.form.special_conditions_quality,
                                  expression: "form.special_conditions_quality"
                                }
                              ],
                              staticClass: "form-control text-capitalize",
                              attrs: { placeholder: "Shuttle Quality" },
                              on: {
                                change: function($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function(o) {
                                      return o.selected
                                    })
                                    .map(function(o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    _vm.form,
                                    "special_conditions_quality",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                }
                              }
                            },
                            [
                              _c("option", { domProps: { value: null } }, [
                                _vm._v(" -- Select from list -- ")
                              ]),
                              _vm._v(" "),
                              _vm._l(
                                _vm.specialConditionsQualityOptions,
                                function(quality) {
                                  return _c(
                                    "option",
                                    {
                                      key: quality,
                                      domProps: { value: quality }
                                    },
                                    [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(quality) +
                                          "\n                  "
                                      )
                                    ]
                                  )
                                }
                              )
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _vm.errors.special_conditions_quality
                            ? _c("div", { staticClass: "text-danger" }, [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      _vm.errors.special_conditions_quality[0]
                                    ) +
                                    "\n                "
                                )
                              ])
                            : _vm._e()
                        ])
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "hasPermits",
                          rawName: "v-hasPermits:allow",
                          value: ["super_admin"],
                          expression: "['super_admin']",
                          arg: "allow"
                        }
                      ],
                      staticClass: "col-sm-7"
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "form-group col-sm-5" },
                        [
                          _c("label", { attrs: { for: "accountManager" } }, [
                            _vm._v("BD/Account Manager")
                          ]),
                          _vm._v(" "),
                          _c("s-select", {
                            attrs: {
                              selected: _vm.account_manager,
                              options: _vm.ims_users,
                              multiple: false
                            },
                            on: { selectedChanged: _vm.setAccountManager }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "form-group col-sm-5" },
                        [
                          _c("label", [_vm._v("BD start date")]),
                          _vm._v(" "),
                          _c("datepicker", {
                            staticClass: "form-control",
                            class: { disabled: !_vm.editDate },
                            attrs: {
                              "min-date": "none",
                              placeholder: "MM/DD/YYYY",
                              disabled: !_vm.editDate
                            },
                            model: {
                              value: _vm.start_date,
                              callback: function($$v) {
                                _vm.start_date = $$v
                              },
                              expression: "start_date"
                            }
                          }),
                          _vm._v(" "),
                          _vm.errors.start_date
                            ? _c("div", { staticClass: "text-danger" }, [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.errors.start_date[0]) +
                                    "\n            "
                                )
                              ])
                            : _vm._e()
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "form-group col-sm-2 margin-top-5x" },
                        [
                          !_vm.editDate
                            ? _c(
                                "button",
                                {
                                  staticClass: "btn btn-info",
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.startEdit()
                                    }
                                  }
                                },
                                [_vm._v("\n              edit\n            ")]
                              )
                            : _vm._e()
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "margin-top-5x col-sm-12" }, [
                        _c("div", { staticClass: "checkbox" }, [
                          _c("label", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.markAsClose,
                                  expression: "markAsClose"
                                }
                              ],
                              attrs: { type: "checkbox" },
                              domProps: {
                                checked: Array.isArray(_vm.markAsClose)
                                  ? _vm._i(_vm.markAsClose, null) > -1
                                  : _vm.markAsClose
                              },
                              on: {
                                change: function($event) {
                                  var $$a = _vm.markAsClose,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        (_vm.markAsClose = $$a.concat([$$v]))
                                    } else {
                                      $$i > -1 &&
                                        (_vm.markAsClose = $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1)))
                                    }
                                  } else {
                                    _vm.markAsClose = $$c
                                  }
                                }
                              }
                            }),
                            _vm._v(
                              "\n                  Mark the location as closed\n              "
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _vm.markAsClose
                          ? _c("div", [
                              _c(
                                "div",
                                { staticClass: "form-group" },
                                [
                                  _c("label", [_vm._v("Effective date")]),
                                  _vm._v(" "),
                                  _c("datepicker", {
                                    staticClass: "form-control",
                                    attrs: { placeholder: "MM/DD/YYYY" },
                                    model: {
                                      value: _vm.location_closed_at,
                                      callback: function($$v) {
                                        _vm.location_closed_at = $$v
                                      },
                                      expression: "location_closed_at"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _vm.errors.location_closed_at
                                    ? _c(
                                        "div",
                                        { staticClass: "text-danger" },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.errors.location_closed_at[0]
                                              ) +
                                              "\n                "
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "form-group" },
                                [
                                  _c("label", [_vm._v("Linked location")]),
                                  _vm._v(" "),
                                  _c("s-select", {
                                    attrs: {
                                      selected: _vm.linkedLocation,
                                      options: _vm.locations,
                                      multiple: false
                                    },
                                    on: {
                                      selectedChanged: _vm.setLinkedLocation
                                    }
                                  }),
                                  _vm._v(" "),
                                  _vm.errors.linked_location_id
                                    ? _c(
                                        "div",
                                        { staticClass: "text-danger" },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.errors.linked_location_id[0]
                                              ) +
                                              "\n                "
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm.linkedLocationParkingTypes.length > 1
                                ? _c(
                                    "div",
                                    { staticClass: "form-group" },
                                    [
                                      _c("label", [
                                        _vm._v("Linked location Parking Type")
                                      ]),
                                      _vm._v(" "),
                                      _c("s-select", {
                                        attrs: {
                                          selected:
                                            _vm.linkedLocationParkingType,
                                          options:
                                            _vm.linkedLocationParkingTypes,
                                          multiple: false
                                        },
                                        on: {
                                          selectedChanged:
                                            _vm.setLinkedLocationParkingType
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ])
                          : _vm._e()
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-sm-12 margin-top-4x margin-bottom-4x" },
                    [
                      _c("hr"),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("label", [_vm._v("Important Notes")]),
                          _vm._v(" "),
                          _c("vue-html5-editor", {
                            ref: "important_notes",
                            attrs: {
                              height: 100,
                              content: _vm.form.important_notes || ""
                            },
                            on: {
                              change: function(x) {
                                return (_vm.form.important_notes = x)
                              }
                            }
                          }),
                          _vm._v(" "),
                          _vm.errors.important_notes
                            ? _c("div", { staticClass: "text-danger" }, [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.errors.important_notes[0]) +
                                    "\n            "
                                )
                              ])
                            : _vm._e()
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("label", [_vm._v("Comments")]),
                          _vm._v(" "),
                          _vm._l(_vm.comments, function(comment, i) {
                            return _c(
                              "div",
                              { key: comment.id, staticClass: "margin-top-1x" },
                              [
                                _c("label", [
                                  _vm._v(_vm._s(comment.user_name))
                                ]),
                                _vm._v(" "),
                                _c("span", [_vm._v(_vm._s(comment.time))]),
                                _vm._v(" "),
                                !comment.editing
                                  ? _c("div", {
                                      domProps: {
                                        innerHTML: _vm._s(comment.comment)
                                      }
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("vue-html5-editor", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: comment.editing,
                                      expression: "comment.editing"
                                    }
                                  ],
                                  ref: "commentEditor" + comment.id,
                                  refInFor: true,
                                  attrs: {
                                    height: 100,
                                    content: comment.edit_value
                                  },
                                  on: {
                                    change: function(x) {
                                      return (comment.edit_value = x)
                                    },
                                    "update:content": function($event) {
                                      return _vm.$set(
                                        comment,
                                        "edit_value",
                                        $event
                                      )
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                comment.editing
                                  ? _c("div", [
                                      _c(
                                        "button",
                                        {
                                          staticClass: "btn btn-primary",
                                          class: { disabled: _vm.processing },
                                          attrs: { disabled: _vm.processing },
                                          on: {
                                            click: function($event) {
                                              return _vm.updateComment(
                                                comment.id,
                                                i
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _vm.processing
                                            ? _c("span", [
                                                _c("i", {
                                                  staticClass:
                                                    "fa fa-spinner fa-spin"
                                                })
                                              ])
                                            : _vm._e(),
                                          _vm._v(
                                            "\n                    Save\n                    "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "button",
                                        {
                                          staticClass: "btn",
                                          on: {
                                            click: function($event) {
                                              return _vm.cancelEdit(
                                                comment.id,
                                                i
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("Cancel")]
                                      )
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                !comment.editing
                                  ? _c("div", { staticClass: "margin-top-0" }, [
                                      _c(
                                        "a",
                                        {
                                          attrs: { href: "#" },
                                          on: {
                                            click: function($event) {
                                              $event.preventDefault()
                                              return _vm.editComment(
                                                comment.id,
                                                i
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("Edit")]
                                      ),
                                      _vm._v(" "),
                                      _c("span", [_vm._v(" . ")]),
                                      _vm._v(" "),
                                      _c(
                                        "a",
                                        {
                                          attrs: { href: "#" },
                                          on: {
                                            click: function($event) {
                                              $event.preventDefault()
                                              return _vm.deleteComment(
                                                comment.id,
                                                i
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("Delete")]
                                      )
                                    ])
                                  : _vm._e()
                              ],
                              1
                            )
                          }),
                          _vm._v(" "),
                          _c("hr"),
                          _vm._v(" "),
                          _c(
                            "div",
                            [
                              !_vm.typing_comment
                                ? _c("input", {
                                    ref: "newComment",
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "text",
                                      placeholder: "Add a comment..."
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.writeComment()
                                      }
                                    }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _c("vue-html5-editor", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.typing_comment,
                                    expression: "typing_comment"
                                  }
                                ],
                                ref: "commentEditor",
                                attrs: {
                                  height: 100,
                                  content: _vm.new_comment
                                },
                                on: {
                                  change: _vm.changeComment,
                                  "update:content": function($event) {
                                    _vm.new_comment = $event
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _vm.typing_comment
                                ? _c("div", [
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-primary",
                                        class: { disabled: _vm.processing },
                                        attrs: { disabled: _vm.processing },
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                            return _vm.newComment()
                                          }
                                        }
                                      },
                                      [
                                        _vm.processing
                                          ? _c("span", [
                                              _c("i", {
                                                staticClass:
                                                  "fa fa-spinner fa-spin"
                                              })
                                            ])
                                          : _vm._e(),
                                        _vm._v(
                                          "\n                    Save\n                  "
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn",
                                        on: { click: _vm.cancelComment }
                                      },
                                      [_vm._v("Cancel")]
                                    )
                                  ])
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        2
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("confirm", {
                    ref: "confirmDelete",
                    attrs: { type: "danger" }
                  })
                ],
                1
              )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-64c1ecb2", { render: render, staticRenderFns: staticRenderFns })
  }
}