import moment from 'moment'
import toastr from 'toastr'
import datepicker from 'components/datepicker'
import { exportReport } from 'components/modals'

const components = {
  datepicker,
  exportReport,
}

const methods = {
  goBackToToday () {
    this.form.from_date = moment().format('MM/DD/YYYY');

    this.getReportData();
  },

  nextMonth (e) {
    e.preventDefault();
    this.form.from_date = moment(this.form.from_date, ['MM/DD/YYYY']).add(this.number_of_days, 'days').format('MM/DD/YYYY');

    this.getReportData();
  },

  prevMonth (e) {
    e.preventDefault();
    let prev = moment(this.form.from_date, ['MM/DD/YYYY']).subtract(this.number_of_days, 'days');

    this.form.from_date = prev.format('MM/DD/YYYY');

    this.getReportData();
  },

  getReportData () {
    if (!this.form.type || !this.form.from_date)
      return;


    let data = _.cloneDeep(this.form);
    data.from_date = moment(data.from_date, ['MM/DD/YYYY']).format('YYYY-MM-DD');
    data.to_date = moment(data.to_date, ['MM/DD/YYYY']).format('YYYY-MM-DD');

    if (!this.location_ids.length) {
      data.locations = this.all_locations.map(l => l.id);
    }
    else {
      data.locations = this.location_ids;
    }
    this.loading = true;

    this.$store.dispatch('reports.getUtilizationReport', {
      data
    }).then(() => {
      this.loading = false;
    });
  },

  reservationsAtDate (type, addDays) {
    let reserved_units = 0;
    // let total = type.number_of_rooms || type.number_of_parkings;
    let date = moment(this.form.from_date, ['MM/DD/YYYY']).add(addDays, 'days');

    let reservation_history_dates = [...type.reservation_history_dates];
    if (type.number_of_parkings && type.child_types && type.child_types.length) {
      for (let j in type.child_types) {
        reservation_history_dates = [...reservation_history_dates, ...type.child_types[j].reservation_history_dates]
      }
    }

    for (let i in reservation_history_dates) {
      let start_date = moment(reservation_history_dates[i].from_date, ['YYYY-MM-DD HH:mm:ss']);
      let end_date = moment(reservation_history_dates[i].to_date, ['YYYY-MM-DD HH:mm:ss']);

      if (date.isSameOrBefore(end_date, 'day') && date.isSameOrAfter(start_date, 'day')) {
        reserved_units += reservation_history_dates[i].number_of_units * reservation_history_dates[i].spot_percentage;
      }
    }

    return reserved_units;
  },

  availabilityAtDate (type, addDays) {
    // let total = type.number_of_rooms || type.number_of_parkings;
    let date = moment(this.form.from_date, ['MM/DD/YYYY']).add(addDays, 'days');
    let total = type.number_of_rooms || type.number_of_parkings;
    let availability = total;

    for (let i in type.blackout_dates) {
      let start_date = moment(type.blackout_dates[i].from_date, ['YYYY-MM-DD HH:mm:ss']);
      let end_date = moment(type.blackout_dates[i].to_date, ['YYYY-MM-DD HH:mm:ss']);
      let day = date.format('dddd');
      if (date.isSameOrBefore(end_date, 'day')
        && date.isSameOrAfter(start_date, 'day')
        && type.blackout_dates[i][day.toLowerCase()]
      ) {
        availability = type.blackout_dates[i].available_num;
      }
    }
    return type.sold_out == 0 ? availability : 0;
  },

  priceInThisDate (type, index, date) {
    let day_name = date.format('dddd').toLowerCase();
    for (let i in this.utilization_data[index]) {
      if (type.id == this.utilization_data[index][i].id) {
        let rates = this.utilization_data[index][i].rates;
        for (let j in rates) {
          let start_date = moment(rates[j].from_date, ['YYYY-MM-DD']);
          let to_date = rates[j].to_date ? rates[j].to_date : '2099-12-31';
          let end_date = moment(to_date, ['YYYY-MM-DD']);
          if (date.isBetween(start_date, end_date, null, "[]") && rates[j][day_name]) {
            return rates[j].price;
          }
        }
        break;
      }
    }
    return 0;
  },

  exportReport(ext) {
    let pairs = [];
    let params = '';
    let data = _.cloneDeep(this.form);
    let from_date = moment(data.from_date, ['MM/DD/YYYY']).format('YYYY-MM-DD');
    let to_date = moment(data.to_date, ['MM/DD/YYYY']).format('YYYY-MM-DD');
    let type = data.type;
    
    if (from_date) {
      pairs.push('from_date=' + from_date)
    }
    if (to_date) {
      pairs.push('to_date=' + to_date)
    }
    pairs.push('type=' + type);

    for (var key in this.location_ids) {
      pairs.push(encodeURIComponent('locations[]') + '=' + encodeURIComponent(this.location_ids[key]));
    }

    if (!this.location_ids.length) {
      let all_locations = this.all_locations.map(l => encodeURIComponent('locations[]') + '=' + encodeURIComponent(l.id));
      pairs = pairs.concat(all_locations);
    }

    params = pairs.join('&');
    let url = 'reports/utilization?' + params;
    this.$refs.export.open(url, ext);
    return;
  },

  resetReports () {
    this.run_report = false;
  },
}

const computed = {
  utilization_data () {
    let data = _.cloneDeep(this.$store.state.reports.utilization_data);
    for (let i in data) {
      for (let j in data[i]) {
        data[i][j].rates = _.sortBy(data[i][j].rates, ['id']).reverse();
      }
    }
    return data;
  },

  can_run () {
    return !this.form.type ? false : true;
  },
}

const watch = {
  ['form.from_date'] () {
    let from_date_unformated = moment(this.form.from_date, ['MM/DD/YYYY']);
    let to_date_unformated = moment(this.form.from_date, ['MM/DD/YYYY']).add(this.number_of_days, 'days');
    this.form.to_date = to_date_unformated.format('MM/DD/YYYY');
    this.getReportData();
  },

  location_ids (n, o) {
    if ( n == o) return;
    this.resetReports();
  }
}

export default {
  name: 'utilization-report',
  props: ['location_ids','all_locations'],
  methods,
  components,
  computed,
  watch,
  data () {
    let number_of_days = 30;
    let from_date_unformated = moment();
    let from_date = moment().format('MM/DD/YYYY');
    let to_date_unformated = moment(from_date, ['MM/DD/YYYY']).add(number_of_days, 'days');
    let to_date = to_date_unformated.format('MM/DD/YYYY');

    return {
      loading: false,
      showResults: false,
      number_of_days,
      form: {
        from_date,
        to_date,
        type: null
      },
      moment
    }
  },

  beforeDestroy () {
    this.$store.dispatch('reports.resetReports');
  }
}
