import _ from 'lodash'
import toastr from 'toastr'
import layout from 'layouts/default'
import pagination from 'components/pagination'
import confirm from 'components/modals/confirm'
import { hasPermits } from 'directives'
import { permits, user } from 'services'
import apiDocumentationUpload from './apiDocumentationUpload'


const directives = {
  hasPermits
}

const components = {
  layout,
  pagination,
  confirm,
  apiDocumentationUpload
}

const computed = {
  // channels () {
  //   return _.cloneDeep(this.$store.state.channels.all);
  // },

  isAdmin () {
    return this.user && this.user.type == 'ims';
  },
}

const methods = {
  loadPage (page) {
    this.$route.query.page = page;
    let data = {page, _q: this.searchKey};

    this.loading = true;

    if (this.filter == 'deleted') {
      data._only_deleted = 'true';
    } else if (this.filter == 'all') {
      data._with_deleted = 'true';
    }

    this.$store.dispatch('channels.getAll', data).then(() => {
      this.channels = _.cloneDeep(this.$store.state.channels.all);
      this.loading = false;
    });
  },
  search (searchKey) {
    this.searchKey = searchKey;
    this.loadPage(1);
  },
  removeChannel (item) {
    this.$refs.confirmDelete.confirm(
    'are you sure that you want to delete this channel?',
    () => {
      this.loadings[item.id] = true;
      this.loadings = _.cloneDeep(this.loadings);

      this.$store.dispatch('channels.remove', item.id).then(() => {
        this.loadings[item.id] = false;
        console.log('this.$store.state.channels.errors', this.$store.state.channels.errors);
        let errors;
        if (errors = this.$store.state.channels.errors) {
          if (errors.message) {
            toastr.error(errors.message);
          } else {
            toastr.error(errors.error);
          }
        }
      });
    });
  },

  undoRemove (item) {
    this.$refs.confirmUndoDelete.confirm(
    'Are you sure that you want to undelete this channel?',
    () => {
      this.loadings[item.id] = true;
      this.loadings = _.cloneDeep(this.loadings);

      this.$store.dispatch('channels.undoRemove', item.id).then(() => {
        this.loadings[item.id] = false;
        console.log('this.$store.state.channels.errors', this.$store.state.channels.errors);
        let errors;
        if (errors = this.$store.state.channels.errors) {
          if (errors.message) {
            toastr.error(errors.message);
          } else {
            toastr.error(errors.error);
          }
        }
      });
    });
  },

  filterChanged () {
    console.log(this.filter);
    this.loadPage(1);
  },

  hasPermitsToAccessChannels () {
    return permits.hasPermits(['channel_list', 'channel_user']);
  }
}

const data = {
  loading: false,
  searchKey: null,
  loadings: {},
  filter: 'active',
  channels: [],
  user: null
}

export default {
  name: 'all-channels',
  components,
  computed,
  methods,
  directives,
  data: () => data,
  created () {
    console.log('this.$store', this.$store);
    this.loadPage(this.$route.query.page || 1);
  },
  mounted () {
    this.user = user.getUser();
  }
}
