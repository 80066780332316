import moment from 'moment'
import datatable from 'components/datatable'

const components = {
  datatable
}

const directives = {

}

const computed = {
}

const methods = {

}

export default {
  name: 'reports-blackouts',
  props: ['url'],
  computed,
  components,
  directives,
  methods,
  data () {
    return {
      // loading: true,
      processing: false,
      errors: {},
      columns: [
        {name: "Location Name", object_name: "location_channels.0.location.name"},
        {name: "Channel Names", object_name: (data) => (data.location_channels.map((item) => item.channel ? item.channel.name : '')).join(', ')},
        {name: "Created at", object_name: (data) => moment(data.created_at, ['YYYY-MM-DD HH:mm:ss']).format('MM/DD/YYYY hh:mm A')},
        {name: "Start Date", object_name: (data) => moment(data.from_date, ['YYYY-MM-DD HH:mm:ss']).format('MM/DD/YYYY')},
        {name: "End Date", object_name: (data) => moment(data.to_date, ['YYYY-MM-DD HH:mm:ss']).format('MM/DD/YYYY')},
        {name: "Unit", object_name: (data) => data.parking_types.length ? "Parking" : "Room"},
        {name: "Unit Types", object_name: (data) => {          
          let unit = data.parking_types.length ? "parking_types" : "room_types";
          return (data[unit].map((item) => item.name)).join(', ');
        }},
        {name: "Available Units", object_name: "available_num"}
      ]
    }
  },
  created () {
  }
}
