import toastr from 'toastr'
import { funcModal } from 'components/modals'

const components = {
    funcModal
}

const methods = {
    open (reservation_id, location_id) {
        this.card_details = null;
        this.location_id = location_id;
        this.reservation_id = reservation_id;
        this.$refs.modal.open();
    },

    onClose () {
        this.$emit('close');
    },

    showCardDetails () {
        this.processing = true;
        this.$store.dispatch('guaranteeCard.getForReservation', {
          reservation_id: this.reservation_id,
          location_id: this.location_id
        }).then(() => {
          if (this.$store.state.guaranteeCard.errors) {
            toastr.error(this.$store.state.guaranteeCard.errors.message);
          } else {
            this.card_details = this.$store.state.guaranteeCard.active;
          }
    
          this.processing = false;
        });
    
      },
}

export default {
    name: 'guarantee-card-modal',
    methods,
    components,
    data () {
        return {
            processing: false,
            card_details: null,
            reservation_id: null,
            location_id: null
        }
    }
}