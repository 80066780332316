var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "func-modal",
        {
          ref: "modal",
          attrs: { title: "Reservation Details" },
          on: { close: _vm.onCloseCheckinModal },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function(props) {
                return [
                  _vm.current && _vm.checkin_out
                    ? _c(
                        "button",
                        {
                          directives: [
                            {
                              name: "hasPermits",
                              rawName: "v-hasPermits:allow",
                              value: {
                                id: _vm.current.location_id,
                                permits: ["reservations_checkin_checkout"]
                              },
                              expression:
                                "{id: current.location_id, permits: ['reservations_checkin_checkout']}",
                              arg: "allow"
                            }
                          ],
                          staticClass: "btn btn-default",
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.checkinCheckout.apply(null, arguments)
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s(_vm.checkinOutButtonName) +
                              "\n      "
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.checkHistoriesForNotes
                    ? _c(
                        "button",
                        {
                          directives: [
                            {
                              name: "hasPermits",
                              rawName: "v-hasPermits:allow",
                              value: ["super_admin"],
                              expression: "['super_admin']",
                              arg: "allow"
                            }
                          ],
                          staticClass: "btn btn-default",
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.calculateNotes.apply(null, arguments)
                            }
                          }
                        },
                        [_vm._v("\n        Calculate Notes\n      ")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.current
                    ? _c(
                        "button",
                        {
                          directives: [
                            {
                              name: "hasPermits",
                              rawName: "v-hasPermits:allow",
                              value: {
                                id: _vm.current.location_id,
                                permits: ["reservations_partial_refund"]
                              },
                              expression:
                                "{id: current.location_id, permits: ['reservations_partial_refund']}",
                              arg: "allow"
                            }
                          ],
                          staticClass: "btn btn-default",
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.openPartialRefundModal.apply(
                                null,
                                arguments
                              )
                            }
                          }
                        },
                        [_vm._v("\n        Partial Refund\n      ")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.current && _vm.current.partial_refund > 0
                    ? _c(
                        "button",
                        {
                          directives: [
                            {
                              name: "hasPermits",
                              rawName: "v-hasPermits:allow",
                              value: ["super_admin"],
                              expression: "['super_admin']",
                              arg: "allow"
                            },
                            {
                              name: "hasPermits",
                              rawName: "v-hasPermits:prevent",
                              value: ["channel_user"],
                              expression: "['channel_user']",
                              arg: "prevent"
                            }
                          ],
                          staticClass: "btn btn-default",
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.openRevertPartialRefundModal.apply(
                                null,
                                arguments
                              )
                            }
                          }
                        },
                        [_vm._v("\n        Revert Partial Refund\n      ")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.current &&
                  !_vm.local_reservation.testing &&
                  !_vm.partialRefundRequest &&
                  _vm.current.location.allow_refund_request &&
                  _vm.user &&
                  _vm.user.type != "ims"
                    ? _c(
                        "button",
                        {
                          directives: [
                            {
                              name: "hasPermits",
                              rawName: "v-hasPermits:allow",
                              value: ["channel_user"],
                              expression: "['channel_user']",
                              arg: "allow"
                            },
                            {
                              name: "hasPermits",
                              rawName: "v-hasPermits:prevent",
                              value: ["super_admin"],
                              expression: "['super_admin']",
                              arg: "prevent"
                            }
                          ],
                          staticClass: "btn btn-default",
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.openPartialRefundRequestModal.apply(
                                null,
                                arguments
                              )
                            }
                          }
                        },
                        [_vm._v("\n          Partial Refund Request\n      ")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.partialRefundRequest
                    ? _c(
                        "router-link",
                        {
                          directives: [
                            {
                              name: "hasPermits",
                              rawName: "v-hasPermits:allow",
                              value: ["channel_user"],
                              expression: "['channel_user']",
                              arg: "allow"
                            },
                            {
                              name: "hasPermits",
                              rawName: "v-hasPermits:prevent",
                              value: ["super_admin"],
                              expression: "['super_admin']",
                              arg: "prevent"
                            }
                          ],
                          staticClass: "btn btn-default",
                          attrs: {
                            to: {
                              name: "refundRequests.edit",
                              params: { id: _vm.partialRefundRequest.id }
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n        Go to partial refund request\n      "
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.current &&
                  !_vm.local_reservation.cancelled &&
                  (!_vm.isReservationFinished ||
                    (_vm.isReservationFinished && _vm.isAdmin)) &&
                  ((!_vm.isReservationStarted(false) &&
                    !_vm.local_reservation.history_id_when_begin) ||
                    _vm.isAdmin)
                    ? _c(
                        "button",
                        {
                          directives: [
                            {
                              name: "hasPermits",
                              rawName: "v-hasPermits:prevent",
                              value: ["location_user"],
                              expression: "['location_user']",
                              arg: "prevent"
                            },
                            {
                              name: "hasPermits",
                              rawName: "v-hasPermits:allow",
                              value: ["channel_user", "reservations_cancel"],
                              expression:
                                "['channel_user', 'reservations_cancel']",
                              arg: "allow"
                            }
                          ],
                          staticClass: "btn btn-danger",
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.cancelReservation()
                            }
                          }
                        },
                        [_vm._v("\n        Cancel\n      ")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.current &&
                  _vm.local_reservation.cancelled &&
                  _vm.local_reservation.dispute_amount == 0
                    ? _c(
                        "button",
                        {
                          directives: [
                            {
                              name: "hasPermits",
                              rawName: "v-hasPermits:prevent",
                              value: ["location_user"],
                              expression: "['location_user']",
                              arg: "prevent"
                            },
                            {
                              name: "hasPermits",
                              rawName: "v-hasPermits:allow",
                              value: ["super_admin"],
                              expression: "['super_admin']",
                              arg: "allow"
                            }
                          ],
                          staticClass: "btn btn-danger",
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.revertCancelReservation.apply(
                                null,
                                arguments
                              )
                            }
                          }
                        },
                        [_vm._v("\n        Revert Cancel\n      ")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.current &&
                  _vm.current.partial_refund == 0 &&
                  _vm.local_reservation.dispute_amount == 0
                    ? _c(
                        "button",
                        {
                          directives: [
                            {
                              name: "hasPermits",
                              rawName: "v-hasPermits:allow",
                              value: ["super_admin"],
                              expression: "['super_admin']",
                              arg: "allow"
                            }
                          ],
                          staticClass: "btn btn-danger",
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.disputeReservation()
                            }
                          }
                        },
                        [_vm._v("\n        dispute\n      ")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-link",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return props.close()
                        }
                      }
                    },
                    [_vm._v("Close")]
                  )
                ]
              }
            }
          ])
        },
        [
          _c(
            "template",
            { slot: "body" },
            [
              _c(
                "tabs",
                [
                  _vm.local_reservation && _vm.local_reservation.cancelled
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "text-center text-danger f18 padding-top-3x"
                        },
                        [_vm._v("CANCELLED")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("tab", { attrs: { title: "Current State" } }, [
                    _vm.current
                      ? _c(
                          "table",
                          { staticClass: "table table-bordered margin-top-2x" },
                          [
                            _c("tbody", [
                              _vm.local_reservation.cancelled
                                ? _c("tr", [
                                    _c("th", [_vm._v("Cancellation Date")]),
                                    _vm._v(" "),
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("dateFormat")(
                                            _vm.local_reservation.updated_at,
                                            "MM/DD/YYYY hh:mm A"
                                          )
                                        )
                                      )
                                    ])
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c("tr", [
                                _c("th", [_vm._v("Reservation #")]),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.local_reservation.reservation_number
                                    )
                                  )
                                ])
                              ]),
                              _vm._v(" "),
                              _c("tr", [
                                _c("th", [_vm._v("Location Name")]),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(_vm._s(_vm.current.location.name))
                                ])
                              ]),
                              _vm._v(" "),
                              _c("tr", [
                                _c("th", [_vm._v("Reservation Type")]),
                                _vm._v(" "),
                                _c("td", [
                                  _vm.historyDetails.room_type
                                    ? _c("span", [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              _vm.current.total_days.room
                                            ) +
                                            " \n                    night"
                                        ),
                                        _vm.current.total_days.room > 1
                                          ? _c("span", [_vm._v("s")])
                                          : _vm._e(),
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              _vm.historyDetails.room_type
                                            ) +
                                            "\n                  "
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.historyDetails.room_type &&
                                  _vm.historyDetails.parking_type
                                    ? _c("span", [_vm._v("&")])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.historyDetails.parking_type
                                    ? _c("span", [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              _vm.current.total_days.parking
                                                .parking_days
                                            ) +
                                            " \n                    day"
                                        ),
                                        _vm.current.total_days.parking
                                          .parking_days > 1
                                          ? _c("span", [_vm._v("s")])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.current.total_days.parking
                                          .parking_hours
                                          ? _c("span", [
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(
                                                    _vm.current.total_days
                                                      .parking.parking_hours
                                                  ) +
                                                  " \n                    hour"
                                              ),
                                              _vm.current.total_days.parking
                                                .parking_hours > 1
                                                ? _c("span", [_vm._v("s")])
                                                : _vm._e()
                                            ])
                                          : _vm._e(),
                                        _vm._v(
                                          "\n                    of " +
                                            _vm._s(
                                              _vm.historyDetails.parking_type
                                            ) +
                                            " Parking\n                  "
                                        )
                                      ])
                                    : _vm._e()
                                ])
                              ]),
                              _vm._v(" "),
                              _c("tr", [
                                _c("th", [_vm._v("Cancelled")]),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.local_reservation.cancelled
                                        ? "Yes"
                                        : "No"
                                    )
                                  )
                                ])
                              ]),
                              _vm._v(" "),
                              _c("tr", [
                                _c("th", [_vm._v("Creation Date")]),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("dateFormat")(
                                        _vm.local_reservation.created_at,
                                        "MM/DD/YYYY hh:mm A"
                                      )
                                    ) +
                                      "  " +
                                      _vm._s(_vm.timezone_name)
                                  )
                                ])
                              ]),
                              _vm._v(" "),
                              _c("tr", [
                                _c("th", [_vm._v("Latest Update")]),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("dateFormat")(
                                        _vm.current.created_at,
                                        "MM/DD/YYYY hh:mm A"
                                      )
                                    )
                                  )
                                ])
                              ]),
                              _vm._v(" "),
                              _vm.historyDetails.parking_type
                                ? _c("tr", [
                                    _c("th", [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.historyDetails.parking_type
                                          ) +
                                          " Parking\n                "
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      _vm._l(
                                        _vm.historyDetails.parking_dates,
                                        function(date) {
                                          return _c("div", [
                                            _vm._v(
                                              "\n                    Check-in: " +
                                                _vm._s(
                                                  _vm._f("dateFormat")(
                                                    date.from_date,
                                                    "MM/DD/YYYY hh:mm A"
                                                  )
                                                )
                                            ),
                                            _c("br"),
                                            _vm._v(
                                              "\n                    Check-out: " +
                                                _vm._s(
                                                  _vm._f("dateFormat")(
                                                    date.to_date,
                                                    "MM/DD/YYYY hh:mm A"
                                                  )
                                                ) +
                                                "\n                  "
                                            )
                                          ])
                                        }
                                      ),
                                      0
                                    )
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.historyDetails.room_type
                                ? _c("tr", [
                                    _c("th", [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(_vm.historyDetails.room_type) +
                                          " Room\n                "
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      _vm._l(
                                        _vm.historyDetails.room_dates,
                                        function(date) {
                                          return _c("div", [
                                            _vm._v(
                                              "\n                    Check-in: " +
                                                _vm._s(
                                                  _vm._f("dateFormat")(
                                                    date.from_date,
                                                    "MM/DD/YYYY hh:mm A"
                                                  )
                                                )
                                            ),
                                            _c("br"),
                                            _vm._v(
                                              "\n                    Check-out: " +
                                                _vm._s(
                                                  _vm._f("dateFormat")(
                                                    date.to_date,
                                                    "MM/DD/YYYY hh:mm A"
                                                  )
                                                ) +
                                                "\n                  "
                                            )
                                          ])
                                        }
                                      ),
                                      0
                                    )
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.historyDetails.room_type
                                ? _c("tr", [
                                    _c("th", [_vm._v("Room Guests")]),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      _vm._l(
                                        _vm.historyDetails.number_of_guests,
                                        function(n, i) {
                                          return _c("div", [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(n) +
                                                " guest"
                                            ),
                                            n > 1
                                              ? _c("span", [_vm._v("s")])
                                              : _vm._e(),
                                            _vm._v(
                                              "\n                    in room " +
                                                _vm._s(i + 1) +
                                                " " +
                                                _vm._s(
                                                  _vm.historyDetails.guest_names
                                                    ? "- " +
                                                        _vm.historyDetails
                                                          .guest_names[i]
                                                    : ""
                                                ) +
                                                "\n                  "
                                            )
                                          ])
                                        }
                                      ),
                                      0
                                    )
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.historyDetails.parking_type
                                ? _c("tr", [
                                    _c("th", [_vm._v("Number of spots")]),
                                    _vm._v(" "),
                                    _c("td", [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.historyDetails.number_of_spots
                                          ) +
                                          "\n                "
                                      )
                                    ])
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.historyDetails.room_type
                                ? _c("tr", [
                                    _c("th", [_vm._v("Number of rooms")]),
                                    _vm._v(" "),
                                    _c("td", [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.historyDetails.number_of_rooms
                                          ) +
                                          "\n                "
                                      )
                                    ])
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c("tr", [
                                _c("th", [_vm._v("Customer Details")]),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(_vm.current.guest_name) +
                                      " - \n                  " +
                                      _vm._s(_vm.current.email) +
                                      " -\n                  " +
                                      _vm._s(_vm.current.phone) +
                                      "\n                "
                                  )
                                ])
                              ]),
                              _vm._v(" "),
                              _c("tr", [
                                _c("th", [_vm._v("Reserved By")]),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(_vm.local_reservation.reserved_by)
                                  )
                                ])
                              ]),
                              _vm._v(" "),
                              _c("tr", [
                                _c("th", [_vm._v("Check In")]),
                                _vm._v(" "),
                                _c("td", [
                                  _vm.historyDetails.parking_type
                                    ? _c(
                                        "div",
                                        _vm._l(
                                          _vm.historyDetails.parking_dates,
                                          function(date, i) {
                                            return _c("div", { key: i }, [
                                              _vm._v(
                                                "\n                      Parking : " +
                                                  _vm._s(
                                                    date.check_in ? "Yes" : "No"
                                                  ) +
                                                  "\n                      "
                                              ),
                                              date.check_in
                                                ? _c("span", [
                                                    _vm._v(
                                                      _vm._s("(") +
                                                        _vm._s(
                                                          _vm._f("dateFormat")(
                                                            date.check_in_time,
                                                            "MM/DD/YYYY hh:mm A"
                                                          )
                                                        ) +
                                                        _vm._s(")")
                                                    )
                                                  ])
                                                : _vm._e(),
                                              _c("br")
                                            ])
                                          }
                                        ),
                                        0
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.historyDetails.room_type
                                    ? _c(
                                        "div",
                                        _vm._l(
                                          _vm.historyDetails.room_dates,
                                          function(date, i) {
                                            return _c("div", { key: i }, [
                                              _vm._v(
                                                "\n                      Room "
                                              ),
                                              _vm.historyDetails.room_dates &&
                                              _vm.historyDetails.room_dates
                                                .length != 1
                                                ? _c("span", [
                                                    _vm._v(_vm._s(i + 1))
                                                  ])
                                                : _vm._e(),
                                              _vm._v(
                                                " : " +
                                                  _vm._s(
                                                    date.check_in ? "Yes" : "No"
                                                  ) +
                                                  " \n                      "
                                              ),
                                              date.check_in
                                                ? _c("span", [
                                                    _vm._v(
                                                      _vm._s("(") +
                                                        _vm._s(
                                                          _vm._f("dateFormat")(
                                                            date.check_in_time,
                                                            "MM/DD/YYYY hh:mm A"
                                                          )
                                                        ) +
                                                        _vm._s(")")
                                                    )
                                                  ])
                                                : _vm._e(),
                                              _c("br")
                                            ])
                                          }
                                        ),
                                        0
                                      )
                                    : _vm._e()
                                ])
                              ]),
                              _vm._v(" "),
                              _c("tr", [
                                _c("th", [_vm._v("Calculations")]),
                                _vm._v(" "),
                                _c("td", [
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "hasPermits",
                                          rawName: "v-hasPermits:prevent",
                                          value: ["location_user"],
                                          expression: "['location_user']",
                                          arg: "prevent"
                                        }
                                      ]
                                    },
                                    [
                                      _vm._v(
                                        "\n                    Subtotal: " +
                                          _vm._s(
                                            _vm.currencyCode(
                                              _vm.current.location
                                            ) + _vm.current.subtotal.toFixed(2)
                                          ) +
                                          "\n                  "
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "hasPermits",
                                          rawName: "v-hasPermits:prevent",
                                          value: ["location_user"],
                                          expression: "['location_user']",
                                          arg: "prevent"
                                        }
                                      ]
                                    },
                                    [
                                      _vm._v(
                                        "\n                    Taxes & Fees: " +
                                          _vm._s(
                                            _vm.currencyCode(
                                              _vm.current.location
                                            ) +
                                              (
                                                _vm.current.total_fees +
                                                _vm.current.orginal_total_tax
                                              ).toFixed(2)
                                          ) +
                                          "\n                  "
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm.current.total_discount
                                    ? _c("div", [
                                        _vm._v(
                                          "\n                    Total Discount: " +
                                            _vm._s(
                                              _vm.currencyCode(
                                                _vm.current.location
                                              ) +
                                                _vm.current.total_discount.toFixed(
                                                  2
                                                )
                                            ) +
                                            "\n                  "
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.current.partial_refund
                                    ? _c("div", [
                                        _vm._v(
                                          "\n                    Partial Refund: " +
                                            _vm._s(
                                              _vm.currencyCode(
                                                _vm.current.location
                                              ) +
                                                _vm.current.partial_refund.toFixed(
                                                  2
                                                )
                                            ) +
                                            "\n                  "
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("div", [
                                    _vm._v(
                                      "\n                    Total: " +
                                        _vm._s(
                                          _vm.currencyCode(
                                            _vm.current.location
                                          ) + _vm.current.grand_total.toFixed(2)
                                        ) +
                                        "\n                    "
                                    ),
                                    _c(
                                      "small",
                                      {
                                        directives: [
                                          {
                                            name: "hasPermits",
                                            rawName: "v-hasPermits:allow",
                                            value: ["location_user"],
                                            expression: "['location_user']",
                                            arg: "allow"
                                          },
                                          {
                                            name: "hasPermits",
                                            rawName: "v-hasPermits:prevent",
                                            value: ["super_admin"],
                                            expression: "['super_admin']",
                                            arg: "prevent"
                                          }
                                        ]
                                      },
                                      [
                                        _vm._v(
                                          "\n                      Does not include channel added fees\n                    "
                                        )
                                      ]
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("div", [
                                    _vm._v(
                                      "\n                    Already Paid: \n                    "
                                    ),
                                    _vm.current.grand_total <
                                    _vm.current.due_at_location_total
                                      ? _c("span", [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(
                                                _vm.currencyCode(
                                                  _vm.current.location
                                                ) +
                                                  (
                                                    _vm.current.grand_total -
                                                    _vm.current.location_total
                                                  ).toFixed(2)
                                              ) +
                                              "\n                    "
                                          )
                                        ])
                                      : _c("span", [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(
                                                _vm.currencyCode(
                                                  _vm.current.location
                                                ) +
                                                  (
                                                    _vm.current.grand_total -
                                                    _vm.current
                                                      .due_at_location_total
                                                  ).toFixed(2)
                                              ) +
                                              "\n                    "
                                          )
                                        ])
                                  ]),
                                  _vm._v(" "),
                                  _c("div", [
                                    _c("b", [
                                      _vm._v(
                                        "Due at Check-in: " +
                                          _vm._s(
                                            _vm.currencyCode(
                                              _vm.current.location
                                            ) +
                                              _vm.current.due_at_location_total.toFixed(
                                                2
                                              )
                                          )
                                      )
                                    ])
                                  ])
                                ])
                              ]),
                              _vm._v(" "),
                              _vm.historyDetails.room_type &&
                              _vm.local_reservation.credit_card_count
                                ? _c(
                                    "tr",
                                    {
                                      directives: [
                                        {
                                          name: "hasPermits",
                                          rawName: "v-hasPermits:allow",
                                          value: {
                                            id: _vm.current.location_id,
                                            permits: [
                                              "guarantee_reservation_card_list"
                                            ]
                                          },
                                          expression:
                                            "{id: current.location_id, permits: ['guarantee_reservation_card_list']}",
                                          arg: "allow"
                                        }
                                      ]
                                    },
                                    [
                                      _c("th"),
                                      _vm._v(" "),
                                      _c("td", [
                                        _c("div", [
                                          _c("b", [
                                            _vm._v("Credit card received")
                                          ])
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "margin-bottom-2x" },
                                          [
                                            _vm._v(
                                              "\n                    You can view these credit card details 3 times until " +
                                                _vm._s(
                                                  _vm.credit_card_max_view_date.format(
                                                    "dddd, MM/DD/YYYY"
                                                  )
                                                ) +
                                                "\n                  "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "button",
                                          {
                                            staticClass: "btn btn-primary",
                                            on: {
                                              click: function($event) {
                                                $event.preventDefault()
                                                return _vm.openCardModal.apply(
                                                  null,
                                                  arguments
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                    View credit card details\n                  "
                                            )
                                          ]
                                        )
                                      ])
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.current.location.special_conditions
                                ? _c("tr", [
                                    _c("th", [_vm._v("Special Conditions")]),
                                    _vm._v(" "),
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("stripTags")(
                                            _vm.current.location
                                              .special_conditions
                                          )
                                        )
                                      )
                                    ])
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.local_reservation.channel
                                ? _c("tr", [
                                    _c("th", [_vm._v("Seller")]),
                                    _vm._v(" "),
                                    _c("td", [
                                      _c(
                                        "a",
                                        {
                                          attrs: {
                                            target: "_blank",
                                            href:
                                              "//" +
                                              _vm.local_reservation.channel
                                                .domain
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.local_reservation.channel
                                                .domain
                                            )
                                          )
                                        ]
                                      )
                                    ])
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.current.extra_fields &&
                              _vm.current.extra_fields.length
                                ? _c("tr", [
                                    _c("th", [_vm._v("Aditional Information")]),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      _vm._l(_vm.current.extra_fields, function(
                                        extra
                                      ) {
                                        return _c("div", [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(extra.label) +
                                              ": " +
                                              _vm._s(
                                                Array.isArray(extra.value)
                                                  ? extra.value.join(", ")
                                                  : extra.value
                                              ) +
                                              "\n                  "
                                          )
                                        ])
                                      }),
                                      0
                                    )
                                  ])
                                : _vm._e()
                            ])
                          ]
                        )
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c(
                    "tab",
                    { attrs: { title: "History" } },
                    _vm._l(_vm.history, function(item) {
                      return _vm.history
                        ? _c(
                            "table",
                            {
                              staticClass: "table table-bordered margin-top-2x",
                              class: { "active-history-table": item.active }
                            },
                            [
                              _c("tbody", [
                                item.active
                                  ? _c("tr", [
                                      _c(
                                        "th",
                                        {
                                          staticClass: "text-center",
                                          attrs: { colspan: "2" }
                                        },
                                        [_vm._v(" (Active history) ")]
                                      )
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("tr", [
                                  _c("th", [_vm._v("Creation Date")]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("dateFormat")(
                                          item.created_at,
                                          "MM/DD/YYYY hh:mm A"
                                        )
                                      )
                                    )
                                  ])
                                ]),
                                _vm._v(" "),
                                _c("tr", [
                                  _c("th", [_vm._v("Reservation Info")]),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    _vm._l(item.dates, function(date) {
                                      return _vm.current
                                        ? _c(
                                            "table",
                                            { staticClass: "table" },
                                            [
                                              _c("tr", [
                                                _c("th", [_vm._v("Type")]),
                                                _vm._v(" "),
                                                _c("td", [
                                                  _vm._v(_vm._s(date.type_type))
                                                ])
                                              ]),
                                              _vm._v(" "),
                                              _c("tr", [
                                                _c("th", [_vm._v("From Date")]),
                                                _vm._v(" "),
                                                _c("td", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm._f("dateFormat")(
                                                        date.from_date,
                                                        "MM/DD/YYYY hh:mm A"
                                                      )
                                                    )
                                                  )
                                                ])
                                              ]),
                                              _vm._v(" "),
                                              _c("tr", [
                                                _c("th", [_vm._v("To Date")]),
                                                _vm._v(" "),
                                                _c("td", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm._f("dateFormat")(
                                                        date.to_date,
                                                        "MM/DD/YYYY hh:mm A"
                                                      )
                                                    )
                                                  )
                                                ])
                                              ]),
                                              _vm._v(" "),
                                              _vm._l(
                                                date.parking_rates,
                                                function(rate, i) {
                                                  return date.parking_rates
                                                    .length
                                                    ? _c("tr", [
                                                        rate.number_of_days > 0
                                                          ? _c("th", [
                                                              _vm._v(
                                                                "Parking Rate " +
                                                                  _vm._s(i + 1)
                                                              )
                                                            ])
                                                          : _vm._e(),
                                                        _vm._v(" "),
                                                        rate.number_of_days > 0
                                                          ? _c("td", [
                                                              _vm._v(
                                                                "\n                        ( " +
                                                                  _vm._s(
                                                                    _vm.currencyCode(
                                                                      _vm
                                                                        .current
                                                                        .location
                                                                    ) +
                                                                      rate.price_of_one_unit
                                                                  ) +
                                                                  "\n                        * " +
                                                                  _vm._s(
                                                                    rate.number_of_days
                                                                  ) +
                                                                  " days\n                        * " +
                                                                  _vm._s(
                                                                    rate.number_of_parkings
                                                                  ) +
                                                                  " spots ) - " +
                                                                  _vm._s(
                                                                    rate.daily_or_hourly
                                                                  ) +
                                                                  "\n                      "
                                                              )
                                                            ])
                                                          : _vm._e()
                                                      ])
                                                    : _vm._e()
                                                }
                                              ),
                                              _vm._v(" "),
                                              date.parking_rates[
                                                date.parking_rates.length - 1
                                              ] &&
                                              date.parking_rates[
                                                date.parking_rates.length - 1
                                              ].number_of_hours
                                                ? _c("tr", [
                                                    _c("th", [
                                                      _vm._v(
                                                        "Parking Rate Extra Hours"
                                                      )
                                                    ]),
                                                    _vm._v(" "),
                                                    _c("td", [
                                                      _vm._v(
                                                        "\n                        ( " +
                                                          _vm._s(
                                                            _vm.currencyCode(
                                                              _vm.current
                                                                .location
                                                            ) +
                                                              date
                                                                .parking_rates[
                                                                date
                                                                  .parking_rates
                                                                  .length - 1
                                                              ]
                                                                .hourly_price_of_one_unit
                                                          ) +
                                                          "\n                        * " +
                                                          _vm._s(
                                                            date.parking_rates[
                                                              date.parking_rates
                                                                .length - 1
                                                            ].number_of_hours
                                                          ) +
                                                          " hours\n                        * " +
                                                          _vm._s(
                                                            date.parking_rates[
                                                              date.parking_rates
                                                                .length - 1
                                                            ].number_of_parkings
                                                          ) +
                                                          " spots ) - " +
                                                          _vm._s(
                                                            date.parking_rates[
                                                              date.parking_rates
                                                                .length - 1
                                                            ].daily_or_hourly
                                                          ) +
                                                          "\n                      "
                                                      )
                                                    ])
                                                  ])
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _vm._l(date.room_rates, function(
                                                rate,
                                                i
                                              ) {
                                                return date.room_rates.length
                                                  ? _c("tr", [
                                                      _c("th", [
                                                        _vm._v(
                                                          "Room Rate " +
                                                            _vm._s(i + 1)
                                                        )
                                                      ]),
                                                      _vm._v(" "),
                                                      _c("td", [
                                                        _vm._v(
                                                          "\n                        ( " +
                                                            _vm._s(
                                                              _vm.currencyCode(
                                                                _vm.current
                                                                  .location
                                                              ) +
                                                                rate.price_of_one_unit
                                                            ) +
                                                            "\n                        * " +
                                                            _vm._s(
                                                              rate.number_of_rooms
                                                            ) +
                                                            " room/s )\n                        "
                                                        ),
                                                        rate.number_of_extra_occupants >
                                                        0
                                                          ? _c("span", [
                                                              _vm._v(
                                                                "\n                          + ( " +
                                                                  _vm._s(
                                                                    _vm.currencyCode(
                                                                      _vm
                                                                        .current
                                                                        .location
                                                                    ) +
                                                                      rate.extra_occupant_price
                                                                  ) +
                                                                  "\n                          * " +
                                                                  _vm._s(
                                                                    rate.number_of_extra_occupants
                                                                  ) +
                                                                  " extra occupants )\n                        "
                                                              )
                                                            ])
                                                          : _vm._e()
                                                      ])
                                                    ])
                                                  : _vm._e()
                                              })
                                            ],
                                            2
                                          )
                                        : _vm._e()
                                    }),
                                    0
                                  )
                                ]),
                                _vm._v(" "),
                                _c("tr", [
                                  _c("th", [_vm._v("Location Name")]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        item.location ? item.location.name : ""
                                      )
                                    )
                                  ])
                                ]),
                                _vm._v(" "),
                                _c("tr", [
                                  _c("th", [_vm._v("Contact Email")]),
                                  _vm._v(" "),
                                  _c("td", [_vm._v(_vm._s(item.email))])
                                ]),
                                _vm._v(" "),
                                _c(
                                  "tr",
                                  {
                                    directives: [
                                      {
                                        name: "hasPermits",
                                        rawName: "v-hasPermits:prevent",
                                        value: ["location_user"],
                                        expression: "['location_user']",
                                        arg: "prevent"
                                      }
                                    ]
                                  },
                                  [
                                    _c("th", [_vm._v("Subtotal")]),
                                    _vm._v(" "),
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.currencyCode(
                                            _vm.current.location
                                          ) + item.subtotal
                                        )
                                      )
                                    ])
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "tr",
                                  {
                                    directives: [
                                      {
                                        name: "hasPermits",
                                        rawName: "v-hasPermits:prevent",
                                        value: ["location_user"],
                                        expression: "['location_user']",
                                        arg: "prevent"
                                      }
                                    ]
                                  },
                                  [
                                    _c("th", [_vm._v("Total Discount")]),
                                    _vm._v(" "),
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.currencyCode(
                                            _vm.current.location
                                          ) + item.total_discount
                                        )
                                      )
                                    ])
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "tr",
                                  {
                                    directives: [
                                      {
                                        name: "hasPermits",
                                        rawName: "v-hasPermits:prevent",
                                        value: ["location_user"],
                                        expression: "['location_user']",
                                        arg: "prevent"
                                      }
                                    ]
                                  },
                                  [
                                    _c("th", [_vm._v("Total Fees")]),
                                    _vm._v(" "),
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.currencyCode(
                                            _vm.current.location
                                          ) + item.total_fees
                                        )
                                      )
                                    ])
                                  ]
                                ),
                                _vm._v(" "),
                                _c("tr", [
                                  _c("th", [_vm._v("Grand Total")]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          _vm.currencyCode(
                                            _vm.current.location
                                          ) + item.grand_total
                                        ) +
                                        "\n                  "
                                    ),
                                    _c(
                                      "small",
                                      {
                                        directives: [
                                          {
                                            name: "hasPermits",
                                            rawName: "v-hasPermits:allow",
                                            value: ["location_user"],
                                            expression: "['location_user']",
                                            arg: "allow"
                                          },
                                          {
                                            name: "hasPermits",
                                            rawName: "v-hasPermits:prevent",
                                            value: ["super_admin"],
                                            expression: "['super_admin']",
                                            arg: "prevent"
                                          }
                                        ]
                                      },
                                      [
                                        _vm._v(
                                          "Does not include channel added fees"
                                        )
                                      ]
                                    )
                                  ])
                                ]),
                                _vm._v(" "),
                                _c("tr", [
                                  _c("th", [_vm._v("Note")]),
                                  _vm._v(" "),
                                  _c("td", [_vm._v(_vm._s(item.notes))])
                                ])
                              ])
                            ]
                          )
                        : _vm._e()
                    }),
                    0
                  ),
                  _vm._v(" "),
                  _c("tab", { attrs: { title: "Comments" } }, [
                    _c("div", { staticClass: "margin-top-2x table-bordered" }, [
                      _vm.current
                        ? _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "hasPermits",
                                  rawName: "v-hasPermits:allow",
                                  value: {
                                    id: _vm.$route.params.id,
                                    permits: ["location_management"]
                                  },
                                  expression:
                                    "{id: $route.params.id, permits: ['location_management']}",
                                  arg: "allow"
                                },
                                {
                                  name: "hasPermits",
                                  rawName: "v-hasPermits:prevent",
                                  value: ["channel_user"],
                                  expression: "['channel_user']",
                                  arg: "prevent"
                                }
                              ],
                              staticClass: "form-group padding-2x"
                            },
                            [
                              _c("label", [_vm._v("No Show")]),
                              _vm._v(" "),
                              _c(
                                "a",
                                {
                                  attrs: { href: "#" },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.toggleShow(
                                        _vm.local_reservation
                                      )
                                    }
                                  }
                                },
                                [
                                  !_vm.no_show
                                    ? _c("i", {
                                        staticClass: "fa fa-square-o",
                                        attrs: { "aria-hidden": "true" }
                                      })
                                    : _c("i", {
                                        staticClass: "fa fa-check-square-o",
                                        attrs: { "aria-hidden": "true" }
                                      })
                                ]
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "form-group padding-left-2x padding-right-2x"
                        },
                        [
                          _vm.comments.length > 0
                            ? _c("label", [_vm._v("Comments")])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm._l(_vm.comments, function(comment, i) {
                            return _vm.comments.length > 0
                              ? _c(
                                  "div",
                                  {
                                    key: comment.id,
                                    staticClass: "margin-top-1x"
                                  },
                                  [
                                    _c("label", [
                                      _vm._v(_vm._s(comment.user_name))
                                    ]),
                                    _vm._v(" "),
                                    _c("span", [_vm._v(_vm._s(comment.time))]),
                                    _vm._v(" "),
                                    !comment.editing
                                      ? _c("div", {
                                          domProps: {
                                            innerHTML: _vm._s(comment.comment)
                                          }
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("vue-html5-editor", {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: comment.editing,
                                          expression: "comment.editing"
                                        }
                                      ],
                                      ref: "commentEditor" + comment.id,
                                      refInFor: true,
                                      attrs: {
                                        height: 100,
                                        content: comment.edit_value
                                      },
                                      on: {
                                        change: function(x) {
                                          return (comment.edit_value = x)
                                        },
                                        "update:content": function($event) {
                                          return _vm.$set(
                                            comment,
                                            "edit_value",
                                            $event
                                          )
                                        }
                                      }
                                    }),
                                    _vm._v(" "),
                                    comment.editing
                                      ? _c("div", [
                                          _c(
                                            "button",
                                            {
                                              staticClass: "btn btn-primary",
                                              class: {
                                                disabled: _vm.processing
                                              },
                                              attrs: {
                                                disabled: _vm.processing
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.updateComment(
                                                    comment.id,
                                                    i
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm.processing
                                                ? _c("span", [
                                                    _c("i", {
                                                      staticClass:
                                                        "fa fa-spinner fa-spin"
                                                    })
                                                  ])
                                                : _vm._e(),
                                              _vm._v(
                                                "\n                    Save\n                    "
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "button",
                                            {
                                              staticClass: "btn",
                                              on: {
                                                click: function($event) {
                                                  return _vm.cancelEdit(
                                                    comment.id,
                                                    i
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v("Cancel")]
                                          )
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    !comment.editing &&
                                    (_vm.isSuperAdmin ||
                                      _vm.user.id == comment.user_id)
                                      ? _c(
                                          "div",
                                          { staticClass: "margin-top-0" },
                                          [
                                            _c(
                                              "a",
                                              {
                                                attrs: { href: "#" },
                                                on: {
                                                  click: function($event) {
                                                    $event.preventDefault()
                                                    return _vm.editComment(
                                                      comment.id,
                                                      i
                                                    )
                                                  }
                                                }
                                              },
                                              [_vm._v("Edit")]
                                            ),
                                            _vm._v(" "),
                                            _c("span", [_vm._v(" . ")]),
                                            _vm._v(" "),
                                            _c(
                                              "a",
                                              {
                                                attrs: { href: "#" },
                                                on: {
                                                  click: function($event) {
                                                    $event.preventDefault()
                                                    return _vm.deleteComment(
                                                      comment.id,
                                                      i
                                                    )
                                                  }
                                                }
                                              },
                                              [_vm._v("Delete")]
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              : _vm._e()
                          }),
                          _vm._v(" "),
                          _c("hr"),
                          _vm._v(" "),
                          _c(
                            "div",
                            [
                              !_vm.typing_comment
                                ? _c("input", {
                                    ref: "newComment",
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "text",
                                      placeholder: "Add a comment..."
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.writeComment()
                                      }
                                    }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _c("vue-html5-editor", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.typing_comment,
                                    expression: "typing_comment"
                                  }
                                ],
                                ref: "commentEditor",
                                attrs: {
                                  height: 100,
                                  content: _vm.new_comment
                                },
                                on: {
                                  change: _vm.changeComment,
                                  "update:content": function($event) {
                                    _vm.new_comment = $event
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _vm.typing_comment
                                ? _c("div", [
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-primary",
                                        class: { disabled: _vm.processing },
                                        attrs: { disabled: _vm.processing },
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                            return _vm.addComment()
                                          }
                                        }
                                      },
                                      [
                                        _vm.processing
                                          ? _c("span", [
                                              _c("i", {
                                                staticClass:
                                                  "fa fa-spinner fa-spin"
                                              })
                                            ])
                                          : _vm._e(),
                                        _vm._v(
                                          "\n                    Save\n                  "
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn",
                                        on: { click: _vm.cancelComment }
                                      },
                                      [_vm._v("Cancel")]
                                    )
                                  ])
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        2
                      )
                    ])
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        2
      ),
      _vm._v(" "),
      _vm.current
        ? _c("partial-refund-modal", {
            directives: [
              {
                name: "hasPermits",
                rawName: "v-hasPermits:allow",
                value: {
                  id: _vm.current.location_id,
                  permits: ["reservations_partial_refund"]
                },
                expression:
                  "{\n      id: current.location_id, permits: ['reservations_partial_refund']\n    }",
                arg: "allow"
              }
            ],
            ref: "partialRefundModal",
            on: { close: _vm.onCloseRefundModal }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.current && _vm.current.partial_refund > 0
        ? _c("revert-partial-refund-modal", {
            directives: [
              {
                name: "hasPermits",
                rawName: "v-hasPermits:allow",
                value: ["super_admin"],
                expression: "['super_admin']",
                arg: "allow"
              }
            ],
            ref: "revertPartialRefundModal",
            on: { close: _vm.onCloseRefundModal }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.current
        ? _c("cancel-reservation-modal", {
            directives: [
              {
                name: "hasPermits",
                rawName: "v-hasPermits:prevent",
                value: ["location_user", "channel_user"],
                expression: "['location_user', 'channel_user']",
                arg: "prevent"
              }
            ],
            ref: "cancelReservationModal",
            on: { close: _vm.onCloseCancelModal }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.current
        ? _c("partial-refund-request-modal", {
            directives: [
              {
                name: "hasPermits",
                rawName: "v-hasPermits:allow",
                value: ["channel_user"],
                expression: "['channel_user']",
                arg: "allow"
              }
            ],
            ref: "partialRefundRequestModal",
            on: { close: _vm.onCloseRefundModal }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.current
        ? _c("guarantee-card", {
            directives: [
              {
                name: "hasPermits",
                rawName: "v-hasPermits:allow",
                value: {
                  id: _vm.current.location_id,
                  permits: ["guarantee_reservation_card_list"]
                },
                expression:
                  "{\n      id: current.location_id, permits: ['guarantee_reservation_card_list']\n    }",
                arg: "allow"
              }
            ],
            ref: "cardModal",
            on: { close: _vm.onCloseCardModal }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.current
        ? _c("extend-reservation-modal", {
            directives: [
              {
                name: "hasPermits",
                rawName: "v-hasPermits:allow",
                value: {
                  id: _vm.current.location_id,
                  permits: ["reservations_checkin_checkout"]
                },
                expression:
                  "{id: current.location_id, permits: ['reservations_checkin_checkout']}",
                arg: "allow"
              }
            ],
            ref: "extendReservationModal",
            attrs: { processing: _vm.checkout_processing },
            on: {
              "update-processing": _vm.updateProcessing,
              "update-reservation": _vm.updateReservation,
              close: _vm.onCloseModal
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("confirm", { ref: "confirmDialog", attrs: { type: "danger" } }),
      _vm._v(" "),
      _c("confirm", { ref: "confirmWarning", attrs: { type: "warning" } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-5ef59602", { render: render, staticRenderFns: staticRenderFns })
  }
}