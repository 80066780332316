var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "onoffswitch",
      on: {
        click: function($event) {
          $event.preventDefault()
          return _vm.$emit("change", !_vm.$refs.checkbox.checked)
        }
      }
    },
    [
      _c("input", {
        ref: "checkbox",
        staticClass: "onoffswitch-checkbox",
        attrs: { type: "checkbox" },
        domProps: { checked: _vm.value == true },
        on: {
          input: function($event) {
            return _vm.$emit("change", $event.target.checked)
          }
        }
      }),
      _vm._v(" "),
      _vm._m(0)
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      { staticClass: "onoffswitch-label", attrs: { for: "myonoffswitch" } },
      [
        _c("span", { staticClass: "onoffswitch-inner" }),
        _vm._v(" "),
        _c("span", { staticClass: "onoffswitch-switch" })
      ]
    )
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-4a0081aa", { render: render, staticRenderFns: staticRenderFns })
  }
}