var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "layout",
    { attrs: { "no-box": true, title: _vm.location ? _vm.location.name : "" } },
    [
      _vm.loading
        ? _c("div", { staticClass: "text-center" }, [
            _c("i", { staticClass: "fa fa-spinner fa-spin fa-3x fa-fw" })
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn btn-default margin-2x",
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.$router.back()
            }
          }
        },
        [_vm._v("Back")]
      ),
      _vm._v(" "),
      !_vm.loading
        ? _c("div", { staticClass: "box" }, [
            _c("div", { staticClass: "box-body" }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "hasPermits",
                      rawName: "v-hasPermits:allow",
                      value: ["parkingLeaseTypePeriod_create"],
                      expression: "['parkingLeaseTypePeriod_create']",
                      arg: "allow"
                    }
                  ],
                  staticClass: "text-right"
                },
                [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-outline-secondary",
                      attrs: { type: "button" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.openNewPeriodModal.apply(null, arguments)
                        }
                      }
                    },
                    [_vm._v("\n          New Period\n        ")]
                  )
                ]
              ),
              _vm._v(" "),
              _c("h3", [
                _vm._v(
                  " Type Name: " +
                    _vm._s(
                      _vm.parkingType.name +
                        (_vm.parkingType.type_info.rate_type.length
                          ? " (" + _vm.parkingType.type_info.rate_type + ")"
                          : "")
                    ) +
                    "  "
                )
              ]),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c("apexchart", {
                    attrs: {
                      type: "area",
                      height: "350",
                      options: _vm.chartOptions,
                      series: _vm.series
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "table-responsive" }, [
                _c("table", { staticClass: "table table-striped" }, [
                  _c("thead", [
                    _c("tr", [
                      _c("th", [_vm._v("#")]),
                      _vm._v(" "),
                      _c("th", [_vm._v("From date")]),
                      _vm._v(" "),
                      _c("th", [_vm._v("To date")]),
                      _vm._v(" "),
                      _c("th", [_vm._v("Number of spots")]),
                      _vm._v(" "),
                      _c("th", [_vm._v("Amount")]),
                      _vm._v(" "),
                      _c("th", [_vm._v("Create date")]),
                      _vm._v(" "),
                      _c(
                        "th",
                        {
                          directives: [
                            {
                              name: "hasPermits",
                              rawName: "v-hasPermits:allow",
                              value: ["parkingLeaseTypePeriod_create"],
                              expression: "['parkingLeaseTypePeriod_create']",
                              arg: "allow"
                            }
                          ]
                        },
                        [_vm._v("Edit")]
                      ),
                      _vm._v(" "),
                      _c(
                        "th",
                        {
                          directives: [
                            {
                              name: "hasPermits",
                              rawName: "v-hasPermits:allow",
                              value: ["parkingLeaseTypePeriod_create"],
                              expression: "['parkingLeaseTypePeriod_create']",
                              arg: "allow"
                            }
                          ]
                        },
                        [_vm._v("remove")]
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "tbody",
                    _vm._l(_vm.periods, function(item, i) {
                      return _c("tr", { key: item.id }, [
                        _c("td", [_vm._v(_vm._s(i))]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            _vm._s(
                              _vm._f("dateFormat")(item.from, "MM/DD/YYYY")
                            )
                          )
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            _vm._s(_vm._f("dateFormat")(item.to, "MM/DD/YYYY"))
                          )
                        ]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(item.number_of_spots))]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(item.amount))]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(item.created_at))]),
                        _vm._v(" "),
                        _c(
                          "td",
                          {
                            directives: [
                              {
                                name: "hasPermits",
                                rawName: "v-hasPermits:allow",
                                value: ["parkingLeaseTypePeriod_create"],
                                expression: "['parkingLeaseTypePeriod_create']",
                                arg: "allow"
                              }
                            ]
                          },
                          [
                            _c(
                              "a",
                              {
                                staticClass: "text-info",
                                attrs: { href: "#" },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.editPeriod(item)
                                  }
                                }
                              },
                              [
                                _c("i", {
                                  staticClass: "fa fa-pencil",
                                  attrs: { "aria-hidden": "true" }
                                })
                              ]
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          {
                            directives: [
                              {
                                name: "hasPermits",
                                rawName: "v-hasPermits:allow",
                                value: ["parkingLeaseTypePeriod_create"],
                                expression: "['parkingLeaseTypePeriod_create']",
                                arg: "allow"
                              }
                            ]
                          },
                          [
                            _c(
                              "a",
                              {
                                staticClass: "text-danger",
                                attrs: { href: "#" },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.removePeriod(item)
                                  }
                                }
                              },
                              [
                                _c("i", {
                                  staticClass: "fa fa-times",
                                  attrs: { "aria-hidden": "true" }
                                })
                              ]
                            )
                          ]
                        )
                      ])
                    }),
                    0
                  )
                ])
              ])
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("confirm", { ref: "deleteConfirm", attrs: { type: "danger" } }),
      _vm._v(" "),
      _c("period-modal", {
        ref: "periodModal",
        attrs: { type_id: _vm.type_id, type: "parking" },
        on: { refreshPeriods: _vm.refreshPeriods }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-1b9c919e", { render: render, staticRenderFns: staticRenderFns })
  }
}