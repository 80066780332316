var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "nav nav-pills nav-justified margin-bottom-4x" },
    [
      _c(
        "li",
        {
          class: { active: _vm.$route.name == "locations.rateManagement" },
          attrs: { role: "presentation" }
        },
        [
          _c(
            "router-link",
            {
              attrs: {
                to: {
                  name: "locations.rateManagement",
                  params: {
                    id: _vm.$route.params.id
                  }
                }
              }
            },
            [_vm._v("\n      Rates & Calendar\n    ")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "li",
        {
          directives: [
            {
              name: "hasPermits",
              rawName: "v-hasPermits:allow",
              value: {
                id: _vm.locId,
                permits: ["blackoutDate_create", "blackoutDate_list"]
              },
              expression:
                "{id: locId, permits: ['blackoutDate_create', 'blackoutDate_list']}",
              arg: "allow"
            }
          ],
          class: {
            active: _vm.$route.name == "locations.rateManagement.blackouts"
          },
          attrs: { role: "presentation" }
        },
        [
          _c(
            "router-link",
            {
              attrs: {
                to: {
                  name: "locations.rateManagement.blackouts",
                  params: {
                    id: _vm.$route.params.id
                  }
                }
              }
            },
            [_vm._v("\n      Blackouts\n    ")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "li",
        {
          directives: [
            {
              name: "hasPermits",
              rawName: "v-hasPermits:allow",
              value: {
                id: _vm.locId,
                permits: [
                  "locationRate_adjustment_create",
                  "longTermDiscount_create"
                ]
              },
              expression:
                "{id: locId, permits: ['locationRate_adjustment_create', 'longTermDiscount_create']}",
              arg: "allow"
            }
          ],
          class: {
            active: _vm.$route.name == "locations.rateManagement.adjustments"
          },
          attrs: { role: "presentation" }
        },
        [
          _c(
            "router-link",
            {
              attrs: {
                to: {
                  name: "locations.rateManagement.adjustments",
                  params: {
                    id: _vm.$route.params.id
                  }
                }
              }
            },
            [_vm._v("\n      Rate Adjustments\n    ")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "li",
        {
          directives: [
            {
              name: "hasPermits",
              rawName: "v-hasPermits:allow",
              value: {
                id: _vm.locId,
                permits: [
                  "locationParkingType_list",
                  "locationRoomType_list",
                  "locationBundle_list"
                ]
              },
              expression:
                "{id: locId, permits: ['locationParkingType_list', 'locationRoomType_list', 'locationBundle_list']}",
              arg: "allow"
            },
            {
              name: "hasPermits",
              rawName: "v-hasPermits:prevent",
              value: ["channel_user"],
              expression: "['channel_user']",
              arg: "prevent"
            }
          ],
          class: {
            active: _vm.$route.name == "locations.rateManagement.types"
          },
          attrs: { role: "presentation" }
        },
        [
          _c(
            "router-link",
            {
              attrs: {
                to: {
                  name: "locations.rateManagement.types",
                  params: {
                    id: _vm.$route.params.id
                  }
                }
              }
            },
            [_vm._v("\n      Rate Types\n    ")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "li",
        {
          directives: [
            {
              name: "hasPermits",
              rawName: "v-hasPermits:allow",
              value: {
                id: _vm.locId,
                permits: [
                  "locationRate_adjustment_list",
                  "longTermDiscount_list"
                ]
              },
              expression:
                "{id: locId, permits: ['locationRate_adjustment_list', 'longTermDiscount_list']}",
              arg: "allow"
            }
          ],
          class: {
            active:
              _vm.$route.name == "locations.rateManagement.adjustmentsList"
          },
          attrs: { role: "presentation" }
        },
        [
          _c(
            "router-link",
            {
              attrs: {
                to: {
                  name: "locations.rateManagement.adjustmentsList",
                  params: {
                    id: _vm.$route.params.id
                  }
                }
              }
            },
            [_vm._v("\n      Adjustments History\n    ")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-0e6d7b08", { render: render, staticRenderFns: staticRenderFns })
  }
}