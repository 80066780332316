var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "func-modal",
        {
          ref: "modal",
          attrs: { title: "New Lease period" },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function(props) {
                return [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      class: { disabled: _vm.processing },
                      attrs: { disabled: _vm.processing },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.submit(props)
                        }
                      }
                    },
                    [
                      _vm.processing
                        ? _c("i", { staticClass: "fa fa-spinner fa-spin" })
                        : _vm._e(),
                      _vm._v("\n        Save\n      ")
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-default",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return props.close()
                        }
                      }
                    },
                    [_vm._v("\n        Close\n      ")]
                  )
                ]
              }
            }
          ])
        },
        [
          _c("template", { slot: "body" }, [
            _c("form", [
              _c("label", [_vm._v("From Date")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "input-group" },
                [
                  _c(
                    "span",
                    {
                      staticClass: "input-group-addon",
                      attrs: { id: "basic-addon1" }
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-calendar",
                        attrs: { "aria-hidden": "true" }
                      })
                    ]
                  ),
                  _vm._v(" "),
                  _c("datepicker", {
                    staticClass: "form-control",
                    attrs: { "min-date": "none" },
                    model: {
                      value: _vm.from_date,
                      callback: function($$v) {
                        _vm.from_date = $$v
                      },
                      expression: "from_date"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _vm.errors.from_date
                ? _c("div", { staticClass: "text-danger" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.errors.from_date[0]) +
                        "\n        "
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("label", [_vm._v("To Date")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "input-group" },
                [
                  _c(
                    "span",
                    {
                      staticClass: "input-group-addon",
                      attrs: { id: "basic-addon1" }
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-calendar",
                        attrs: { "aria-hidden": "true" }
                      })
                    ]
                  ),
                  _vm._v(" "),
                  _c("datepicker", {
                    staticClass: "form-control",
                    attrs: { "min-date": _vm.from_date },
                    model: {
                      value: _vm.to_date,
                      callback: function($$v) {
                        _vm.to_date = $$v
                      },
                      expression: "to_date"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _vm.errors.to_date
                ? _c("div", { staticClass: "text-danger" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.errors.to_date[0]) +
                        "\n        "
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("label", [_vm._v("Number of spots")]),
              _vm._v(" "),
              _c("div", { staticClass: "input-group" }, [
                _c(
                  "span",
                  {
                    staticClass: "input-group-addon",
                    attrs: { id: "basic-addon1" }
                  },
                  [
                    _c("i", {
                      staticClass: "fa fa-usd",
                      attrs: { "aria-hidden": "true" }
                    })
                  ]
                ),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.number_of_spots,
                      expression: "form.number_of_spots"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { type: "text" },
                  domProps: { value: _vm.form.number_of_spots },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.form, "number_of_spots", $event.target.value)
                    }
                  }
                })
              ]),
              _vm._v(" "),
              _vm.errors.number_of_spots
                ? _c("div", { staticClass: "text-danger" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.errors.number_of_spots[0]) +
                        "\n        "
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("label", [_vm._v("Amount")]),
              _vm._v(" "),
              _c("div", { staticClass: "input-group" }, [
                _c(
                  "span",
                  {
                    staticClass: "input-group-addon",
                    attrs: { id: "basic-addon1" }
                  },
                  [
                    _c("i", {
                      staticClass: "fa fa-usd",
                      attrs: { "aria-hidden": "true" }
                    })
                  ]
                ),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.amount,
                      expression: "form.amount"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { type: "text" },
                  domProps: { value: _vm.form.amount },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.form, "amount", $event.target.value)
                    }
                  }
                })
              ]),
              _vm._v(" "),
              _vm.errors.amount
                ? _c("div", { staticClass: "text-danger" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.errors.amount[0]) +
                        "\n        "
                    )
                  ])
                : _vm._e()
            ])
          ])
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-39468c34", { render: render, staticRenderFns: staticRenderFns })
  }
}