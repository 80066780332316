var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "layout",
    { attrs: { "no-sidebar": true } },
    [
      _c("div", { staticClass: "page-header" }, [
        _c("h1", [_c("small", [_vm._v("Points Of Interest")])])
      ]),
      _vm._v(" "),
      !_vm.loading
        ? _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-lg-3 margin-bottom-3x" }, [
              _c("div", { staticClass: "input-group" }, [
                _c("input", {
                  ref: "search",
                  staticClass: "form-control",
                  attrs: { type: "text", placeholder: "Search for..." },
                  domProps: { value: _vm.searchKey },
                  on: {
                    keyup: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        $event.keyCode !== 13
                      ) {
                        return null
                      }
                      return _vm.search(_vm.$refs.search.value)
                    }
                  }
                }),
                _vm._v(" "),
                _c("span", { staticClass: "input-group-btn" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-default",
                      attrs: { type: "button" },
                      on: {
                        click: function($event) {
                          return _vm.search(_vm.$refs.search.value)
                        }
                      }
                    },
                    [_vm._v("Go!")]
                  )
                ])
              ])
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "hasPermits",
                    rawName: "v-hasPermits:allow",
                    value: { permits: ["point_of_interest_create"] },
                    expression: "{permits: ['point_of_interest_create']}",
                    arg: "allow"
                  }
                ],
                staticClass: "col-lg-9 text-right margin-bottom-3x"
              },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "btn btn-default",
                    attrs: {
                      to: {
                        name: "poi.new"
                      }
                    }
                  },
                  [
                    _c("i", {
                      staticClass: "fa fa-plus",
                      attrs: { "aria-hidden": "true" }
                    }),
                    _vm._v("\n        New Point Of Interest\n      ")
                  ]
                )
              ],
              1
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.loading
        ? _c("div", { staticClass: "text-center" }, [
            _c("i", { staticClass: "fa fa-spinner fa-spin fa-3x fa-fw" })
          ])
        : _c(
            "div",
            [
              _c("div", { staticClass: "table-responsive" }, [
                _c("table", { staticClass: "table table-striped" }, [
                  _c("thead", [
                    _c("tr", [
                      _c("th", [_vm._v("Name")]),
                      _vm._v(" "),
                      _c(
                        "th",
                        {
                          directives: [
                            {
                              name: "hasPermits",
                              rawName: "v-hasPermits:allow",
                              value: { permits: ["point_of_interest_update"] },
                              expression:
                                "{permits: ['point_of_interest_update']}",
                              arg: "allow"
                            }
                          ]
                        },
                        [_vm._v("Edit")]
                      ),
                      _vm._v(" "),
                      _c(
                        "th",
                        {
                          directives: [
                            {
                              name: "hasPermits",
                              rawName: "v-hasPermits:allow",
                              value: { permits: ["point_of_interest_delete"] },
                              expression:
                                "{permits: ['point_of_interest_delete']}",
                              arg: "allow"
                            }
                          ],
                          staticClass: "text-right"
                        },
                        [_vm._v("Remove")]
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "tbody",
                    _vm._l(_vm.poi.data, function(item, i) {
                      return _c("tr", [
                        _c("td", [
                          _vm._v(
                            "\n              " +
                              _vm._s(item.name) +
                              "\n            "
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "td",
                          {
                            directives: [
                              {
                                name: "hasPermits",
                                rawName: "v-hasPermits:allow",
                                value: {
                                  permits: ["point_of_interest_update"]
                                },
                                expression:
                                  "{permits: ['point_of_interest_update']}",
                                arg: "allow"
                              }
                            ]
                          },
                          [
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to: {
                                    name: "poi.edit",
                                    params: { id: item.id }
                                  }
                                }
                              },
                              [
                                _c("i", {
                                  staticClass: "fa fa-pencil",
                                  attrs: { "aria-hidden": "true" }
                                })
                              ]
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          {
                            directives: [
                              {
                                name: "hasPermits",
                                rawName: "v-hasPermits:allow",
                                value: {
                                  permits: ["point_of_interest_delete"]
                                },
                                expression:
                                  "{permits: ['point_of_interest_delete']}",
                                arg: "allow"
                              }
                            ],
                            staticClass: "text-right"
                          },
                          [
                            _vm.poi_loadings[item.id]
                              ? _c("span", [
                                  _c("i", {
                                    staticClass: "fa fa-spinner fa-spin fa-fw"
                                  })
                                ])
                              : _c(
                                  "a",
                                  {
                                    staticClass: "text-danger",
                                    attrs: { href: "#" },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        return _vm.removePOI(item)
                                      }
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "fa fa-times",
                                      attrs: { "aria-hidden": "true" }
                                    })
                                  ]
                                )
                          ]
                        )
                      ])
                    }),
                    0
                  )
                ])
              ]),
              _vm._v(" "),
              _c("pagination", {
                attrs: { "pages-number": _vm.poi.last_page },
                on: {
                  pageChanged: function(page) {
                    return _vm.loadPage(page)
                  }
                }
              })
            ],
            1
          ),
      _vm._v(" "),
      _c("confirm", { ref: "deleteConfirm", attrs: { type: "danger" } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-7e313be2", { render: render, staticRenderFns: staticRenderFns })
  }
}