import toastr from 'toastr'
import { funcModal } from 'components/modals'
import { dateTime } from 'services'

const components = {
    funcModal
}

const methods = {
    open (location, roomComponent) {
        this.location = location;
        this.roomComponent = roomComponent;
        this.$nextTick(() => {
            this.$refs.modal.open();
        });
    },

    submit (modal) {
        this.processing = true;
        this.$store.dispatch('locations.edit', {
            id: this.$route.params.id,
            data: this.location
        }).then(() => {
            if (this.$store.state.locations.errors) {
                this.errors = _.cloneDeep(this.$store.state.locations.errors.errors);
            } else {
                toastr.success('Data has been saved');
                modal.close();
                this.roomComponent.close();
            }
            this.processing = false;
        })
    }
}

export default {
    components,
    methods,
    data () {
        return {
            errors: {},
            processing: false,
            roomComponent: null,
            location: null,
            times: dateTime.getHalfHours(),
        }
    }
}