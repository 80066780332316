import toastr from 'toastr'
import { hasPermits } from 'directives'
import { permits } from 'services'
import { funcModal } from 'components/modals'

const components = {
  funcModal
}

const directives = {
  hasPermits
}

const computed = {
  roles () {
    return this.$store.state.roles.all;
  },

  form () {
    return {
      role: this.userData.role ?
      this.userData.role.id : null,
      user_id: this.userData.user_id
    }
  },

  user () {
    return this.userData.user || {};
  },

  country () {
    let countries = this.$store.state.address.countries;
    for (let i in countries) {
      if (countries[i].id == this.user.country_id) {
        return countries[i];
      }
    }
    return {};
  },

  state () {
    for (let i in this.country.states) {
      if (this.country.states[i].id == this.user.state_id) {
        return this.country.states[i];
      }
    }
    return {};
  }
}

const methods = {
  submit (modal) {
    this.errors = {}

    this.processing = true;
    this.form.role = this.role;
    this.$store.dispatch('locations.upsertEmployee', {id: this.$route.params.id, data: this.form})
    .then(() => {
      this.processing = false;
      let errors = this.$store.state.locations.errors;
      if (errors) {
        if (errors.errors) {
          this.errors = _.cloneDeep(errors.errors);
        }
        toastr.error(errors.message);
      } else {
        this.$refs.modal.close();
      }
    });
  },
  open () {
    this.$refs.modal.open();
  }
}

const watch = {
  userData (n) {
    if (n.roles.length) {
      this.role = n.roles[0].id
    } else {
      this.role = null;
    }
  }
}

export default {
  name: 'edit-employee-modal',
  props: ['userData'],
  computed,
  components,
  directives,
  methods,
  watch,
  data () {
    // let role = null;
    // if (!_.isEmpty(this.userData)) {
    //   role = this.userData.roles[0].id
    // }

    return {
      // loading: true,
      processing: false,
      errors: {},
      locId: this.$route.params.id,
      role: null
    }
  },
  created () {
    if (permits.hasLocationPermits(this.$route.params.id, [
      'locationEmployee_update'
    ])) {
      this.$store.dispatch('roles.getAll', {
        type: 'location',
        _all: true,
        location_id: this.$route.params.id
      }).then(() => {
        this.loading = false;
      });
    }

  }
}
