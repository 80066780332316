
const methods = {
  reload () {
    $(this.$refs.input).timepicker().update(this.onChange);
  },
  onChange () {
    let value= $(this.$refs.input).val();
    this.$emit('input', value)
  },
}

const watch = {
}

const computed = {
}

export default {
  name: 'timepicker',
  props: ['value'],
  methods,
  watch,
  computed,
  mounted () {
    this.reload();
  }
}
