import _ from 'lodash'
import toastr from 'toastr'
import { funcModal } from 'components/modals'
import moment from 'moment'
import { Captcha } from 'services'

const components = {
  funcModal
}

const methods = {
  async submit (email, password) {
    this.errors = {};
    this.loading = true;
    let token = await Captcha.execute('login');
    this.$store.dispatch('user.login', {email, password, token}).then(() => {
      this.loading = false;
      let errors = this.$store.state.user.errors;
      if(errors) {
        if (errors.errors) {
          this.errors = _.cloneDeep(errors.errors);
        }
        toastr.error(errors.message);
      } else {
        if (this.$route.query.goto) {
          let {href} = this.$router.resolve(this.$route.query.goto);
          console.log('hreffffff---', href);
          
          window.location.href = href;
        } else {
          this.$router.push({ name: 'home'});
        }
      }
    });
  },

  openSendEmail () {
    this.$refs.forgotPass.open();
  },

  closeSendEmail () {
    this.forgotPass.email = null;
    this.errors = {};
  },

  submitSendEmail (modal) {
    this.errors = {};
    this.processing = true;
    this.$store.dispatch('user.forgotPassword', this.forgotPass.email).then(() => {
      this.processing = false;
      let errors;
      if (errors = this.$store.state.user.errors) {
        this.errors = errors.errors;
      } else {
        this.forgotPass.email = null;
        toastr.success("Please check your email");
        modal.close();
      }
    });
  }
}

export default {
  name: "login-layout",
  methods,
  components,
  data () {
    return {
      errors: {},
      forgotPass: {
        email: null
      },
      loading: false,
      processing: false,
      captchaLoaded: false,
      year: moment().format('YYYY')
    }
  },
  mounted () {
    Captcha.init();

    window.addEventListener('google_recaptcha_loaded', () => {
      Captcha.ready(() => {
        this.captchaLoaded = true;
      })
  });
  },
  created () {
  }
}
