import toastr from 'toastr'
import { hasPermits } from 'directives'
import { editableText, editableRadio, editableCheckbox } from 'components/editable'
import { confirm } from 'components/modals'
import newFee from './newFee'

const components = {
  newFee,
  editableText,
  editableRadio,
  editableCheckbox,
  confirm
}

const directives = {
  hasPermits
}

const computed = {
  fees () {
    return _.cloneDeep(this.$store.state.locations.active.fees);
  }
}

const methods = {
  onEditFee (editableData, item, key) {
    console.log('data, item', editableData, item);
    item[key] = editableData.value;
    editableData.startLoading();
    if (key == 'calculation') {
      if (item.calculation == 'one_time') {
        item.type = 'fixed';
      }
    }
    this.$store.dispatch('locations.updateFee', {
      locId: this.$route.params.id,
      id: item.id, data: item
    }).then(() => {
      editableData.stopLoading();
      let errors;
      if (this.$store.state.locations.errors) {
        errors = this.$store.state.locations.errors.errors;
        _.forEach(errors, (error) => {
          toastr.error(error[0]);
        })
      } else {
        editableData.close();
      }
    })
  },

  removeFee (id) {
    this.$refs.confirmDelete.confirm('Are you sure?', () => {
      this.loadings[id] = true;
      this.loadings = _.cloneDeep(this.loadings);
      this.$store.dispatch('locations.removeFee', {
        locId: this.$route.params.id,
        id
      }).then(() => {
        this.loadings[id] = false;
        let errors;
        if (this.$store.state.locations.errors) {
          errors = this.$store.state.locations.errors.errors;
          console.log('errors', errors);
          _.forEach(errors.errors, (error) => {
            toastr.error(error[0]);
          })
        } else {
          toastr.success('fee removed successfully');
        }
      });
    })
  }
}

export default {
  name: 'location-fees',
  components,
  directives,
  computed,
  methods,
  data () {
    return {
      loadings: {},
      locId: this.$route.params.id,
    }
  }
}
