import layout from 'layouts/default'
import validateReservation from './validate'
import checkin from './checkin'
import checkout from './checkout'
import { hasPermits } from 'directives'
import moment from 'moment'
import datepicker from 'components/datepicker'

const directives = {
  hasPermits
}

const components = {
  validateReservation,
  checkin,
  checkout,
  layout,
  datepicker,
}

const computed = {
  errors () {
      let errors = _.cloneDeep(this.$store.state.dashboards.errors);
      return errors && errors.errors && errors.errors.date && errors.errors.date[0] ? 'This date is not valid' : '';
  }
}

const methods = {
  prevDay (e) {
    e.preventDefault();
    this.selected_date = moment(this.selected_date, ['MM/DD/YYYY']).subtract(1, 'days').format('MM/DD/YYYY');
  },
  nextDay (e) {
    e.preventDefault();
    this.selected_date = moment(this.selected_date, ['MM/DD/YYYY']).add(1, 'days').format('MM/DD/YYYY');
  },
  goBackToToday () {
    this.selected_date = moment().format('MM/DD/YYYY');
  }
}
export default {
  name: 'location-dashboard',
  methods,
  components,
  computed,
  directives,
  data () {
    let selected_date = moment().format('MM/DD/YYYY');
    return {
      locations: [],
      selected_location: null,
      selected_date,
      // errors: {},
    }
  },
  created () {
    this.$store.dispatch('locations.get', this.$route.params.id).then(() => {
      this.selected_location = this.$store.state.locations.active;
    });
  }
}
