import objectPath from 'object-path'
import pagination from 'components/pagination'
import { funcModal, reservationDetails } from 'components/modals'
import { http } from 'services'
import toastr from 'toastr'
import { hasPermits } from 'directives'

const components = {
  reservationDetails,
  funcModal,
  pagination
}

if (!isServer) {
  components.JsonViewer = require('vue-json-viewer').default;
}

const directives = {
  hasPermits
}

export default {
  name: 'datatable',
  components,
  directives,
    props: {
    ajaxUrl: {
      type: String,
      default: null
    },
    tableClass: {
      type: String,
      default: 'table'
    },
    columns: [Object, Array],
    data: [Object, Array, String, Function],
    filters: {
      type: [Object, Array],
      default: null
    },
    sort: {
      type: Function,
    },
    page: {
      type: Number,
    }
  },
  data() {
    return {
      sort_by: null,
      sort_dir: null,
      rows: [],
      current_page: 1,
      pages: 1,
      objectPath,
      loading: false,
      activeReservation: null,
      loadings: {},
      url: this.ajaxUrl,
    }
    },
  computed: {
    numberOfPages: () => {
      return 10;
    }
  },
  watch: {
    filters: function(value) {
      this.buildRows()
    },
    columns (n, o) {
      if (n == o) return;

      this.setCols();
    }
  },
  methods: {
    _sort(object_name, sortable) {

      if (!sortable) {
        return;
      }

      this.$emit('sortChanged', this.sort_by, this.sort_dir)

      if (this.sort_by == object_name) {
        this.sort_dir = this.sort_dir == 'asc' ? 'desc' : 'asc';
      } else {
        this.sort_by = object_name
        this.sort_dir = 'asc'
      }

      if (this.ajaxUrl) {
        this.buildRows()
        return;
      }

      return _.orderBy(this.rows, [this.sort_by], [this.sort_dir]);
    },

    showHiddenItems (r) {
      $(`#row${r} .jv-container`).css('max-height', 'unset');
      $(`.ellipsis-btn-${r}`).css('display', 'none');
    },

    heightOver( r, c) {
      setTimeout(() => {
        let content = document.querySelector(`.audits #column${r}-${c} .jv-container`);
        if (content) {
          if (content.offsetHeight < content.scrollHeight) {
            $(`#ellipsis-btn-${r}${c}`).css('display', 'flex');
          } else {
            $(`#ellipsis-btn-${r}${c}`).css('display', 'none');
            $(`#column${r}-${c} .jv-container`).css({'max-height': 'unset', 'overflow-y': 'auto'});
          }
        }
      }, 100);
      return true    
    },

    buildRows() {
      if (this.ajaxUrl) {
        this.getDataFromAjax()
      } else {
        this.rows = this.data;
      }
    },

    loadPage(page) {
      this.current_page = page
      this.getDataFromAjax();
    },

    loadReservation (reservation_number) {
      this.$set(this.loadings, reservation_number, true);
      this.$store.dispatch('reservations.get', reservation_number).then(() => {
        this.$set(this.loadings, reservation_number, false);
        this.activeReservation = _.cloneDeep(this.$store.state.reservations.active);
        this.$nextTick(() => {
          this.$refs.reservationDetailsModal.open();
        })
      })
    },

    getDataFromAjax() {

      // Build get params
      var params = ''
      if (this.ajaxUrl.indexOf('?') > -1) {
        params = '&page=' + this.current_page
      } else {
        params = '?page=' + this.current_page
      }

      if (this.sort_by) {
        params += '&_sort=' + (this.sort_dir == 'desc' ? '-' : '') + this.sort_by;
      }

      if (this.filters) {
        _.each(this.filters, function(value, key) {
          if (value)
            params += '&' + (key + '=' + value);
        });
      }

      this.loading = true;
      this.url = this.ajaxUrl + params;
      http.get(this.url)
      .then ((res) => {
        this.loading = false
        this.rows = res.data.data
        this.pages = res.data.last_page
      }).catch((err) => {
        if (err.response.data.errors) {
          this.errors = err.response.data.errors;
          this.$emit('datatableErrors', this.errors);
        }
        if (err.response.data.message) {
          toastr.error(err.response.data.message);
        }
        this.rows = [];
        this.loading = false
      })
    },
    setCols () {
      for (let i in this.columns) {
        if (!_.isFunction(this.columns[i].click)) {
          this.columns[i].click = () => {};
        }
        if (!_.isFunction(this.columns[i].hover)) {
          this.columns[i].hover = () => {};
        }
        if (!this.columns[i].hasOwnProperty('visible')) {
          this.columns[i].visible = true;
        }
      }
    }
    },
    created(){
    this.buildRows()
    this.setCols();
    }
}
