var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "layout",
    { attrs: { "no-box": true, title: _vm.location ? _vm.location.name : "" } },
    [
      _c("tabs"),
      _vm._v(" "),
      _c("div", { staticClass: "box" }, [
        _c("div", { staticClass: "box-body" }, [
          _vm.location.parking_types && _vm.location.room_types
            ? _c("div", [
                !_vm.location.parking_types.length &&
                !_vm.location.room_types.length
                  ? _c("div", [
                      _c(
                        "div",
                        {
                          staticClass: "alert alert-warning",
                          attrs: { role: "alert" }
                        },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "close",
                              attrs: {
                                type: "button",
                                "data-dismiss": "alert",
                                "aria-label": "Close"
                              }
                            },
                            [
                              _c("span", { attrs: { "aria-hidden": "true" } }, [
                                _vm._v("×")
                              ])
                            ]
                          ),
                          _vm._v(
                            "\n          No rate types have been defined, set up rate types\n          "
                          ),
                          _c("strong", [
                            _c(
                              "u",
                              [
                                _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to: {
                                        name: "locations.rateManagement.types",
                                        params: {
                                          id: _vm.$route.params.id
                                        }
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                here\n              "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ])
                        ]
                      )
                    ])
                  : _vm._e()
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "margin-top-5x" }, [
            _c("div", { staticClass: "row margin-bottom-5x" }, [
              _c(
                "div",
                { staticClass: "col-sm-3" },
                [
                  _c("bulk-upload", {
                    directives: [
                      {
                        name: "hasPermits",
                        rawName: "v-hasPermits:allow",
                        value: {
                          id: _vm.locId,
                          permits: ["locationRate_bulk_upload"]
                        },
                        expression:
                          "{id: locId, permits: ['locationRate_bulk_upload']}",
                        arg: "allow"
                      }
                    ],
                    staticClass: "margin-bottom-3x pull-left margin-right-1x",
                    on: {
                      uploadCompleted: function($event) {
                        return _vm.refreshRates()
                      }
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-sm-9 text-right" },
                [
                  _c("room-rate-modal", {
                    directives: [
                      {
                        name: "hasPermits",
                        rawName: "v-hasPermits:allow",
                        value: {
                          id: _vm.locId,
                          permits: ["channelRoomRate_create"]
                        },
                        expression:
                          "{id: locId, permits: ['channelRoomRate_create']}",
                        arg: "allow"
                      }
                    ],
                    on: {
                      rateSaved: function($event) {
                        return _vm.refreshRates()
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("parking-rate-modal", {
                    directives: [
                      {
                        name: "hasPermits",
                        rawName: "v-hasPermits:allow",
                        value: {
                          id: _vm.locId,
                          permits: ["channelParkingRate_create"]
                        },
                        expression:
                          "{id: locId, permits: ['channelParkingRate_create']}",
                        arg: "allow"
                      }
                    ],
                    on: {
                      rateSaved: function($event) {
                        return _vm.refreshRates()
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("bundle-rate-modal", {
                    directives: [
                      {
                        name: "hasPermits",
                        rawName: "v-hasPermits:allow",
                        value: {
                          id: _vm.locId,
                          permits: ["locationBundleRate_create"]
                        },
                        expression:
                          "{id: locId, permits: ['locationBundleRate_create']}",
                        arg: "allow"
                      }
                    ],
                    on: {
                      bundleSaved: function($event) {
                        return _vm.refreshRates()
                      }
                    }
                  })
                ],
                1
              )
            ]),
            _vm._v(" "),
            !_vm.loading
              ? _c("div", [_c("rate-views", { ref: "rateReviews" })], 1)
              : _vm._e(),
            _vm._v(" "),
            _vm.loading
              ? _c("div", { staticClass: "text-center" }, [
                  _c("i", { staticClass: "fa fa-spinner fa-spin fa-3x fa-fw" })
                ])
              : _vm._e()
          ])
        ])
      ]),
      _vm._v(" "),
      _c("confirm", { ref: "confirmDelete", attrs: { type: "danger" } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-1a851674", { render: render, staticRenderFns: staticRenderFns })
  }
}