import toastr from 'toastr'

const components = {
}

const directives = {

}

const computed = {
  channelRequests() {
    return _.cloneDeep(this.$store.state.reports.channelRequests);
  } 
}

const methods = {
  displayErrors (errors) {
    this.$emit('checkinReportErrors',errors);
  }
}

export default {
  name: 'reports-channel_requests',
  props: ['url'],
  computed,
  components,
  directives,
  methods,
  data () {
    return {
      processing: false,
      errors: {},
      rows: [],
      columns: []
    }
  },
  created () {

    this.processing = true;
    this.$store.dispatch('reports.getChannelRequests', this.url).then(() => {
      this.processing = false;
      if (this.$store.state.reports.errors) {
        toastr.error(this.$store.state.reports.errors.message);
      } else {
        this.columns = this.channelRequests.slice(0, 1)[0];
        this.rows = this.channelRequests.slice(1);
      }

    });
  },

  beforeDestroy () {
    this.$store.dispatch('reports.resetReports');
  }
}
