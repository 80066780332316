import toastr from 'toastr'
import { http } from 'services'
import { funcModal } from 'components/modals'
import env from 'constants/env'

const components = {
    funcModal
}

const methods = {
    open (url,ext) {
        this.url = url;
        this.ext = ext;
        this.complete = false;
        this.canDownloadAgain = false;
        this.percent = 0;
        this.$refs.modal.open();
        this.exportReport(ext);
    },

    onClose () {
        this.finish = true;
        this.$emit('close');
    },

    closeExport () {
        this.finish = true;
        this.$refs.modal.close();
    },

    async exportReport(ext) {
        http.get(this.url, {
        params: {download: true, ext},
        responseType: 'arraybuffer'
        }).then((res) => {
            let contentType = res.headers['content-type'];
            if (contentType.indexOf('application/csv') > -1 || contentType.indexOf('application/pdf') > -1 || contentType.indexOf('application/xlsx') > -1) {
                this.downloadFileFromArrayBuffer(res, ext);
                this.closeExport();
            } else {
                let jsonResult = JSON.parse(String.fromCharCode.apply(null, new Uint8Array(res.data)));
                if (jsonResult.type == 'export') {
                    this.canDownloadAgain = true;
                    this.file_name = jsonResult.file_name;
                    this.file_token = jsonResult.file_token;
                    this.getFileForDownload(jsonResult.file_name, ext, this.file_token);
                }
            }
        }).catch((err) =>{
            this.complete = true;
            this.closeExport();
            let decodedString = String.fromCharCode.apply(null, new Uint8Array(err.response.data));
            var error_obj = JSON.parse(decodedString);
            toastr.error(error_obj.message);
        });
    },

    async getFileForDownload (file_name, ext, token) {
        this.finish = false;
        let times = 0;
        let hasError = false;
        this.complete = false;
        this.fileReady = false;
        while(!this.finish && !hasError && times < 900) {

            let reportStatusUrl = `reports/check-report-status/${file_name}?file_token=${token}`;
            http.get(reportStatusUrl).then((res) => {
                let reportStatus = res.data;
                this.percent = reportStatus.percent;
                if (reportStatus.complete) {
                    this.fileReady = true;
                    this.finish = true;
                }
            }).catch((err) =>{
                hasError = true;
                this.complete = true;
                console.log('err',err);
            });

            if (!this.finish && !hasError) {
                await this.sleep(2000);
                times++;
            }
        }

        if (this.fileReady) {
            let reportStatusUrl = `reports/check-report-status/${file_name}?download=true&file_token=${token}`;
            let downloadUrl = env.default.api + reportStatusUrl;
            this.complete = true;
            this.downloadLink = downloadUrl;
            document.getElementById('downlaod_iframe').src = downloadUrl;
        }
    },

    downloadFileFromArrayBuffer(res, ext) {
        console.log('download file');
        const blob = new Blob([res.data], { type: `application/${ext}; charset=UTF-8` });
        blob.name = 'report.' + ext;
        const reader = new FileReader();
        reader.onload = e => {
            const anchor = document.createElement('a');
            anchor.style.display = 'none';
            anchor.href = e.target.result;
            anchor.download = blob.name;
            anchor.click();
        };
        reader.readAsDataURL(blob);
        this.complete = true;
    },

    sleep (ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    },
}

export default {
    name: 'report-export-modal',
    methods,
    components,
    data () {
        return {
            processing: false,
            file_name: null,
            file_token: "",
            url: '',
            ext: '',
            finish: false,
            complete: false,
            canDownloadAgain: false,
            fileReady: false,
            downloadLink: '',
            percent: 0,
        }
    }
}