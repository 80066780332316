import toastr from 'toastr'
import { hasPermits } from 'directives'
import { editableText } from 'components/editable'

const directives = { 
  hasPermits
}

const watch = {

}

const components = {
  editableText,
  toastr
}

const methods = {
	commissionChanged(event, index, commission_field) {
    let errors = {};
    this.$emit('throwErrors', errors);
    if (isNaN(event.value)) {
      let key = `channels.${index}.${commission_field}`;
      errors[key] = [];
      errors[key].push("Comission value can only be a number")
      this.$emit('throwErrors', errors);
      return;
    }
		let value = Number(event.value);
		this.form.channels[index][commission_field] = value;
    let data = _.cloneDeep(this.form);
    //data.channels = _.cloneDeep(this.channels);

    event.startLoading();
    data.due_at_location = data.due_at_location ? 1 : 0;
    data.printed_receipt = data.printed_receipt ? 1 : 0;
    this.$store.dispatch('locations.edit', {id: this.$route.params.id, data}).then(() => {

      if (this.$store.state.locations.errors) {
        errors = _.cloneDeep(this.$store.state.locations.errors.errors);
        this.$emit('throwErrors', errors)
        toastr.error(this.$store.state.locations.errors.message);
        event.stopLoading();
        event.close();
      } else {
        this.$store.dispatch('locations.getChannels', this.$route.params.id)
        .then(() => {
          toastr.success('Data has been saved');
          event.stopLoading();
          event.close();
        })
      }
    })

	},
}
export default {
  name: "commissions-tab",
  props: ['form', 'errors'],
  methods,
  components,
  directives,
  data () {
  	return {
  		channels: this.form.channels,
  	}
  },

	mounted () {
		console.log(this.form.channels);
	}

}
