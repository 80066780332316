import toastr from 'toastr'

const components = {
  
}

const filters = {
}

const computed = {
}

const watch = {
  formActive () {
    this.errors = {};
  }
}

const methods = {
  submit () {
    this.processing = true;
    let data = _.cloneDeep(this.form);
    this.errors = {};

    this.$store.dispatch('locationEmailSchedule.new', data).then(() => {
      let errors = _.cloneDeep(this.$store.state.locationEmailSchedule.errors);
      if (errors) {
        this.errors = errors.errors;
        toastr.error(errors.message);
      } else {
        toastr.success('Schedule added successfully');
        this.formActive = false;
        this.clearForm()
      }
      this.processing = false;
    });
  },

  clearForm () {
    this.form.reports_name = [];
    this.form.type = 'week';
    this.form.day = 0;
    this.form.hour = '00';
  },

  
}

export default {
  name: 'add-location-email-schedule',
  props: ['location_email_id'],
  computed,
  methods,
  components,
  filters,
  watch,
  data () {
    return {
      formActive: false,
      loading: false,
      processing: false,
      days: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'],
      hours: [
        "12:00 AM", "01:00 AM", '02:00 AM', '03:00 AM', 
        '04:00 AM', '05:00 AM', '06:00 AM', '07:00 AM', 
        '08:00 AM', '09:00 AM', '10:00 AM', '11:00 AM',
        '12:00 PM', '01:00 PM', '02:00 PM', '03:00 PM',
        '04:00 PM', '05:00 PM', '06:00 PM', '07:00 PM',
        '08:00 PM', '09:00 PM', '10:00 PM', '11:00 PM'
      ],
      reports: {
        "commission_pickup": "Commission report by Pickup date",
        "commission_booking": "Commission report by booking date"
      },
      form: {
        reports_name: ['commission_pickup'],
        type: 'week',
        day: 0,
        hour: '00',
        location_id: this.$route.params.id,
        location_email_id: this.location_email_id
      },
      errors: {},
    }
  }
}
