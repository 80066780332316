import _ from 'lodash'

const computed = {
  pagesRange () {
    let arr = [], current, start, end;
    current = this.current || 1;
    start = current - 5 > 0 ? current - 5 : 1;
    end = current + 5 <= this.pagesNumber ? current + 5 : this.pagesNumber;
    for(let i = start; i <= end; i++) {
      arr.push(i);
    }
    return arr;
  },
  current () {
    if(this.changeRoute) {
      return parseInt(this.$route.query.page) || 1
    } else {
      return this.page
    }
  }
}

const methods = {
  goTo (page, $event) {
    if (page && page <= this.pagesNumber && page > 0) {
      this.page = page
      this.$emit('pageChanged', this.page, this.perPage)
    } else if(this.showPerPage){
      this.page = 1;
      this.$emit('pageChanged', this.page, this.perPage)
    }
    return
  },
  perPageChanged() {

  }
}

export default {
  name: 'pagination',
  data () {
    return {
      page: 1,
      perPageList: [15, 50 ,100],
      perPage: 15,
    }
  },
  props: {
    pagesNumber: {
      type: Number,
      required: true
    },
    changeRoute: {
      type: Boolean,
      default: true
    },
    showPerPage: {
      type: Boolean,
      default: false
    }
  },
  computed,
  methods
}
