import toastr from 'toastr'
import moment from 'moment'
import layout from 'layouts/default'
import { dateFormat } from 'filters'
import datepicker from 'components/datepicker'
import pagination from 'components/pagination'
import tabs from '../tabs'
import { hasPermits } from 'directives'
import { permits } from 'services'
import manualAdjustment from './manualAdjustment'
import dynamicAdjustment from './dynamicAdjustment'
import confirm from 'components/modals/confirm'

const directives = {
  hasPermits
}

const components = {
  layout,
  datepicker,
  manualAdjustment,
  dynamicAdjustment,
  pagination,
  confirm,
  tabs
}

const filters = {
  dateFormat
}

const computed = {
  location () {
    return _.cloneDeep(this.$store.state.locations.active)
  },
  roomTypes () {
    let data = _.cloneDeep(this.$store.state.locations.active.roomTypes);
    if (this.isLocationUser()) {
      data = data.filter(function (rate) { return rate['rate_type'] !== 'L'; });
    }
    return data;
  },
  parkingTypes () {
    let data = _.cloneDeep(this.$store.state.locations.active.parkingTypes);
    if (this.isLocationUser()) {
      data = data.filter(function (rate) { return rate['rate_type'] !== 'L'; });
    }
    return data;
  },
  bundleTypes () {
    let data = _.cloneDeep(this.$store.state.locations.active.bundleTypes);
    if (this.isLocationUser()) {
      data = data.filter(function (rate) { return rate['rate_type'] !== 'L'; });
    }
    return data;
  },

  dynamicAdjustments () {
    return _.cloneDeep(this.$store.state.rateManagement.dynamic_adjustments);
  },
  hasSelectChannelsPermit () {
    return permits.hasLocationPermit(this.$route.params.id, 'location_channel_select');
  }
}

const watch = {
  dynamicAdjustments: {
    handler(newVal) {
      // Deep clone the new value and assign it to a local data property
      this.dynamicAdjustmentsData = _.cloneDeep(newVal);
    },
    // Deep watch to watch for changes in nested properties
    deep: true
  }
}

const methods = {
  showManualAdjustment() {
    this.showNewManualAdjustment = !this.showNewManualAdjustment;
    this.showNewDynamicAdjustment = false;
  },

  showDynamicAdjustment() {
    this.showNewDynamicAdjustment = !this.showNewDynamicAdjustment;
    this.showNewManualAdjustment = false;
  },

  dynamicAdjustmentAdded() {
    this.showNewDynamicAdjustment = false;
  },

  dynamicAdjustmentUpdated() {
    this.showNewDynamicAdjustment = false;
    this.activeDynamicAdjustment = null;
  },
  
  cancelEdit() {
    this.showNewManualAdjustment = false;
    this.showNewDynamicAdjustment = false;
    this.disableNew = false;
    this.dynamicAdjustmentTitle = "New Dynamic adjustment";
    this.activeDynamicAdjustment = null;
  },

  editDynamicAdjustment(dynamicAdjustment) {
    this.showNewManualAdjustment = false;
    this.showNewDynamicAdjustment = false;
    this.disableNew = true;
    this.activeDynamicAdjustment = dynamicAdjustment;
    
    this.dynamicAdjustmentTitle = "Edit Dynamic adjustment";
    this.$nextTick(() => {
      this.showNewDynamicAdjustment = true;
      $('html, body').animate({
        scrollTop: $("#adjustmentsDiv").offset().top
      }, 200);
    });
  },

  getDynamicAdjustments (page) {
    this.$route.query.page = page;
    this.loading = true;
    this.$store.dispatch('rateManagement.getDynamicAdjustments', {
      page,
      location_id: this.$route.params.id,
      from: this.from_date ? moment(this.from_date, ['MM/DD/YYYY']).format('YYYY-MM-DD HH:mm:ss') : null,
      to: this.to_date ? moment(this.to_date, ['MM/DD/YYYY']).format('YYYY-MM-DD HH:mm:ss') : null
    }).then(() => {
      this.loading = false;
    });
  },

  removeDynamicAdjustment (index, dynamicAdjustment) {
    this.$refs.deleteConfirm.confirm('are you sure that you want to delete this Dynamic Adjustments?',() => {
        this.loadings[dynamicAdjustment.id] = true;
        this.loadings = _.cloneDeep(this.loadings);
  
        this.$store.dispatch('rateManagement.removeDynamicAdjustment', {
          location_id: this.$route.params.id,
          rate_adjustment_id: dynamicAdjustment.id
        }).then(() => {
          this.loadings[dynamicAdjustment.id] = false;
          let errors;
          if (errors = this.$store.state.rateManagement.errors) {
            if (errors.message) {
              toastr.error(errors.message);
            } else {
              toastr.error(errors.error);
            }
          }
        });
      });
  },

  isLocationUser () {
    return !permits.hasPermit('super_admin') && permits.hasPermit('location_user');
  },
}

export default {
  name: 'rate-modifications',
  components,
  filters,
  computed,
  methods,
  watch,
  directives,
  data () {
    return {
      loading: true,
      loadings: [],
      processing: false,
      from_date: null,
      to_date: null,
      dynamicAdjustmentTitle: 'New Dynamic adjustment',
      showNewManualAdjustment: false,
      showNewDynamicAdjustment: false,
      disableNew: false,
      activeDynamicAdjustment: null,
      errors: {},
      locId: this.$route.params.id,
      type: "adjustment",//adjustment, long_term, adjustment_bundles
    }
  },
  created () {

    if (permits.hasLocationPermit(this.locId, 'locationRate_adjustment_create')) {
      this.type = 'adjustment'
    } else if (permits.hasLocationPermit(this.locId, 'longTermDiscount_create')) {
      this.type = 'long_term'
    }

    Promise.all([
      this.$store.dispatch('locations.get', this.$route.params.id),
      this.$store.dispatch('locations.parkingTypes', {id: this.$route.params.id}),
      this.$store.dispatch('locations.roomTypes', {id: this.$route.params.id}),
      this.$store.dispatch('locations.bundleTypes', {id: this.$route.params.id}),
      this.$store.dispatch('locations.getChannels', this.$route.params.id),
      // this.$store.dispatch('rateManagement.getDynamicAdjustments', this.$route.params.id),
      this.getDynamicAdjustments(1),
    ]).then(() => {
      this.loading = false;
      if (this.isLocationUser()) {
        this.showNewManualAdjustment = true;
      } 
    });
  }
}
