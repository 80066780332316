import {
  rateModifications, rateModificationsList,
  rateManagement, rateTypes, blackouts
  ,parkingLease
} from 'pages/locations'
import { editParkingType } from 'pages/locations/rateManagement/parkings'

export default [
  //rate management
  { path: '/locations/:id/rate-management', name: 'locations.rateManagement', component: rateManagement
    , meta:{permits:['location_user', 'location_rate_management']}},
  { path: '/locations/:id/rate-management/blackout-dates', name: 'locations.rateManagement.blackouts', component: blackouts
    , meta:{permits:[ 'location_user', 'blackoutDate_list', 'blackoutDate_create']}},
  { path: '/locations/:id/rate-management/types', name: 'locations.rateManagement.types', component: rateTypes
    , meta:{permits:[
      'location_user',
      'locationParkingType_list',
      'locationRoomType_list',
      'locationBundle_list',
    ]}},
  { path: '/locations/:id/rate-management/types/:type_id', name: 'locations.rateManagement.types.edit', component: editParkingType
    , meta:{permits:[
      'locationParkingType_update',
    ]}},
  { path: '/locations/:id/rate-management/adjustments', name: 'locations.rateManagement.adjustments', component: rateModifications
    , meta:{permits:['location_user', 'locationRate_adjustment_create']}},
  { path: '/locations/:id/rate-management/adjustments-list', name: 'locations.rateManagement.adjustmentsList', component: rateModificationsList
    , meta:{permits:['location_user', 'locationRate_adjustment_list']}},
  { path: '/locations/:id/parking-lease-types/:type_id', name: 'locations.parkingLeasePeriods', component: parkingLease},
]
