import {all as allPOI, new as newPOI, edit as editPOI} from 'pages/pointOfInterest'

export default [
  //point of interest
  { path: '/point-of-interest', name: 'poi.all', component: allPOI
    , meta:{permits:['point_of_interest_list']}},
  { path: '/point-of-interest/new', name: 'poi.new', component: newPOI
    , meta:{permits:['point_of_interest_create']}},
  { path: '/point-of-interest/edit/:id', name: 'poi.edit', component: editPOI
    , meta:{permits:['point_of_interest_list']}},
]
