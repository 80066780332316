var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "row margin-top-4x" },
    [
      _c("div", { staticClass: "col-sm-12 col-md-8 col-md-offset-2" }, [
        _c("div", { staticClass: "form-group" }, [
          _c("label", { attrs: { for: "channelName" } }, [_vm._v("Name")]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model.trim",
                value: _vm.formData.name,
                expression: "formData.name",
                modifiers: { trim: true }
              }
            ],
            staticClass: "form-control",
            attrs: {
              type: "text",
              id: "channelName",
              placeholder: "Channel Name"
            },
            domProps: { value: _vm.formData.name },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.formData, "name", $event.target.value.trim())
              },
              blur: function($event) {
                return _vm.$forceUpdate()
              }
            }
          }),
          _vm._v(" "),
          _vm.errors.name
            ? _c("div", { staticClass: "text-danger" }, [
                _vm._v("\n        " + _vm._s(_vm.errors.name[0]) + "\n      ")
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-group" }, [
          _c("label", { attrs: { for: "channelDomain" } }, [_vm._v("Domain")]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model.trim",
                value: _vm.formData.domain,
                expression: "formData.domain",
                modifiers: { trim: true }
              }
            ],
            staticClass: "form-control",
            attrs: {
              type: "text",
              id: "channelDomain",
              placeholder: "Channel Domain"
            },
            domProps: { value: _vm.formData.domain },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.formData, "domain", $event.target.value.trim())
              },
              blur: function($event) {
                return _vm.$forceUpdate()
              }
            }
          }),
          _vm._v(" "),
          _vm.errors.domain
            ? _c("div", { staticClass: "text-danger" }, [
                _vm._v("\n        " + _vm._s(_vm.errors.domain[0]) + "\n      ")
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "hasPermits",
                rawName: "v-hasPermits:allow",
                value: ["super_admin"],
                expression: "['super_admin']",
                arg: "allow"
              }
            ],
            staticClass: "form-group"
          },
          [
            _c("label", { attrs: { for: "channelPrefix" } }, [
              _vm._v("Prefix")
            ]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.trim",
                  value: _vm.formData.prefix,
                  expression: "formData.prefix",
                  modifiers: { trim: true }
                }
              ],
              staticClass: "form-control",
              attrs: {
                type: "text",
                id: "channelPrefix",
                placeholder: "Channel Prefix like OSP"
              },
              domProps: { value: _vm.formData.prefix },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.formData, "prefix", $event.target.value.trim())
                },
                blur: function($event) {
                  return _vm.$forceUpdate()
                }
              }
            }),
            _vm._v(" "),
            _vm.errors.prefix
              ? _c("div", { staticClass: "text-danger" }, [
                  _vm._v(
                    "\n        " + _vm._s(_vm.errors.prefix[0]) + "\n      "
                  )
                ])
              : _vm._e()
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "hasPermits",
                rawName: "v-hasPermits:allow",
                value: ["super_admin"],
                expression: "['super_admin']",
                arg: "allow"
              }
            ],
            staticClass: "form-group"
          },
          [
            _c("div", { staticClass: "checkbox" }, [
              _c("label", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.formData.show_webhook,
                      expression: "formData.show_webhook"
                    }
                  ],
                  attrs: { type: "checkbox" },
                  domProps: {
                    checked: Array.isArray(_vm.formData.show_webhook)
                      ? _vm._i(_vm.formData.show_webhook, null) > -1
                      : _vm.formData.show_webhook
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.formData.show_webhook,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.formData,
                              "show_webhook",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.formData,
                              "show_webhook",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.formData, "show_webhook", $$c)
                      }
                    }
                  }
                }),
                _vm._v("\n            Show webhook\n        ")
              ])
            ])
          ]
        ),
        _vm._v(" "),
        _vm.formData.show_webhook || _vm.isAdmin
          ? _c(
              "div",
              { staticClass: "d-flex flex-justify-between form-group" },
              [
                _c("label", { attrs: { for: "" } }, [
                  _vm._v("Send Notifications via:")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "radio" }, [
                  _c("label", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.formData.send_notifications_via,
                          expression: "formData.send_notifications_via"
                        }
                      ],
                      attrs: { type: "radio", value: "email" },
                      domProps: {
                        checked: _vm._q(
                          _vm.formData.send_notifications_via,
                          "email"
                        )
                      },
                      on: {
                        change: function($event) {
                          return _vm.$set(
                            _vm.formData,
                            "send_notifications_via",
                            "email"
                          )
                        }
                      }
                    }),
                    _vm._v("\n            Email\n        ")
                  ]),
                  _vm._v(" "),
                  _c("label", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.formData.send_notifications_via,
                          expression: "formData.send_notifications_via"
                        }
                      ],
                      attrs: { type: "radio", value: "webhook" },
                      domProps: {
                        checked: _vm._q(
                          _vm.formData.send_notifications_via,
                          "webhook"
                        )
                      },
                      on: {
                        change: function($event) {
                          return _vm.$set(
                            _vm.formData,
                            "send_notifications_via",
                            "webhook"
                          )
                        }
                      }
                    }),
                    _vm._v("\n            Webhook\n        ")
                  ])
                ])
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "hasPermits",
                rawName: "v-hasPermits:allow",
                value: ["super_admin"],
                expression: "['super_admin']",
                arg: "allow"
              }
            ],
            staticClass: "form-group"
          },
          [
            _c("div", { staticClass: "checkbox" }, [
              _c("label", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.formData.production_access,
                      expression: "formData.production_access"
                    }
                  ],
                  attrs: { type: "checkbox" },
                  domProps: {
                    checked: Array.isArray(_vm.formData.production_access)
                      ? _vm._i(_vm.formData.production_access, null) > -1
                      : _vm.formData.production_access
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.formData.production_access,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.formData,
                              "production_access",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.formData,
                              "production_access",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.formData, "production_access", $$c)
                      }
                    }
                  }
                }),
                _vm._v("\n            Production Access\n        ")
              ])
            ])
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "hasPermits",
                rawName: "v-hasPermits:allow",
                value: ["super_admin"],
                expression: "['super_admin']",
                arg: "allow"
              }
            ],
            staticClass: "d-flex flex-justify-between form-group"
          },
          [
            _c("label", { attrs: { for: "" } }, [
              _vm._v("API Documentation Type")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "radio" }, [
              _c("label", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.formData.api_documentation_type,
                      expression: "formData.api_documentation_type"
                    }
                  ],
                  attrs: { type: "radio", value: "parking_only" },
                  domProps: {
                    checked: _vm._q(
                      _vm.formData.api_documentation_type,
                      "parking_only"
                    )
                  },
                  on: {
                    change: function($event) {
                      return _vm.$set(
                        _vm.formData,
                        "api_documentation_type",
                        "parking_only"
                      )
                    }
                  }
                }),
                _vm._v("\n            Parking Only\n        ")
              ]),
              _vm._v(" "),
              _c("label", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.formData.api_documentation_type,
                      expression: "formData.api_documentation_type"
                    }
                  ],
                  attrs: { type: "radio", value: "parking_and_rooms" },
                  domProps: {
                    checked: _vm._q(
                      _vm.formData.api_documentation_type,
                      "parking_and_rooms"
                    )
                  },
                  on: {
                    change: function($event) {
                      return _vm.$set(
                        _vm.formData,
                        "api_documentation_type",
                        "parking_and_rooms"
                      )
                    }
                  }
                }),
                _vm._v("\n            Parking and Rooms\n        ")
              ])
            ])
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "hasPermits",
                rawName: "v-hasPermits:allow",
                value: ["channel_user"],
                expression: "['channel_user']",
                arg: "allow"
              }
            ],
            staticClass: "d-flex flex-justify-between form-group"
          },
          [
            _c(
              "a",
              {
                attrs: { href: "#" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.loadDocumentation()
                  }
                }
              },
              [_vm._v("\n        API Documentation\n      ")]
            ),
            _vm._v(" "),
            _vm.formData.last_updated
              ? _c("span", { staticClass: "pull-right" }, [
                  _vm._v("last updated: " + _vm._s(_vm.formData.last_updated))
                ])
              : _vm._e()
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "hasPermits",
                rawName: "v-hasPermits:allow",
                value: ["super_admin"],
                expression: "['super_admin']",
                arg: "allow"
              }
            ],
            staticClass: "form-group"
          },
          [
            _c("label", { attrs: { for: "channelLimitDays" } }, [
              _vm._v("Number Of Days To Book In Advance")
            ]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.trim",
                  value: _vm.formData.limit_days_to_book,
                  expression: "formData.limit_days_to_book",
                  modifiers: { trim: true }
                }
              ],
              staticClass: "form-control",
              attrs: {
                type: "text",
                id: "channelLimitDays",
                placeholder: "Number Of Days To Book In Advance"
              },
              domProps: { value: _vm.formData.limit_days_to_book },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(
                    _vm.formData,
                    "limit_days_to_book",
                    $event.target.value.trim()
                  )
                },
                blur: function($event) {
                  return _vm.$forceUpdate()
                }
              }
            }),
            _vm._v(" "),
            _vm.errors.limit_days_to_book
              ? _c("div", { staticClass: "text-danger" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.errors.limit_days_to_book[0]) +
                      "\n      "
                  )
                ])
              : _vm._e()
          ]
        ),
        _vm._v(" "),
        _c("hr"),
        _vm._v(" "),
        _c("div", { staticClass: "form-group" }, [
          _c("label", { attrs: { for: "channelKey" } }, [
            _vm._v("Testing Key")
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "hasPermits",
                  rawName: "v-hasPermits:allow",
                  value: ["super_admin"],
                  expression: "['super_admin']",
                  arg: "allow"
                }
              ],
              staticClass: "pull-right"
            },
            [
              _c(
                "a",
                {
                  attrs: { href: "#" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.regenerateKey("test")
                    }
                  }
                },
                [_vm._v("Generate key")]
              )
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "panel panel-default" }, [
            _c("div", { staticClass: "panel-body" }, [
              _vm._v(
                "\n          " + _vm._s(_vm.formData.testing_key) + "\n        "
              )
            ])
          ])
        ]),
        _vm._v(" "),
        _vm.formData.production_access
          ? _c("div", { staticClass: "form-group" }, [
              _c("label", { attrs: { for: "channelKey" } }, [
                _vm._v("Production Key")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "pull-right" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "hasPermits",
                        rawName: "v-hasPermits:allow",
                        value: ["super_admin"],
                        expression: "['super_admin']",
                        arg: "allow"
                      }
                    ],
                    staticClass: "horizontal-group"
                  },
                  [
                    _c(
                      "a",
                      {
                        attrs: { href: "#" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.regenerateKey("prod")
                          }
                        }
                      },
                      [_vm._v("Generate key")]
                    ),
                    _vm._v(" "),
                    !_vm.formData.key ? _c("span", [_vm._v("|")]) : _vm._e()
                  ]
                ),
                _vm._v(" "),
                !_vm.formData.key
                  ? _c(
                      "a",
                      {
                        attrs: { href: "#" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.showPasswordModal("key")
                          }
                        }
                      },
                      [_vm._v("Show Key")]
                    )
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "panel panel-default" }, [
                _c("div", { staticClass: "panel-body" }, [
                  !_vm.formData.key
                    ? _c("span", { staticClass: "bluerred-text" }, [
                        _vm._v(
                          "\n            prod_xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx\n          "
                        )
                      ])
                    : _c("span", [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.formData.key) +
                            "\n          "
                        )
                      ])
                ])
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "hasPermits",
                rawName: "v-hasPermits:allow",
                value: ["super_admin"],
                expression: "['super_admin']",
                arg: "allow"
              }
            ],
            staticClass: "form-group"
          },
          [
            _c("label", { attrs: { for: "channelFailedRequestsEmail" } }, [
              _vm._v("Faild Requests Email")
            ]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.trim",
                  value: _vm.formData.failed_requests_email,
                  expression: "formData.failed_requests_email",
                  modifiers: { trim: true }
                }
              ],
              staticClass: "form-control",
              attrs: {
                type: "text",
                id: "channelFailedRequestsEmail",
                placeholder: "Channel Faild Requests Email"
              },
              domProps: { value: _vm.formData.failed_requests_email },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(
                    _vm.formData,
                    "failed_requests_email",
                    $event.target.value.trim()
                  )
                },
                blur: function($event) {
                  return _vm.$forceUpdate()
                }
              }
            }),
            _vm._v(" "),
            _vm.errors.failed_requests_email
              ? _c("div", { staticClass: "text-danger" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.errors.failed_requests_email[0]) +
                      "\n      "
                  )
                ])
              : _vm._e()
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "hasPermits",
                rawName: "v-hasPermits:allow",
                value: ["super_admin"],
                expression: "['super_admin']",
                arg: "allow"
              }
            ],
            staticClass: "form-group"
          },
          [
            _c("div", { staticClass: "checkbox" }, [
              _c("label", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.formData.send_failed_requests_email,
                      expression: "formData.send_failed_requests_email"
                    }
                  ],
                  attrs: { type: "checkbox" },
                  domProps: {
                    checked: Array.isArray(
                      _vm.formData.send_failed_requests_email
                    )
                      ? _vm._i(_vm.formData.send_failed_requests_email, null) >
                        -1
                      : _vm.formData.send_failed_requests_email
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.formData.send_failed_requests_email,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.formData,
                              "send_failed_requests_email",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.formData,
                              "send_failed_requests_email",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(
                          _vm.formData,
                          "send_failed_requests_email",
                          $$c
                        )
                      }
                    }
                  }
                }),
                _vm._v("\n            Send Email\n        ")
              ])
            ])
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "hasPermits",
                rawName: "v-hasPermits:allow",
                value: ["super_admin"],
                expression: "['super_admin']",
                arg: "allow"
              }
            ],
            staticClass: "form-group"
          },
          [
            _c("label", { attrs: { for: "maxTestingRequests" } }, [
              _vm._v("Max Testing Requests Per Day")
            ]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.trim",
                  value: _vm.formData.max_testing_requests,
                  expression: "formData.max_testing_requests",
                  modifiers: { trim: true }
                }
              ],
              staticClass: "form-control",
              attrs: {
                type: "text",
                id: "maxTestingRequests",
                placeholder: "Max Testing Requests"
              },
              domProps: { value: _vm.formData.max_testing_requests },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(
                    _vm.formData,
                    "max_testing_requests",
                    $event.target.value.trim()
                  )
                },
                blur: function($event) {
                  return _vm.$forceUpdate()
                }
              }
            }),
            _vm._v(" "),
            _vm.errors.max_testing_requests
              ? _c("div", { staticClass: "text-danger" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.errors.max_testing_requests[0]) +
                      "\n      "
                  )
                ])
              : _vm._e()
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "hasPermits",
                rawName: "v-hasPermits:allow",
                value: ["super_admin"],
                expression: "['super_admin']",
                arg: "allow"
              }
            ],
            staticClass: "form-group"
          },
          [
            _c("label", { attrs: { for: "maxProductionRequests" } }, [
              _vm._v("Max Production Requests Per Day")
            ]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.trim",
                  value: _vm.formData.max_production_requests,
                  expression: "formData.max_production_requests",
                  modifiers: { trim: true }
                }
              ],
              staticClass: "form-control",
              attrs: {
                type: "text",
                id: "maxProductionRequests",
                placeholder: "Max Production Requests"
              },
              domProps: { value: _vm.formData.max_production_requests },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(
                    _vm.formData,
                    "max_production_requests",
                    $event.target.value.trim()
                  )
                },
                blur: function($event) {
                  return _vm.$forceUpdate()
                }
              }
            }),
            _vm._v(" "),
            _vm.errors.max_production_requests
              ? _c("div", { staticClass: "text-danger" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.errors.max_production_requests[0]) +
                      "\n      "
                  )
                ])
              : _vm._e()
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "hasPermits",
                rawName: "v-hasPermits:allow",
                value: ["super_admin"],
                expression: "['super_admin']",
                arg: "allow"
              }
            ],
            staticClass: "form-group"
          },
          [
            _c("div", { staticClass: "checkbox" }, [
              _c("label", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.formData.allow_only_due_at_check_in_locations,
                      expression:
                        "formData.allow_only_due_at_check_in_locations"
                    }
                  ],
                  attrs: { type: "checkbox" },
                  domProps: {
                    checked: Array.isArray(
                      _vm.formData.allow_only_due_at_check_in_locations
                    )
                      ? _vm._i(
                          _vm.formData.allow_only_due_at_check_in_locations,
                          null
                        ) > -1
                      : _vm.formData.allow_only_due_at_check_in_locations
                  },
                  on: {
                    change: function($event) {
                      var $$a =
                          _vm.formData.allow_only_due_at_check_in_locations,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.formData,
                              "allow_only_due_at_check_in_locations",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.formData,
                              "allow_only_due_at_check_in_locations",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(
                          _vm.formData,
                          "allow_only_due_at_check_in_locations",
                          $$c
                        )
                      }
                    }
                  }
                }),
                _vm._v(
                  "\n            allow only Due at check-in locations\n        "
                )
              ])
            ])
          ]
        ),
        _vm._v(" "),
        _vm.formData.ips && _vm.formData.ips.length
          ? _c("div", { staticClass: "form-group" }, [
              _c("table", { staticClass: "table table-striped" }, [
                _vm._m(0),
                _vm._v(" "),
                _c(
                  "tbody",
                  [
                    _vm._l(_vm.ips, function(ip) {
                      return _c("tr", [
                        _c("td", [_vm._v(" " + _vm._s(ip.ip) + " ")]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(" " + _vm._s(ip.last_used_date) + " ")
                        ])
                      ])
                    }),
                    _vm._v(" "),
                    _vm.has_more
                      ? _c("tr", [
                          _c("td"),
                          _vm._v(" "),
                          _c("td", [
                            _c(
                              "a",
                              {
                                attrs: { href: "#" },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.loadMoreIps.apply(
                                      null,
                                      arguments
                                    )
                                  }
                                }
                              },
                              [_vm._v("load more ...")]
                            )
                          ])
                        ])
                      : _vm._e()
                  ],
                  2
                )
              ])
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _vm.modalShouldShow
        ? _c(
            "func-modal",
            {
              ref: "passwordModal",
              attrs: { title: "Show Production Key" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "footer",
                    fn: function(props) {
                      return [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-link",
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return props.close()
                              }
                            }
                          },
                          [_vm._v("\n        Cancel\n      ")]
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-primary",
                            class: [{ disabled: _vm.loading }],
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.requestKey(
                                  _vm.$refs.passwordField.value
                                )
                              }
                            }
                          },
                          [
                            _vm.loading
                              ? _c("span", [
                                  _c("i", {
                                    staticClass: "fa fa-spinner fa-spin"
                                  })
                                ])
                              : _vm._e(),
                            _vm._v("\n        Show the key\n      ")
                          ]
                        )
                      ]
                    }
                  }
                ],
                null,
                false,
                1329851083
              )
            },
            [
              _c("template", { slot: "body" }, [
                _c("label", [_vm._v("Enter Password")]),
                _vm._v(" "),
                _c("input", {
                  ref: "passwordField",
                  staticClass: "form-control",
                  attrs: { type: "password", placeholder: "Enter Password" },
                  on: {
                    keypress: function($event) {
                      return _vm.onPressEnterRequestKey(
                        $event,
                        _vm.$refs.passwordField.value
                      )
                    }
                  }
                }),
                _vm._v(" "),
                _vm.errors.password
                  ? _c("div", { staticClass: "text-danger" }, [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.errors.password[0]) +
                          "\n      "
                      )
                    ])
                  : _vm._e()
              ])
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _c("confirm", { ref: "confirmRegenerateKey", attrs: { type: "danger" } })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v(" IP ")]),
        _vm._v(" "),
        _c("th", [_vm._v(" Last Used Date ")])
      ])
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-3cf72140", { render: render, staticRenderFns: staticRenderFns })
  }
}