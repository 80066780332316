var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "col-xs-12" }, [
    _vm.processing
      ? _c("div", { staticClass: "text-center" }, [
          _c("i", { staticClass: "fa fa-spinner fa-spin fa-3x fa-fw" })
        ])
      : _c("div", { staticClass: "table-responsive" }, [
          _c("table", { staticClass: "table table-striped table-bordered" }, [
            _c("thead", [
              _c(
                "tr",
                _vm._l(_vm.columns, function(column, z) {
                  return _c("th", { key: z }, [
                    _vm._v("\n            " + _vm._s(column) + "\n          ")
                  ])
                }),
                0
              )
            ]),
            _vm._v(" "),
            _c(
              "tbody",
              _vm._l(_vm.rows, function(row, i) {
                return _c(
                  "tr",
                  { key: i },
                  _vm._l(row, function(item, j) {
                    return _c("td", { key: j }, [
                      _c("div", [_vm._v(_vm._s(item))])
                    ])
                  }),
                  0
                )
              }),
              0
            )
          ])
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-56f0f008", { render: render, staticRenderFns: staticRenderFns })
  }
}