var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("layout", { attrs: { "no-sidebar": true } }, [
    _c("div", { staticClass: "page-header" }, [
      _c("h1", [_c("small", [_vm._v("Personal Settings")])])
    ]),
    _vm._v(" "),
    _vm.form
      ? _c("div", { staticClass: "col-sm-8 col-sm-offset-2" }, [
          _c("div", { staticClass: "text-right" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-success",
                class: { disabled: _vm.processing },
                attrs: { disabled: _vm.processing },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.submit.apply(null, arguments)
                  }
                }
              },
              [
                _vm.processing
                  ? _c("span", [
                      _c("i", { staticClass: "fa fa-spinner fa-spin fa-fw" }),
                      _vm._v("\n        SAVING...\n      ")
                    ])
                  : _c("span", [_vm._v("SAVE")])
              ]
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "margin-bottom-3x" }, [
            _c("label", [_vm._v("Name")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.name,
                  expression: "form.name"
                }
              ],
              staticClass: "form-control",
              attrs: { type: "text" },
              domProps: { value: _vm.form.name },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.form, "name", $event.target.value)
                }
              }
            }),
            _vm._v(" "),
            _vm.errors.name
              ? _c("div", { staticClass: "text-danger" }, [
                  _vm._v("\n      " + _vm._s(_vm.errors.name[0]) + "\n    ")
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "margin-bottom-3x" }, [
            _c("label", [_vm._v("Email")]),
            _c("br"),
            _vm._v("\n    " + _vm._s(_vm.form.email) + "\n  ")
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "margin-bottom-3x" }, [
            _c("label", [_vm._v("Password")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.password,
                  expression: "form.password"
                }
              ],
              staticClass: "form-control",
              attrs: { type: "password" },
              domProps: { value: _vm.form.password },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.form, "password", $event.target.value)
                }
              }
            }),
            _vm._v(" "),
            _vm.errors.password
              ? _c("div", { staticClass: "text-danger" }, [
                  _vm._v("\n      " + _vm._s(_vm.errors.password[0]) + "\n    ")
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "margin-bottom-3x" }, [
            _c("label", [_vm._v("Confirm Password")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.password_confirmation,
                  expression: "form.password_confirmation"
                }
              ],
              staticClass: "form-control",
              attrs: { type: "password" },
              domProps: { value: _vm.form.password_confirmation },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(
                    _vm.form,
                    "password_confirmation",
                    $event.target.value
                  )
                }
              }
            })
          ]),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          _c("div", { staticClass: "margin-bottom-3x" }, [
            _c("label", [_vm._v("Phone")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.phone,
                  expression: "form.phone"
                }
              ],
              staticClass: "form-control",
              attrs: { type: "text" },
              domProps: { value: _vm.form.phone },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.form, "phone", $event.target.value)
                }
              }
            }),
            _vm._v(" "),
            _vm.errors.phone
              ? _c("div", { staticClass: "text-danger" }, [
                  _vm._v("\n      " + _vm._s(_vm.errors.phone[0]) + "\n    ")
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "margin-bottom-3x" }, [
            _c("label", [_vm._v("Address 1")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.address1,
                  expression: "form.address1"
                }
              ],
              staticClass: "form-control",
              attrs: { type: "text" },
              domProps: { value: _vm.form.address1 },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.form, "address1", $event.target.value)
                }
              }
            }),
            _vm._v(" "),
            _vm.errors.address1
              ? _c("div", { staticClass: "text-danger" }, [
                  _vm._v("\n      " + _vm._s(_vm.errors.address1[0]) + "\n    ")
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "margin-bottom-3x" }, [
            _c("label", [_vm._v("Address 2")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.address2,
                  expression: "form.address2"
                }
              ],
              staticClass: "form-control",
              attrs: { type: "text" },
              domProps: { value: _vm.form.address2 },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.form, "address2", $event.target.value)
                }
              }
            }),
            _vm._v(" "),
            _vm.errors.address2
              ? _c("div", { staticClass: "text-danger" }, [
                  _vm._v("\n      " + _vm._s(_vm.errors.address2[0]) + "\n    ")
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "margin-bottom-3x" }, [
            _c("label", [_vm._v("Zip Code")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.zip_code,
                  expression: "form.zip_code"
                }
              ],
              staticClass: "form-control",
              attrs: { type: "text" },
              domProps: { value: _vm.form.zip_code },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.form, "zip_code", $event.target.value)
                }
              }
            }),
            _vm._v(" "),
            _vm.errors.zip_code
              ? _c("div", { staticClass: "text-danger" }, [
                  _vm._v("\n      " + _vm._s(_vm.errors.zip_code[0]) + "\n    ")
                ])
              : _vm._e()
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-42d7846a", { render: render, staticRenderFns: staticRenderFns })
  }
}