var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "layout",
    { attrs: { "no-box": true, title: _vm.location ? _vm.location.name : "" } },
    [
      _c("tabs"),
      _vm._v(" "),
      _vm.loading
        ? _c("div", { staticClass: "text-center" }, [
            _c("i", { staticClass: "fa fa-spinner fa-spin fa-3x fa-fw" })
          ])
        : _c("div", { staticClass: "box" }, [
            _c(
              "div",
              { staticClass: "box-header with-border margin-bottom-8x" },
              [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-sm-4 col-xs-12 margin-bottom-2x" },
                    [
                      _c("label", [_vm._v("From Date")]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "input-group" },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "input-group-addon",
                              attrs: { id: "basic-addon1" }
                            },
                            [
                              _c("i", {
                                staticClass: "fa fa-calendar",
                                attrs: { "aria-hidden": "true" }
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c("datepicker", {
                            staticClass: "form-control dashboard",
                            attrs: {
                              "date-format": "MMMM/YYYY",
                              "min-date": "none",
                              options: _vm.date_picker_settings,
                              "hide-days": true
                            },
                            model: {
                              value: _vm.form.from_date,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "from_date", $$v)
                              },
                              expression: "form.from_date"
                            }
                          })
                        ],
                        1
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-sm-8 col-xs-12 padding-top-5x" },
                    [
                      _c("span", [
                        _c(
                          "a",
                          {
                            attrs: { href: "#" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.prevMonth.apply(null, arguments)
                              }
                            }
                          },
                          [
                            _c("i", {
                              staticClass: "fa fa-chevron-left",
                              attrs: { "aria-hidden": "true" }
                            })
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "span",
                          { staticClass: "margin-right-3x margin-left-3x" },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(this.form.from_date) +
                                "\n              "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            attrs: { href: "#" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.nextMonth.apply(null, arguments)
                              }
                            }
                          },
                          [
                            _c("i", {
                              staticClass: "fa fa-chevron-right",
                              attrs: { "aria-hidden": "true" }
                            })
                          ]
                        )
                      ])
                    ]
                  )
                ])
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "box-body" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-sm-12 col-xs-12" }, [
                  _c("div", { staticClass: "chart-box chart-box--shadow" }, [
                    _c("div", { staticClass: "chart-box chart-box--title" }, [
                      _c("h3", [
                        _vm._v("Contribution Per Channel (For a Month)")
                      ]),
                      _vm._v(" "),
                      _c("small", { staticClass: "text-warning" }, [
                        _vm._v(
                          "Channels with no contribution will not be shown"
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c(
                      "table",
                      { staticClass: "table table-hover text-center" },
                      [
                        _c("thead", [
                          _c("tr", [
                            _c("th", { attrs: { scope: "col" } }, [
                              _vm._v(
                                "\n                      Channel\n                    "
                              )
                            ]),
                            _vm._v(" "),
                            _c("th", { attrs: { scope: "col" } }, [
                              _vm._v(
                                "\n                      Contribution\n                    "
                              )
                            ]),
                            _vm._v(" "),
                            _c("th", { attrs: { scope: "col" } }, [
                              _vm._v(
                                "\n                      % of Total\n                    "
                              )
                            ])
                          ])
                        ]),
                        _vm._v(" "),
                        !_vm._.isEmpty(_vm.tableData)
                          ? _c(
                              "tbody",
                              [
                                _vm._l(_vm.tableData.data, function(item) {
                                  return _c("tr", [
                                    _c("th", [
                                      _vm._v(
                                        "\n                      " +
                                          _vm._s(item.name) +
                                          "\n                    "
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("th", [
                                      _vm._v(
                                        "\n                      " +
                                          _vm._s(
                                            _vm._f("currency")(
                                              item.contribution,
                                              _vm.currencyCode,
                                              2
                                            )
                                          ) +
                                          "\n                    "
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("th", [
                                      _vm._v("%" + _vm._s(item.percentage))
                                    ])
                                  ])
                                }),
                                _vm._v(" "),
                                _c("tr", [
                                  _c("th", [_vm._v("Total")]),
                                  _vm._v(" "),
                                  _c("th", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("currency")(
                                          _vm.tableData.total,
                                          _vm.currencyCode,
                                          2
                                        )
                                      )
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("th")
                                ]),
                                _vm._v(" "),
                                _c("tr", [
                                  _c("th", [_vm._v("Adjustments")]),
                                  _vm._v(" "),
                                  _c("th", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("currency")(
                                          _vm.tableData.adjustments,
                                          _vm.currencyCode,
                                          2
                                        )
                                      )
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("th")
                                ]),
                                _vm._v(" "),
                                _c("tr", [
                                  _c("th", [_vm._v("Amount Owed")]),
                                  _vm._v(" "),
                                  _c("th", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("currency")(
                                          _vm.tableData.adjustments +
                                            _vm.tableData.total,
                                          _vm.currencyCode,
                                          2
                                        )
                                      )
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("th")
                                ])
                              ],
                              2
                            )
                          : _c("tbody", [
                              _c("tr", [
                                _c(
                                  "th",
                                  {
                                    staticClass: "text-danger text-center",
                                    attrs: { colspan: "3" }
                                  },
                                  [
                                    _vm._v(
                                      "\n                      No data available.\n                    "
                                    )
                                  ]
                                )
                              ])
                            ])
                      ]
                    )
                  ])
                ])
              ])
            ])
          ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-0a7b5c49", { render: render, staticRenderFns: staticRenderFns })
  }
}