import { http } from 'services'

// initial state
const state = () => ({
  all: [],
  active: {
    locations: {},
    reservations: {},
    customers: {},
    fees: [],
    dynamicFees: [],
    fees_breakdown: [],
    users: [],
    need_to_save: false,
    downloads: null
  },
  active_reservation: {},
  active_customer: {
    locations: {},
    reservations: {}
  },
  errors: null,
  api_doc: null
})

// getters
const getters = {

}

// actions
const actions = {
  ['channels.getAll'] ({ commit }, params) {
    if(!params._q) params._q = null;
    if (!params._with_deleted) params._with_deleted = null
    if (!params._only_deleted) params._only_deleted = null

    commit('CLEAR_ERRORS')

    return (http.get('channels', {
      params
    })).then((res) => {
      commit('GET_ALL_CHANNELS', res);
    }).catch((err) => {
      commit('CHANNELS_ERROR', err);
    });
  },

  ['channels.get'] ({commit}, id) {
    commit('CLEAR_ERRORS')
    return (http.get(`channels/${id}`)).then((res) => {
      commit('GET_CHANNEL', res);
    }).catch ((res) => {
      commit('CHANNELS_ERROR', res);
    });
  },

  ['channels.getFees'] ({commit}, {channel_id}) {
    commit('CLEAR_ERRORS')
    return (http.get(`channels/${channel_id}/fees?_all=true`)).then((res) => {
      commit('GET_FEES', res);
    }).catch ((res) => {
      commit('CHANNELS_ERROR', res);
    });
  },

  ['channels.getDynamicFees'] ({commit}, {channel_id}) {
    commit('CLEAR_ERRORS')
    return (http.get(`channels/${channel_id}/fees?dynamic=true&_all=true`)).then((res) => {
      commit('GET_DYNAMIC_FEES', res);
    }).catch ((res) => {
      commit('CHANNELS_ERROR', res);
    });
  },

  ['channels.getReservationFees'] ({commit}, {reservation_id, channel_id}) {
    commit('CLEAR_ERRORS')
    return (http.get(`channels/${channel_id}/reservations/${reservation_id}/fees`))
    .then((res) => {
      commit('GET_CHANNEL_RESERVATION_FEES', res);
    })
  },

  ['channels.getProductionKey'] ({commit}, {id, password}) {
    commit('CLEAR_ERRORS')
    return (http.post(`channels/${id}/production-key`, {password}))
    .then((res) => {
      commit('GET_CHANNEL_PRODUCTION_KEY', res);
    }).catch((err) => {
      commit('CHANNELS_ERROR', err);
    });
  },

  ['channels.regenerateKey'] ({commit}, {id, type}) {
    commit('CLEAR_ERRORS')
    return (http.put(`channels/${id}/regenerate-key`, {type}))
    .then((res) => {
      if (type == 'prod') {
        commit('GET_CHANNEL_PRODUCTION_KEY', res);
      } else if (type == 'test') {
        commit('EDIT_CHANNEL', res);
      }
    }).catch((err) => {
      commit('CHANNELS_ERROR', err);
    });
  },

  ['channels.clearProductionKey'] ({commit}) {
    commit('CLEAR_ERRORS');
    commit('CLEAR_CHANNEL_PRODUCTION_KEY');
  },

  ['channels.getCustomers'] ({commit}, {id, params}) {
    commit('CLEAR_ERRORS')
    return (http.get(`channels/${id}/customers`, {
      params
    }))
    .then((res) => {
      commit('GET_CHANNEL_CUSTOMERS', res);
    }).catch((err) => {
      commit('CHANNELS_ERROR', err);
    });
  },

  ['channels.getCustomer'] ({commit}, {chId, id}) {
    commit('CLEAR_ERRORS')
    return (http.get(`channels/${chId}/customers/${id}`))
    .then((res) => {
      commit('GET_CHANNEL_CUSTOMER', res);
    }).catch((err) => {
      commit('CHANNELS_ERROR', err);
    });
  },

  ['channels.getCustomerLocations'] ({commit}, {chId, id, params}) {
    commit('CLEAR_ERRORS')
    return (http.get(`channels/${id}/customers/${id}/locations`, {
      params
    }))
    .then((res) => {
      commit('GET_CHANNEL_CUSTOMER_LOCATIONS', res);
    }).catch((err) => {
      commit('CHANNELS_ERROR', err);
    });
  },

  ['channels.getCustomerReservations'] ({commit}, {chId, id, params}) {
    commit('CLEAR_ERRORS')
    return (http.get(`channels/${id}/customers/${id}/reservations`, {
      params
    }))
    .then((res) => {
      commit('GET_CHANNEL_CUSTOMER_RESERVATIONS', res);
    }).catch((err) => {
      commit('CHANNELS_ERROR', err);
    });
  },

  ['channels.getLocations'] ({commit}, {id, params}) {
    commit('CLEAR_ERRORS')
    return (http.get(`channels/${id}/locations`, {
      params
    }))
    .then((res) => {
      commit('GET_CHANNEL_LOCATIONS', res);
    }).catch((err) => {
      commit('CHANNELS_ERROR', err);
    });
  },

  ['channels.updateLocations'] ({dispatch, commit, state}, {id, data}) {
    commit('CLEAR_ERRORS');
    return (http.post(`channels/${id}/locations`, data)).then ((res) => {
      commit('GET_CHANNEL_LOCATIONS', res);
    }).catch ((res) => {
      commit('CHANNELS_ERROR', res);
    })
  },

  ['channels.getReservations'] ({commit}, {id, params}) {
    commit('CLEAR_ERRORS')
    return (http.get(`channels/${id}/reservations`, {
      params
    }))
    .then((res) => {
      commit('GET_CHANNEL_RESERVATIONS', res);
    }).catch((err) => {
      commit('CHANNELS_ERROR', err);
    });
  },

  ['channels.downloadReservations'] ({commit}, {id, params}) {
    commit('CLEAR_ERRORS')
    return (http.get(`channels/${id}/reservations/download`, {
      params
    }))
    .then((res) => {
      commit('GET_CHANNEL_RESERVATIONS_FOR_DOWNLOAD', res);
    }).catch((err) => {
      commit('CHANNELS_ERROR', err);
    });
  },

  ['channels.getReservation'] ({commit}, {chId, id}) {
    commit('CLEAR_ERRORS')
    return (http.get(`channels/${chId}/reservations/${id}`))
    .then((res) => {
      commit('GET_CHANNEL_RESERVATION', res);
    }).catch((err) => {
      commit('CHANNELS_ERROR', err);
    });
  },

  ['channels.edit'] ({commit}, {id, data}) {
    commit('CLEAR_ERRORS')
    return (http.put(`channels/${id}`, data)).then((res) => {
      commit('EDIT_CHANNEL', res);
    }).catch((err) => {
      commit('CHANNELS_ERROR', err);
    });
  },

  ['channels.remove'] ({commit}, id) {
    commit('CLEAR_ERRORS');

    return (http.delete(`channels/${id}`)).then ((res) => {
      commit('REMOVE_CHANNEL', res);
    })
    .catch ((res) => {
      commit('CHANNELS_ERROR', res);
    })
  },

  ['channels.undoRemove'] ({commit}, id) {
    commit('CLEAR_ERRORS');

    return (http.delete(`channels/${id}?_undelete=true`)).then ((res) => {
      commit('UNDO_REMOVE_CHANNEL', res);
    })
    .catch ((res) => {
      commit('CHANNELS_ERROR', res);
    })
  },

  ['channels.update_no_show_reseration'] ({commit}, no_show) {
    commit('CLEAR_ERRORS');
    commit('UPDATE_NO_SHOW_RESERVATION', no_show);
  },

  ['channels.add'] ({commit}, data) {
    commit('CLEAR_ERRORS')

    return (http.post(`channels`, data)).then((res) => {
      commit('NEW_CHANNEL', res);
    }).catch((err) => {
      commit('CHANNELS_ERROR', err);
    });
  },

  ['channels.upsertEmployee'] ({ commit }, {id, data}) {
    commit('CLEAR_ERRORS')

    return (http.post(`channels/${id}/users`, data))
    .then ((res) => {
      commit('CHANNEL_UPSERT_EMPLOYEE', res);
    })
    .catch ((res) => {
      commit('CHANNELS_ERROR', res);
    })
  },
  ['channels.removeEmployee'] ({ commit }, {chId, id}) {
    commit('CLEAR_ERRORS')

    return (http.delete(`channels/${chId}/users/${id}`))
    .then ((res) => {
      commit('CHANNEL_REMOVE_EMPLOYEE', res);
    })
    .catch ((res) => {
      commit('CHANNELS_ERROR', res);
    })
  },

  //fees
  ['channels.newFee']({commit}, {id, data}) {
    commit('CLEAR_ERRORS')
    return (http.post(`channels/${id}/fees`, data)).then((res) => {
      commit('NEW_FEE', res);
    })
    .catch((err) => {
      commit('CHANNELS_ERROR', err);
    });
  },
  
  ['channels.newDynamicFee']({commit}, {id, data}) {
    commit('CLEAR_ERRORS')
    return (http.post(`channels/${id}/dynamic-fees`, data)).then((res) => {
      commit('NEW_DYNAMIC_FEE', res);
    })
    .catch((err) => {
      commit('CHANNELS_ERROR', err);
    });
  },

  ['channels.updateFee'] ({commit}, {chId, id, data}) {
    commit('CLEAR_ERRORS')
    return (http.put(`channels/${chId}/fees/${id}`, data)).then((res) => {
      commit('UPDATE_CHANNEL_FEE', res);
    })
    .catch((err) => {
      commit('CHANNELS_ERROR', err);
    });
  },

  ['channels.updateDynamicFee'] ({commit}, {chId, id, data}) {
    commit('CLEAR_ERRORS')
    return (http.put(`channels/${chId}/dynamic-fees/${id}`, data)).then((res) => {
      commit('UPDATE_DYNAMIC_CHANNEL_FEE', res);
    })
    .catch((err) => {
      commit('CHANNELS_ERROR', err);
    });
  },

  ['channels.removeFee']({commit}, {channel_id, id, is_dynamic = false}) {
    commit('CLEAR_ERRORS')
    return (http.delete(`channels/${channel_id}/fees/${id}`)).then((res) => {
      if (!is_dynamic) {
        commit('REMOVE_CHANNEL_FEE', res);
      } else {
        commit('REMOVE_DYNAMIC_CHANNEL_FEE', res);
      }
    })
    .catch((err) => {
      commit('CHANNELS_ERROR', err);
    });
  },

  ['channels.clearErrors'] ({commit}) {
    commit('CLEAR_ERRORS')
  },

  ['channels.copyFees'] ({commit}, {chIdSource, chIdDestination}) {
    commit('CLEAR_ERRORS')
    return (http.put(`channels/${chIdDestination}/copy_fees_from/${chIdSource}`)).then((res) => {
      commit('UPDATE_CHANNEL_FEES', res);
    })
    .catch((err) => {
      commit('CHANNELS_ERROR', err);
    });
  },

  ['channels.getApiDocumentation'] ({ commit }, {id}) {
    commit('CLEAR_ERRORS')

    return (http.get(`channels/${id}/api-documentation`)).then ((res) => {
      commit('GET_API_DOCUMENTATION', res);
    })
    .catch ((res) => {
      commit('CHANNELS_ERROR', res);
    })
  }
}

// mutations
const mutations = {
  GET_ALL_CHANNELS (state, channels) {
    state.all = channels.data;
  },

  GET_CHANNEL (state, channel) {
    state.active = _.assign(state.active, channel.data);
  },

  GET_FEES (state, res) {
    state.active.fees = res.data;
    state.active = _.cloneDeep(state.active);
  },

  GET_DYNAMIC_FEES (state, res) {
    state.active.dynamicFees = res.data;
    state.active = _.cloneDeep(state.active);
  },

  GET_CHANNEL_RESERVATION_FEES (state, res) {
    state.active.fees_breakdown = _.isEmpty(res.data) ? [] : res.data;
  },

  GET_CHANNEL_PRODUCTION_KEY (state, res) {
    state.active.key = res.data.key;
    state.active = _.cloneDeep(state.active);
  },

  CLEAR_CHANNEL_PRODUCTION_KEY (state) {
    state.active.key = null;
    state.active = _.cloneDeep(state.active);
  },

  GET_CHANNEL_LOCATIONS (state, locations) {
    state.active.locations = locations.data;
  },

  GET_CHANNEL_CUSTOMER_LOCATIONS (state, locations) {
    state.active_customer.locations = locations.data;
  },

  GET_CHANNEL_CUSTOMER_RESERVATIONS (state, reservations) {
    state.active_customer.reservations = reservations.data;
  },

  GET_CHANNEL_CUSTOMERS (state, customers) {
    state.active.customers = customers.data;
  },

  GET_CHANNEL_RESERVATIONS (state, reservations) {
    state.active.reservations = reservations.data;
    // Add Active History
    _.forEach(state.active.reservations.data, (item, index) => {
      state.active.reservations.data[index].active_history = _.find(state.active.reservations.data[index].history, (item) => item.active);
    });
  },

  GET_CHANNEL_RESERVATIONS_FOR_DOWNLOAD (state, res) {
    state.active.downloads = res;
  },

  GET_CHANNEL_RESERVATION (state, reservation) {
    state.active_reservation = reservation.data;
  },

  GET_API_DOCUMENTATION (state, res) {
    state.api_doc = res.data;
  },

  GET_CHANNEL_CUSTOMER (state, customer) {
    state.active_customer = _.assign(state.active_customer, customer.data);
  },

  EDIT_CHANNEL (state, channel) {
    state.active = _.assign(state.active, channel.data);
    state.active.need_to_save = false;
    state.active = _.cloneDeep(state.active);
  },

  NEW_CHANNEL (state, channel) {
    state.active = _.assign(state.active, channel.data);
  },

  CHANNEL_UPSERT_EMPLOYEE (state, res) {
    let index = _.findIndex(state.active.users, (user) => user.id == res.data.id);
    if (index > -1) {
      state.active.users[index] = res.data;
    } else {
      state.active.users = state.active.users.concat(res.data);
    }
    state.active = _.cloneDeep(state.active);
  },

  CHANNEL_REMOVE_EMPLOYEE (state, res) {
    state.active.users = state.active.users.filter(
      (user) => user.id != res.data.id);
  },

  REMOVE_CHANNEL (state, res) {
    state.all.data = _.filter(state.all.data, (item) => item.id != res.data.id);
    state.all = _.cloneDeep(state.all);
  },

  UNDO_REMOVE_CHANNEL (state, res) {
    let indx = _.findIndex(state.all.data, (item) => item.id == res.data.id);

    if (indx > -1) {
      state.all.data[indx] = _.assign(state.all.data[indx], res.data);
      state.all = _.cloneDeep(state.all);
    }
  },

  NEW_FEE (state, res) {
    state.active.fees.push(res.data);
    state.active = _.cloneDeep(state.active);
  },

  NEW_DYNAMIC_FEE (state, res) {
    state.active.dynamicFees.push(res.data);
    state.active = _.cloneDeep(state.active);
  },

  UPDATE_CHANNEL_FEE (state, res) {
    let indx = _.findIndex(state.active.fees, (item) => item.id == res.data.id);
    if(indx > -1) {
      state.active.fees[indx] = _.assign(state.active.fees[indx], res.data);
    }
    state.active = _.cloneDeep(state.active);
  },

  UPDATE_DYNAMIC_CHANNEL_FEE (state, res) {
    let indx = _.findIndex(state.active.dynamicFees, (item) => item.id == res.data.id);
    if(indx > -1) {
      state.active.dynamicFees[indx] = _.assign(state.active.dynamicFees[indx], res.data);
    }
    state.active = _.cloneDeep(state.active);
  },

  REMOVE_CHANNEL_FEE (state, res) {
    state.active.fees = state.active.fees.filter((item) => {
      return item.id != res.data.id
    });
    state.active = _.cloneDeep(state.active);
  },

  REMOVE_DYNAMIC_CHANNEL_FEE (state, res) {
    state.active.dynamicFees = state.active.dynamicFees.filter((item) => {
      return item.id != res.data.id
    });
    state.active = _.cloneDeep(state.active);
  },

  UPDATE_CHANNEL_FEES (state, res) {
    state.active.fees = res.data;
    state.active = _.cloneDeep(state.active);
  },

  UPDATE_NO_SHOW_RESERVATION (state, data) {
    let active = state.active_reservation;
    if (!_.isEmpty(state.active.reservations)) {
      let index = state.active.reservations.data.findIndex(i => i.id === active.id);
      state.active.reservations.data[index].no_show = data.no_show;
    }
    
  },

  //ERRORS
  CHANNELS_ERROR (state, err) {
    try {
      state.errors = err.response.data;
    } catch (e) {
      state.errors = err;
    }
  },
  CLEAR_ERRORS (state) {
    state.errors = null
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
