var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "layout",
    { attrs: { "no-box": true, title: _vm.location ? _vm.location.name : "" } },
    [
      _c("tabs"),
      _vm._v(" "),
      _c("div", { staticClass: "box" }, [
        _c("div", { staticClass: "box-body" }, [
          _vm.loading
            ? _c("div", { staticClass: "text-center" }, [
                _c("i", { staticClass: "fa fa-spinner fa-spin fa-3x fa-fw" })
              ])
            : _c(
                "div",
                {},
                [
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-sm-4 margin-bottom-3x form-inline" },
                      [
                        _c("div", { staticClass: "margin-bottom-2x" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c(
                              "select",
                              { ref: "types", staticClass: "form-control" },
                              [
                                _c("option", { domProps: { value: null } }, [
                                  _vm._v(
                                    "\n                    -- Select --\n                  "
                                  )
                                ]),
                                _vm._v(" "),
                                _c(
                                  "option",
                                  {
                                    directives: [
                                      {
                                        name: "hasPermits",
                                        rawName: "v-hasPermits:allow",
                                        value: {
                                          id: _vm.location_id,
                                          permits: ["longTermDiscount_list"]
                                        },
                                        expression:
                                          "{id: location_id, permits: ['longTermDiscount_list']}",
                                        arg: "allow"
                                      }
                                    ],
                                    attrs: { value: "long_terms" },
                                    domProps: {
                                      selected: _vm.type == "long_terms"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                    Long Term Discounts\n                  "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "option",
                                  {
                                    directives: [
                                      {
                                        name: "hasPermits",
                                        rawName: "v-hasPermits:allow",
                                        value: {
                                          id: _vm.location_id,
                                          permits: [
                                            "locationRate_adjustment_list"
                                          ]
                                        },
                                        expression:
                                          "{id: location_id, permits: ['locationRate_adjustment_list']}",
                                        arg: "allow"
                                      }
                                    ],
                                    attrs: { value: "adjustments" },
                                    domProps: {
                                      selected: _vm.type == "adjustments"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                    Rate Adjustments\n                  "
                                    )
                                  ]
                                )
                              ]
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-primary",
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.go.apply(null, arguments)
                                }
                              }
                            },
                            [_vm._v("\n                Go\n              ")]
                          )
                        ]),
                        _vm._v(" "),
                        _vm.type == "adjustments"
                          ? _c("div", {}, [
                              _c("div", { staticClass: "input-group radio" }, [
                                _c(
                                  "label",
                                  { staticClass: "margin-right-2x" },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.filter,
                                          expression: "filter"
                                        }
                                      ],
                                      attrs: { type: "radio", value: "all" },
                                      domProps: {
                                        checked: _vm._q(_vm.filter, "all")
                                      },
                                      on: {
                                        change: [
                                          function($event) {
                                            _vm.filter = "all"
                                          },
                                          function($event) {
                                            return _vm.getType()
                                          }
                                        ]
                                      }
                                    }),
                                    _vm._v(
                                      "\n                  All\n                "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "label",
                                  { staticClass: "margin-right-2x" },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.filter,
                                          expression: "filter"
                                        }
                                      ],
                                      attrs: { type: "radio", value: "room" },
                                      domProps: {
                                        checked: _vm._q(_vm.filter, "room")
                                      },
                                      on: {
                                        change: [
                                          function($event) {
                                            _vm.filter = "room"
                                          },
                                          function($event) {
                                            return _vm.getType()
                                          }
                                        ]
                                      }
                                    }),
                                    _vm._v(
                                      "\n                  Rooms\n                "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "label",
                                  { staticClass: "margin-right-2x" },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.filter,
                                          expression: "filter"
                                        }
                                      ],
                                      attrs: {
                                        type: "radio",
                                        value: "parking"
                                      },
                                      domProps: {
                                        checked: _vm._q(_vm.filter, "parking")
                                      },
                                      on: {
                                        change: [
                                          function($event) {
                                            _vm.filter = "parking"
                                          },
                                          function($event) {
                                            return _vm.getType()
                                          }
                                        ]
                                      }
                                    }),
                                    _vm._v(
                                      "\n                  Parkings\n                "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "label",
                                  { staticClass: "margin-right-2x" },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.filter,
                                          expression: "filter"
                                        }
                                      ],
                                      attrs: { type: "radio", value: "bundle" },
                                      domProps: {
                                        checked: _vm._q(_vm.filter, "bundle")
                                      },
                                      on: {
                                        change: [
                                          function($event) {
                                            _vm.filter = "bundle"
                                          },
                                          function($event) {
                                            return _vm.getType()
                                          }
                                        ]
                                      }
                                    }),
                                    _vm._v(
                                      "\n                  Bundles\n                "
                                    )
                                  ]
                                )
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "input-group radio" }, [
                                _c("span", [_vm._v("Adjustment Type : ")]),
                                _vm._v(" "),
                                _c(
                                  "label",
                                  { staticClass: "margin-right-2x" },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.adjType,
                                          expression: "adjType"
                                        }
                                      ],
                                      attrs: { type: "radio", value: "all" },
                                      domProps: {
                                        checked: _vm._q(_vm.adjType, "all")
                                      },
                                      on: {
                                        change: [
                                          function($event) {
                                            _vm.adjType = "all"
                                          },
                                          function($event) {
                                            return _vm.getType()
                                          }
                                        ]
                                      }
                                    }),
                                    _vm._v(
                                      "\n                  All\n                "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "label",
                                  { staticClass: "margin-right-2x" },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.adjType,
                                          expression: "adjType"
                                        }
                                      ],
                                      attrs: { type: "radio", value: "manual" },
                                      domProps: {
                                        checked: _vm._q(_vm.adjType, "manual")
                                      },
                                      on: {
                                        change: [
                                          function($event) {
                                            _vm.adjType = "manual"
                                          },
                                          function($event) {
                                            return _vm.getType()
                                          }
                                        ]
                                      }
                                    }),
                                    _vm._v(
                                      "\n                  Manual\n                "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "label",
                                  { staticClass: "margin-right-2x" },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.adjType,
                                          expression: "adjType"
                                        }
                                      ],
                                      attrs: {
                                        type: "radio",
                                        value: "dynamic"
                                      },
                                      domProps: {
                                        checked: _vm._q(_vm.adjType, "dynamic")
                                      },
                                      on: {
                                        change: [
                                          function($event) {
                                            _vm.adjType = "dynamic"
                                          },
                                          function($event) {
                                            return _vm.getType()
                                          }
                                        ]
                                      }
                                    }),
                                    _vm._v(
                                      "\n                  Dynamic\n                "
                                    )
                                  ]
                                )
                              ])
                            ])
                          : _vm._e()
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _vm.type
                    ? _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "col-md-10 form-inline margin-bottom-3x"
                          },
                          [
                            _vm.type == "adjustments"
                              ? _c("div", { staticClass: "form-group" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.type_name,
                                        expression: "type_name"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "text",
                                      placeholder: "Search by Type Name"
                                    },
                                    domProps: { value: _vm.type_name },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.type_name = $event.target.value
                                      }
                                    }
                                  })
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "form-group" },
                              [
                                _c("datepicker", {
                                  staticClass: "form-control",
                                  attrs: {
                                    "min-date": "none",
                                    placeholder: "Filter by From Date"
                                  },
                                  model: {
                                    value: _vm.from_date,
                                    callback: function($$v) {
                                      _vm.from_date = $$v
                                    },
                                    expression: "from_date"
                                  }
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "form-group" },
                              [
                                _c("datepicker", {
                                  staticClass: "form-control",
                                  attrs: {
                                    "min-date": _vm.from_date,
                                    placeholder: "Filter by To Date"
                                  },
                                  model: {
                                    value: _vm.to_date,
                                    callback: function($$v) {
                                      _vm.to_date = $$v
                                    },
                                    expression: "to_date"
                                  }
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "form-group" }, [
                              _c(
                                "a",
                                {
                                  staticClass: "btn btn-primary btn-md",
                                  attrs: { href: "#" },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.search()
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                  Search\n                "
                                  )
                                ]
                              )
                            ]),
                            _vm._v(" "),
                            _vm.type == "adjustments"
                              ? _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "hasPermits",
                                        rawName: "v-hasPermits:allow",
                                        value: {
                                          id: _vm.location_id,
                                          permits: [
                                            "channelRoomRate_delete",
                                            "channelParkingRate_delete",
                                            "locationBundleRate_delete"
                                          ]
                                        },
                                        expression:
                                          "{id: location_id, permits: ['channelRoomRate_delete', 'channelParkingRate_delete', 'locationBundleRate_delete']}",
                                        arg: "allow"
                                      }
                                    ],
                                    staticClass: "form-group"
                                  },
                                  [
                                    _vm.loadings[
                                      _vm.adjustments.data
                                        .rates_adjustments_history_id
                                    ]
                                      ? _c("span", [
                                          _c("i", {
                                            staticClass: "fa fa-spin fa-spinner"
                                          })
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "a",
                                      {
                                        staticClass: "btn btn-danger",
                                        attrs: {
                                          href: "#",
                                          disabled: _vm.isDisabled
                                        },
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                            return _vm.clearHistory()
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                  Clear History\n                  "
                                        ),
                                        _c("i", {
                                          staticClass: "fa fa-trash",
                                          attrs: { "aria-hidden": "true" }
                                        })
                                      ]
                                    )
                                  ]
                                )
                              : _vm._e()
                          ]
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.type == "long_terms"
                    ? _c(
                        "div",
                        {},
                        [
                          _c("div", { staticClass: "table-responsive" }, [
                            _c(
                              "table",
                              { staticClass: "table table-striped" },
                              [
                                _c("thead", [
                                  _c("tr", [
                                    _c("th", [
                                      _vm._v(
                                        "\n                    Number Of Days\n                  "
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("th", [
                                      _vm._v(
                                        "\n                    Discount Amount\n                  "
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("th", [
                                      _vm._v(
                                        "\n                    From Date\n                  "
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("th", [
                                      _vm._v(
                                        "\n                    To Date\n                  "
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("th", [
                                      _vm._v(
                                        "\n                    Details\n                  "
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "th",
                                      {
                                        directives: [
                                          {
                                            name: "hasPermits",
                                            rawName: "v-hasPermits:allow",
                                            value: {
                                              id: _vm.location_id,
                                              permits: [
                                                "longTermDiscount_delete"
                                              ]
                                            },
                                            expression:
                                              "{id: location_id, permits: ['longTermDiscount_delete']}",
                                            arg: "allow"
                                          }
                                        ]
                                      },
                                      [
                                        _vm._v(
                                          "\n                    Remove\n                  "
                                        )
                                      ]
                                    )
                                  ])
                                ]),
                                _vm._v(" "),
                                _c(
                                  "tbody",
                                  _vm._l(_vm.longTerms.data, function(long) {
                                    return _c("tr", [
                                      _c("td", [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(long.number_of_days) +
                                            "\n                  "
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c("td", [
                                        _vm._v(
                                          "\n                    %" +
                                            _vm._s(long.discount) +
                                            "\n                  "
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c("td", [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              _vm._f("dateFormat")(
                                                long.from_date,
                                                "MM/DD/YYYY"
                                              )
                                            ) +
                                            "\n                  "
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c("td", [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              _vm._f("dateFormat")(
                                                long.to_date,
                                                "MM/DD/YYYY"
                                              )
                                            ) +
                                            "\n                  "
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c("td", [
                                        _c(
                                          "a",
                                          {
                                            attrs: { href: "#" },
                                            on: {
                                              click: function($event) {
                                                $event.preventDefault()
                                                return _vm.showLongTerm(long)
                                              }
                                            }
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "fa fa-eye",
                                              attrs: { "aria-hidden": "true" }
                                            })
                                          ]
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        {
                                          directives: [
                                            {
                                              name: "hasPermits",
                                              rawName: "v-hasPermits:allow",
                                              value: {
                                                id: _vm.location_id,
                                                permits: [
                                                  "longTermDiscount_delete"
                                                ]
                                              },
                                              expression:
                                                "{id: location_id, permits: ['longTermDiscount_delete']}",
                                              arg: "allow"
                                            }
                                          ]
                                        },
                                        [
                                          _vm.loadings[long.id]
                                            ? _c("span", [
                                                _c("i", {
                                                  staticClass:
                                                    "fa fa-spin fa-spinner"
                                                })
                                              ])
                                            : _c(
                                                "a",
                                                {
                                                  staticClass: "text-danger",
                                                  attrs: { href: "#" },
                                                  on: {
                                                    click: function($event) {
                                                      $event.preventDefault()
                                                      return _vm.removeLong(
                                                        long
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass: "fa fa-times",
                                                    attrs: {
                                                      "aria-hidden": "true"
                                                    }
                                                  })
                                                ]
                                              )
                                        ]
                                      )
                                    ])
                                  }),
                                  0
                                )
                              ]
                            )
                          ]),
                          _vm._v(" "),
                          _c("pagination", {
                            attrs: { "pages-number": _vm.longTerms.last_page },
                            on: {
                              pageChanged: function(page) {
                                return _vm.getLongTerm(page)
                              }
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.type == "adjustments"
                    ? _c(
                        "div",
                        {},
                        [
                          _c("div", { staticClass: "table-responsive" }, [
                            _c(
                              "table",
                              { staticClass: "table table-striped" },
                              [
                                _c("thead", [
                                  _c("tr", [
                                    _c("th"),
                                    _vm._v(" "),
                                    _c("th", [
                                      _vm._v(
                                        "\n                    Action\n                  "
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("th", [
                                      _vm._v(
                                        "\n                    Type Name\n                  "
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("th", [
                                      _vm._v(
                                        "\n                    From Date\n                  "
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("th", [
                                      _vm._v(
                                        "\n                    To Date\n                  "
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("th", [
                                      _vm._v(
                                        "\n                    Adjustment Type\n                  "
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("th", [
                                      _vm._v(
                                        "\n                    Details\n                  "
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "th",
                                      {
                                        directives: [
                                          {
                                            name: "hasPermits",
                                            rawName: "v-hasPermits:allow",
                                            value: {
                                              id: _vm.location_id,
                                              permits: [
                                                "channelRoomRate_delete",
                                                "channelParkingRate_delete",
                                                "locationBundleRate_delete"
                                              ]
                                            },
                                            expression:
                                              "{id: location_id, permits: ['channelRoomRate_delete', 'channelParkingRate_delete', 'locationBundleRate_delete']}",
                                            arg: "allow"
                                          }
                                        ]
                                      },
                                      [
                                        _vm._v(
                                          "\n                    Remove\n                  "
                                        )
                                      ]
                                    )
                                  ])
                                ]),
                                _vm._v(" "),
                                _c(
                                  "tbody",
                                  [
                                    _vm._l(_vm.adjustments.data, function(
                                      adj,
                                      index
                                    ) {
                                      return [
                                        _c("tr", [
                                          adj.rates_adjustments_history_id !=
                                          null
                                            ? _c(
                                                "td",
                                                {
                                                  class: {
                                                    opened: _vm.opened.includes(
                                                      index
                                                    )
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.toggle(index)
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm.arrow[index] == "up"
                                                    ? _c("span", {
                                                        staticClass:
                                                          "fa fa-caret-up"
                                                      })
                                                    : _c("span", {
                                                        staticClass:
                                                          "fa fa-caret-down"
                                                      })
                                                ]
                                              )
                                            : _c("td"),
                                          _vm._v(" "),
                                          _c("td", [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(
                                                  _vm._f("capitalize")(
                                                    _vm.actionTaken(adj)
                                                  )
                                                ) +
                                                "\n                  "
                                            )
                                          ]),
                                          _vm._v(" "),
                                          adj.rates_adjustments_history_id ==
                                          null
                                            ? _c("td", [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(adj.type_name) +
                                                    "\n                  "
                                                )
                                              ])
                                            : _c(
                                                "td",
                                                _vm._l(adj.type, function(
                                                  type,
                                                  index
                                                ) {
                                                  return _c(
                                                    "span",
                                                    { key: type },
                                                    [
                                                      index != 0
                                                        ? _c("span", [
                                                            _vm._v(", ")
                                                          ])
                                                        : _vm._e(),
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm._f(
                                                              "capitalize"
                                                            )(type)
                                                          )
                                                        )
                                                      ])
                                                    ]
                                                  )
                                                }),
                                                0
                                              ),
                                          _vm._v(" "),
                                          _c("td", [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(
                                                  _vm._f("dateFormat")(
                                                    adj.from_date,
                                                    "MM/DD/YYYY"
                                                  )
                                                ) +
                                                "\n                  "
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c("td", [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(
                                                  _vm._f("dateFormat")(
                                                    adj.to_date,
                                                    "MM/DD/YYYY"
                                                  )
                                                ) +
                                                "\n                  "
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c("td", [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(
                                                  adj.automation
                                                    ? "Dynamic"
                                                    : "Manual"
                                                ) +
                                                "\n                  "
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c("td", [
                                            _c(
                                              "a",
                                              {
                                                attrs: { href: "#" },
                                                on: {
                                                  click: function($event) {
                                                    $event.preventDefault()
                                                    return _vm.showAdjustment(
                                                      adj
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c("i", {
                                                  staticClass: "fa fa-eye",
                                                  attrs: {
                                                    "aria-hidden": "true"
                                                  }
                                                })
                                              ]
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "td",
                                            {
                                              directives: [
                                                {
                                                  name: "hasPermits",
                                                  rawName: "v-hasPermits:allow",
                                                  value: {
                                                    id: _vm.location_id,
                                                    permits: [
                                                      "channelRoomRate_delete",
                                                      "channelParkingRate_delete",
                                                      "locationBundleRate_delete"
                                                    ]
                                                  },
                                                  expression:
                                                    "{id: location_id, permits: ['channelRoomRate_delete', 'channelParkingRate_delete', 'locationBundleRate_delete']}",
                                                  arg: "allow"
                                                }
                                              ]
                                            },
                                            [
                                              _vm.loadings[adj.id] ||
                                              _vm.loadings[
                                                adj.rates_adjustments_history_id
                                              ]
                                                ? _c("span", [
                                                    _c("i", {
                                                      staticClass:
                                                        "fa fa-spin fa-spinner"
                                                    })
                                                  ])
                                                : _c("div", [
                                                    adj.rates_adjustments_history_id ==
                                                    null
                                                      ? _c(
                                                          "a",
                                                          {
                                                            staticClass:
                                                              "text-danger",
                                                            attrs: {
                                                              href: "#"
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                $event.preventDefault()
                                                                return _vm.removeAdjustment(
                                                                  adj
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "fa fa-times",
                                                              attrs: {
                                                                "aria-hidden":
                                                                  "true"
                                                              }
                                                            })
                                                          ]
                                                        )
                                                      : _c(
                                                          "a",
                                                          {
                                                            staticClass:
                                                              "text-danger",
                                                            attrs: {
                                                              href: "#"
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                $event.preventDefault()
                                                                return _vm.removeAdjustments(
                                                                  adj
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "fa fa-times",
                                                              attrs: {
                                                                "aria-hidden":
                                                                  "true"
                                                              }
                                                            })
                                                          ]
                                                        )
                                                  ])
                                            ]
                                          )
                                        ]),
                                        _vm._v(" "),
                                        _vm._l(
                                          adj.channel_parking_rates,
                                          function(rate, i) {
                                            return _vm.opened.includes(index)
                                              ? _c("tr", [
                                                  _c("td"),
                                                  _vm._v(" "),
                                                  _c("td", [
                                                    _vm._v("  Parking")
                                                  ]),
                                                  _vm._v(" "),
                                                  _c("td", [
                                                    _vm._v(
                                                      _vm._s(
                                                        rate
                                                          .location_parking_type
                                                          .name
                                                      )
                                                    )
                                                  ]),
                                                  _vm._v(" "),
                                                  _c("td", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f("dateFormat")(
                                                          rate.from_date,
                                                          "MM/DD/YYYY"
                                                        )
                                                      )
                                                    )
                                                  ]),
                                                  _vm._v(" "),
                                                  _c("td", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f("dateFormat")(
                                                          rate.to_date,
                                                          "MM/DD/YYYY"
                                                        )
                                                      )
                                                    )
                                                  ]),
                                                  _vm._v(" "),
                                                  _c("td", [
                                                    _vm._v(
                                                      _vm._s(
                                                        rate.automation
                                                          ? "Dynamic"
                                                          : "Manual"
                                                      )
                                                    )
                                                  ]),
                                                  _vm._v(" "),
                                                  _c("td", [
                                                    _c(
                                                      "a",
                                                      {
                                                        attrs: { href: "#" },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            $event.preventDefault()
                                                            return _vm.showAdjustment(
                                                              rate
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "fa fa-eye",
                                                          attrs: {
                                                            "aria-hidden":
                                                              "true"
                                                          }
                                                        })
                                                      ]
                                                    )
                                                  ]),
                                                  _vm._v(" "),
                                                  _c("td")
                                                ])
                                              : _vm._e()
                                          }
                                        ),
                                        _vm._v(" "),
                                        _vm._l(adj.channel_room_rates, function(
                                          rate,
                                          i
                                        ) {
                                          return _vm.opened.includes(index)
                                            ? _c("tr", [
                                                _c("td"),
                                                _vm._v(" "),
                                                _c("td", [_vm._v("  Room")]),
                                                _vm._v(" "),
                                                _c("td", [
                                                  _vm._v(
                                                    _vm._s(
                                                      rate.location_room_type
                                                        .name
                                                    )
                                                  )
                                                ]),
                                                _vm._v(" "),
                                                _c("td", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm._f("dateFormat")(
                                                        rate.from_date,
                                                        "MM/DD/YYYY"
                                                      )
                                                    )
                                                  )
                                                ]),
                                                _vm._v(" "),
                                                _c("td", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm._f("dateFormat")(
                                                        rate.to_date,
                                                        "MM/DD/YYYY"
                                                      )
                                                    )
                                                  )
                                                ]),
                                                _vm._v(" "),
                                                _c("td", [
                                                  _c(
                                                    "a",
                                                    {
                                                      attrs: { href: "#" },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                          return _vm.showAdjustment(
                                                            rate
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "fa fa-eye",
                                                        attrs: {
                                                          "aria-hidden": "true"
                                                        }
                                                      })
                                                    ]
                                                  )
                                                ]),
                                                _vm._v(" "),
                                                _c("td")
                                              ])
                                            : _vm._e()
                                        }),
                                        _vm._v(" "),
                                        _vm._l(
                                          adj.location_bundle_rates,
                                          function(rate, i) {
                                            return _vm.opened.includes(index)
                                              ? _c("tr", [
                                                  _c("td"),
                                                  _vm._v(" "),
                                                  _c("td", [
                                                    _vm._v("  Bundle")
                                                  ]),
                                                  _vm._v(" "),
                                                  _c("td", [
                                                    _vm._v(
                                                      _vm._s(
                                                        rate.location_bundle
                                                          .name
                                                      )
                                                    )
                                                  ]),
                                                  _vm._v(" "),
                                                  _c("td", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f("dateFormat")(
                                                          rate.from_date,
                                                          "MM/DD/YYYY"
                                                        )
                                                      )
                                                    )
                                                  ]),
                                                  _vm._v(" "),
                                                  _c("td", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f("dateFormat")(
                                                          rate.to_date,
                                                          "MM/DD/YYYY"
                                                        )
                                                      )
                                                    )
                                                  ]),
                                                  _vm._v(" "),
                                                  _c("td", [
                                                    _vm._v(
                                                      _vm._s(
                                                        rate.automation
                                                          ? "Dynamic"
                                                          : "Manual"
                                                      )
                                                    )
                                                  ]),
                                                  _vm._v(" "),
                                                  _c("td", [
                                                    _c(
                                                      "a",
                                                      {
                                                        attrs: { href: "#" },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            $event.preventDefault()
                                                            return _vm.showAdjustment(
                                                              rate
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "fa fa-eye",
                                                          attrs: {
                                                            "aria-hidden":
                                                              "true"
                                                          }
                                                        })
                                                      ]
                                                    )
                                                  ]),
                                                  _vm._v(" "),
                                                  _c("td")
                                                ])
                                              : _vm._e()
                                          }
                                        )
                                      ]
                                    })
                                  ],
                                  2
                                )
                              ]
                            )
                          ]),
                          _vm._v(" "),
                          _c("pagination", {
                            attrs: {
                              "pages-number": _vm.adjustments.last_page
                            },
                            on: {
                              pageChanged: function(page) {
                                return _vm.getAdjustments(page)
                              }
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "func-modal",
                    {
                      ref: "modal",
                      attrs: { title: _vm.modalTitle },
                      scopedSlots: _vm._u([
                        {
                          key: "footer",
                          fn: function(props) {
                            return [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-default",
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return props.close()
                                    }
                                  }
                                },
                                [_vm._v("Close")]
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _c("template", { slot: "body" }, [
                        _vm.type == "long_terms" &&
                        _vm.active_long_term_discount
                          ? _c("div", [
                              _c(
                                "table",
                                { staticClass: "table table-bordered" },
                                [
                                  _c("tbody", [
                                    _c("tr", [
                                      _c("th", [_vm._v(" Number of Days ")]),
                                      _vm._v(" "),
                                      _c("td", [
                                        _vm._v(
                                          "\n                      " +
                                            _vm._s(
                                              _vm.active_long_term_discount
                                                .number_of_days
                                            ) +
                                            "\n                    "
                                        )
                                      ])
                                    ]),
                                    _vm._v(" "),
                                    _c("tr", [
                                      _c("th", [_vm._v(" Discount ")]),
                                      _vm._v(" "),
                                      _c("td", [
                                        _vm._v(
                                          "\n                      %" +
                                            _vm._s(
                                              _vm.active_long_term_discount
                                                .discount
                                            ) +
                                            "\n                    "
                                        )
                                      ])
                                    ]),
                                    _vm._v(" "),
                                    _c("tr", [
                                      _c("th", [_vm._v(" From Date ")]),
                                      _vm._v(" "),
                                      _c("td", [
                                        _vm._v(
                                          "\n                      " +
                                            _vm._s(
                                              _vm._f("dateFormat")(
                                                _vm.active_long_term_discount
                                                  .from_date,
                                                "MM/DD/YYYY"
                                              )
                                            ) +
                                            "\n                    "
                                        )
                                      ])
                                    ]),
                                    _vm._v(" "),
                                    _c("tr", [
                                      _c("th", [_vm._v(" To Date ")]),
                                      _vm._v(" "),
                                      _c("td", [
                                        _vm._v(
                                          "\n                      " +
                                            _vm._s(
                                              _vm._f("dateFormat")(
                                                _vm.active_long_term_discount
                                                  .to_date,
                                                "MM/DD/YYYY"
                                              )
                                            ) +
                                            "\n                    "
                                        )
                                      ])
                                    ]),
                                    _vm._v(" "),
                                    _c("tr", [
                                      _c("th", [
                                        _vm._v(" Eligible Rooms Types ")
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        _vm._l(
                                          _vm.active_long_term_discount
                                            .room_types,
                                          function(rtype) {
                                            return _c("div", [
                                              _vm._v(
                                                "\n                        " +
                                                  _vm._s(rtype.name) +
                                                  "\n                      "
                                              )
                                            ])
                                          }
                                        ),
                                        0
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("tr", [
                                      _c("th", [
                                        _vm._v(" Eligible Parking Types ")
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        _vm._l(
                                          _vm.active_long_term_discount
                                            .parking_types,
                                          function(ptype) {
                                            return _c("div", [
                                              _vm._v(
                                                "\n                        " +
                                                  _vm._s(ptype.name) +
                                                  "\n                      "
                                              )
                                            ])
                                          }
                                        ),
                                        0
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "tr",
                                      {
                                        directives: [
                                          {
                                            name: "hasPermits",
                                            rawName: "v-hasPermits:prevent",
                                            value: ["channel_user"],
                                            expression: "['channel_user']",
                                            arg: "prevent"
                                          }
                                        ]
                                      },
                                      [
                                        _c("th", [_vm._v(" channels ")]),
                                        _vm._v(" "),
                                        _c("td", [
                                          _c(
                                            "table",
                                            { staticClass: "table" },
                                            _vm._l(
                                              _vm.active_long_term_discount
                                                .location_channels,
                                              function(channel) {
                                                return _c("tr", [
                                                  _c("td", [
                                                    _vm._v(
                                                      "\n                            " +
                                                        _vm._s(
                                                          channel.channel.name
                                                        ) +
                                                        "\n                          "
                                                    )
                                                  ]),
                                                  _vm._v(" "),
                                                  _c("td", [
                                                    _vm._v(
                                                      "\n                            " +
                                                        _vm._s(
                                                          channel.channel.domain
                                                        ) +
                                                        "\n                          "
                                                    )
                                                  ])
                                                ])
                                              }
                                            ),
                                            0
                                          )
                                        ])
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _vm.active_long_term_discount.user
                                      ? _c(
                                          "tr",
                                          {
                                            directives: [
                                              {
                                                name: "hasPermits",
                                                rawName: "v-hasPermits:allow",
                                                value: ["super_admin"],
                                                expression: "['super_admin']",
                                                arg: "allow"
                                              }
                                            ]
                                          },
                                          [
                                            _c("th", [_vm._v(" User ")]),
                                            _vm._v(" "),
                                            _c("td", [
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(
                                                    _vm
                                                      .active_long_term_discount
                                                      .user.name
                                                  ) +
                                                  "\n                    "
                                              )
                                            ])
                                          ]
                                        )
                                      : _vm._e()
                                  ])
                                ]
                              )
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.type == "adjustments" && _vm.active_rate_adjustment
                          ? _c("div", [
                              _c(
                                "table",
                                { staticClass: "table table-bordered" },
                                [
                                  _c("tbody", [
                                    !_vm.active_rate_adjustment.channels
                                      ? _c("tr", [
                                          _c("th", [_vm._v(" Price ")]),
                                          _vm._v(" "),
                                          _c("td", [
                                            _vm._v(
                                              "\n                      " +
                                                _vm._s(
                                                  _vm._f("currency")(
                                                    _vm.active_rate_adjustment
                                                      .price
                                                  )
                                                ) +
                                                "\n                    "
                                            )
                                          ])
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("tr", [
                                      _c("th", [_vm._v(" Modification Type ")]),
                                      _vm._v(" "),
                                      _c("td", [
                                        _vm._v(
                                          "\n                      " +
                                            _vm._s(
                                              _vm.active_rate_adjustment
                                                .modification_type
                                            ) +
                                            "\n                    "
                                        )
                                      ])
                                    ]),
                                    _vm._v(" "),
                                    _c("tr", [
                                      _c("th", [
                                        _vm._v(" Modification Amount ")
                                      ]),
                                      _vm._v(" "),
                                      _c("td", [
                                        _vm._v(
                                          "\n                      " +
                                            _vm._s(
                                              _vm.active_rate_adjustment
                                                .modification_amount
                                            ) +
                                            "\n                    "
                                        )
                                      ])
                                    ]),
                                    _vm._v(" "),
                                    _c("tr", [
                                      _c("th", [_vm._v(" Amount Type ")]),
                                      _vm._v(" "),
                                      _c("td", [
                                        _vm._v(
                                          "\n                      " +
                                            _vm._s(
                                              _vm.active_rate_adjustment
                                                .modification_amount_type
                                            ) +
                                            "\n                    "
                                        )
                                      ])
                                    ]),
                                    _vm._v(" "),
                                    _c("tr", [
                                      _c("th", [_vm._v(" From Date ")]),
                                      _vm._v(" "),
                                      _c("td", [
                                        _vm._v(
                                          "\n                      " +
                                            _vm._s(
                                              _vm._f("dateFormat")(
                                                _vm.active_rate_adjustment
                                                  .from_date,
                                                "MM/DD/YYYY"
                                              )
                                            ) +
                                            "\n                    "
                                        )
                                      ])
                                    ]),
                                    _vm._v(" "),
                                    _c("tr", [
                                      _c("th", [_vm._v(" To Date ")]),
                                      _vm._v(" "),
                                      _c("td", [
                                        _vm._v(
                                          "\n                      " +
                                            _vm._s(
                                              _vm._f("dateFormat")(
                                                _vm.active_rate_adjustment
                                                  .to_date,
                                                "MM/DD/YYYY"
                                              )
                                            ) +
                                            "\n                    "
                                        )
                                      ])
                                    ]),
                                    _vm._v(" "),
                                    _c("tr", [
                                      _c("th", [_vm._v(" Eligible Days ")]),
                                      _vm._v(" "),
                                      _c("td", [
                                        _vm._v(
                                          "\n                      " +
                                            _vm._s(
                                              _vm.trueDays(
                                                _vm.active_rate_adjustment
                                              )
                                            ) +
                                            "\n                    "
                                        )
                                      ])
                                    ]),
                                    _vm._v(" "),
                                    _c("tr", [
                                      _c("th", [_vm._v(" Type ")]),
                                      _vm._v(" "),
                                      _vm.active_rate_adjustment
                                        .rates_adjustments_history_id == null
                                        ? _c("td", [
                                            _vm._v(
                                              "\n                      " +
                                                _vm._s(
                                                  _vm.active_rate_adjustment
                                                    .type
                                                ) +
                                                "\n                    "
                                            )
                                          ])
                                        : _c("td", [
                                            _vm.active_rate_adjustment
                                              .location_parking_type
                                              ? _c("div", [
                                                  _vm._v(
                                                    "\n                        Parking\n                      "
                                                  )
                                                ])
                                              : _vm.active_rate_adjustment
                                                  .location_room_type
                                              ? _c("div", [
                                                  _vm._v(
                                                    "\n                        Room\n                      "
                                                  )
                                                ])
                                              : _vm.active_rate_adjustment
                                                  .location_bundle
                                              ? _c("div", [
                                                  _vm._v(
                                                    "\n                        Bundle\n                      "
                                                  )
                                                ])
                                              : _c(
                                                  "div",
                                                  _vm._l(
                                                    _vm.active_rate_adjustment
                                                      .type,
                                                    function(type, index) {
                                                      return _c(
                                                        "span",
                                                        { key: type },
                                                        [
                                                          index != 0
                                                            ? _c("span", [
                                                                _vm._v(", ")
                                                              ])
                                                            : _vm._e(),
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm._f(
                                                                  "capitalize"
                                                                )(type)
                                                              )
                                                            )
                                                          ])
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                )
                                          ])
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "tr",
                                      [
                                        _c("th", [_vm._v(" Type Name ")]),
                                        _vm._v(" "),
                                        _vm.active_rate_adjustment.types_names
                                          ? [
                                              _c(
                                                "td",
                                                _vm._l(
                                                  _vm.active_rate_adjustment
                                                    .types_names,
                                                  function(typeName, index) {
                                                    return _c(
                                                      "span",
                                                      { key: typeName },
                                                      [
                                                        index != 0
                                                          ? _c("span", [
                                                              _vm._v(", ")
                                                            ])
                                                          : _vm._e(),
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(typeName)
                                                          )
                                                        ])
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            ]
                                          : [
                                              _vm.active_rate_adjustment
                                                .rates_adjustments_history_id ==
                                              null
                                                ? _c("td", [
                                                    _vm._v(
                                                      "\n                        " +
                                                        _vm._s(
                                                          _vm
                                                            .active_rate_adjustment
                                                            .type_name
                                                        ) +
                                                        "\n                      "
                                                    )
                                                  ])
                                                : _c("td", [
                                                    _vm.active_rate_adjustment
                                                      .location_parking_type
                                                      ? _c("div", [
                                                          _vm._v(
                                                            "\n                          " +
                                                              _vm._s(
                                                                _vm
                                                                  .active_rate_adjustment
                                                                  .location_parking_type
                                                                  .name
                                                              ) +
                                                              "\n                        "
                                                          )
                                                        ])
                                                      : _vm
                                                          .active_rate_adjustment
                                                          .location_room_type
                                                      ? _c("div", [
                                                          _vm._v(
                                                            "\n                          " +
                                                              _vm._s(
                                                                _vm
                                                                  .active_rate_adjustment
                                                                  .location_room_type
                                                                  .name
                                                              ) +
                                                              "\n                        "
                                                          )
                                                        ])
                                                      : _vm
                                                          .active_rate_adjustment
                                                          .location_bundle
                                                      ? _c("div", [
                                                          _vm._v(
                                                            "\n                          " +
                                                              _vm._s(
                                                                _vm
                                                                  .active_rate_adjustment
                                                                  .location_bundle
                                                                  .name
                                                              ) +
                                                              "\n                        "
                                                          )
                                                        ])
                                                      : _vm._e()
                                                  ])
                                            ]
                                      ],
                                      2
                                    ),
                                    _vm._v(" "),
                                    _c("tr", [
                                      _c("th", [_vm._v(" Adjustment Type ")]),
                                      _vm._v(" "),
                                      _c("td", [
                                        _vm._v(
                                          "\n                      " +
                                            _vm._s(
                                              _vm.active_rate_adjustment
                                                .automation
                                                ? "Dynamic"
                                                : "Manual"
                                            ) +
                                            "\n                    "
                                        )
                                      ])
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "tr",
                                      {
                                        directives: [
                                          {
                                            name: "hasPermits",
                                            rawName: "v-hasPermits:prevent",
                                            value: ["channel_user"],
                                            expression: "['channel_user']",
                                            arg: "prevent"
                                          }
                                        ]
                                      },
                                      [
                                        _c("th", [_vm._v(" channels ")]),
                                        _vm._v(" "),
                                        _c("td", [
                                          _c(
                                            "table",
                                            { staticClass: "table" },
                                            [
                                              _vm.active_rate_adjustment
                                                .channels
                                                ? _vm._l(
                                                    _vm.active_rate_adjustment
                                                      .channels,
                                                    function(channel) {
                                                      return _c("tr", [
                                                        _c("td", [
                                                          _vm._v(
                                                            "\n                              " +
                                                              _vm._s(
                                                                channel.channel
                                                                  .name
                                                              ) +
                                                              "\n                            "
                                                          )
                                                        ]),
                                                        _vm._v(" "),
                                                        _c("td", [
                                                          _vm._v(
                                                            "\n                              " +
                                                              _vm._s(
                                                                channel.channel
                                                                  .domain
                                                              ) +
                                                              "\n                            "
                                                          )
                                                        ])
                                                      ])
                                                    }
                                                  )
                                                : _vm._l(
                                                    _vm.active_rate_adjustment
                                                      .location_channels,
                                                    function(channel) {
                                                      return _c("tr", [
                                                        _c("td", [
                                                          _vm._v(
                                                            "\n                              " +
                                                              _vm._s(
                                                                channel.channel
                                                                  .name
                                                              ) +
                                                              "\n                            "
                                                          )
                                                        ]),
                                                        _vm._v(" "),
                                                        _c("td", [
                                                          _vm._v(
                                                            "\n                              " +
                                                              _vm._s(
                                                                channel.channel
                                                                  .domain
                                                              ) +
                                                              "\n                            "
                                                          )
                                                        ])
                                                      ])
                                                    }
                                                  )
                                            ],
                                            2
                                          )
                                        ])
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _vm.active_rate_adjustment.user
                                      ? _c(
                                          "tr",
                                          {
                                            directives: [
                                              {
                                                name: "hasPermits",
                                                rawName: "v-hasPermits:allow",
                                                value: ["super_admin"],
                                                expression: "['super_admin']",
                                                arg: "allow"
                                              }
                                            ]
                                          },
                                          [
                                            _c("th", [_vm._v(" User ")]),
                                            _vm._v(" "),
                                            _c("td", [
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(
                                                    _vm.active_rate_adjustment
                                                      .user.name
                                                  ) +
                                                  "\n                    "
                                              )
                                            ])
                                          ]
                                        )
                                      : _vm._e()
                                  ])
                                ]
                              )
                            ])
                          : _vm._e()
                      ])
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c("confirm", {
                    ref: "deleteConfirm",
                    attrs: { type: "danger" }
                  })
                ],
                1
              )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-95cf4546", { render: render, staticRenderFns: staticRenderFns })
  }
}