import toastr from 'toastr'
import layout from 'layouts/default'
import placesAutocomplete from 'components/placesAutocomplete'
// import tipTap from 'components/tipTap'
import googleMapsMovableMarker from 'components/googleMapsMovableMarker'
import tabs from '../tabs'
import {dateTime} from 'services'
import { hasPermits } from 'directives'
import { permits } from 'services'
import shuttleSchedule from './shuttleSchedule'
import integrationSystemAttributes from './integrationSystemAttributes'
import switchBtn from 'components/switchBtn'
import timepicker from 'components/timepicker'
import moment from 'moment'
import { user, helper} from 'services'
import { dateFormat } from 'filters'
// import './details.scss'

const directives = {
  hasPermits
}

const filters = {
  dateFormat,
}

const components = {
  layout,
  tabs,
  placesAutocomplete,
  googleMapsMovableMarker,
  shuttleSchedule,
  integrationSystemAttributes,
  switchBtn,
  timepicker,
  tipTap: () => import('components/tipTap'),
}

const computed = {
  location () {
    return this.$store.state.locations.active;
  },
  countries () {
    return [{name: "Select Country", id: null}].concat(this.$store.state.address.countries);
  },
  states () {
    let states = [{name: "Select State", id: null}];
    if (this.form.country_id) {

      let indx = _.findIndex(this.countries, item => item.id == this.form.country_id);
      if (indx > -1) {
        states = states.concat(this.countries[indx].states);
      }
    }
    console.log(states);
    return states;
  },
  timezones () {
    return [{name: "Select Timezone", id: null}].concat(this.$store.state.timezones.all);
  },
  center () {
    if (this.googlePlace) {
      let location = {
        lat: this.googlePlace.geometry.location.lat(),
        lng: this.googlePlace.geometry.location.lng()
      };
      this.form.latitude = this.googlePlace.geometry.location.lat();
      this.form.longitude = this.googlePlace.geometry.location.lng();
      this.googlePlace = null;
      return location;
    } else if (this.form.latitude && this.form.longitude) {
      let location = {
        lat: parseFloat(this.form.latitude),
        lng: parseFloat(this.form.longitude)
      };
      return location;
    }

    return null
  }
}

const methods = {
  onLocationChanged (location) {
    this.form.latitude = location.lat;
    this.form.longitude = location.lng;
  },

  hasShuttleSchedulePermits() {
    return permits.hasLocationPermits(this.locId,['location_shuttle_schedule_update']);
  },

  async submit () {
    this.errors = {}
    this.processing = true;
    
    // execute random command to force change event to run
    // this.$refs.description.execCommand('refresh');
    // this.$refs.special_conditions.execCommand('refresh');
    // if (!this.form.shuttle_schedule.length) {
    //   this.$refs.shuttle_info_summary.execCommand('refresh');
    // }
    // this.$refs.shuttle_info_details.execCommand('refresh');

    await this.$store.dispatch('locations.get', {id: this.$route.params.id}).then(() => {      
      this.db_location_data = this.$store.state.locations.active;
    });

    if (!helper.validateChanges(this.keys, this.form, this.initiate_location_data, this.db_location_data)) {
      toastr.error("someone else is making changes to this page, please copy your changes, refresh and do it again.");
      this.processing = false;
      return;
    }

    let data = _.cloneDeep(this.form);
    if (this.published) {
      if (data.published_at == null) {
        data.published_at = moment().format('YYYY-MM-DD HH:mm:ss');
      }
    } else {
      data.published_at = null;
    } 
    delete data.channels;
    // data.country_id = this.country;
    // data.state_id = this.state;

    this.$store.dispatch('locations.edit', {id: this.$route.params.id, data}).then(() => {
      this.processing = false;
      if (this.$store.state.locations.errors) {
        this.errors = _.cloneDeep(this.$store.state.locations.errors.errors);
        toastr.error(this.$store.state.locations.errors.message);
      } else {
        toastr.success('Data has been saved');
        this.form.shuttle_info_summary_readable_text = this.$store.state.locations.active.shuttle_info_summary_readable_text;
        this.form.published_at = this.$store.state.locations.active.published_at;
        this.need_to_save = false;
        this.initiate_location_data = _.cloneDeep(this.$store.state.locations.active);
      }
    })
  },
}

export default {
  name: "location-information-details",
  computed,
  components,
  methods,
  directives,
  filters,
  data () {
    return {
      locId: this.$route.params.id,
      loading: false,
      form: {},
      errors: {},
      published: false,
      // timezones: [],
      // country: null,
      // state: null,
      googlePlace: null,
      processing: false,
      user: null,
      times: dateTime.getHalfHours(),
      keys: [
        'name', 'id', 'phone', 'fax', 'send_fax', 'country_id', 'state_id', 'city', 'port_type', 'timezone_id',
        'address', 'zip_code', 'directions', 'send_special_instructions_email', 'number_of_days_before_reservation_starts',
        'special_instructions', 'checkin_time', 'checkout_time', 'extend_reservation_when_check_out', 'tripadvisor_id',
        'netpark_code', 'use_netpark_price', 'integration_system', 'integration_system_attributes', 'front_desk_hours',
        'daily_email_time', 'description', 'special_conditions', 'shuttle_available', 'shuttle_info_summary',
        'shuttle_schedule', 'shuttle_info_summary_readable_text', 'shuttle_info_details', 'exclude_bookings_outside_of_shuttle_hours',
        'latitude', 'longitude'
      ],
      initiate_location_data: {},
      db_location_data: {},
    }
  },
  mounted () {
    this.user = user.getUser();
  },
  created () {
    this.loading = true;
    Promise.all([
      this.$store.dispatch('locations.get', {id: this.$route.params.id}),
      this.$store.dispatch('address.getCountries'),
      this.$store.dispatch('timezones.getAll')
    ]).then(() => {
      this.loading = false;
      this.form = Object.assign({}, this.location);
      this.initiate_location_data = Object.assign({}, this.location);
      if (this.form.published_at) {
        this.published = true;
      }
      // this.timezones = _.cloneDeep(this.$store.state.timezones.all);
      // this.country = this.form.country_id
      // this.state = this.form.state_id
    });
  }
}
