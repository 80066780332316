import { http } from 'services'

// initial state
const state = () => ({
    all: [],
    active: {},
    errors: null
  })
  
  // getters
  const getters = {
    
  }
  
  // actions
  const actions = {
    ['refundRequests.getAll'] ({ commit }, params) {
      if (!params._q) params._q = null
  
      commit('CLEAR_ERRORS')
  
      return (http.get('refund-requests', {
        params
      })).then ((res) => {
        commit('GET_ALL_REFUND_REQUESTS', res);
      })
      .catch ((res) => {
        commit('REFUND_REQUESTS_ERROR', res);
      })
    },

    ['refundRequests.get'] ({ commit }, {id, query}) {
      commit('CLEAR_ERRORS')
  
      return (http.get(`refund-requests/${id}`, {params: query})).then ((res) => {
        commit('GET_REFUND_REQUEST', res);
      })
      .catch ((res) => {
        commit('REFUND_REQUESTS_ERROR', res);
      })
    },
    
    ['refundRequests.update'] ({ commit }, {id, data}) {
      commit('CLEAR_ERRORS')
  
      return (http.put(`refund-requests/${id}`, data)).then ((res) => {
        commit('UPDATE_REFUND_REQUEST', res);
      })
      .catch ((res) => {
        commit('REFUND_REQUESTS_ERROR', res);
      })
    },
    
    ['refundRequests.newComment'] ({ commit }, {id, data}) {
      commit('CLEAR_ERRORS')
  
      return (http.post(`refund-requests/${id}/comments`, data)).then ((res) => {
        commit('NEW_REFUND_REQUEST_COMMENT', res);
      })
      .catch ((res) => {
        commit('REFUND_REQUESTS_ERROR', res);
      })
    },

    ['refundRequests.add'] ({commit}, {chId, id, data, files}) {
        commit('CLEAR_ERRORS');
        let config = {
          headers: {
            'Content-Type': '',
          },
          "processData": false,
          "contentType": false,
          "mimeType": "multipart/form-data",
        };
        let formData = new FormData();
        if (files.length) {
          for (let i = 0; i < files.length; i++) {
            formData.append(`files[${i}]`, files[i]);
          }
        }
        formData.append('amount', data.amount);
        formData.append('notes', data.notes);
        formData.append('type', data.type);

        return (http.post(`channels/${chId}/reservations/${id}/refund-requests`, formData, config))
        .then ((res) => {
            commit('NEW_REFUND_REQUEST', res);
        })
        .catch ((res) => {
            commit('REFUND_REQUESTS_ERROR', res);
        })
    },

  }
  
  // mutations
  const mutations = {

    GET_ALL_REFUND_REQUESTS (state, res) {
      state.all = res.data;
    },

    GET_REFUND_REQUEST (state, res) {
      state.active = res.data;
    },

    NEW_REFUND_REQUEST_COMMENT (state, res) {
      state.active.comments.push(res.data);
      state.active = _.cloneDeep(state.active);
    },

    UPDATE_REFUND_REQUEST (state, res) {
      state.active.status = res.data.status;
      if (res.data.comment) {
        state.active.comments.push(res.data.comment);
        state.active = _.cloneDeep(state.active);
      }
    },

    NEW_REFUND_REQUEST (state, res) {
        state.active = _.assign(state.active, res.data);
    },

    //ERRORS
    REFUND_REQUESTS_ERROR (state, err) {
        try {
            state.errors = err.response.data;
        } catch (e) {
            state.errors = err;
        }
    },

    CLEAR_ERRORS (state) {
      try {
        state.errors = null
      } catch (e) {}
    }
  }
  
  export default {
    state,
    getters,
    actions,
    mutations
  }
  