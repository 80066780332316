import { http } from 'services'

// initial state
const state = () => ({
    active: {},
    errors: null
  })
  
  // getters
  const getters = {
    // reversedMessage: state => state.message.reverse()
  }
  
  // actions
  const actions = {
    ['guaranteeCard.get'] ({ commit }, id) {
        commit('CLEAR_ERRORS');

        return (http.get(`guarantee-cards/${id}`))
        .then ((res) => {
            commit('GET_GUARANTEE_CARD', res);
        })
        .catch ((res) => {
            commit('GUARANTEE_CARD_ERROR', res);
        })
    },

    ['guaranteeCard.getForReservation'] ({ commit }, {reservation_id, location_id}) {
        commit('CLEAR_ERRORS');

        return (http.get(`locations/${location_id}/reservations/${reservation_id}/guarantee-card`))
        .then ((res) => {
            commit('GET_GUARANTEE_CARD', res);
        })
        .catch ((res) => {
            commit('GUARANTEE_CARD_ERROR', res);
        })
    },

    ['guaranteeCard.update'] ({ commit }, {id, data}) {
        commit('CLEAR_ERRORS');

        return (http.put(`guarantee-cards/${id}`, data))
        .then ((res) => {
            commit('EDIT_GUARANTEE_CARD', res);
        })
        .catch ((res) => {
            commit('GUARANTEE_CARD_ERROR', res);
        })
    },
  }
  
  // mutations
  const mutations = {
    GET_GUARANTEE_CARD (state, res) {
        state.active = res.data
    },

    EDIT_GUARANTEE_CARD (state, res) {
        state.active = res.data
    },

    //ERRORS
    GUARANTEE_CARD_ERROR (state, err) {
        try {
        state.errors = err.response.data;
        } catch (e) {
        state.errors = err;
        }
    },

    CLEAR_ERRORS (state) {
        state.errors = null
    }
  }
  
  export default {
    state,
    getters,
    actions,
    mutations
  }
  