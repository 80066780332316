import _ from 'lodash'
import toastr from 'toastr'
import layout from 'layouts/default'
import { hasPermits } from 'directives'

const directives = {
  hasPermits
}

const components = {
  layout
}

const methods = {
  submit () {
    this.errors = {}
    let data = _.clone(this.form);

    this.processing = true;
    this.$store.dispatch('poi.editPOI', {id: this.$route.params.id, data})
    .then(() => {
      this.processing = false;
      let errors = this.$store.state.pointOfInterest.errors;
      if (errors) {
        if (errors.errors) {
          this.errors = _.cloneDeep(errors.errors);
        }
        toastr.error(errors.message);
      } else {
        toastr.success('Data has been saved');
      }
    });
  }
}

export default {
  name: 'edit-poi',
  components,
  methods,
  directives,
  data () {
    return {
      loading: true,
      processing: false,
      errors: {},
      form: null,
    }
  },
  created () {
    this.$store.dispatch('poi.getPOI', this.$route.params.id).then(() => {
      this.loading = false;
      this.form = _.cloneDeep(this.$store.state.pointOfInterest.active);
    });
  }
}
