import confirm from 'components/modals/confirm'
import newEmployee from '../newEmployeeModal'
import viewEmployee from '../editEmployeeModal'
import { hasPermits } from 'directives'

const directives = {
  hasPermits
}

const components = {
  newEmployee,
  viewEmployee,
  confirm
}

const computed = {
  channel () {
    return _.cloneDeep(this.$store.state.channels.active);
  }
}

const methods = {
  openEditModal(emp) {
    this.selectedUser = emp;
    this.$refs.editModal.open();
  },
  removeEmployee (emp) {
    this.$refs.removeConfirm.confirm("Are you sure?", () => {
      this.delete_loadings[emp.id] = true;
      this.delete_loadings = _.cloneDeep(this.delete_loadings);

      this.$store.dispatch('channels.removeEmployee', {
        chId: this.$route.params.id,
        id: emp.id
      }).then(() => {
        this.delete_loadings[emp.id] = false;
        let errors = this.$store.state.channels.errors;
        if (errors) {
          if (errors.errors) {
            this.errors = _.cloneDeep(errors.errors);
          }
          toastr.error(errors.message);
        }
      })
    })
  }
}

export default {
  name: 'employees-tab',
  props: ['formData'],
  computed,
  directives,
  methods,
  components,
  data () {
    return {
      delete_loadings: {},
      selectedUser: {},
      locId: this.$route.params.id
    }
  }
}
