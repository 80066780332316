import {all as allChannels, edit as editChannel, new as newChannel} from 'pages/channels'
import { customerDetails } from 'pages/channels/partials'

export default [  
  //Channels
  { path: '/channels', name: 'channels.all', component: allChannels
  , meta:{permits:['channel_list', 'channel_user']}},
  { path: '/channels/new', name: 'channels.new', component: newChannel
  , meta:{permits:['channel_create']}},
  { path: '/channels/:id', name: 'channels.edit', component: editChannel
  , meta:{permits:['channel_list', 'channel_user'], keepAlive: true}},
]
