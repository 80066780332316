import moment from 'moment'
import { dateFormat, currencySymbol } from 'filters'
import pagination from 'components/pagination'
import { funcModal, reservationDetails } from 'components/modals'
import sSelect from 'components/sSelect'
import datepicker from 'components/datepicker'
import { hasPermits } from 'directives'

const directives = {
  hasPermits
}

const components = {
  pagination,
  reservationDetails,
  sSelect,
  datepicker,
  funcModal
}

const filters = {
  dateFormat,
  currencySymbol
}

const computed = {
  reservations () {
    return _.cloneDeep(this.$store.state.channels.active.reservations);
  },

  locations () {
    let locations = _.cloneDeep(this.$store.state.channels.active.locations);
    if (locations.data) return [];

    if (!_.isEmpty(locations)) {
      locations = [{text: '-- select a location --', value: null}].concat(_.map(locations, location_channel => ({
        value: location_channel.location.id,
        text: location_channel.location.name
      })))

      if (_.isEmpty(this.selected_location)) {
        this.selected_location = locations[0];
      }

      return locations;
    }

    return [];
  },

  disable_export() {
    return this.download_processing || !this.filters.from_date || !this.filters.to_date;
  }
}

const methods = {
  loadPage (page, action = 'channels.getReservations') {
    this.$route.query.page = page;    
    return this.$store.dispatch(action, {
      id: this.$route.params.id,
      params: {
        page,
        testing: this.testingMode ? 1 : 0,
        _sort: '-id',
        'reservation_number-lk': this.filters.search ? "*" + this.filters.search + "*" : null,
        cancelled: this.filters.cancelled,
        type: this.filters.type,
        location_id: this.filters.location_id,
        from_date: this.filters.from_date ? moment(this.filters.from_date, ['MM/DD/YYYY']).format('YYYY-MM-DD') : null,
        to_date: this.filters.to_date ? moment(this.filters.to_date, ['MM/DD/YYYY']).format('YYYY-MM-DD') : null,
      }
    })
  },

  download () {
    this.download_processing = true;
    this.loadPage(null, 'channels.downloadReservations').then(() => {
      const blob = new Blob([this.$store.state.channels.active.downloads.data], { type: `application/csv; charset=UTF-8` });
      blob.name = 'report.csv';
      const reader = new FileReader();
      reader.onload = e => {
        const anchor = document.createElement('a');
        anchor.style.display = 'none';
        anchor.href = e.target.result;
        anchor.download = blob.name;
        anchor.click();
      };
      reader.readAsDataURL(blob);

      this.download_processing = false
    });
  },

  applyFilters (page = 1) {
    this.loading = true;
    this.loadPage(page).then(() => this.loading = false);
  },

  loadReservation (item) {
    this.$set(this.loadings, item.id, true);
    this.$store.dispatch('channels.getReservation', {
      id: item.id,
      chId: this.$route.params.id
    }).then(() => {
      this.$set(this.loadings, item.id, false);
      this.activeReservation = this.$store.state.channels.active_reservation;
      this.$nextTick(() => {
        this.$refs.reservationDetailsModal.open();
      })
    })
  },

  loadLocations () {
    return this.$store.dispatch('channels.getLocations', {
      id: this.$route.params.id,
      params: {_all: true}
    });
  },

  currencyCode(location) {
    return location.currency ? currencySymbol(location.currency.code) : "Na";
  },

  onChangeLocation (location) {
    this.selected_location = location;
    this.filters.location_id = location.value;
  },

  openFeesBreakDown (channel_id, reservation_id) {
    this.fees_loading = true;
    this.fees_breakdown = [];
    this.$refs.feesBreakDown.open();
    this.$store.dispatch('channels.getReservationFees', {channel_id, reservation_id}).then(() => {
      this.fees_breakdown = this.$store.state.channels.active.fees_breakdown;
      this.fees_loading = false;
    });
  }
}

const watch = {
  activeTab (n) {
    if (n.title == "Reservations") {
      this.loading = true;
      Promise.all([
        this.loadPage(1),
        this.loadLocations(),
      ]).then(() => {
        this.loading = false;
      });
    }
  },

  testingMode (n) {
    console.log('testingMode', n);
    if (this.activeTab.title == 'Reservations') {
      this.applyFilters(1)
    }
  }
}

export default {
  name: 'reservations-tab',
  props: ['activeTab', 'testingMode'],
  directives,
  components,
  computed,
  methods,
  filters,
  watch,
  data () {
    return {
      loading: false,
      download_processing: false,
      activeReservation: null,
      loadings: {},
      selected_location: {},
      fees_breakdown: [],
      fees_loading: false,
      filters: {
        search: null,
        cancelled: null,
        type: null,
        location_id: null,
        from_date: null,
        to_date: null
      }
    }
  }
}
