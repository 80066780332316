var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return true
    ? _c(
        "div",
        [
          !_vm.attributes.length
            ? _c("div", [
                _c(
                  "a",
                  {
                    attrs: { href: "#" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.addAttribute()
                      }
                    }
                  },
                  [_c("i", { staticClass: "fa fa-plus fa-lg" })]
                )
              ])
            : _c("div", [
                !_vm.editIntegrationAttributes
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn-info",
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.startEdit()
                          }
                        }
                      },
                      [_vm._v("\n        edit\n    ")]
                    )
                  : _vm._e()
              ]),
          _vm._v(" "),
          _vm._l(_vm.attributes, function(attribute, i) {
            return _c("div", { key: i, staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-5" }, [
                _c("label", [_vm._v("Attribute")]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: attribute.key,
                      expression: "attribute.key"
                    }
                  ],
                  staticClass: "form-control",
                  class: { disabled: !_vm.editIntegrationAttributes },
                  attrs: { disabled: !_vm.editIntegrationAttributes },
                  domProps: { value: attribute.key },
                  on: {
                    input: [
                      function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(attribute, "key", $event.target.value)
                      },
                      _vm.onchange
                    ]
                  }
                }),
                _vm._v(" "),
                _vm.errors["integration_system_attributes." + i + ".key"]
                  ? _c("div", { staticClass: "text-danger" }, [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.errors[
                              "integration_system_attributes." + i + ".key"
                            ][0]
                          ) +
                          "\n      "
                      )
                    ])
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-5" }, [
                _c("label", [_vm._v("Value")]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: attribute.value,
                      expression: "attribute.value"
                    }
                  ],
                  staticClass: "form-control",
                  class: { disabled: !_vm.editIntegrationAttributes },
                  attrs: { disabled: !_vm.editIntegrationAttributes },
                  domProps: { value: attribute.value },
                  on: {
                    input: [
                      function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(attribute, "value", $event.target.value)
                      },
                      _vm.onchange
                    ]
                  }
                }),
                _vm._v(" "),
                _vm.errors["integration_system_attributes." + i + ".value"]
                  ? _c("div", { staticClass: "text-danger" }, [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.errors[
                              "integration_system_attributes." + i + ".value"
                            ][0]
                          ) +
                          "\n      "
                      )
                    ])
                  : _vm._e()
              ]),
              _vm._v(" "),
              _vm.editIntegrationAttributes
                ? _c("div", { staticClass: "col-sm-2" }, [
                    _c("label", [_vm._v("   ")]),
                    _vm._v(" "),
                    _c("div", [
                      _c(
                        "a",
                        {
                          staticClass: "text-danger",
                          attrs: { href: "#" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.removeAttribute(i)
                            }
                          }
                        },
                        [_c("i", { staticClass: "fa fa-trash fa-lg" })]
                      ),
                      _vm._v(" "),
                      i + 1 == _vm.attributes.length
                        ? _c(
                            "a",
                            {
                              attrs: { href: "#" },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.addAttribute(i + 1)
                                }
                              }
                            },
                            [_c("i", { staticClass: "fa fa-plus fa-lg" })]
                          )
                        : _vm._e()
                    ])
                  ])
                : _vm._e()
            ])
          })
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-1ed96ce8", { render: render, staticRenderFns: staticRenderFns })
  }
}