var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("table", { staticClass: "table table-bordered" }, [
    _c("thead", [
      _c(
        "tr",
        [
          _c("th", [_vm._v("\n        Type Name\n      ")]),
          _vm._v(" "),
          _vm._l(_vm.numberOfDays + 1, function(n, i) {
            return _c("th", [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm
                      .moment(_vm.fromDate, ["MM/DD/YYYY"])
                      .add(i, "days")
                      .format("ddd")
                  ) +
                  "\n        " +
                  _vm._s(
                    _vm
                      .moment(_vm.fromDate, ["MM/DD/YYYY"])
                      .add(i, "days")
                      .format("DD")
                  ) +
                  "\n      "
              )
            ])
          })
        ],
        2
      )
    ]),
    _vm._v(" "),
    _c(
      "tbody",
      _vm._l(_vm.rateTypes, function(type) {
        return _c(
          "tr",
          [
            _c("th", { staticClass: "rought-td" }, [
              _vm._v("\n        " + _vm._s(type.name) + "\n      ")
            ]),
            _vm._v(" "),
            _vm._l(_vm.numberOfDays + 1, function(n, i) {
              return _c(
                "td",
                {
                  key: i,
                  class: [
                    {
                      "bg-warning":
                        type.rates[i].availability < 5 &&
                        type.rates[i].availability >= 1
                    },
                    { "bg-danger": type.rates[i].availability < 1 },
                    {
                      "lease-rate":
                        _vm.isLease(type, _vm.fromDate, i) && !_vm.isChannelUser
                    }
                  ]
                },
                [
                  type.percentage && type.percentage > 1
                    ? _c("div", { ref: "cells", refInFor: true }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm._f("currency")(type.rates[i].price)) +
                            " " +
                            _vm._s(type.is_large) +
                            "\n        "
                        )
                      ])
                    : _c(
                        "div",
                        {
                          ref: "cells",
                          refInFor: true,
                          attrs: {
                            "data-placement": "top",
                            "data-original-title": type.rates[i].tooltip
                          }
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm._f("currency")(type.rates[i].price)) +
                              "\n          "
                          ),
                          _vm.showAvailability
                            ? _c("div", [
                                _vm._v(
                                  "(" + _vm._s(type.rates[i].availability) + ")"
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.showBasePrice
                            ? _c("div", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("currency")(type.rates[i].base_price)
                                  )
                                )
                              ])
                            : _vm._e()
                        ]
                      )
                ]
              )
            })
          ],
          2
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-d74a2c46", { render: render, staticRenderFns: staticRenderFns })
  }
}