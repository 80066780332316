import toastr from 'toastr'
import moment from 'moment'
import layout from 'layouts/default'
import datepicker from 'components/datepicker'

const components = {
    layout,
    datepicker,
}


const computed = {
    location () {
        return _.cloneDeep(this.$store.state.locations.active)
    },
    parentParkingTypes () {
        let data = _.cloneDeep(this.$store.state.locations.active.parkingTypes);
        for (let i = 0; i < data.length; i++) {
            data[i].childs = [];
            for (let j = 0; j < data.length; j++) {
                if (data[i].id == data[j].parent_id) {
                    let child = _.cloneDeep(data[j]);
                    data[i].childs.push(child);
                }
            }
        }
        data = data.filter(function (type) { return !type.parent_id});
        console.log('parentParkingTypes with childs', data);

        return data;
    },
}

const methods = {

    addAdjustment(index = -1) {
        let item = {
            from: '',
            to: '',
            amount: null,
            price: 10,
        }
        
        let length = this.form.adjustments.length; 
        if (index == -1 || index >= length) {
            this.form.adjustments.push(item);
        } else {
            this.form.adjustments.splice(index, 0, item);
        }
    },
    removeAdjustment(index) {
        this.form.adjustments.splice(index, 1);
    },
    
    addExcludedPeriod(index = -1) {
        let item = {
            from: '',
            to: '',
        }
        
        let length = this.form.excluded_periods.length; 
        if (index == -1 || index >= length) {
            this.form.excluded_periods.push(item);
        } else {
            this.form.excluded_periods.splice(index, 0, item);
        }
    },
    removeExcludedPeriod(index) {
        this.form.excluded_periods.splice(index, 1);
    },

    typeChanged() {
        this.form.type_id = null;
    },

    cancelEdit() {
        this.$emit('cancelEdit');
    },

    submit (modal) {
        let data = _.cloneDeep(this.form);

        data.from_date = moment(this.from_date, ['MM/DD/YYYY']).format('YYYY-MM-DD');
        data.to_date = this.to_date ? moment(this.to_date, ['MM/DD/YYYY']).format('YYYY-MM-DD') : null;

        for (let i = 0; i <  data.excluded_periods.length; i++) {
            data.excluded_periods[i].from = moment(data.excluded_periods[i].from, ['MM/DD/YYYY']).format('YYYY-MM-DD');
            data.excluded_periods[i].to = moment(data.excluded_periods[i].to, ['MM/DD/YYYY']).format('YYYY-MM-DD');
        }

        this.processing = true;
        let action = 'rateManagement.newDynamicAdjustment';
        if (this.edit) {
            action = 'rateManagement.updateDynamicAdjustment';
        }
        this.$store.dispatch(action, {
            location_id: this.$route.params.id,
            params: data
        }).then(() => {
            if (this.$store.state.rateManagement.errors) {
                this.errors = _.cloneDeep(this.$store.state.rateManagement.errors.errors);
                toastr.error(this.$store.state.rateManagement.errors.message);
            } else {
                toastr.success('Data has been saved');
                this.$emit('dynamicAdjustmentAdded');
                this.from_date = null;
                this.to_date = null;
                this.form.type = null;
                this.form.type_id = null;
                this.form.from_date = null;
                this.form.to_date = null;
                this.form.number_of_period_days = 30;
                this.form.adjustments = [];
                this.form.excluded_periods = [];
                this.form.enable = true;
                this.form.exclude_blackout = true;
            }
            this.processing = false;
        });
    }
}

export default {
    name: 'dynamic-adjustment',
    components,
    computed,
    methods,
    props: ['dynamicAdjustment', 'parkingTypes', 'roomTypes'],
    data () {
        return {
            edit: false,
            title: "New Dynamic adjustment",
            loading: true,
            from_date: null,
            to_date: null,
            form: {
                location_id: null, 
                type: 'parking',
                type_id: null,
                from_date: null,
                to_date: null,
                number_of_period_days: 30,// number of days to apply the adjustment start from today
                adjustments: [],
                excluded_periods: [],
                enable: true,
                exclude_blackout: true,
            },
            errors: {},
            processing: false,
        }
    },

    created () {
        this.loading = true;
        Promise.all([
        ]).then(() => {
        this.loading = false;
        
        });

        if (this.dynamicAdjustment) {
            this.edit = true;
            this.title = "Edit Dynamic adjustment";
            this.form = _.assign(this.form, _.cloneDeep(this.dynamicAdjustment));
            this.form.rate_adjustment_id = this.dynamicAdjustment.id;
            this.from_date = moment(this.form.from_date, ['YYYY-MM-DD']).format('MM/DD/YYYY');
            this.to_date = this.form.to_date ? moment(this.form.to_date, ['YYYY-MM-DD']).format('MM/DD/YYYY') : null;

            for (let i = 0; i < this.form.excluded_periods.length; i++) {
                this.form.excluded_periods[i].from = moment(this.form.excluded_periods[i].from, ['YYYY-MM-DD']).format('MM/DD/YYYY');
                this.form.excluded_periods[i].to = moment(this.form.excluded_periods[i].to, ['YYYY-MM-DD']).format('MM/DD/YYYY');
            }
        } else {
            this.edit = false;
            this.form.location_id = this.$route.params.id;
        }
       
        if (this.form.adjustments.length == 0) {
            this.addAdjustment();
        }
    },


}