var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("layout", { attrs: { "no-sidebar": true } }, [
    _c("div", { staticClass: "page-header" }, [
      _c("h1", [_c("small", [_vm._v("Reservations")])])
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "row margin-top-4x" },
      [
        _c("div", { staticClass: "col-sm-12" }, [
          _vm.loading
            ? _c("div", { staticClass: "text-center" }, [
                _c("i", { staticClass: "fa fa-spinner fa-spin fa-3x fa-fw" })
              ])
            : _c(
                "div",
                [
                  _c("reservation-details", {
                    ref: "reservationDetailsModal",
                    attrs: {
                      reservation: _vm.activeReservation,
                      type: "checkin"
                    }
                  }),
                  _vm._v(" "),
                  _c("div", [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-sm-3 margin-bottom-3x" }, [
                        _c("div", { staticClass: "input-group" }, [
                          _c("label", [
                            _vm._v("Reservation Number Or Extra Fields")
                          ]),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.filters.search,
                                expression: "filters.search"
                              }
                            ],
                            ref: "search",
                            staticClass: "form-control",
                            attrs: {
                              type: "text",
                              placeholder: "Search for..."
                            },
                            domProps: { value: _vm.filters.search },
                            on: {
                              keyup: function($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  $event.keyCode !== 13
                                ) {
                                  return null
                                }
                                return _vm.search(_vm.$refs.search.value)
                              },
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.filters,
                                  "search",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-sm-3" }, [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v("Search By:")
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "radio" }, [
                          _c("label", { staticClass: "margin-right-1x" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.filters.search_by,
                                  expression: "filters.search_by"
                                }
                              ],
                              attrs: {
                                type: "radio",
                                value: "reservation_number"
                              },
                              domProps: {
                                checked: _vm._q(
                                  _vm.filters.search_by,
                                  "reservation_number"
                                )
                              },
                              on: {
                                change: function($event) {
                                  return _vm.$set(
                                    _vm.filters,
                                    "search_by",
                                    "reservation_number"
                                  )
                                }
                              }
                            }),
                            _vm._v(
                              "\n                  Reservation Number\n                "
                            )
                          ]),
                          _vm._v(" "),
                          _c("label", { staticClass: "margin-right-1x" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.filters.search_by,
                                  expression: "filters.search_by"
                                }
                              ],
                              attrs: { type: "radio", value: "email" },
                              domProps: {
                                checked: _vm._q(_vm.filters.search_by, "email")
                              },
                              on: {
                                change: function($event) {
                                  return _vm.$set(
                                    _vm.filters,
                                    "search_by",
                                    "email"
                                  )
                                }
                              }
                            }),
                            _vm._v(
                              "\n                  Email\n                "
                            )
                          ]),
                          _vm._v(" "),
                          _c("label", { staticClass: "margin-right-1x" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.filters.search_by,
                                  expression: "filters.search_by"
                                }
                              ],
                              attrs: { type: "radio", value: "name" },
                              domProps: {
                                checked: _vm._q(_vm.filters.search_by, "name")
                              },
                              on: {
                                change: function($event) {
                                  return _vm.$set(
                                    _vm.filters,
                                    "search_by",
                                    "name"
                                  )
                                }
                              }
                            }),
                            _vm._v("\n                  Name\n                ")
                          ]),
                          _vm._v(" "),
                          _c("label", { staticClass: "margin-right-1x" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.filters.search_by,
                                  expression: "filters.search_by"
                                }
                              ],
                              attrs: { type: "radio", value: "extra_field" },
                              domProps: {
                                checked: _vm._q(
                                  _vm.filters.search_by,
                                  "extra_field"
                                )
                              },
                              on: {
                                change: function($event) {
                                  return _vm.$set(
                                    _vm.filters,
                                    "search_by",
                                    "extra_field"
                                  )
                                }
                              }
                            }),
                            _vm._v(
                              "\n                  Extra Field\n                "
                            )
                          ]),
                          _vm._v(" "),
                          _c("label", { staticClass: "margin-right-1x" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.filters.search_by,
                                  expression: "filters.search_by"
                                }
                              ],
                              attrs: { type: "radio", value: "all" },
                              domProps: {
                                checked: _vm._q(_vm.filters.search_by, "all")
                              },
                              on: {
                                change: function($event) {
                                  return _vm.$set(
                                    _vm.filters,
                                    "search_by",
                                    "all"
                                  )
                                }
                              }
                            }),
                            _vm._v("\n                  All\n                ")
                          ])
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-sm-3" }, [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v("Testing Or Production")
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "radio" }, [
                          _c("label", { staticClass: "margin-right-1x" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.filters.mode,
                                  expression: "filters.mode"
                                }
                              ],
                              attrs: { type: "radio", value: "all" },
                              domProps: {
                                checked: _vm._q(_vm.filters.mode, "all")
                              },
                              on: {
                                change: function($event) {
                                  return _vm.$set(_vm.filters, "mode", "all")
                                }
                              }
                            }),
                            _vm._v("\n                  All\n                ")
                          ]),
                          _vm._v(" "),
                          _c("label", { staticClass: "margin-right-1x" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.filters.mode,
                                  expression: "filters.mode"
                                }
                              ],
                              attrs: { type: "radio", value: "testing" },
                              domProps: {
                                checked: _vm._q(_vm.filters.mode, "testing")
                              },
                              on: {
                                change: function($event) {
                                  return _vm.$set(
                                    _vm.filters,
                                    "mode",
                                    "testing"
                                  )
                                }
                              }
                            }),
                            _vm._v(
                              "\n                  Testing\n                "
                            )
                          ]),
                          _vm._v(" "),
                          _c("label", { staticClass: "margin-right-1x" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.filters.mode,
                                  expression: "filters.mode"
                                }
                              ],
                              attrs: { type: "radio", value: "production" },
                              domProps: {
                                checked: _vm._q(_vm.filters.mode, "production")
                              },
                              on: {
                                change: function($event) {
                                  return _vm.$set(
                                    _vm.filters,
                                    "mode",
                                    "production"
                                  )
                                }
                              }
                            }),
                            _vm._v(
                              "\n                  Production\n                "
                            )
                          ])
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-sm-1" }, [
                        _c("div", [_c("label", { attrs: { for: "" } })]),
                        _vm._v(" "),
                        _c("span", { staticClass: "input-group-btn" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-primary",
                              attrs: { type: "button" },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.search(_vm.$refs.search.value)
                                }
                              }
                            },
                            [_vm._v("Search")]
                          )
                        ])
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("hr"),
                  _vm._v(" "),
                  _c("div", { staticClass: "table-responsive" }, [
                    _c("table", { staticClass: "table table-striped" }, [
                      _c("thead", [
                        _c("tr", [
                          _c("th", [_vm._v("Res #")]),
                          _vm._v(" "),
                          _c("th", [_vm._v("Creation Date")]),
                          _vm._v(" "),
                          _c("th", [_vm._v("Status")]),
                          _vm._v(" "),
                          _c("th", [_vm._v("Location Name")]),
                          _vm._v(" "),
                          _c("th", [_vm._v("Channel Name")]),
                          _vm._v(" "),
                          _c("th", [_vm._v("Reserved By")]),
                          _vm._v(" "),
                          _c("th", [_vm._v("Info")]),
                          _vm._v(" "),
                          _c("th", [_vm._v("Location Fees")]),
                          _vm._v(" "),
                          _c("th", [_vm._v("Taxes Total")]),
                          _vm._v(" "),
                          _c("th", [_vm._v("Subtotal")]),
                          _vm._v(" "),
                          _c("th", [_vm._v("Grand Total")]),
                          _vm._v(" "),
                          _c("th", [_vm._v("Due at Lot")]),
                          _vm._v(" "),
                          _c("th", [_vm._v("Location Commission")]),
                          _vm._v(" "),
                          _c("th", [_vm._v("Channel Commission")]),
                          _vm._v(" "),
                          _c("th", [_vm._v("RL fee")]),
                          _vm._v(" "),
                          _c("th", [_vm._v("Amount Owed")]),
                          _vm._v(" "),
                          _c("th", [_vm._v("Partial Refund")]),
                          _vm._v(" "),
                          _c(
                            "th",
                            {
                              directives: [
                                {
                                  name: "hasPermits",
                                  rawName: "v-hasPermits:allow",
                                  value: [
                                    "update_reservation_dates",
                                    "update_reservation"
                                  ],
                                  expression:
                                    "['update_reservation_dates', 'update_reservation']",
                                  arg: "allow"
                                }
                              ]
                            },
                            [_vm._v("Edit")]
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c(
                        "tbody",
                        _vm._l(_vm.reservations.data, function(item) {
                          return _c(
                            "tr",
                            { class: { "bg-warning": item.no_show } },
                            [
                              _c("td", [
                                _c(
                                  "a",
                                  {
                                    attrs: { href: "#" },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        return _vm.loadReservation(item)
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(item.reservation_number) +
                                        "\n                  "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _vm.loadings[item.id]
                                  ? _c("span", [
                                      _c("i", {
                                        staticClass: "fa fa-spin fa-spinner"
                                      })
                                    ])
                                  : _vm._e()
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("dateFormat")(
                                      item.created_at,
                                      "MM/DD/YYYY hh:mm A"
                                    )
                                  )
                                )
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    item.active_history
                                      ? item.cancelled
                                        ? "Cancelled"
                                        : "Active"
                                      : ""
                                  )
                                )
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    item.active_history
                                      ? item.active_history.location.name
                                      : ""
                                  )
                                )
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  _vm._s(item.channel ? item.channel.name : "")
                                )
                              ]),
                              _vm._v(" "),
                              _c("td", [_vm._v(_vm._s(item.reserved_by))]),
                              _vm._v(" "),
                              item.active_history
                                ? _c(
                                    "td",
                                    _vm._l(item.active_history.dates, function(
                                      date
                                    ) {
                                      return item.active_history
                                        ? _c(
                                            "table",
                                            {
                                              staticClass: "table",
                                              class: {
                                                "bg-warning": item.no_show
                                              }
                                            },
                                            [
                                              _c("tr", [
                                                _c("th", [_vm._v("Type")]),
                                                _vm._v(" "),
                                                _c("td", [
                                                  _vm._v(_vm._s(date.type_type))
                                                ])
                                              ]),
                                              _vm._v(" "),
                                              _c("tr", [
                                                _c("th", [_vm._v("From Date")]),
                                                _vm._v(" "),
                                                _c("td", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm._f("dateFormat")(
                                                        date.from_date,
                                                        "MM/DD/YYYY hh:mm A"
                                                      )
                                                    )
                                                  )
                                                ])
                                              ]),
                                              _vm._v(" "),
                                              _c("tr", [
                                                _c("th", [_vm._v("To Date")]),
                                                _vm._v(" "),
                                                _c("td", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm._f("dateFormat")(
                                                        date.to_date,
                                                        "MM/DD/YYYY hh:mm A"
                                                      )
                                                    )
                                                  )
                                                ])
                                              ])
                                            ]
                                          )
                                        : _vm._e()
                                    }),
                                    0
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  staticClass: "pointer",
                                  on: {
                                    click: function($event) {
                                      return _vm.openFeesBreakDown(
                                        item.channel_id,
                                        item.id
                                      )
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(
                                        item.active_history
                                          ? _vm.currencyCode(
                                              item.active_history.location
                                            ) +
                                              item.active_history
                                                .location_fees_total
                                          : ""
                                      ) +
                                      "\n                "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    item.active_history
                                      ? _vm.currencyCode(
                                          item.active_history.location
                                        ) +
                                          Number(
                                            item.active_history.total_tax
                                          ).toFixed(2)
                                      : ""
                                  )
                                )
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    item.active_history
                                      ? _vm.currencyCode(
                                          item.active_history.location
                                        ) + item.active_history.subtotal
                                      : ""
                                  )
                                )
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    item.active_history
                                      ? _vm.currencyCode(
                                          item.active_history.location
                                        ) + item.active_history.grand_total
                                      : ""
                                  )
                                )
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    item.active_history
                                      ? item.active_history.due_at_location
                                        ? "Yes"
                                        : "No"
                                      : ""
                                  )
                                )
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    item.active_history
                                      ? _vm.currencyCode(
                                          item.active_history.location
                                        ) +
                                          item.active_history.commissions_total
                                      : ""
                                  )
                                )
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    item.active_history
                                      ? _vm.currencyCode(
                                          item.active_history.location
                                        ) +
                                          Number(
                                            item.active_history.channel_total
                                          ).toFixed(2)
                                      : ""
                                  )
                                )
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    item.active_history
                                      ? _vm.currencyCode(
                                          item.active_history.location
                                        ) +
                                          Number(
                                            item.active_history.grand_total -
                                              Number(
                                                item.active_history
                                                  .channel_total +
                                                  item.active_history
                                                    .location_total
                                              ).toFixed(2)
                                          ).toFixed(2)
                                      : ""
                                  )
                                )
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    item.active_history
                                      ? _vm.currencyCode(
                                          item.active_history.location
                                        ) +
                                          Number(
                                            item.active_history.due_at_location
                                              ? item.active_history
                                                  .grand_total -
                                                  Number(
                                                    item.active_history
                                                      .channel_total +
                                                      item.active_history
                                                        .location_total
                                                  ).toFixed(2)
                                              : item.active_history
                                                  .grand_total -
                                                  item.active_history
                                                    .channel_total
                                          ).toFixed(2)
                                      : ""
                                  )
                                )
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    item.active_history
                                      ? _vm.currencyCode(
                                          item.active_history.location
                                        ) + item.active_history.partial_refund
                                      : ""
                                  )
                                )
                              ]),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  directives: [
                                    {
                                      name: "hasPermits",
                                      rawName: "v-hasPermits:allow",
                                      value: [
                                        "update_reservation_dates",
                                        "update_reservation"
                                      ],
                                      expression:
                                        "['update_reservation_dates', 'update_reservation']",
                                      arg: "allow"
                                    }
                                  ]
                                },
                                [
                                  _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        to: {
                                          name: "reservations.edit",
                                          params: { id: item.id }
                                        }
                                      }
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "fa fa-pencil",
                                        attrs: { "aria-hidden": "true" }
                                      })
                                    ]
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        }),
                        0
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("pagination", {
                    attrs: { "pages-number": _vm.reservations.last_page },
                    on: {
                      pageChanged: function(page) {
                        return _vm.loadPage(page)
                      }
                    }
                  })
                ],
                1
              )
        ]),
        _vm._v(" "),
        _c(
          "func-modal",
          {
            ref: "feesBreakDown",
            attrs: { title: "Fees Breakdown" },
            scopedSlots: _vm._u([
              {
                key: "footer",
                fn: function(props) {
                  return [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-default",
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return props.close()
                          }
                        }
                      },
                      [_vm._v("Close")]
                    )
                  ]
                }
              }
            ])
          },
          [
            _c("template", { slot: "body" }, [
              !_vm.fees_loading && _vm.fees_breakdown.length
                ? _c("table", { staticClass: "table table-bordered" }, [
                    _c("thead", [
                      _c("tr", [
                        _c("th", [_vm._v("Fee Name")]),
                        _vm._v(" "),
                        _c("th", [_vm._v("Amount")])
                      ])
                    ]),
                    _vm._v(" "),
                    _c(
                      "tbody",
                      _vm._l(_vm.fees_breakdown, function(fee) {
                        return _c("tr", { key: fee.id }, [
                          _c("td", [_vm._v(_vm._s(fee.name))]),
                          _vm._v(" "),
                          _c("th", [_vm._v(_vm._s(fee.doller_amount))])
                        ])
                      }),
                      0
                    )
                  ])
                : !_vm.fees_loading && !_vm.fees_breakdown.length
                ? _c("div", [
                    _vm._v(
                      "\n          No fees charged for this reservation\n        "
                    )
                  ])
                : _c("div", { staticClass: "text-center" }, [
                    _c("i", {
                      staticClass: "fa fa-spinner fa-spin fa-3x fa-fw"
                    })
                  ])
            ])
          ],
          2
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-8fa01924", { render: render, staticRenderFns: staticRenderFns })
  }
}