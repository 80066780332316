var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "func-modal",
    {
      ref: "modal",
      attrs: { title: "Update Started Reservation" },
      on: { close: _vm.onClose },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function(props) {
            return [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  class: [{ disabled: _vm.processing }],
                  attrs: { disabled: _vm.processing },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.submit()
                    }
                  }
                },
                [
                  _vm.processing
                    ? _c("i", { staticClass: "fa fa-spin fa-spinner" })
                    : _vm._e(),
                  _vm._v("\n            Submit\n        ")
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-link",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return props.close()
                    }
                  }
                },
                [_vm._v("Close")]
              )
            ]
          }
        }
      ])
    },
    [
      _c("template", { slot: "body" }, [
        _c("div", { staticClass: "form-group" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.with_refund,
                expression: "with_refund"
              }
            ],
            ref: "input",
            class: { disabled: _vm.processing },
            attrs: {
              id: "refund",
              type: "checkbox",
              disabled: _vm.processing,
              placeholder: ""
            },
            domProps: {
              checked: Array.isArray(_vm.with_refund)
                ? _vm._i(_vm.with_refund, null) > -1
                : _vm.with_refund
            },
            on: {
              change: function($event) {
                var $$a = _vm.with_refund,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 && (_vm.with_refund = $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      (_vm.with_refund = $$a
                        .slice(0, $$i)
                        .concat($$a.slice($$i + 1)))
                  }
                } else {
                  _vm.with_refund = $$c
                }
              }
            }
          }),
          _vm._v(" "),
          _c("label", { attrs: { for: "refund" } }, [_vm._v("with Refund")])
        ])
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-325e0d2d", { render: render, staticRenderFns: staticRenderFns })
  }
}