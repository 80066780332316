import Vue from 'vue'

export default new Vue({
  methods: {
    setValue (key, value) {
      sessionStorage.setItem("__busItem__" + key, JSON.stringify(value));
    },

    getValue (key) {
      return JSON.parse(sessionStorage.getItem("__busItem__" + key))
    }
  }
});
