var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.fees.length
        ? _c("div", [
            _c("div", { staticClass: "table-responsive" }, [
              _c("table", { staticClass: "table table-striped" }, [
                _c("thead", [
                  _c("tr", [
                    _c("th", { attrs: { width: "15%" } }, [_vm._v(" Name ")]),
                    _vm._v(" "),
                    _c("th", [_vm._v(" For ")]),
                    _vm._v(" "),
                    _c("th", [_vm._v(" Amount ")]),
                    _vm._v(" "),
                    _c("th", [_vm._v(" Desc. ")]),
                    _vm._v(" "),
                    _c("th", [_vm._v(" Type ")]),
                    _vm._v(" "),
                    _c("th", [_vm._v(" calculation ")]),
                    _vm._v(" "),
                    _c("th", [_vm._v(" Include in tax ")]),
                    _vm._v(" "),
                    _c("th", {
                      directives: [
                        {
                          name: "hasPermits",
                          rawName: "v-hasPermits:allow",
                          value: {
                            id: _vm.locId,
                            permits: ["locationFee_delete"]
                          },
                          expression:
                            "{id: locId, permits: ['locationFee_delete']}",
                          arg: "allow"
                        }
                      ]
                    })
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "tbody",
                  _vm._l(_vm.fees, function(item) {
                    return _c("tr", [
                      _c(
                        "td",
                        [
                          _c("editable-text", {
                            attrs: { value: item.name },
                            on: {
                              valueSaved: function(data) {
                                return _vm.onEditFee(data, item, "name")
                              }
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "td",
                        [
                          _c("editable-radio", {
                            attrs: {
                              value: item.associated_to,
                              options: {
                                room: "Rooms",
                                parking: "Parkings"
                              }
                            },
                            on: {
                              valueSaved: function(data) {
                                return _vm.onEditFee(
                                  data,
                                  item,
                                  "associated_to"
                                )
                              }
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "td",
                        [
                          _c("editable-text", {
                            attrs: { value: item.amount },
                            on: {
                              valueSaved: function(data) {
                                return _vm.onEditFee(data, item, "amount")
                              }
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "td",
                        [
                          _c("editable-text", {
                            attrs: { value: item.description },
                            on: {
                              valueSaved: function(data) {
                                return _vm.onEditFee(data, item, "description")
                              }
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      item.calculation == "daily"
                        ? _c(
                            "td",
                            [
                              _c("editable-radio", {
                                attrs: {
                                  value: item.type,
                                  options: {
                                    fixed: "$",
                                    percentage: "%"
                                  }
                                },
                                on: {
                                  valueSaved: function(data) {
                                    return _vm.onEditFee(data, item, "type")
                                  }
                                }
                              })
                            ],
                            1
                          )
                        : _c("td", [
                            _vm._v(
                              "\n              " +
                                _vm._s(item.type == "fixed" ? "$" : "%") +
                                "\n            "
                            )
                          ]),
                      _vm._v(" "),
                      _c(
                        "td",
                        [
                          _c("editable-radio", {
                            attrs: {
                              value: item.calculation,
                              options: {
                                daily: "Daily",
                                one_time: "One Time"
                              }
                            },
                            on: {
                              valueSaved: function(data) {
                                return _vm.onEditFee(data, item, "calculation")
                              }
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "td",
                        [
                          _c("editable-checkbox", {
                            attrs: { value: item.include_in_tax },
                            on: {
                              valueSaved: function(data) {
                                return _vm.onEditFee(
                                  data,
                                  item,
                                  "include_in_tax"
                                )
                              }
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "td",
                        {
                          directives: [
                            {
                              name: "hasPermits",
                              rawName: "v-hasPermits:allow",
                              value: {
                                id: _vm.locId,
                                permits: ["locationFee_delete"]
                              },
                              expression:
                                "{id: locId, permits: ['locationFee_delete']}",
                              arg: "allow"
                            }
                          ]
                        },
                        [
                          _vm.loadings[item.id]
                            ? _c("div", [
                                _c("i", {
                                  staticClass: "fa fa-spinner fa-spin"
                                })
                              ])
                            : _c(
                                "a",
                                {
                                  staticClass: "text-danger",
                                  attrs: { href: "#" },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.removeFee(item.id)
                                    }
                                  }
                                },
                                [_c("i", { staticClass: "fa fa-trash" })]
                              )
                        ]
                      )
                    ])
                  }),
                  0
                )
              ])
            ])
          ])
        : _c("div", { staticClass: "text-warning" }, [
            _vm._v("\n    There are no fees yet\n  ")
          ]),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _c("new-fee", {
        directives: [
          {
            name: "hasPermits",
            rawName: "v-hasPermits:allow",
            value: { id: _vm.locId, permits: ["locationFee_create"] },
            expression: "{id: locId, permits: ['locationFee_create']}",
            arg: "allow"
          }
        ]
      }),
      _vm._v(" "),
      _c("confirm", { ref: "confirmDelete", attrs: { type: "danger" } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-5eb817e3", { render: render, staticRenderFns: staticRenderFns })
  }
}