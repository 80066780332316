import { http, user } from 'services'
// initial state
const state = () => ({
  token: user.getToken(),
  data: user.getUser(),
  errors: null
})

// getters
const getters = {
}

// actions
const actions = {
  ['user.login'] ({ commit }, {email, password, token}) {
    commit('CLEAR_ERRORS');
    return (http.post(`users/auth`, {email, password, "g-recaptcha-response": token}))
    .then((res) => {
      commit('USER_LOGIN', res);
    })
    .catch((err) => {
      commit('USER_ERROR', {err});
    });
  },
  ['user.logout'] ({ commit }) {
    commit('CLEAR_ERRORS');
    return (http.post(`users/auth/logout`))
    .then((res) => {
      commit('USER_LOGOUT');
    })
    .catch((err) => {
      commit('USER_ERROR', {err});
    });
  },
  ['user.signup'] ({ commit }, data) {
    commit('CLEAR_ERRORS');
  },
  ['user.updatePersonalData'] ({ commit }, data) {
    commit('CLEAR_ERRORS');

    let roles = data.roles;
    delete data.roles;
    console.log(data);
    return (http.put(`users/update-profile`, data))
    .then((res) => {
      res.data.roles = roles;
      commit('USER_UPDATED', res);
    })
    .catch((err) => {
      commit('USER_ERROR', {err, clearUser: false});
    });
  },
  ['user.forgotPassword'] ({ commit }, email) {
    commit('CLEAR_ERRORS');
    return (http.post(`users/reset-password`, {email}))
    .catch((err) => {
      commit('USER_ERROR', {err});
    });
  },
  ['user.resetPassword'] ({ commit }, {token, password, welcome}) {
    commit('CLEAR_ERRORS');
    return (http.post(`users/reset-user-password`, {token, password, welcome}))
    .catch((err) => {
      commit('USER_ERROR', {err});
    });
  },
}

// mutations
const mutations = {
  USER_LOGIN (state, res) {
    state.token = res.data.token;
    state.data = res.data.data;
    state.data.locations = res.data.locations;
    state.data.channels = res.data.channels;

    user.setUser(state.data);
    user.setToken(state.token);
  },
  USER_UPDATED (state, res) {
    state.data = _.assign(state.data, res.data);
    user.setUser(state.data);
  },
  USER_LOGOUT (state) {
    state.token = null;
    state.data = null;

    user.setUser(state.data);
    user.setToken(state.token);
    sessionStorage.setItem('__testing_mode_items__', null);
  },
  //ERRORS
  USER_ERROR (state, {err, clearUser = true}) {
    try {
      state.errors = err.response.data;
    } catch (e) {
      state.errors = err;
    }
    if (clearUser) {
      user.setUser(null);
      user.setToken(null);
    }
  },

  CLEAR_ERRORS (state) {
    state.errors = null
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
