var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "func-modal",
    {
      ref: "modal",
      attrs: { title: "Partial Refund Request" },
      on: { close: _vm.onClose },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function(props) {
            return [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  class: [{ disabled: _vm.processing }],
                  attrs: { disabled: _vm.processing },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.submit()
                    }
                  }
                },
                [
                  _vm.processing
                    ? _c("i", { staticClass: "fa fa-spin fa-spinner" })
                    : _vm._e(),
                  _vm._v("\n            Submit\n        ")
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-link",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return props.close()
                    }
                  }
                },
                [_vm._v("Close")]
              )
            ]
          }
        }
      ])
    },
    [
      _c("template", { slot: "body" }, [
        !isNaN(_vm.amount)
          ? _c("div", { staticClass: "form-group" }, [
              _c("label", { attrs: { for: "refundAmount" } }, [
                _vm._v("Refund Amount (" + _vm._s(_vm.amount.toFixed(2)) + ")")
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.form.amount,
                    expression: "form.amount",
                    modifiers: { trim: true }
                  }
                ],
                staticClass: "form-control",
                attrs: {
                  type: "text",
                  id: "refundAmount",
                  placeholder: "Amount to be refunded"
                },
                domProps: { value: _vm.form.amount },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.form, "amount", $event.target.value.trim())
                  },
                  blur: function($event) {
                    return _vm.$forceUpdate()
                  }
                }
              }),
              _vm._v(" "),
              _vm.errors.amount
                ? _c("div", { staticClass: "text-danger" }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.errors.amount[0]) +
                        "\n            "
                    )
                  ])
                : _vm._e()
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "form-group" }, [
          _c("label", { attrs: { for: "expiry_date" } }, [
            _vm._v("Refund Type")
          ]),
          _c("br"),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.form.type,
                expression: "form.type"
              }
            ],
            attrs: {
              type: "radio",
              id: "case_1",
              name: "refund_cases",
              value: "cancellation_request_prior_to_check_in_time"
            },
            domProps: {
              checked: _vm._q(
                _vm.form.type,
                "cancellation_request_prior_to_check_in_time"
              )
            },
            on: {
              change: function($event) {
                return _vm.$set(
                  _vm.form,
                  "type",
                  "cancellation_request_prior_to_check_in_time"
                )
              }
            }
          }),
          _vm._v(" "),
          _c("label", { attrs: { for: "case_1" } }, [
            _vm._v(
              "Customer sent a cancellation request prior to check-in time"
            )
          ]),
          _c("br"),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.form.type,
                expression: "form.type"
              }
            ],
            attrs: {
              type: "radio",
              id: "case_2",
              name: "refund_cases",
              value: "significant_issue"
            },
            domProps: { checked: _vm._q(_vm.form.type, "significant_issue") },
            on: {
              change: function($event) {
                return _vm.$set(_vm.form, "type", "significant_issue")
              }
            }
          }),
          _vm._v(" "),
          _c("label", { attrs: { for: "case_2" } }, [
            _vm._v(
              "Customer could not park because of a significant issue at location"
            )
          ]),
          _c("br"),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.form.type,
                expression: "form.type"
              }
            ],
            attrs: {
              type: "radio",
              id: "case_3",
              name: "refund_cases",
              value: "unacceptable_service"
            },
            domProps: {
              checked: _vm._q(_vm.form.type, "unacceptable_service")
            },
            on: {
              change: function($event) {
                return _vm.$set(_vm.form, "type", "unacceptable_service")
              }
            }
          }),
          _vm._v(" "),
          _c("label", { attrs: { for: "case_3" } }, [
            _vm._v("Unacceptable service")
          ]),
          _c("br"),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.form.type,
                expression: "form.type"
              }
            ],
            attrs: {
              type: "radio",
              id: "case_4",
              name: "refund_cases",
              value: "other"
            },
            domProps: { checked: _vm._q(_vm.form.type, "other") },
            on: {
              change: function($event) {
                return _vm.$set(_vm.form, "type", "other")
              }
            }
          }),
          _vm._v(" "),
          _c("label", { attrs: { for: "case_4" } }, [_vm._v("Other")]),
          _vm._v(" "),
          _vm.errors.type
            ? _c("div", { staticClass: "text-danger" }, [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.errors.type[0]) +
                    "\n            "
                )
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("label", { attrs: { for: "expiry_date" } }, [_vm._v("Notes")]),
            _vm._v(" "),
            _c("vue-html5-editor", {
              attrs: { height: 100, content: _vm.form.notes },
              on: {
                change: function(x) {
                  return (_vm.form.notes = x)
                },
                "update:content": function($event) {
                  return _vm.$set(_vm.form, "notes", $event)
                }
              }
            }),
            _vm._v(" "),
            _vm.errors.notes
              ? _c("div", { staticClass: "text-danger" }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.errors.notes[0]) +
                      "\n            "
                  )
                ])
              : _vm._e()
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("label", { attrs: { for: "expiry_date" } }, [
              _vm._v("Attachments")
            ]),
            _vm._v(" "),
            _c("file-upload", {
              ref: "file_upload",
              attrs: {
                multiple: true,
                accept: ".jpeg,.jpg,.bmp,.png,.pdf,.doc,.docx"
              },
              on: { filesChanged: _vm.filesToUpload }
            }),
            _vm._v(" "),
            _vm.errors.files
              ? _c("div", { staticClass: "text-danger" }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.errors.files[0]) +
                      "\n            "
                  )
                ])
              : _vm._e()
          ],
          1
        ),
        _vm._v(" "),
        _c("div", [
          _vm._v(
            "\n            The location manager has 2 weeks to respond to this request. If they fail to do so, the request will be approved automatically. Generally acceptable refund requests include customers not receiving the service such as full parking or shuttle break-down. Generally rejected refund requests include cases of sub-par customer service, unfriendly staff or a slightly delayed shuttle.\n        "
          )
        ])
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-06bbec4a", { render: render, staticRenderFns: staticRenderFns })
  }
}