import { http } from 'services'
// initial state
const state = () => ({
  errors: null,
})

// getters
const getters = {
}

// actions
const actions = {
  ['apiDocumentation.uploadFile'] ({ commit, state }, {file, type}) {
    commit('CLEAR_ERRORS')
    let config = {
      headers: {
        'Content-Type': 'application/html'
      },
      "processData": false,
      "contentType": false,
      "mimeType": "multipart/form-data",
    }
    let formData = new FormData();
    formData.append("file", file);
    formData.append("type", type);

    return (http.post('api-documentation', formData, config)).then ((res) => {
    })
    .catch ((res) => {
      commit('API_DOCUMENTATION_ERROR', res);
    })
  },

}

// mutations
const mutations = {

  //ERRORS
  API_DOCUMENTATION_ERROR (state, err) {
    try {
      state.errors = err.response.data;
    } catch (e) {
      state.errors = err;
    }  
  },

  CLEAR_ERRORS (state) {
    state.errors = null
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
