var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("layout", { attrs: { "no-sidebar": true } }, [
    _c("div", { staticClass: "page-header" }, [
      _c("h1", [_c("small", [_vm._v("Vendors:")])])
    ]),
    _vm._v(" "),
    _vm.loading
      ? _c("div", { staticClass: "text-center" }, [
          _c("i", { staticClass: "fa fa-spinner fa-spin fa-3x fa-fw" })
        ])
      : _c("div", { staticClass: "col-sm-8 col-sm-offset-2" }, [
          _c("div", { staticClass: "text-right" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-success",
                class: { disabled: _vm.processing },
                attrs: { disabled: _vm.processing },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.submit.apply(null, arguments)
                  }
                }
              },
              [
                _vm.processing
                  ? _c("span", [
                      _c("i", { staticClass: "fa fa-spinner fa-spin fa-fw" }),
                      _vm._v("\n        SAVING...\n      ")
                    ])
                  : _c("span", [_vm._v("SAVE")])
              ]
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "margin-bottom-3x" }, [
            _c("label", [_vm._v("Name")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.name,
                  expression: "form.name"
                }
              ],
              staticClass: "form-control",
              attrs: { type: "text" },
              domProps: { value: _vm.form.name },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.form, "name", $event.target.value)
                }
              }
            }),
            _vm._v(" "),
            _vm.errors.name
              ? _c("div", { staticClass: "text-danger" }, [
                  _vm._v("\n      " + _vm._s(_vm.errors.name[0]) + "\n    ")
                ])
              : _vm._e()
          ])
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-02d388e8", { render: render, staticRenderFns: staticRenderFns })
  }
}