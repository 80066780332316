import toastr from 'toastr'
import { funcModal } from 'components/modals'

const components = {
    funcModal
}

const methods = {
    open () {
        this.$refs.modal.open();
    },

    submit () {
        this.errors = {};
        this.processing = true;
        this.$store.dispatch('refundRequests.update', {
        id: this.$route.params.id,
        data: {
            status: 'approved',
        }
        }).then(() => {
        this.processing = false;
        let errors =  this.$store.state.refundRequests.errors;
        if (errors) {
            if (errors.errors) {
            this.errors = _.cloneDeep(errors.errors);  
            }
            toastr.error(errors.message);
        } else {
            toastr.success('Refund request has been updated successfully');
            this.$refs.modal.close();
        }
        });
    }
}

export default {
    name: 'approve-modal',
    methods,
    components,
    data () {
        return {
            errors: {},
            processing: false
        }
    }
}