import toastr from 'toastr'
import { funcModal } from 'components/modals'
import { dateFormat } from 'filters'

const components = {
    funcModal
}

const filters = {
    dateFormat
}
  
const computed = {
}

const methods = {
    open (reservation, activeHistory) {
        this.reservation = reservation;
        this.activeHistory = activeHistory;
        this.partial_refunds = reservation.partial_refunds;
        this.revert_type = 'revert_last';
        this.not_reverted_partial_refunds_count = 0;
        let last_pr_found = false;
        for (let i = this.partial_refunds.length -1; i >= 0; i--) {
            this.partial_refunds[i].selected = false;

            if (!this.partial_refunds[i].reverted ) {
                this.not_reverted_partial_refunds_count++;
                if (!last_pr_found) {
                    this.last_partial_refund = this.partial_refunds[i];
                    this.partial_refunds[i].selected = true;
                    last_pr_found = true;
                }
            }
        }
        this.amount = this.last_partial_refund.partial_refund_amount;
        this.$refs.modal.open();
    },

    onClose () {
        this.$emit('close');
    },

    changeRevertType () {
        if (this.revert_type == 'revert_last') {
            this.amount = this.last_partial_refund.partial_refund_amount;
        } else {
            this.selectChanged();
        }
    },

    selectChanged () {
        let sum = 0;
        this.history_ids_to_revert = [];
        for (let i = 0; i < this.partial_refunds.length; i++) {
            if (!this.partial_refunds[i].reverted && this.partial_refunds[i].selected) {
                sum += this.partial_refunds[i].partial_refund_amount;
                this.history_ids_to_revert.push(this.partial_refunds[i].reservation_history_id);
            }
        }
        this.amount = sum;
    },

    submit () {
        if (this.revert_type == 'select_revert' && !this.history_ids_to_revert.length) {
            this.errors = {
                history_ids_to_revert : [
                    "You should select at least one item"
                ]
            }
            return;
        }
        this.processing = true;
        this.errors = {};
        this.$store.dispatch('locations.revertPartialRefund', {
            lId: this.activeHistory.location_id, id: this.reservation.id,
            data: {
                history_ids_to_revert: this.history_ids_to_revert,
                amount: this.amount
            }
        }).then(() => {
            if (this.$store.state.locations.errors) {
                this.errors = _.cloneDeep(this.$store.state.locations.errors.errors);
                toastr.error(this.$store.state.locations.errors.message);
            } else {
                toastr.success('Partial refund has been reverted');
                this.$refs.modal.close();
            }

            this.processing = false;
        });
    }
}

export default {
    name: 'revert-partial-refund-modal',
    methods,
    computed,
    components,
    filters,
    data () {
        return {
            errors: {},
            reservation: null,
            activeHistory: null,
            partial_refunds: [],
            history_ids_to_revert: [],
            not_reverted_partial_refunds_count: 0,
            last_partial_refund: null,
            amount: 0,
            revert_type: 'revert_last',
            processing: false
        }
    }
}