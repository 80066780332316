import _ from 'lodash'
import { funcModal } from 'components/modals'
import sSelect from 'components/sSelect'
import typeForm from './form'
import toastr from 'toastr'

const components = {
  sSelect,
  funcModal,
  typeForm
}

const methods = {
  submit (modal) {
    let errors = false;
    this.errors = {};

    //validating totals
    let added_rooms = 0;
    for (let i in this.roomTypes) {
      if (this.roomTypes[i].id !== this.form.id) {
        added_rooms += parseInt(this.roomTypes[i].number_of_rooms);
      }
    }

    console.log("added_rooms", added_rooms);
    console.log("added_rooms + this.form.roomsNumber", added_rooms + parseInt(this.form.number_of_rooms));

    if (added_rooms + parseInt(this.form.number_of_rooms) > this.totalRoomsNumber) {
      this.errors.number_of_rooms = ["Number of total rooms types must be less than or equal to " + this.totalRoomsNumber + ", " + parseInt(this.totalRoomsNumber - added_rooms) + ' left.'];
      errors = true;
    }

    if (errors) return;

    let data = _.cloneDeep(this.form)
    data.location_id = this.$route.params.id;

    this.$store.dispatch('locations.updateRoomType', {id: this.form.id, data}).then(() => {
      if (this.$store.state.locations.errors) {
        toastr.error(this.$store.state.locations.errors.message);
        this.errors = _.cloneDeep(this.$store.state.locations.errors.errors);
      } else {
        this.form.number_of_rooms = null;
        this.form.name = null;
        modal.close();
      }
    });

  },

  open () {
    this.$refs.modal.open();
  }
}

const computed = {
  roomTypes () {
    return _.cloneDeep(this.$store.state.locations.active.roomTypes);
  },

  totalRoomsNumber () {
    return this.$store.state.locations.active.number_of_rooms;
  }
}

const watch = {
  type (n) {
    if (_.isEmpty(n)) {
      return n
    }
    this.form = n;
    this.form.beds = _.map(n.beds, (item) => {
      return {
        id: item.id,
        number_of_beds: item.pivot.beds_number
      }
    });
  }
}

export default {
  name: 'edit-rooms-type',
  props: ['type'],
  methods,
  components,
  computed,
  watch,
  data() {
    return {
      errors: {},
      form: this.type
    }
  },
  created () {
    this.form.beds = _.map(this.type.beds, (item) => {
      return {
        id: item.id,
        number_of_beds: item.pivot.beds_number
      }
    });
  }
}
