import {
  employees, channels, reservations, locationEmails
} from 'pages/locations'

export default [
  //administration
  { path: '/locations/:id/administration/users', name: 'locations.administration.employees', component: employees
    , meta:{permits:['location_user', 'locationEmployee_list']}},
  { path: '/locations/:id/administration/channels', name: 'locations.administration.channels', component: channels
    , meta:{permits:['location_user', 'location_channel_list']}},
  { path: '/locations/:id/administration/reservations', name: 'locations.administration.reservations', component: reservations
    , meta:{permits:['location_user', 'reservations_list'], keepAlive: true}},
  { path: '/locations/:id/administration/location-emails', name: 'locations.administration.emails', component: locationEmails
    , meta:{permits:['location_user', 'locationEmails_list']}},
]
