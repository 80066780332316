import layout from 'layouts/default'
import { hasPermits } from 'directives'
import timepicker from 'components/timepicker'
import _ from 'lodash'

const directives = {
  hasPermits
}

const components = {
  layout,
  timepicker,
}

const computed = {
  
}

const methods = {
  addAttribute(index = -1) {
    this.editIntegrationAttributes = true;
    let item = {
      key: '',
      value: '',
    }
    
    let length = this.attributes.length; 
    if (index == -1 || index >= length) {
      this.attributes.push(item);
    } else {
      this.attributes.splice(index, 0, item);
    }
    this.onchange();
  },

  removeAttribute(index) {
    this.attributes.splice(index, 1);
    this.onchange();
  },

  onchange() {
    this.$emit('input', this.attributes);
  },

  startEdit() {
    this.editIntegrationAttributes = true;
  },
}

export default {
  name: "integration-system-attributes",
  props: ['value', 'errors'],
  computed,
  components,
  methods,
  directives,
  data () {
    return {
      locId: this.$route.params.id,
      attributes: _.cloneDeep(this.value) || [],
      loading: false,
      processing: false,
      editIntegrationAttributes: false,
    }
  },
  created () {
  },

  mounted() {
  }
}
