import moment from 'moment'
import toastr from 'toastr'
import { permits } from 'services'
import { baseModal } from 'components/modals'
import { hasPermits } from 'directives'
import datepicker from 'components/datepicker'

const components = {
  baseModal,
  datepicker
}

const directives = {
  hasPermits
}

const computed = {
  types () {
    let types = _.cloneDeep(this.$store.state.locations.active.bundleTypes);
    if (this.isLocationUser()) {
      types = types.filter(function (rate) { return rate['rate_type'] !== 'L'; });
    }
    return types;
  },

  channels () {
    return _.cloneDeep(this.$store.state.locations.active.channels) || [];
  },

  hasSelectChannelsPermit () {
    return permits.hasLocationPermit(this.$route.params.id, 'location_channel_select');
  }
}

const methods = {
  submit (modal) {
    this.errors = {}
    this.processing = true;

    let data = _.cloneDeep(this.form);
    data.location_id = this.$route.params.id;
    data.from_date = moment(data.from_date, ['MM/DD/YYYY']).format('YYYY-MM-DD HH:mm:ss');
    data.to_date = data.to_date ?
      moment(data.to_date, ['MM/DD/YYYY']).format('YYYY-MM-DD HH:mm:ss') : null;
    data.change_price_reason = this.isLocationUser() ? 'Random (e.g. location request without reason)' : data.change_price_reason;

    this.$store.dispatch('locations.addBundleRate', data).then(() => {
      this.processing = false;
      if (this.$store.state.locations.errors) {
        if (this.$store.state.locations.errors.errors) {
          this.errors = _.cloneDeep(this.$store.state.locations.errors.errors);
        }

        toastr.error(this.$store.state.locations.errors.message);
      } else {
        this.$emit('bundleSaved', data);
        toastr.success('Data has been saved');
        modal.close();
        this.form = {
          price: null,
          change_price_reason: null,
          from_date: null,
          to_date: null,
          type_id: null
        }
        this.selectAll();
      }

    });

  },
  selectAll () {
    this.selectNone();
    if(this.channels && this.channels.length > 0) {
      this.channels.forEach((item) => {
        this.form.channels.push(item.id);
      });
    }
  },

  selectNone () {
    this.form.channels = [];
  },

  isLocationUser () {
    return !permits.hasPermit('super_admin') && permits.hasPermit('location_user');
  },
}

const watch = {
  channels (n, o) {
    if (n.length != o.length) {
      this.selectAll();
    }
  }
}

export default {
  name: "room-rate-modal",
  components,
  computed,
  methods,
  directives,
  watch,
  data () {
    return {
      errors: {},
      form: {
        from_date: null,
        price: null,
        change_price_reason: null,
        channels: [],
        type_id: null,
        from_date: null,
        to_date: null
      },
      loading_rates: false,
      processing: false
    }
  },

  created () {
    this.selectAll();
  }
}
