var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "layout",
    { attrs: { "no-box": true, title: _vm.location ? _vm.location.name : "" } },
    [
      _c("tabs"),
      _vm._v(" "),
      _c("div", { staticClass: "box" }, [
        _c("div", { staticClass: "box-body" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-sm-3" }, [
              _c("label", [
                _vm._v(
                  "Reservation Number Or Extra Fields             \n              "
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.filters.search,
                      expression: "filters.search"
                    }
                  ],
                  ref: "search",
                  staticClass: "form-control margin-top-2x",
                  attrs: { type: "text", placeholder: "Search for..." },
                  domProps: { value: _vm.filters.search },
                  on: {
                    keyup: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        $event.keyCode !== 13
                      ) {
                        return null
                      }
                      return _vm.loadPage(1)
                    },
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.filters, "search", $event.target.value)
                    }
                  }
                })
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-sm-3" }, [
              _c("label", { attrs: { for: "" } }, [_vm._v("Search By:")]),
              _vm._v(" "),
              _c("div", { staticClass: "radio" }, [
                _c("label", { staticClass: "margin-right-1x" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.filters.search_by,
                        expression: "filters.search_by"
                      }
                    ],
                    attrs: { type: "radio", value: "reservation_number" },
                    domProps: {
                      checked: _vm._q(
                        _vm.filters.search_by,
                        "reservation_number"
                      )
                    },
                    on: {
                      change: function($event) {
                        return _vm.$set(
                          _vm.filters,
                          "search_by",
                          "reservation_number"
                        )
                      }
                    }
                  }),
                  _vm._v("\n              Reservation Number\n            ")
                ]),
                _vm._v(" "),
                _c("label", { staticClass: "margin-right-1x" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.filters.search_by,
                        expression: "filters.search_by"
                      }
                    ],
                    attrs: { type: "radio", value: "email" },
                    domProps: {
                      checked: _vm._q(_vm.filters.search_by, "email")
                    },
                    on: {
                      change: function($event) {
                        return _vm.$set(_vm.filters, "search_by", "email")
                      }
                    }
                  }),
                  _vm._v("\n              Email\n            ")
                ]),
                _vm._v(" "),
                _c("label", { staticClass: "margin-right-1x" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.filters.search_by,
                        expression: "filters.search_by"
                      }
                    ],
                    attrs: { type: "radio", value: "name" },
                    domProps: {
                      checked: _vm._q(_vm.filters.search_by, "name")
                    },
                    on: {
                      change: function($event) {
                        return _vm.$set(_vm.filters, "search_by", "name")
                      }
                    }
                  }),
                  _vm._v("\n              Name\n            ")
                ]),
                _vm._v(" "),
                _c("label", { staticClass: "margin-right-1x" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.filters.search_by,
                        expression: "filters.search_by"
                      }
                    ],
                    attrs: { type: "radio", value: "extra_field" },
                    domProps: {
                      checked: _vm._q(_vm.filters.search_by, "extra_field")
                    },
                    on: {
                      change: function($event) {
                        return _vm.$set(_vm.filters, "search_by", "extra_field")
                      }
                    }
                  }),
                  _vm._v("\n              Extra Field\n            ")
                ]),
                _vm._v(" "),
                _c("label", { staticClass: "margin-right-1x" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.filters.search_by,
                        expression: "filters.search_by"
                      }
                    ],
                    attrs: { type: "radio", value: "all" },
                    domProps: { checked: _vm._q(_vm.filters.search_by, "all") },
                    on: {
                      change: function($event) {
                        return _vm.$set(_vm.filters, "search_by", "all")
                      }
                    }
                  }),
                  _vm._v("\n              All\n            ")
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-sm-2" }, [
              _c("div", [
                _c("label", { attrs: { for: "" } }, [_vm._v("Res type")])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "radio" }, [
                _c("label", { staticClass: "margin-right-1x" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.filters.type,
                        expression: "filters.type"
                      }
                    ],
                    attrs: { type: "radio" },
                    domProps: {
                      value: null,
                      checked: _vm._q(_vm.filters.type, null)
                    },
                    on: {
                      change: function($event) {
                        return _vm.$set(_vm.filters, "type", null)
                      }
                    }
                  }),
                  _vm._v("\n              All\n            ")
                ]),
                _vm._v(" "),
                _c("label", { staticClass: "margin-right-1x" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.filters.type,
                        expression: "filters.type"
                      }
                    ],
                    attrs: { type: "radio", value: "parking" },
                    domProps: { checked: _vm._q(_vm.filters.type, "parking") },
                    on: {
                      change: function($event) {
                        return _vm.$set(_vm.filters, "type", "parking")
                      }
                    }
                  }),
                  _vm._v("\n              Parking\n            ")
                ]),
                _vm._v(" "),
                _c("label", { staticClass: "margin-right-1x" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.filters.type,
                        expression: "filters.type"
                      }
                    ],
                    attrs: { type: "radio", value: "room" },
                    domProps: { checked: _vm._q(_vm.filters.type, "room") },
                    on: {
                      change: function($event) {
                        return _vm.$set(_vm.filters, "type", "room")
                      }
                    }
                  }),
                  _vm._v("\n              Room\n            ")
                ]),
                _vm._v(" "),
                _c("label", { staticClass: "margin-right-1x" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.filters.type,
                        expression: "filters.type"
                      }
                    ],
                    attrs: { type: "radio", value: "both" },
                    domProps: { checked: _vm._q(_vm.filters.type, "both") },
                    on: {
                      change: function($event) {
                        return _vm.$set(_vm.filters, "type", "both")
                      }
                    }
                  }),
                  _vm._v("\n              Both\n            ")
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-sm-2" }, [
              _c("div", [
                _c("label", { attrs: { for: "" } }, [_vm._v("Active res")])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "radio" }, [
                _c("label", { staticClass: "margin-right-1x" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.filters.cancelled,
                        expression: "filters.cancelled"
                      }
                    ],
                    attrs: { type: "radio" },
                    domProps: {
                      value: null,
                      checked: _vm._q(_vm.filters.cancelled, null)
                    },
                    on: {
                      change: function($event) {
                        return _vm.$set(_vm.filters, "cancelled", null)
                      }
                    }
                  }),
                  _vm._v("\n              All\n            ")
                ]),
                _vm._v(" "),
                _c("label", { staticClass: "margin-right-1x" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.filters.cancelled,
                        expression: "filters.cancelled"
                      }
                    ],
                    attrs: { type: "radio" },
                    domProps: {
                      value: 0,
                      checked: _vm._q(_vm.filters.cancelled, 0)
                    },
                    on: {
                      change: function($event) {
                        return _vm.$set(_vm.filters, "cancelled", 0)
                      }
                    }
                  }),
                  _vm._v("\n              Active\n            ")
                ]),
                _vm._v(" "),
                _c("label", { staticClass: "margin-right-1x" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.filters.cancelled,
                        expression: "filters.cancelled"
                      }
                    ],
                    attrs: { type: "radio" },
                    domProps: {
                      value: 1,
                      checked: _vm._q(_vm.filters.cancelled, 1)
                    },
                    on: {
                      change: function($event) {
                        return _vm.$set(_vm.filters, "cancelled", 1)
                      }
                    }
                  }),
                  _vm._v("\n              Cancelled\n            ")
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-sm-1" }, [
              _c("div", [_c("label", { attrs: { for: "" } })]),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.loadPage(1)
                    }
                  }
                },
                [_vm._v("Apply")]
              )
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "box" }, [
        _c("div", { staticClass: "box-body" }, [
          _c("div", { staticClass: "row margin-top-4x" }, [
            _c("div", { staticClass: "col-sm-12" }, [
              _vm.loading
                ? _c("div", { staticClass: "text-center" }, [
                    _c("i", {
                      staticClass: "fa fa-spinner fa-spin fa-3x fa-fw"
                    })
                  ])
                : _c(
                    "div",
                    [
                      _c("reservation-details", {
                        ref: "reservationDetailsModal",
                        attrs: { reservation: _vm.activeReservation }
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "table-responsive" }, [
                        _c("table", { staticClass: "table table-striped" }, [
                          _c("thead", [
                            _c("tr", [
                              _c("th", [_vm._v("Res #")]),
                              _vm._v(" "),
                              _c("th", [_vm._v("Creation Date")]),
                              _vm._v(" "),
                              _c("th", [_vm._v("Status")]),
                              _vm._v(" "),
                              _c("th", [_vm._v("Location Name")]),
                              _vm._v(" "),
                              _c("th", [_vm._v("Reserved By")]),
                              _vm._v(" "),
                              _c("th", [_vm._v("Info.")]),
                              _vm._v(" "),
                              _c("th", [_vm._v("Grand Total")]),
                              _vm._v(" "),
                              _c("th", [_vm._v("Details")]),
                              _vm._v(" "),
                              _c(
                                "th",
                                {
                                  directives: [
                                    {
                                      name: "hasPermits",
                                      rawName: "v-hasPermits:allow",
                                      value: {
                                        id: _vm.location.id,
                                        permits: [
                                          "update_reservation_dates",
                                          "update_reservation"
                                        ]
                                      },
                                      expression:
                                        "{id: location.id, permits: ['update_reservation_dates', 'update_reservation']}",
                                      arg: "allow"
                                    }
                                  ]
                                },
                                [_vm._v("Edit")]
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c(
                            "tbody",
                            _vm._l(_vm.reservations.data, function(item) {
                              return _c(
                                "tr",
                                { class: { "bg-warning": item.no_show } },
                                [
                                  _c("td", [
                                    _vm._v(_vm._s(item.reservation_number))
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("dateFormat")(
                                          item.created_at,
                                          "MM/DD/YYYY hh:mm A"
                                        )
                                      )
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        item.cancelled ? "Cancelled" : "Active"
                                      )
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        item.active_history
                                          ? item.active_history.location.name
                                          : ""
                                      )
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [_vm._v(_vm._s(item.reserved_by))]),
                                  _vm._v(" "),
                                  item.active_history
                                    ? _c(
                                        "td",
                                        _vm._l(
                                          item.active_history.dates,
                                          function(date) {
                                            return item.active_history
                                              ? _c(
                                                  "table",
                                                  {
                                                    staticClass: "table",
                                                    class: {
                                                      "bg-warning": item.no_show
                                                    }
                                                  },
                                                  [
                                                    _c("tr", [
                                                      _c("th", [
                                                        _vm._v("Type")
                                                      ]),
                                                      _vm._v(" "),
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(date.type_type)
                                                        )
                                                      ])
                                                    ]),
                                                    _vm._v(" "),
                                                    _c("tr", [
                                                      _c("th", [
                                                        _vm._v("From Date")
                                                      ]),
                                                      _vm._v(" "),
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm._f(
                                                              "dateFormat"
                                                            )(
                                                              date.from_date,
                                                              "MM/DD/YYYY hh:mm A"
                                                            )
                                                          )
                                                        )
                                                      ])
                                                    ]),
                                                    _vm._v(" "),
                                                    _c("tr", [
                                                      _c("th", [
                                                        _vm._v("To Date")
                                                      ]),
                                                      _vm._v(" "),
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm._f(
                                                              "dateFormat"
                                                            )(
                                                              date.to_date,
                                                              "MM/DD/YYYY hh:mm A"
                                                            )
                                                          )
                                                        )
                                                      ])
                                                    ])
                                                  ]
                                                )
                                              : _vm._e()
                                          }
                                        ),
                                        0
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("td", [
                                    _c(
                                      "span",
                                      {
                                        class: {
                                          "text-danger":
                                            _vm.currencyCode == "Na"
                                        }
                                      },
                                      [_vm._v(_vm._s(_vm.currencyCode))]
                                    ),
                                    _vm._v(
                                      "\n                      " +
                                        _vm._s(
                                          item.active_history
                                            ? +item.active_history.grand_total
                                            : ""
                                        ) +
                                        "\n                    "
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm.loadings[item.id]
                                      ? _c("span", [
                                          _c("i", {
                                            staticClass: "fa fa-spin fa-spinner"
                                          })
                                        ])
                                      : _c(
                                          "a",
                                          {
                                            attrs: { href: "#" },
                                            on: {
                                              click: function($event) {
                                                $event.preventDefault()
                                                return _vm.loadReservation(item)
                                              }
                                            }
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "fa fa-ellipsis-h",
                                              attrs: { "aria-hidden": "true" }
                                            })
                                          ]
                                        )
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    {
                                      directives: [
                                        {
                                          name: "hasPermits",
                                          rawName: "v-hasPermits:allow",
                                          value: {
                                            id: _vm.location.id,
                                            permits: [
                                              "update_reservation_dates",
                                              "update_reservation"
                                            ]
                                          },
                                          expression:
                                            "{id: location.id, permits: ['update_reservation_dates', 'update_reservation']}",
                                          arg: "allow"
                                        }
                                      ]
                                    },
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          attrs: {
                                            to: {
                                              name: "reservations.edit",
                                              params: { id: item.id }
                                            }
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fa fa-pencil",
                                            attrs: { "aria-hidden": "true" }
                                          })
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              )
                            }),
                            0
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("pagination", {
                        attrs: { "pages-number": _vm.reservations.last_page },
                        on: {
                          pageChanged: function(page) {
                            return _vm.loadPage(page)
                          }
                        }
                      })
                    ],
                    1
                  )
            ])
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-02df59f6", { render: render, staticRenderFns: staticRenderFns })
  }
}