import toastr from 'toastr'
import moment from 'moment'
import { permits } from 'services'
import sSelect from 'components/sSelect'
import datepicker from 'components/datepicker'
import autocomplete from 'components/autocomplete'
import { hasPermits } from 'directives'
import { baseModal } from 'components/modals'

const components = {
  baseModal,
  sSelect,
  datepicker,
  autocomplete
}

const directives = {
  hasPermits
}

const computed = {
  types () {
    let types = this.$store.state.locations.active.roomTypes.map((item) => {
      return _.merge({text: item.name, value: item.id}, item)
    });

    if (this.isLocationUser()) {
      types = types.filter(function (rate) { return rate['rate_type'] !== 'L'; });
    }
    return types;
  },
  channels () {
    return _.cloneDeep(this.$store.state.locations.active.channels) || [];
  },

  hasSelectChannelsPermit () {
    return permits.hasLocationPermit(this.$route.params.id, 'location_channel_select');
  }
}

const methods = {
  typeChanged (n) {
    this.form.type = n
  },
  submit (modal) {
    this.errors = {}
    let required = {
      type: "" + this.form.type.value,
      // number_of_rooms: this.form.number_of_rooms,
      from_date: this.form.from_date,
      change_price_reason: this.isLocationUser() ? 'Random (e.g. location request without reason)' : this.form.change_price_reason,
      price: this.form.price
    }

    let hasError = false;

    for (let i in required) {
      if (!required[i]) {
        this.errors[i] = ['this field is required'];
        hasError = true;
      }
    }

    if (!this.selectedChannels.length) {
      this.errors.channels = ['You must select at least one channel'];
      hasError = true;
    }

    if (hasError) {
      return;
    }

    let data = _.cloneDeep(this.form);
    data.location_room_type_id = data.type.value;
    delete data.type;
    data.channels = this.selectedChannels.map((channel) => {
      return channel.id;
    });
    data.from_date = moment(data.from_date, ['MM/DD/YYYY']).format('YYYY-MM-DD HH:mm:ss');
    data.to_date = data.to_date ? moment(data.to_date, ['MM/DD/YYYY']).format('YYYY-MM-DD HH:mm:ss') : null;
    data.location_id = this.$route.params.id;
    data.change_price_reason = this.isLocationUser() ? 'Random (e.g. location request without reason)' : data.change_price_reason;
    // console.log('data.channels', data.channels);
    //Add rates to database here
    this.$store.dispatch('locations.addRoomRateToChannels', data).then(() => {
      if (this.$store.state.locations.errors) {
        this.errors = _.cloneDeep(this.$store.state.locations.errors.errors);
        toastr.error(this.$store.state.locations.errors.message);
      } else {
        this.$emit('rateSaved', data);
        toastr.success('Data has been saved');
        modal.close();
        this.form = {
          type: {value: null, text: '-- Choose --'},
          // number_of_rooms: null,
          from_date: null,
          price: null,
          change_price_reason: null,
          to_date: null
        }
        this.selectAll();
        this.errors = {};
      }
    });

  },
  selectAll () {
    this.selectNone();
    if(this.channels && this.channels.length > 0) {
      this.channels.forEach((item) => {
        this.selectedChannels.push(item);
      });
    }
  },

  selectNone () {
    this.selectedChannels = [];
  },

  isLocationUser () {
    return !permits.hasPermit('super_admin') && permits.hasPermit('location_user');
  },
}

const watch = {
  channels (n, o) {
    if (n.length != o.length) {
      this.selectAll();
    }
  }
}

export default {
  name: "room-rate-modal",
  components,
  directives,
  computed,
  methods,
  watch,
  data () {
    return {
      errors: {},
      form: {
        type: {value: null, text: '-- Choose --'},
        change_price_reason: null,
        // number_of_rooms: null,
        from_date: null,
        price: null,
        to_date: null
      },
      selectedChannels: [],
      loading_rates: false
    }
  },

  created () {
    this.selectAll();
  }
}
