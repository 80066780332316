var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "layout",
    { attrs: { "no-box": true, title: _vm.location ? _vm.location.name : "" } },
    [
      _c("tabs"),
      _vm._v(" "),
      _c("div", { staticClass: "box" }, [
        _c("div", { staticClass: "box-body" }, [
          _vm.loading
            ? _c("div", { staticClass: "text-center" }, [
                _c("i", { staticClass: "fa fa-spinner fa-spin fa-3x fa-fw" })
              ])
            : _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "hasPermits",
                        rawName: "v-hasPermits:allow",
                        value: { id: _vm.locId, permits: ["location_update"] },
                        expression: "{id: locId, permits: ['location_update']}",
                        arg: "allow"
                      }
                    ],
                    staticClass:
                      "col-sm-12 text-right margin-top-4x margin-bottom-4x"
                  },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-success",
                        class: { disabled: _vm.processing },
                        attrs: { disabled: _vm.processing },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.submit()
                          }
                        }
                      },
                      [
                        _vm.processing
                          ? _c("span", [
                              _c("i", {
                                staticClass: "fa fa-spinner fa-spin fa-fw"
                              })
                            ])
                          : _vm._e(),
                        _vm._v("\n          Save\n        ")
                      ]
                    )
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-6" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: "locationName" } }, [
                      _vm._v("Location Name")
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.form.name,
                          expression: "form.name",
                          modifiers: { trim: true }
                        },
                        {
                          name: "hasPermits",
                          rawName: "v-hasPermits:allow",
                          value: {
                            id: _vm.locId,
                            permits: ["location_name_update"]
                          },
                          expression:
                            "{id: locId, permits: ['location_name_update']}",
                          arg: "allow"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        id: "locationName",
                        placeholder: "Location Name"
                      },
                      domProps: { value: _vm.form.name },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.form, "name", $event.target.value.trim())
                        },
                        blur: function($event) {
                          return _vm.$forceUpdate()
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.form.name,
                          expression: "form.name",
                          modifiers: { trim: true }
                        },
                        {
                          name: "hasPermits",
                          rawName: "v-hasPermits:prevent",
                          value: {
                            id: _vm.locId,
                            permits: ["location_name_update", "super_admin"]
                          },
                          expression:
                            "{id: locId, permits: ['location_name_update', 'super_admin']}",
                          arg: "prevent"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        readonly: "",
                        disabled: "",
                        id: "locationName",
                        placeholder: "Location Name"
                      },
                      domProps: { value: _vm.form.name },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.form, "name", $event.target.value.trim())
                        },
                        blur: function($event) {
                          return _vm.$forceUpdate()
                        }
                      }
                    }),
                    _vm._v(" "),
                    _vm.errors.name
                      ? _c("div", { staticClass: "text-danger" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.errors.name[0]) +
                              "\n          "
                          )
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: "locationName" } }, [
                      _vm._v("Location ID")
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.form.id,
                          expression: "form.id",
                          modifiers: { trim: true }
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        disabled: "",
                        readonly: "",
                        type: "text",
                        id: "locationId",
                        placeholder: "Location ID"
                      },
                      domProps: { value: _vm.form.id },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.form, "id", $event.target.value.trim())
                        },
                        blur: function($event) {
                          return _vm.$forceUpdate()
                        }
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: "locationPhone" } }, [
                      _vm._v("Phone Number")
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.form.phone,
                          expression: "form.phone",
                          modifiers: { trim: true }
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        id: "locationPhone",
                        placeholder: "Location Phone Number"
                      },
                      domProps: { value: _vm.form.phone },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.form,
                            "phone",
                            $event.target.value.trim()
                          )
                        },
                        blur: function($event) {
                          return _vm.$forceUpdate()
                        }
                      }
                    }),
                    _vm._v(" "),
                    _vm.errors.phone
                      ? _c("div", { staticClass: "text-danger" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.errors.phone[0]) +
                              "\n          "
                          )
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: "locationFax" } }, [
                      _vm._v("Fax Number")
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.form.fax,
                          expression: "form.fax",
                          modifiers: { trim: true }
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        id: "locationFax",
                        placeholder: "Location Fax Number"
                      },
                      domProps: { value: _vm.form.fax },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.form, "fax", $event.target.value.trim())
                        },
                        blur: function($event) {
                          return _vm.$forceUpdate()
                        }
                      }
                    }),
                    _vm._v(" "),
                    _vm.errors.fax
                      ? _c("div", { staticClass: "text-danger" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.errors.fax[0]) +
                              "\n          "
                          )
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.send_fax,
                            expression: "form.send_fax"
                          }
                        ],
                        attrs: { type: "checkbox" },
                        domProps: {
                          checked: Array.isArray(_vm.form.send_fax)
                            ? _vm._i(_vm.form.send_fax, null) > -1
                            : _vm.form.send_fax
                        },
                        on: {
                          change: function($event) {
                            var $$a = _vm.form.send_fax,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(
                                    _vm.form,
                                    "send_fax",
                                    $$a.concat([$$v])
                                  )
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    _vm.form,
                                    "send_fax",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(_vm.form, "send_fax", $$c)
                            }
                          }
                        }
                      }),
                      _vm._v("\n            Send Fax\n          ")
                    ]),
                    _vm._v(" "),
                    _vm.errors.send_fax
                      ? _c("div", { staticClass: "text-danger" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.errors.send_fax[0]) +
                              "\n          "
                          )
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", [_vm._v("Country")]),
                    _vm._v(" "),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.country_id,
                            expression: "form.country_id"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: { placeholder: "Country" },
                        on: {
                          change: function($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function(o) {
                                return o.selected
                              })
                              .map(function(o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.form,
                              "country_id",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          }
                        }
                      },
                      _vm._l(_vm.countries, function(country) {
                        return _c(
                          "option",
                          { domProps: { value: country.id } },
                          [_vm._v(_vm._s(country.name))]
                        )
                      }),
                      0
                    ),
                    _vm._v(" "),
                    _vm.errors.country_id
                      ? _c("div", { staticClass: "text-danger" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.errors.country_id[0]) +
                              "\n          "
                          )
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", [_vm._v("State")]),
                    _vm._v(" "),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.state_id,
                            expression: "form.state_id"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: { placeholder: "Country" },
                        on: {
                          change: function($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function(o) {
                                return o.selected
                              })
                              .map(function(o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.form,
                              "state_id",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          }
                        }
                      },
                      _vm._l(_vm.states, function(state) {
                        return _c("option", { domProps: { value: state.id } }, [
                          _vm._v(_vm._s(state.name))
                        ])
                      }),
                      0
                    ),
                    _vm._v(" "),
                    _vm.errors.state_id
                      ? _c("div", { staticClass: "text-danger" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.errors.state_id[0]) +
                              "\n          "
                          )
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", [_vm._v("City")]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.form.city,
                          expression: "form.city",
                          modifiers: { trim: true }
                        }
                      ],
                      staticClass: "form-control",
                      attrs: { type: "text", placeholder: "City name" },
                      domProps: { value: _vm.form.city },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.form, "city", $event.target.value.trim())
                        },
                        blur: function($event) {
                          return _vm.$forceUpdate()
                        }
                      }
                    }),
                    _vm._v(" "),
                    _vm.errors.city
                      ? _c("div", { staticClass: "text-danger" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.errors.city[0]) +
                              "\n          "
                          )
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", [_vm._v("Port Type")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "input-group radio" }, [
                      _c("label", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.port_type,
                              expression: "form.port_type"
                            }
                          ],
                          attrs: { type: "radio", value: "airport" },
                          domProps: {
                            checked: _vm._q(_vm.form.port_type, "airport")
                          },
                          on: {
                            change: function($event) {
                              return _vm.$set(_vm.form, "port_type", "airport")
                            }
                          }
                        }),
                        _vm._v("\n              Airport\n            ")
                      ]),
                      _vm._v("\n             \n            "),
                      _c("label", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.port_type,
                              expression: "form.port_type"
                            }
                          ],
                          attrs: { type: "radio", value: "seaport" },
                          domProps: {
                            checked: _vm._q(_vm.form.port_type, "seaport")
                          },
                          on: {
                            change: function($event) {
                              return _vm.$set(_vm.form, "port_type", "seaport")
                            }
                          }
                        }),
                        _vm._v("\n              Seaport\n            ")
                      ])
                    ]),
                    _vm._v(" "),
                    _vm.errors.port_type
                      ? _c("div", { staticClass: "text-danger" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.errors.port_type[0]) +
                              "\n          "
                          )
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: "timezone" } }, [
                      _vm._v("Timezone")
                    ]),
                    _vm._v(" "),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.timezone_id,
                            expression: "form.timezone_id"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: { id: "timezone" },
                        on: {
                          change: function($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function(o) {
                                return o.selected
                              })
                              .map(function(o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.form,
                              "timezone_id",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          }
                        }
                      },
                      [
                        _c("option", { domProps: { value: null } }, [
                          _vm._v("Select Timezone")
                        ]),
                        _vm._v(" "),
                        _vm._l(_vm.timezones, function(timezone) {
                          return _c(
                            "option",
                            { domProps: { value: timezone.id } },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(timezone.name) +
                                  "\n            "
                              )
                            ]
                          )
                        })
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _vm.errors.timezone_id
                      ? _c("div", { staticClass: "text-danger" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.errors.timezone_id[0]) +
                              "\n          "
                          )
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: "locationAddress" } }, [
                      _vm._v("Address")
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.form.address,
                          expression: "form.address",
                          modifiers: { trim: true }
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        id: "locationAddress",
                        placeholder: "Address"
                      },
                      domProps: { value: _vm.form.address },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.form,
                            "address",
                            $event.target.value.trim()
                          )
                        },
                        blur: function($event) {
                          return _vm.$forceUpdate()
                        }
                      }
                    }),
                    _vm._v(" "),
                    _vm.errors.address
                      ? _c("div", { staticClass: "text-danger" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.errors.address[0]) +
                              "\n          "
                          )
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: "locationZipCode" } }, [
                      _vm._v("Zip/Postal Code")
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.form.zip_code,
                          expression: "form.zip_code",
                          modifiers: { trim: true }
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        id: "locationZipCode",
                        placeholder: "Address"
                      },
                      domProps: { value: _vm.form.zip_code },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.form,
                            "zip_code",
                            $event.target.value.trim()
                          )
                        },
                        blur: function($event) {
                          return _vm.$forceUpdate()
                        }
                      }
                    }),
                    _vm._v(" "),
                    _vm.errors.zip_code
                      ? _c("div", { staticClass: "text-danger" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.errors.zip_code[0]) +
                              "\n          "
                          )
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: "directions" } }, [
                      _vm._v("Specific Directions")
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.form.directions,
                          expression: "form.directions",
                          modifiers: { trim: true }
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        id: "directions",
                        placeholder:
                          "Tell the customer if they need specific directions"
                      },
                      domProps: { value: _vm.form.directions },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.form,
                            "directions",
                            $event.target.value.trim()
                          )
                        },
                        blur: function($event) {
                          return _vm.$forceUpdate()
                        }
                      }
                    }),
                    _vm._v(" "),
                    _vm.errors.directions
                      ? _c("div", { staticClass: "text-danger" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.errors.directions[0]) +
                              "\n          "
                          )
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.send_special_instructions_email,
                            expression: "form.send_special_instructions_email"
                          }
                        ],
                        attrs: { type: "checkbox" },
                        domProps: {
                          checked: Array.isArray(
                            _vm.form.send_special_instructions_email
                          )
                            ? _vm._i(
                                _vm.form.send_special_instructions_email,
                                null
                              ) > -1
                            : _vm.form.send_special_instructions_email
                        },
                        on: {
                          change: function($event) {
                            var $$a = _vm.form.send_special_instructions_email,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(
                                    _vm.form,
                                    "send_special_instructions_email",
                                    $$a.concat([$$v])
                                  )
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    _vm.form,
                                    "send_special_instructions_email",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(
                                _vm.form,
                                "send_special_instructions_email",
                                $$c
                              )
                            }
                          }
                        }
                      }),
                      _vm._v(
                        "\n            Send email after booking\n          "
                      )
                    ]),
                    _vm._v(" "),
                    _vm.errors.send_special_instructions_email
                      ? _c("div", { staticClass: "text-danger" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.errors.send_special_instructions_email[0]
                              ) +
                              "\n          "
                          )
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c(
                      "label",
                      {
                        attrs: {
                          for: "number_of_days_before_reservation_starts"
                        }
                      },
                      [_vm._v("Number of days before the reservation starts")]
                    ),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value:
                            _vm.form.number_of_days_before_reservation_starts,
                          expression:
                            "form.number_of_days_before_reservation_starts",
                          modifiers: { trim: true }
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "number",
                        min: "1",
                        id: "number_of_days_before_reservation_starts",
                        placeholder:
                          "number of days before the reservation start"
                      },
                      domProps: {
                        value: _vm.form.number_of_days_before_reservation_starts
                      },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.form,
                            "number_of_days_before_reservation_starts",
                            $event.target.value.trim()
                          )
                        },
                        blur: function($event) {
                          return _vm.$forceUpdate()
                        }
                      }
                    }),
                    _vm._v(" "),
                    _vm.errors.number_of_days_before_reservation_starts
                      ? _c("div", { staticClass: "text-danger" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.errors
                                  .number_of_days_before_reservation_starts[0]
                              ) +
                              "\n          "
                          )
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("label", [_vm._v("Special Instructions")]),
                      _vm._v(" "),
                      _c("tipTap", {
                        attrs: { removeFormatOnPaste: true },
                        model: {
                          value: _vm.form.special_instructions,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "special_instructions", $$v)
                          },
                          expression: "form.special_instructions"
                        }
                      }),
                      _vm._v(" "),
                      _vm.errors.special_instructions
                        ? _c("div", { staticClass: "text-danger" }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.errors.special_instructions[0]) +
                                "\n          "
                            )
                          ])
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: "checkinTime" } }, [
                      _vm._v("Check-in Time")
                    ]),
                    _vm._v(" "),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.checkin_time,
                            expression: "form.checkin_time"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: { id: "checkinTime" },
                        on: {
                          change: function($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function(o) {
                                return o.selected
                              })
                              .map(function(o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.form,
                              "checkin_time",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          }
                        }
                      },
                      [
                        _c("option", { attrs: { value: "" } }, [
                          _vm._v("-- Choose time --")
                        ]),
                        _vm._v(" "),
                        _vm._l(_vm.times, function(time) {
                          return _c(
                            "option",
                            { domProps: { value: time.value } },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(time.label) +
                                  "\n            "
                              )
                            ]
                          )
                        })
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _vm.errors.checkin_time
                      ? _c("div", { staticClass: "text-danger" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.errors.checkin_time[0]) +
                              "\n          "
                          )
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: "checkoutTime" } }, [
                      _vm._v("Check-out Time")
                    ]),
                    _vm._v(" "),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.checkout_time,
                            expression: "form.checkout_time"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: { id: "checkoutTime" },
                        on: {
                          change: function($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function(o) {
                                return o.selected
                              })
                              .map(function(o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.form,
                              "checkout_time",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          }
                        }
                      },
                      [
                        _c("option", { attrs: { value: "" } }, [
                          _vm._v("-- Choose time --")
                        ]),
                        _vm._v(" "),
                        _vm._l(_vm.times, function(time) {
                          return _c(
                            "option",
                            { domProps: { value: time.value } },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(time.label) +
                                  "\n            "
                              )
                            ]
                          )
                        })
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _vm.errors.checkout_time
                      ? _c("div", { staticClass: "text-danger" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.errors.checkout_time[0]) +
                              "\n          "
                          )
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.extend_reservation_when_check_out,
                            expression: "form.extend_reservation_when_check_out"
                          }
                        ],
                        attrs: { type: "checkbox" },
                        domProps: {
                          checked: Array.isArray(
                            _vm.form.extend_reservation_when_check_out
                          )
                            ? _vm._i(
                                _vm.form.extend_reservation_when_check_out,
                                null
                              ) > -1
                            : _vm.form.extend_reservation_when_check_out
                        },
                        on: {
                          change: function($event) {
                            var $$a =
                                _vm.form.extend_reservation_when_check_out,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(
                                    _vm.form,
                                    "extend_reservation_when_check_out",
                                    $$a.concat([$$v])
                                  )
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    _vm.form,
                                    "extend_reservation_when_check_out",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(
                                _vm.form,
                                "extend_reservation_when_check_out",
                                $$c
                              )
                            }
                          }
                        }
                      }),
                      _vm._v(
                        "\n            Extend Reservations when checked out late\n          "
                      )
                    ]),
                    _vm._v(" "),
                    _vm.errors.extend_reservation_when_check_out
                      ? _c("div", { staticClass: "text-danger" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.errors.extend_reservation_when_check_out[0]
                              ) +
                              "\n          "
                          )
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: "tripadvisorID" } }, [
                      _vm._v("Tripadvisor ID")
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.form.tripadvisor_id,
                          expression: "form.tripadvisor_id",
                          modifiers: { trim: true }
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        id: "tripadvisorID",
                        placeholder: "Location Tripadvisor ID"
                      },
                      domProps: { value: _vm.form.tripadvisor_id },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.form,
                            "tripadvisor_id",
                            $event.target.value.trim()
                          )
                        },
                        blur: function($event) {
                          return _vm.$forceUpdate()
                        }
                      }
                    }),
                    _vm._v(" "),
                    _vm.errors.tripadvisor_id
                      ? _c("div", { staticClass: "text-danger" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.errors.tripadvisor_id[0]) +
                              "\n            "
                          )
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "hasPermits",
                          rawName: "v-hasPermits:allow",
                          value: {
                            id: _vm.locId,
                            permits: ["location_netpark_key_manage"]
                          },
                          expression:
                            "{id: locId, permits: ['location_netpark_key_manage']}",
                          arg: "allow"
                        }
                      ],
                      staticClass: "form-group"
                    },
                    [
                      _c("label", { attrs: { for: "tripadvisorID" } }, [
                        _vm._v("Netpark Code")
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _vm.form.netpark_code,
                            expression: "form.netpark_code",
                            modifiers: { trim: true }
                          }
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          id: "netpark_code",
                          placeholder: "Location Netpark Code"
                        },
                        domProps: { value: _vm.form.netpark_code },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.form,
                              "netpark_code",
                              $event.target.value.trim()
                            )
                          },
                          blur: function($event) {
                            return _vm.$forceUpdate()
                          }
                        }
                      }),
                      _vm._v(" "),
                      _vm.errors.netpark_code
                        ? _c("div", { staticClass: "text-danger" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.errors.netpark_code[0]) +
                                "\n            "
                            )
                          ])
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "hasPermits",
                          rawName: "v-hasPermits:allow",
                          value: ["super_admin"],
                          expression: "['super_admin']",
                          arg: "allow"
                        }
                      ],
                      staticClass: "form-group"
                    },
                    [
                      _c("label", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.use_netpark_price,
                              expression: "form.use_netpark_price"
                            }
                          ],
                          attrs: { type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(_vm.form.use_netpark_price)
                              ? _vm._i(_vm.form.use_netpark_price, null) > -1
                              : _vm.form.use_netpark_price
                          },
                          on: {
                            change: function($event) {
                              var $$a = _vm.form.use_netpark_price,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.form,
                                      "use_netpark_price",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.form,
                                      "use_netpark_price",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(_vm.form, "use_netpark_price", $$c)
                              }
                            }
                          }
                        }),
                        _vm._v("\n            Use Netpark price\n          ")
                      ]),
                      _vm._v(" "),
                      _vm.errors.use_netpark_price
                        ? _c("div", { staticClass: "text-danger" }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.errors.use_netpark_price[0]) +
                                "\n          "
                            )
                          ])
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "hasPermits",
                          rawName: "v-hasPermits:allow",
                          value: ["super_admin"],
                          expression: "['super_admin']",
                          arg: "allow"
                        }
                      ],
                      staticClass: "form-group"
                    },
                    [
                      _c("label", { attrs: { for: "integrationSystem" } }, [
                        _vm._v("Integration System")
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _vm.form.integration_system,
                            expression: "form.integration_system",
                            modifiers: { trim: true }
                          }
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          id: "integration_system",
                          placeholder: "Integration System name"
                        },
                        domProps: { value: _vm.form.integration_system },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.form,
                              "integration_system",
                              $event.target.value.trim()
                            )
                          },
                          blur: function($event) {
                            return _vm.$forceUpdate()
                          }
                        }
                      }),
                      _vm._v(" "),
                      _vm.errors.integration_system
                        ? _c("div", { staticClass: "text-danger" }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.errors.integration_system[0]) +
                                "\n          "
                            )
                          ])
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "hasPermits",
                          rawName: "v-hasPermits:allow",
                          value: ["super_admin"],
                          expression: "['super_admin']",
                          arg: "allow"
                        }
                      ],
                      staticClass: "form-group"
                    },
                    [
                      _c("label", [_vm._v("Integration System Attributes")]),
                      _vm._v(" "),
                      _c("integrationSystemAttributes", {
                        attrs: { errors: _vm.errors },
                        on: {
                          "update:errors": function($event) {
                            _vm.errors = $event
                          }
                        },
                        model: {
                          value: _vm.form.integration_system_attributes,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.form,
                              "integration_system_attributes",
                              $$v
                            )
                          },
                          expression: "form.integration_system_attributes"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-6" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: "front_desk_hours" } }, [
                      _vm._v("Front Desk Hours")
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.form.front_desk_hours,
                          expression: "form.front_desk_hours",
                          modifiers: { trim: true }
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        id: "front_desk_hours",
                        placeholder: "hours of operation of front desk"
                      },
                      domProps: { value: _vm.form.front_desk_hours },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.form,
                            "front_desk_hours",
                            $event.target.value.trim()
                          )
                        },
                        blur: function($event) {
                          return _vm.$forceUpdate()
                        }
                      }
                    }),
                    _vm._v(" "),
                    _vm.errors.front_desk_hours
                      ? _c("div", { staticClass: "text-danger" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.errors.front_desk_hours[0]) +
                              "\n          "
                          )
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "hasPermits",
                          rawName: "v-hasPermits:prevent",
                          value: ["location_user", "channel_user"],
                          expression: "['location_user', 'channel_user']",
                          arg: "prevent"
                        }
                      ],
                      staticClass: "form-group"
                    },
                    [
                      _c("label", { attrs: { for: "daily_email_time" } }, [
                        _vm._v("Daily Email Time")
                      ]),
                      _vm._v(" "),
                      _c("timepicker", {
                        staticClass: "form-control",
                        model: {
                          value: _vm.form.daily_email_time,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "daily_email_time", $$v)
                          },
                          expression: "form.daily_email_time"
                        }
                      }),
                      _vm._v(" "),
                      _vm.errors.daily_email_time
                        ? _c("div", { staticClass: "text-danger" }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.errors.daily_email_time[0]) +
                                "\n          "
                            )
                          ])
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("label", [_vm._v("Description")]),
                      _vm._v(" "),
                      _c("tipTap", {
                        attrs: { removeFormatOnPaste: true },
                        model: {
                          value: _vm.form.description,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "description", $$v)
                          },
                          expression: "form.description"
                        }
                      }),
                      _vm._v(" "),
                      _vm.errors.description
                        ? _c("div", { staticClass: "text-danger" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.errors.description[0]) +
                                "\n            "
                            )
                          ])
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("label", [_vm._v("Special Conditions")]),
                      _vm._v(" "),
                      _c("tipTap", {
                        attrs: { removeFormatOnPaste: true },
                        model: {
                          value: _vm.form.special_conditions,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "special_conditions", $$v)
                          },
                          expression: "form.special_conditions"
                        }
                      }),
                      _vm._v(" "),
                      _vm.errors.special_conditions
                        ? _c("div", { staticClass: "text-danger" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.errors.special_conditions[0]) +
                                "\n            "
                            )
                          ])
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.user && _vm.user.type == "ims"
                    ? _c("div", { staticClass: "form-group" }, [
                        _c("label", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form.shuttle_available,
                                expression: "form.shuttle_available"
                              }
                            ],
                            attrs: { type: "checkbox" },
                            domProps: {
                              checked: Array.isArray(_vm.form.shuttle_available)
                                ? _vm._i(_vm.form.shuttle_available, null) > -1
                                : _vm.form.shuttle_available
                            },
                            on: {
                              change: function($event) {
                                var $$a = _vm.form.shuttle_available,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.form,
                                        "shuttle_available",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.form,
                                        "shuttle_available",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(_vm.form, "shuttle_available", $$c)
                                }
                              }
                            }
                          }),
                          _vm._v(
                            "\n              Shuttle Available\n            "
                          )
                        ]),
                        _vm._v(" "),
                        _vm.errors.shuttle_available
                          ? _c("div", { staticClass: "text-danger" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.errors.shuttle_available[0]) +
                                  "\n            "
                              )
                            ])
                          : _vm._e()
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("label", [_vm._v("Shuttle Info Summary")]),
                      _vm._v(" "),
                      _vm.hasShuttleSchedulePermits() &&
                      _vm.form.shuttle_schedule &&
                        !_vm.form.shuttle_schedule.length
                        ? _c("tipTap", {
                            attrs: { removeFormatOnPaste: true },
                            model: {
                              value: _vm.form.shuttle_info_summary,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "shuttle_info_summary", $$v)
                              },
                              expression: "form.shuttle_info_summary"
                            }
                          })
                        : _c("div", {
                            staticClass: "editor-border",
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.form.shuttle_info_summary_readable_text ||
                                  _vm.form.shuttle_info_summary
                              )
                            }
                          }),
                      _vm._v(" "),
                      _vm.errors.shuttle_info_summary
                        ? _c("div", { staticClass: "text-danger" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.errors.shuttle_info_summary[0]) +
                                "\n            "
                            )
                          ])
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("label", [_vm._v("Shuttle Info Details")]),
                      _vm._v(" "),
                      _vm.hasShuttleSchedulePermits()
                        ? _c("tipTap", {
                            attrs: { removeFormatOnPaste: true },
                            model: {
                              value: _vm.form.shuttle_info_details,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "shuttle_info_details", $$v)
                              },
                              expression: "form.shuttle_info_details"
                            }
                          })
                        : _c("div", {
                            staticClass: "editor-border",
                            domProps: {
                              innerHTML: _vm._s(_vm.form.shuttle_info_details)
                            }
                          }),
                      _vm._v(" "),
                      _vm.errors.shuttle_info_details
                        ? _c("div", { staticClass: "text-danger" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.errors.shuttle_info_details[0]) +
                                "\n            "
                            )
                          ])
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value:
                              _vm.form
                                .exclude_bookings_outside_of_shuttle_hours,
                            expression:
                              "form.exclude_bookings_outside_of_shuttle_hours"
                          }
                        ],
                        attrs: {
                          type: "checkbox",
                          disabled:
                            !_vm.form.shuttle_schedule ||
                            (_vm.form.shuttle_schedule &&
                              _vm.form.shuttle_schedule.length == 0) ||
                            !_vm.hasShuttleSchedulePermits()
                        },
                        domProps: {
                          checked: Array.isArray(
                            _vm.form.exclude_bookings_outside_of_shuttle_hours
                          )
                            ? _vm._i(
                                _vm.form
                                  .exclude_bookings_outside_of_shuttle_hours,
                                null
                              ) > -1
                            : _vm.form.exclude_bookings_outside_of_shuttle_hours
                        },
                        on: {
                          change: function($event) {
                            var $$a =
                                _vm.form
                                  .exclude_bookings_outside_of_shuttle_hours,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(
                                    _vm.form,
                                    "exclude_bookings_outside_of_shuttle_hours",
                                    $$a.concat([$$v])
                                  )
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    _vm.form,
                                    "exclude_bookings_outside_of_shuttle_hours",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(
                                _vm.form,
                                "exclude_bookings_outside_of_shuttle_hours",
                                $$c
                              )
                            }
                          }
                        }
                      }),
                      _vm._v(
                        "\n              Exclude bookings outside of shuttle hours\n            "
                      )
                    ]),
                    _vm._v(" "),
                    _vm.errors.exclude_bookings_outside_of_shuttle_hours
                      ? _c("div", { staticClass: "text-danger" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.errors
                                  .exclude_bookings_outside_of_shuttle_hours[0]
                              ) +
                              "\n            "
                          )
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("label", [_vm._v("Shuttle Schedule")]),
                      _vm._v(" "),
                      _c("shuttleSchedule", {
                        attrs: { errors: _vm.errors },
                        on: {
                          "update:errors": function($event) {
                            _vm.errors = $event
                          }
                        },
                        model: {
                          value: _vm.form.shuttle_schedule,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "shuttle_schedule", $$v)
                          },
                          expression: "form.shuttle_schedule"
                        }
                      }),
                      _vm._v(" "),
                      _vm.errors.shuttle_schedule
                        ? _c("div", { staticClass: "text-danger" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.errors.shuttle_schedule[0]) +
                                "\n            "
                            )
                          ])
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("label", { attrs: { for: "locationLatitude" } }, [
                        _vm._v("Geo Coordinates")
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("placesAutocomplete", {
                            staticClass: "form-control margin-bottom-3x",
                            attrs: {
                              type: "text",
                              id: "locationPlace",
                              placeholder: "Search for your city or place"
                            },
                            model: {
                              value: _vm.googlePlace,
                              callback: function($$v) {
                                _vm.googlePlace = $$v
                              },
                              expression: "googlePlace"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("u", [
                        _c("i", [
                          _vm._v(
                            "\n              Search for your place and then click on the map to pin your location\n            "
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("google-maps-movable-marker", {
                        staticClass: "admin-location-map",
                        attrs: {
                          center: _vm.center,
                          "default-marker": {
                            lat: parseFloat(_vm.form.latitude),
                            lng: parseFloat(_vm.form.longitude)
                          }
                        },
                        on: { locationChanged: _vm.onLocationChanged }
                      }),
                      _vm._v(" "),
                      _vm.errors.latitude
                        ? _c("div", { staticClass: "text-danger" }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.errors.latitude[0]) +
                                "\n          "
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.errors.longitude
                        ? _c("div", { staticClass: "text-danger" }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.errors.longitude[0]) +
                                "\n          "
                            )
                          ])
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "hasPermits",
                          rawName: "v-hasPermits:allow",
                          value: {
                            id: _vm.locId,
                            permits: ["location_publish"]
                          },
                          expression:
                            "{id: locId, permits: ['location_publish']}",
                          arg: "allow"
                        }
                      ],
                      staticClass: "form-group"
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "flex flex-middle" },
                        [
                          _c("div", { staticClass: "margin-right-2x" }, [
                            _vm._v(" Published ")
                          ]),
                          _vm._v(" "),
                          _c("switch-btn", {
                            on: {
                              change: function($event) {
                                return _vm.submit()
                              }
                            },
                            model: {
                              value: _vm.published,
                              callback: function($$v) {
                                _vm.published = $$v
                              },
                              expression: "published"
                            }
                          }),
                          _vm._v(" "),
                          _vm.form.published_at
                            ? _c("span", { staticClass: "margin-left-2x" }, [
                                _c("b", [_vm._v("published at")]),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("dateFormat")(
                                        _vm.form.published_at,
                                        "MM/DD/YYYY hh:mm A"
                                      )
                                    )
                                )
                              ])
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  )
                ])
              ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-3bb0b05d", { render: render, staticRenderFns: staticRenderFns })
  }
}