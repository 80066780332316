var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "layout",
    { attrs: { "no-box": true, title: _vm.location ? _vm.location.name : "" } },
    [
      _c("tabs"),
      _vm._v(" "),
      _vm.loading
        ? _c("div", { staticClass: "text-center" }, [
            _c("i", { staticClass: "fa fa-spinner fa-spin fa-3x fa-fw" })
          ])
        : _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-sm-4" },
              [
                _c("facility", {
                  attrs: { location: _vm.location },
                  on: {
                    "update:location": function($event) {
                      _vm.location = $event
                    }
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-sm-4" },
              [
                _c("parking", {
                  attrs: { location: _vm.location },
                  on: {
                    "update:location": function($event) {
                      _vm.location = $event
                    }
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-sm-4" },
              [
                _c("room", {
                  attrs: { location: _vm.location },
                  on: {
                    "update:location": function($event) {
                      _vm.location = $event
                    }
                  }
                })
              ],
              1
            )
          ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-28b65388", { render: render, staticRenderFns: staticRenderFns })
  }
}