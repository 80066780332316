var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("layout", { attrs: { "no-sidebar": true } }, [
    _c("div", { staticClass: "page-header" }, [
      !_vm.loading
        ? _c("h1", [
            _c("small", [_vm._v("Channel: " + _vm._s(_vm.formData.name))])
          ])
        : _vm._e()
    ]),
    _vm._v(" "),
    _vm.loading
      ? _c("div", { staticClass: "text-center" }, [
          _c("i", { staticClass: "fa fa-spinner fa-spin fa-3x fa-fw" })
        ])
      : _c(
          "form",
          {},
          [
            _c("div", { staticClass: "s-wrapper wrapper-overflow" }, [
              _c("div", { staticClass: "pull-left" }, [
                _c(
                  "div",
                  { staticClass: "flex flex-middle" },
                  [
                    _c("div", { staticClass: "margin-right-2x" }, [
                      _vm._v("\n            Testing Mode\n          ")
                    ]),
                    _vm._v(" "),
                    _c("switch-btn", {
                      model: {
                        value: _vm.testing_mode,
                        callback: function($$v) {
                          _vm.testing_mode = $$v
                        },
                        expression: "testing_mode"
                      }
                    })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "hasPermits",
                      rawName: "v-hasPermits:allow",
                      value: ["channel_update", "channel_user"],
                      expression: "['channel_update', 'channel_user']",
                      arg: "allow"
                    }
                  ],
                  staticClass: "pull-right margin-bottom-3x"
                },
                [
                  _vm.submit_loading
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-success",
                          attrs: {
                            disabled: _vm.submit_loading,
                            type: "button"
                          }
                        },
                        [
                          _c("i", {
                            staticClass: "fa fa-spinner fa-spin fa-fw"
                          }),
                          _vm._v("\n          SAVING...\n        ")
                        ]
                      )
                    : _c(
                        "button",
                        {
                          staticClass: "btn btn-success",
                          attrs: { type: "button" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.onSubmitForm.apply(null, arguments)
                            }
                          }
                        },
                        [_vm._v("\n          SAVE\n        ")]
                      )
                ]
              )
            ]),
            _vm._v(" "),
            _c(
              "tabs",
              {
                attrs: { justified: true },
                on: {
                  tabChange: function(tab) {
                    _vm.activeTab = tab
                  }
                }
              },
              [
                _c(
                  "tab",
                  { attrs: { title: "Locations" } },
                  [
                    _c("locations", {
                      attrs: {
                        channelId: _vm.formData.id,
                        activeTab: _vm.activeTab
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "tab",
                  { attrs: { title: "Reservations" } },
                  [
                    _c("reservations", {
                      attrs: {
                        activeTab: _vm.activeTab,
                        testingMode: _vm.testing_mode
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "tab",
                  { attrs: { title: "Developers" } },
                  [_c("general", { attrs: { "form-data": _vm.formData } })],
                  1
                ),
                _vm._v(" "),
                _c(
                  "tab",
                  {
                    attrs: {
                      permits: ["channelFee_list", "channel_user"],
                      title: "Fees"
                    }
                  },
                  [
                    _c("fees", {
                      attrs: {
                        activeTab: _vm.activeTab,
                        "form-data": _vm.formData
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "tab",
                  { attrs: { title: "Employees" } },
                  [_c("employees", { attrs: { "form-data": _vm.formData } })],
                  1
                ),
                _vm._v(" "),
                _vm.formData.show_webhook || _vm.isAdmin
                  ? _c(
                      "tab",
                      { attrs: { title: "Webhook" } },
                      [_c("webhook", { attrs: { "form-data": _vm.formData } })],
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          ],
          1
        )
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-001d56d6", { render: render, staticRenderFns: staticRenderFns })
  }
}