var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("layout", { attrs: { "no-sidebar": true } }, [
    _c("div", { staticClass: "page-header" }, [
      !_vm.loading ? _c("h1", [_c("small", [_vm._v("New Channel")])]) : _vm._e()
    ]),
    _vm._v(" "),
    _vm.loading
      ? _c("div", { staticClass: "text-center" }, [
          _c("i", { staticClass: "fa fa-spinner fa-spin fa-3x fa-fw" })
        ])
      : _c("form", {}, [
          _c("div", { staticClass: "text-right" }, [
            _vm.submit_loading
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-success",
                    attrs: { disabled: _vm.submit_loading, type: "button" }
                  },
                  [
                    _c("i", { staticClass: "fa fa-spinner fa-spin fa-fw" }),
                    _vm._v("\n          SAVING...\n      ")
                  ]
                )
              : _c(
                  "button",
                  {
                    staticClass: "btn btn-success",
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.onSubmitFom.apply(null, arguments)
                      }
                    }
                  },
                  [_vm._v("\n        SAVE\n      ")]
                )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row margin-top-4x" }, [
            _c("div", { staticClass: "col-sm-12 col-md-8 col-md-offset-2" }, [
              _c("div", { staticClass: "form-group" }, [
                _c("label", { attrs: { for: "channelName" } }, [
                  _vm._v("Name")
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.formData.name,
                      expression: "formData.name",
                      modifiers: { trim: true }
                    }
                  ],
                  staticClass: "form-control",
                  attrs: {
                    type: "text",
                    id: "channelName",
                    placeholder: "Channel Name"
                  },
                  domProps: { value: _vm.formData.name },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.formData, "name", $event.target.value.trim())
                    },
                    blur: function($event) {
                      return _vm.$forceUpdate()
                    }
                  }
                }),
                _vm._v(" "),
                _vm.errors.name
                  ? _c("div", { staticClass: "text-danger" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.errors.name[0]) +
                          "\n          "
                      )
                    ])
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group" }, [
                _c("label", { attrs: { for: "channelDomain" } }, [
                  _vm._v("Domain")
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.formData.domain,
                      expression: "formData.domain",
                      modifiers: { trim: true }
                    }
                  ],
                  staticClass: "form-control",
                  attrs: {
                    type: "text",
                    id: "channelDomain",
                    placeholder: "Channel Domain"
                  },
                  domProps: { value: _vm.formData.domain },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.formData,
                        "domain",
                        $event.target.value.trim()
                      )
                    },
                    blur: function($event) {
                      return _vm.$forceUpdate()
                    }
                  }
                }),
                _vm._v(" "),
                _vm.errors.domain
                  ? _c("div", { staticClass: "text-danger" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.errors.domain[0]) +
                          "\n          "
                      )
                    ])
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "hasPermits",
                      rawName: "v-hasPermits:allow",
                      value: ["super_admin"],
                      expression: "['super_admin']",
                      arg: "allow"
                    }
                  ],
                  staticClass: "form-group"
                },
                [
                  _c("label", { attrs: { for: "channelPrefix" } }, [
                    _vm._v("Prefix")
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: _vm.formData.prefix,
                        expression: "formData.prefix",
                        modifiers: { trim: true }
                      }
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "text",
                      id: "channelPrefix",
                      placeholder: "Channel Prefix like OSP"
                    },
                    domProps: { value: _vm.formData.prefix },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.formData,
                          "prefix",
                          $event.target.value.trim()
                        )
                      },
                      blur: function($event) {
                        return _vm.$forceUpdate()
                      }
                    }
                  }),
                  _vm._v(" "),
                  _vm.errors.prefix
                    ? _c("div", { staticClass: "text-danger" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.errors.prefix[0]) +
                            "\n          "
                        )
                      ])
                    : _vm._e()
                ]
              )
            ])
          ])
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-64224e36", { render: render, staticRenderFns: staticRenderFns })
  }
}