import { http } from 'services'
// initial state
const state = () => ({
  parking_amenities: [],
  room_amenities: [],
  facility_amenities: [],
  errors: null
})

// getters
const getters = {
}

// actions
const actions = {
  ['amenities.getParkingAmenities'] ({ commit, state }) {
    commit('CLEAR_ERRORS')

    if (state.parking_amenities.length) {
      return commit('GET_PARKING_AMENITIES');
    }

    return (http.get('amenities?type=parking&_all=true')).then ((res) => {
      commit('GET_PARKING_AMENITIES', res);
    })
    .catch ((res) => {
      commit('AMENITIES_ERROR', res);
    })
  },

  ['amenities.getRoomAmenities'] ({ commit, state }, country_id) {
    commit('CLEAR_ERRORS')

    return (http.get('amenities?type=room&_all=true'))
    .then ((res) => {
      commit('GET_ROOM_AMENITIES', res);
    })
    .catch ((res) => {
      commit('AMENITIES_ERROR', res);
    })
  },

  ['amenities.getFacilityAmenities'] ({ commit, state }, country_id) {
    commit('CLEAR_ERRORS')

    return (http.get('amenities?type=facility&_all=true'))
    .then ((res) => {
      commit('GET_FACILITY_AMENITIES', res);
    })
    .catch ((res) => {
      commit('AMENITIES_ERROR', res);
    })
  },
}

// mutations
const mutations = {
  GET_PARKING_AMENITIES (state, res) {
    if (res)
      state.parking_amenities = res.data
  },

  GET_ROOM_AMENITIES (state, res) {
    if (res)
      state.room_amenities = res.data

    console.log('state.room_amenities', state.room_amenities);
  },

  GET_FACILITY_AMENITIES (state, res) {
    if (res)
      state.facility_amenities = res.data
  },

  //ERRORS
  AMENITIES_ERROR (state, err) {
    try {
      state.errors = err.response.data;
    } catch (e) {
      state.errors = err;
    }
  },

  CLEAR_ERRORS (state) {
    state.errors = null
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
