var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c("div", { staticClass: "table-responsive" }, [
        _c("table", { staticClass: "table" }, [
          _c("thead", [
            _c("tr", [
              _c("th", [_vm._v(" Number Of Days ")]),
              _vm._v(" "),
              _c("th", [_vm._v(" Refund Percentage ")]),
              _vm._v(" "),
              _c("th", [_vm._v(" Type ")]),
              _vm._v(" "),
              _c(
                "th",
                {
                  directives: [
                    {
                      name: "hasPermits",
                      rawName: "v-hasPermits:allow",
                      value: {
                        id: _vm.locId,
                        permits: ["cancellationPolicy_delete"]
                      },
                      expression:
                        "{id: locId, permits: ['cancellationPolicy_delete']}",
                      arg: "allow"
                    }
                  ]
                },
                [_vm._v(" Delete ")]
              )
            ])
          ]),
          _vm._v(" "),
          _c(
            "tbody",
            _vm._l(_vm.cancellationPolicies, function(policy) {
              return _c("tr", [
                _c("td", [
                  _vm._v(
                    "\n            " +
                      _vm._s(policy.number_of_days) +
                      "\n          "
                  )
                ]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(
                    "\n            " +
                      _vm._s(policy.percentage) +
                      "\n          "
                  )
                ]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm._f("capitalize")(policy.type)) +
                      "\n          "
                  )
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  {
                    directives: [
                      {
                        name: "hasPermits",
                        rawName: "v-hasPermits:allow",
                        value: {
                          id: _vm.locId,
                          permits: ["cancellationPolicy_delete"]
                        },
                        expression:
                          "{id: locId, permits: ['cancellationPolicy_delete']}",
                        arg: "allow"
                      }
                    ]
                  },
                  [
                    _vm.loadings[policy.id]
                      ? _c("div", [
                          _c("i", { staticClass: "fa fa-spinner fa-spin" })
                        ])
                      : _c(
                          "a",
                          {
                            staticClass: "text-danger",
                            attrs: { href: "#" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.removePolicy(policy.id)
                              }
                            }
                          },
                          [_c("i", { staticClass: "fa fa-trash" })]
                        )
                  ]
                )
              ])
            }),
            0
          )
        ])
      ]),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "hasPermits",
              rawName: "v-hasPermits:allow",
              value: { id: _vm.locId, permits: ["cancellationPolicy_create"] },
              expression: "{id: locId, permits: ['cancellationPolicy_create']}",
              arg: "allow"
            }
          ]
        },
        [
          _c("div", { staticClass: "text-right" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-default",
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    _vm.formActive = !_vm.formActive
                  }
                }
              },
              [
                !_vm.formActive
                  ? _c("i", {
                      staticClass: "fa fa-caret-down",
                      attrs: { "aria-hidden": "true" }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.formActive
                  ? _c("i", {
                      staticClass: "fa fa-caret-up",
                      attrs: { "aria-hidden": "true" }
                    })
                  : _vm._e(),
                _vm._v("\n        New Policies\n      ")
              ]
            )
          ]),
          _vm._v(" "),
          _vm.formActive
            ? _c(
                "div",
                { staticClass: "margin-top-3x" },
                [
                  _vm.errors
                    ? _c("div", { staticClass: "text-danger" }, [
                        _vm._v(
                          "\n        " + _vm._s(_vm.errors[0]) + "\n      "
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm._l(_vm.policies_to_submit, function(policy, i) {
                    return _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-sm-4" }, [
                        _c("label", [_vm._v("Number Of Days")]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: policy.number_of_days,
                              expression: "policy.number_of_days"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: { type: "text" },
                          domProps: { value: policy.number_of_days },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                policy,
                                "number_of_days",
                                $event.target.value
                              )
                            }
                          }
                        }),
                        _vm._v(" "),
                        _vm.errors["number_of_days." + i]
                          ? _c("div", { staticClass: "text-danger" }, [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.errors["number_of_days." + i][0]) +
                                  "\n          "
                              )
                            ])
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-sm-4" }, [
                        _c("label", [_vm._v("Percentage")]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: policy.percentage,
                              expression: "policy.percentage"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: { type: "text" },
                          domProps: { value: policy.percentage },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                policy,
                                "percentage",
                                $event.target.value
                              )
                            }
                          }
                        }),
                        _vm._v(" "),
                        _vm.errors["percentage." + i]
                          ? _c("div", { staticClass: "text-danger" }, [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.errors["percentage." + i][0]) +
                                  "\n          "
                              )
                            ])
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-sm-4" }, [
                        _c("label", [_vm._v("Type")]),
                        _vm._v(" "),
                        i > 0
                          ? _c("div", { staticClass: "pull-right" }, [
                              _c(
                                "a",
                                {
                                  staticClass: "text-danger",
                                  attrs: { href: "#" },
                                  on: {
                                    click: function($event) {
                                      return _vm.removeFields(i, $event)
                                    }
                                  }
                                },
                                [
                                  _c("i", {
                                    staticClass: "fa fa-times",
                                    attrs: { "aria-hidden": "true" }
                                  })
                                ]
                              )
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: policy.type,
                                expression: "policy.type"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: { type: "text" },
                            on: {
                              change: function($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function(o) {
                                    return o.selected
                                  })
                                  .map(function(o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  policy,
                                  "type",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              }
                            }
                          },
                          [
                            _c("option", { attrs: { value: "room" } }, [
                              _vm._v("Room")
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "parking" } }, [
                              _vm._v("Parking")
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _vm.errors["type." + i]
                          ? _c("div", { staticClass: "text-danger" }, [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.errors["type." + i][0]) +
                                  "\n          "
                              )
                            ])
                          : _vm._e()
                      ])
                    ])
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "text-right margin-top-3x" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-default",
                        on: { click: _vm.addMoreFields }
                      },
                      [_vm._v("\n          +\n        ")]
                    )
                  ]),
                  _vm._v(" "),
                  _c("hr"),
                  _vm._v(" "),
                  _c("div", { staticClass: "text-right" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-success",
                        class: { disabled: _vm.processing },
                        attrs: { disabled: _vm.processing },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.submit()
                          }
                        }
                      },
                      [
                        _vm.processing
                          ? _c("span", [
                              _c("i", { staticClass: "fa fa-spinner fa-spin" })
                            ])
                          : _vm._e(),
                        _vm._v("\n        SAVE POLICIES\n        ")
                      ]
                    )
                  ])
                ],
                2
              )
            : _vm._e()
        ]
      ),
      _vm._v(" "),
      _c("confirm", { ref: "confirmDelete", attrs: { type: "danger" } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-7ce8e10b", { render: render, staticRenderFns: staticRenderFns })
  }
}