import { http } from 'services'
// initial state
const state = () => ({
  all: [],
  active: {},
  errors: null
})

// getters
const getters = {
}

// actions
const actions = {
  ['locationEmail.getAll'] ({ commit },params) {
    
    if (!params._q) params._q = null

    commit('CLEAR_ERRORS')

    return (http.get(`locations/${params.location_id}/location-emails?_all=true`)).then ((res) => {
      commit('GET_ALL_LOCATION_EMAILS', res);
    })
    .catch ((res) => {
      commit('LOCATION_EMAILS_ERROR', res);
    })
  },
  ['locationEmail.new'] ({ commit , dispatch}, data) {
    commit('CLEAR_ERRORS')

    return (http.post(`locations/${data.location_id}/location-emails`, data))
    .then ((res) => {
      dispatch('locationEmail.getAll',{location_id: data.location_id});
    })
    .catch ((res) => {
      commit('LOCATION_EMAILS_ERROR', res);
    })
  },
  ['locationEmail.edit'] ({ commit }, {id, data}) {
    commit('CLEAR_ERRORS')

    return (http.put(`locations/${data.location_id}/location-emails/${id}`, data))
    .then ((res) => {
      commit('EDIT_LOCATION_EMAIL', res);
    })
    .catch ((res) => {
      commit('LOCATION_EMAILS_ERROR', res);
    })
  },
  ['locationEmail.remove'] ({ commit }, data) {
    commit('CLEAR_ERRORS')

    return (http.delete(`locations/${data.location_id}/location-emails/${data.id}`))
    .then ((res) => {
      commit('REMOVE_LOCATION_EMAIL', data.id);
    })
    .catch ((res) => {
      commit('LOCATION_EMAILS_ERROR', res);
    })
  },

}

// mutations
const mutations = {
  GET_ALL_LOCATION_EMAILS (state, res) {
    state.all = res.data;
  },

  GET_LOCATION_EMAIL (state, res) {
    state.active = res.data
  },

  REMOVE_LOCATION_EMAIL (state, id) {
    state.all = _.filter(state.all, (item) => item.id != id);
  },

  EDIT_LOCATION_EMAIL (state, res) {
    state.active = res.data
  },

  //ERRORS
  LOCATION_EMAILS_ERROR (state, err) {
    try {
      state.errors = err.response.data;
    } catch (e) {
      state.errors = err;
    }
  },

  CLEAR_ERRORS (state) {
    state.errors = null
  }
}



export default {
  state,
  getters,
  actions,
  mutations
}
