import toastr from 'toastr'
import { funcModal } from 'components/modals'
import sSelect from 'components/sSelect'
import typeForm from './form'

const components = {
  sSelect,
  funcModal,
  typeForm
}

const methods = {
  submit (modal) {
    this.errors = {};


    let data = _.cloneDeep(this.form)
    data.location_id = this.$route.params.id;

    this.$store.dispatch('locations.updateBundleType', {
      id: this.form.id, data
    }).then(() => {
      toastr.error(this.$store.state.locations.errors.message);
      let errors = this.$store.state.locations.errors;
      if (errors) {
        if (errors.errors) {
          this.errors = _.cloneDeep(errors.errors);
        } 
        toastr.error(error.message);
      } else {
        modal.close();
      }
    });

  },

  open () {
    this.$refs.modal.open();
  }
}

const watch = {
  bundle (n) {
    this.form = n
  }
}

export default {
  name: 'edit-rooms-type',
  props: ['bundle'],
  methods,
  watch,
  components,
  data () {
    return {
      errors: {},
      form: this.bundle
    }
  },
}
