import {all as allVendors, new as newVendor, edit as editVendor} from 'pages/vendors'

export default [
  //Vendors
  { path: '/vendors', name: 'vendors.all', component: allVendors
    , meta:{permits:['vendor_list']}},
  { path: '/vendors/new', name: 'vendors.new', component: newVendor
    , meta:{permits:['vendor_create']}},
  { path: '/vendors/edit/:id', name: 'vendors.edit', component: editVendor
    , meta:{permits:['vendor_list']}},
]
