var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "layout",
    { attrs: { "no-box": true, title: _vm.location ? _vm.location.name : "" } },
    [
      _c("tabs"),
      _vm._v(" "),
      _vm.loading
        ? _c("div", { staticClass: "text-center" }, [
            _c("i", { staticClass: "fa fa-spinner fa-spin fa-3x fa-fw" })
          ])
        : _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { ref: "adjustmentsDiv", attrs: { id: "adjustmentsDiv" } },
              [
                _c("div", { staticClass: "flex flex-row" }, [
                  _c(
                    "button",
                    {
                      directives: [
                        {
                          name: "hasPermits",
                          rawName: "v-hasPermits:prevent",
                          value: ["location_user"],
                          expression: "['location_user']",
                          arg: "prevent"
                        }
                      ],
                      staticClass: "btn margin-left-2x",
                      class: [
                        _vm.showNewManualAdjustment
                          ? "btn-primary"
                          : "btn-turkwaz"
                      ],
                      attrs: { type: "button", disabled: _vm.disableNew },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.showManualAdjustment()
                        }
                      }
                    },
                    [_vm._v("\n            New Manual Adjustment\n          ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      directives: [
                        {
                          name: "hasPermits",
                          rawName: "v-hasPermits:allow",
                          value: ["dynamicLocationRate_adjustment_create"],
                          expression:
                            "['dynamicLocationRate_adjustment_create']",
                          arg: "allow"
                        }
                      ],
                      staticClass: "btn margin-left-2x",
                      class: [
                        _vm.showNewDynamicAdjustment
                          ? "btn-primary"
                          : "btn-turkwaz"
                      ],
                      attrs: { type: "button", disabled: _vm.disableNew },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.showDynamicAdjustment()
                        }
                      }
                    },
                    [_vm._v("\n            New Dynamic Adjustment\n          ")]
                  )
                ]),
                _vm._v(" "),
                _vm.showNewManualAdjustment
                  ? _c("div", { staticClass: "box margin-top-3x" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "hasPermits",
                              rawName: "v-hasPermits:prevent",
                              value: ["location_user"],
                              expression: "['location_user']",
                              arg: "prevent"
                            }
                          ],
                          staticClass: "box-header with-border"
                        },
                        [_vm._v("new Manual Adjustment")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "box-body" },
                        [
                          _c("manual-adjustment", {
                            attrs: {
                              parkingTypes: _vm.parkingTypes,
                              roomTypes: _vm.roomTypes,
                              bundleTypes: _vm.bundleTypes
                            }
                          })
                        ],
                        1
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.showNewDynamicAdjustment
                  ? _c("div", { staticClass: "box margin-top-3x" }, [
                      _c("div", { staticClass: "box-header with-border" }, [
                        _vm._v(" " + _vm._s(_vm.dynamicAdjustmentTitle) + " ")
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "box-body" },
                        [
                          _c("dynamic-adjustment", {
                            attrs: {
                              dynamicAdjustment: _vm.activeDynamicAdjustment,
                              parkingTypes: _vm.parkingTypes,
                              roomTypes: _vm.roomTypes
                            },
                            on: {
                              dynamicAdjustmentAdded: function($event) {
                                return _vm.dynamicAdjustmentAdded()
                              },
                              cancelEdit: function($event) {
                                return _vm.cancelEdit()
                              }
                            }
                          })
                        ],
                        1
                      )
                    ])
                  : _vm._e()
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "hasPermits",
                    rawName: "v-hasPermits:allow",
                    value: ["dynamicLocationRate_adjustment_list"],
                    expression: "['dynamicLocationRate_adjustment_list']",
                    arg: "allow"
                  }
                ]
              },
              [
                _c("h3", { staticClass: "margin-bottom-2x margin-top-6x" }, [
                  _vm._v("Adjustment Activity")
                ]),
                _vm._v(" "),
                _c("div", [
                  _c("div", { staticClass: "form-inline margin-bottom-3x" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("datepicker", {
                          staticClass: "form-control",
                          attrs: {
                            "min-date": "none",
                            placeholder: "Filter by From Date"
                          },
                          model: {
                            value: _vm.from_date,
                            callback: function($$v) {
                              _vm.from_date = $$v
                            },
                            expression: "from_date"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("datepicker", {
                          staticClass: "form-control",
                          attrs: {
                            "min-date": _vm.from_date,
                            placeholder: "Filter by To Date"
                          },
                          model: {
                            value: _vm.to_date,
                            callback: function($$v) {
                              _vm.to_date = $$v
                            },
                            expression: "to_date"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group" }, [
                      _c(
                        "a",
                        {
                          staticClass: "btn btn-primary btn-md",
                          attrs: { href: "#" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.getAdjustment(1)
                            }
                          }
                        },
                        [_vm._v("\n              Search\n            ")]
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _c("div", { staticClass: "table-responsive" }, [
                        _c("table", { staticClass: "table bg-white" }, [
                          _c("thead", [
                            _c("tr", [
                              _c("th", [_vm._v("Type")]),
                              _vm._v(" "),
                              _c("th", [_vm._v("Type name")]),
                              _vm._v(" "),
                              _c("th", [_vm._v("From Date")]),
                              _vm._v(" "),
                              _c("th", [_vm._v("To Date")]),
                              _vm._v(" "),
                              _c("th", [_vm._v("Number of period days")]),
                              _vm._v(" "),
                              _c("th", [_vm._v("Adjustments")]),
                              _vm._v(" "),
                              _c("th", [_vm._v("Excluded periods")]),
                              _vm._v(" "),
                              _c("th", [_vm._v("Exclude blackout")]),
                              _vm._v(" "),
                              _c("th", [_vm._v("Enable")]),
                              _vm._v(" "),
                              _c(
                                "th",
                                {
                                  directives: [
                                    {
                                      name: "hasPermits",
                                      rawName: "v-hasPermits:allow",
                                      value: [
                                        "dynamicLocationRate_adjustment_update"
                                      ],
                                      expression:
                                        "['dynamicLocationRate_adjustment_update']",
                                      arg: "allow"
                                    }
                                  ]
                                },
                                [_vm._v("Edit")]
                              ),
                              _vm._v(" "),
                              _c(
                                "th",
                                {
                                  directives: [
                                    {
                                      name: "hasPermits",
                                      rawName: "v-hasPermits:allow",
                                      value: [
                                        "dynamicLocationRate_adjustment_delete"
                                      ],
                                      expression:
                                        "['dynamicLocationRate_adjustment_delete']",
                                      arg: "allow"
                                    }
                                  ]
                                },
                                [_vm._v("Remove")]
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c(
                            "tbody",
                            _vm._l(_vm.dynamicAdjustments.data, function(
                              dynamicAdjustment,
                              indx
                            ) {
                              return _c("tr", { key: dynamicAdjustment.id }, [
                                _c("td", [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(
                                        dynamicAdjustment.type
                                          ? "Parking"
                                          : "Room"
                                      ) +
                                      "\n                  "
                                  )
                                ]),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(
                                        "" +
                                          (dynamicAdjustment.type_info
                                            ? dynamicAdjustment.type_info.name
                                            : "")
                                      ) +
                                      "\n                  "
                                  )
                                ]),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(
                                        _vm._f("dateFormat")(
                                          dynamicAdjustment.from_date,
                                          "MM/DD/YYYY"
                                        )
                                      ) +
                                      "\n                  "
                                  )
                                ]),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(
                                        _vm._f("dateFormat")(
                                          dynamicAdjustment.to_date,
                                          "MM/DD/YYYY"
                                        )
                                      ) +
                                      "\n                  "
                                  )
                                ]),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(
                                        dynamicAdjustment.number_of_period_days
                                      ) +
                                      "\n                  "
                                  )
                                ]),
                                _vm._v(" "),
                                _c("td", [
                                  _c(
                                    "table",
                                    { staticClass: "table table-hover" },
                                    [
                                      _c("thead", [
                                        _c("tr", [
                                          _c("th", [_vm._v("from")]),
                                          _vm._v(" "),
                                          _c("th", [_vm._v("to")]),
                                          _vm._v(" "),
                                          _c("th", [_vm._v("+amount")])
                                        ])
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "tbody",
                                        _vm._l(
                                          dynamicAdjustment.adjustments,
                                          function(adj, i) {
                                            return _c("tr", { key: i }, [
                                              _c("td", [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: adj.from,
                                                      expression: "adj.from"
                                                    }
                                                  ],
                                                  staticClass: "form-control",
                                                  attrs: {
                                                    type: "text",
                                                    disabled: ""
                                                  },
                                                  domProps: { value: adj.from },
                                                  on: {
                                                    input: function($event) {
                                                      if (
                                                        $event.target.composing
                                                      ) {
                                                        return
                                                      }
                                                      _vm.$set(
                                                        adj,
                                                        "from",
                                                        $event.target.value
                                                      )
                                                    }
                                                  }
                                                })
                                              ]),
                                              _vm._v(" "),
                                              _c("td", [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: adj.to,
                                                      expression: "adj.to"
                                                    }
                                                  ],
                                                  staticClass: "form-control",
                                                  attrs: {
                                                    type: "text",
                                                    disabled: ""
                                                  },
                                                  domProps: { value: adj.to },
                                                  on: {
                                                    input: function($event) {
                                                      if (
                                                        $event.target.composing
                                                      ) {
                                                        return
                                                      }
                                                      _vm.$set(
                                                        adj,
                                                        "to",
                                                        $event.target.value
                                                      )
                                                    }
                                                  }
                                                })
                                              ]),
                                              _vm._v(" "),
                                              _c("td", [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: adj.amount,
                                                      expression: "adj.amount"
                                                    }
                                                  ],
                                                  staticClass: "form-control",
                                                  attrs: {
                                                    type: "text",
                                                    disabled: ""
                                                  },
                                                  domProps: {
                                                    value: adj.amount
                                                  },
                                                  on: {
                                                    input: function($event) {
                                                      if (
                                                        $event.target.composing
                                                      ) {
                                                        return
                                                      }
                                                      _vm.$set(
                                                        adj,
                                                        "amount",
                                                        $event.target.value
                                                      )
                                                    }
                                                  }
                                                })
                                              ])
                                            ])
                                          }
                                        ),
                                        0
                                      )
                                    ]
                                  )
                                ]),
                                _vm._v(" "),
                                _c("td", [
                                  dynamicAdjustment.excluded_periods.length > 0
                                    ? _c("div", [
                                        _c(
                                          "table",
                                          { staticClass: "table table-hover" },
                                          [
                                            _c("thead", [
                                              _c("tr", [
                                                _c("th", [_vm._v("from")]),
                                                _vm._v(" "),
                                                _c("th", [_vm._v("to")])
                                              ])
                                            ]),
                                            _vm._v(" "),
                                            _c(
                                              "tbody",
                                              _vm._l(
                                                dynamicAdjustment.excluded_periods,
                                                function(excluded_period, i) {
                                                  return _c("tr", { key: i }, [
                                                    _c("td", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm._f(
                                                              "dateFormat"
                                                            )(
                                                              excluded_period.from,
                                                              "MM/DD/YYYY"
                                                            )
                                                          ) +
                                                          " "
                                                      )
                                                    ]),
                                                    _vm._v(" "),
                                                    _c("td", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm._f(
                                                              "dateFormat"
                                                            )(
                                                              excluded_period.to,
                                                              "MM/DD/YYYY"
                                                            )
                                                          ) +
                                                          " "
                                                      )
                                                    ])
                                                  ])
                                                }
                                              ),
                                              0
                                            )
                                          ]
                                        )
                                      ])
                                    : _c("div", [_vm._v("-")])
                                ]),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(
                                        dynamicAdjustment.exclude_blackout
                                          ? "Yes"
                                          : "No"
                                      ) +
                                      "\n                  "
                                  )
                                ]),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(
                                        dynamicAdjustment.enable ? "Yes" : "No"
                                      ) +
                                      "\n                  "
                                  )
                                ]),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  {
                                    directives: [
                                      {
                                        name: "hasPermits",
                                        rawName: "v-hasPermits:allow",
                                        value: [
                                          "dynamicLocationRate_adjustment_update"
                                        ],
                                        expression:
                                          "['dynamicLocationRate_adjustment_update']",
                                        arg: "allow"
                                      }
                                    ]
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        attrs: { href: "#" },
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                            return _vm.editDynamicAdjustment(
                                              dynamicAdjustment
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "fa fa-pencil",
                                          attrs: { "aria-hidden": "true" }
                                        })
                                      ]
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  {
                                    directives: [
                                      {
                                        name: "hasPermits",
                                        rawName: "v-hasPermits:allow",
                                        value: [
                                          "dynamicLocationRate_adjustment_delete"
                                        ],
                                        expression:
                                          "['dynamicLocationRate_adjustment_delete']",
                                        arg: "allow"
                                      }
                                    ]
                                  },
                                  [
                                    _vm.loadings[dynamicAdjustment.id]
                                      ? _c("div", [
                                          _c("i", {
                                            staticClass: "fa fa-spinner fa-spin"
                                          })
                                        ])
                                      : _c("div", [
                                          _c(
                                            "a",
                                            {
                                              staticClass: "text-danger",
                                              attrs: { href: "#" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.removeDynamicAdjustment(
                                                    indx,
                                                    dynamicAdjustment
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "fa fa-times",
                                                attrs: { "aria-hidden": "true" }
                                              })
                                            ]
                                          )
                                        ])
                                  ]
                                )
                              ])
                            }),
                            0
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("pagination", {
                        attrs: {
                          "pages-number": _vm.dynamicAdjustments.last_page
                        },
                        on: {
                          pageChanged: function(page) {
                            return _vm.getDynamicAdjustments(page)
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("confirm", {
                        ref: "deleteConfirm",
                        attrs: { type: "danger" }
                      })
                    ],
                    1
                  )
                ])
              ]
            )
          ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-0967ce23", { render: render, staticRenderFns: staticRenderFns })
  }
}