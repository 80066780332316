import Vue from 'vue'
import _ from 'lodash'
import { http } from 'services'

// initial state
const state = () => ({
  periods: [],
  errors: null
})

// getters
const getters = {
  
}

// actions
const actions = {
  ['leaseTypes.getLeasePerids'] ({ commit, state }, {location_id, params}) {
    commit('CLEAR_ERRORS')
    return (http.get(`locations/${location_id}/types-lease-periods`,{params})).then((res) => {
      commit('GET_TYPES_LEASE_PERIODS', res);
    }).catch((res) => {
      commit('LEASE_TYPES_ERRORS', res);
    });
  },

  ['leaseTypes.newPeriod'] ({ commit, state }, {location_id, params}) {
    commit('CLEAR_ERRORS')
    return (http.post(`locations/${location_id}/types-lease-periods`,params)).then((res) => {
      commit('NEW_LEASE_PERIOD', res);
    }).catch((res) => {
      commit('LEASE_TYPES_ERRORS', res);
    });
  },

  ['leaseTypes.updatePeriod'] ({ commit, state }, {location_id, params}) {
    commit('CLEAR_ERRORS');
    let period_id = params.period_id;
    return (http.put(`locations/${location_id}/types-lease-periods/${period_id}`,params)).then((res) => {
      commit('UPDATE_LEASE_PERIOD', res);
    }).catch((res) => {
      commit('LEASE_TYPES_ERRORS', res);
    });
  },

  ['leaseTypes.removePeriod'] ({ commit, state }, {location_id, period_id}) {
    commit('CLEAR_ERRORS')
    return (http.delete(`locations/${location_id}/types-lease-periods/${period_id}`)).then((res) => {
      commit('REMOVE_LEASE_PERIOD', res);
    }).catch((res) => {
      commit('LEASE_TYPES_ERRORS', res);
    });
  },

}

// mutations
const mutations = {
  GET_TYPES_LEASE_PERIODS (state, res) {
    state.periods = res.data
  },
  NEW_LEASE_PERIOD (state, res) {
    state.periods.push(res.data);
    state.periods = _.cloneDeep(state.periods);
  },

  UPDATE_LEASE_PERIOD (state, res) {
    let indx = _.findIndex(state.periods, (item) => item.id == res.data.id);
    if (indx > -1) {
      state.periods[indx] = _.assign(state.periods[indx], res.data);
    }
    state.periods[indx] = _.cloneDeep(state.periods[indx]);
    state.periods = _.cloneDeep(state.periods);
  },

  REMOVE_LEASE_PERIOD (state, res) {
    state.periods = state.periods.filter((item) => {
      return item.id !== res.data.id
    });
    state.periods = _.cloneDeep(state.periods);
  },

  //ERRORS
  LEASE_TYPES_ERRORS (state, err) {
    try {
      state.errors = err.response.data;
    } catch (e) {
      state.errors = err;
    }
  },
  CLEAR_ERRORS (state) {
    state.errors = null
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
