var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "func-modal",
    {
      ref: "modal",
      attrs: { title: "Extend checked Out Reservation" },
      on: { close: _vm.onClose },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function(props) {
            return [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  class: [{ disabled: _vm.processing }],
                  attrs: { disabled: _vm.processing },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.submit()
                    }
                  }
                },
                [
                  _vm.processing
                    ? _c("i", { staticClass: "fa fa-spin fa-spinner" })
                    : _vm._e(),
                  _vm._v("\n            Submit\n        ")
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-link",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return props.close()
                    }
                  }
                },
                [_vm._v("Back")]
              )
            ]
          }
        }
      ])
    },
    [
      _c("template", { slot: "body" }, [
        _c("div", { staticClass: "form-group" }, [
          _c("label", [
            _vm._v(
              "This checkout is " +
                _vm._s(_vm.diff_hours) +
                " hours after the reserved checkout time. A difference of " +
                _vm._s(_vm.amount) +
                " would be owed if customer is checking out now."
            )
          ]),
          _c("br"),
          _vm._v(" "),
          _c("div", { staticClass: "radio" }, [
            _c("label", { staticClass: "margin-bottom-1x" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.type,
                    expression: "type"
                  }
                ],
                attrs: { type: "radio", value: "charge" },
                domProps: { checked: _vm._q(_vm.type, "charge") },
                on: {
                  change: function($event) {
                    _vm.type = "charge"
                  }
                }
              }),
              _vm._v(" "),
              _c("strong", [
                _vm._v(
                  "Charge customer the difference (" + _vm._s(_vm.amount) + ")"
                )
              ])
            ]),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c("label", { staticClass: "margin-bottom-1x" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.type,
                    expression: "type"
                  }
                ],
                attrs: { type: "radio", value: "without_charge" },
                domProps: { checked: _vm._q(_vm.type, "without_charge") },
                on: {
                  change: function($event) {
                    _vm.type = "without_charge"
                  }
                }
              }),
              _vm._v(" "),
              _c("strong", [_vm._v("Check-out without additional charge")])
            ]),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c("label", { staticClass: "margin-bottom-1x" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.type,
                    expression: "type"
                  }
                ],
                attrs: { type: "radio", value: "dont_checkout" },
                domProps: { checked: _vm._q(_vm.type, "dont_checkout") },
                on: {
                  change: function($event) {
                    _vm.type = "dont_checkout"
                  }
                }
              }),
              _vm._v(" "),
              _c("strong", [_vm._v("Back – Don’t check out")])
            ])
          ])
        ])
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-756dc7e7", { render: render, staticRenderFns: staticRenderFns })
  }
}