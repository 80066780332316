/*
* @INFO: This component runs a bootstrap modal
* @PROP canOpen: determaines if the confirm should show or not
* @PROP title: the text that will be shown on the modal header
* @PROP backdrop: a flag, if set to false, the modal can't be closed when clicking on the screen
* @NOTE: you can use the following to close the modal in the parent component:
*        <template slot="footer(or body)" slot-scope="props">
*         <button @click="props.close()"></button>
*        </template>
* @NOTE: this component can be espcially used by
*  referencing it using ref="something" and then
*  use it as this.$refs.something.open()
*/

const computed = {
  canShow () {
    console.log(typeof this.canOpen);
    return typeof this.canOpen == 'undefined' ? true : this.canOpen
  }
}

const methods = {
  open () {
    // console.log(this.canShow);
    if (this.canShow) {
      $(this.$refs.modal).modal('show');
      $(this.$refs.modal).modal({
        backdrop: this.backdrop
      });
    } else {
      $(this.$refs.modal).modal("hide");
    }
  },
  close () {
    $(this.$refs.modal).modal("hide");
    // this.$emit('close');
  }
}

export default {
  name: 'functional-modal',
  props: ['canOpen', 'backdrop', 'title', 'size', 'centered', 'hideFooter'],
  computed,
  methods,
  data () {
    return {
    }
  },
  mounted () {
    $(this.$refs.modal).on('hidden.bs.modal', (e) => {
      this.$emit('close');
      console.log('modal closed');
    })

    $(this.$refs.modal).on('shown.bs.modal', (e) => {

      $('body').addClass('modal-open')
      console.log('modal opened');
    })
  },
  beforeDestroy () {
    $('.modal-backdrop').remove();
    $('body').removeClass('modal-open')
  }
}
