import toastr from 'toastr'
import { permits } from 'services'
import { baseModal } from 'components/modals'

const components = {
  baseModal
}

const computed = {
  roles () {
    return this.$store.state.roles.all;
  },
  countries () {
    let countries = [{name: "Select Country", id: null}]
      .concat(this.$store.state.address.countries)

    return countries
  },
  states () {
    let states = [{name: "Select State", id: null}];
    if (this.form.country_id) {

      let indx = _.findIndex(this.countries, item => item.id == this.form.country_id);
      if (indx > -1) {
        states = states.concat(this.countries[indx].states);
      }
    }

    return states;
  }
}

const methods = {
  checkEmail () {
    if (!this.form.email) return;
    this.checkingEmail = true;
    this.$store.dispatch("users.getUserByEmail", {
      type: 'location',
      params: {
        email: this.form.email,
        location_id: this.$route.params.id
      }
    })
    .then(() => {
      this.checkingEmail = false;
      if (this.$store.state.users.errors || _.isEmpty(this.$store.state.users.active)) {
        toastr.error(this.$store.state.users.errors.message);
      } else {
        this.selectedUser = this.$store.state.users.active;
        this.form = _.assign(this.form, this.selectedUser);
        toastr.success('User found, assign permissions to them and then press submit');
      }
    });
  },

  submit (modal) {
    this.errors = {}
    this.processing = true;
    this.$store.dispatch('locations.upsertEmployee', {id: this.$route.params.id, data: this.form})
    .then(() => {
      this.processing = false;
      let errors = this.$store.state.locations.errors;
      if (errors) {
          if (errors.errors) {
            this.errors = _.cloneDeep(errors.errors);
          }
          toastr.error(errors.message);
        } else {
        modal.close();
        this.unSelectUser();
      }
    });
  },

  unSelectUser () {
    this.selectedUser = null;
    this.form = {
      name: null,
      email: null,
      address1: null,
      address2: null,
      zip_code: null,
      country_id: null,
      state_id: null,
      type: 'location',
      role: null
    }
  }
}

export default {
  name: 'new-employee-modal',
  computed,
  components,
  methods,
  data () {
    return {
      form: {
        name: null,
        email: null,
        address1: null,
        address2: null,
        zip_code: null,
        country_id: null,
        state_id: null,
        type: 'location',
        role: null
      },
      selectedUser: null,
      loading: true,
      processing: false,
      checkingEmail: false,
      errors: {}
    }
  },
  created () {
    this.$store.dispatch('address.getCountries');

    if (permits.hasLocationPermits(this.$route.params.id, [
      'locationEmployee_create',
    ])) {
      this.$store.dispatch('roles.getAll', {
        type: 'location',
        _all: true,
        location_id: this.$route.params.id
      }).then(() => {
        this.loading = false;
      });
    }
  }
}
