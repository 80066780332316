import moment from 'moment'
import toastr from 'toastr'
import { confirm } from 'components/modals'
import { hasPermits } from 'directives'
import { permits } from 'services'
import pagination from 'components/pagination'

const directives = {
  hasPermits
}

const computed = {
  channel () {
    console.log('rates changed');
    
    return _.cloneDeep(this.$store.state.locations.activeChannel);
  },

  priceBasis () {
    return _.cloneDeep(this.$store.state.locations.active.daily_or_hourly) || 'hourly';
  },

  rateTypesWithRates() {
    let rateTypes = _.cloneDeep(this.rateTypes);
    rateTypes.forEach(rate_type => {      
      let rates;
      if (this.type == 'parking') {
        rates = this.channel.parkingRates;
      } else if (this.type == 'bundle') {
        rates = this.channel.bundleRates;
      } else {
        rates = this.channel.roomRates;
      }

      let typeRates = _.find(rates, (rate) => rate_type.id == rate.typeId)
      rate_type.rates = typeRates && typeRates.rates ? typeRates.rates : [];
    })
    return rateTypes;
  },
}

const components = {
  confirm,
  pagination,
}

const methods = {
  loadPage (page, type, perPage = 15) {        
    this.loading[type.id] = true
    this.loading = _.cloneDeep(this.loading);
    this.$store.dispatch('locations.getRatesForListView', {
      type: this.type,
      locationId: this.locId,
      channelId: this.channel.channel_id,
      typeId: type.id,
      page,
      perPage
    }).then(() => {
      this.loading[type.id] = false;
      this.loading = _.cloneDeep(this.loading);
    });
  },
  remove (type, rate, force = false) {
    this.$refs.confirmDelete.confirm('Are you sure?', () => {
      this.$store.dispatch('locations.removeRate', {type, rate, force, channel_id: this.channel.id, location_id: this.locId}).then(() => {
        let errors = this.$store.state.locations.errors;
        if (errors) {
          if (errors.message && errors.message.includes("You don't have permission to delete")) {
            toastr.error(errors.message);
          }
          else if(errors.status_code == 400) {
            this.$refs.confirmDelete.confirm('This rate has reservations in it, are you realy sure?', () => {
              this.remove (type, rate, true);
            })
          } else if (errors) {
            _.forEach(errors, (error) => {
              toastr.error(error[0]);
            })
          }
        }
      })
    });
  },
  trueDays (item) {
    let days = [
      'sunday', 'monday', 'tuesday', 'wednesday',
      'thursday', 'friday', 'saturday'
    ];

    let to_return = [];
    for (let i in days) {
      if (item[days[i]])
        to_return.push(days[i])
    }

    return to_return.join(', ')
  },

  isLocationUser () {
    return !permits.hasPermit('super_admin') && permits.hasPermit('location_user');
  },
}

export default {
  name: "list-view",
  props: ['numberOfDays', 'rateTypes', 'fromDate', 'type'],
  computed,
  methods,
  components,
  directives,
  data () {
    return {
      moment,
      locId: this.$route.params.id,
      loading: {},
      pageLoading: false,
    }
  }
}
