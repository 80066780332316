import _ from 'lodash'
import toastr from 'toastr'
import { baseModal } from 'components/modals'
import sSelect from 'components/sSelect'
import typeForm from './form'

const components = {
  sSelect,
  baseModal,
  typeForm
}

const methods = {
  submit (modal) {
    this.errors = {};

    let data = _.cloneDeep(this.form)
    data.location_id = this.$route.params.id;

    this.$store.dispatch('locations.newLocationBundleType', data).then(() => {
      if (this.$store.state.locations.errors) {
        this.errors = _.cloneDeep(this.$store.state.locations.errors.errors);
        toastr.error(this.$store.state.locations.errors.message);
      } else {
        this.form = {
          name: null,
          price_limit: null,
          location_room_type_id: null,
          location_parking_type_id: null,
          number_of_room_days: null,
          number_of_parking_days: null,
        }
        modal.close();
      }
    });

  },
}

const computed = {

}

export default {
  name: 'new-rooms-type',
  methods,
  components,
  computed,
  data () {
    return {
      form: {
        name: null,
        price_limit: null,
        location_room_type_id: null,
        location_parking_type_id: null,
        number_of_room_days: null,
        number_of_parking_days: null,
      },
      errors: {}
    }
  },
}
