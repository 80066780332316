import toastr from 'toastr'
import fileUpload from 'components/fileUpload'
import { funcModal } from 'components/modals'

const components = {
    fileUpload,
    funcModal
}

const methods = {
    open (amount, reservation_id) {
        this.amount = amount;
        this.reservation_id = reservation_id;
        this.$refs.modal.open();
    },

    onClose () {
        this.$emit('close');
    },

    filesToUpload (files) {
        if (files.length > 5) {
            alert('maximum allowd items is 5.');
            this.$refs.file_upload.removeFiles();
            return;
        } else {
            for (let i = 0; i < files.length; i++) {
                if ((files[i].size / 1024) > 3072) {
                    alert('The maximum size for each file is 3M.');
                    this.$refs.file_upload.removeFiles();
                    return;
                }
            }
        }
        
        if (files.length) {
          this.files = files;
        } else {
          this.files = [];
        }
    },

    submit () {
        this.processing = true;
        this.errors = {};
        this.$store.dispatch('refundRequests.add', {
            chId: this.$route.params.id, id: this.reservation_id,
            data: this.form,
            files: this.files
        }).then(() => {
            if (this.$store.state.refundRequests.errors) {
                this.errors = _.cloneDeep(this.$store.state.refundRequests.errors.errors);
                toastr.error(this.$store.state.refundRequests.errors.message);
            } else {
                toastr.success('Partial refund Request has been send');
                this.form.amount = null;
                this.form.notes = '';
                this.form.type = '';
                this.$refs.file_upload.removeFiles();
                this.$refs.modal.close();
            }

            this.processing = false;
        });
    }
}

export default {
    name: 'partial-refund-request-modal',
    methods,
    components,
    data () {
        return {
            errors: {},
            amount: 0,
            files: [],
            reservation_id: null,
            form: {
                amount: null,
                notes: "",
                type: "",
            },
            processing: false
        }
    }
}