var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c("div", { staticClass: "table-responsive" }, [
        _c("table", { staticClass: "table" }, [
          _c("thead", [
            _c("tr", [
              _c("th", [_vm._v(" Field Name ")]),
              _vm._v(" "),
              _c("th", [_vm._v(" Reservation Type ")]),
              _vm._v(" "),
              _c("th", [_vm._v(" Input Type ")]),
              _vm._v(" "),
              _c("th", [_vm._v(" Per car ")]),
              _vm._v(" "),
              _c(
                "th",
                {
                  directives: [
                    {
                      name: "hasPermits",
                      rawName: "v-hasPermits:allow",
                      value: { id: _vm.locId, permits: ["extraFields_delete"] },
                      expression:
                        "{id: locId, permits: ['extraFields_delete']}",
                      arg: "allow"
                    }
                  ]
                },
                [_vm._v(" Delete ")]
              )
            ])
          ]),
          _vm._v(" "),
          _c(
            "tbody",
            _vm._l(_vm.extraFields, function(field) {
              return _c("tr", [
                _c("td", [
                  _vm._v(
                    "\n            " + _vm._s(field.label) + "\n          "
                  )
                ]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm._f("capitalize")(field.type)) +
                      "\n          "
                  )
                ]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(
                    "\n            " +
                      _vm._s(
                        _vm._f("capitalize")(field.input_type.replace("_", " "))
                      ) +
                      "\n          "
                  )
                ]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(
                    "\n            " + _vm._s(!!field.per_car) + "\n          "
                  )
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  {
                    directives: [
                      {
                        name: "hasPermits",
                        rawName: "v-hasPermits:allow",
                        value: {
                          id: _vm.locId,
                          permits: ["extraFields_delete"]
                        },
                        expression:
                          "{id: locId, permits: ['extraFields_delete']}",
                        arg: "allow"
                      }
                    ]
                  },
                  [
                    _vm.loadings[field.id]
                      ? _c("div", [
                          _c("i", { staticClass: "fa fa-spinner fa-spin" })
                        ])
                      : _c(
                          "a",
                          {
                            staticClass: "text-danger",
                            attrs: { href: "#" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.removeExtraField(field.id)
                              }
                            }
                          },
                          [_c("i", { staticClass: "fa fa-trash" })]
                        )
                  ]
                )
              ])
            }),
            0
          )
        ])
      ]),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "hasPermits",
              rawName: "v-hasPermits:allow",
              value: { id: _vm.locId, permits: ["extraFields_create"] },
              expression: "{id: locId, permits: ['extraFields_create']}",
              arg: "allow"
            }
          ]
        },
        [
          _c("div", { staticClass: "text-right" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-default",
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    _vm.formActive = !_vm.formActive
                  }
                }
              },
              [
                !_vm.formActive
                  ? _c("i", {
                      staticClass: "fa fa-caret-down",
                      attrs: { "aria-hidden": "true" }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.formActive
                  ? _c("i", {
                      staticClass: "fa fa-caret-up",
                      attrs: { "aria-hidden": "true" }
                    })
                  : _vm._e(),
                _vm._v("\n        New Fields\n      ")
              ]
            )
          ]),
          _vm._v(" "),
          _vm.formActive
            ? _c(
                "div",
                { staticClass: "margin-top-3x" },
                [
                  _vm._l(_vm.fields_to_submit, function(field, i) {
                    return _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-sm-4" }, [
                        _c("label", [_vm._v("Field Name")]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: field.label,
                              expression: "field.label"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: { type: "text" },
                          domProps: { value: field.label },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(field, "label", $event.target.value)
                            }
                          }
                        }),
                        _vm._v(" "),
                        _vm.errors[i + ".label"]
                          ? _c("div", { staticClass: "text-danger" }, [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.errors[i + ".label"][0]) +
                                  "\n          "
                              )
                            ])
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-sm-3" }, [
                        _c("label", [_vm._v("Type")]),
                        _vm._v(" "),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: field.type,
                                expression: "field.type"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: { type: "text" },
                            on: {
                              change: function($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function(o) {
                                    return o.selected
                                  })
                                  .map(function(o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  field,
                                  "type",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              }
                            }
                          },
                          [
                            _c("option", { attrs: { value: "both" } }, [
                              _vm._v("Both")
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "room" } }, [
                              _vm._v("Room")
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "parking" } }, [
                              _vm._v("Parking")
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _vm.errors[i + ".type"]
                          ? _c("div", { staticClass: "text-danger" }, [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.errors[i + ".type"][0]) +
                                  "\n          "
                              )
                            ])
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-sm-3" }, [
                        _c("label", [_vm._v("Input Type")]),
                        _vm._v(" "),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: field.input_type,
                                expression: "field.input_type"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: { type: "text" },
                            on: {
                              change: function($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function(o) {
                                    return o.selected
                                  })
                                  .map(function(o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  field,
                                  "input_type",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              }
                            }
                          },
                          [
                            _c("option", { attrs: { value: "text" } }, [
                              _vm._v("Text")
                            ]),
                            _vm._v(" "),
                            _c(
                              "option",
                              { attrs: { value: "flight_number" } },
                              [_vm._v("Flight Number")]
                            ),
                            _vm._v(" "),
                            _c(
                              "option",
                              { attrs: { value: "license_plate" } },
                              [_vm._v("License Plate")]
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _vm.errors[i + ".input_type"]
                          ? _c("div", { staticClass: "text-danger" }, [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.errors[i + ".input_type"][0]) +
                                  "\n          "
                              )
                            ])
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      field.type == "both" || field.type == "parking"
                        ? _c("div", { staticClass: "col-sm-2" }, [
                            _c("label", [_vm._v("Per car")]),
                            _vm._v(" "),
                            i > 0
                              ? _c("div", { staticClass: "pull-right" }, [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "text-danger",
                                      attrs: { href: "#" },
                                      on: {
                                        click: function($event) {
                                          return _vm.removeFields(i, $event)
                                        }
                                      }
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "fa fa-times",
                                        attrs: { "aria-hidden": "true" }
                                      })
                                    ]
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: field.per_car,
                                  expression: "field.per_car"
                                }
                              ],
                              staticStyle: { display: "block" },
                              attrs: { type: "checkbox" },
                              domProps: {
                                checked: Array.isArray(field.per_car)
                                  ? _vm._i(field.per_car, null) > -1
                                  : field.per_car
                              },
                              on: {
                                change: function($event) {
                                  var $$a = field.per_car,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          field,
                                          "per_car",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          field,
                                          "per_car",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(field, "per_car", $$c)
                                  }
                                }
                              }
                            }),
                            _vm._v(" "),
                            _vm.errors[i + ".per_car"]
                              ? _c("div", { staticClass: "text-danger" }, [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.errors[i + ".per_car"][0]) +
                                      "\n          "
                                  )
                                ])
                              : _vm._e()
                          ])
                        : _vm._e()
                    ])
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "text-right margin-top-3x" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-default",
                        on: { click: _vm.addMoreFields }
                      },
                      [_vm._v("\n          +\n        ")]
                    )
                  ]),
                  _vm._v(" "),
                  _c("hr"),
                  _vm._v(" "),
                  _c("div", { staticClass: "text-right" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-success",
                        class: { disabled: _vm.processing },
                        attrs: { disabled: _vm.processing },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.submit()
                          }
                        }
                      },
                      [
                        _vm.processing
                          ? _c("span", [
                              _c("i", { staticClass: "fa fa-spinner fa-spin" })
                            ])
                          : _vm._e(),
                        _vm._v("\n        SAVE Fields\n        ")
                      ]
                    )
                  ])
                ],
                2
              )
            : _vm._e()
        ]
      ),
      _vm._v(" "),
      _c("confirm", { ref: "confirmDelete", attrs: { type: "danger" } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-4f491f09", { render: render, staticRenderFns: staticRenderFns })
  }
}