var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "layout",
    { attrs: { "no-box": true, title: _vm.location ? _vm.location.name : "" } },
    [
      _c("tabs"),
      _vm._v(" "),
      _c("div", { staticClass: "box" }, [
        _c("div", { staticClass: "box-body" }, [
          _vm.loading
            ? _c("div", { staticClass: "text-center" }, [
                _c("i", { staticClass: "fa fa-spinner fa-spin fa-3x fa-fw" })
              ])
            : _c(
                "div",
                [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-sm-12 margin-top-3x" }, [
                      _c(
                        "div",
                        { staticClass: "text-right" },
                        [
                          _c("new-rooms-type", {
                            directives: [
                              {
                                name: "hasPermits",
                                rawName: "v-hasPermits:allow",
                                value: {
                                  id: _vm.location.id,
                                  permits: ["locationRoomType_create"]
                                },
                                expression:
                                  "{id: location.id, permits: ['locationRoomType_create']}",
                                arg: "allow"
                              }
                            ]
                          }),
                          _vm._v(" "),
                          _c("edit-rooms-type", {
                            directives: [
                              {
                                name: "hasPermits",
                                rawName: "v-hasPermits:allow",
                                value: {
                                  id: _vm.location.id,
                                  permits: ["locationRoomType_update"]
                                },
                                expression:
                                  "{id: location.id, permits: ['locationRoomType_update']}",
                                arg: "allow"
                              }
                            ],
                            ref: "editType",
                            attrs: {
                              type: _vm.selectedType,
                              canOpen: !_vm._.isEmpty(_vm.selectedType)
                            }
                          }),
                          _vm._v(" "),
                          _c("new-parkings-type", {
                            directives: [
                              {
                                name: "hasPermits",
                                rawName: "v-hasPermits:allow",
                                value: {
                                  id: _vm.location.id,
                                  permits: ["locationParkingType_create"]
                                },
                                expression:
                                  "{id: location.id, permits: ['locationParkingType_create']}",
                                arg: "allow"
                              }
                            ]
                          }),
                          _vm._v(" "),
                          _c("new-bundle-type", {
                            directives: [
                              {
                                name: "hasPermits",
                                rawName: "v-hasPermits:allow",
                                value: {
                                  id: _vm.location.id,
                                  permits: ["locationBundle_create"]
                                },
                                expression:
                                  "{id: location.id, permits: ['locationBundle_create']}",
                                arg: "allow"
                              }
                            ]
                          }),
                          _vm._v(" "),
                          _c("edit-bundle-type", {
                            directives: [
                              {
                                name: "hasPermits",
                                rawName: "v-hasPermits:allow",
                                value: {
                                  id: _vm.location.id,
                                  permits: ["locationBundle_update"]
                                },
                                expression:
                                  "{id: location.id, permits: ['locationBundle_update']}",
                                arg: "allow"
                              }
                            ],
                            ref: "editBundle",
                            attrs: {
                              bundle: _vm.selectedBundle,
                              canOpen: !_vm._.isEmpty(_vm.selectedBundle)
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "hasPermits",
                              rawName: "v-hasPermits:allow",
                              value: ["super_admin"],
                              expression: "['super_admin']",
                              arg: "allow"
                            }
                          ],
                          staticClass: "col-md-4 col-sm-6 margin-top-3x"
                        },
                        [
                          _c("div", [
                            _c("label", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.filter,
                                    expression: "filter"
                                  }
                                ],
                                attrs: {
                                  type: "radio",
                                  value: "active",
                                  selected: ""
                                },
                                domProps: {
                                  checked: _vm._q(_vm.filter, "active")
                                },
                                on: {
                                  change: function($event) {
                                    _vm.filter = "active"
                                  }
                                }
                              }),
                              _vm._v(
                                "\n                  Active Rates\n                "
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", [
                            _c("label", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.filter,
                                    expression: "filter"
                                  }
                                ],
                                attrs: { type: "radio", value: "deleted" },
                                domProps: {
                                  checked: _vm._q(_vm.filter, "deleted")
                                },
                                on: {
                                  change: function($event) {
                                    _vm.filter = "deleted"
                                  }
                                }
                              }),
                              _vm._v(
                                "\n                  Deleted Rates\n                "
                              )
                            ])
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c("table", {}, [
                        _c("tbody", [
                          _c("tr", [
                            _c("th", { staticClass: "padding-2x" }, [
                              _vm._v("Total Rooms:")
                            ]),
                            _vm._v(" "),
                            _c(
                              "td",
                              [
                                _c("editable-text", {
                                  ref: "roomComponent",
                                  attrs: {
                                    value: _vm.location.number_of_rooms,
                                    "show-pen": true
                                  },
                                  on: {
                                    valueSaved: function(data) {
                                      return _vm.onUpdateTypesNumber(
                                        "number_of_rooms",
                                        data
                                      )
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _vm.errors.number_of_rooms
                                  ? _c("div", { staticClass: "text-danger" }, [
                                      _vm._v(
                                        _vm._s(_vm.errors.number_of_rooms[0])
                                      )
                                    ])
                                  : _vm._e()
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c("tr", [
                            _c("th", { staticClass: "padding-2x" }, [
                              _vm._v("Total Parking Spots:")
                            ]),
                            _vm._v(" "),
                            _c(
                              "td",
                              [
                                _c("editable-text", {
                                  attrs: {
                                    value: _vm.location.number_of_parkings,
                                    "show-pen": true
                                  },
                                  on: {
                                    valueSaved: function(data) {
                                      return _vm.onUpdateTypesNumber(
                                        "number_of_parkings",
                                        data
                                      )
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _vm.errors.number_of_parkings
                                  ? _c("div", { staticClass: "text-danger" }, [
                                      _vm._v(
                                        _vm._s(_vm.errors.number_of_parkings[0])
                                      )
                                    ])
                                  : _vm._e()
                              ],
                              1
                            )
                          ])
                        ])
                      ]),
                      _vm._v(" "),
                      _vm.filter == "active"
                        ? _c("div", [
                            _c("hr"),
                            _vm._v(" "),
                            _c("div", {}, [
                              _vm.roomTypes.length
                                ? _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "hasPermits",
                                          rawName: "v-hasPermits:allow",
                                          value: {
                                            id: _vm.location.id,
                                            permits: ["locationRoomType_list"]
                                          },
                                          expression:
                                            "{id: location.id, permits: ['locationRoomType_list']}",
                                          arg: "allow"
                                        }
                                      ]
                                    },
                                    [
                                      _c("h4", { staticClass: "text-info" }, [
                                        _vm._v("Available rooms types:")
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "table",
                                        { staticClass: "table table-hover" },
                                        [
                                          _c("thead", [
                                            _c("tr", [
                                              _c("th", [_vm._v("Room Type")]),
                                              _vm._v(" "),
                                              _c("th", [
                                                _vm._v("Number of Rooms")
                                              ]),
                                              _vm._v(" "),
                                              _c("th", [_vm._v("Price Limit")]),
                                              _vm._v(" "),
                                              _c(
                                                "th",
                                                {
                                                  directives: [
                                                    {
                                                      name: "hasPermits",
                                                      rawName:
                                                        "v-hasPermits:allow",
                                                      value: ["super_admin"],
                                                      expression:
                                                        "['super_admin']",
                                                      arg: "allow"
                                                    }
                                                  ]
                                                },
                                                [_vm._v("Package Only")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "th",
                                                {
                                                  directives: [
                                                    {
                                                      name: "hasPermits",
                                                      rawName:
                                                        "v-hasPermits:allow",
                                                      value: ["super_admin"],
                                                      expression:
                                                        "['super_admin']",
                                                      arg: "allow"
                                                    }
                                                  ]
                                                },
                                                [_vm._v("Sold Out")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "th",
                                                {
                                                  directives: [
                                                    {
                                                      name: "hasPermits",
                                                      rawName:
                                                        "v-hasPermits:allow",
                                                      value: {
                                                        id: _vm.location.id,
                                                        permits: [
                                                          "locationRoomType_update"
                                                        ]
                                                      },
                                                      expression:
                                                        "{id: location.id, permits: ['locationRoomType_update']}",
                                                      arg: "allow"
                                                    }
                                                  ]
                                                },
                                                [_vm._v("Edit")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "th",
                                                {
                                                  directives: [
                                                    {
                                                      name: "hasPermits",
                                                      rawName:
                                                        "v-hasPermits:allow",
                                                      value: {
                                                        id: _vm.location.id,
                                                        permits: [
                                                          "locationRoomType_delete"
                                                        ]
                                                      },
                                                      expression:
                                                        "{id: location.id, permits: ['locationRoomType_delete']}",
                                                      arg: "allow"
                                                    }
                                                  ]
                                                },
                                                [_vm._v("Remove")]
                                              )
                                            ])
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "tbody",
                                            _vm._l(_vm.roomTypes, function(
                                              item
                                            ) {
                                              return _c("tr", [
                                                _vm.isLocationUser() &&
                                                item.rate_type === "L"
                                                  ? _c("td", [
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(item.name) +
                                                          "\n                      "
                                                      )
                                                    ])
                                                  : _c(
                                                      "td",
                                                      [
                                                        _c("editable-text", {
                                                          attrs: {
                                                            value: item.name
                                                          },
                                                          on: {
                                                            valueSaved: function(
                                                              data
                                                            ) {
                                                              return _vm.onEditType(
                                                                "Room",
                                                                data,
                                                                item,
                                                                "name"
                                                              )
                                                            }
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    ),
                                                _vm._v(" "),
                                                _vm.isLocationUser() &&
                                                item.rate_type === "L"
                                                  ? _c("td", [
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(
                                                            item.number_of_rooms
                                                          ) +
                                                          "\n                      "
                                                      )
                                                    ])
                                                  : _c(
                                                      "td",
                                                      [
                                                        _c("editable-text", {
                                                          attrs: {
                                                            value:
                                                              item.number_of_rooms
                                                          },
                                                          on: {
                                                            valueSaved: function(
                                                              data
                                                            ) {
                                                              return _vm.onEditType(
                                                                "Room",
                                                                data,
                                                                item,
                                                                "number_of_rooms"
                                                              )
                                                            }
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    ),
                                                _vm._v(" "),
                                                _vm.isSuperAdmin
                                                  ? _c(
                                                      "td",
                                                      [
                                                        _c("editable-text", {
                                                          attrs: {
                                                            value:
                                                              item.price_limit
                                                          },
                                                          on: {
                                                            valueSaved: function(
                                                              data
                                                            ) {
                                                              return _vm.onEditType(
                                                                "Room",
                                                                data,
                                                                item,
                                                                "price_limit"
                                                              )
                                                            }
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  : _c("td", [
                                                      _vm._v(
                                                        _vm._s(item.price_limit)
                                                      )
                                                    ]),
                                                _vm._v(" "),
                                                _c(
                                                  "td",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "hasPermits",
                                                        rawName:
                                                          "v-hasPermits:allow",
                                                        value: ["super_admin"],
                                                        expression:
                                                          "['super_admin']",
                                                        arg: "allow"
                                                      }
                                                    ]
                                                  },
                                                  [
                                                    _c("editable-checkbox", {
                                                      attrs: {
                                                        value: item.package_only
                                                      },
                                                      on: {
                                                        valueSaved: function(
                                                          data
                                                        ) {
                                                          return _vm.onCheckboxClicked(
                                                            "Room",
                                                            data,
                                                            item,
                                                            "package_only"
                                                          )
                                                        }
                                                      }
                                                    })
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "td",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "hasPermits",
                                                        rawName:
                                                          "v-hasPermits:allow",
                                                        value: ["super_admin"],
                                                        expression:
                                                          "['super_admin']",
                                                        arg: "allow"
                                                      }
                                                    ]
                                                  },
                                                  [
                                                    _c("editable-checkbox", {
                                                      attrs: {
                                                        value: item.sold_out
                                                      },
                                                      on: {
                                                        valueSaved: function(
                                                          data
                                                        ) {
                                                          return _vm.onCheckboxClicked(
                                                            "Room",
                                                            data,
                                                            item,
                                                            "sold_out"
                                                          )
                                                        }
                                                      }
                                                    })
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                !_vm.isLocationUser() ||
                                                item.rate_type !== "L"
                                                  ? _c(
                                                      "td",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "hasPermits",
                                                            rawName:
                                                              "v-hasPermits:allow",
                                                            value: {
                                                              id:
                                                                _vm.location.id,
                                                              permits: [
                                                                "locationRoomType_update"
                                                              ]
                                                            },
                                                            expression:
                                                              "{id: location.id, permits: ['locationRoomType_update']}",
                                                            arg: "allow"
                                                          }
                                                        ]
                                                      },
                                                      [
                                                        _c(
                                                          "a",
                                                          {
                                                            staticClass:
                                                              "text-info",
                                                            attrs: {
                                                              href: "#"
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                $event.preventDefault()
                                                                return _vm.editType(
                                                                  item
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "fa fa-pencil",
                                                              attrs: {
                                                                "aria-hidden":
                                                                  "true"
                                                              }
                                                            })
                                                          ]
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                !_vm.isLocationUser() ||
                                                item.rate_type !== "L"
                                                  ? _c(
                                                      "td",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "hasPermits",
                                                            rawName:
                                                              "v-hasPermits:allow",
                                                            value: {
                                                              id:
                                                                _vm.location.id,
                                                              permits: [
                                                                "locationRoomType_delete"
                                                              ]
                                                            },
                                                            expression:
                                                              "{id: location.id, permits: ['locationRoomType_delete']}",
                                                            arg: "allow"
                                                          }
                                                        ]
                                                      },
                                                      [
                                                        _c(
                                                          "a",
                                                          {
                                                            staticClass:
                                                              "text-danger",
                                                            attrs: {
                                                              href: "#"
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                $event.preventDefault()
                                                                return _vm.removeRoomType(
                                                                  item
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "fa fa-times",
                                                              attrs: {
                                                                "aria-hidden":
                                                                  "true"
                                                              }
                                                            })
                                                          ]
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e()
                                              ])
                                            }),
                                            0
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                : _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "hasPermits",
                                          rawName: "v-hasPermits:allow",
                                          value: {
                                            id: _vm.location.id,
                                            permits: ["locationRoomType_list"]
                                          },
                                          expression:
                                            "{id: location.id, permits: ['locationRoomType_list']}",
                                          arg: "allow"
                                        }
                                      ],
                                      staticClass: "text-warning"
                                    },
                                    [_vm._v("No room types were added")]
                                  )
                            ]),
                            _vm._v(" "),
                            _c("hr"),
                            _vm._v(" "),
                            _c("div", {}, [
                              _vm.parkingTypes.length
                                ? _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "hasPermits",
                                          rawName: "v-hasPermits:allow",
                                          value: {
                                            id: _vm.location.id,
                                            permits: [
                                              "locationParkingType_list"
                                            ]
                                          },
                                          expression:
                                            "{id: location.id, permits: ['locationParkingType_list']}",
                                          arg: "allow"
                                        }
                                      ]
                                    },
                                    [
                                      _c("h4", { staticClass: "text-info" }, [
                                        _vm._v("Available Parking types:")
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "table",
                                        { staticClass: "table table-hover" },
                                        [
                                          _c("thead", [
                                            _c("tr", [
                                              _c("th", [
                                                _vm._v("Parking Type")
                                              ]),
                                              _vm._v(" "),
                                              _c("th", [_vm._v("Suffix")]),
                                              _vm._v(" "),
                                              _c("th", [
                                                _vm._v("Number of Spots")
                                              ]),
                                              _vm._v(" "),
                                              _c("th", [_vm._v("Price Limit")]),
                                              _vm._v(" "),
                                              _c(
                                                "th",
                                                {
                                                  directives: [
                                                    {
                                                      name: "hasPermits",
                                                      rawName:
                                                        "v-hasPermits:allow",
                                                      value: ["super_admin"],
                                                      expression:
                                                        "['super_admin']",
                                                      arg: "allow"
                                                    }
                                                  ]
                                                },
                                                [_vm._v("Package Only")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "th",
                                                {
                                                  directives: [
                                                    {
                                                      name: "hasPermits",
                                                      rawName:
                                                        "v-hasPermits:allow",
                                                      value: ["super_admin"],
                                                      expression:
                                                        "['super_admin']",
                                                      arg: "allow"
                                                    }
                                                  ]
                                                },
                                                [_vm._v("Sold Out")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "th",
                                                {
                                                  directives: [
                                                    {
                                                      name: "hasPermits",
                                                      rawName:
                                                        "v-hasPermits:allow",
                                                      value: {
                                                        id: _vm.location.id,
                                                        permits: [
                                                          "locationParkingType_update"
                                                        ]
                                                      },
                                                      expression:
                                                        "{id: location.id, permits: ['locationParkingType_update']}",
                                                      arg: "allow"
                                                    }
                                                  ]
                                                },
                                                [_vm._v("Edit")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "th",
                                                {
                                                  directives: [
                                                    {
                                                      name: "hasPermits",
                                                      rawName:
                                                        "v-hasPermits:allow",
                                                      value: [
                                                        "parkingLeaseTypePeriod_list"
                                                      ],
                                                      expression:
                                                        "['parkingLeaseTypePeriod_list']",
                                                      arg: "allow"
                                                    }
                                                  ]
                                                },
                                                [_vm._v("Lease periods")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "th",
                                                {
                                                  directives: [
                                                    {
                                                      name: "hasPermits",
                                                      rawName:
                                                        "v-hasPermits:allow",
                                                      value: {
                                                        id: _vm.location.id,
                                                        permits: [
                                                          "locationParkingType_delete"
                                                        ]
                                                      },
                                                      expression:
                                                        "{id: location.id, permits: ['locationParkingType_delete']}",
                                                      arg: "allow"
                                                    }
                                                  ]
                                                },
                                                [_vm._v("Remove")]
                                              )
                                            ])
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "tbody",
                                            [
                                              _vm._l(_vm.parkingTypes, function(
                                                item
                                              ) {
                                                return [
                                                  _c("tr", [
                                                    _vm.isLocationUser() &&
                                                    item.rate_type === "L"
                                                      ? _c("td", [
                                                          _vm._v(
                                                            "\n                          " +
                                                              _vm._s(
                                                                item.name
                                                              ) +
                                                              "\n                        "
                                                          )
                                                        ])
                                                      : _c(
                                                          "td",
                                                          [
                                                            _c(
                                                              "editable-text",
                                                              {
                                                                attrs: {
                                                                  value:
                                                                    item.name,
                                                                  shownValue:
                                                                    item.displayName
                                                                },
                                                                on: {
                                                                  valueSaved: function(
                                                                    data
                                                                  ) {
                                                                    return _vm.onEditType(
                                                                      "Parking",
                                                                      data,
                                                                      item,
                                                                      "name"
                                                                    )
                                                                  }
                                                                }
                                                              }
                                                            )
                                                          ],
                                                          1
                                                        ),
                                                    _vm._v(" "),
                                                    _vm.isLocationUser()
                                                      ? _c("td", [
                                                          _vm._v(
                                                            _vm._s(item.suffix)
                                                          )
                                                        ])
                                                      : _c(
                                                          "td",
                                                          [
                                                            _c(
                                                              "editable-text",
                                                              {
                                                                attrs: {
                                                                  value:
                                                                    item.suffix
                                                                },
                                                                on: {
                                                                  valueSaved: function(
                                                                    data
                                                                  ) {
                                                                    return _vm.onEditType(
                                                                      "Parking",
                                                                      data,
                                                                      item,
                                                                      "suffix"
                                                                    )
                                                                  }
                                                                }
                                                              }
                                                            )
                                                          ],
                                                          1
                                                        ),
                                                    _vm._v(" "),
                                                    _vm.isLocationUser() &&
                                                    item.rate_type === "L"
                                                      ? _c("td", [
                                                          _vm._v(
                                                            "\n                          " +
                                                              _vm._s(
                                                                item.number_of_parkings
                                                              ) +
                                                              "\n                        "
                                                          )
                                                        ])
                                                      : _c(
                                                          "td",
                                                          [
                                                            _c(
                                                              "editable-text",
                                                              {
                                                                attrs: {
                                                                  value:
                                                                    item.number_of_parkings
                                                                },
                                                                on: {
                                                                  valueSaved: function(
                                                                    data
                                                                  ) {
                                                                    return _vm.onEditType(
                                                                      "Parking",
                                                                      data,
                                                                      item,
                                                                      "number_of_parkings"
                                                                    )
                                                                  }
                                                                }
                                                              }
                                                            )
                                                          ],
                                                          1
                                                        ),
                                                    _vm._v(" "),
                                                    _vm.isSuperAdmin
                                                      ? _c(
                                                          "td",
                                                          [
                                                            _c(
                                                              "editable-text",
                                                              {
                                                                attrs: {
                                                                  value:
                                                                    item.price_limit
                                                                },
                                                                on: {
                                                                  valueSaved: function(
                                                                    data
                                                                  ) {
                                                                    return _vm.onEditType(
                                                                      "Parking",
                                                                      data,
                                                                      item,
                                                                      "price_limit"
                                                                    )
                                                                  }
                                                                }
                                                              }
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      : _c("td", [
                                                          _vm._v(
                                                            _vm._s(
                                                              item.price_limit
                                                            )
                                                          )
                                                        ]),
                                                    _vm._v(" "),
                                                    _c(
                                                      "td",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "hasPermits",
                                                            rawName:
                                                              "v-hasPermits:allow",
                                                            value: [
                                                              "super_admin"
                                                            ],
                                                            expression:
                                                              "['super_admin']",
                                                            arg: "allow"
                                                          }
                                                        ]
                                                      },
                                                      [
                                                        _c(
                                                          "editable-checkbox",
                                                          {
                                                            attrs: {
                                                              value:
                                                                item.package_only
                                                            },
                                                            on: {
                                                              valueSaved: function(
                                                                data
                                                              ) {
                                                                return _vm.onCheckboxClicked(
                                                                  "Parking",
                                                                  data,
                                                                  item,
                                                                  "package_only"
                                                                )
                                                              }
                                                            }
                                                          }
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "td",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "hasPermits",
                                                            rawName:
                                                              "v-hasPermits:allow",
                                                            value: [
                                                              "super_admin"
                                                            ],
                                                            expression:
                                                              "['super_admin']",
                                                            arg: "allow"
                                                          }
                                                        ]
                                                      },
                                                      [
                                                        _c(
                                                          "editable-checkbox",
                                                          {
                                                            attrs: {
                                                              value:
                                                                item.sold_out
                                                            },
                                                            on: {
                                                              valueSaved: function(
                                                                data
                                                              ) {
                                                                return _vm.onCheckboxClicked(
                                                                  "Parking",
                                                                  data,
                                                                  item,
                                                                  "sold_out"
                                                                )
                                                              }
                                                            }
                                                          }
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    !_vm.isLocationUser() ||
                                                    item.rate_type !== "L"
                                                      ? _c(
                                                          "td",
                                                          {
                                                            directives: [
                                                              {
                                                                name:
                                                                  "hasPermits",
                                                                rawName:
                                                                  "v-hasPermits:allow",
                                                                value: {
                                                                  id:
                                                                    _vm.location
                                                                      .id,
                                                                  permits: [
                                                                    "locationParkingType_update"
                                                                  ]
                                                                },
                                                                expression:
                                                                  "{id: location.id, permits: ['locationParkingType_update']}",
                                                                arg: "allow"
                                                              }
                                                            ]
                                                          },
                                                          [
                                                            _c(
                                                              "router-link",
                                                              {
                                                                attrs: {
                                                                  to: {
                                                                    name:
                                                                      "locations.rateManagement.types.edit",
                                                                    params: {
                                                                      type_id:
                                                                        item.id
                                                                    }
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _c("i", {
                                                                  staticClass:
                                                                    "fa fa-pencil",
                                                                  attrs: {
                                                                    "aria-hidden":
                                                                      "true"
                                                                  }
                                                                })
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    _c(
                                                      "td",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "hasPermits",
                                                            rawName:
                                                              "v-hasPermits:allow",
                                                            value: [
                                                              "parkingLeaseTypePeriod_list"
                                                            ],
                                                            expression:
                                                              "['parkingLeaseTypePeriod_list']",
                                                            arg: "allow"
                                                          }
                                                        ]
                                                      },
                                                      [
                                                        _c(
                                                          "router-link",
                                                          {
                                                            attrs: {
                                                              to: {
                                                                name:
                                                                  "locations.parkingLeasePeriods",
                                                                params: {
                                                                  type_id:
                                                                    item.id
                                                                }
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                            Lease periods\n                          "
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    !_vm.isLocationUser() ||
                                                    item.rate_type !== "L"
                                                      ? _c(
                                                          "td",
                                                          {
                                                            directives: [
                                                              {
                                                                name:
                                                                  "hasPermits",
                                                                rawName:
                                                                  "v-hasPermits:allow",
                                                                value: {
                                                                  id:
                                                                    _vm.location
                                                                      .id,
                                                                  permits: [
                                                                    "locationParkingType_delete"
                                                                  ]
                                                                },
                                                                expression:
                                                                  "{id: location.id, permits: ['locationParkingType_delete']}",
                                                                arg: "allow"
                                                              }
                                                            ]
                                                          },
                                                          [
                                                            _c(
                                                              "a",
                                                              {
                                                                staticClass:
                                                                  "text-danger",
                                                                attrs: {
                                                                  href: "#"
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    $event.preventDefault()
                                                                    return _vm.removeParkingType(
                                                                      item
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _c("i", {
                                                                  staticClass:
                                                                    "fa fa-times",
                                                                  attrs: {
                                                                    "aria-hidden":
                                                                      "true"
                                                                  }
                                                                })
                                                              ]
                                                            )
                                                          ]
                                                        )
                                                      : _vm._e()
                                                  ]),
                                                  _vm._v(" "),
                                                  item.childTypes &&
                                                  item.childTypes.length
                                                    ? _vm._l(
                                                        item.childTypes,
                                                        function(child, i) {
                                                          return _c("tr", [
                                                            _c("td", [
                                                              _vm._v(
                                                                "\n                               " +
                                                                  _vm._s(
                                                                    child.name
                                                                  ) +
                                                                  "\n                          "
                                                              )
                                                            ]),
                                                            _vm._v(" "),
                                                            _c("td", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  child.suffix
                                                                )
                                                              )
                                                            ]),
                                                            _vm._v(" "),
                                                            _c("td", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  child.percentage
                                                                ) + " * spot"
                                                              )
                                                            ]),
                                                            _vm._v(" "),
                                                            _c("td", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  child.price_limit
                                                                )
                                                              )
                                                            ]),
                                                            _vm._v(" "),
                                                            _c("td", {
                                                              directives: [
                                                                {
                                                                  name:
                                                                    "hasPermits",
                                                                  rawName:
                                                                    "v-hasPermits:allow",
                                                                  value: [
                                                                    "super_admin"
                                                                  ],
                                                                  expression:
                                                                    "['super_admin']",
                                                                  arg: "allow"
                                                                }
                                                              ]
                                                            }),
                                                            _vm._v(" "),
                                                            _c("td", {
                                                              directives: [
                                                                {
                                                                  name:
                                                                    "hasPermits",
                                                                  rawName:
                                                                    "v-hasPermits:allow",
                                                                  value: [
                                                                    "super_admin"
                                                                  ],
                                                                  expression:
                                                                    "['super_admin']",
                                                                  arg: "allow"
                                                                }
                                                              ]
                                                            }),
                                                            _vm._v(" "),
                                                            !_vm.isLocationUser() ||
                                                            child.rate_type !==
                                                              "L"
                                                              ? _c(
                                                                  "td",
                                                                  {
                                                                    directives: [
                                                                      {
                                                                        name:
                                                                          "hasPermits",
                                                                        rawName:
                                                                          "v-hasPermits:allow",
                                                                        value: {
                                                                          id:
                                                                            _vm
                                                                              .location
                                                                              .id,
                                                                          permits: [
                                                                            "locationParkingType_update"
                                                                          ]
                                                                        },
                                                                        expression:
                                                                          "{id: location.id, permits: ['locationParkingType_update']}",
                                                                        arg:
                                                                          "allow"
                                                                      }
                                                                    ]
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "router-link",
                                                                      {
                                                                        attrs: {
                                                                          to: {
                                                                            name:
                                                                              "locations.rateManagement.types.edit",
                                                                            params: {
                                                                              type_id:
                                                                                child.id
                                                                            }
                                                                          }
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "i",
                                                                          {
                                                                            staticClass:
                                                                              "fa fa-pencil",
                                                                            attrs: {
                                                                              "aria-hidden":
                                                                                "true"
                                                                            }
                                                                          }
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e(),
                                                            _vm._v(" "),
                                                            _c(
                                                              "td",
                                                              {
                                                                directives: [
                                                                  {
                                                                    name:
                                                                      "hasPermits",
                                                                    rawName:
                                                                      "v-hasPermits:allow",
                                                                    value: [
                                                                      "parkingLeaseTypePeriod_list"
                                                                    ],
                                                                    expression:
                                                                      "['parkingLeaseTypePeriod_list']",
                                                                    arg: "allow"
                                                                  }
                                                                ]
                                                              },
                                                              [
                                                                _c(
                                                                  "router-link",
                                                                  {
                                                                    attrs: {
                                                                      to: {
                                                                        name:
                                                                          "locations.parkingLeasePeriods",
                                                                        params: {
                                                                          type_id:
                                                                            child.id
                                                                        }
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                              Lease periods\n                            "
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            ),
                                                            _vm._v(" "),
                                                            !_vm.isLocationUser() ||
                                                            child.rate_type !==
                                                              "L"
                                                              ? _c(
                                                                  "td",
                                                                  {
                                                                    directives: [
                                                                      {
                                                                        name:
                                                                          "hasPermits",
                                                                        rawName:
                                                                          "v-hasPermits:allow",
                                                                        value: {
                                                                          id:
                                                                            _vm
                                                                              .location
                                                                              .id,
                                                                          permits: [
                                                                            "locationParkingType_delete"
                                                                          ]
                                                                        },
                                                                        expression:
                                                                          "{id: location.id, permits: ['locationParkingType_delete']}",
                                                                        arg:
                                                                          "allow"
                                                                      }
                                                                    ]
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "a",
                                                                      {
                                                                        staticClass:
                                                                          "text-danger",
                                                                        attrs: {
                                                                          href:
                                                                            "#"
                                                                        },
                                                                        on: {
                                                                          click: function(
                                                                            $event
                                                                          ) {
                                                                            $event.preventDefault()
                                                                            return _vm.removeParkingType(
                                                                              child
                                                                            )
                                                                          }
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "i",
                                                                          {
                                                                            staticClass:
                                                                              "fa fa-times",
                                                                            attrs: {
                                                                              "aria-hidden":
                                                                                "true"
                                                                            }
                                                                          }
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                )
                                                              : _vm._e()
                                                          ])
                                                        }
                                                      )
                                                    : _vm._e()
                                                ]
                                              })
                                            ],
                                            2
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                : _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "hasPermits",
                                          rawName: "v-hasPermits:allow",
                                          value: {
                                            id: _vm.location.id,
                                            permits: [
                                              "locationParkingType_list"
                                            ]
                                          },
                                          expression:
                                            "{id: location.id, permits: ['locationParkingType_list']}",
                                          arg: "allow"
                                        }
                                      ],
                                      staticClass: "text-warning"
                                    },
                                    [_vm._v("No Parking types were added")]
                                  )
                            ]),
                            _vm._v(" "),
                            _c("hr"),
                            _vm._v(" "),
                            _c("div", {}, [
                              _vm.bundleTypes.length
                                ? _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "hasPermits",
                                          rawName: "v-hasPermits:allow",
                                          value: {
                                            id: _vm.location.id,
                                            permits: ["locationBundle_list"]
                                          },
                                          expression:
                                            "{id: location.id, permits: ['locationBundle_list']}",
                                          arg: "allow"
                                        }
                                      ]
                                    },
                                    [
                                      _c("h4", { staticClass: "text-info" }, [
                                        _vm._v("Available Bundle Types:")
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "table",
                                        { staticClass: "table table-hover" },
                                        [
                                          _c("thead", [
                                            _c("tr", [
                                              _c("th", [_vm._v("Bundle Name")]),
                                              _vm._v(" "),
                                              _c("th", [_vm._v("Room Type")]),
                                              _vm._v(" "),
                                              _c("th", [
                                                _vm._v("Parking Type")
                                              ]),
                                              _vm._v(" "),
                                              _c("th", [_vm._v("Price Limit")]),
                                              _vm._v(" "),
                                              _c(
                                                "th",
                                                {
                                                  directives: [
                                                    {
                                                      name: "hasPermits",
                                                      rawName:
                                                        "v-hasPermits:allow",
                                                      value: {
                                                        id: _vm.location.id,
                                                        permits: [
                                                          "locationBundle_update"
                                                        ]
                                                      },
                                                      expression:
                                                        "{id: location.id, permits: ['locationBundle_update']}",
                                                      arg: "allow"
                                                    }
                                                  ]
                                                },
                                                [_vm._v("Edit")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "th",
                                                {
                                                  directives: [
                                                    {
                                                      name: "hasPermits",
                                                      rawName:
                                                        "v-hasPermits:allow",
                                                      value: {
                                                        id: _vm.location.id,
                                                        permits: [
                                                          "locationBundle_delete"
                                                        ]
                                                      },
                                                      expression:
                                                        "{id: location.id, permits: ['locationBundle_delete']}",
                                                      arg: "allow"
                                                    }
                                                  ]
                                                },
                                                [_vm._v("Remove")]
                                              )
                                            ])
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "tbody",
                                            _vm._l(_vm.bundleTypes, function(
                                              item
                                            ) {
                                              return _c("tr", [
                                                _vm.isLocationUser() &&
                                                item.rate_type === "L"
                                                  ? _c("td", [
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(item.name) +
                                                          "\n                      "
                                                      )
                                                    ])
                                                  : _c(
                                                      "td",
                                                      [
                                                        _c("editable-text", {
                                                          attrs: {
                                                            value: item.name
                                                          },
                                                          on: {
                                                            valueSaved: function(
                                                              data
                                                            ) {
                                                              return _vm.onEditType(
                                                                "Bundle",
                                                                data,
                                                                item,
                                                                "name"
                                                              )
                                                            }
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    ),
                                                _vm._v(" "),
                                                _c("td", [
                                                  _vm._v(
                                                    _vm._s(
                                                      item.room_type
                                                        ? item.room_type.name
                                                        : ""
                                                    )
                                                  )
                                                ]),
                                                _vm._v(" "),
                                                _c("td", [
                                                  _vm._v(
                                                    _vm._s(
                                                      item.parking_type
                                                        ? item.parking_type.name
                                                        : ""
                                                    )
                                                  )
                                                ]),
                                                _vm._v(" "),
                                                _vm.isSuperAdmin
                                                  ? _c(
                                                      "td",
                                                      [
                                                        _c("editable-text", {
                                                          attrs: {
                                                            value:
                                                              item.price_limit
                                                          },
                                                          on: {
                                                            valueSaved: function(
                                                              data
                                                            ) {
                                                              return _vm.onEditType(
                                                                "Bundle",
                                                                data,
                                                                item,
                                                                "price_limit"
                                                              )
                                                            }
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  : _c("td", [
                                                      _vm._v(
                                                        _vm._s(item.price_limit)
                                                      )
                                                    ]),
                                                _vm._v(" "),
                                                !_vm.isLocationUser() ||
                                                item.rate_type !== "L"
                                                  ? _c(
                                                      "td",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "hasPermits",
                                                            rawName:
                                                              "v-hasPermits:allow",
                                                            value: {
                                                              id:
                                                                _vm.location.id,
                                                              permits: [
                                                                "locationBundle_update"
                                                              ]
                                                            },
                                                            expression:
                                                              "{id: location.id, permits: ['locationBundle_update']}",
                                                            arg: "allow"
                                                          }
                                                        ]
                                                      },
                                                      [
                                                        _c(
                                                          "a",
                                                          {
                                                            staticClass:
                                                              "text-info",
                                                            attrs: {
                                                              href: "#"
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                $event.preventDefault()
                                                                return _vm.editBundle(
                                                                  item
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "fa fa-pencil",
                                                              attrs: {
                                                                "aria-hidden":
                                                                  "true"
                                                              }
                                                            })
                                                          ]
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                !_vm.isLocationUser() ||
                                                item.rate_type !== "L"
                                                  ? _c(
                                                      "td",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "hasPermits",
                                                            rawName:
                                                              "v-hasPermits:allow",
                                                            value: {
                                                              id:
                                                                _vm.location.id,
                                                              permits: [
                                                                "locationBundle_delete"
                                                              ]
                                                            },
                                                            expression:
                                                              "{id: location.id, permits: ['locationBundle_delete']}",
                                                            arg: "allow"
                                                          }
                                                        ]
                                                      },
                                                      [
                                                        _c(
                                                          "a",
                                                          {
                                                            staticClass:
                                                              "text-danger",
                                                            attrs: {
                                                              href: "#"
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                $event.preventDefault()
                                                                return _vm.removeBundleType(
                                                                  item
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "fa fa-times",
                                                              attrs: {
                                                                "aria-hidden":
                                                                  "true"
                                                              }
                                                            })
                                                          ]
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e()
                                              ])
                                            }),
                                            0
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                : _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "hasPermits",
                                          rawName: "v-hasPermits:allow",
                                          value: {
                                            id: _vm.location.id,
                                            permits: ["locationBundle_list"]
                                          },
                                          expression:
                                            "{id: location.id, permits: ['locationBundle_list']}",
                                          arg: "allow"
                                        }
                                      ],
                                      staticClass: "text-warning"
                                    },
                                    [_vm._v("No room types were added")]
                                  )
                            ])
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.filter == "deleted"
                        ? _c("div", [
                            _c("hr"),
                            _vm._v(" "),
                            _c("div", {}, [
                              _vm.roomTypes && _vm.roomTypes.length
                                ? _c("div", {}, [
                                    _c("h4", { staticClass: "text-info" }, [
                                      _vm._v("Deleted rooms types:")
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "table",
                                      { staticClass: "table table-hover" },
                                      [
                                        _c("thead", [
                                          _c("tr", [
                                            _c("th", [_vm._v("Room Type")]),
                                            _vm._v(" "),
                                            _c("th", [
                                              _vm._v("Number of Rooms")
                                            ]),
                                            _vm._v(" "),
                                            _c("th", [_vm._v("Recover")])
                                          ])
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "tbody",
                                          _vm._l(_vm.roomTypes, function(item) {
                                            return _c("tr", [
                                              _c("td", [
                                                _vm._v(_vm._s(item.name))
                                              ]),
                                              _vm._v(" "),
                                              _c("td", [
                                                _vm._v(
                                                  _vm._s(item.number_of_rooms)
                                                )
                                              ]),
                                              _vm._v(" "),
                                              _c("td", [
                                                _c(
                                                  "a",
                                                  {
                                                    staticClass: "text-danger",
                                                    attrs: { href: "#" },
                                                    on: {
                                                      click: function($event) {
                                                        $event.preventDefault()
                                                        return _vm.recoverRoomType(
                                                          item
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass: "fa fa-undo",
                                                      attrs: {
                                                        "aria-hidden": "true"
                                                      }
                                                    })
                                                  ]
                                                )
                                              ])
                                            ])
                                          }),
                                          0
                                        )
                                      ]
                                    )
                                  ])
                                : _c("div", { staticClass: "text-warning" }, [
                                    _vm._v("No deleted Room types")
                                  ])
                            ]),
                            _vm._v(" "),
                            _c("hr"),
                            _vm._v(" "),
                            _c("div", {}, [
                              _vm.parkingTypes && _vm.parkingTypes.length
                                ? _c("div", {}, [
                                    _c("h4", { staticClass: "text-info" }, [
                                      _vm._v("Deleted Parking types:")
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "table",
                                      { staticClass: "table table-hover" },
                                      [
                                        _c("thead", [
                                          _c("tr", [
                                            _c("th", [_vm._v("Parking Type")]),
                                            _vm._v(" "),
                                            _c("th", [
                                              _vm._v("Number of Spots")
                                            ]),
                                            _vm._v(" "),
                                            _c("th", [_vm._v("Recover")])
                                          ])
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "tbody",
                                          _vm._l(_vm.parkingTypes, function(
                                            item
                                          ) {
                                            return _c("tr", [
                                              _c("td", [
                                                _vm._v(_vm._s(item.name))
                                              ]),
                                              _vm._v(" "),
                                              _c("td", [
                                                _vm._v(
                                                  _vm._s(
                                                    item.number_of_parkings
                                                  )
                                                )
                                              ]),
                                              _vm._v(" "),
                                              _c("td", [
                                                _c(
                                                  "a",
                                                  {
                                                    staticClass: "text-danger",
                                                    attrs: { href: "#" },
                                                    on: {
                                                      click: function($event) {
                                                        $event.preventDefault()
                                                        return _vm.recoverParkingType(
                                                          item
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass: "fa fa-undo",
                                                      attrs: {
                                                        "aria-hidden": "true"
                                                      }
                                                    })
                                                  ]
                                                )
                                              ])
                                            ])
                                          }),
                                          0
                                        )
                                      ]
                                    )
                                  ])
                                : _c("div", { staticClass: "text-warning" }, [
                                    _vm._v("No deleted Parking types")
                                  ])
                            ]),
                            _vm._v(" "),
                            _c("hr"),
                            _vm._v(" "),
                            _c("div", {}, [
                              _vm.bundleTypes && _vm.bundleTypes.length
                                ? _c("div", {}, [
                                    _c("h4", { staticClass: "text-info" }, [
                                      _vm._v("Deleted Bundle Types:")
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "table",
                                      { staticClass: "table table-hover" },
                                      [
                                        _c("thead", [
                                          _c("tr", [
                                            _c("th", [_vm._v("Bundle Name")]),
                                            _vm._v(" "),
                                            _c("th", [_vm._v("Room Type")]),
                                            _vm._v(" "),
                                            _c("th", [_vm._v("Parking Type")]),
                                            _vm._v(" "),
                                            _c("th", [_vm._v("Recover")])
                                          ])
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "tbody",
                                          _vm._l(_vm.bundleTypes, function(
                                            item
                                          ) {
                                            return _c("tr", [
                                              _c("td", [
                                                _vm._v(_vm._s(item.name))
                                              ]),
                                              _vm._v(" "),
                                              _c("td", [
                                                _vm._v(
                                                  _vm._s(
                                                    item.room_type
                                                      ? item.room_type.name
                                                      : ""
                                                  )
                                                )
                                              ]),
                                              _vm._v(" "),
                                              _c("td", [
                                                _vm._v(
                                                  _vm._s(
                                                    item.parking_type
                                                      ? item.parking_type.name
                                                      : ""
                                                  )
                                                )
                                              ]),
                                              _vm._v(" "),
                                              _c("td", [
                                                _c(
                                                  "a",
                                                  {
                                                    staticClass: "text-danger",
                                                    attrs: { href: "#" },
                                                    on: {
                                                      click: function($event) {
                                                        $event.preventDefault()
                                                        return _vm.recoverBundleType(
                                                          item
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass: "fa fa-undo",
                                                      attrs: {
                                                        "aria-hidden": "true"
                                                      }
                                                    })
                                                  ]
                                                )
                                              ])
                                            ])
                                          }),
                                          0
                                        )
                                      ]
                                    )
                                  ])
                                : _c("div", { staticClass: "text-warning" }, [
                                    _vm._v("No deleted Bundle types")
                                  ])
                            ])
                          ])
                        : _vm._e()
                    ])
                  ]),
                  _vm._v(" "),
                  _c("confirm", {
                    ref: "deleteConfirm",
                    attrs: { type: "danger" }
                  }),
                  _vm._v(" "),
                  _c("check-in-out-modal", { ref: "timesRequired" })
                ],
                1
              )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-8dd019a8", { render: render, staticRenderFns: staticRenderFns })
  }
}