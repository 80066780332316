var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "main" }, [
    _c("div", { staticClass: "container header" }, [
      _c(
        "div",
        {
          staticClass:
            "flex flex-justify-between flex-align-center padding-top-2x padding-bottom-2x"
        },
        [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "div",
            [
              _c(
                "router-link",
                { staticClass: "btn btn-lg", attrs: { to: { name: "login" } } },
                [_vm._v("Login")]
              )
            ],
            1
          )
        ]
      )
    ]),
    _vm._v(" "),
    _vm._m(1),
    _vm._v(" "),
    _vm._m(2),
    _vm._v(" "),
    _c("div", { staticClass: "section section-grey" }, [
      _c("div", { staticClass: "container" }, [
        _c(
          "div",
          { staticClass: "col-sm-6 col-sm-offset-3" },
          [
            _c(
              "router-link",
              {
                staticClass: "btn btn-primary btn-lg btn-block margin-2x",
                attrs: { to: { name: "login" } }
              },
              [
                _vm._v(
                  "\n                    Have an Account? Login here\n                "
                )
              ]
            )
          ],
          1
        )
      ])
    ]),
    _vm._v(" "),
    _vm._m(3),
    _vm._v(" "),
    _c("div", { staticClass: "separator" }),
    _vm._v(" "),
    _vm._m(4),
    _vm._v(" "),
    _c(
      "footer",
      {
        staticClass: "main-footer",
        class: { "no-sidebar": _vm.noSidebar },
        staticStyle: { "margin-left": "0!important" }
      },
      [
        _c("div", { staticClass: "copyright" }, [
          _c("strong", [
            _vm._v("Copyright ©  "),
            _c("a", { attrs: { href: "/" } }, [_vm._v("Reservations Lab ")]),
            _vm._v(" " + _vm._s(_vm.year) + ".")
          ]),
          _vm._v(" All rights reserved.\n        ")
        ])
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", {
        staticClass: "rl-logo",
        attrs: { src: "/assets/logo.png", alt: "" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "hero flex flex-middle text-center" }, [
      _c("div", { staticClass: "container" }, [
        _vm._v("\n            Reservations Made Easy\n        ")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "container section" }, [
      _c("div", { staticClass: "title" }, [
        _vm._v("\n            The Future of Reservations\n        ")
      ]),
      _vm._v(" "),
      _c("div", [
        _c("p", [
          _vm._v(
            "\n                Reservations Lab focuses on maximizing revenues for a large range of products in the travel sector. It aims at taking the online travel ecosystem to the next level by providing a flexible platform that enables both the supply and demand sides of the travel industry. "
          ),
          _c("br")
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n                Reservations Lab optimizes your assets by helping you manage your facility with flexibility, supported by a machine learning approach to your data. It also helps you find new sources of revenues by matching you with the best players in the industry \n            "
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "container section" }, [
      _c("div", { staticClass: "title" }, [
        _vm._v("\n            Hotel & Parking Facility Owners\n        ")
      ]),
      _vm._v(" "),
      _c("div", [
        _c("p", [
          _vm._v(
            "\n                Do you have a hotel or a facility near a point of interest and would like to rent out travel-related auxiliary products and services such as parking, conference rooms, airport transfers, lounge passes, etc.?\n            "
          )
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n                Let's start with a conversation to find out how you can maximize revenues on your existing assets\n            "
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "container" }, [
      _c(
        "iframe",
        {
          staticClass: "sign-up-form",
          attrs: {
            src:
              "https://docs.google.com/forms/d/e/1FAIpQLSdFjIr_TCx7uzN5nMiJboP5KfzduXRqWlGRyXg4U28Qx2hM1g/viewform?embedded=true",
            height: "1070",
            frameborder: "0",
            marginheight: "0",
            marginwidth: "0",
            onload: "window.parent.parent.scrollTo(300,100)"
          }
        },
        [_vm._v("\n            Loading…\n        ")]
      )
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-5252bf00", { render: render, staticRenderFns: staticRenderFns })
  }
}