/*
* @DESC: this component makes an element editable when double click on it.
* @param value: the value that you need to change
* @param minDate: the min date of datepicker
* @param maxDate: the max date of the datepicker
* @param autoCloseOnSave: true to close the component without loading
* @callback: v-on:valueSaved="doSomething({value, close(), startLoading(), startLoading()})"
*/

import datepicker from 'components/datepicker'

const components = {
  datepicker
}

const methods = {
  cancel () {
    this.editedValue = this.cachedVal;
    this.active = false;
  },
  close () {
    this.active = false;
  },
  startLoading () {
    this.loading = true;
  },
  stopLoading () {
    this.loading = false;
  },
  save (value) {
    console.log('value', value);
    console.log('input', this.$refs.input);
    // this.initValue = value;

    this.$emit('valueSaved', {
      value,
      close: this.close,
      startLoading: this.startLoading,
      stopLoading: this.stopLoading
    });

    if (this.autoCloseOnSave) {
      this.active = false;
    }
  },
  enterActiveMode () {
    this.active = true;
    console.log('double clicked');
  }
}

const computed = {
  editedValue () {
    // if(!this.initValue || this.value != this.cachedVal) {
    //   this.initValue = _.cloneDeep(this.value);
    // }

    return _.cloneDeep(this.value)//this.initValue;
  }
}

const watch = {
  active (n) {
    if (n) {
      setTimeout(() => {
        $(this.$refs.input).focus();
      }, 100)
      this.cachedVal = _.cloneDeep(this.editedValue);
    }
    console.log(this.cachedVal);
  },
  minDate (n) {

    console.log('new value datepicker', n);
  },
  editedValue (n) {
    console.log('editedValue  ---->  ', n);
  }
}

export default {
  name: 'editable-datepicker',
  props: ['value','maxDate', 'minDate', 'autoCloseOnSave', 'showPen'],
  components,
  methods,
  computed,
  watch,
  data () {
    return {
      active: false,
      cachedVal: null,
      loading: false,
      initValue: null
    }
  },
  mounted () {

  }
}
