import { hasPermits } from 'directives'

const directives = {
  hasPermits
}

export default {
  name: 'rate-management-tabs',
  directives,
  data () {
    return {
      locId: this.$route.params.id
    }
  },
}
