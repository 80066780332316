import toastr from 'toastr'
import { funcModal } from 'components/modals'

const components = {
    funcModal
}

const methods = {
    open (activeHistory) {
        this.activeHistory = activeHistory;
        this.$refs.modal.open();
    },

    onClose () {
        this.$emit('close');
    },

    submit () {
        this.processing = true;
        this.errors = {};
        this.$store.dispatch('locations.partialRefund', {
            lId: this.activeHistory.location_id, id: this.activeHistory.reservation_id,
            data: this.form
        }).then(() => {
            if (this.$store.state.locations.errors) {
                this.errors = _.cloneDeep(this.$store.state.locations.errors.errors);
                toastr.error(this.$store.state.locations.errors.message);
            } else {
                toastr.success('Partial refund has been saved');
                this.form.amount = null;
                this.$refs.modal.close();
            }

            this.processing = false;
        });
    }
}

export default {
    name: 'partial-refund-modal',
    methods,
    components,
    data () {
        return {
            errors: {},
            activeHistory: null,
            form: {
                amount: null,
                type: "all"
            },
            processing: false
        }
    }
}