import { funcModal } from 'components/modals'

const components = {
    funcModal
}

const methods = {
    open (reservation, amount, diff_hours) {
        this.reservation = reservation;
        this.amount = amount;
        this.diff_hours = diff_hours;
        this.$refs.modal.open();
    },

    onClose () {
        this.$emit('close');
    },

    submit () {
        this.$emit("update-processing", true);
        this.$emit('update-reservation', this.type, this.reservation);
        this.$refs.modal.close();
    },
}

export default {
    name: 'extend-reservation-modal',
    props: ['processing'],
    methods,
    components,
    data () {
        return {
            reservation: null,
            amount: 0,
            diff_hours: 0,
            type: "charge",
        }
    }
}