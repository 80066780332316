var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "table-responsive" }, [
    _c("table", { staticClass: "table" }, [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "tbody",
        _vm._l(_vm.form.channels, function(channel, i) {
          return _c("tr", [
            _c("td", [
              _vm._v("\n          " + _vm._s(channel.name) + "\n        ")
            ]),
            _vm._v(" "),
            _c("td", [
              _vm._v("\n          " + _vm._s(channel.domain) + "\n        ")
            ]),
            _vm._v(" "),
            _c("td", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: channel.accept_coupon,
                    expression: "channel.accept_coupon"
                  }
                ],
                attrs: { type: "checkbox" },
                domProps: {
                  checked: Array.isArray(channel.accept_coupon)
                    ? _vm._i(channel.accept_coupon, null) > -1
                    : channel.accept_coupon
                },
                on: {
                  change: [
                    function($event) {
                      var $$a = channel.accept_coupon,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              channel,
                              "accept_coupon",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              channel,
                              "accept_coupon",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(channel, "accept_coupon", $$c)
                      }
                    },
                    function($event) {
                      return _vm.acceptCouponChanged(i)
                    }
                  ]
                }
              }),
              _vm._v(" "),
              _vm.errors["channels." + i + ".bundle_commission"]
                ? _c("div", { staticClass: "text-danger" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm.errors["channels." + i + ".bundle_commission"][0]
                        ) +
                        "\n          "
                    )
                  ])
                : _vm._e()
            ]),
            _vm._v(" "),
            _c(
              "td",
              [
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "hasPermits",
                        rawName: "v-hasPermits:prevent",
                        value: {
                          locId: _vm.$route.params.id,
                          permits: ["location_update", "super_admin"]
                        },
                        expression:
                          "{locId: $route.params.id, permits: ['location_update', 'super_admin']}",
                        arg: "prevent"
                      }
                    ]
                  },
                  [_vm._v(_vm._s(channel.coupon_limit))]
                ),
                _vm._v(" "),
                _c("editable-text", {
                  directives: [
                    {
                      name: "hasPermits",
                      rawName: "v-hasPermits:allow",
                      value: {
                        locId: _vm.$route.params.id,
                        permits: ["location_update"]
                      },
                      expression:
                        "{locId: $route.params.id, permits: ['location_update']}",
                      arg: "allow"
                    }
                  ],
                  attrs: { value: channel.coupon_limit },
                  on: {
                    valueSaved: function($event) {
                      return _vm.couponLimitChanged($event, i)
                    }
                  }
                }),
                _vm._v(" "),
                _vm.errors["channels." + i + ".coupon_limit"]
                  ? _c("div", { staticClass: "text-danger" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.errors["channels." + i + ".coupon_limit"][0]
                          ) +
                          "\n          "
                      )
                    ])
                  : _vm._e()
              ],
              1
            ),
            _vm._v(" "),
            _c("td", [
              _c("div", { staticClass: "input-group radio" }, [
                _c("label", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: channel.coupon_limit_type,
                        expression: "channel.coupon_limit_type"
                      }
                    ],
                    attrs: { type: "radio", value: "percentage" },
                    domProps: {
                      checked: _vm._q(channel.coupon_limit_type, "percentage")
                    },
                    on: {
                      change: function($event) {
                        return _vm.$set(
                          channel,
                          "coupon_limit_type",
                          "percentage"
                        )
                      }
                    }
                  }),
                  _vm._v("\n              %\n            ")
                ]),
                _vm._v("\n             \n            "),
                _c("label", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: channel.coupon_limit_type,
                        expression: "channel.coupon_limit_type"
                      }
                    ],
                    attrs: { type: "radio", value: "fixed" },
                    domProps: {
                      checked: _vm._q(channel.coupon_limit_type, "fixed")
                    },
                    on: {
                      change: function($event) {
                        return _vm.$set(channel, "coupon_limit_type", "fixed")
                      }
                    }
                  }),
                  _vm._v("\n              $\n            ")
                ])
              ])
            ])
          ])
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("\n          Channel Name\n        ")]),
        _vm._v(" "),
        _c("th", [_vm._v("\n          Channel Domain\n        ")]),
        _vm._v(" "),
        _c("th", [_vm._v("\n          Accept Coupon\n        ")]),
        _vm._v(" "),
        _c("th", [_vm._v("\n          Coupon Limit\n        ")]),
        _vm._v(" "),
        _c("th", [_vm._v("\n          Coupon Limit Type\n        ")])
      ])
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-5d4bb2a6", { render: render, staticRenderFns: staticRenderFns })
  }
}