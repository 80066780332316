import _ from 'lodash'
import toastr from 'toastr'
import layout from 'layouts/default'
import { hasPermits } from 'directives'

const directives = {
  hasPermits
}

const components = {
  layout
}

const computed = {
  parking_types () {
    let types = _.filter(this.parkingTypes, item => item.id != this.form.id && !(item.parent_id));
    return _.map(types, (item) => {
      return {id: item.id, name: item.name};
    });
  },

  types () {
    return _.map(this.$store.state.types.parkings, (item) => {
      return {id: item.id, name: item.name};
    });
  },

  parkingTypes () {
    return _.cloneDeep(this.$store.state.locations.active.parkingTypes);
  },

  totalParkingsNumber () {
    return _.cloneDeep(this.$store.state.locations.active.number_of_parkings);
  }
}

const methods = {
  submit () {
    this.errors = {};

    let data = _.cloneDeep(this.form);
    data.location_id = this.$route.params.id;
    data.percentage = this.form.percentage ? this.form.percentage : 0;
    if (this.form.parent_id && this.form.percentage != 1) {
      data.number_of_parkings = this.form.number_of_parkings === "" ? -1 : this.form.number_of_parkings;
    }

    this.processing = true;
    
    this.$store.dispatch('locations.updateParkingType', {id: this.form.id, data}).then(() => {
      this.processing = false;
      if (this.$store.state.locations.errors) {
        this.errors = _.cloneDeep(this.$store.state.locations.errors.errors);
        toastr.error(this.$store.state.locations.errors.message);
      } else {
        toastr.success('Data has been saved');
      }
    });
  },
}

const watch = {
  ['link_with_parent'] (n, o) {
    if (n == o) return;

    this.form.percentage = this.location_parking_type.percentage;
    this.form.parent_id = this.location_parking_type.parent_id;
  },

  ['form.percentage'] (n, o) {
    if (n == o) return;
    if (n == 1) {
      this.form.related_to_lease = 0;
      this.$nextTick(() => {
        this.form.related_to_lease = 0;
      });
    }
  }
}

export default {
  name: 'edit-parkings-type',
  components,
  computed,
  methods,
  watch,
  directives,
  data () {
    return {
      loading: true,
      processing: false,
      errors: {},
      link_with_parent: false,
      location_parking_type: null,
      form: null,
    }
  },

  created () {
    this.$store.dispatch('locations.getLocationParkingType', {id : this.$route.params.type_id}).then(() => {
      this.loading = false;
      this.form = _.cloneDeep(this.$store.state.locations.active.parkingType);
      this.link_with_parent = this.form.parent_id ? true : false;
      this.location_parking_type = _.cloneDeep(this.$store.state.locations.active.parkingType);
      if (this.form.number_of_parkings == -1) {
        this.form.number_of_parkings = "";
      }
    });
    this.$store.dispatch('locations.parkingTypes', {id: this.$route.params.id});
    this.$store.dispatch('types.getParkings', this.$route.params.id);
  }
}
