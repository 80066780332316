import { hasPermits } from 'directives'

const directives = {
  hasPermits
}

const computed = {
  bedTypes () {
    return _.cloneDeep(this.$store.state.types.bedTypes);
  },
}

const methods = {
  addBedType () {
    this.formData.beds.push({
      id: null,
      number_of_beds: 1
    });
  },
  removeBedType (i) {
    this.formData.beds.splice(i, 1);
  }
}

export default {
  name: 'room-type-form',
  props: ['formData', 'errors'],
  computed,
  methods,
  directives
}
