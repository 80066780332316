import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import { permits } from 'services'

Vue.use(VueRouter)

//vue router options

export function createRouter () {
  let router = new VueRouter({
    hashbang: true,
    mode: 'history',
    routes, // short for routes: routes
  })

  return router;
}

createRouter().beforeEach((to, from, next) => {
  if (to.meta.permits && !isServer) {
    if (permits.hasPermits(to.meta.permits)) {
      next();
    } else {
      next(false);
    }

    return;
  }

  next();
});
