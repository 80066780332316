import toastr from 'toastr'
import { hasPermits } from 'directives'
import { editableText } from 'components/editable'

const directives = { 
  hasPermits
}

const watch = {

}

const components = {
  editableText,
  toastr
}

const methods = {
  acceptCouponChanged (index) {
    let acceptCoupon = this.form.channels[index]['accept_coupon'];
    let couponLimit = this.form.channels[index]['coupon_limit'];
    if (acceptCoupon && !couponLimit) {
      this.form.channels[index]['coupon_limit'] = 6;
    }
  },

	couponLimitChanged(event, index) {
    let errors = {};
    this.$emit('throwErrors', errors);
    if (isNaN(event.value)) {
      let key = `channels.${index}.coupon_limit`;
      errors[key] = [];
      errors[key].push("Coupon Limit can only be a number")
      this.$emit('throwErrors', errors);
      return;
    }

    event.startLoading();
    let value = Number(event.value);
    this.form.channels[index]['coupon_limit'] = value;
    // this.channels[index]['coupon_limit'] = value;
    

    event.stopLoading();
    event.close();

	},
}
export default {
  name: "coupons-tab",
  props: ['form', 'errors'],
  methods,
  components,
  directives,
  data () {
  	return {
  		channels: this.form.channels,
  	}
  },

	mounted () {
		console.log(this.form.channels);
	}

}
