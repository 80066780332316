import layout from 'layouts/default'
import { hasPermits } from 'directives'
import moment from 'moment'
import tabs from '../tabs'
import { exportReport } from 'components/modals'
import datatable from 'components/datatable'

const directives = {
  hasPermits
}

const components = {
  layout,
  tabs,
  exportReport,
  datatable
}

const computed = {
}

const methods = {
  exportReport (ext) {
    var params = '';
    let sort_by = sessionStorage.getItem('sort_by');
    let sort_dir = sessionStorage.getItem('sort_dir');
    if (sort_by && sort_dir) {
      params += '?_sort=' + (sort_dir == 'desc' ? '-' : '') + sort_by;
    }
    this.$refs.export.open(this.url + params, ext);
    return;
  },

  setCols () {
    this.columns = [
      {name: "Reservation Number", object_name: "reservation_number", sortable: true, sort_key: "reservation_number"},
      {name: "From", object_name: (obj) => {
        return moment(obj.start_date, ['YYYY-MM-DD HH:mm:ss']).format('MM/DD/YYYY hh:mm A');
      }, sortable: true, sort_key: "from_date"},
      {name: "To", object_name: (obj) => {
        return moment(obj.end_date, ['YYYY-MM-DD HH:mm:ss']).format('MM/DD/YYYY hh:mm A');
      }, sortable: true, sort_key: "to_date"},
      {name: "License Plate", object_name: "license_plate", visible: this.has_license_plate},
    ]
  },

  loadData () {
    this.processing = true;
    if (this.page > 1) {
      let query = _.cloneDeep(this.$route.query);
      delete query.page;
      this.$router.replace({ query });
    }
    this.$store.dispatch('locations.get', {id: this.$route.params.id}).then(() => {
      this.selected_location = this.$store.state.locations.active;
      this.date = moment(moment(), ['YYYY-MM-DD HH:mm:ss']).tz(this.selected_location.timezone.code).format('YYYY-MM-DD HH:mm');
      if (this.selected_location.extra_fields.some(extra_field => extra_field.input_type == 'license_plate' || 
        (extra_field.input_type == 'text' && extra_field.name.includes('license')))) {
        this.has_license_plate = true;
      } else {
        this.has_license_plate = false;
      }
    });
    this.$store.dispatch('locations.getInHouseReportTotals', { location_id: this.$route.params.id }).then(() => {
      let data = _.cloneDeep(this.$store.state.locations.in_house_report_totals);
      this.total_checkin = data.total_checkin;
      this.total_checkout = data.total_checkout;
      this.total_cars = data.number_of_cars;
      this.processing = false;
    });
    this.setCols();
  },

  pageChanged(page) {
    this.page = page;
  }
}

const watch ={
  has_license_plate (n, o) {
    if (n == o) return;
    this.setCols();
  }
}

export default {
  name: 'in-house-report',
  methods,
  components,
  computed,
  directives,
  watch,
  data () {
    return {
      processing: false,
      page: 1,
      url: 'locations/' + this.$route.params.id + '/in-house-report',
      locations: [],
      selected_location: null,
      date: null,
      total_checkin: 0,
      total_checkout: 0,
      total_cars: 0,
      // errors: {},
      columns: [],
      has_license_plate: false,
    }
  },

  created () {
    this.loadData();
  },
}
