var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _vm.loading
        ? _c("div", { staticClass: "text-center" }, [
            _c("i", { staticClass: "fa fa-spinner fa-spin fa-3x fa-fw" })
          ])
        : _c("div", { staticClass: "table-responsive" }, [
            _c("table", { class: _vm.tableClass }, [
              _c("thead", [
                _c(
                  "tr",
                  _vm._l(_vm.columns, function(column) {
                    return column.visible
                      ? _c(
                          "th",
                          {
                            directives: [
                              {
                                name: "hasPermits",
                                rawName: "v-hasPermits:allow",
                                value: column.permits,
                                expression: "column.permits",
                                arg: "allow"
                              }
                            ],
                            on: {
                              click: function($event) {
                                return _vm._sort(
                                  column.sort_key,
                                  column.sortable
                                )
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(column.name) +
                                "\n          "
                            ),
                            column.sortable
                              ? _c("span", {
                                  staticClass:
                                    "glyphicon glyphicon glyphicon-sort",
                                  class: {
                                    "glyphicon-sort-by-alphabet":
                                      _vm.sort_by === column.sort_key &&
                                      _vm.sort_dir === "asc",
                                    "glyphicon-sort-by-alphabet-alt":
                                      _vm.sort_by === column.sort_key &&
                                      _vm.sort_dir === "desc"
                                  }
                                })
                              : _vm._e()
                          ]
                        )
                      : _vm._e()
                  }),
                  0
                )
              ]),
              _vm._v(" "),
              _c(
                "tbody",
                _vm._l(_vm.rows, function(row, r) {
                  return _c(
                    "tr",
                    { attrs: { id: "row" + r } },
                    _vm._l(_vm.columns, function(column, c) {
                      return column.visible
                        ? _c(
                            "td",
                            {
                              directives: [
                                {
                                  name: "hasPermits",
                                  rawName: "v-hasPermits:allow",
                                  value: column.permits,
                                  expression: "column.permits",
                                  arg: "allow"
                                }
                              ],
                              class: column.classes,
                              style: [
                                row.read_request
                                  ? { "font-weight": "normal" }
                                  : { "font-weight": "bold" }
                              ],
                              attrs: { id: "column" + r + "-" + c },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return column.click(row)
                                },
                                hover: function($event) {
                                  $event.preventDefault()
                                  return column.hover(row)
                                }
                              }
                            },
                            [
                              _vm._.isFunction(column.object_name)
                                ? _c("div", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        column.object_name(row, _vm.$parent)
                                      )
                                    }
                                  })
                                : _c("div", [
                                    column.object_name == "old_values" ||
                                    column.object_name == "new_values"
                                      ? _c(
                                          "div",
                                          [
                                            _c("json-viewer", {
                                              attrs: {
                                                value: _vm.objectPath.get(
                                                  row,
                                                  column.object_name
                                                )
                                              }
                                            }),
                                            _vm._v(" "),
                                            _vm.heightOver(r, c)
                                              ? _c(
                                                  "span",
                                                  {
                                                    class: [
                                                      "ellipsis-btn",
                                                      "ellipsis-btn-" + r
                                                    ],
                                                    attrs: {
                                                      id:
                                                        "ellipsis-btn-" + r + c,
                                                      title:
                                                        "click to show hidden items"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.showHiddenItems(
                                                          r
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "fa fa-angle-double-down",
                                                      attrs: {
                                                        "aria-hidden": "true"
                                                      }
                                                    })
                                                  ]
                                                )
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      : column.object_name ==
                                          "reservation_number" &&
                                        _vm.$route.fullPath.includes("reports")
                                      ? _c("div", [
                                          _c(
                                            "a",
                                            {
                                              attrs: { href: "#" },
                                              on: {
                                                click: function($event) {
                                                  $event.preventDefault()
                                                  _vm.loadReservation(
                                                    _vm.objectPath.get(
                                                      row,
                                                      column.object_name
                                                    )
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.objectPath.get(
                                                      row,
                                                      column.object_name
                                                    )
                                                  ) +
                                                  "\n                "
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _vm.loadings[
                                            _vm.objectPath.get(
                                              row,
                                              column.object_name
                                            )
                                          ]
                                            ? _c("span", [
                                                _c("i", {
                                                  staticClass:
                                                    "fa fa-spin fa-spinner"
                                                })
                                              ])
                                            : _vm._e()
                                        ])
                                      : _c("div", [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm.objectPath.get(
                                                  row,
                                                  column.object_name
                                                )
                                              ) +
                                              "\n              "
                                          )
                                        ])
                                  ])
                            ]
                          )
                        : _vm._e()
                    }),
                    0
                  )
                }),
                0
              )
            ])
          ]),
      _vm._v(" "),
      _c("reservation-details", {
        ref: "reservationDetailsModal",
        attrs: { reservation: _vm.activeReservation }
      }),
      _vm._v(" "),
      _vm.pages > 1
        ? _c("pagination", {
            attrs: { "pages-number": _vm.pages },
            on: {
              pageChanged: function(page) {
                return _vm.loadPage(page)
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-7782dff2", { render: render, staticRenderFns: staticRenderFns })
  }
}