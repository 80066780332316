/*
* @INFO: This component runs a bootstrap modal
* @PROP label: the text that will be shown on the button
* @PROP title: the text that will be shown on the modal header
* @PROP shouldRun: a flag to tell if the modal should run or not
* @PROP backdrop: a flag, if set to false, the modal can't be closed when clicking on the screen
* @PROP btnClass: the class attribute of the button
* @PROP modalClass: the class attribute of the modal
* @PROP size: modal size ("sm" for small, "lg" for large, or don't pass it for normal)
* @NOTE: you can use the following to close the modal in the parent component:
*        <template slot="footer(or body)" slot-scope="props">
*         <button @click="props.close()"></button>
*        </template>
*/

const computed = {
  canShow () {
    console.log(typeof this.shouldRun);
    return typeof this.shouldRun == 'undefined' ? true : this.shouldRun
  }
}

const methods = {
  open () {
    // console.log(this.canShow);
    if (this.canShow) {
      $(this.$refs.modal).modal({
        backdrop: this.backdrop
      });

    } else {
      $(this.$refs.modal).modal("hide");
    }
  },
  close () {
    $(this.$refs.modal).modal("hide");
  }
}

export default {
  props: ['label', 'title', 'shouldRun', 'backdrop', 'btnClass', 'modalClass', 'size'],
  computed,
  methods,
  data () {
    return {
      shown: false
    }
  },
  mounted () {
    // $(this.$refs.modal).on('hidden.bs.modal', (function (e) {
    //   for (let i in this.$parent.$data) {
    //     NOTE: this is not working
    //     this.$parent.$data[i] = null;
    //   }
    // }).bind(this))
  }
}
