
import { http } from 'services'

//analytics
//ANALYTICS
// initial state
const state = () => ({
  all: [],
  salesPerformance: {
    demand_line: null,
    conversion_rate: null,
    revenue: null,
  },
  channels_contribution: null,
  occupancy: {
    room: null,
    parking: null,
  },
  errors: null
})

// getters
const getters = {

}

// actions
const actions = {
  
  ['analytics.salesPerformance.demandLine'] ({ commit, state }, {id, params}) {
   
    commit('CLEAR_ERRORS')
    params.type = 'demand-line';

    return (http.get(`locations/${id}/analytics/sales-performance`, {
      params
    })).then ((res) => {
      commit('GET_ANALYTICS_SALES_PERFORMANCE_DEMAND_LINE', res);
    }).catch ((res) => {
      commit('ANALYTICS_ERROR', res);
    })
  },

  ['analytics.salesPerformance.revenue'] ({ commit, state }, {id, params}) {
   
    commit('CLEAR_ERRORS')
    params.type = 'revenue';

    return (http.get(`locations/${id}/analytics/sales-performance`, {
      params
    })).then ((res) => {
      commit('GET_ANALYTICS_SALES_PERFORMANCE_REVENUE', res);
    }).catch ((res) => {
      commit('ANALYTICS_ERROR', res);
    })
  },

  ['analytics.salesPerformance.conversionRate'] ({ commit, state }, {id, params}) {
   
    commit('CLEAR_ERRORS')
    params.type = 'conversion-rate';

    return (http.get(`locations/${id}/analytics/sales-performance`, {
      params
    })).then ((res) => {
      commit('GET_ANALYTICS_SALES_CONVERSION_RATE', res);
    }).catch ((res) => {
      commit('ANALYTICS_ERROR', res);
    })
  },
  ['analytics.contributions'] ({ commit, state }, {id, params}) {
   
    commit('CLEAR_ERRORS')
    
    return (http.get(`locations/${id}/analytics/contributions`, {
      params
    })).then ((res) => {
      commit('GET_ANALYTICS_CHANNEL_CONTRIBUTIONS', res);
    }).catch ((res) => {
      commit('ANALYTICS_ERROR', res);
    })
  },

  ['analytics.occupancy.room'] ({ commit, state }, {id, params}) {
    commit('CLEAR_ERRORS')
    params.type = 'room';

    return (http.get(`locations/${id}/analytics/occupancy`, {
      params
    })).then ((res) => {
      commit('GET_ROOM_OCCUPANCY', res);
    }).catch ((res) => {
      commit('ANALYTICS_ERROR', res);
    })
  },

  ['analytics.occupancy.parking'] ({ commit, state }, {id, params}) {
    
    commit('CLEAR_ERRORS')
    params.type = 'parking';

    return (http.get(`locations/${id}/analytics/occupancy`, {
      params
    })).then ((res) => {
      commit('GET_PARKING_OCCUPANCY', res);
    }).catch ((res) => {
      commit('ANALYTICS_ERROR', res);
    })
  }


}

// mutations
const mutations = {
  GET_ANALYTICS_SALES_PERFORMANCE_DEMAND_LINE (state, res) {
    state.salesPerformance.demand_line = res.data;
  },

  GET_ANALYTICS_SALES_CONVERSION_RATE (state, res) {
    state.salesPerformance.conversion_rate = res.data;
  },

  GET_ANALYTICS_SALES_PERFORMANCE_REVENUE (state, res) {
    state.salesPerformance.revenue = res.data;
  },

  GET_ANALYTICS_CHANNEL_CONTRIBUTIONS (state, res) {
    state.channels_contribution = res.data;
  },

  GET_PARKING_OCCUPANCY (state, res) {
    state.occupancy.parking = res.data.parking;
  },

  GET_ROOM_OCCUPANCY (state, res) {
    state.occupancy.room = res.data.room;
  },

  
  //ERRORS
  ANALYTICS_ERROR (state, err) {
    try {
      state.errors = err.response.data;
    } catch (e) {
      state.errors = err;
    }
  },

  CLEAR_ERRORS (state) {
    state.errors = null
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
