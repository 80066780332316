var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.loading
        ? _c("div", { staticClass: "text-center" }, [
            _c("i", { staticClass: "fa fa-spinner fa-spin fa-3x fa-fw" })
          ])
        : _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-3 col-sm-6" }, [
              _c("div", { staticClass: "box" }, [
                _c("div", { staticClass: "box-header with-border" }, [
                  _vm._v("Actions")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "box-body radio" }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "hasPermits",
                          rawName: "v-hasPermits:allow",
                          value: {
                            id: _vm.locId,
                            permits: ["locationRate_adjustment_create"]
                          },
                          expression:
                            "{id: locId, permits: ['locationRate_adjustment_create']}",
                          arg: "allow"
                        }
                      ],
                      staticClass: "margin-bottom-1x"
                    },
                    [
                      _c("label", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.type,
                              expression: "type"
                            }
                          ],
                          attrs: { type: "radio", value: "adjustment" },
                          domProps: { checked: _vm._q(_vm.type, "adjustment") },
                          on: {
                            change: function($event) {
                              _vm.type = "adjustment"
                            }
                          }
                        }),
                        _vm._v(
                          "\n                Rate Adjustment (Rooms/Parking)\n              "
                        )
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "hasPermits",
                          rawName: "v-hasPermits:allow",
                          value: {
                            id: _vm.locId,
                            permits: ["locationRate_adjustment_create"]
                          },
                          expression:
                            "{id: locId, permits: ['locationRate_adjustment_create']}",
                          arg: "allow"
                        }
                      ],
                      staticClass: "margin-bottom-1x"
                    },
                    [
                      _c("label", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.type,
                              expression: "type"
                            }
                          ],
                          attrs: { type: "radio", value: "adjustment_bundles" },
                          domProps: {
                            checked: _vm._q(_vm.type, "adjustment_bundles")
                          },
                          on: {
                            change: function($event) {
                              _vm.type = "adjustment_bundles"
                            }
                          }
                        }),
                        _vm._v(
                          "\n                Rate Adjustment (Bundles)\n              "
                        )
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "hasPermits",
                          rawName: "v-hasPermits:allow",
                          value: {
                            id: _vm.locId,
                            permits: ["longTermDiscount_create"]
                          },
                          expression:
                            "{id: locId, permits: ['longTermDiscount_create']}",
                          arg: "allow"
                        }
                      ]
                    },
                    [
                      _c("label", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.type,
                              expression: "type"
                            }
                          ],
                          attrs: { type: "radio", value: "long_term" },
                          domProps: { checked: _vm._q(_vm.type, "long_term") },
                          on: {
                            change: function($event) {
                              _vm.type = "long_term"
                            }
                          }
                        }),
                        _vm._v(
                          "\n                Long Term Discounts\n              "
                        )
                      ])
                    ]
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "box" }, [
                _c("div", { staticClass: "box-header with-border" }, [
                  _vm._v("Dates & Days")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "box-body" }, [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("label", [_vm._v("From Date")]),
                      _vm._v(" "),
                      _c("datepicker", {
                        staticClass: "form-control",
                        model: {
                          value: _vm.form.from_date,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "from_date", $$v)
                          },
                          expression: "form.from_date"
                        }
                      }),
                      _vm._v(" "),
                      _vm.errors.from_date
                        ? _c("div", { staticClass: "text-danger" }, [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.errors.from_date[0]) +
                                "\n              "
                            )
                          ])
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("label", [_vm._v("To Date")]),
                      _vm._v(" "),
                      _c("datepicker", {
                        staticClass: "form-control",
                        attrs: { "min-date": _vm.form.from_date },
                        model: {
                          value: _vm.form.to_date,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "to_date", $$v)
                          },
                          expression: "form.to_date"
                        }
                      }),
                      _vm._v(" "),
                      _vm.errors.to_date
                        ? _c("div", { staticClass: "text-danger" }, [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.errors.to_date[0]) +
                                "\n              "
                            )
                          ])
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.type != "long_term" && _vm.type != "adjustment_bundles"
                    ? _c(
                        "div",
                        {},
                        [
                          _c("label", [_vm._v("Days of Week")]),
                          _vm._v(" "),
                          _vm._l(_vm.daysOfWeek, function(day) {
                            return _c("div", { staticClass: "checkbox" }, [
                              _c("label", [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.selected_days[day],
                                      expression: "selected_days[day]"
                                    }
                                  ],
                                  class: !_vm.canUncheckDay(day)
                                    ? "disabled"
                                    : "",
                                  attrs: {
                                    type: "checkbox",
                                    disabled: !_vm.canUncheckDay(day)
                                  },
                                  domProps: {
                                    value: day,
                                    checked: Array.isArray(
                                      _vm.selected_days[day]
                                    )
                                      ? _vm._i(_vm.selected_days[day], day) > -1
                                      : _vm.selected_days[day]
                                  },
                                  on: {
                                    change: function($event) {
                                      var $$a = _vm.selected_days[day],
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = day,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.selected_days,
                                              day,
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.selected_days,
                                              day,
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(_vm.selected_days, day, $$c)
                                      }
                                    }
                                  }
                                }),
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(_vm._f("capitalize")(day)) +
                                    "  \n                "
                                )
                              ])
                            ])
                          })
                        ],
                        2
                      )
                    : _vm._e()
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-md-3 col-sm-6" }, [
              _c("div", { staticClass: "box" }, [
                _c("div", { staticClass: "box-header with-border" }, [
                  _vm._v("Channels")
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "box-body" },
                  [
                    _vm.errors.channels
                      ? _c("div", { staticClass: "text-danger" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.errors.channels[0]) +
                              "\n            "
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.hasSelectChannelsPermit
                      ? _c("div", [
                          _c(
                            "a",
                            {
                              attrs: { href: "#" },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.selectAll("channels")
                                }
                              }
                            },
                            [_vm._v("All")]
                          ),
                          _vm._v("  \n              "),
                          _c(
                            "a",
                            {
                              attrs: { href: "#" },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.selectNone("channels")
                                }
                              }
                            },
                            [_vm._v("None")]
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm._l(_vm.location.channels, function(channel) {
                      return _c("div", { staticClass: "checkbox" }, [
                        _c("label", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form.channels,
                                expression: "form.channels"
                              }
                            ],
                            attrs: {
                              disabled: !_vm.hasSelectChannelsPermit,
                              type: "checkbox"
                            },
                            domProps: {
                              value: channel.id,
                              checked: Array.isArray(_vm.form.channels)
                                ? _vm._i(_vm.form.channels, channel.id) > -1
                                : _vm.form.channels
                            },
                            on: {
                              change: function($event) {
                                var $$a = _vm.form.channels,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = channel.id,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.form,
                                        "channels",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.form,
                                        "channels",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(_vm.form, "channels", $$c)
                                }
                              }
                            }
                          }),
                          _vm._v(
                            "\n                " +
                              _vm._s(channel.channel.name) +
                              " (" +
                              _vm._s(channel.channel.domain) +
                              ")\n              "
                          )
                        ])
                      ])
                    })
                  ],
                  2
                )
              ]),
              _vm._v(" "),
              _vm.type != "adjustment_bundles"
                ? _c("div", { staticClass: "box" }, [
                    _c("div", { staticClass: "box-header with-border" }, [
                      _vm._v("Room Types")
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "box-body" },
                      [
                        _vm.errors.room_types
                          ? _c("div", { staticClass: "text-danger" }, [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.errors.room_types[0]) +
                                  "\n          "
                              )
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            attrs: { href: "#" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.selectAll("roomTypes")
                              }
                            }
                          },
                          [_vm._v("All")]
                        ),
                        _vm._v("  \n          "),
                        _c(
                          "a",
                          {
                            attrs: { href: "#" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.selectNone("roomTypes")
                              }
                            }
                          },
                          [_vm._v("None")]
                        ),
                        _vm._v(" "),
                        _vm._l(_vm.roomTypes, function(type) {
                          return _c("div", { staticClass: "checkbox" }, [
                            _c("label", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.form.room_types,
                                    expression: "form.room_types"
                                  }
                                ],
                                attrs: { type: "checkbox" },
                                domProps: {
                                  value: type.id,
                                  checked: Array.isArray(_vm.form.room_types)
                                    ? _vm._i(_vm.form.room_types, type.id) > -1
                                    : _vm.form.room_types
                                },
                                on: {
                                  change: function($event) {
                                    var $$a = _vm.form.room_types,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = type.id,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            _vm.form,
                                            "room_types",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            _vm.form,
                                            "room_types",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(_vm.form, "room_types", $$c)
                                    }
                                  }
                                }
                              }),
                              _vm._v(
                                "\n              " +
                                  _vm._s(type.name) +
                                  "\n            "
                              )
                            ])
                          ])
                        })
                      ],
                      2
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.type != "adjustment_bundles"
                ? _c("div", { staticClass: "box" }, [
                    _c("div", { staticClass: "box-header with-border" }, [
                      _vm._v("Parking Types")
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "box-body" },
                      [
                        _vm.errors.parking_types
                          ? _c("div", { staticClass: "text-danger" }, [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.errors.parking_types[0]) +
                                  "\n        "
                              )
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            attrs: { href: "#" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.selectAll("parkingTypes")
                              }
                            }
                          },
                          [_vm._v("All")]
                        ),
                        _vm._v("  \n        "),
                        _c(
                          "a",
                          {
                            attrs: { href: "#" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.selectNone("parkingTypes")
                              }
                            }
                          },
                          [_vm._v("None")]
                        ),
                        _vm._v(" "),
                        _vm._l(_vm.parkingTypes, function(type) {
                          return _c("div", { staticClass: "checkbox" }, [
                            _c("label", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.form.parking_types,
                                    expression: "form.parking_types"
                                  }
                                ],
                                attrs: { type: "checkbox" },
                                domProps: {
                                  value: type.id,
                                  checked: Array.isArray(_vm.form.parking_types)
                                    ? _vm._i(_vm.form.parking_types, type.id) >
                                      -1
                                    : _vm.form.parking_types
                                },
                                on: {
                                  change: [
                                    function($event) {
                                      var $$a = _vm.form.parking_types,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = type.id,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.form,
                                              "parking_types",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.form,
                                              "parking_types",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(_vm.form, "parking_types", $$c)
                                      }
                                    },
                                    function($event) {
                                      return _vm.parkingTypeChanged($event)
                                    }
                                  ]
                                }
                              }),
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    type.name +
                                      "" +
                                      (type.has_automation ? " *" : "")
                                  ) +
                                  "\n          "
                              )
                            ])
                          ])
                        }),
                        _vm._v(" "),
                        _vm.typesContainsDynamicAdj
                          ? _c("div", [
                              _c("span", [_vm._v("* (has Automation)")])
                            ])
                          : _vm._e()
                      ],
                      2
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.type != "adjustment" && _vm.type != "long_term"
                ? _c("div", { staticClass: "box" }, [
                    _c("div", { staticClass: "box-header with-border" }, [
                      _vm._v("Bundle Types")
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "box-body" },
                      [
                        _vm.errors.bundle_types
                          ? _c("div", { staticClass: "text-danger" }, [
                              _vm._v(
                                "\n        " +
                                  _vm._s(_vm.errors.bundle_types[0]) +
                                  "\n      "
                              )
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            attrs: { href: "#" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.selectAll("bundleTypes")
                              }
                            }
                          },
                          [_vm._v("All")]
                        ),
                        _vm._v("  \n      "),
                        _c(
                          "a",
                          {
                            attrs: { href: "#" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.selectNone("bundleTypes")
                              }
                            }
                          },
                          [_vm._v("None")]
                        ),
                        _vm._v(" "),
                        _vm._l(_vm.bundleTypes, function(type) {
                          return _c("div", { staticClass: "checkbox" }, [
                            _c("label", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.form.bundle_types,
                                    expression: "form.bundle_types"
                                  }
                                ],
                                attrs: { type: "checkbox" },
                                domProps: {
                                  value: type.id,
                                  checked: Array.isArray(_vm.form.bundle_types)
                                    ? _vm._i(_vm.form.bundle_types, type.id) >
                                      -1
                                    : _vm.form.bundle_types
                                },
                                on: {
                                  change: function($event) {
                                    var $$a = _vm.form.bundle_types,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = type.id,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            _vm.form,
                                            "bundle_types",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            _vm.form,
                                            "bundle_types",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(_vm.form, "bundle_types", $$c)
                                    }
                                  }
                                }
                              }),
                              _vm._v(
                                "\n          " +
                                  _vm._s(type.name) +
                                  "\n        "
                              )
                            ])
                          ])
                        })
                      ],
                      2
                    )
                  ])
                : _vm._e()
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-sm-6" }, [
              _c("div", { staticClass: "box" }, [
                _c("div", { staticClass: "box-header with-border" }, [
                  _vm._v("Rate Modification")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "box-body" }, [
                  _vm.type == "adjustment" || _vm.type == "adjustment_bundles"
                    ? _c("div", {}, [
                        _c(
                          "div",
                          { staticClass: "form-input margin-bottom-3x" },
                          [
                            _c("label", [_vm._v("Type of Change")]),
                            _vm._v(" "),
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.adjustmentForm.type,
                                    expression: "adjustmentForm.type"
                                  }
                                ],
                                staticClass: "form-control",
                                on: {
                                  change: function($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call($event.target.options, function(o) {
                                        return o.selected
                                      })
                                      .map(function(o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      _vm.adjustmentForm,
                                      "type",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  }
                                }
                              },
                              [
                                _c("option", { attrs: { value: "increase" } }, [
                                  _vm._v("Increase Price")
                                ]),
                                _vm._v(" "),
                                _c("option", { attrs: { value: "decrease" } }, [
                                  _vm._v("Decrease Price")
                                ])
                              ]
                            ),
                            _vm._v(" "),
                            _vm.errors.type
                              ? _c("div", { staticClass: "text-danger" }, [
                                  _vm._v(
                                    "\n          " +
                                      _vm._s(_vm.errors.type[0]) +
                                      "\n        "
                                  )
                                ])
                              : _vm._e()
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "form-input margin-bottom-3x" },
                          [
                            _c("label", [_vm._v("Unit of Measure")]),
                            _vm._v(" "),
                            _c("div", { staticClass: "radio" }, [
                              _c("label", { staticClass: "margin-right-2x" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.adjustmentForm.unit,
                                      expression: "adjustmentForm.unit"
                                    }
                                  ],
                                  attrs: { type: "radio", value: "percentage" },
                                  domProps: {
                                    checked: _vm._q(
                                      _vm.adjustmentForm.unit,
                                      "percentage"
                                    )
                                  },
                                  on: {
                                    change: function($event) {
                                      return _vm.$set(
                                        _vm.adjustmentForm,
                                        "unit",
                                        "percentage"
                                      )
                                    }
                                  }
                                }),
                                _vm._v(
                                  "\n            Percentage (%)\n          "
                                )
                              ]),
                              _vm._v(" "),
                              _c("label", [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.adjustmentForm.unit,
                                      expression: "adjustmentForm.unit"
                                    }
                                  ],
                                  attrs: { type: "radio", value: "fixed" },
                                  domProps: {
                                    checked: _vm._q(
                                      _vm.adjustmentForm.unit,
                                      "fixed"
                                    )
                                  },
                                  on: {
                                    change: function($event) {
                                      return _vm.$set(
                                        _vm.adjustmentForm,
                                        "unit",
                                        "fixed"
                                      )
                                    }
                                  }
                                }),
                                _vm._v("\n            Fixed ($)\n          ")
                              ])
                            ]),
                            _vm._v(" "),
                            _vm.errors.unit
                              ? _c("div", { staticClass: "text-danger" }, [
                                  _vm._v(
                                    "\n          " +
                                      _vm._s(_vm.errors.unit[0]) +
                                      "\n        "
                                  )
                                ])
                              : _vm._e()
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "form-input margin-bottom-3x" },
                          [
                            _c("label", [_vm._v("Amount")]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.adjustmentForm.amount,
                                  expression: "adjustmentForm.amount"
                                }
                              ],
                              staticClass: "form-control",
                              attrs: { type: "text" },
                              domProps: { value: _vm.adjustmentForm.amount },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.adjustmentForm,
                                    "amount",
                                    $event.target.value
                                  )
                                }
                              }
                            }),
                            _vm._v(" "),
                            _vm.errors.amount
                              ? _c("div", { staticClass: "text-danger" }, [
                                  _vm._v(
                                    "\n          " +
                                      _vm._s(_vm.errors.amount[0]) +
                                      "\n        "
                                  )
                                ])
                              : _vm._e()
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "hasPermits",
                                rawName: "v-hasPermits:allow",
                                value: ["super_admin"],
                                expression: "['super_admin']",
                                arg: "allow"
                              }
                            ],
                            staticClass: "form-input margin-bottom-3x"
                          },
                          [
                            _c("label", [_vm._v("Reason to change the price")]),
                            _vm._v(" "),
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.adjustmentForm.change_price_reason,
                                    expression:
                                      "adjustmentForm.change_price_reason"
                                  }
                                ],
                                staticClass: "form-control",
                                on: {
                                  change: function($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call($event.target.options, function(o) {
                                        return o.selected
                                      })
                                      .map(function(o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      _vm.adjustmentForm,
                                      "change_price_reason",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  }
                                }
                              },
                              [
                                _c("option", { domProps: { value: null } }, [
                                  _vm._v("-- Select Reason --")
                                ]),
                                _vm._v(" "),
                                _c(
                                  "option",
                                  { attrs: { value: "Upcoming holiday" } },
                                  [_vm._v(" Upcoming holiday ")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "option",
                                  {
                                    attrs: {
                                      value:
                                        "Expected change in demand (increase/decrease)"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " Expected change in demand (increase/decrease) "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "option",
                                  {
                                    attrs: {
                                      value:
                                        "Unexpected change in supply (excess/shortage of spaces)"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " Unexpected change in supply (excess/shortage of spaces) "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "option",
                                  {
                                    attrs: {
                                      value:
                                        "Random (e.g. location request without reason)"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " Random (e.g. location request without reason) "
                                    )
                                  ]
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _vm.errors.change_price_reason
                              ? _c("div", { staticClass: "text-danger" }, [
                                  _vm._v(
                                    "\n          " +
                                      _vm._s(
                                        _vm.errors.change_price_reason[0]
                                      ) +
                                      "\n        "
                                  )
                                ])
                              : _vm._e()
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-success margin-top-2x",
                            class: { disabled: _vm.processing },
                            attrs: { disabled: _vm.processing },
                            on: { click: _vm.submit }
                          },
                          [
                            _vm.processing
                              ? _c("span", [
                                  _c("i", {
                                    staticClass: "fa fa-spinner fa-spin fa-fw"
                                  })
                                ])
                              : _vm._e(),
                            _vm._v("\n      Submit Rate Adjustment\n    ")
                          ]
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.type == "long_term"
                    ? _c("div", {}, [
                        _c(
                          "div",
                          { staticClass: "form-input" },
                          _vm._l(_vm.longTermForm.discounts, function(
                            discount,
                            i
                          ) {
                            return _c("div", { staticClass: "row" }, [
                              _c(
                                "div",
                                { staticClass: "col-sm-12 margin-bottom-3x" },
                                [
                                  _c("label", [
                                    _vm._v(
                                      "Number of days eligible for discount"
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: discount.number_of_days,
                                        expression: "discount.number_of_days"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: { type: "text" },
                                    domProps: {
                                      value: discount.number_of_days
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          discount,
                                          "number_of_days",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  }),
                                  _vm._v(" "),
                                  _vm.errors[
                                    "discounts." + i + ".number_of_days"
                                  ]
                                    ? _c(
                                        "div",
                                        { staticClass: "text-danger" },
                                        [
                                          _vm._v(
                                            "\n            " +
                                              _vm._s(
                                                _vm.errors[
                                                  "discounts." +
                                                    i +
                                                    ".number_of_days"
                                                ][0]
                                              ) +
                                              "\n          "
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-sm-12" }, [
                                _c("label", [_vm._v("Discount percentage")]),
                                _vm._v(" "),
                                i > 0
                                  ? _c("div", { staticClass: "pull-right" }, [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "text-danger",
                                          attrs: { href: "#" },
                                          on: {
                                            click: function($event) {
                                              return _vm.removeLongTermFields(
                                                i,
                                                $event
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fa fa-times",
                                            attrs: { "aria-hidden": "true" }
                                          })
                                        ]
                                      )
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: discount.discount,
                                      expression: "discount.discount"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: { type: "text" },
                                  domProps: { value: discount.discount },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        discount,
                                        "discount",
                                        $event.target.value
                                      )
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _vm.errors["discounts." + i + ".discount"]
                                  ? _c("div", { staticClass: "text-danger" }, [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(
                                            _vm.errors[
                                              "discounts." + i + ".discount"
                                            ][0]
                                          ) +
                                          "\n          "
                                      )
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("hr")
                              ])
                            ])
                          }),
                          0
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-sm-8" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-success",
                                class: { disabled: _vm.processing },
                                attrs: { disabled: _vm.processing },
                                on: { click: _vm.submitLongTerm }
                              },
                              [
                                _vm.processing
                                  ? _c("span", [
                                      _c("i", {
                                        staticClass:
                                          "fa fa-spinner fa-spin fa-fw"
                                      })
                                    ])
                                  : _vm._e(),
                                _vm._v(
                                  "\n        Submit Long Term Discount\n      "
                                )
                              ]
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-sm-4" }, [
                            _c("div", { staticClass: "text-right" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-default",
                                  on: { click: _vm.addMoreLongTermFields }
                                },
                                [_vm._v("\n        +\n      ")]
                              )
                            ])
                          ])
                        ])
                      ])
                    : _vm._e()
                ])
              ])
            ])
          ]),
      _vm._v(" "),
      _c("confirm", { ref: "submitConfirm", attrs: { type: "danger" } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-1f036ad6", { render: render, staticRenderFns: staticRenderFns })
  }
}