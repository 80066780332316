import { dateFormat, currencySymbol } from 'filters'
import pagination from 'components/pagination'
import { funcModal, reservationDetails } from 'components/modals'
import { hasPermits } from 'directives'
import layout from 'layouts/default'

const directives = {
  hasPermits
}

const components = {
  layout,
  pagination,
  reservationDetails,
  funcModal,
}

const filters = {
  dateFormat,
  currencySymbol
}

const computed = {
  reservations () {
    return _.cloneDeep(this.$store.state.reservations.all);
  },
}

const methods = {
  loadPage (page = 1) {
    this.$route.query.page = page;
    this.loading = true;
    return this.$store.dispatch('reservations.getReservations', {
      params: {
        page,
        mode: this.filters.mode,
        search_by: this.filters.search_by,
        'reservation_number-lk': this.filters.search ? this.filters.search_by == 'reservation_number' ? "*" + this.filters.search.trim() + "*" : this.filters.search.trim() : null,
      }
    }).then(() => this.loading = false);
  },

  loadReservation (item) {
    this.$set(this.loadings, item.id, true);
    this.$store.dispatch('reservations.get', item.id).then(() => {
      this.$set(this.loadings, item.id, false);
      this.activeReservation = _.cloneDeep(this.$store.state.reservations.active);
      this.$nextTick(() => {
        this.$refs.reservationDetailsModal.open();
      })
    })
  },

  currencyCode(location) {
    return location.currency ? currencySymbol(location.currency.code) : "Na";
  },

  openFeesBreakDown (channel_id, reservation_id) {
    this.fees_loading = true;
    this.fees_breakdown = [];
    this.$refs.feesBreakDown.open();
    this.$store.dispatch('channels.getReservationFees', {channel_id, reservation_id}).then(() => {
      this.fees_breakdown = this.$store.state.channels.active.fees_breakdown;
      this.fees_loading = false;
    });
  },

  search (searchKey) {
    this.filters.search = searchKey;
    this.loadPage(1);
  },
}

export default {
  name: 'reservations',
  directives,
  components,
  computed,
  methods,
  filters,
  data () {
    return {
      loading: false,
      activeReservation: null,
      loadings: {},
      fees_breakdown: [],
      fees_loading: false,
      filters: {
        search: null,
        mode: 'all',
        search_by: 'reservation_number',
      }
    }
  },
  created () {
    this.loadPage(this.$route.query.page || 1);
  },
}
