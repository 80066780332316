var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "layout",
    { attrs: { "no-sidebar": true } },
    [
      _c("div", { staticClass: "page-header" }, [
        _c("h1", [_c("small", [_vm._v("Reports")])])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-sm-3" }, [
          _c("div", { staticClass: "form-group" }, [
            _c("label", { attrs: { for: "report" } }, [_vm._v("Report:")]),
            _vm._v(" "),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.report,
                    expression: "report"
                  }
                ],
                staticClass: "form-control",
                attrs: { id: "report" },
                on: {
                  change: function($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function(o) {
                        return o.selected
                      })
                      .map(function(o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.report = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  }
                }
              },
              [
                _c("option", { attrs: { value: "" } }, [
                  _vm._v("Select Report")
                ]),
                _vm._v(" "),
                _vm.canAccessReport("report_blackouts")
                  ? _c("option", { attrs: { value: "blackouts" } }, [
                      _vm._v("Blackouts Dates")
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.canAccessReport("report_commission")
                  ? _c(
                      "option",
                      {
                        class: _vm.isChannelUser
                          ? ""
                          : "overall-commission-option",
                        attrs: { value: "overall_commission_pickup" }
                      },
                      [_vm._v("Overall Commission report by Pickup date")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.canAccessReport("report_commission")
                  ? _c(
                      "option",
                      {
                        directives: [
                          {
                            name: "hasPermits",
                            rawName: "v-hasPermits:prevent",
                            value: ["location_user", "channel_user"],
                            expression: "['location_user','channel_user']",
                            arg: "prevent"
                          }
                        ],
                        attrs: { value: "location_commission" }
                      },
                      [_vm._v("Location Commission")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.canAccessReport("report_commission")
                  ? _c(
                      "option",
                      {
                        directives: [
                          {
                            name: "hasPermits",
                            rawName: "v-hasPermits:prevent",
                            value: ["location_user", "channel_user"],
                            expression: "['location_user','channel_user']",
                            arg: "prevent"
                          }
                        ],
                        attrs: { value: "commission_pickup" }
                      },
                      [_vm._v("Commission report by Pickup date")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.canAccessReport("report_commission")
                  ? _c(
                      "option",
                      {
                        directives: [
                          {
                            name: "hasPermits",
                            rawName: "v-hasPermits:prevent",
                            value: ["channel_user"],
                            expression: "['channel_user']",
                            arg: "prevent"
                          }
                        ],
                        attrs: { value: "commission_booking" }
                      },
                      [_vm._v("Commission report by booking date")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.canAccessReport("report_commission")
                  ? _c(
                      "option",
                      {
                        directives: [
                          {
                            name: "hasPermits",
                            rawName: "v-hasPermits:prevent",
                            value: ["location_user", "channel_user"],
                            expression: "['location_user','channel_user']",
                            arg: "prevent"
                          }
                        ],
                        attrs: { value: "commission_last_change" }
                      },
                      [_vm._v("Commission report by last change")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.canAccessReport("report_refunded_finished_reservations")
                  ? _c(
                      "option",
                      { attrs: { value: "refunded_finished_reservations" } },
                      [_vm._v("Refunded Finished Reservations")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.canAccessReport("report_extended_finished_reservations")
                  ? _c(
                      "option",
                      { attrs: { value: "extended_finished_reservations" } },
                      [_vm._v("Extended Finished Reservations")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.canAccessReport("report_checkin_checkout")
                  ? _c("option", { attrs: { value: "checkin_checkout" } }, [
                      _vm._v("Check-in/Check-out report")
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.canAccessReport("report_utilization")
                  ? _c("option", { attrs: { value: "utilization_report" } }, [
                      _vm._v("Utilization Report")
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "option",
                  {
                    directives: [
                      {
                        name: "hasPermits",
                        rawName: "v-hasPermits:allow",
                        value: ["super_admin"],
                        expression: "['super_admin']",
                        arg: "allow"
                      }
                    ],
                    attrs: { value: "channel_requests" }
                  },
                  [_vm._v("Channels Requests")]
                ),
                _vm._v(" "),
                _c(
                  "option",
                  {
                    directives: [
                      {
                        name: "hasPermits",
                        rawName: "v-hasPermits:allow",
                        value: ["super_admin"],
                        expression: "['super_admin']",
                        arg: "allow"
                      }
                    ],
                    attrs: { value: "bd_report" }
                  },
                  [_vm._v("BD Report")]
                )
              ]
            )
          ]),
          _vm._v(" "),
          _vm.report &&
          _vm.report != "channel_requests" &&
          _vm.report != "bd_report" &&
          (!_vm.isChannelUser ||
            (_vm.isChannelUser && _vm.channels.length == 1))
            ? _c("div", [
                _c("label", [_vm._v("Select Locations:")]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "list-group multi-select-list" },
                  _vm._l(_vm.locations, function(location) {
                    return _c(
                      "a",
                      {
                        staticClass: "list-group-item",
                        class: {
                          active: _vm._.includes(_vm.location_ids, location.id)
                        },
                        attrs: { href: "#" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.selectLocation(location, $event)
                          }
                        }
                      },
                      [_vm._v(_vm._s(location.name))]
                    )
                  }),
                  0
                ),
                _vm._v(" "),
                _vm.errors.locations
                  ? _c("div", { staticClass: "text-danger" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.errors.locations[0]) +
                          "\n        "
                      )
                    ])
                  : _vm._e()
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.report &&
          _vm.report != "checkin_checkout" &&
          _vm.report != "utilization_report" &&
          _vm.report != "bd_report" &&
          (!_vm.isChannelUser || (_vm.isChannelUser && _vm.channels.length > 1))
            ? _c("div", [
                _c("label", [_vm._v("Select Channels:")]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "list-group multi-select-list" },
                  _vm._l(_vm.channels, function(channel) {
                    return _c(
                      "a",
                      {
                        staticClass: "list-group-item",
                        class: {
                          active: _vm._.includes(_vm.channel_ids, channel.id)
                        },
                        attrs: { href: "#" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.selectChannel(channel, $event)
                          }
                        }
                      },
                      [_vm._v(_vm._s(channel.name) + "\n          ")]
                    )
                  }),
                  0
                ),
                _vm._v(" "),
                _vm.errors.channels
                  ? _c("div", { staticClass: "text-danger" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.errors.channels[0]) +
                          "\n        "
                      )
                    ])
                  : _vm._e()
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-sm-9" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-sm-8" }, [
              _vm.showReport &&
              _vm.report != "utilization_report" &&
              _vm.report != "bd_report"
                ? _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      {
                        class:
                          _vm.report &&
                          (_vm.report == "channel_requests" ||
                            _vm.report == "commission_booking" ||
                            _vm.report == "commission_last_change")
                            ? "col-xs-4"
                            : "col-xs-6"
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("label", { attrs: { for: "from_date" } }, [
                              _vm._v("From Date:")
                            ]),
                            _vm._v(" "),
                            _c("datepicker", {
                              staticClass: "form-control",
                              attrs: { "min-date": "none" },
                              model: {
                                value: _vm.from_date,
                                callback: function($$v) {
                                  _vm.from_date = $$v
                                },
                                expression: "from_date"
                              }
                            }),
                            _vm._v(" "),
                            _vm.errors.from_date
                              ? _c("div", { staticClass: "text-danger" }, [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(_vm.errors.from_date[0]) +
                                      "\n                "
                                  )
                                ])
                              : _vm._e()
                          ],
                          1
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        class:
                          _vm.report &&
                          (_vm.report == "channel_requests" ||
                            _vm.report == "commission_booking" ||
                            _vm.report == "commission_last_change")
                            ? "col-xs-4"
                            : "col-xs-6"
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("label", { attrs: { for: "to_date" } }, [
                              _vm._v("To Date:")
                            ]),
                            _vm._v(" "),
                            _c("datepicker", {
                              staticClass: "form-control",
                              attrs: { "min-date": _vm.from_date },
                              model: {
                                value: _vm.to_date,
                                callback: function($$v) {
                                  _vm.to_date = $$v
                                },
                                expression: "to_date"
                              }
                            }),
                            _vm._v(" "),
                            _vm.errors.to_date
                              ? _c("div", { staticClass: "text-danger" }, [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(_vm.errors.to_date[0]) +
                                      "\n                "
                                  )
                                ])
                              : _vm._e()
                          ],
                          1
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _vm.report && _vm.report == "channel_requests"
                      ? _c("div", { staticClass: "col-sm-4" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c("label", { attrs: { for: "report" } }, [
                              _vm._v("Report Type:")
                            ]),
                            _vm._v(" "),
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.channel_requests_report_type,
                                    expression: "channel_requests_report_type"
                                  }
                                ],
                                staticClass: "form-control",
                                attrs: { id: "report" },
                                on: {
                                  change: function($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call($event.target.options, function(o) {
                                        return o.selected
                                      })
                                      .map(function(o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.channel_requests_report_type = $event
                                      .target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  }
                                }
                              },
                              [
                                _c(
                                  "option",
                                  { attrs: { value: "technical" } },
                                  [_vm._v("Technical Requests")]
                                ),
                                _vm._v(" "),
                                _c("option", { attrs: { value: "business" } }, [
                                  _vm._v("Business Requests")
                                ])
                              ]
                            )
                          ])
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.report && _vm.report == "overall_commission_pickup"
                      ? _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "hasPermits",
                                rawName: "v-hasPermits:allow",
                                value: ["super_admin"],
                                expression: "['super_admin']",
                                arg: "allow"
                              }
                            ],
                            staticClass: "col-sm-4"
                          },
                          [
                            _c("div", { staticClass: "checkbox" }, [
                              _c("label", [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.old_report,
                                      expression: "old_report"
                                    }
                                  ],
                                  attrs: { type: "checkbox" },
                                  domProps: {
                                    checked: Array.isArray(_vm.old_report)
                                      ? _vm._i(_vm.old_report, null) > -1
                                      : _vm.old_report
                                  },
                                  on: {
                                    change: function($event) {
                                      var $$a = _vm.old_report,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            (_vm.old_report = $$a.concat([$$v]))
                                        } else {
                                          $$i > -1 &&
                                            (_vm.old_report = $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1)))
                                        }
                                      } else {
                                        _vm.old_report = $$c
                                      }
                                    }
                                  }
                                }),
                                _vm._v(
                                  "\n                    Old Report\n                "
                                )
                              ])
                            ])
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.report &&
                    (_vm.report == "commission_booking" ||
                      _vm.report == "commission_last_change")
                      ? _c("div", { staticClass: "col-sm-4" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c("label", { attrs: { for: "report" } }, [
                              _vm._v("Time zone:")
                            ]),
                            _vm._v(" "),
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.timezone,
                                    expression: "timezone"
                                  }
                                ],
                                staticClass: "form-control",
                                attrs: { id: "report" },
                                on: {
                                  change: function($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call($event.target.options, function(o) {
                                        return o.selected
                                      })
                                      .map(function(o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.timezone = $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  }
                                }
                              },
                              [
                                _c("option", { attrs: { value: "EST" } }, [
                                  _vm._v("EST")
                                ]),
                                _vm._v(" "),
                                _c("option", { attrs: { value: "UTC" } }, [
                                  _vm._v("UTC")
                                ])
                              ]
                            )
                          ])
                        ])
                      : _vm._e()
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.showReport && _vm.report == "bd_report"
                ? _c("div", { staticClass: "row" }, [
                    _c("div", { class: _vm.report }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", { attrs: { for: "from_date" } }, [
                          _vm._v("Pick up month:")
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "margin-bottom-8x" }, [
                          _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "col-sm-4 col-xs-12 margin-bottom-2x"
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "input-group" },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "input-group-addon",
                                        attrs: { id: "basic-addon1" }
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "fa fa-calendar",
                                          attrs: { "aria-hidden": "true" }
                                        })
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("datepicker", {
                                      staticClass: "form-control dashboard",
                                      attrs: {
                                        "date-format": "MMMM/YYYY",
                                        "min-date": "none",
                                        options: _vm.date_picker_settings,
                                        "hide-days": true
                                      },
                                      model: {
                                        value: _vm.from,
                                        callback: function($$v) {
                                          _vm.from = $$v
                                        },
                                        expression: "from"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "col-sm-4 col-xs-12 padding-top-2x"
                              },
                              [
                                _c("span", [
                                  _c(
                                    "a",
                                    {
                                      attrs: { href: "#" },
                                      on: {
                                        click: function($event) {
                                          $event.preventDefault()
                                          return _vm.prevMonth.apply(
                                            null,
                                            arguments
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "fa fa-chevron-left",
                                        attrs: { "aria-hidden": "true" }
                                      })
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "margin-right-3x margin-left-3x"
                                    },
                                    [
                                      _vm._v(
                                        "\n                          " +
                                          _vm._s(this.from) +
                                          "\n                        "
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "a",
                                    {
                                      attrs: { href: "#" },
                                      on: {
                                        click: function($event) {
                                          $event.preventDefault()
                                          return _vm.nextMonth.apply(
                                            null,
                                            arguments
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "fa fa-chevron-right",
                                        attrs: { "aria-hidden": "true" }
                                      })
                                    ]
                                  )
                                ])
                              ]
                            )
                          ])
                        ])
                      ])
                    ])
                  ])
                : _vm._e()
            ]),
            _vm._v(" "),
            _vm.can_run
              ? _c("div", { staticClass: "col-sm-4 text-right" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary margin-top-5x",
                      class: { disabled: !_vm.can_run },
                      attrs: { disabled: !_vm.can_run },
                      on: { click: _vm.runReport }
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-paper-plane",
                        attrs: { "aria-hidden": "true" }
                      }),
                      _vm._v("\n            Run Report\n          ")
                    ]
                  ),
                  _vm._v(" "),
                  _vm.report != "utilization_report"
                    ? _c(
                        "div",
                        { staticClass: "pull-right dropdown margin-left-2x" },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-default margin-top-5x",
                              class: { disabled: !_vm.can_run },
                              attrs: {
                                disabled: !_vm.can_run,
                                "data-toggle": "dropdown",
                                "aria-haspopup": "true",
                                "aria-expanded": "true"
                              }
                            },
                            [
                              _c("i", {
                                staticClass: "fa fa-download",
                                attrs: { "aria-hidden": "true" }
                              }),
                              _vm._v("\n              Export\n            ")
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "ul",
                            {
                              staticClass: "dropdown-menu",
                              attrs: { "aria-labelledby": "dropdownMenu1" }
                            },
                            [
                              _c("li", [
                                _c(
                                  "a",
                                  {
                                    attrs: { href: "#" },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        return _vm.exportReport("csv")
                                      }
                                    }
                                  },
                                  [_vm._v("CSV")]
                                )
                              ]),
                              _vm._v(" "),
                              _vm.report == "overall_commission_pickup"
                                ? _c("li", [
                                    _c(
                                      "a",
                                      {
                                        attrs: { href: "#" },
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                            return _vm.exportReport("xlsx")
                                          }
                                        }
                                      },
                                      [_vm._v("xlsx")]
                                    )
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c("li", [
                                _c(
                                  "a",
                                  {
                                    attrs: { href: "#" },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        return _vm.exportReport("pdf")
                                      }
                                    }
                                  },
                                  [_vm._v("PDF")]
                                )
                              ])
                            ]
                          )
                        ]
                      )
                    : _vm._e()
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "row" },
            [
              _vm.report == "blackouts" && _vm.run_report
                ? _c("blackouts", { attrs: { url: _vm.url } })
                : _vm._e(),
              _vm._v(" "),
              _vm.report == "overall_commission_pickup" && _vm.run_report
                ? _c("overallCommissionPickup", {
                    attrs: { url: _vm.url },
                    on: {
                      locationFeesClicked: _vm.openFeesBreakDown,
                      commissionPickupErrors: _vm.showErrors
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.report == "location_commission" && _vm.run_report
                ? _c("locationCommission", {
                    attrs: { url: _vm.url },
                    on: {
                      locationFeesClicked: _vm.openFeesBreakDown,
                      commissionPickupErrors: _vm.showErrors
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.report == "commission_pickup" && _vm.run_report
                ? _c("commissionPickup", {
                    attrs: { url: _vm.url },
                    on: {
                      locationFeesClicked: _vm.openFeesBreakDown,
                      commissionPickupErrors: _vm.showErrors
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.report == "commission_booking" && _vm.run_report
                ? _c("commissionBooking", {
                    attrs: { url: _vm.url },
                    on: {
                      locationFeesClicked: _vm.openFeesBreakDown,
                      commissionBookingErrors: _vm.showErrors
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.report == "commission_last_change" && _vm.run_report
                ? _c("commissionLastChange", {
                    attrs: { url: _vm.url },
                    on: {
                      locationFeesClicked: _vm.openFeesBreakDown,
                      commissionLastChangeErrors: _vm.showErrors
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.report == "refunded_finished_reservations" && _vm.run_report
                ? _c("refundedFinishedReservations", {
                    attrs: { url: _vm.url },
                    on: {
                      locationFeesClicked: _vm.openFeesBreakDown,
                      refundedFinishedReservationsErrors: _vm.showErrors
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.report == "extended_finished_reservations" && _vm.run_report
                ? _c("extendedFinishedReservations", {
                    attrs: { url: _vm.url },
                    on: {
                      locationFeesClicked: _vm.openFeesBreakDown,
                      extendedFinishedReservationsErrors: _vm.showErrors
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.report == "checkin_checkout" && _vm.run_report
                ? _c("checkinCheckout", {
                    attrs: { url: _vm.url },
                    on: { checkinReportErrors: _vm.showErrors }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.report == "channel_requests" && _vm.run_report
                ? _c("channelReqests", {
                    attrs: { url: _vm.url },
                    on: { channelReqestsErrors: _vm.showErrors }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.report == "utilization_report"
                ? _c("utilization-report", {
                    attrs: {
                      location_ids: _vm.location_ids,
                      all_locations: _vm.locations
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "row" },
        [
          _vm.report == "bd_report" && _vm.run_report
            ? _c("BDReport", {
                attrs: { url: _vm.url },
                on: { DBReportsErrors: _vm.showErrors }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "func-modal",
        {
          ref: "feesBreakDown",
          attrs: { title: "Fees Breakdown" },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function(props) {
                return [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-default",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return props.close()
                        }
                      }
                    },
                    [_vm._v("Close")]
                  )
                ]
              }
            }
          ])
        },
        [
          _c("template", { slot: "body" }, [
            !_vm.fees_loading && _vm.fees_breakdown.length
              ? _c("table", { staticClass: "table table-bordered" }, [
                  _c("thead", [
                    _c("tr", [
                      _c("th", [_vm._v("Fee Name")]),
                      _vm._v(" "),
                      _c("th", [_vm._v("Amount")])
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "tbody",
                    _vm._l(_vm.fees_breakdown, function(fee) {
                      return _c("tr", { key: fee.id }, [
                        _c("td", [_vm._v(_vm._s(fee.name))]),
                        _vm._v(" "),
                        _c("th", [_vm._v(_vm._s(fee.doller_amount))])
                      ])
                    }),
                    0
                  )
                ])
              : !_vm.fees_loading && !_vm.fees_breakdown.length
              ? _c("div", [
                  _vm._v(
                    "\n        No fees charged for this reservation\n      "
                  )
                ])
              : _c("div", { staticClass: "text-center" }, [
                  _c("i", { staticClass: "fa fa-spinner fa-spin fa-3x fa-fw" })
                ])
          ])
        ],
        2
      ),
      _vm._v(" "),
      _c("exportReport", { ref: "export" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-6225d0a4", { render: render, staticRenderFns: staticRenderFns })
  }
}