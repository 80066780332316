import toastr from 'toastr'
import { hasPermits } from 'directives'
import { editableText, editableRadio } from 'components/editable'
import { confirm } from 'components/modals'
import newFee from './newFee'
import editFee from './editFee'
import copyFee from './copyFee'
import newDynamicFee from './newDynamicFee'
import editDynamicFee from './editDynamicFee'

const components = {
  newFee,
  editFee,
  editableText,
  editableRadio,
  confirm,
  copyFee,
  newDynamicFee,
  editDynamicFee
}

const directives = {
  hasPermits
}

const computed = {
  fees () {
    return _.cloneDeep(this.$store.state.channels.active.fees);
  },
  dynamicFees () {
    return _.cloneDeep(this.$store.state.channels.active.dynamicFees);
  },
  locations () {
    return _.cloneDeep(this.$store.state.channels.active.locations);
  },
  channel () {
    return _.cloneDeep(this.$store.state.channels.active);
  }
}

const methods = {
  loadLocations () {
    this.locationsLoading = true;
    this.$store.dispatch('channels.getLocations', {
      id: this.$route.params.id,
      params: {_all: true}
    }).then (() => {
      this.locationsLoading = false;
    })
  },

  openEditFeeModal (fee) {
    this.selectedFee = fee;
    this.$refs.editFeeModal.open();
  },

  getFees () {
    this.feesLoading = true
    this.$store.dispatch('channels.getFees', {
      channel_id: this.$route.params.id    
    }).then(() => {
      this.feesLoading = false
    });
  },

  getDynamicFees () {
    this.dynamicFeesLoading = true
    this.$store.dispatch('channels.getDynamicFees', {
      channel_id: this.$route.params.id    
    }).then(() => {
      this.dynamicFeesLoading = false;
      _.forEach(this.dynamicFees, element => {
        this.dynamicFeesEditStatus[element.id] = false;
      });
    });
  },

  onEditFee (editableData, item, key) {
    console.log('data, item', editableData, item);
    item[key] = editableData.value;
    editableData.startLoading();
    if (key == 'calculation') {
      if (item.calculation == 'one_time') {
        item.type = 'fixed';
      }
    }

    item.locations = item.channel_locations.map(x => x.id);
    delete item.channel_locations;
    
    this.$store.dispatch('channels.updateFee', {
      chId: this.$route.params.id,
      id: item.id, data: item
    }).then(() => {
      editableData.stopLoading();
      let errors;
      if (this.$store.state.channels.errors) {
        errors = this.$store.state.channels.errors.errors;
        console.log('errors', errors);
        _.forEach(errors, (error) => {
          console.log("error", error);
          toastr.error(error[0]);
        })
      } else {
        editableData.close();
      }
    })
  },

  removeFee (id, is_dynamic = false) {
    this.$refs.confirmDelete.confirm('Are you sure?', () => {
      this.loadings[id] = true;
      this.loadings = _.cloneDeep(this.loadings);
      this.$store.dispatch('channels.removeFee', {
        channel_id: this.$route.params.id,
        id,
        is_dynamic: is_dynamic
      }).then(() => {
        this.loadings[id] = false;
        let errors;
        if (this.$store.state.channels.errors) {
          errors = this.$store.state.channels.errors.errors;
          _.forEach(errors.errors, (error) => {
            toastr.error(error[0]);
          })
        } else {
          toastr.success('fee removed successfully');
        }
      });
    })
  },

  editDynamicFeeStatus (feeId, editing = true) {
    this.dynamicFeesEditStatus[feeId] = editing;
    if (editing) {
      _.forEach(this.dynamicFees, element => {
        if (element.id != feeId) {
          this.dynamicFeesEditStatus[element.id] = false;
        }
      });
    }
    this.dynamicFeesEditStatus = _.cloneDeep(this.dynamicFeesEditStatus);
  },

  openCopyFeeModal () {
    this.$refs.copyFeeModal.open();
  }
}

const watch = {
  activeTab (n) {
    if (n.title == "Fees") {
      Promise.all([
        this.loadLocations(),
        this.getDynamicFees(),
        this.getFees()
      ]);
    }
  },
  formData (n, o) {
    if (n == o) return;

    this.form = n;
  }
}

export default {
  name: 'location-fees',
  props: ['activeTab', 'formData'],
  watch,
  components,
  directives,
  computed,
  methods,
  data () {
    return {
      form: this.formData,
      loadings: {},
      selectedFee: null,
      locationsLoading: false,
      feesLoading: false,
      dynamicFeesLoading: false,
      dynamicFeesEditStatus: {},
      copyingFees: false
    }
  }
}
