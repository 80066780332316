/*
* @INFO: This component makes a a jquery-ui datepicker
* @PROP value: (pass it as v-model)
* @PROP minDate: is the minimum date to reach
* @PROP maxDate: is the maximum date to reach
*/
import moment from 'moment'

const methods = {
  reload () {

    let data = {
      maxDate: this.maxDate,
      onClose: this.onChange,
      dateFormat: "mm/dd/yy"
    }
    let format = this.dateFormat ? this.dateFormat : 'MM/DD/YYYY'
    if (this.minDate != "none") {
        data.minDate = this.minDate || moment().format(format)
    }


    if (this.options) {
      data = Object.assign(data, this.options);
    }

    if (this.hideDays) {
        data.beforeShow = (input, obj) => {
          $(this.$refs.input).after($(this.$refs.input).datepicker('widget'));

          let date = moment(this.value, [format]);
          // this one to set the default selected date  
          $(this.$refs.input).datepicker('option', 'defaultDate', new Date(date.year(), date.month(), 1));
          // this one to set the value to the input 
          $(this.$refs.input).datepicker('setDate', new Date(date.year(), date.month(), 1));

          this.$nextTick(() => {
            $(this.$refs.input).next().addClass('hide-days');
            $(this.$refs.input).datepicker("refresh");
          });
        };

        data.onClose = (dateText, inst) => {
          $(this.$refs.input).datepicker('setDate', new Date(inst.selectedYear, inst.selectedMonth, 1));
          let date = moment(new Date(inst.selectedYear, inst.selectedMonth, 1)).format(format);
          this.$emit('input', date)
        }
       
    } 

    if (!this.hideDays) {
      this.$nextTick(() => {
        $("#ui-datepicker-div").removeClass('hide-days');
      });
    }

    $(this.$refs.input).datepicker("destroy");

    $(this.$refs.input).datepicker(data).css("z-index", "inherit");
    
  },
  onChange (value) {
    this.$emit('input', value)
  }
}

const watch = {
  maxDate: function () {
    this.reload()
  },
  minDate: function () {
    this.reload()
  },
}

const computed = {
  // newValue () {
  //   return this.value
  // }
}

export default {
  name: 'datepicker',
  props: ['maxDate', 'minDate', 'value', 'dateFormat', 'options','hideDays'],
  methods,
  watch,
  computed,
  mounted () {
    this.reload();
  }
}
