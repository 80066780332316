import { hasPermits } from 'directives'
import { user} from 'services'
import { permits } from 'services'

const directives = {
  hasPermits
}
export default {
  name: "general-tab",
  props: ['form', 'errors', 'currencies'],
  directives,
  data () {
    return {
      locId: this.$route.params.id,
      user: null,
      updateGeneral :false
    }
  },
  mounted () {
    this.user = user.getUser();
  },
  created () {
    if (permits.hasPermit('location_booking_conditions_update')||permits.hasLocationPermit(this.$route.params.id, [
      'location_booking_conditions_update',
    ])) {
      this.updateGeneral = true;
    }
  }
}
