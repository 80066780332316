import { permits } from 'services'

export default {
  name: "hasPermits",
  inserted (el, binding) {
    //if the user doesn't have any permission that listed in "allow", prevent his/her access
    if (binding.arg == 'allow' && binding.value) {
      if (!permits.hasPermits(binding.value.length ? binding.value : binding.value.permits) &&
      !permits.hasLocationPermits(binding.value.id, binding.value.permits)) {
        $(el).remove();
      }
    }

    //if one of these permissions exists, prevent his/her access
    if (binding.arg == 'prevent' && binding.value) { 
      if (permits.hasPermit('super_admin')) {
        if ((binding.value instanceof Array && (binding.value.indexOf('super_admin') > -1)) || (binding.value.permits && (binding.value.permits.indexOf('super_admin') > -1))) {
          $(el).remove();
        }
        return;
      }

      if (permits.hasPermits(binding.value) ||
        permits.hasLocationPermits(binding.value.id, binding.value.permits)) {
        $(el).remove();
      }
    }

  }
}
