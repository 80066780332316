import toastr from 'toastr'
import { funcModal } from 'components/modals'

const components = {
    funcModal
}

const filters = {
}

const computed = {
}

const methods = {
    open (reservation, activeHistory, form) {
        this.reservation = reservation;
        this.form = form;
        this.with_refund = 1;
        this.$refs.modal.open();
    },

    onClose () {
        this.$emit('close');
    },
    submit () {
        this.processing = true;
        this.errors = {};
        this.recalculationRoomsItems();
        this.form['with_refund'] = Number(this.with_refund);
        this.$store.dispatch('reservations.update', {id: this.$route.params.id, data: this.form}).then(() => {
            this.processing = false;
            let errors = _.cloneDeep(this.$store.state.reservations.errors);
            if (errors) {
                toastr.error(errors.message);
                this.errors = errors.errors;
            } else {
                toastr.success("Reservation has been updated successfully.");
                this.$refs.modal.close();
            }
        })
    },

    recalculationRoomsItems() {
        this.form.items = _.map(this.form.items, (item) => {
          if (item.type == 'room') {
            item.guest_names = _.slice(item.guest_names, 0, Number(item.number_of_rooms));
            item.guests_in_rooms = _.slice(item.guests_in_rooms, 0, Number(item.number_of_rooms));
          }
          return item;
        });
      },
}

export default {
    name: 'update-reservation-modal',
    methods,
    computed,
    components,
    filters,
    data () {
        return {
            errors: {},
            reservation: null,
            with_refund: 1,
            processing: false,
            form: {},
        }
    }
}