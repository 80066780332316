var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {}, [
    _c("h3", [_vm._v("Rooms")]),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "hasPermits",
            rawName: "v-hasPermits:allow",
            value: {
              id: _vm.locId,
              permits: ["amenity_list", "amenity_create"]
            },
            expression:
              "{id: locId, permits: ['amenity_list', 'amenity_create']}",
            arg: "allow"
          }
        ],
        staticClass: "box"
      },
      [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "box-body" },
          [
            _vm._l(_vm.base_amenities, function(amenity) {
              return _c(
                "div",
                {
                  directives: [
                    {
                      name: "hasPermits",
                      rawName: "v-hasPermits:allow",
                      value: { id: _vm.locId, permits: ["amenity_list"] },
                      expression: "{id: locId, permits: ['amenity_list']}",
                      arg: "allow"
                    }
                  ],
                  staticClass: "checkbox"
                },
                [
                  _c("label", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.amenities,
                          expression: "amenities"
                        }
                      ],
                      attrs: { type: "checkbox" },
                      domProps: {
                        value: amenity.id,
                        checked: Array.isArray(_vm.amenities)
                          ? _vm._i(_vm.amenities, amenity.id) > -1
                          : _vm.amenities
                      },
                      on: {
                        change: function($event) {
                          var $$a = _vm.amenities,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = amenity.id,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.amenities = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.amenities = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.amenities = $$c
                          }
                        }
                      }
                    }),
                    _vm._v(
                      "\n          " +
                        _vm._s(amenity.display_name) +
                        "\n        "
                    )
                  ])
                ]
              )
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "hasPermits",
                    rawName: "v-hasPermits:allow",
                    value: { id: _vm.locId, permits: ["amenity_list"] },
                    expression: "{id: locId, permits: ['amenity_list']}",
                    arg: "allow"
                  }
                ]
              },
              [
                _vm._l(_vm.custom_amenities, function(amenity, i) {
                  return _c("div", { staticClass: "margin-bottom-1x" }, [
                    _c(
                      "a",
                      {
                        directives: [
                          {
                            name: "hasPermits",
                            rawName: "v-hasPermits:allow",
                            value: {
                              id: _vm.locId,
                              permits: ["amenity_delete"]
                            },
                            expression:
                              "{id: locId, permits: ['amenity_delete']}",
                            arg: "allow"
                          }
                        ],
                        staticClass: "text-danger",
                        attrs: { href: "#" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.removeAmenity(i)
                          }
                        }
                      },
                      [_c("i", { staticClass: "fa fa-times" })]
                    ),
                    _vm._v("\n          " + _vm._s(amenity) + "\n        ")
                  ])
                }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "hasPermits",
                        rawName: "v-hasPermits:allow",
                        value: { id: _vm.locId, permits: ["amenity_create"] },
                        expression: "{id: locId, permits: ['amenity_create']}",
                        arg: "allow"
                      }
                    ],
                    staticClass: "input-group"
                  },
                  [
                    _c("input", {
                      ref: "new_amenity",
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        placeholder: "Type Custom Amenity Name"
                      }
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "input-group-btn" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-default",
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.addAmenity.apply(null, arguments)
                            }
                          }
                        },
                        [_vm._v("\n              Add\n            ")]
                      )
                    ])
                  ]
                )
              ],
              2
            )
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "hasPermits",
                rawName: "v-hasPermits:allow",
                value: {
                  id: _vm.locId,
                  permits: ["amenity_create", "amenity_delete"]
                },
                expression:
                  "{id: locId, permits: ['amenity_create', 'amenity_delete']}",
                arg: "allow"
              }
            ],
            staticClass: "box-footer text-right"
          },
          [
            _c(
              "button",
              {
                staticClass: "btn btn-success",
                class: { disabled: _vm.processing },
                attrs: { disabled: _vm.processing },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.submit()
                  }
                }
              },
              [
                _vm.processing
                  ? _c("span", [
                      _c("i", { staticClass: "fa fa-spinner fa-spin fa-fw" })
                    ])
                  : _vm._e(),
                _vm._v("\n        Save\n      ")
              ]
            )
          ]
        )
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "hasPermits",
            rawName: "v-hasPermits:allow",
            value: {
              id: _vm.locId,
              permits: ["locationPhoto_list", "locationPhoto_upload"]
            },
            expression:
              "{id: locId, permits: ['locationPhoto_list', 'locationPhoto_upload']}",
            arg: "allow"
          }
        ],
        staticClass: "box"
      },
      [
        _vm._m(1),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "box-body" },
          [
            _c(
              "file-upload",
              {
                directives: [
                  {
                    name: "hasPermits",
                    rawName: "v-hasPermits:allow",
                    value: { id: _vm.locId, permits: ["locationPhoto_upload"] },
                    expression:
                      "{id: locId, permits: ['locationPhoto_upload']}",
                    arg: "allow"
                  }
                ],
                ref: "file_upload",
                attrs: { multiple: true, accept: "image/*" },
                on: { filesChanged: _vm.imagesToUpload }
              },
              [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-success",
                    class: { disabled: _vm.files_processing },
                    attrs: { disabled: _vm.files_processing },
                    on: { click: _vm.uploadImages }
                  },
                  [
                    _vm.files_processing
                      ? _c("span", [
                          _vm.files_processing
                            ? _c("i", {
                                staticClass: "fa fa-spinner fa-spin fa-fw"
                              })
                            : _vm._e()
                        ])
                      : _vm._e(),
                    _vm._v("\n          Upload\n        ")
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _vm._l(_vm.imageErrors, function(error, i) {
              return _c("div", { key: i, staticClass: "text-danger" }, [
                _vm._v("\n        " + _vm._s(error[0]) + "\n      ")
              ])
            }),
            _vm._v(" "),
            _vm.photos.length
              ? _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "hasPermits",
                        rawName: "v-hasPermits:allow",
                        value: {
                          id: _vm.locId,
                          permits: ["locationPhoto_list"]
                        },
                        expression:
                          "{id: locId, permits: ['locationPhoto_list']}",
                        arg: "allow"
                      }
                    ],
                    staticClass: "margin-top-2x"
                  },
                  [
                    _vm.need_to_save
                      ? _c("div", { staticClass: "text-warning" }, [
                          _c("strong", [
                            _vm._v(
                              "When removing an image, you need to hit save"
                            )
                          ])
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "ul",
                      { staticClass: "images-list" },
                      _vm._l(_vm.photos, function(photo) {
                        return _c("li", [
                          _c("img", {
                            staticClass: "pointer",
                            class: {
                              "amenities-featured-photo": photo.featured
                            },
                            attrs: {
                              src: photo.filename,
                              alt: "",
                              "data-toggle": "tooltip",
                              title: "Make this photo featured ?"
                            },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.setFeatured(photo)
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              directives: [
                                {
                                  name: "hasPermits",
                                  rawName: "v-hasPermits:allow",
                                  value: {
                                    id: _vm.locId,
                                    permits: ["locationPhoto_delete"]
                                  },
                                  expression:
                                    "{id: locId, permits: ['locationPhoto_delete']}",
                                  arg: "allow"
                                }
                              ],
                              staticClass: "remove text-danger",
                              attrs: { href: "#" },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.removePhoto(photo)
                                }
                              }
                            },
                            [_c("i", { staticClass: "fa fa-times" })]
                          )
                        ])
                      }),
                      0
                    )
                  ]
                )
              : _vm._e()
          ],
          2
        ),
        _vm._v(" "),
        _vm.need_to_save
          ? _c(
              "div",
              {
                directives: [
                  {
                    name: "hasPermits",
                    rawName: "v-hasPermits:allow",
                    value: {
                      id: _vm.locId,
                      permits: ["locationPhoto_upload", "locationPhoto_delete"]
                    },
                    expression:
                      "{id: locId, permits: ['locationPhoto_upload', 'locationPhoto_delete']}",
                    arg: "allow"
                  }
                ],
                staticClass: "box-footer text-right"
              },
              [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-success",
                    class: { disabled: _vm.processing },
                    attrs: { disabled: _vm.processing },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.submit()
                      }
                    }
                  },
                  [
                    _vm.processing
                      ? _c("span", [
                          _c("i", {
                            staticClass: "fa fa-spinner fa-spin fa-fw"
                          })
                        ])
                      : _vm._e(),
                    _vm._v("\n        Save\n      ")
                  ]
                )
              ]
            )
          : _vm._e()
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "box-header with-border" }, [
      _c("h4", [_vm._v("Amenities")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "box-header with-border" }, [
      _c("h4", [_vm._v("Photos")])
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-1ec59cb8", { render: render, staticRenderFns: staticRenderFns })
  }
}