import toastr from 'toastr'
import { funcModal } from 'components/modals'
import { hasPermits } from 'directives'

const components = {
  funcModal
}

const directives = {
  hasPermits
}

const computed = {
  user () {
    return this.userData.user || {};
  },

  country () {
    let countries = this.$store.state.address.countries;
    for (let i in countries) {
      if (countries[i].id == this.user.country_id) {
        return countries[i];
      }
    }
    return {};
  },

  state () {
    for (let i in this.country.states) {
      if (this.country.states[i].id == this.user.state_id) {
        return this.country.states[i];
      }
    }
    return {};
  }
}

const methods = {
  open () {
    this.$refs.modal.open();
  }
}

export default {
  name: 'edit-employee-modal',
  props: ['userData'],
  computed,
  components,
  directives,
  methods,
  data () {
    return {
      // loading: true,
      processing: false,
      errors: {},
      locId: this.$route.params.id
    }
  },
  created () {
    // this.$store.dispatch('address.getCountries');
    // this.$store.dispatch('roles.getPermissions', {type: 'location'}).then(() => {
    //   this.loading = false;
    // });
  }
}
