import layout from 'layouts/default'
import { user, permits } from 'services'

const components = {
  layout,
}

const methods = {

}

const watch = {
  user (n, o) {
    if (n == o) return;

  }
}

export default {
  name: 'dashboards-handler',
  components,
  data () {
    return {
      user: user.getUser()
    }
  },
  created () {
    console.log("this.user", this.user);
    if (permits.hasPermit('super_admin')) {
      this.$router.replace({
        name: 'locations.all'
      });
    } else if (permits.hasPermits(['location_user', 'location_list'])) {
      if (this.user.locations) {
        if (this.user.locations.length == 1) {
          try {
            this.$router.replace({
              name: 'locations.guestManagement',
              params: {
                id: this.user.locations[0].location_id
              }
            });
            return
          } catch (e) {
            console.log('no locations found');
          }
        }
      }

      this.$router.replace({
        name: 'locations.all'
      });

    } else if (permits.hasPermits(['channel_user', 'channel_list'])) {
      if (this.user.channels) {
        if (this.user.channels.length == 1) {
          try {
            this.$router.replace({
              name: 'channels.edit',
              params: {
                id: this.user.channels[0].channel_id
              }
            });
            return
          } catch (e) {
            console.log('no channels found');
          }
        }
      }

      this.$router.replace({
        name: 'channels.all'
      });
    }
  }
}
