var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "table-responsive" }, [
    _c("table", { staticClass: "table" }, [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "tbody",
        _vm._l(_vm.form.channels, function(channel, i) {
          return _c("tr", [
            _c("td", [
              _vm._v("\n          " + _vm._s(channel.name) + "\n        ")
            ]),
            _vm._v(" "),
            _c("td", [
              _vm._v("\n          " + _vm._s(channel.domain) + "\n        ")
            ]),
            _vm._v(" "),
            _c("td", [
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "hasPermits",
                      rawName: "v-hasPermits:prevent",
                      value: {
                        locId: _vm.$route.params.id,
                        permits: ["location_update", "super_admin"]
                      },
                      expression:
                        "{locId: $route.params.id, permits: ['location_update', 'super_admin']}",
                      arg: "prevent"
                    }
                  ]
                },
                [_vm._v(_vm._s(channel.parking_commission_type))]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "hasPermits",
                      rawName: "v-hasPermits:allow",
                      value: {
                        locId: _vm.$route.params.id,
                        permits: ["location_update"]
                      },
                      expression:
                        "{locId: $route.params.id, permits: ['location_update']}",
                      arg: "allow"
                    }
                  ],
                  staticClass: "input-group radio"
                },
                [
                  _c("label", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: channel.parking_commission_type,
                          expression: "channel.parking_commission_type"
                        }
                      ],
                      attrs: { type: "radio", value: "percentage" },
                      domProps: {
                        checked: _vm._q(
                          channel.parking_commission_type,
                          "percentage"
                        )
                      },
                      on: {
                        change: function($event) {
                          return _vm.$set(
                            channel,
                            "parking_commission_type",
                            "percentage"
                          )
                        }
                      }
                    }),
                    _vm._v("\n              Percentage\n            ")
                  ]),
                  _vm._v("\n             \n            "),
                  _c("label", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: channel.parking_commission_type,
                          expression: "channel.parking_commission_type"
                        }
                      ],
                      attrs: { type: "radio", value: "flat" },
                      domProps: {
                        checked: _vm._q(channel.parking_commission_type, "flat")
                      },
                      on: {
                        change: function($event) {
                          return _vm.$set(
                            channel,
                            "parking_commission_type",
                            "flat"
                          )
                        }
                      }
                    }),
                    _vm._v("\n              Flat\n            ")
                  ])
                ]
              ),
              _vm._v(" "),
              _vm.errors["channels." + i + ".parking_commission_type"]
                ? _c("div", { staticClass: "text-danger" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm.errors[
                            "channels." + i + ".parking_commission_type"
                          ][0]
                        ) +
                        "\n          "
                    )
                  ])
                : _vm._e()
            ]),
            _vm._v(" "),
            _c(
              "td",
              [
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "hasPermits",
                        rawName: "v-hasPermits:prevent",
                        value: {
                          locId: _vm.$route.params.id,
                          permits: ["location_update", "super_admin"]
                        },
                        expression:
                          "{locId: $route.params.id, permits: ['location_update', 'super_admin']}",
                        arg: "prevent"
                      }
                    ]
                  },
                  [_vm._v(_vm._s(channel.parking_commission))]
                ),
                _vm._v(" "),
                _c("editable-text", {
                  directives: [
                    {
                      name: "hasPermits",
                      rawName: "v-hasPermits:allow",
                      value: {
                        locId: _vm.$route.params.id,
                        permits: ["location_update"]
                      },
                      expression:
                        "{locId: $route.params.id, permits: ['location_update']}",
                      arg: "allow"
                    }
                  ],
                  attrs: { value: channel.parking_commission },
                  on: {
                    valueSaved: function($event) {
                      return _vm.commissionChanged(
                        $event,
                        i,
                        "parking_commission"
                      )
                    }
                  }
                }),
                _vm._v(" "),
                _vm.errors["channels." + i + ".parking_commission"]
                  ? _c("div", { staticClass: "text-danger" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.errors[
                              "channels." + i + ".parking_commission"
                            ][0]
                          ) +
                          "\n          "
                      )
                    ])
                  : _vm._e()
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "td",
              [
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "hasPermits",
                        rawName: "v-hasPermits:prevent",
                        value: {
                          locId: _vm.$route.params.id,
                          permits: ["location_update", "super_admin"]
                        },
                        expression:
                          "{locId: $route.params.id, permits: ['location_update', 'super_admin']}",
                        arg: "prevent"
                      }
                    ]
                  },
                  [_vm._v(_vm._s(channel.room_commission))]
                ),
                _vm._v(" "),
                _c("editable-text", {
                  directives: [
                    {
                      name: "hasPermits",
                      rawName: "v-hasPermits:allow",
                      value: {
                        locId: _vm.$route.params.id,
                        permits: ["location_update"]
                      },
                      expression:
                        "{locId: $route.params.id, permits: ['location_update']}",
                      arg: "allow"
                    }
                  ],
                  attrs: { value: channel.room_commission },
                  on: {
                    valueSaved: function($event) {
                      return _vm.commissionChanged($event, i, "room_commission")
                    }
                  }
                }),
                _vm._v(" "),
                _vm.errors["channels." + i + ".room_commission"]
                  ? _c("div", { staticClass: "text-danger" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.errors["channels." + i + ".room_commission"][0]
                          ) +
                          "\n          "
                      )
                    ])
                  : _vm._e()
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "td",
              [
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "hasPermits",
                        rawName: "v-hasPermits:prevent",
                        value: {
                          locId: _vm.$route.params.id,
                          permits: ["location_update", "super_admin"]
                        },
                        expression:
                          "{locId: $route.params.id, permits: ['location_update', 'super_admin']}",
                        arg: "prevent"
                      }
                    ]
                  },
                  [_vm._v(_vm._s(channel.bundle_commission))]
                ),
                _vm._v(" "),
                _c("editable-text", {
                  directives: [
                    {
                      name: "hasPermits",
                      rawName: "v-hasPermits:allow",
                      value: {
                        locId: _vm.$route.params.id,
                        permits: ["location_update"]
                      },
                      expression:
                        "{locId: $route.params.id, permits: ['location_update']}",
                      arg: "allow"
                    }
                  ],
                  attrs: { value: channel.bundle_commission },
                  on: {
                    valueSaved: function($event) {
                      return _vm.commissionChanged(
                        $event,
                        i,
                        "bundle_commission"
                      )
                    }
                  }
                }),
                _vm._v(" "),
                _vm.errors["channels." + i + ".bundle_commission"]
                  ? _c("div", { staticClass: "text-danger" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.errors[
                              "channels." + i + ".bundle_commission"
                            ][0]
                          ) +
                          "\n          "
                      )
                    ])
                  : _vm._e()
              ],
              1
            )
          ])
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("\n          Channel Name\n        ")]),
        _vm._v(" "),
        _c("th", [_vm._v("\n          Channel Domain\n        ")]),
        _vm._v(" "),
        _c("th", [_vm._v("\n          Parking Commission Type\n        ")]),
        _vm._v(" "),
        _c("th", [_vm._v("\n          Parking Comm.(%)\n        ")]),
        _vm._v(" "),
        _c("th", [_vm._v("\n          Room Comm.(%)\n        ")]),
        _vm._v(" "),
        _c("th", [_vm._v("\n          Bundle Comm.(%)\n        ")])
      ])
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-2d4b4b70", { render: render, staticRenderFns: staticRenderFns })
  }
}