var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "layout",
    { attrs: { "no-box": true, title: _vm.location ? _vm.location.name : "" } },
    [
      _c("tabs"),
      _vm._v(" "),
      _c("div", { staticClass: "box" }, [
        _c("div", { staticClass: "box-body" }, [
          _c(
            "div",
            { staticClass: "row" },
            [
              _c(
                "div",
                { staticClass: "col-sm-8 col-sm-offset-2 margin-top-3x" },
                [
                  _c(
                    "div",
                    { staticClass: "text-right margin-bottom-3x" },
                    [
                      _c("new-channel", {
                        directives: [
                          {
                            name: "hasPermits",
                            rawName: "v-hasPermits:allow",
                            value: {
                              id: _vm.locId,
                              permits: ["location_channel_update"]
                            },
                            expression:
                              "{id: locId, permits: ['location_channel_update']}",
                            arg: "allow"
                          }
                        ],
                        staticClass: "margin-bottom-3x"
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "table-responsive" }, [
                    _c(
                      "table",
                      { staticClass: "table table-striped margin-top-3x" },
                      [
                        _c("thead", [
                          _c("tr", [
                            _c("th", [_vm._v(" Channel Name ")]),
                            _vm._v(" "),
                            _c("th", [_vm._v(" Domain ")]),
                            _vm._v(" "),
                            _c("th")
                          ])
                        ]),
                        _vm._v(" "),
                        _c(
                          "tbody",
                          _vm._l(_vm.location.channels, function(data) {
                            return _c("tr", [
                              _c("td", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      data.channel ? data.channel.name : ""
                                    ) +
                                    " "
                                )
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      data.channel ? data.channel.domain : ""
                                    ) +
                                    " "
                                )
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _c(
                                  "button",
                                  {
                                    directives: [
                                      {
                                        name: "hasPermits",
                                        rawName: "v-hasPermits:allow",
                                        value: {
                                          id: _vm.locId,
                                          permits: ["location_channel_update"]
                                        },
                                        expression:
                                          "{id: locId, permits: ['location_channel_update']}",
                                        arg: "allow"
                                      }
                                    ],
                                    staticClass: "btn btn-danger",
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        return _vm.removeChannel(data.channel)
                                      }
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "fa fa-trash",
                                      attrs: { "aria-hidden": "true" }
                                    }),
                                    _vm._v(
                                      "\n                    Remove\n                  "
                                    )
                                  ]
                                )
                              ])
                            ])
                          }),
                          0
                        )
                      ]
                    )
                  ])
                ]
              ),
              _vm._v(" "),
              _c("confirm", { ref: "confirmDelete", attrs: { type: "danger" } })
            ],
            1
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-1528e25f", { render: render, staticRenderFns: staticRenderFns })
  }
}