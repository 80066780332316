import { http } from 'services'

// initial state
const state = () => ({
  long_term_discounts: {},
  active_long_term_discount: {},
  blackout_dates: {},
  active_blackout_date: {},
  rate_adjustments: {},
  active_rate_adjustment: {},
  dynamic_adjustments: {},
  active_dynamic_adjustment: {},
  errors: null
})

// getters
const getters = {
  // reversedMessage: state => state.message.reverse()
}

// actions
const actions = {
  ['rateManagement.submitLongTermDiscount'] ({ commit }, data) {
    commit('CLEAR_ERRORS')

    return (http.post('long-term-discount', data)).then ((res) => {
      commit('SUBMIT_LONG_TERM_DISCOUNT', res );
    })
    .catch ((res) => {
      commit('RATE_MANAGEMENT_ERROR', res);
    })
  },

  ['rateManagement.deleteLongTermDiscount'] ({ commit }, {id, location_id}) {
    commit('CLEAR_ERRORS')

    return (http.delete('long-term-discount/' + id+ '?location_id=' + location_id)).then ((res) => {
      commit('DELETE_LONG_DISCOUNT', res );
    })
    .catch ((res) => {
      commit('RATE_MANAGEMENT_ERROR', res);
    })
  },

  ['rateManagement.submitBlackoutDate'] ({ commit }, data) {
    commit('CLEAR_ERRORS')

    return (http.post('blackout-dates', data)).then ((res) => {
      commit('SUBMIT_BLACKOUT_DATE', res );
    })
    .catch ((res) => {
      console.log('xxx', res);
      
      commit('RATE_MANAGEMENT_ERROR', res);
    })
  },

  ['rateManagement.deleteBlackoutDate'] ({ commit }, {id, location_id}) {
    commit('CLEAR_ERRORS')

    return (http.delete('blackout-dates/' + id + '?location_id=' + location_id)).then ((res) => {
      commit('DELETE_BLACKOUT_DATE', res );
    })
    .catch ((res) => {
      commit('RATE_MANAGEMENT_ERROR', res);
    })
  },

  ['rateManagement.submitRateAdjustment'] ({ commit }, {location_id, data}) {
    commit('CLEAR_ERRORS')

    return (http.post(`locations/${location_id}/rate-adjustment`, data)).then ((res) => {
      commit('SUBMIT_RATE_ADJUSTMENT', res );
    })
    .catch ((res) => {
      commit('RATE_MANAGEMENT_ERROR', res);
    })
  },

  ['rateManagement.deleteRateAdjustment'] ({ commit }, {type, res}) {
    commit('CLEAR_ERRORS')
    commit(`REMOVE_${type.toUpperCase()}_ADJ_RATE`, res);

  },

  ['rateManagement.deleteRateAdjustments'] ({ commit }, {res}) {
    commit('CLEAR_ERRORS')
    commit(`REMOVE_ADJ_RATES`, res);
  },

  ['rateManagement.clearRateHistory'] ({ commit }, {res}) {
    commit('CLEAR_ERRORS')
    commit(`REMOVE_ALL_RATES`, res);
  },

  ['rateManagement.getBlackouts'] ({ commit }, params) {
    if(!params._q) params._q = null;
    if (!params._with_deleted) params._with_deleted = null
    if (!params._only_deleted) params._only_deleted = null
    params._sort = "-id";

    commit('CLEAR_ERRORS')

    return (http.get(`blackout-dates`, {
      params
    })).then((res) => {
      commit('GET_ALL_BLACKOUTS', res);
    }).catch((err) => {
      commit('RATE_MANAGEMENT_ERROR', err);
    });
  },

  ['rateManagement.getRateAdjustments'] ({ commit }, {location_id, params}) {
    if(!params._q) params._q = null;
    if (!params._with_deleted) params._with_deleted = null
    if (!params._only_deleted) params._only_deleted = null
    params._sort = "-id";

    commit('CLEAR_ERRORS')

    return (http.get(`locations/${location_id}/rate-adjustment`, {
      params
    })).then((res) => {
      commit('GET_ALL_ADJUSTMENTS', res);
    }).catch((err) => {
      commit('RATE_MANAGEMENT_ERROR', err);
    });
  },

  ['rateManagement.getLongTermDiscounts'] ({ commit }, params) {
    if(!params._q) params._q = null;
    if (!params._with_deleted) params._with_deleted = null
    if (!params._only_deleted) params._only_deleted = null
    params._sort = "-id";

    commit('CLEAR_ERRORS')

    return (http.get(`/long-term-discount`, {
      params
    })).then((res) => {
      commit('GET_ALL_LONG_DISCOUNTS', res);
    }).catch((err) => {
      commit('RATE_MANAGEMENT_ERROR', err);
    });
  },

  ['rateManagement.getDynamicAdjustments'] ({ commit, state }, {location_id, params}) {
    commit('CLEAR_ERRORS')
    return (http.get(`locations/${location_id}/dynamic-rate-adjustments`,{params})).then((res) => {
      commit('GET_DYNAMIC_ADJUSTMENTS', res);
    }).catch((res) => {
      commit('RATE_MANAGEMENT_ERROR', res);
    });
  },

  ['rateManagement.newDynamicAdjustment'] ({ commit, state }, {location_id, params}) {
    commit('CLEAR_ERRORS')
    return (http.post(`locations/${location_id}/dynamic-rate-adjustments`,params)).then((res) => {
      commit('NEW_DYNAMIC_ADJUSTMENT', res);
    }).catch((res) => {
      commit('RATE_MANAGEMENT_ERROR', res);
    });
  },

  ['rateManagement.updateDynamicAdjustment'] ({ commit, state }, {location_id, params}) {
    commit('CLEAR_ERRORS');
    let rate_adjustment_id = params.rate_adjustment_id;
    return (http.put(`locations/${location_id}/dynamic-rate-adjustments/${rate_adjustment_id}`,params)).then((res) => {
      commit('UPDATE_DYNAMIC_ADJUSTMENT', res);
    }).catch((res) => {
      commit('RATE_MANAGEMENT_ERROR', res);
    });
  },

  ['rateManagement.removeDynamicAdjustment'] ({ commit, state }, {location_id, rate_adjustment_id}) {
    commit('CLEAR_ERRORS')
    return (http.delete(`locations/${location_id}/dynamic-rate-adjustments/${rate_adjustment_id}`)).then((res) => {
      commit('REMOVE_DYNAMIC_ADJUSTMENT', res);
    }).catch((res) => {
      commit('RATE_MANAGEMENT_ERROR', res);
    });
  },
}

// mutations
const mutations = {
  SUBMIT_LONG_TERM_DISCOUNT (state, res) {
    console.log('dispatched', state, res.data);
    state.active_long_term_discount = res.data;
  },

  SUBMIT_BLACKOUT_DATE (state, res) {
    console.log('dispatched', state, res.data);
    state.blackout_dates.data.unshift(res.data);
    state.active_blackout_date = res.data;
  },

  SUBMIT_RATE_ADJUSTMENT (state, res) {
    console.log('dispatched', state, res.data);
    state.active_rate_adjustment = res.data;
  },

  GET_ALL_BLACKOUTS (state, res) {
    console.log('dispatched', state, res.data);
    state.blackout_dates = res.data;
  },

  GET_ALL_ADJUSTMENTS (state, res) {
    console.log('dispatched', state, res.data);
    state.rate_adjustments = res.data;
  },

  DELETE_BLACKOUT_DATE (state, res) {
    state.blackout_dates.data = state.blackout_dates.data.filter((item) => {
      return item.id != res.data.id
    });
  },

  GET_ALL_LONG_DISCOUNTS (state, res) {
    console.log('dispatched', state, res.data);
    state.long_term_discounts = res.data;
  },

  DELETE_LONG_DISCOUNT (state, res) {
    state.long_term_discounts.data = state.long_term_discounts.data.filter((item) => {
      return item.id != res.data.id
    });
  },

  REMOVE_PARKING_ADJ_RATE (state, res) {
    state.rate_adjustments.data = state.rate_adjustments.data
      .filter(item => {
        if (item.id == res.data.id && item.type.toLowerCase() == 'parking') {
          return false
        }

        return true
      })
    state.rate_adjustments.data = _.cloneDeep(state.rate_adjustments.data)
    console.log('parking state.rate_adjustments', state.rate_adjustments);
  },

  REMOVE_ROOM_ADJ_RATE (state, res) {
    state.rate_adjustments.data = state.rate_adjustments.data
      .filter(item => {
        if (item.id == res.data.id && item.type.toLowerCase() == 'room') {
          return false
        }

        return true
      })
    state.rate_adjustments.data = _.cloneDeep(state.rate_adjustments.data)
    console.log('room state.rate_adjustments', state.rate_adjustments);

  },

  REMOVE_BUNDLE_ADJ_RATE (state, res) {
    state.rate_adjustments.data = state.rate_adjustments.data
      .filter(item => {
        if (item.id == res.data.id && item.type.toLowerCase() == 'bundle') {
          return false
        }

        return true
      })
    state.rate_adjustments.data = _.cloneDeep(state.rate_adjustments.data)
    console.log('bundle state.rate_adjustments', state.rate_adjustments);
  },

  REMOVE_ADJ_RATES (state, res) {
    state.rate_adjustments.data = state.rate_adjustments.data
      .filter(item => {
          if (item.rates_adjustments_history_id != null && item.rates_adjustments_history_id == res.data.rates_adjustments_history_id) {
            return false
        }

        return true
      })
    state.rate_adjustments.data = _.cloneDeep(state.rate_adjustments.data)
  },

  REMOVE_ALL_RATES (state, res) {
    if(res.data.length === 0) {
      state.rate_adjustments.data = [];
    }
  },

  GET_DYNAMIC_ADJUSTMENTS (state, res) {
    state.dynamic_adjustments = res.data
  },
  NEW_DYNAMIC_ADJUSTMENT (state, res) {
    state.dynamic_adjustments.data.unshift(res.data);
    // state.active_dynamic_adjustment = res.data;
  },

  UPDATE_DYNAMIC_ADJUSTMENT (state, res) {
    let indx = _.findIndex(state.dynamic_adjustments.data, (item) => item.id == res.data.id);
    if (indx > -1) {
      state.dynamic_adjustments.data[indx] = _.assign(state.dynamic_adjustments.data[indx], res.data);
    }
  },

  REMOVE_DYNAMIC_ADJUSTMENT (state, res) {
    state.dynamic_adjustments.data = state.dynamic_adjustments.data.filter((item) => {
      return item.id != res.data.id
    });
    // state.dynamic_adjustments = _.cloneDeep(state.dynamic_adjustments);
  },

  //ERRORS
  RATE_MANAGEMENT_ERROR (state, err) {
    try {
      state.errors = err.response.data;
    } catch (e) {
      state.errors = err;
    }
  },

  CLEAR_ERRORS (state) {
    state.errors = null
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
