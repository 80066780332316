import { hasPermits } from 'directives'

const directives = {
  hasPermits
}

export default {
  name: 'location-information-tabs',
  directives,
  data () {
    return {
      locId: this.$route.params.id
    }
  },
}
