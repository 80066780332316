import { http } from 'services'
// initial state
const state = () => ({
  modals: [],
  filtered_audits: [],
  errors: null
})

// getters
const getters = {
}

// actions
const actions = {
  ['audits.get'] ({commit}, {data}) {

    commit('CLEAR_ERRORS')

    return (http.get('audits', {params: data})).then ((res) => {
      commit('GET_AUDITS', res);
    })
    .catch ((res) => {
      commit('AUDITS_ERROR', res);
    })
  },

  ['audits.getModals'] ({ commit }, params) {

    commit('CLEAR_ERRORS')

    return (http.get('audits-modal', {
      params
    })).then ((res) => {
      commit('GET_MODALS', res);
    })
    .catch ((res) => {
      commit('AUDITS_ERROR', res);
    })
  }
}

// mutations
const mutations = {
  GET_MODALS (state, res) {
    state.modals = res.data;
  },

  GET_AUDITS (state, res) {
    state.filtered_audits = res.data
  },

  //ERRORS
  AUDITS_ERROR (state, err) {
    try {
      state.errors = err.response.data;
    } catch (e) {
      state.errors = err;
    }
  },

  CLEAR_ERRORS (state) {
    state.errors = null
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
