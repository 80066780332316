import {
  all as allLocations, new as newLocation,
} from 'pages/locations'

export default [
  //Locations
  { path: '/locations', name: 'locations.all', component: allLocations
    , meta:{permits:['location_user', 'location_list']}},
  { path: '/locations/new', name: 'locations.new', component: newLocation
    , meta:{permits:['location_user', 'location_create']}},
]
