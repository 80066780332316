import moment from 'moment'
import { funcModal } from 'components/modals'
import { dateFormat, stripTags } from 'filters'
import { hasPermits } from 'directives'
import toastr from 'toastr'
import confirm from 'components/modals/confirm'
import newLocationEmailSchedule from "./newLocationEmailSchedule"

const components = {
  funcModal,
  confirm,
  newLocationEmailSchedule
}

const filters = {
  dateFormat,
  stripTags
}

const directives = {
  hasPermits
}

const methods = {
  open (location_id, location_email_id) {

    this.location_id = location_id
    this.location_email_id = location_email_id;

    this.loading = true;
    this.$store.dispatch('locationEmailSchedule.getAll', {location_id: this.location_id, location_email_id: this.location_email_id}).then((res) => {
      this.loading = false;
    });

    this.$refs.modal.open();
  },

  removeSchedule (item) {
    this.$refs.confirmDelete.confirm('Are you sure that you want to remove this schedule?', () => {
      this.$set(this.loadings, item.id, true)
      this.$store.dispatch('locationEmailSchedule.remove', {
        location_id: this.location_id, 
        location_email_id: this.location_email_id,
        id: item.id
      }).then(() => {
        this.$set(this.loadings, item.id, false)
        
        let errors = _.cloneDeep(this.$store.state.locationEmailSchedule.errors);
        if (errors) {
          toastr.error(errors.message);
        } else {
          toastr.success('Email Schedule has been deleted successfully');
        }
      });
    });
  },

  fromIntToHour(hour) {
    return this.hours[Number(hour)];
  },

  printReports(reports) {
    return _.map(reports, (item) => {
      return this.reports[item];
    }).join(', ')
  }
}

const computed = {
  data () {
    return _.cloneDeep(this.$store.state.locationEmailSchedule.all);
  }
}

const watch = {
}

export default {
  name: "location-emails-schedules",
  props: [],
  watch,
  components,
  filters,
  methods,
  computed,
  directives,
  data () {
    return {
      loadings: [],
      loading: false,
      location_id: null,
      location_email_id: null,
      days: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'],
      hours: [
        "12:00 AM", "01:00 AM", '02:00 AM', '03:00 AM', 
        '04:00 AM', '05:00 AM', '06:00 AM', '07:00 AM', 
        '08:00 AM', '09:00 AM', '10:00 AM', '11:00 AM',
        '12:00 PM', '01:00 PM', '02:00 PM', '03:00 PM',
        '04:00 PM', '05:00 PM', '06:00 PM', '07:00 PM',
        '08:00 PM', '09:00 PM', '10:00 PM', '11:00 PM'
      ],
      reports: {
        "commission_pickup": "Commission report by Pickup date",
        "commission_booking": "Commission report by booking date"
      }
    }
  }
}
