import toastr from 'toastr'
import { stripTags } from 'filters'
import { funcModal } from 'components/modals'

const components = {
    funcModal
}

const methods = {
    open () {
        this.$refs.modal.open();
    },

    submit () {

        if (stripTags(this.reject_reason) == "" ) {
            toastr.error('Reject reason empty');
            return;
        }

        this.errors = {};
        this.processing = true;
        this.$store.dispatch('refundRequests.update', {
        id: this.$route.params.id,
        data: {
            status: 'rejected',
            reject_reason: this.reject_reason
        }
        }).then(() => {
        this.processing = false;
        let errors =  this.$store.state.refundRequests.errors;
        if (errors) {
            if (errors.errors) {
            this.errors = _.cloneDeep(errors.errors);  
            }
            toastr.error(errors.message);
        } else {
            toastr.success('Refund request has been updated successfully');
            this.$refs.modal.close();
        }
        });
    }
}

export default {
    name: 'reject-modal',
    methods,
    components,
    data () {
        return {
            errors: {},
            reject_reason: "",
            processing: false
        }
    }
}