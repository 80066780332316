import { http } from 'services'
// initial state
const state = () => ({
  countries: [],
  states: [],
  errors: null
})

// getters
const getters = {
}

// actions
const actions = {
  ['address.getCountries'] ({ commit, state }) {
    commit('CLEAR_ERRORS')

    if (state.countries.length) {
      return commit('GET_ALL_COUNTRIES');
    }

    return (http.get('countries?_all=true')).then ((res) => {
      commit('GET_ALL_COUNTRIES', res);
    })
    .catch ((res) => {
      commit('ADDRESS_ERROR', res);
    })
  },

  ['address.getStates'] ({ commit, state }, country_id) {
    commit('CLEAR_ERRORS')

    return (http.get('states?country_id=' + id))
    .then ((res) => {
      commit('GET_STATES', res);
    })
    .catch ((res) => {
      commit('ADDRESS_ERROR', res);
    })
  },
}

// mutations
const mutations = {
  GET_ALL_COUNTRIES (state, res) {
    if (res)
      state.countries = res.data;
  },

  GET_STATES (state, res) {
    state.states = res.data
  },

  //ERRORS
  ADDRESS_ERROR (state, err) {
    try {
      state.errors = err.response.data;
    } catch (e) {
      state.errors = err;
    }
  },

  CLEAR_ERRORS (state) {
    state.errors = null
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
