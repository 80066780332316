var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "layout",
    { attrs: { "no-box": true, title: _vm.location ? _vm.location.name : "" } },
    [
      _c("tabs"),
      _vm._v(" "),
      _c("div", { staticClass: "box" }, [
        _vm.loading
          ? _c("div", { staticClass: "text-center" }, [
              _c("i", { staticClass: "fa fa-spinner fa-spin fa-3x fa-fw" })
            ])
          : _c("div", { staticClass: "box-body" }, [
              _c(
                "div",
                { staticClass: "row" },
                [
                  _c(
                    "div",
                    { staticClass: "col-sm-8 col-sm-offset-2 margin-top-3x" },
                    [
                      _c(
                        "div",
                        { staticClass: "text-right margin-bottom-3x" },
                        [
                          _c("new-location-email", {
                            directives: [
                              {
                                name: "hasPermits",
                                rawName: "v-hasPermits:allow",
                                value: {
                                  id: _vm.location_id,
                                  permits: ["locationEmails_create"]
                                },
                                expression:
                                  "{id: location_id, permits: ['locationEmails_create']}",
                                arg: "allow"
                              }
                            ],
                            staticClass: "margin-bottom-3x"
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "table-responsive" }, [
                        _c(
                          "table",
                          { staticClass: "table table-striped margin-top-3x" },
                          [
                            _c("thead", [
                              _c("tr", [
                                _c("th", [_vm._v(" Email ")]),
                                _vm._v(" "),
                                _c("th", [_vm._v(" Parking ")]),
                                _vm._v(" "),
                                _c("th", [_vm._v(" Room ")]),
                                _vm._v(" "),
                                _c("th", [_vm._v(" Daily Check-in/out List ")]),
                                _vm._v(" "),
                                _c(
                                  "th",
                                  {
                                    directives: [
                                      {
                                        name: "hasPermits",
                                        rawName: "v-hasPermits:prevent",
                                        value: ["location_user"],
                                        expression: "['location_user']",
                                        arg: "prevent"
                                      }
                                    ]
                                  },
                                  [_vm._v(" Decision Maker ")]
                                ),
                                _vm._v(" "),
                                _c("th")
                              ])
                            ]),
                            _vm._v(" "),
                            _c(
                              "tbody",
                              [
                                _vm._l(_vm.data, function(user) {
                                  return _vm.data.length
                                    ? _c("tr", [
                                        _c("td", [
                                          _vm._v(" " + _vm._s(user.email) + " ")
                                        ]),
                                        _vm._v(" "),
                                        _c("td", [
                                          _c(
                                            "label",
                                            { staticClass: "checkbox-inline" },
                                            [
                                              _c("input", {
                                                attrs: { type: "checkbox" },
                                                domProps: {
                                                  checked: user.parking
                                                },
                                                on: {
                                                  change: function($event) {
                                                    return _vm.updateLocationEmail(
                                                      user,
                                                      "parking",
                                                      $event
                                                    )
                                                  }
                                                }
                                              })
                                            ]
                                          )
                                        ]),
                                        _vm._v(" "),
                                        _c("td", [
                                          _c(
                                            "label",
                                            { staticClass: "checkbox-inline" },
                                            [
                                              _c("input", {
                                                attrs: { type: "checkbox" },
                                                domProps: {
                                                  checked: user.room
                                                },
                                                on: {
                                                  change: function($event) {
                                                    return _vm.updateLocationEmail(
                                                      user,
                                                      "room",
                                                      $event
                                                    )
                                                  }
                                                }
                                              })
                                            ]
                                          )
                                        ]),
                                        _vm._v(" "),
                                        _c("td", [
                                          _c(
                                            "label",
                                            { staticClass: "checkbox-inline" },
                                            [
                                              _c("input", {
                                                attrs: { type: "checkbox" },
                                                domProps: {
                                                  checked: user.daily_list
                                                },
                                                on: {
                                                  change: function($event) {
                                                    return _vm.updateLocationEmail(
                                                      user,
                                                      "daily_list",
                                                      $event
                                                    )
                                                  }
                                                }
                                              })
                                            ]
                                          )
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "td",
                                          {
                                            directives: [
                                              {
                                                name: "hasPermits",
                                                rawName: "v-hasPermits:prevent",
                                                value: ["location_user"],
                                                expression: "['location_user']",
                                                arg: "prevent"
                                              }
                                            ]
                                          },
                                          [
                                            _c(
                                              "label",
                                              {
                                                staticClass: "checkbox-inline"
                                              },
                                              [
                                                _c("input", {
                                                  attrs: {
                                                    type: "checkbox",
                                                    disabled: !user.is_location_user
                                                  },
                                                  domProps: {
                                                    checked: user.decision_maker
                                                  },
                                                  on: {
                                                    change: function($event) {
                                                      return _vm.updateLocationEmail(
                                                        user,
                                                        "decision_maker",
                                                        $event
                                                      )
                                                    }
                                                  }
                                                })
                                              ]
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("td", [
                                          _c(
                                            "button",
                                            {
                                              directives: [
                                                {
                                                  name: "hasPermits",
                                                  rawName: "v-hasPermits:allow",
                                                  value: {
                                                    id: _vm.location_id,
                                                    permits: [
                                                      "locationEmailsSchedules_list"
                                                    ]
                                                  },
                                                  expression:
                                                    "{id: location_id, permits: ['locationEmailsSchedules_list']}",
                                                  arg: "allow"
                                                }
                                              ],
                                              staticClass: "btn btn-primary",
                                              on: {
                                                click: function($event) {
                                                  $event.preventDefault()
                                                  return _vm.openSchedules(user)
                                                }
                                              }
                                            },
                                            [_vm._v("Schedules")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "button",
                                            {
                                              directives: [
                                                {
                                                  name: "hasPermits",
                                                  rawName: "v-hasPermits:allow",
                                                  value: {
                                                    id: _vm.location_id,
                                                    permits: [
                                                      "locationEmails_update"
                                                    ]
                                                  },
                                                  expression:
                                                    "{id: location_id, permits: ['locationEmails_update']}",
                                                  arg: "allow"
                                                }
                                              ],
                                              staticClass: "btn btn-danger",
                                              class: {
                                                disabled: _vm.loadings[user.id]
                                              },
                                              attrs: {
                                                disabled: _vm.loadings[user.id]
                                              },
                                              on: {
                                                click: function($event) {
                                                  $event.preventDefault()
                                                  return _vm.removeEmail(user)
                                                }
                                              }
                                            },
                                            [
                                              _vm.loadings[user.id]
                                                ? _c("span", [
                                                    _c("i", {
                                                      staticClass:
                                                        "fa fa-spinner fa-spin"
                                                    })
                                                  ])
                                                : _vm._e(),
                                              _vm._v(" "),
                                              !_vm.loadings[user.id]
                                                ? _c("i", {
                                                    staticClass: "fa fa-trash",
                                                    attrs: {
                                                      "aria-hidden": "true"
                                                    }
                                                  })
                                                : _vm._e(),
                                              _vm._v(
                                                "\n                      Remove\n                      \n                    "
                                              )
                                            ]
                                          )
                                        ])
                                      ])
                                    : _vm._e()
                                }),
                                _vm._v(" "),
                                !_vm.data.length
                                  ? _c("tr", [
                                      _c("td", { attrs: { colspan: "4" } }, [
                                        _vm._v(
                                          "\n                    No data has been saved yet.\n                  "
                                        )
                                      ])
                                    ])
                                  : _vm._e()
                              ],
                              2
                            )
                          ]
                        )
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c("confirm", {
                    ref: "confirmDelete",
                    attrs: { type: "danger" }
                  }),
                  _vm._v(" "),
                  _c("schedules-modal", { ref: "schedulesModal" })
                ],
                1
              )
            ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-7fe39cfb", { render: render, staticRenderFns: staticRenderFns })
  }
}